import { CForm, CInputGroup } from '@coreui/react';
import React, { useEffect } from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorsForTranslations } from '../../../../store/TranslationsReducer';
import { editEpisodeReadOnlyCell } from '../../Lines/_Helpers';
import { calculateMovementOnClickLine } from './_TranslationsHelpers';

export const Customization1TranslationLine = ({ line, index, inputLine, isPreview } : any) =>
{
    const { lineTypes, selectedLineIndex, serbianTranslate, englishTranslate, otherLanguageTranslate, linesFrame, defaultLine } = useSelector((state: any) => state.translationsReducer);
    const dispatch = useDispatch();

    useEffect(() => 
    {
        let lineNumber = document.querySelector(`span[id="lineNumber${index}"]`);
        if(lineNumber) lineNumber.innerHTML = line.lineNumber;
        let lineType = document.querySelector(`span[id="lineType${index}"]`);
        if(lineType) 
        { 
            let types = lineTypes?.filter((x:any) => x.id == line.lineType);
            lineType.innerHTML = types.length > 0 ? types[0].label : '';
        }
        let clothes = document.querySelector(`span[id="clothes${index}"]`)
        if(clothes) clothes.innerHTML = "Clothes"

        let clothes1Name = document.querySelector(`span[id="cclothes1Name${index}"]`)
        if(clothes1Name) clothes1Name.innerHTML = line.clothes1Name;
        
        let clothes2Name = document.querySelector(`span[id="cclothes2Name${index}"]`)
        if(clothes2Name) clothes2Name.innerHTML = line.clothes2Name;
        
        let clothes3Name = document.querySelector(`span[id="cclothes3Name${index}"]`)
        if(clothes3Name) clothes3Name.innerHTML = line.clothes3Name;
    }, [line, lineTypes])

    return(
    <div>
        <CForm className={inputLine ? 'is-input-translations row' : 'row'}>
                <CInputGroup className='col c-line-trns-clth ' style={{marginLeft: !inputLine ? `${line.depth * parseInt(editEpisodeReadOnlyCell.minWidth.split("px")[0])}px` : "" ,}}
            onClick={() =>
                {
                    if(!inputLine && !isPreview)
                    {
                        let movement = calculateMovementOnClickLine(index, selectedLineIndex, linesFrame, serbianTranslate, englishTranslate)

                        dispatch(ActionCreatorsForTranslations.setCurrentLineForTranslations(
                        {
                            currentLineSerbian: serbianTranslate && serbianTranslate?.length != 0 && !serbianTranslate[0]?.isDefault ? serbianTranslate[movement] : defaultLine,
                            currentLineEnglish: englishTranslate && englishTranslate?.length != 0 && !englishTranslate[0]?.isDefault ? englishTranslate[movement] : defaultLine,
                            currentLineOther: otherLanguageTranslate && otherLanguageTranslate?.length != 0 && !otherLanguageTranslate[0]?.isDefault ? otherLanguageTranslate[movement] : defaultLine,
                            currentLineIndex: movement, 
                            linesStartIndex: movement + 1 > linesFrame ? movement + 1 - linesFrame : 0
                        }))
                    }
                }}
            >
                <span style={editEpisodeReadOnlyCell} id={`lineNumber${index}`}></span>
                    <span style={editEpisodeReadOnlyCell}  id={`lineType${index}`}></span>
                    <span style={editEpisodeReadOnlyCell}  id={`clothes${index}`}></span>
                    <span style={editEpisodeReadOnlyCell} className="col c-line-trns"  id={`cclothes1Name${index}`}></span>
                    <span style={editEpisodeReadOnlyCell} className="col c-line-trns"  id={`cclothes2Name${index}`}></span>
                    <span style={editEpisodeReadOnlyCell} className="col c-line-trns" id={`cclothes3Name${index}`}></span>
                <input style={{flexGrow: 1}} disabled></input>
            </CInputGroup>
        </CForm>
    </div>
    )
}

export default Customization1TranslationLine;