import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

interface PrivateRouteProps extends RouteProps 
{
    component?: any;
    children?: any;
}

const ProtectedRoute = (props: PrivateRouteProps) => 
{
  const { component: Component, children, ...rest } = props;
  
  return (
    <Route {...rest} render={routeProps => 
    {
        if(localStorage.getItem('token') !== null)
        {
            return(<Component {...routeProps}/>)
        }
        else
        {
            return(<Redirect
                to={{
                    pathname: '',
                    state: { from: routeProps.location },
                }}
            />)
        }
    }}
    />)
}

export default ProtectedRoute;