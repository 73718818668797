import { LineTypes } from "../components/Common/Enums/LineTypes";
import { generateBAnumbers, generateLineNumbers, generateQAnumbers, generateTAnumbers, generateVAnumbers, validateIfBorCLineFormatSelected } from "../components/Common/Lines/_Helpers";
import { LineType } from "../interfaces/lineType";

export const defaultLine = {
    isDefault: true,
    docId: undefined,
    storyId: undefined,
    storyTranslationId: undefined,
    episodeId: undefined,
    lineNumber: 0,
    depth: 0,
    lineType: 0,
    lineFormat: 0,

    characterId: undefined,
    characterName: undefined,
    emotion: 0,
    text: '',

    clothes1Name: '',
    clothes2Name: '',
    clothes3Name: '',
    clothes4Name: '',
    clothes5Name: '',
    clothes6Name: '',
    hairstyle1Name: undefined,
    hairstyle2Name: undefined,
    hairstyle3Name: undefined,
    skinColor1Name: undefined,
    skinColor2Name: undefined,
    skinColor3Name: undefined,
    skinColor4Name: undefined,

    payed: false,

    animationPath: undefined,
    animationName: undefined,

    questionDocId: undefined,

    comment: '',
    commentReporterName: undefined,
    commentReporterUID: undefined,
    commentAssignedToName: undefined,
    commentAssignedToUID: undefined,
    commentResolved: false,

    backgroundId: undefined,
    backgroundPath: undefined,
    backgroundName: undefined,
    backgroundDescription: undefined,
    musicId: undefined,
    musicPath: undefined,
    musicName: undefined,
    musicDescription: undefined,
    shortSoundId: undefined,
    shortSoundPath: undefined,
    shortSoundName: undefined,
    shortSoundDescription: undefined,
    shortSoundEnabled: false,
    itemId: undefined,
    itemName: undefined,
    itemDescription: undefined,
    avatarEnabled: true,
    callPhoneEnabled: false,
    callSmsEnabled: false,
    actionChangeFlag: false,
    charmingPoint: undefined,
    premiumChoiceText: undefined,
    premiumChoiceDescription: undefined,
    charactersOutfit: [] as any[],
    answerQuestionId: undefined,

    branchType: undefined,
    branchValue: undefined,
    filtered: false,

    changeIdentityOn: false,
    changeIdentityName: undefined,
    visualId: undefined,
    visualName: undefined,
    callerCharacterId: undefined,
    callerCharacterName: undefined
}
const initialState = 
{
    editEpisodePassParam: 
    {   
        createdDate: "",
        episodeStatus: "",
        episodeStatusEnum: 0,
        id: "",
        inReviewDate: "",
        readyForTranslationDate: "",
        serialNumber: 1,
        storyId: "",
        storyTranslationId: "",
        title: "",
        titleDescription: ""
    },
    initEditEpisodeBody: true,
    selectedLineIndex: -1,
    currentLine: defaultLine,
    defaultLine,
    linesStartIndex: 0,
    linesFrame: 25,
    lines: 
    [
        defaultLine
    ],
    actionsParams: 
    {
        backgroundId: null,
        backgroundName: null,
        backgroundDescription: null,

        musicId: null,
        musicName: null,
        musicDescription: null,

        shortSoundId: null,
        shortSoundName: null,
        shortSoundDescription: null,
        shortSoundEnabled: false,
        
        itemId: null,
        itemName: null,
        itemDescription: null,

        avatarEnabled: false,
        callPhoneEnabled: false,
        callSmsEnabled: false,
            
        callerCharacterId: null,
        callerCharacterName: null,

        accessoryEnabled: false,
        changeIdentityOn: null,
        changeIdentityName: null,

        charmingPoint: null,
        premiumChoiceText: null,
        premiumChoiceDescription: null,

        clothesId: null,
        clothesName: null,
        clothesDescription: null,

        hairstyleId: null,
        hairstyleName: null,
        hairstyleDescription: null,
        
        accessoryId: null,
        accessoryName: null,
        accessoryDescription: null,
        
        characterId: null,
        characterName: null,
    },
    isRenderedByAction: false,
    cinematics: [] as any[],
    charactersChangeOufit: [] as any[],
    characters: [] as any[],
    storyItems: [] as any[],
    animations: [] as any[],
    backgrounds: [] as any[],
    
    clothes: [] as any[],
    hairstyles: [] as any[],
    accessories: [] as any[],
    accessoryTypes: [] as any[],

    musics: [] as any[],
    shortSounds: [] as any[],
    visualAnswers: [] as any[],
    defaultLineTypes: undefined as any,
    defaultLineFormats: undefined as any,
    defaultEmotions: undefined as any,
    defaultSkinColors: undefined as any,
    defaultBranchQandC: undefined as any,
    defaultVisualChoices: undefined as any,
    defaultClothingCombinations: undefined as any,
    previousStateActions: 
    {
        backgroundId: null,
        backgroundName: null,
        backgroundDescription: null,
        musicId: null,
        musicName: null,
        musicDescription: null,
        shortSoundId: null,
        shortSoundName: null,
        shortSoundDescription: null,
        shortSoundEnabled: false,
        itemId: null,
        itemName: null,
        itemDescription: null,
        charmingPoint: null,
        premiumChoiceText: null,
        premiumChoiceDescription: null,
        avatarEnabled: null,
        callPhoneEnabled: null,
        callSmsEnabled: null,
        callerCharacterId: null,
        callerCharacterName: null,
        charactersOutfit: null,
        changeIdentityOn: null,
        changeIdentityName: null
    },
    updateCloudBool: 'not updating',
    episodeSummary: 
    {
        wordsNum: 0,
        linesNum: 0,
        questionsNum: 0,
        customizationsNum: 0,
        paidAnswersNum: 0,
        cinematicsNum: 0
    },
    answerNumber: 0,
    isOLineAdded: false,
    maxLineNumber: 0,
    episodeStates: [] as any[],
    linesSliceParam: 0,
    changeOutfitTypes: [] as any[],
    lineColors: [
        { id: 0, value: 0, label: 'Backgrounds', color: '#ede2cd'},
        { id: 1, value: 1, label: 'Music Themes', color: '#e2f1d6'},
        { id: 2, value: 2, label: 'Calls', color: '#FFBCBC'},
        { id: 3, value: 3, label: 'Short Sounds', color: '#FAFCC2'},
        { id: 4, value: 4, label: 'Changed Clothes', color: '#F6D6AD'},
        { id: 5, value: 5, label: 'Changed Hairs', color: '#dac7eb'},
        { id: 6, value: 6, label: 'Changed Accessories', color: '#d5e8fb'},
        { id: 7, value: 7, label: 'Charming Points', color: '#c6f2f2'},
        { id: 8, value: 8, label: 'SMS', color: '#fcb9db' }
    ],
    pickedColorType: 
    {
        colors: {
            Q: '#fcb9db',
            T: '#fcb9db',
            V: '#fcb9db',
            A1: '#FFBCBC',
            A2: '#dac7eb',
            A3: '#d5e8fb',
            A4: '#FAFCC2',
            B: '#c6f2f2',
            E: '#e06666'
        }
    },
    pickedActionColor: { id: null, value: null, label: '', color: '' },
    numberOfAddedCommentsInSession: 0
}

export const ActionTypes =
{
    SET_LINES: 'SET_LINES',
    SET_CHARACTES: 'SET_CHARACTES',
    SET_CHARACTERS_FOR_OUTFIT_CHANGE: 'SET_CHARACTERS_FOR_OUTFIT_CHANGE',
    SET_STORY_ITEMS: 'SET_STORY_ITEMS',
    SET_ANIMATIONS: 'SET_ANIMATIONS',
    SET_BACKGROUNDS: 'SET_BACKGROUNDS',
    SET_MUSICS: 'SET_MUSICS',
    SET_SHORT_SOUNDS: 'SET_SHORT_SOUNDS',
    ADD_CHARACTERS: 'ADD_CHARACTERS',
    ADD_CHARACTERS_FOR_OUTFIT_CHANGE: 'ADD_CHARACTERS_FOR_OUTFIT_CHANGE',
    ADD_ANIMATIONS: 'ADD_ANIMATIONS',
    ADD_STORY_ITEM: 'ADD_STORY_ITEM',
    ADD_BACKGROUNDS: 'ADD_BACKGROUNDS',
    ADD_MUSICS: 'ADD_MUSICS',
    ADD_SHORT_SOUNDS: 'ADD_SHORT_SOUNDS',
    ADD_NEW_LINE: 'ADD_NEW_LINE',
    ADD_NEW_LINES: 'ADD_NEW_LINES',

    SET_EDIT_EPISODE_PASS_PARAM: 'SET_EDIT_EPISODE_PASS_PARAM',
    SET_CURRENT_LINE: 'SET_CURRENT_LINE',
    SET_SELECTED_LINE_INDEX: 'SET_SELECTED_LINE_INDEX',
    SET_DEFAULT_LINE: 'SET_DEFAULT_LINE',
    SET_INIT_EDIT_EPISODE_BODY: 'SET_INIT_EDIT_EPISODE_BODY',

    UPDATE_AVATAR_BOOL: 'UPDATE_AVATAR_BOOL',
    UPDATE_SHORTSOUND_BOOL: 'UPDATE_SHORTSOUND_BOOL',
    UPDATE_ACCESSORY_BOOL: 'UPDATE_ACCESSORY_BOOL',
    UPDATE_PHONE_BOOL: 'UPDATE_PHONE_BOOL',
    UPDATE_CALLER_NAME: 'UPDATE_CALLER_NAME',
    UPDATE_SMS_BOOL: 'UPDATE_SMS_BOOL',
    UPDATE_CHARACTER_IDENTITY_ENABLED: 'UPDATE_CHARACTER_IDENTITY_ENABLED',
    UPDATE_CHARACTER_IDENTITY_NAME: 'UPDATE_CHARACTER_IDENTITY_NAME',

    UPDATE_CHARMING_POINT: 'UPDATE_CHARMING_POINT',
    UPDATE_PREMIUM_CHOICE_TEXT: 'UPDATE_PREMIUM_CHOICE_TEXT',
    UPDATE_PREMIUM_CHOICE_DESCRIPTION: 'UPDATE_PREMIUM_CHOICE_DESCRIPTION',

    UPDATE_BACKGROUND_PARAM: 'UPDATE_BACKGROUND_PARAM',
    UPDATE_MUSICTHEME_PARAM: 'UPDATE_MUSICTHEME_PARAM',
    UPDATE_SHORTSOUND_PARAM: 'UPDATE_SHORTSOUND_PARAM',
    UPDATE_CLOTHES_PARAM: 'UPDATE_CLOTHES_PARAM',
    UPDATE_HAIRSTYLE_PARAM: 'UPDATE_HAIRSTYLE_PARAM',
    UPDATE_ACCESSORY_PARAM: 'UPDATE_ACCESSORY_PARAM',
    UPDATE_CHARACTER_PARAM: 'UPDATE_CHARACTER_PARAM',
    UPDATE_ANIMATION_PARAM: 'UPDATE_ANIMATION_PARAM',
    UPDATE_EMOTION_PARAM: 'UPDATE_EMOTION_PARAM',
    UPDATE_TEXT_PARAM:'UPDATE_TEXT_PARAM',
    UPDATE_LINEFORMAT_PARAM: 'UPDATE_LINEFORMAT_PARAM',
    UPDATE_LINETYPE_PARAM: 'UPDATE_LINETYPE_PARAM',
    UPDATE_TEXT: 'UPDATE_TEXT',
    UPDATE_BRANCH_QandCId: 'UPDATE_BRANCH_QandCId',

    UPDATE_BACKGROUND_DESCRIPTION: 'UPDATE_BACKGROUND_DESCRIPTION',
    UPDATE_MUSICTHEME_DESCRIPTION: 'UPDATE_MUSICTHEME_DESCRIPTION',
    UPDATE_SHORTSOUND_DESCRIPTION: 'UPDATE_SHORTSOUND_DESCRIPTION',
    UPDATE_CLOTHES_DESCRIPTION: 'UPDATE_CLOTHES_DESCRIPTION',
    UPDATE_HAIRSTYLE_DESCRIPTION: 'UPDATE_HAIRSTYLE_DESCRIPTION',
    UPDATE_ACCESSORY_DESCRIPTION: 'UPDATE_ACCESSORY_DESCRIPTION',

    UPDATE_CLOTHING_COMB_PARAM: 'UPDATE_CLOTHING_COMB_PARAM',

    UPDATE_SHOW_ITEM_BOOL: 'UPDATE_SHOW_ITEM_BOOL',
    UPDATE_SHOW_ITEM: 'UPDATE_SHOW_ITEM',
    UPDATE_SHOW_ITEM_DESCRIPTION: 'UPDATE_SHOW_ITEM_DESCRIPTION',

    REMOVE_CHARACTERS_OUTFIT: 'REMOVE_CHARACTERS_OUTFIT',
    CLEAR_CHARACTERS_OUTFIT: 'CLEAR_CHARACTERS_OUTFIT',
    REPLACE_CHARACTERS_OUTFIT: 'REPLACE_CHARACTERS_OUTFIT',
    
    UPDATE_CHARACTERS_OUTFIT: 'UPDATE_CHARACTERS_OUTFIT',
    UPDATE_SPECIFIC_CHARACTER_OUTFIT: 'UPDATE_SPECIFIC_CHARACTER_OUTFIT',
    REMOVE_LINE: 'REMOVE_LINE',
    REMOVE_LINES: 'REMOVE_LINES',
    UPDATE_LINE: 'UPDATE_LINE',
    SET_DEFAULT_LINE_TYPES: 'SET_DEFAULT_LINE_TYPES',
    SET_DEFAULT_LINE_FORMATS: 'SET_DEFAULT_LINE_FORMATS',
    SET_DEFAULT_EMOTIONS: 'SET_DEFAULT_EMOTIONS',
    SET_DEFAULT_SKIN_COLORS: 'SET_DEFAULT_SKIN_COLORS',
    SET_DEFAULT_BRANCH_CandQ: 'SET_DEFAULT_BRANCH_CandQ',
    SET_DEFAULT_VISUAL_CHOICES: 'SET_DEFAULT_VISUAL_CHOICES',
    SET_DEFAULT_CLOTHING_COMBINATIONS: 'SET_DEFAULT_CLOTHING_COMBINATIONS',
    UPDATE_ACTION_CHANGE_FLAG: 'UPDATE_ACTION_CHANGE_FLAG',

    SET_PREVIOUS_DEFAULT_STATE: 'SET_PREVIOUS_DEFAULT_STATE',
    SET_AVATAR_STATE: 'SET_AVATAR_STATE',
    SET_BACKGROUND_STATE: 'SET_BACKGROUND_STATE',
    SET_BACKGROUND_DESCRIPTION_STATE: 'SET_BACKGROUND_DESCRIPTION_STATE',
    SET_MUSIC_STATE: 'SET_MUSIC_STATE',
    SET_MUSIC_DESCRIPTION_STATE: 'SET_MUSIC_DESCRIPTION_STATE',
    SET_SHORT_SOUND_STATE: 'SET_SHORT_SOUND_STATE',
    SET_SHORT_SOUND_DESCRIPTION_STATE: 'SET_SHORT_SOUND_DESCRIPTION_STATE',
    SET_SHORT_SOUND_ENABLED_STATE: 'SET_SHORT_SOUND_ENABLED_STATE',

    SET_SHOW_ITEM_STATE: 'SET_SHOW_ITEM_STATE',
    SET_SHOW_ITEM_DESCRIPTION_STATE: 'SET_SHOW_ITEM_DESCRIPTION_STATE',
    SET_SHOW_ITEM_ENABLED_STATE: 'SET_SHOW_ITEM_ENABLED_STATE',

    SET_CHARMING_POINT: 'SET_CHARMING_POINT',
    SET_PREMIUM_CHOICE_STATE: 'SET_PREMIUM_CHOICE_STATE',
    SET_PREMIUM_CHOICE_DESCRIPTION_STATE: 'SET_PREMIUM_CHOICE_DESCRIPTION_STATE',
    SET_CALL_PHONE_STATE: 'SET_CALL_PHONE_STATE',
    SET_CALL_SMS_STATE: 'SET_CALL_SMS_STATE',
    SET_CALLER_NAME: 'SET_CALLER_NAME',
    SET_CHARACTERS_OUTFIT_STATE: 'SET_CHARACTERS_OUTFIT_STATE',
    SET_UPDATE_CLOUD_BOOL: 'SET_UPDATE_CLOUD_BOOL',

    SET_CLOTHES: 'SET_CLOTHES',
    SET_ACCESSORIES: 'SET_ACCESSORIES',
    SET_HAIRSTYLES: 'SET_HAIRSTYLES',

    ADD_CLOTHES: 'ADD_CLOTHES',
    ADD_ACCESSORIES: 'ADD_ACCESSORIES',
    ADD_HAIRSTYLES: 'ADD_HAIRSTYLES',
    ADD_VISUAL_ANSWERS: 'ADD_VISUAL_ANSWERS',

    UPDATE_CLOTHES: 'UPDATE_CLOTHES',
    UPDATE_HAIRSTYLE: 'UPDATE_HAIRSTYLE',
    UPDATE_ACCESSORIES: 'UPDATE_ACCESSORIES',

    SET_LINES_FRAME: 'SET_LINES_FRAME',
    SET_EPISODE_SUMMARY: 'SET_EPISODE_SUMMARY',

    SET_ANSWER_NUMBER: 'SET_ANSWER_NUMBER',
    UPDATE_CLOTHES1_PARAM: 'UPDATE_CLOTHES1_PARAM',
    UPDATE_CLOTHES2_PARAM: 'UPDATE_CLOTHES2_PARAM',
    UPDATE_CLOTHES3_PARAM: 'UPDATE_CLOTHES3_PARAM',
    UPDATE_HAIRSTYLE1_PARAM: 'UPDATE_HAIRSTYLE1_PARAM',
    UPDATE_HAIRSTYLE2_PARAM: 'UPDATE_HAIRSTYLE2_PARAM',
    UPDATE_HAIRSTYLE3_PARAM: 'UPDATE_HAIRSTYLE3_PARAM',
    SET_ISRENDERED_PARAM: 'SET_ISRENDERED_PARAM',
    ADD_CHARACTERS_IN_LINE: 'ADD_CHARACTERS_IN_LINE',
    SET_EPISODE_STATES: 'SET_EPISODE_STATES',
    UPDATE_COMMENT_RESOLVED_STATE: 'UPDATE_COMMENT_RESOLVED_STATE',
    UPDATE_COMMENT_STATE: 'UPDATE_COMMENT_STATE',

    FIND_TEXT_FILTER: 'FIND_TEXT_FILTER',
    UPDATE_LINE_AND_PROPAGATE_ACTIONS: 'UPDATE_LINE_AND_PROPAGATE_ACTIONS',
    UPDATE_LINES_OPTIONS: 'UPDATE_LINES_OPTIONS',
    UPDATE_ACTION_CHANGE_FLAG_ON_DELETE: 'UPDATE_ACTION_CHANGE_FLAG_ON_DELETE',

    UPDATE_C_LINE_CLOTHES_1: 'UPDATE_C_LINE_CLOTHES_1',
    UPDATE_C_LINE_CLOTHES_2: 'UPDATE_C_LINE_CLOTHES_2',
    UPDATE_C_LINE_CLOTHES_3: 'UPDATE_C_LINE_CLOTHES_3',
    SET_ACCESSORY_TYPES: 'SET_ACCESSORY_TYPES',

    UPDATE_EPISODE_DESCRIPTION: 'UPDATE_EPISODE_DESCRIPTION',
    UPDATE_GJUMPTO_PARAM: 'UPDATE_GJUMPTO_PARAM',

    SET_CHANGE_OUTFIT_TYPES: 'SET_CHANGE_OUTFIT_TYPES',
    SET_CHANGE_IDENTITY_ENABLED: 'SET_CHANGE_IDENTITY_ENABLED',
    SET_CHANGE_IDENTITY_NAME: 'SET_CHANGE_IDENTITY_ENABLED',

    SET_CINEMATICS: 'SET_CINEMATICS',
    ADD_CINEMATIC: 'ADD_CINEMATIC',
    UPDATE_VISUAL_PARAM: 'UPDATE_VISUAL_PARAM',
    DISABLE_VISUAL_CHOICE_IF_CHOSEN: 'DISABLE_VISUAL_CHOICE_IF_CHOSEN',
    POPULATE_E_LINES: 'POPULATE_E_LINES',

    SET_PICKED_COLORS: 'SET_PICKED_COLORS',
    SET_PICKED_ACTION_COLOR: 'SET_PICKED_ACTION_COLOR',

    SET_NUMBER_OF_ADDED_COMMENTS_IN_SESSION: 'SET_NUMBER_OF_ADDED_COMMENTS_IN_SESSION'
}

export const ActionCreatorsForEditEpisode = 
{
    setLines: (payload: any) => ({ type: ActionTypes.SET_LINES, payload}),
    setCharacters: (payload: any) => ({ type: ActionTypes.SET_CHARACTES, payload}),
    setCharactersForOutfitChange: (payload: any) => ({ type: ActionTypes.SET_CHARACTERS_FOR_OUTFIT_CHANGE, payload}),
    setStoryItems: (payload : any) => ({type: ActionTypes.SET_STORY_ITEMS, payload}),
    addCharacters: (payload: any) => ({ type: ActionTypes.ADD_CHARACTERS, payload}),
    addCharactersForOutfitChange: (payload: any) => ({ type: ActionTypes.ADD_CHARACTERS_FOR_OUTFIT_CHANGE, payload}),
    setAnimations: (payload: any) => ({ type: ActionTypes.SET_ANIMATIONS, payload}),
    addAnimations: (payload: any) => ({ type: ActionTypes.ADD_ANIMATIONS, payload}),
    addStoryItem: (payload : any) => ({ type: ActionTypes.ADD_STORY_ITEM, payload}),
    setBackgrounds: (payload: any) => ({ type: ActionTypes.SET_BACKGROUNDS, payload}),
    addBackgrounds: (payload: any) => ({ type: ActionTypes.ADD_BACKGROUNDS, payload}),
    setMusics: (payload: any) => ({ type: ActionTypes.SET_MUSICS, payload}),
    addMusics: (payload: any) => ({ type: ActionTypes.ADD_MUSICS, payload}),
    setShortSounds: (payload: any) => ({ type: ActionTypes.SET_SHORT_SOUNDS, payload}),
    addShortSounds: (payload: any) => ({ type: ActionTypes.ADD_SHORT_SOUNDS, payload}),

    addNewLine: (payload: any) => ({ type: ActionTypes.ADD_NEW_LINE, payload}),
    addNewLines: (payload: any) => ({ type: ActionTypes.ADD_NEW_LINES, payload}),
    setEditEpisodePassParam: (payload: any) => ({type: ActionTypes.SET_EDIT_EPISODE_PASS_PARAM, payload}),
    setCurrentLine: (payload: any) => ({type: ActionTypes.SET_CURRENT_LINE, payload}),
    setSelectedLineIndex: (payload: any) => ({type: ActionTypes.SET_SELECTED_LINE_INDEX, payload}),
    setDefaultLine: (payload: any) => ({type: ActionTypes.SET_DEFAULT_LINE, payload}),
    setInitEditEpisodeBody: (payload: any) => ({type: ActionTypes.SET_INIT_EDIT_EPISODE_BODY, payload}),

    updateShowItemBool: (payload: any) => ({type: ActionTypes.UPDATE_SHOW_ITEM_BOOL, payload}),
    updateShowItem: (payload : any) => ({type: ActionTypes.UPDATE_SHOW_ITEM, payload}),
    updateShowItemDescription: (payload : any) => ({type: ActionTypes.UPDATE_SHOW_ITEM_DESCRIPTION, payload}),

    updateAvatarBool: (payload: any) => ({type: ActionTypes.UPDATE_AVATAR_BOOL, payload}),
    updateShortSoundBool: (payload: any) => ({type: ActionTypes.UPDATE_SHORTSOUND_BOOL, payload}),
    updateAccessoryBool: (payload: any) => ({type: ActionTypes.UPDATE_ACCESSORY_BOOL, payload}),
    updatePhoneBool: (payload: any) => ({type: ActionTypes.UPDATE_PHONE_BOOL, payload}),
    updateCallerName: (payload: any) => ({type: ActionTypes.UPDATE_CALLER_NAME, payload}),
    updateSmsBool: (payload: any) => ({type: ActionTypes.UPDATE_SMS_BOOL, payload}),

    updateCharacterIdentityEnabled: (payload: any) => ({type: ActionTypes.UPDATE_CHARACTER_IDENTITY_ENABLED, payload}),
    updateCharacterIdentityName: (payload: any) => ({type: ActionTypes.UPDATE_CHARACTER_IDENTITY_NAME, payload}),

    updateCharmingPoint: (payload: any) => ({type: ActionTypes.UPDATE_CHARMING_POINT, payload}),
    updatePremiumChoiceText: (payload: any) => ({type: ActionTypes.UPDATE_PREMIUM_CHOICE_TEXT, payload}),
    updatePremiumChoiceDescription: (payload: any) => ({type: ActionTypes.UPDATE_PREMIUM_CHOICE_DESCRIPTION, payload}),

    updateBackgroundParam: (payload : any) => ({type: ActionTypes.UPDATE_BACKGROUND_PARAM, payload}),
    updateMusicThemeParam: (payload : any) => ({type: ActionTypes.UPDATE_MUSICTHEME_PARAM, payload}),
    updateShortSoundParam: (payload : any) => ({type: ActionTypes.UPDATE_SHORTSOUND_PARAM, payload}),
    updateClothesParam: (payload : any) => ({type: ActionTypes.UPDATE_CLOTHES_PARAM, payload}),
    updateHairstyleParam: (payload : any) => ({type: ActionTypes.UPDATE_HAIRSTYLE_PARAM, payload}),
    updateAccessoryParam: (payload : any) => ({type: ActionTypes.UPDATE_ACCESSORY_PARAM, payload}),
    updateCharacterParam: (payload : any) => ({type: ActionTypes.UPDATE_CHARACTER_PARAM, payload}),
    updateAnimationParam: (payload: any) => ({ type: ActionTypes.UPDATE_ANIMATION_PARAM, payload }),

    updateClothes1Param: (payload: any) => ({ type: ActionTypes.UPDATE_CLOTHES1_PARAM, payload }),
    updateClothes2Param: (payload: any) => ({ type: ActionTypes.UPDATE_CLOTHES2_PARAM, payload }),
    updateClothes3Param: (payload: any) => ({ type: ActionTypes.UPDATE_CLOTHES3_PARAM, payload }),

    updateClothingCombParam: (payload: any) => ({ type: ActionTypes.UPDATE_CLOTHING_COMB_PARAM, payload }),
   
    updateHairstyle1Param: (payload: any) => ({ type: ActionTypes.UPDATE_HAIRSTYLE1_PARAM, payload }),
    updateHairstyle2Param: (payload: any) => ({ type: ActionTypes.UPDATE_HAIRSTYLE2_PARAM, payload }),
    updateHairstyle3Param: (payload: any) => ({ type: ActionTypes.UPDATE_HAIRSTYLE3_PARAM, payload }),

    updateVisualParam: (payload: any) => ({ type: ActionTypes.UPDATE_VISUAL_PARAM, payload }),

    updateEmotionParam: (payload: any) => ({ type: ActionTypes.UPDATE_EMOTION_PARAM, payload }),
    updateGJumpToParam: (payload : any) => ({type: ActionTypes.UPDATE_GJUMPTO_PARAM, payload}),
    updateTextParam: (payload: any) => ({ type: ActionTypes.UPDATE_TEXT_PARAM, payload }),
    updateLineFormatParam: (payload: any) => ({ type: ActionTypes.UPDATE_LINEFORMAT_PARAM, payload }),
    updateLineTypeParam: (payload: any) => ({ type: ActionTypes.UPDATE_LINETYPE_PARAM, payload }),
    updateText: (payload: any) => ({ type: ActionTypes.UPDATE_TEXT, payload }),
    updateBranchQandCIdParam: (payload: any) => ({ type: ActionTypes.UPDATE_BRANCH_QandCId, payload }),

    updateBackgroundDescription: (payload : any) => ({type: ActionTypes.UPDATE_BACKGROUND_DESCRIPTION, payload}),
    updateMusicThemeDescription: (payload : any) => ({type: ActionTypes.UPDATE_MUSICTHEME_DESCRIPTION, payload}),
    updateShortSoundDescription: (payload : any) => ({type: ActionTypes.UPDATE_SHORTSOUND_DESCRIPTION, payload}),
    updateClothesDescription: (payload : any) => ({type: ActionTypes.UPDATE_CLOTHES_DESCRIPTION, payload}),
    updateHairstyleDescription: (payload : any) => ({type: ActionTypes.UPDATE_HAIRSTYLE_DESCRIPTION, payload}),
    updateAccessoryDescription: (payload : any) => ({type: ActionTypes.UPDATE_ACCESSORY_DESCRIPTION, payload}),

    removeCharactersOutfit: (payload : any) => ({type: ActionTypes.REMOVE_CHARACTERS_OUTFIT, payload}),
    clearCharactersOutfit: () => ({type: ActionTypes.CLEAR_CHARACTERS_OUTFIT}),
    replaceCharactersOutfit: (payload: any) => ({type: ActionTypes.REPLACE_CHARACTERS_OUTFIT, payload}),

    updateCharactersOutfit: (payload: any) => ({type: ActionTypes.UPDATE_CHARACTERS_OUTFIT, payload}),
    updateSpecificCharacterOufit: (payload: any) => ({type: ActionTypes.UPDATE_SPECIFIC_CHARACTER_OUTFIT, payload}),
    removeLine: (payload: any) => ({ type: ActionTypes.REMOVE_LINE, payload} ),
    removeLines: (payload: any) => ({ type: ActionTypes.REMOVE_LINES, payload} ),

    updateLine: (payload: any) => ({ type: ActionTypes.UPDATE_LINE, payload}),
    setDefaultLineTypes: (payload: any) => ({ type: ActionTypes.SET_DEFAULT_LINE_TYPES, payload}),
    setDefaultLineFormats: (payload: any) => ({ type: ActionTypes.SET_DEFAULT_LINE_FORMATS, payload}),
    setDefaultEmotions: (payload: any) => ({ type: ActionTypes.SET_DEFAULT_EMOTIONS, payload}),
    setDefaultSkinColors: (payload: any) => ({ type: ActionTypes.SET_DEFAULT_SKIN_COLORS, payload}),
    setDefaultBranchCandQ: (payload: any) => ({ type: ActionTypes.SET_DEFAULT_BRANCH_CandQ, payload}),
    setDefaultVisualChoices: (payload: any) => ({ type: ActionTypes.SET_DEFAULT_VISUAL_CHOICES, payload}),
    setDefaultClothingCombinations: (payload: any) => ({ type: ActionTypes.SET_DEFAULT_CLOTHING_COMBINATIONS, payload}),
    updateChangeActionFlag: (payload : any) => ({type: ActionTypes.UPDATE_ACTION_CHANGE_FLAG, payload}),

    setPreviousDefaultState: (payload: any) => ({type: ActionTypes.SET_PREVIOUS_DEFAULT_STATE, payload}),
    setAvatarState: (payload : any) => ({type: ActionTypes.SET_AVATAR_STATE, payload}),
    setBackgroundState: (payload : any) => ({type: ActionTypes.SET_BACKGROUND_STATE, payload }),
    setBackgroundDescState: (payload : any) => ({type: ActionTypes.SET_BACKGROUND_DESCRIPTION_STATE, payload }),
    setMusicState: (payload : any) => ({type: ActionTypes.SET_MUSIC_STATE, payload }),
    setMusicDescState: (payload : any) => ({type: ActionTypes.SET_MUSIC_DESCRIPTION_STATE, payload }),
    setShortSoundState: (payload : any) => ({type: ActionTypes.SET_SHORT_SOUND_STATE, payload }),
    setShortSoundDesc: (payload : any) => ({type: ActionTypes.SET_SHORT_SOUND_DESCRIPTION_STATE, payload }),
    setShortSoundEnabled: (payload : any) => ({type: ActionTypes.SET_SHORT_SOUND_ENABLED_STATE, payload }),

    setShowItemState: (payload : any) => ({type: ActionTypes.SET_SHOW_ITEM_STATE, payload }),
    setShowItemDesc: (payload : any) => ({type: ActionTypes.SET_SHOW_ITEM_DESCRIPTION_STATE, payload }),
    setShowItemEnabled: (payload : any) => ({type: ActionTypes.SET_SHOW_ITEM_ENABLED_STATE, payload }),

    setCharmingPointState: (payload : any) => ({type: ActionTypes.SET_CHARMING_POINT, payload }),
    setPremiumChoiceState: (payload : any) => ({type: ActionTypes.SET_PREMIUM_CHOICE_STATE, payload }),
    setPremiumChoiceDescriptionState: (payload : any) => ({type: ActionTypes.SET_PREMIUM_CHOICE_DESCRIPTION_STATE, payload }),
    setCallPhoneState: (payload : any) => ({type: ActionTypes.SET_CALL_PHONE_STATE, payload }),
    setCallSmsState: (payload : any) => ({type: ActionTypes.SET_CALL_SMS_STATE, payload }),
    setCallerName: (payload : any) => ({type: ActionTypes.SET_CALLER_NAME, payload }),
    setCharactersOutfitState: (payload : any) => ({type: ActionTypes.SET_CHARACTERS_OUTFIT_STATE, payload }),
    setUpdateCloudBool: (payload: any) => ({type: ActionTypes.SET_UPDATE_CLOUD_BOOL, payload}),
    setChangeIndetityEnabled: (payload : any) => ({type: ActionTypes.SET_CHANGE_IDENTITY_ENABLED, payload}),
    setChangeIndetityName: (payload : any) => ({type: ActionTypes.SET_CHANGE_IDENTITY_NAME, payload}),

    setClothes: (payload : any) => ({type: ActionTypes.SET_CLOTHES, payload}),
    setAccessories: (payload : any) => ({type: ActionTypes.SET_ACCESSORIES, payload}),
    setAccessoryTypes: (payload : any) => ({type: ActionTypes.SET_ACCESSORY_TYPES, payload}),
    setHairstyles: (payload : any) => ({type: ActionTypes.SET_HAIRSTYLES, payload}),

    addClothes: (payload : any) => ({type: ActionTypes.ADD_CLOTHES, payload}),
    addAccessories: (payload : any) => ({type: ActionTypes.ADD_ACCESSORIES, payload}),
    addHairstyles: (payload : any) => ({type: ActionTypes.ADD_HAIRSTYLES, payload}),

    addVisualAnswers: (payload : any) => ({type: ActionTypes.ADD_VISUAL_ANSWERS, payload}),
    disableVisualChoiceIfChosen: (payload : any) => ({type: ActionTypes.DISABLE_VISUAL_CHOICE_IF_CHOSEN, payload}),

    updateClothes: (payload : any) => ({type: ActionTypes.UPDATE_CLOTHES, payload}),
    updateAccessories: (payload : any) => ({type: ActionTypes.UPDATE_ACCESSORIES, payload}),
    updateHairstyles: (payload : any) => ({type: ActionTypes.UPDATE_HAIRSTYLE, payload}),

    setLinesFrame: (payload : any) => ({ type: ActionTypes.SET_LINES_FRAME, payload}),
    setEpisodeSummary: (payload :any) => ({ type: ActionTypes.SET_EPISODE_SUMMARY, payload}),

    setAnswerNumber: (payload : any) => ({type: ActionTypes.SET_ANSWER_NUMBER, payload}),
    setIsRenderedParam: (payload : any) => ({type: ActionTypes.SET_ISRENDERED_PARAM, payload}),
    addCharactersInLine: (payload : any) => ({type: ActionTypes.ADD_CHARACTERS_IN_LINE, payload}),
    setEpisodeStates: (payload : any) => ({type: ActionTypes.SET_EPISODE_STATES, payload}),
    updateCommentResolvedState: (payload : any) => ({type: ActionTypes.UPDATE_COMMENT_RESOLVED_STATE, payload}),
    updateCommentState: (payload : any) => ({type: ActionTypes.UPDATE_COMMENT_STATE, payload}),
    findTextFilter: (payload : any) => ({type: ActionTypes.FIND_TEXT_FILTER, payload}),
    updateLineAndPropagateActions: (payload: any) => ({ type: ActionTypes.UPDATE_LINE_AND_PROPAGATE_ACTIONS, payload}),
    updateLineOptions: (payload: any) => ({ type: ActionTypes.UPDATE_LINES_OPTIONS, payload}),
    updateActionChangeFlagOnDelete: (payload : any) => ({type: ActionTypes.UPDATE_ACTION_CHANGE_FLAG_ON_DELETE, payload}),

    updateCLineClothes1: (payload : any) => ({type: ActionTypes.UPDATE_C_LINE_CLOTHES_1, payload}),
    updateCLineClothes2: (payload : any) => ({type: ActionTypes.UPDATE_C_LINE_CLOTHES_2, payload}),
    updateCLineClothes3: (payload : any) => ({type: ActionTypes.UPDATE_C_LINE_CLOTHES_3, payload}),

    updateEpisodeDescription: (payload : any) => ({type: ActionTypes.UPDATE_EPISODE_DESCRIPTION, payload}),

    setChangeOutfitTypes: (payload : any) => ({type: ActionTypes.SET_CHANGE_OUTFIT_TYPES, payload}),

    setCinematics: (payload : any) => ({type: ActionTypes.SET_CINEMATICS, payload}),
    addCinematics: (payload : any) => ({type: ActionTypes.ADD_CINEMATIC, payload}),
    populateELines: (payload : any) => ({type: ActionTypes.POPULATE_E_LINES, payload}),

    setPickedColors: (payload: any) => ({type: ActionTypes.SET_PICKED_COLORS, payload}),
    setPickedActionColor: (payload : any) => ({type: ActionTypes.SET_PICKED_ACTION_COLOR, payload}),

    setNumberOfAddedCommentsInSession: () => ({type: ActionTypes.SET_NUMBER_OF_ADDED_COMMENTS_IN_SESSION})
}

export default function editEpisodeReducer(state=initialState, action: any)
{    
    let newLines, linesFrame, linesStartIndex, newLinesLength, linesToAdd, currentLineNew, lastLines, onlyLines, selectedLineIndex, maxLineNumber;
    let clickedLineIndex: number, index: number;
    let addedLineIndex = -1;
    
    switch(action.type)
    {
        case ActionTypes.SET_UPDATE_CLOUD_BOOL:
            return {...state, updateCloudBool: action.payload, isRenderedByAction: true}

        case ActionTypes.SET_LINES:
            return {...state, lines: [...action.payload.lines], maxLineNumber: action.payload.maxLineNumber, isRenderedByAction: false};
        case ActionTypes.SET_LINES_FRAME:
            linesFrame = action.payload
            linesStartIndex = state.selectedLineIndex + 1 > linesFrame ? state.selectedLineIndex + 1 - linesFrame : (state.selectedLineIndex === -1 && state.lines.length + 1 > linesFrame ? state.lines.length - linesFrame + 1 : 0)
            return {...state, linesFrame, linesStartIndex, isRenderedByAction: false }
        case ActionTypes.SET_CLOTHES:
            return {...state, clothes: [...action.payload], isRenderedByAction: true};
        case ActionTypes.SET_ACCESSORIES:
            return {...state, accessories: [...action.payload], isRenderedByAction: true};
        case ActionTypes.SET_ACCESSORY_TYPES:
            return {...state, accessoryTypes: [...action.payload], isRenderByAction: true};
        case ActionTypes.SET_HAIRSTYLES:
            return {...state, hairstyles: [...action.payload], isRenderedByAction: true};

        case ActionTypes.SET_STORY_ITEMS:
            return {...state, storyItems: [...action.payload], isRenderedByAction: false}
        case ActionTypes.ADD_STORY_ITEM:
            return {...state, storyItems: [...state.storyItems, action.payload], isRenderedByAction: false}

        case ActionTypes.ADD_CLOTHES:
            return {...state, clothes: [...state.clothes, action.payload], isRenderedByAction: true}
        case ActionTypes.ADD_ACCESSORIES:
            return {...state, accessories: [...state.accessories, action.payload], isRenderedByAction: true}
        case ActionTypes.ADD_HAIRSTYLES:
            return {...state, hairstyles: [...state.hairstyles, action.payload], isRenderedByAction: true}

        case ActionTypes.UPDATE_CLOTHES:
            return { ...state, clothes: state.clothes.map((content: any, i : any) => content.value === action.payload.value ? {...content, 
                id: action.payload.id,
                value: action.payload.value,
                label: action.payload.label
            } : content ), isRenderedByAction: true }
        case ActionTypes.UPDATE_ACCESSORIES:
            return { ...state, accessories: state.accessories.map((content: any, i : any) => content.value === action.payload.value ? {...content, id: action.payload.id} : content ), isRenderedByAction: true }
        case ActionTypes.UPDATE_HAIRSTYLE:
            return { ...state, hairstyles: state.hairstyles.map((content: any, i : any) => content.value === action.payload.value ? {...content, id: action.payload.id} : content ), isRenderedByAction: true }

        case ActionTypes.SET_CHARACTES:
            return {...state, characters: [...action.payload], isRenderedByAction: false};
        case ActionTypes.SET_CHARACTERS_FOR_OUTFIT_CHANGE:
            return {...state, charactersChangeOufit: [...action.payload], isRenderedByAction: false};

        case ActionTypes.ADD_CHARACTERS:
            let characterExists = state.characters?.find((content : any) => action.payload.id == content.id);
            if(!characterExists) return {...state, characters: [...state.characters, action.payload], isRenderedByAction: true };
            return state;
        case ActionTypes.ADD_CHARACTERS_FOR_OUTFIT_CHANGE:
            let characterOutfitChangeExists = state.charactersChangeOufit?.find((content : any) => action.payload.id == content.id);
            if(!characterOutfitChangeExists) return {...state, charactersChangeOufit: [...state.charactersChangeOufit, action.payload], isRenderedByAction: true };
            return state;

        case ActionTypes.SET_ANIMATIONS:
            return {...state, animations: [...action.payload], isRenderedByAction: false};
        case ActionTypes.ADD_ANIMATIONS:
            let animationExists = state.animations?.find((content : any) => action.payload.id == content.id);
            if(!animationExists) return {...state, animations: [...state.animations, action.payload], isRenderedByAction: true };
            return state;
        case ActionTypes.SET_BACKGROUNDS:
            return {...state, backgrounds: [...action.payload], isRenderedByAction: false};

        case ActionTypes.ADD_BACKGROUNDS:
            let backgroundExists = state.backgrounds?.find((content : any) => action.payload.id == content.id);
            if(!backgroundExists) return {...state, backgrounds: [...state.backgrounds, action.payload], isRenderedByAction: true }
            return state;
        case ActionTypes.SET_MUSICS:
            return {...state, musics: [...action.payload], isRenderedByAction: false};

        case ActionTypes.ADD_MUSICS:
            let musicExists = state.musics?.find((content : any) => action.payload.id == content.id);
            if(!musicExists) return {...state, musics: [...state.musics, action.payload], isRenderedByAction: true };
            return state;
        case ActionTypes.SET_SHORT_SOUNDS:
            return {...state, shortSounds: [...action.payload], isRenderedByAction: false};

        case ActionTypes.ADD_SHORT_SOUNDS:
            let shortSoundExists = state.shortSounds?.find((content : any) => action.payload.id == content.id);
            if(!shortSoundExists) return {...state, shortSounds: [...state.shortSounds, action.payload], isRenderedByAction: true }
            return state;

        case ActionTypes.ADD_VISUAL_ANSWERS:
            let visualAnswersExits = state.defaultVisualChoices?.find((content : any) => action.payload.id == content.id);
            if(!visualAnswersExits) return {...state, defaultVisualChoices: [...state.defaultVisualChoices, action.payload], isRenderedByAction: true }
            return state;

        case ActionTypes.ADD_NEW_LINE: 
            linesFrame = state.linesFrame;
            clickedLineIndex = action.payload.clickedLineIndex; 
            newLinesLength = 1;       
            maxLineNumber = state.maxLineNumber;
            if(clickedLineIndex != null) {
                if(action.payload.modalOpen && action.payload.modalType == 'above')
                {
                    addedLineIndex = clickedLineIndex; 
                }
                else
                {
                    addedLineIndex = clickedLineIndex + 1; 
                }
            }
            
            if(state.lines[0].isDefault) {
                newLines = [action.payload.newLine];
            } 
            else if (addedLineIndex === -1) { 
                newLines = [...state.lines, action.payload.newLine];
            }
            else {
                newLines = state.lines.map(value => Object.assign({}, value));
                let incrementedLines = [action.payload.newLine, ...newLines.slice(addedLineIndex)];
                newLines.splice(addedLineIndex, incrementedLines.length - 1, ...incrementedLines);
            }

            maxLineNumber = generateLineNumbers(newLines, 0, 0, newLines);
            newLines = generateTAnumbers(newLines);
            newLines = generateQAnumbers(newLines);
            newLines = generateVAnumbers(newLines);
            newLines = generateBAnumbers(newLines);

            selectedLineIndex = state.selectedLineIndex;
            if(selectedLineIndex != -1 && selectedLineIndex >= addedLineIndex) selectedLineIndex++;

            linesStartIndex = selectedLineIndex + newLinesLength > linesFrame ? selectedLineIndex + newLinesLength - linesFrame : (selectedLineIndex === -1 && newLines.length + newLinesLength > linesFrame ? newLines.length + newLinesLength - linesFrame : 0);
            
            let newCurrentLine = { ...newLines[selectedLineIndex === -1 ? newLines.length - 1 : selectedLineIndex]}

            if(clickedLineIndex == null || selectedLineIndex === -1) newCurrentLine.text = undefined;
            if(!newCurrentLine.shortSoundEnabled)
            {
                newCurrentLine.shortSoundId = null;
                newCurrentLine.shortSoundPath = null;
                newCurrentLine.shortSoundName = null;
                newCurrentLine.shortSoundDescription = null;
            }           

            if(!newCurrentLine.avatarEnabled)
            {
                newCurrentLine.avatarEnabled = true;
            }

            newCurrentLine.charactersOutfit = newCurrentLine.charactersOutfit?.map((value : any) => !value.accessoryEnabled ? {...value, accessoryId: null, accessoryName: null, accessoryDescription: null, accessoryType: 0} : value);

            if(newCurrentLine.lineFormat == 2 || newCurrentLine.lineFormat == 3)
            {
                newCurrentLine.lineFormat = 0;
            }

            if(newCurrentLine.changeIdentityOn)
            {
                newCurrentLine.changeIdentityOn = false;
                newCurrentLine.changeIdentityName = null;
            }

            return { ...state, lines: newLines, linesSliceParam: selectedLineIndex != -1 ? selectedLineIndex : newLines.length, currentLine: {...newCurrentLine, 
                premiumChoiceText: undefined, 
                premiumChoiceDescription: undefined, 
                charmingPoint: undefined,
                itemId: undefined,
                itemName: undefined,
                itemDescription: undefined
            }, maxLineNumber, selectedLineIndex, linesStartIndex, isRenderedByAction: false };
        case ActionTypes.ADD_NEW_LINES: 
            clickedLineIndex = action.payload.clickedLineIndex;
            newLines = action.payload.newLines;
            newLinesLength = newLines.length;
            linesFrame = state.linesFrame;
            if(clickedLineIndex != null) { 
                if(action.payload.isOpen && action.payload.type == 'above')
                {
                    addedLineIndex = clickedLineIndex;
                }
                else
                { 
                    addedLineIndex = clickedLineIndex + 1;
                }
            }
            
            if(state.lines[0].isDefault) 
            { 
                linesToAdd = newLines;
            }
            else if (addedLineIndex === -1) {
                linesToAdd = state.lines.concat(newLines);
            }
            else {                
                linesToAdd = state.lines.map(value => Object.assign({}, value));
                let restOfTheArray = linesToAdd.slice(addedLineIndex);
                let restOfTheArrayLength = restOfTheArray.length;
                let incrementedLines = newLines.concat(restOfTheArray);
                linesToAdd.splice(addedLineIndex, restOfTheArrayLength, ...incrementedLines);
            }

            selectedLineIndex = state.selectedLineIndex;
            if(addedLineIndex !== -1) {
                let potentialSelectedLineIndex = addedLineIndex + newLinesLength;
                if(potentialSelectedLineIndex > linesToAdd.length - 1)
                    selectedLineIndex = -1
                else if(selectedLineIndex != -1) {
                    if(potentialSelectedLineIndex > selectedLineIndex)
                        selectedLineIndex = potentialSelectedLineIndex
                    else { 
                        selectedLineIndex = selectedLineIndex + newLinesLength
                    }
                }
            }
            linesStartIndex = selectedLineIndex + 1 > linesFrame ? selectedLineIndex + 1 - linesFrame : (selectedLineIndex === -1 && linesToAdd.length + 1 > linesFrame ? linesToAdd.length - linesFrame + 1 : 0)
            //O types
            let isOLineAdded = state.isOLineAdded;
            if(!isOLineAdded) {
                if([1,2,3].includes(linesToAdd[addedLineIndex === -1 ? linesToAdd.length - 1 : addedLineIndex].lineType)) {
                    isOLineAdded = true
                }
            }
            currentLineNew = linesToAdd[selectedLineIndex === -1 ? linesToAdd.length - 1 : selectedLineIndex];
            if(state.selectedLineIndex !== -1 && selectedLineIndex === -1) currentLineNew.text = undefined;
            
            if(!currentLineNew.shortSoundEnabled)
            {
                currentLineNew.shortSoundId = null;
                currentLineNew.shortSoundPath = null;
                currentLineNew.shortSoundName = null;
                currentLineNew.shortSoundDescription = null;
            }
            
            if(!currentLineNew.avatarEnabled)
            {
                currentLineNew.avatarEnabled = true;
            }
            
            if(currentLineNew.changeIdentityOn)
            {
                currentLineNew.changeIdentityOn = false;
                currentLineNew.changeIdentityName = null;
            }
            
            currentLineNew.charactersOutfit = currentLineNew.charactersOutfit?.map((value : any) => !value.accessoryEnabled ? {...value, accessoryId: null, accessoryName: null, accessoryDescription: null, accessoryType: 0} : value);

            let lineType =  [1,2,3].includes(currentLineNew.lineType) ? 0 : currentLineNew.lineType;   
            maxLineNumber = generateLineNumbers(linesToAdd, 0, 0, linesToAdd);
            linesToAdd = generateTAnumbers(linesToAdd);
            linesToAdd = generateQAnumbers(linesToAdd);
            linesToAdd = generateVAnumbers(linesToAdd);
            linesToAdd = generateBAnumbers(linesToAdd); 

            return { ...state, 
                lines: linesToAdd, linesSliceParam: selectedLineIndex != -1 ? selectedLineIndex : linesToAdd.length,
                currentLine: {...currentLineNew, 
                    premiumChoiceText: undefined, 
                    premiumChoiceDescription: undefined, 
                    charmingPoint: undefined, 
                    itemId: undefined,
                    itemName: undefined,
                    itemDescription: undefined,
                    text: currentLineNew.lineType == 10 || currentLineNew.lineType == 14 ? undefined : currentLineNew.text},
                maxLineNumber, selectedLineIndex, linesStartIndex, lineType, isOLineAdded, isRenderedByAction: false };      
        case ActionTypes.SET_EDIT_EPISODE_PASS_PARAM:
            return {
                ...state, 
                editEpisodePassParam: action.payload, 
                episodeSummary: {...action.payload.episodeSummary}, 
                isOLineAdded: action.payload.isOLineAdded, 
                isRenderedByAction: true
            };
        case ActionTypes.SET_CURRENT_LINE:
            var currentLineWithProperLineType = action.payload.currentLine;
            if([LineTypes.A, LineTypes.BA, LineTypes.TA, LineTypes.VA].includes(action.payload.currentLine.lineType ?? 0) && action.payload.currentLineIndex == -1)
            {
                currentLineWithProperLineType.lineType = LineTypes.L;
            }
            return {...state, 
                currentLine: currentLineWithProperLineType, 
                selectedLineIndex: action.payload.currentLineIndex, 
                linesStartIndex: action.payload.linesStartIndex, isRenderedByAction: false, linesSliceParam: action.payload.linesSliceParam }
        case ActionTypes.SET_SELECTED_LINE_INDEX:
            return {...state, selectedLineIndex: action.payload, isRenderedByAction: true}
        case ActionTypes.SET_DEFAULT_LINE:
            return {
                ...state, 
                lines: [defaultLine], 
                linesStartIndex: 0,
                currentLine: { 
                    ...defaultLine, 
                    storyId: action.payload.storyId, 
                    storyTranslationId: action.payload.storyTranslationId,
                    episodeId: action.payload.episodeId
                },
                selectedLineIndex: -1, 
                isRenderedByAction: false,
                maxLineNumber: 0
            }
        case ActionTypes.SET_INIT_EDIT_EPISODE_BODY:
            return {...state, initEditEpisodeBody: action.payload, isRenderedByAction: false }

        case ActionTypes.UPDATE_AVATAR_BOOL:
            let lineFormat = state.currentLine.lineFormat;
            let emotion = state.currentLine.emotion;
            if(!action.payload) {
                lineFormat = 0;
                emotion = 0;
            }
            return {...state, currentLine: { ...state.currentLine, avatarEnabled: action.payload, lineFormat, emotion }, isRenderedByAction: true }
        case ActionTypes.UPDATE_SHORTSOUND_BOOL:
            return {...state, currentLine: { ...state.currentLine, shortSoundEnabled: action.payload }, isRenderedByAction: true }

        case ActionTypes.UPDATE_ACCESSORY_BOOL:
            return {...state, currentLine: { ...state.currentLine, accessoryEnabled: action.payload }, isRenderedByAction: true }
        case ActionTypes.UPDATE_PHONE_BOOL:
            return {...state, currentLine: { ...state.currentLine, callPhoneEnabled: action.payload }, isRenderedByAction: true }
        case ActionTypes.UPDATE_CALLER_NAME:
            return {...state, currentLine: { ...state.currentLine, callerCharacterId: action.payload.id, callerCharacterName: action.payload.label }, isRenderedByAction: true }
        case ActionTypes.UPDATE_SMS_BOOL:
            return {...state, currentLine: { ...state.currentLine, callSmsEnabled: action.payload }, isRenderedByAction: true }   
        case ActionTypes.UPDATE_CHARACTER_IDENTITY_ENABLED:
            return {...state, currentLine: { ...state.currentLine, changeIdentityOn: action.payload }, isRenderedByAction: true }
        case ActionTypes.UPDATE_CHARACTER_IDENTITY_NAME:
            return {...state, currentLine: { ...state.currentLine, changeIdentityName: action.payload }, isRenderedByAction: true }

        case ActionTypes.UPDATE_CHARMING_POINT:
            return {...state, currentLine: { ...state.currentLine, charmingPoint: action.payload }, isRenderedByAction: true }
        case ActionTypes.UPDATE_PREMIUM_CHOICE_TEXT:
            return {...state, currentLine: { ...state.currentLine, premiumChoiceText: action.payload }, isRenderedByAction: true }
        case ActionTypes.UPDATE_PREMIUM_CHOICE_DESCRIPTION:
            return {...state, currentLine: { ...state.currentLine, premiumChoiceDescription: action.payload }, isRenderedByAction: true }

        case ActionTypes.UPDATE_CLOTHES1_PARAM:
            return {...state, currentLine: { ...state.currentLine, clothes1Id: action.payload.id, clothes1Name: action.payload.label }}
        case ActionTypes.UPDATE_CLOTHES2_PARAM:
            return {...state, currentLine: { ...state.currentLine, clothes2Id: action.payload.id, clothes2Name: action.payload.label }}
        case ActionTypes.UPDATE_CLOTHES3_PARAM:
            return {...state, currentLine: { ...state.currentLine, clothes3Id: action.payload.id, clothes3Name: action.payload.label }}

        case ActionTypes.UPDATE_HAIRSTYLE1_PARAM:
            return {...state, currentLine: { ...state.currentLine, hairstyle1Id: action.payload.id, hairstyle1Name: action.payload.label }}
        case ActionTypes.UPDATE_HAIRSTYLE2_PARAM:
            return {...state, currentLine: { ...state.currentLine, hairstyle2Id: action.payload.id, hairstyle2Name: action.payload.label }}
        case ActionTypes.UPDATE_HAIRSTYLE3_PARAM:
            return {...state, currentLine: { ...state.currentLine, hairstyle3Id: action.payload.id, hairstyle3Name: action.payload.label }}  
            
        case ActionTypes.UPDATE_VISUAL_PARAM:
            return {...state, currentLine: { ...state.currentLine, visualId: action.payload.id, visualName: action.payload.label }}  

        case ActionTypes.UPDATE_BACKGROUND_PARAM:
            return {...state, currentLine: { ...state.currentLine, backgroundId: action.payload.id, backgroundName: action.payload.label }, isRenderedByAction: true }
        case ActionTypes.UPDATE_MUSICTHEME_PARAM:
            return {...state, currentLine: { ...state.currentLine, musicId: action.payload.id, musicName: action.payload.label }, isRenderedByAction: true }
        case ActionTypes.UPDATE_SHORTSOUND_PARAM:
            return {...state, currentLine: { ...state.currentLine, shortSoundId: action.payload.id, shortSoundName: action.payload.label }, isRenderedByAction: true }
        case ActionTypes.UPDATE_CLOTHES_PARAM:
            return {...state, currentLine: { ...state.currentLine, clothesId: action.payload.id, clothesName: action.payload.label, clothesDescription: action.payload.description }, isRenderedByAction: true }
        case ActionTypes.UPDATE_HAIRSTYLE_PARAM:
            return {...state, currentLine: { ...state.currentLine, hairstyleId: action.payload.id, hairstyleName: action.payload.label, hairstyleDescription: action.payload.description }, isRenderedByAction: true }
        case ActionTypes.UPDATE_ACCESSORY_PARAM:
            return {...state, currentLine: { ...state.currentLine, accessoryId: action.payload.id, accessoryName: action.payload.label, accessoryDescription: action.payload.description }, isRenderedByAction: true }
        case ActionTypes.UPDATE_CHARACTER_PARAM:
            return { ...state, currentLine: { ...state.currentLine, characterId: action.payload.id, characterName: action.payload.label, characterPath: action.payload.path }, isRenderedByAction: true }
        case ActionTypes.UPDATE_ANIMATION_PARAM:
            return { ...state, currentLine: { ...state.currentLine, animationId: action.payload.id, animationName: action.payload.label}, isRenderedByAction: true  }
        case ActionTypes.UPDATE_EMOTION_PARAM:
            return { ...state, currentLine: { ...state.currentLine, emotion: action.payload }, isRenderedByAction: true  }
        case ActionTypes.UPDATE_GJUMPTO_PARAM:
            return { ...state, currentLine: {...state.currentLine, g_JumpTo: action.payload}, isRenderedByAction: true }
        case ActionTypes.UPDATE_TEXT_PARAM:
                return { ...state, currentLine: { ...state.currentLine, text: action.payload }, isRenderedByAction: true  }
        case ActionTypes.UPDATE_LINEFORMAT_PARAM:
            return { ...state, currentLine: { ...state.currentLine, lineFormat: action.payload }, isRenderedByAction: true  }
        case ActionTypes.UPDATE_LINETYPE_PARAM:
            return { ...state, currentLine: { ...state.currentLine, lineType: action.payload, text: undefined }, isRenderedByAction: true  }
        case ActionTypes.UPDATE_TEXT:
            return { ...state, currentLine: { ...state.currentLine, text: action.payload }, isRenderedByAction: true  }
        case ActionTypes.UPDATE_BRANCH_QandCId:
            return { 
                ...state, 
                currentLine: { 
                    ...state.currentLine, 
                    branchType: action.payload.type, 
                    branchValue: action.payload.value, 
                    text: action.payload.text,
                    srcEpisodeId: action.payload.srcEpisodeId,
                    srcEpisodeSerialNumber: action.payload.srcEpisodeSerialNumber
                }, 
                isRenderedByAction: true  
            }
        case ActionTypes.UPDATE_BACKGROUND_DESCRIPTION:
            return {...state, currentLine: { ...state.currentLine, backgroundDescription: action.payload }, isRenderedByAction: true }

        case ActionTypes.UPDATE_MUSICTHEME_DESCRIPTION:
            return {...state, currentLine: { ...state.currentLine, musicDescription: action.payload }, isRenderedByAction: true }

        case ActionTypes.UPDATE_SHORTSOUND_DESCRIPTION:
            return {...state, currentLine: { ...state.currentLine, shortSoundDescription: action.payload }, isRenderedByAction: true }

        case ActionTypes.UPDATE_CLOTHES_DESCRIPTION:
            return {...state, currentLine: { ...state.currentLine, clothesDescription: action.payload }, isRenderedByAction: true }

        case ActionTypes.UPDATE_HAIRSTYLE_DESCRIPTION:
            return {...state, currentLine: { ...state.currentLine, hairstyleDescription: action.payload }, isRenderedByAction: true }

        case ActionTypes.UPDATE_ACCESSORY_DESCRIPTION:
            return {...state, currentLine: { ...state.currentLine, accessoryDescription: action.payload }, isRenderedByAction: true }
        
        case ActionTypes.UPDATE_CHARACTERS_OUTFIT:
            return { ...state, currentLine: { ...state.currentLine, charactersOutfit: [...state.currentLine.charactersOutfit, action.payload ]}, isRenderedByAction: true }
        case ActionTypes.UPDATE_SPECIFIC_CHARACTER_OUTFIT:
            return { ...state, currentLine: { ...state.currentLine, charactersOutfit: state.currentLine.charactersOutfit.map((content : any, i : any) => 
            content.characterId === action.payload.characterId ? {...content, 
                    characterId: action.payload.characterId,
                    characterName: action.payload.characterName,
                    clothesId: action.payload.clothesId,
                    clothesName: action.payload.clothesName,
                    clothesDescription: action.payload.clothesDescription,
                    hairstyleId: action.payload.hairstyleId,
                    hairstyleName: action.payload.hairstyleName,
                    hairstyleDescription: action.payload.hairstyleDescription,
                    accessoryEnabled: action.payload.accessoryEnabled,
                    accessoryId: action.payload.accessoryId,
                    accessoryName: action.payload.accessoryName,
                    accessoryDescription: action.payload.accessoryDescription,
                    accessoryType: action.payload.accessoryType,
                    changeOutfitType: action.payload.changeOutfitType
            } : content )}
            , isRenderedByAction: true}
        
        case ActionTypes.REMOVE_CHARACTERS_OUTFIT:
            return { 
                ...state, 
                currentLine: { ...state.currentLine, charactersOutfit: [...state.currentLine.charactersOutfit.filter((item : any) => item.characterId !== action.payload) ]}
                , isRenderedByAction: true
            }
        case ActionTypes.CLEAR_CHARACTERS_OUTFIT:
            return { 
                ...state, 
                currentLine: { ...state.currentLine, charactersOutfit: []}
                , isRenderedByAction: true
            }
        case ActionTypes.REPLACE_CHARACTERS_OUTFIT:
            return { 
                ...state, 
                currentLine: { ...state.currentLine, charactersOutfit: [...action.payload]}
                , isRenderedByAction: true
            }
        case ActionTypes.REMOVE_LINE:
            index = action.payload.clickedLineIndex;
            let lastLine = state.lines.length - 1 == index;
            let onlyLine = state.lines.length === 1;
            newLines = onlyLine 
            ? [{
                ...defaultLine, 
                storyId: state.lines[index].storyId, 
                storyTranslationId: state.lines[index].storyTranslationId,
                episodeId: state.lines[index].episodeId,
                text: state.selectedLineIndex === -1 ? (state.currentLine.text === state.lines[state.lines.length-1].text ? "" : state.currentLine.text) : ""
            }] 
            : [...state.lines.filter((item : any, i: number) => i !== index).map((element : any, i : any) => 
                {
                    let returnEl = element;
                    if(i >= index) 
                    { 
                        if(state.lines[index].lineType !== 5) {
                            if(state.lines[i].lineType !== 11) {
                                returnEl = {...returnEl, lineNumber: element.lineNumber - 1 }
                            }
                        }
                        else {
                            if(returnEl.answerQuestionId == state.lines[index].answerQuestionId)
                                returnEl = {...returnEl, answerNumber: returnEl.answerNumber - 1 }
                        }
                    }
                    return returnEl;
                })]
            selectedLineIndex = newLines[0].isDefault ? -1 : (state.selectedLineIndex === -1 ? state.selectedLineIndex : state.selectedLineIndex - 1)
            maxLineNumber = generateLineNumbers(newLines,0,0,newLines);
            newLines = generateQAnumbers(newLines);
            newLines = generateTAnumbers(newLines);
            newLines = generateBAnumbers(newLines);
            return { ...state, 
            currentLine: onlyLine 
            ? {
                ...defaultLine,                 
                storyId: state.lines[index].storyId, 
                storyTranslationId: state.lines[index].storyTranslationId,
                episodeId: state.lines[index].episodeId,
                text: state.selectedLineIndex === -1 ? (state.currentLine.text === newLines[newLines.length-1].text ? "" : state.currentLine.text) : ""
            } 
            : (lastLine ? { ...newLines[index - 1], text: '' } : selectedLineIndex === -1 ? { ...newLines[newLines.length - 1], text: '' } : {...newLines[selectedLineIndex]}), 
            lines: newLines, 
            linesStartIndex: selectedLineIndex + 1 > state.linesFrame ? selectedLineIndex + 1 - state.linesFrame  : (selectedLineIndex === -1 && newLines.length + 1 > state.linesFrame ? newLines.length - state.linesFrame + 1 : 0),
            selectedLineIndex,
            maxLineNumber,
            isRenderedByAction: false
        }
        case ActionTypes.REMOVE_LINES:    
            let linesToDelete = action.payload.lines;
            index = action.payload.index
            lastLines = state.lines.length - linesToDelete.length === index;
            onlyLines = state.lines.length === linesToDelete.length;
            let indexesArray = Array.from({length: linesToDelete.length}, (_, i) => i + index);
            newLines = onlyLines 
            ? [{
                ...defaultLine, 
                storyId: state.lines[index].storyId, 
                storyTranslationId: state.lines[index].storyTranslationId,
                episodeId: state.lines[index].episodeId,
                text: state.selectedLineIndex === -1 ? (state.currentLine.text === state.lines[state.lines.length-1].text ? "" : state.currentLine.text) : ""
            }]
            : state.lines.map(x => Object.assign({}, x))
                        .filter((x, i) =>{ return !indexesArray.includes(i)})
                        ?.map((x,i) => { 
                            if(i>=index) { x.lineNumber = x.lineNumber - 1 - (linesToDelete[linesToDelete.length - 1].lineNumber - linesToDelete[0].lineNumber); } 
                            return x; 
                        })
            selectedLineIndex = newLines[0].isDefault ? -1 : (state.selectedLineIndex === -1 ? state.selectedLineIndex : state.selectedLineIndex - linesToDelete.length)
            maxLineNumber = generateLineNumbers(newLines,0,0,newLines);
            newLines = generateQAnumbers(newLines);
            newLines = generateTAnumbers(newLines);
            newLines = generateBAnumbers(newLines);
            return { ...state, 
            currentLine: onlyLines 
            ? {
                ...defaultLine,                 
                storyId: state.lines[index].storyId, 
                storyTranslationId: state.lines[index].storyTranslationId,
                episodeId: state.lines[index].episodeId,
                text: state.selectedLineIndex === -1 ? (state.currentLine.text === newLines[newLines.length-1].text ? "" : state.currentLine.text) : ""
            }  
            : (lastLines ? { ...newLines[index - 1], text: '' } : newLines[selectedLineIndex === -1 ? newLines.length - 1 : selectedLineIndex]), 
            lines: newLines,
            linesStartIndex: selectedLineIndex + 1 > state.linesFrame ? selectedLineIndex + 1 - state.linesFrame  : (selectedLineIndex === -1 && newLines.length + 1 > state.linesFrame ? newLines.length - state.linesFrame + 1 : 0),
            selectedLineIndex,
            isOLineAdded: action.payload.isOLineAdded ?? state.isOLineAdded,
            maxLineNumber,
            isRenderedByAction: false
        }
        case ActionTypes.UPDATE_LINE:
            let currentLineIndex = action.payload.currentLineIndex;
            let currentLine = action.payload.currentLine;
            newLines = state.lines.map(value => Object.assign({}, value));
            newLines[currentLineIndex] = currentLine;
            if(currentLine.lineType === 4) {
                while(newLines[++currentLineIndex]) {
                    if(newLines[currentLineIndex].depth > currentLine.depth) continue;
                    if(newLines[currentLineIndex].lineType !== 5 || newLines[currentLineIndex].depth < currentLine.depth) break;
                    newLines[currentLineIndex].answerQuestionId = currentLine.docId;
                }
            }
            if(action.payload.setCurrentLine === false)
                return { ...state, lines: newLines, isRenderedByAction: true}
            return { ...state, lines: newLines, currentLine, isRenderedByAction: true}
        case ActionTypes.SET_DEFAULT_LINE_TYPES:
            return { ...state, defaultLineTypes: [...action.payload], isRenderedByAction: false }
        case ActionTypes.SET_DEFAULT_LINE_FORMATS:
            return { ...state, defaultLineFormats: [...action.payload], isRenderedByAction: false }
        case ActionTypes.SET_DEFAULT_EMOTIONS:
            return { ...state, defaultEmotions: [...action.payload], isRenderedByAction: false }
        case ActionTypes.SET_DEFAULT_SKIN_COLORS:
            return { ...state, defaultSkinColors: [...action.payload], isRenderedByAction: false }
        case ActionTypes.SET_DEFAULT_BRANCH_CandQ:
            return { ...state, defaultBranchQandC: [...action.payload ?? []], isRenderedByAction: false }
        case ActionTypes.SET_DEFAULT_VISUAL_CHOICES:
            return { ...state, defaultVisualChoices: [...action.payload ?? []], isRenderedByAction: false }
        case ActionTypes.SET_DEFAULT_CLOTHING_COMBINATIONS:
            return { ...state, defaultClothingCombinations: [...action.payload ?? []], isRenderedByAction: false}
        case ActionTypes.DISABLE_VISUAL_CHOICE_IF_CHOSEN:
            var lineBlock = state.lines.filter((element : any) => element.answerVisualChoiceId == action.payload.answerVisualChoiceId);
            if(lineBlock.length > 0)
            {
                var filteredArray = state.defaultVisualChoices.map((element : any) => element?.isDisabled ? {...element, isDisabled: false} : element)
                var arrayToMap = filteredArray.map((element : any) => lineBlock.find((item : any) => item.visualId == element.id) 
                    ? {...element, isDisabled: true} : element) 

                return { ...state, defaultVisualChoices: arrayToMap}
            }
            return state;
        case ActionTypes.UPDATE_ACTION_CHANGE_FLAG:
            return { ...state, 
                lines: state.lines.map((content: any, i : any) => i === action.payload.currentLineIndex ? {...content, actionChangeFlag: action.payload.returnValue} : content ) 
                , isRenderedByAction: true
            }

        case ActionTypes.SET_PREVIOUS_DEFAULT_STATE:
            return { ...state, previousStateActions: action.payload, isRenderedByAction: true}
        case ActionTypes.SET_AVATAR_STATE:
            return { ...state, previousStateActions: {...state.previousStateActions, avatarEnabled: action.payload }, isRenderedByAction: true }
        case ActionTypes.SET_BACKGROUND_STATE:
            return { ...state, previousStateActions: {...state.previousStateActions, backgroundId: action.payload.backgroundId, backgroundName: action.payload.backgroundName}, isRenderedByAction: true }
        case ActionTypes.SET_BACKGROUND_DESCRIPTION_STATE:
            return { ...state, previousStateActions: {...state.previousStateActions, backgroundDescription: action.payload}, isRenderedByAction: true }
        case ActionTypes.SET_MUSIC_STATE:
            return { ...state, previousStateActions: {...state.previousStateActions, musicId: action.payload.musicId, musicName: action.payload.musicName}, isRenderedByAction: true }
        case ActionTypes.SET_MUSIC_DESCRIPTION_STATE:
            return { ...state, previousStateActions: {...state.previousStateActions, musicDescription: action.payload}, isRenderedByAction: true }

        case ActionTypes.SET_SHORT_SOUND_STATE:
            return { ...state, previousStateActions: {...state.previousStateActions, shortSoundId: action.payload.shortSoundId, shortSoundName: action.payload.shortSoundName}, isRenderedByAction: true }
        case ActionTypes.SET_SHORT_SOUND_DESCRIPTION_STATE:
            return { ...state, previousStateActions: {...state.previousStateActions, shortSoundDescription: action.payload}, isRenderedByAction: true}
        case ActionTypes.SET_SHORT_SOUND_ENABLED_STATE:
            return { ...state, previousStateActions: {...state.previousStateActions, shortSoundEnabled: action.payload}, isRenderedByAction: true}
            
        case ActionTypes.SET_SHOW_ITEM_STATE:
            return { ...state, previousStateActions: {...state.previousStateActions, itemId: action.payload.itemId, itemName: action.payload.itemName}, isRenderedByAction: true }
        case ActionTypes.SET_SHOW_ITEM_DESCRIPTION_STATE:
            return { ...state, previousStateActions: {...state.previousStateActions, itemDescription: action.payload}, isRenderedByAction: true}

        case ActionTypes.SET_CHARMING_POINT:
            return { ...state, previousStateActions: {...state.previousStateActions, charmingPoint: action.payload}, isRenderedByAction: true}
        case ActionTypes.SET_PREMIUM_CHOICE_STATE:
            return { ...state, previousStateActions: {...state.previousStateActions, premiumChoiceText: action.payload}, isRenderedByAction: true}
        case ActionTypes.SET_PREMIUM_CHOICE_DESCRIPTION_STATE:
            return { ...state, previousStateActions: {...state.previousStateActions, premiumChoiceDescription: action.payload}, isRenderedByAction: true}
        case ActionTypes.SET_CALL_PHONE_STATE:
            return { ...state, previousStateActions: {...state.previousStateActions, callPhoneEnabled: action.payload}, isRenderedByAction: true}
        case ActionTypes.SET_CALL_SMS_STATE:
            return { ...state, previousStateActions: {...state.previousStateActions, callSmsEnabled: action.payload}, isRenderedByAction: true}
        case ActionTypes.SET_CALLER_NAME:
            return { ...state, previousStateActions: {...state.previousStateActions, callerCharacterId: action.payload.callerCharacterId, callerCharacterName: action.payload.callerCharacterName}, isRenderedByAction: true}
        case ActionTypes.SET_CHARACTERS_OUTFIT_STATE:
            return { ...state, previousStateActions: {...state.previousStateActions, charactersOutfit: action.payload}, isRenderedByAction: true}
        case ActionTypes.SET_CHANGE_IDENTITY_ENABLED:
            return { ...state, previousStateActions: {...state.previousStateActions, changeIdentityOn: action.payload}, isRenderedByAction: true}
        case ActionTypes.SET_CHANGE_IDENTITY_NAME:
            return { ...state, previousStateActions: {...state.previousStateActions, changeIdentityName: action.payload}, isRenderedByAction: true}
        case ActionTypes.SET_EPISODE_SUMMARY:
            return { ...state, episodeSummary: action.payload, isRenderedByAction: false}
        case ActionTypes.SET_ISRENDERED_PARAM: 
            return {...state, isRenderedByAction: action.payload}

        case ActionTypes.ADD_CHARACTERS_IN_LINE:
            if(state.characters.filter((value) => value.id === action.payload.id).length != 0)
                return state;
            else
                return { ...state, characters: [...state.characters, action.payload]}
        case ActionTypes.SET_EPISODE_STATES:
            return {...state, episodeStates: [...action.payload]}
        case ActionTypes.UPDATE_COMMENT_RESOLVED_STATE:
            return {...state, 
                currentLine: {...state.currentLine, commentResolved: action.payload.resolved}, 
                lines: state.lines.map((content: any, i : any) => content.docId === action.payload.lineId ? {...content, commentResolved: action.payload.resolved} : content )}
        case ActionTypes.UPDATE_COMMENT_STATE:
            return {...state, 
                currentLine: {...state.currentLine, 
                    comment: action.payload.comment, 
                    commentAssignedToName: action.payload.assignedToName, 
                    commentAssignedToUID: action.payload.assignedToUID,
                    commentReporterName: action.payload.reporterName, 
                    commentReporterUID: action.payload.reporterUID, 
                    commentResolved: action.payload.resolved }, 
                lines: state.lines.map((content: any, i : any) => 
                    content.docId === action.payload.lineId 
                        ? {...content, 
                            comment: action.payload.comment, 
                            commentAssignedToName: action.payload.assignedToName, 
                            commentAssignedToUID: action.payload.assignedToUID, 
                            commentReporterName: action.payload.reporterName, 
                            commentReporterUID: action.payload.reporterUID, 
                            commentResolved: action.payload.resolved } 
                        : content )
            }
        case ActionTypes.FIND_TEXT_FILTER:
            let updatedStoredArr = state.lines.map(line => 
            {
                const exists = action.payload.find((content : any) => line.docId == content.docId);
                return exists ? ({...line, filtered: true}) : {...line, filtered: false};
            });
            return {...state, lines: updatedStoredArr}
        case ActionTypes.UPDATE_LINES_OPTIONS:
            let currentLineForOptionsUpdate = action.payload.currentLine;
            newLines = state.lines.map((value : any) => 
                value.docId == currentLineForOptionsUpdate.docId ? 
                {...value,
                    charmingPoint: currentLineForOptionsUpdate.charmingPoint,
                    premiumChoiceText: currentLineForOptionsUpdate.premiumChoiceText,
                    premiumChoiceDescription: currentLineForOptionsUpdate.premiumChoiceDescription,
                    itemId: currentLineForOptionsUpdate.itemId,
                    itemName: currentLineForOptionsUpdate.itemName,
                    itemDescription: currentLineForOptionsUpdate.itemDescription
                } : value
            )
            return {...state, lines: newLines}
        case ActionTypes.UPDATE_LINE_AND_PROPAGATE_ACTIONS:
            let currentLineIndexForPropagate = action.payload.currentLineIndex;
            let currentLineForPropagate = action.payload.currentLine;

            let shortSoundIdPropagate = currentLineForPropagate.shortSoundId;
            let shortSoundPathPropagate = currentLineForPropagate.shortSoundPath;
            let shortSoundNamePropagate = currentLineForPropagate.shortSoundName;
            let shortSoundDescriptionPropagate = currentLineForPropagate.shortSoundDescription;
            if (currentLineForPropagate.shortSoundEnabled != null && currentLineForPropagate.shortSoundEnabled == false) {
                shortSoundIdPropagate = null;
                shortSoundPathPropagate = null;
                shortSoundNamePropagate = null;
                shortSoundDescriptionPropagate = null;
            }

            let charactersOutfitPropagate = currentLineForPropagate.charactersOutfit.map((x : any) => Object.assign({}, x));
            
            charactersOutfitPropagate.map((value : any) => !value.accessoryEnabled ? {...value, accessoryId: null, accessoryName: null, accessoryDescription: null, accessoryType: 0} : value);
            newLines = state.lines.map(value => 
            {
                const exists = action.payload.lineIds?.find((content : any) => value.docId == content);
                return exists ? (
                {...value, 
                    backgroundId: currentLineForPropagate.backgroundId,
                    backgroundPath: currentLineForPropagate.backgroundPath,
                    backgroundName: currentLineForPropagate.backgroundName,
                    backgroundDescription: currentLineForPropagate.backgroundDescription,
                    musicId: currentLineForPropagate.musicId,
                    musicPath: currentLineForPropagate.musicPath,
                    musicName: currentLineForPropagate.musicName,
                    musicDescription: currentLineForPropagate.musicDescription,
                    shortSoundId: shortSoundIdPropagate,
                    shortSoundPath: shortSoundPathPropagate,
                    shortSoundName: shortSoundNamePropagate,
                    shortSoundDescription: shortSoundDescriptionPropagate,
                    shortSoundEnabled: currentLineForPropagate.shortSoundEnabled,
                    avatarEnabled: currentLineForPropagate.avatarEnabled,
                    callPhoneEnabled: currentLineForPropagate.callPhoneEnabled,
                    callSmsEnabled: currentLineForPropagate.callSmsEnabled,
                    callerCharacterId: currentLineForPropagate.callerCharacterId,
                    callerCharacterName: currentLineForPropagate.callerCharacterName,
                    charactersOutfit: charactersOutfitPropagate,
                    changeIdentityOn: currentLineForPropagate.changeIdentityOn != null && currentLineForPropagate.changeIdentityOn == true ? false : currentLineForPropagate.changeIdentityOn,
                    changeIdentityName: currentLineForPropagate.changeIdentityOn != null && currentLineForPropagate.changeIdentityOn == true ? "" : currentLineForPropagate.changeIdentityName,
                }) : value;
            });

            newLines[currentLineIndexForPropagate] = currentLineForPropagate;
            if(currentLineForPropagate.lineType === 4) {
                while(newLines[++currentLineIndexForPropagate]) 
                {
                    if(newLines[currentLineIndexForPropagate].depth > currentLineForPropagate.depth) continue;
                    if(newLines[currentLineIndexForPropagate].lineType !== 5 || newLines[currentLineIndexForPropagate].depth < currentLineForPropagate.depth) break;
                    newLines[currentLineIndexForPropagate].answerQuestionId = currentLineForPropagate.docId;
                }
            }

            let setActionsInCurrentLine = action.payload.lineIds?.find((value : any) => value == state.currentLine.docId);
            let isLastLine = action.payload.currentLine?.docId == state.lines[state.lines.length - 1]?.docId;

            return { ...state, 
                lines: newLines, 
                currentLine: setActionsInCurrentLine || isLastLine ? 
                {
                    ...state.currentLine,  
                    backgroundId: currentLineForPropagate.backgroundId,
                    backgroundPath: currentLineForPropagate.backgroundPath,
                    backgroundName: currentLineForPropagate.backgroundName,
                    backgroundDescription: currentLineForPropagate.backgroundDescription,
                    musicId: currentLineForPropagate.musicId,
                    musicPath: currentLineForPropagate.musicPath,
                    musicName: currentLineForPropagate.musicName,
                    musicDescription: currentLineForPropagate.musicDescription,
                    shortSoundId: shortSoundIdPropagate,
                    shortSoundPath: shortSoundPathPropagate,
                    shortSoundName: shortSoundNamePropagate,
                    shortSoundDescription: shortSoundDescriptionPropagate,
                    shortSoundEnabled: currentLineForPropagate.shortSoundEnabled,
                    avatarEnabled: currentLineForPropagate.avatarEnabled,
                    callPhoneEnabled: currentLineForPropagate.callPhoneEnabled,
                    callSmsEnabled: currentLineForPropagate.callSmsEnabled,
                    callerCharacterId: currentLineForPropagate.callerCharacterId,
                    callerCharacterName: currentLineForPropagate.callerCharacterName,
                    charactersOutfit: charactersOutfitPropagate,
                    changeIdentityOn: currentLineForPropagate.changeIdentityOn != null && currentLineForPropagate.changeIdentityOn == true ? false : currentLineForPropagate.changeIdentityOn,
                    changeIdentityName: currentLineForPropagate.changeIdentityOn != null && currentLineForPropagate.changeIdentityOn == true ? "" : currentLineForPropagate.changeIdentityName,
                } : currentLineForPropagate, 
                isRenderedByAction: true}
        case ActionTypes.UPDATE_ACTION_CHANGE_FLAG_ON_DELETE:
            return { ...state, lines: state.lines.map((content: any) => content.docId === action.payload ? {...content, actionChangeFlag: true} : content ) }
        case ActionTypes.UPDATE_C_LINE_CLOTHES_1:
            return {...state, clothes: state.clothes.map((element : any) => element.id == action.payload.clothes1Id 
                ? {...element, value: action.payload.clothes1Name, label: action.payload.clothes1Name} : element)}
        case ActionTypes.UPDATE_C_LINE_CLOTHES_2:
            return {...state, clothes: state.clothes.map((element : any) => element.id == action.payload.clothes2Id 
                ? {...element, value: action.payload.clothes2Name, label: action.payload.clothes2Name} : element)}
        case ActionTypes.UPDATE_C_LINE_CLOTHES_3:
            return {...state, clothes: state.clothes.map((element : any) => element.id == action.payload.clothes3Id 
                ? {...element, value: action.payload.clothes3Name, label: action.payload.clothes3Name} : element)}

        case ActionTypes.UPDATE_CLOTHING_COMB_PARAM:
            if(action.payload)
                return {...state, 
                    currentLine: 
                    {...state.currentLine,
                        f_ClothingComb1: action.payload.f_ClothingComb1,
                        f_ClothingComb2: action.payload.f_ClothingComb2,
                        f_ClothingComb3: action.payload.f_ClothingComb3,
                        f_ClothingComb4: action.payload.f_ClothingComb4,
                        f_ClothingComb5: action.payload.f_ClothingComb5
                    }
                }

        case ActionTypes.UPDATE_EPISODE_DESCRIPTION:
            return { ...state, editEpisodePassParam: {...state.editEpisodePassParam, titleDescription: action.payload.text} }
        case ActionTypes.SET_CHANGE_OUTFIT_TYPES:
            return { ...state, changeOutfitTypes: [...action.payload]}
        case ActionTypes.SET_CINEMATICS:
            return { ...state, cinematics: [...action.payload]}
        case ActionTypes.ADD_CINEMATIC:
            return { ...state, cinematics: [...state.cinematics, action.payload] } 
        
        case ActionTypes.UPDATE_SHOW_ITEM:
            return {...state, currentLine: { ...state.currentLine, itemId: action.payload.id, itemName: action.payload.label }, isRenderedByAction: true }
        case ActionTypes.UPDATE_SHOW_ITEM_DESCRIPTION:
            return {...state, currentLine: { ...state.currentLine, itemDescription: action.payload }, isRenderedByAction: true }  
        
        case ActionTypes.POPULATE_E_LINES:
            return {...state, lines: state.lines.map((element : any) => action.payload.find((item : any) => item.docId == element.docId) 
                ? {...element, e_Name: action.payload.find((item : any) => item.docId == element.docId)?.e_Name } : element) };

        case ActionTypes.SET_PICKED_COLORS:
            return {...state, pickedColorType: {...action.payload}}
        case ActionTypes.SET_PICKED_ACTION_COLOR:
            return {...state, pickedActionColor: {...action.payload}}
        case ActionTypes.SET_NUMBER_OF_ADDED_COMMENTS_IN_SESSION:
            return {...state, numberOfAddedCommentsInSession: state.numberOfAddedCommentsInSession + 1}
        default:
            return state;
    }
}
