import React, { useEffect, useState } from 'react';
import { CButton, CDataTable, CForm, CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import CreatableSelect from 'react-select/creatable';
import { useAlert } from 'react-alert';
import { BsFileEarmarkPlus } from 'react-icons/bs';
import { IoIosMan } from 'react-icons/io';
import { RiWalkLine } from 'react-icons/ri';
import ReactLoading from 'react-loading';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

//project imports
import { ErrorMessage, Field, Formik } from 'formik';
import { ConcreteStory } from '../Models/ConcreteStory';
import { CreateNewStory, GetAllStories, GetGameTypes } from '../../services/StoriesServices';
import { SelectField } from '../Common/SelectField';
import {  GetLanguagesForStoryCreate } from '../../services/LanguageServices';
import { GetCharactersType } from '../../services/StoriesServices';
import { isWriter, isWriterExternal, isWriterLead } from '../Auth/AuthConfig';
import { handleNameConvert } from '../Helpers/NameConvert';
import { GetAllWritters } from '../../services/LineServices';
import { ActionCreatorsForEditEpisode } from '../../store/EditEpisodeReducer';
import { ActionCreators } from '../../store/StoriesReducer';
import { StorySelectionSessionLogger } from '../../modules/firebaseDetailedLoggers';
import { StoryStatusChangedLogger } from '../../modules/firebaseAnalyticsLoggers';

let createStorySchema = yup.object().shape({
    title: yup.string().max(255, 'Maximum amount of characters in story name is 255.').required("You must enter story name."),
    shortName: yup.string().max(100, 'Maximum amount of characters in story short name is 100.').required("You must enter story short name."),
    language: yup.string().required('Language is required.'),
    description: yup.string().max(400, 'Maximum amount of characters for feature text is 400.').when("storyGameType", {
        is: 0,
        then: yup.string().required("Feature text is required.")
    }),
    characters: yup.array(),
    charactersType: yup.string(),
    writer: yup.string(),
    storyGameType: yup.number()
});

export const Stories = () =>
{
    //reducer state for stories
    const data = useSelector((state: any) => state.storiesReducer.stories);

    const [writters, setWritters] = useState<any[]>([]);

    //language data for select input
    const [languageData, setLanguageData] = useState([]);
    const [charactersTypes, setCharactersType] = useState([ { id: 0, label: '', value: 0 }, { id: 0, label: '', value: 0 } ]);
    const [gameTypes, setGameTypes] = useState([]);
    
    //loading state
    const [loading, setLoading] = useState(false);

    const alert = useAlert();
    const dispatch = useDispatch();

    //modal state
    const [visible, setVisible] = useState(false);
    const [isLoadingWhenCreate, setIsLoadingWhenCreate] = useState(false);
    const [onRowClicked, setStateOnRowClicked] = useState({ state: false, values: { id: 0 } });

    //additional writters state
    const [displayAdditionalWritter, setDisplayAdditionalWriter] = useState(false);
    const [additionalWriters, setAdditionalWriters] = useState([]);

    //table columns
    const columns = 
    [
        {
            key: 'title',    
            id:'title-td',
            sorter: false
        },
        {
            key: 'episodesNum', 
            label: 'Episodes',      
            sorter: false
        },
        {
            key: 'storyGameType', 
            label: 'Game Type',      
            sorter: false
        },
        {
            key: 'authorName',
            sorter: false
        },
        {
            key: 'status',
            sorter: false
        },
        {
            key: 'releaseDate',
            sorter: false
        },
    ]

    useEffect(() => 
    {  
        document.title = 'Story Maker - Stories'
        setLoading(true);

        GetLanguagesForStoryCreate(alert).then((response) => 
        { 
            handleNameConvert(response);

            setLanguageData(response);
        }); 

        GetAllStories(dispatch, alert).then(() => { setLoading(false); });

        GetCharactersType(alert).then((response) => setCharactersType(response));
        
        GetAllWritters(alert).then((response) => { setWritters(response) });

        GetGameTypes().then((response) => { setGameTypes(response); });
    }, [])

    return(
    <div className="mt-3 column-style"> 
        { (isWriter() || isWriterLead() || isWriterExternal()) ? 
        <CButton onClick={() => { setVisible(!visible) }} className="button-create button-width create-story-button-margin">
            <BsFileEarmarkPlus color="white" size={18} />&nbsp;&nbsp;Create New Story</CButton> 
                : ''} 
            <div className="register-signin-left-panel col-sm-12 rounded shadow stories"> 
                <h4 className="story-title">Stories</h4>
        <CDataTable 
            clickableRows
            addTableClasses='stories-data-table'
            items={data}
            loading={loading}
            fields={columns}
            tableFilter
            itemsPerPageSelect
            itemsPerPage={10}
            striped  
            hover
            sorter
            pagination
            onRowClick={(row: any) => 
            { 
                const storySelectionLogger = new StorySelectionSessionLogger();

                storySelectionLogger.story_name = row.title ?? '';
                storySelectionLogger.timestamp = new Date().toUTCString();
                storySelectionLogger.session_id = localStorage.getItem('sessionId') ?? '';
                storySelectionLogger.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                storySelectionLogger.user_id = localStorage.getItem('uid') ?? '';

                storySelectionLogger.logEvent();

                setStateOnRowClicked({ state: true, values: row }) 
            }}
            responsive={true}
            scopedSlots= {{
                'releaseDate': (item: any) =>
                (
                    <td>{item.releaseDate === "" ? 'N/A' : item.releaseDate}</td>
                ),
                'storyGameType': (item: any) =>
                {
                    var item : any = gameTypes?.find((element : any) => element.value == item.storyGameType);
                    return(
                        <td>{item?.label ?? ''}</td>
                    )
                }
            }}
                /> 
                </div>
        <br/>

        { onRowClicked.state === true ? <ConcreteStory story={onRowClicked.values}/> : <div/> }

            <CModal show={visible} className="create-new-story-modal" fade onClose={() => {setVisible(false); setDisplayAdditionalWriter(false); }} closeOnBackdrop={false}>
            <CModalHeader closeButton>
                    <CModalTitle><BsFileEarmarkPlus color="white" size={18} />&nbsp;&nbsp;Create New Story</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <Formik initialValues={{ title: '', shortName: '', language: '', description: '', characters: [], charactersType: '0', writer: '', storyGameType: 0 }}
                    onSubmit={(values, { resetForm }) => 
                    { 
                        if(data?.filter(function(element : any) { return element.title === values.title }).length === 0)
                        {
                            setIsLoadingWhenCreate(true);
                            let writerUID = isWriterLead() ? values.writer : localStorage.getItem('uid');
                            let writerName = null;
                            if (isWriterLead()) {
                                if (writters !== null && writters.find((x: any) => x.id === values.writer) !== undefined) {
                                    writerName = writters.find((x: any) => x.id === values.writer).label;
                                }
                            }
                            else {
                                writerName = localStorage.getItem('user');
                            }
                            CreateNewStory(
                                { ...values, 
                                    charactersType: parseInt(values.charactersType),
                                    authorName: localStorage.getItem('user'),
                                    authorUID: localStorage.getItem('uid'),
                                    writterUID: writerUID,
                                    writterName: writerName,
                                    additionalWriters: additionalWriters?.map((element : any) => { return { writerUID: element.id, writerName: element.label }}), 
                                    storyGenreEnum: 0,
                                    storySubGenreEnum: 0
                                }, dispatch, alert, setStateOnRowClicked, setIsLoadingWhenCreate, setVisible, resetForm).then((res : any) =>
                                {
                                    const storyStatusChangedLogger = new StoryStatusChangedLogger();
                                    storyStatusChangedLogger.story_name = res.title;
                                    storyStatusChangedLogger.author_name = res.authorName;
                                    storyStatusChangedLogger.user_id = localStorage.getItem('uid') ?? '';
                                    storyStatusChangedLogger.language = res.languageString;
                                    storyStatusChangedLogger.character_type = res.charactersTypeString;
                                    storyStatusChangedLogger.category = res.storyGenre;
                                    storyStatusChangedLogger.internal_writer = false; //currently info not available in the app, to be added
                                    storyStatusChangedLogger.game_type = res.storyGameTypeString;
                                    storyStatusChangedLogger.new_status = res.status;
                                    storyStatusChangedLogger.number_of_episodes = res.episodesNum;
                                    storyStatusChangedLogger.logged_in_user_name = localStorage.getItem('user') ?? '';
                    
                                    storyStatusChangedLogger.logEvent();
                                })

                        }
                        else
                        {
                            alert.error('Story with the same title already exists.')
                        }
                    }
                    }
                    validationSchema={createStorySchema}>
                    {({ values, dirty, isValid, handleSubmit }) => 
                    {
                        return(
                            <CForm onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                 { isLoadingWhenCreate ? 
                                 <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1000, position: 'absolute', top: '0%', left: '0%', width: '100%', height: '100%', backgroundColor: 'rgb(115,115,115,0.5)'}}>
                                    <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1001, position: 'absolute', top: '45%', left: '45%'}}>
                                        <ReactLoading className="mt-3 mb-5" type='spin' color='#0080FF'/>
                                    </div>
                                </div> : <div></div> }
                                <div className="row-style crt-stry-12 modal-row col-sm-12 row">
                                    <div className="form-group-name crt-story col-sm-6">
                                        <label htmlFor="title">Story name</label>
                                        <Field
                                            className="form-control"
                                            name="title"
                                            type="text"
                                            autoComplete="off"
                                            placeholder="Enter story name..."
                                            required>
                                        </Field>
                                        <ErrorMessage component="p" className="field-colorchange" name="title" />
                                    </div>

                                    <div className="form-group-name crt-story lang col-sm-6 ">
                                        <label htmlFor="title">Short Name</label>
                                        <Field
                                            className="form-control"
                                            name="shortName"
                                            type="text"
                                            autoComplete="off"
                                            placeholder="Enter short name..."
                                            required>
                                        </Field>
                                        <ErrorMessage component="p" className="field-colorchange" name="shortName" />
                                    </div>
                                </div>

                                <div className="row-style crt-stry-12 modal-row col-sm-12 row">
                                    <div className="form-group-name crt-story lang col-sm-6 ">
                                        <label htmlFor="language">Language</label>
                                        <Field
                                            component={SelectField}
                                            options={languageData}
                                            name="language"
                                            autoComplete="off"
                                            required>
                                        </Field>
                                        <ErrorMessage component="p" className="field-colorchange" name="language" />
                                    </div>
                                </div>
                                
                                <div className="row-style crt-stry-12 modal-row col-sm-12 row">
                                    { isWriterLead() ? <div className="form-group-name crt-story col-sm-6">
                                        <label htmlFor="writer">Writer</label>
                                        <Field
                                            component={SelectField}
                                            options={writters.filter((element) => {
                                                if(!additionalWriters.find((el : any) => el.id == element.id))
                                                {
                                                    return element;
                                                }
                                            })}
                                            name="writer"
                                            autoComplete="off">
                                        </Field>
                                        <CButton color="cancel-sm" style={{fontStyle:"italic", textDecoration:"underline"}} onClick={() => setDisplayAdditionalWriter((value : any) => {
                                            if(!value)
                                                return !value;
                                            else
                                            {
                                                setAdditionalWriters([]);
                                                return !value;
                                            }
                                        })}>{ !displayAdditionalWritter ? 'Add additional writters' : 'Remove additional writters'}</CButton>
                                        <ErrorMessage component="p" className="field-colorchange" name="writer" />
                                    </div> : '' }
                                    <div className="form-group-name crt-story col-sm-6">
                                        <label htmlFor="storyGameType">Game Type</label>
                                        <Field
                                            component={SelectField}
                                            options={gameTypes}
                                            name="storyGameType"
                                            autoComplete="off"
                                            required>
                                        </Field>
                                        
                                        <ErrorMessage component="p" className="field-colorchange" name="storyGameType" />
                                    </div>
                                </div> 
                                {displayAdditionalWritter ? <div className="form-group-name crt-stry-12 col-sm-12 modal-row">
                                    <label htmlFor="additionalWriters">Additional writers</label>
                                    <Select
                                            hideSelectedOptions
                                            options={writters.filter((element) => 
                                            { 
                                                let writerUID = isWriterLead() ? values.writer : localStorage.getItem('uid');
                                                if(element.id != writerUID)
                                                {
                                                    return element;
                                                }
                                            })}
                                            isMulti={true}
                                            isSearchable={true}
                                            name="additionalWriters"
                                            autoComplete="off"
                                            className="mt-1"
                                            onChange={(option: any ) => setAdditionalWriters(option) }>
                                    </Select>
                                    <ErrorMessage component="p" className="field-colorchange" name="additionalWriters" />
                                </div> : ''}  
                                <div className="form-group-name crt-stry-12 col-sm-12 modal-row">
                                    <label htmlFor="description">Feature text</label>
                                    <Field
                                        className="form-control"
                                        name="description"
                                        type="text"
                                        as="textarea"
                                        autoComplete="off">
                                    </Field>
                                    <ErrorMessage component="p" className="field-colorchange" name="description" />
                                </div>
                                
                                <div className="row-style crt-stry-12 modal-row col-sm-12 row">
                                    <div className="form-group-name crt-story char col-sm-6 ">
                                        <div id="my-radio-group" className="col-sm-12">
                                            <label htmlFor="charactersType">Characters Type</label>
                                        </div>
                                        <div role="group" aria-labelledby="my-radio-group" className="row-style form-group col-sm-12 char-type-radios">
                                            {charactersTypes?.map((type, index) => (<div key={index} className="div-input-row mr-2">
                                                <div className="radio-cont">
                                                    <Field
                                                        className="mr-2"
                                                        type="radio"
                                                        name="charactersType"
                                                        value={type.value.toString()}
                                                        autoComplete="off"
                                                        required>
                                                    </Field>
                                                </div>
                                                <div className="label-cont-radio"><label htmlFor="yes">{index === 0 ? <IoIosMan size={20}/> : <RiWalkLine size={20}/>}{type.label}
                                                </label ></div>
                                                
                                            </div>))}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-buttons-container float-right">
                                    <CButton type="submit" disabled={!dirty || !isValid} className="mr-4" color="primary-sm" onClick={() => setDisplayAdditionalWriter(false)}>Create Story</CButton>
                                    <CButton color="cancel-sm" onClick={() => {
                                        setVisible(false);
                                        setDisplayAdditionalWriter(false);
                                    }}>Cancel</CButton>
                                </div>
                            </CForm>
                        )}
                    }
                </Formik>
            </CModalBody>
        </CModal>
    </div>)
}

export default Stories;