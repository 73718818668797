import { axiosInstance } from '../axios/key';
import { ErrorHandler } from '../components/Helpers/ErrorHandler';
import { ActionCreators } from '../store/StoriesReducer';
import { ActionCreatorsForTranslations } from '../store/TranslationsReducer';

export const GetAllStories = async (dispatch: any, alert : any) =>
{
    try
    {
        const { data } = await axiosInstance.get('api/Stories/GetStories')
        dispatch(ActionCreators.setStories(data));
    }
    catch(e)
    {
        ErrorHandler(e, 'There was an error while trying to get stories.', alert);
    }
}

export const GetAllStoriesForTranslations = async (dispatch: any, alert : any) =>
{
    try
    {
        const { data } = await axiosInstance.get('api/Translations/GetStories')
        dispatch(ActionCreatorsForTranslations.setTranslations(data));
    }
    catch(e)
    {
        ErrorHandler(e, 'There was an error while trying to get stories.', alert);
    }
}

export const GetAllStoriesForTranslationsSelect = async (alert : any) =>
{
    try
    {
        const { data } = await axiosInstance.get('api/Translations/GetStoriesForSelect')
        return data.map((value : any) => { return { id: value.id, label: value.title, value: value.translationId, language: value.language }});
    }
    catch(e)
    {
        ErrorHandler(e, 'There was an error while trying to get stories.', alert);
    }
}

export const CreateNewStory = async (story: any, dispatch: any, alert: any, setStateOnRowClicked : any, setIsLoadingWhenCreate : any, setVisible : any, resetForm : any)  => 
{
    try
    {
        delete story.writer;
        
        const { data } = await axiosInstance.post('api/Stories/SaveStory', story);
        dispatch(ActionCreators.addNewStory(data));

        setIsLoadingWhenCreate(false);
        setVisible(false);
        resetForm();
        alert.success('Successfuly added new story.')
        setStateOnRowClicked({ state: true, values: data });
        sessionStorage.setItem('isCreatedBool', 'true');
        return data;
    }
    catch(e)
    {
        ErrorHandler(e, 'There was an error while trying to create new story.', alert);
    }
}

export const GetCharactersType = async (alert : any) =>
{
    try
    {
        const { data } = await axiosInstance.get('/api/Stories/GetStoryCharactersTypes');
        return data;
    }
    catch(e)
    {
        ErrorHandler(e, 'There was an error while trying to get character types.', alert);
    }
}

export const GetAllStoriesForHeader = async () =>
{
    try
    {
        const { data } = await axiosInstance.get('api/Stories/GetStories')
        return data.map((value : any) => { return { id: value.id, label: value.title, value: value.translationId}});
    }
    catch
    {
        console.log('There was an error while trying to get stories.');
    }
}


export const GetEpisodesForHeader = async (storyId: any, translationId: any) =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/Episode/GetStoryEpisodes?storyId=${storyId}&storyTranslationId=${translationId}`);
        return data.map((value : any) => { return { id: value.id, label: value.title, value: value.id}});
    }
    catch
    {
        console.log('Error in getting all stories');
    }
}

export const GetStoryDetailsForSetupStory = async (dispatch : any, storyId: string, translationId: string) =>
{
    try
    {
        const { data } = await axiosInstance.post('api/Stories/GetStory', { storyId: storyId, translationId: translationId});
        dispatch(ActionCreators.setStoryDetails(data));
        return data;
    }
    catch
    {
        console.log('Error in getting story details');
    }
}

export const GetSetupStoryTableData = async (baseURL : string) =>
{
    try
    {
        const { data } = await axiosInstance.get(baseURL);
        return data;
    }
    catch
    {
        console.log('Error in getting setup story table data.')
    }
}

export const GetGameTypes = async () => 
{
    try
    {
        const { data } = await axiosInstance.get('api/Stories/GetGameTypes');
        return data;
    }   
    catch
    {
        console.log('Error in getting game types.')
    }
}

export const GetEpisodesForTasksExport = async (storyId: string, storyTranslationId: string) => 
{
    try
    {
        const { data } = await axiosInstance.get(`api/DesignerTasksExport/GetEpisodes?storyId=${storyId}&storyTranslationId=${storyTranslationId}`);
        return data;
    }   
    catch
    {
        console.log('Error in getting episodes for task exports.')
    }
}

export const ExportEpisode = async (storyId: string, storyTranslationId: string, episodeSerialNum: number, alert : any, episodes : any, setEpisodes : any) => 
{
    try
    {
        const { data } = await axiosInstance.get(`api/DesignerTasksExport/ExportEpisode?storyId=${storyId}&storyTranslationId=${storyTranslationId}&episodeSerialNum=${episodeSerialNum}`);
        
        setEpisodes(episodes.map((element : any) => element.episodeSerialNumber == episodeSerialNum ? {...element, allowExport: data.allowForExport} : element));
        alert.success('Export was successfull.')
    }   
    catch
    {
        alert.error('An error occured while trying to export.')
    }
}