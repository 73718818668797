import { axiosInstance } from '../axios/key';
import { ActionCreatorsForEditEpisode } from '../store/EditEpisodeReducer';

export const GetPossibleEpisodeStatuses = async (dispatch: any, body : any) =>
{
    try
    {
        const { data } = await axiosInstance.post('api/StateMachine/GetPossibleEpisodeStatuses', body)
        dispatch(ActionCreatorsForEditEpisode.setEpisodeStates(data));
    }
    catch
    {
        console.log('There was an error while trying to get episode statuses.');
    }
}

export const ChangeEpisodeStatus = async (query: any, body : any) =>
{
    try
    {
        const { data } = await axiosInstance.post('api/StateMachine/ChangeEpisodeStatus?newStatus='+`${query}`, body)
        return data;
    }
    catch
    {
        console.log('There was an error while trying to change episode status.');
    }
}
