
const initialState = 
{
    currentUsers: [] as any[]
}

export const ActionTypes =
{
    GET_CURRENTUSERS: 'GET_CURRENTUSERS'
}

export const ActionCreatorsForUserManagement = 
{
    getCurrentUsers: (payload: any) => ({type: ActionTypes.GET_CURRENTUSERS, payload})
}

export default function userManagementReducer(state=initialState, action: any)
{
    switch(action.type)
    {
        case ActionTypes.GET_CURRENTUSERS:
            return {...state, currentUsers: [...action.payload]};
        default:
            return state;
    }
}