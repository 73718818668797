import React, { useState } from 'react';
import { CBadge, CButton, CCard, CCardBody, CInput, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react';
import { useSelector } from 'react-redux';
import ReactLoading from 'react-loading';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BsCheck, BsFileEarmarkPlus } from 'react-icons/bs';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import { HiCheck } from 'react-icons/hi';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { GetSelectValuesForAssets, UpdateAssets } from '../../services/ArtCatalog';
import { useAlert } from 'react-alert';
import AsyncSelect from 'react-select/async'
import Switch from "react-switch";
import { HairstylePictureBody } from './ArtCatalogPictureBody/HairstylePictureBody';
import { ClothesPictureBody } from './ArtCatalogPictureBody/ClothesPictureBody';
import { CharactersPictureBody } from './ArtCatalogPictureBody/CharactersPictureBody';
import { CinematicsPictureBody } from './ArtCatalogPictureBody/CinematicsPictureBody';
import { AccessoryPictureBody } from './ArtCatalogPictureBody/AccessoryPictureBody';
import { AudioPictureBody } from './ArtCatalogPictureBody/AudioPictureBody';
import { BackgroundPictureBody } from './ArtCatalogPictureBody/BackgroundPictureBody';
import ReactPaginate from 'react-paginate';
import { OpenImageSessionLogger } from '../../modules/firebaseDetailedLoggers';

export const ArtCatalogTabPane = ({header, page, setPage, data, loading, name} : any) =>
{
    const alert = useAlert();

    const [visible, setVisible] = useState({ isVisible: false, object: {} as any, index: 0});
    
    const PER_PAGE = 24;
    const 
    { 
        accessoryTypes,
        audioTypes, 
        audioTags, 
        interiors,
        exteriors, 
        bodyTypesCharacters, 
        skinColorsCharacters, 
        characterTypes, 
        characterDisplayTypes, 
        cinematicLocations, 
        cinematicActorObjects, 
        cinematicPlots, 
        clothesTypes, 
        clothesAttributes, 
        hairstyleTypes 
    } = useSelector((state: any) => state.artCatalogReducer);

    const pageCount = Math.ceil(data.length / PER_PAGE);

    return(   
        <div className="column-style">   
            {header}
            {loading  ? 
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <ReactLoading className="mt-3 mb-5" type='spin' color='#0080FF'/>
                </div> : 
                <div>
                    { name == "audio" ? <div className="row-style mt-2">
                        <div className="row-style mr-4"><div className="mr-1 circle-for-none"/>None</div>
                        <div className="row-style mr-4"><div className="mr-1 circle-for-shortsound"/>Short Sound</div>
                        <div className="row-style mr-4"><div className="mr-1 circle-for-music"/>Music</div>
                    </div>: <div/> }
                    <div className="row-style mt-2 pictures-div">
                    { data?.slice((page) * PER_PAGE, (page + 1) * PER_PAGE)?.map((value : any, index: any) => {
                        return(
                            <div className="flip-card"  
                                onClick={() => 
                                { 
                                    const openImageSession = new OpenImageSessionLogger();
                                    
                                    openImageSession.timestamp = new Date().toUTCString();
                                    openImageSession.session_id = localStorage.getItem('sessionId') ?? '';
                                    openImageSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                                    openImageSession.user_id = localStorage.getItem('uid') ?? '';
                                    
                                    openImageSession.logEvent();
                                    
                                    setVisible({isVisible: true, object: value, index: index})
                                }}>
                                    { name != "audio" ? 
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front">
                                            <LazyLoadImage className='fixed-image-size cursor' src={value.resourcePath}/> 
                                            <p className="font-weight-light text-muted" style={{fontSize: '0.8rem', wordWrap: 'break-word'}}>{value.name}</p>
                                        </div>
                                        
                                        <div className="flip-card-back cursor center column-style">
                                            <h6 className="mt-2" style={{wordWrap: 'break-word', width: '100%'}}>{value.name}</h6>
                                            <p className="p-bold">Created Date:</p><p>{new Date(value.createdTime).toLocaleDateString("en-GB")}</p>
                                            <p className="p-bold"style={{wordWrap: 'break-word', width: '100%'}}>Modified Date:</p><p>{new Date(value.modifiedTime).toLocaleDateString("en-GB")} by {value.lastModifyingUser}</p>
                                        </div>
                                    </div>
                                    : 
                                    <div className="audio-player-container">
                                        <audio className={value.audioType == 0 ? 'none' : value.audioType == 1 ? 'shortsound' : 'music'} controls><source src={value.resourcePath} type="audio/ogg"/></audio>
                                        <p className="font-weight-light text-muted center" style={{fontSize: '0.8rem', wordWrap: 'break-word'}}>{value.name}</p>
                                    </div>}
                            </div>
                        )})}
                </div>
                <div className="center mt-4">
                <ReactPaginate
                    previousLabel={"← "}
                    nextLabel={" →"}
                    pageRangeDisplayed={PER_PAGE}
                    marginPagesDisplayed={PER_PAGE}
                    pageCount={pageCount}
                    onPageChange={(selected : any) => { setPage(selected.selected) }}
                    forcePage={page}
                    containerClassName={'pagination'} 
                    activeClassName={'active'} 
                /></div>
                </div>
            }
            <CModal show={visible.isVisible} className="popup-modal remove-width" fade onClose={() => setVisible({isVisible: false, object: {}, index: 0})}>
                <CModalBody className="center row">
                    { visible.index != 0 ? <FaArrowCircleLeft size={43} className="mr-5 cursor" onClick={() => { setVisible({isVisible: true, object: data[visible.index-1], index: visible.index-1}) }}/> : '' }
                    <div className="column">
                        <h5 className="center mb-2">{visible.object.name}</h5>
                        { name != 'audio' ? <img src={visible.object.resourcePath} alt="..."/> : ''}
                        {visible.object.areSomeMetatagsMissing ? <p style={{color:"red"}}>Some meta tags are missing!</p> : "" }
                        {
                            name=="cinematics" ? 
                                <CinematicsPictureBody visible={visible} setVisible={setVisible} alert={alert}/>
                            : name == 'characters' ? 
                                <CharactersPictureBody characterTypes={characterTypes} visible={visible} setVisible={setVisible} alert={alert}/>
                            : name == 'hairstyles' ? 
                                <HairstylePictureBody hairstyleTypes={hairstyleTypes} visible={visible} setVisible={setVisible} alert={alert}/>
                            : name == 'clothes' ?
                                <ClothesPictureBody clothesTypes={clothesTypes} visible={visible} setVisible={setVisible} alert={alert}/>
                            : name == 'accessories' ?
                                <AccessoryPictureBody accessoryTypes={accessoryTypes} visible={visible} setVisible={setVisible} alert={alert}/>
                            : name == 'audio' ?
                                <AudioPictureBody audioTypes={audioTypes} visible={visible} setVisible={setVisible} alert={alert}/>
                            : name == 'backgrounds' ?
                                <BackgroundPictureBody visible={visible} setVisible={setVisible} alert={alert} />
                            : ''
                        }
                        
                    </div>
                    { visible.index === data?.length - 1 ? '' : <FaArrowCircleRight size={43} className="ml-5 cursor" onClick={() => { setVisible({isVisible: true, object: data[visible.index+1], index: visible.index+1})}}/>}
                </CModalBody>
            </CModal>
        </div>
    )
}

export default ArtCatalogTabPane;