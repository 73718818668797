import React, { useEffect, useState } from 'react';
import { CInputGroup, CInput, CForm, CButton, CTooltip } from '@coreui/react';
import { Field, Formik } from 'formik';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { CgInsertBefore } from '@meronex/icons/cg';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorsForEditEpisode } from '../../../store/EditEpisodeReducer';
import { LineType } from '../../../interfaces/lineType';
import { AddNewLine, SaveOLine } from '../../../services/EpisodeServices';
import { useAlert} from 'react-alert'
import { colorLineBasedOnActions, colorLineWithComment, createDefaultLineTypeOptions, editEpisodeReadOnlyCell, getClassesWhenInReviewStatus, getInputLineNumber, getLineOptionsClass, showLineActions, validateActionChange } from './_Helpers';
import ReactLoading from 'react-loading';
import { ActionCreators } from '../../../store/StoriesReducer';
import { DeleteLines } from '../../../services/LineServices';
import { RiCharacterRecognitionFill } from 'react-icons/ri';
import { GiCutDiamond } from 'react-icons/gi';
import { DeleteMultipleLinesConfirmationModal } from '../../Helpers/YesNoModal';
import '../../../styles/Lines.css'
import { LineTypes } from '../Enums/LineTypes';
import LineSidebar from '../LineSidebar';

const getProperIndexAndDocId = (lines: LineType[], index: number, isOpen : boolean, type : string) => {
    let properIndex = index;
    let previousLineDocId = lines[lines.length - 1]?.docId;

    if (index != null) {
        if(isOpen && type == 'above')
        {
            let counter = index - 1;
            previousLineDocId = counter == -1 ? undefined : lines[counter]?.docId;
        }
        else
        {
            let counter = index + 1;
            previousLineDocId = lines[index]?.docId;
            while(counter < lines.length 
                && ((lines[counter].depth ?? 0) > 0 || (lines[counter].depth == 0 
                    && [LineTypes.A, LineTypes.BA, LineTypes.TA, LineTypes.VA].includes(lines[counter].lineType ?? 0))))
            {
                previousLineDocId = lines[counter]?.docId;
                properIndex = counter;
                counter = counter + 1;
            }
        }
    }
    return { properIndex, previousLineDocId };
}

const stateWithClearedLineOutfit = (inputLine: boolean, selectedLineIndex: number, lines: LineType[], line: LineType, index: number, isModalOpen: boolean, maxLineNumber: number, modalClickedLine: LineType, modalType : string) => {
    let { properIndex } = getProperIndexAndDocId(lines, index, isModalOpen, modalType);
    return (inputLine && selectedLineIndex === -1) || isModalOpen ? 
    { 
        docId: line.docId,
        storyId: line.storyId,
        storyTranslationId: line.storyTranslationId,
        episodeId: line.episodeId,
        lineNumber: getInputLineNumber(lines, properIndex === index ? line : lines[properIndex], properIndex, maxLineNumber,isModalOpen, modalType),
        depth: 0,
        lineType: line.lineType,
        lineFormat: line.lineFormat,
        episodeName: line.episodeName,
        previousLineDocId: line.previousLineDocId,
        episodeSerialNumber: line.episodeSerialNumber,

        characterId: line.characterId,
        characterName: line.characterName,
        characterPath: line.characterPath,
        emotion: line.emotion,
        text: undefined,

        animationId: undefined,
        animationName: undefined,
        payed: undefined,
        answerNumber: undefined,
        questionDocId: undefined,
    
        comment: undefined,
        commentReporterName: undefined,
        commentReporterUID: undefined,
        commentAssignedToName: undefined,
        commentAssignedToUID: undefined,
        commentResolved: undefined,

        musicId: line.musicId,
        musicName: line.musicName,
        musicDescription: line.musicDescription,

        shortSoundEnabled: line.shortSoundEnabled,
        shortSoundId: line.shortSoundId,
        shortSoundName: line.shortSoundName,
        shortSoundDescription: line.shortSoundDescription,

        itemId: line.itemId ?? undefined,
        itemName: line.itemName ?? undefined,
        itemDescription: line.itemDescription ?? undefined,

        avatarEnabled: line.avatarEnabled,

        changeIdentityOn: line.changeIdentityOn,
        changeIdentityName: line.changeIdentityName,

        callPhoneEnabled: line.callPhoneEnabled != null ? line.callPhoneEnabled : undefined,
        callSmsEnabled: line.callSmsEnabled != null ? line.callSmsEnabled : undefined,
        callerCharacterId: line.callerCharacterId,
        callerCharacterName: line.callerCharacterName,
        charmingPoint: line.charmingPoint != null ? line.charmingPoint : undefined,
        premiumChoiceText: line.premiumChoiceText != null ? line.premiumChoiceText : undefined,
        premiumChoiceDescription: line.premiumChoiceDescription != null ? line.premiumChoiceDescription : undefined,
        charactersOutfit: line.charactersOutfit,

        backgroundId: line.backgroundId,
        backgroundName: line.backgroundName,
        backgroundDescription: line.backgroundDescription,
    
        clothes1Name: undefined,
        clothes2Name: undefined,
        clothes3Name: undefined,
        clothes4Name: undefined,
        clothes5Name: undefined,
        clothes6Name: undefined,
        hairstyle1Name: undefined,
        hairstyle2Name: undefined,
        hairstyle3Name: undefined,
    
        clothes1Id: undefined,
        clothes2Id: undefined,
        clothes3Id: undefined,
        clothes4Id: undefined,
        clothes5Id: undefined,
        clothes6Id: undefined,
        hairstyle1Id: undefined,
        hairstyle2Id: undefined,
        hairstyle3Id: undefined
    } 
    : 
    line
}

export const OutfitLine1 = ({ line, inputLine, index, episodeName, serialNumber, isPreviewInModal, inPreviewIndex }: any) => {
    const { selectedLineIndex, lines, defaultLine, linesFrame, pickedActionColor, maxLineNumber, editEpisodePassParam } = useSelector((state: any) => state.editEpisodeReducer);
    const { modalPopupBool } = useSelector((state: any) => state.storiesReducer);
    const dispatch = useDispatch();
    const alert = useAlert();

    const [yesnomodal, setYesnomodal] = useState({boolean: false, array: [] as any[], nextLineDocId: null})

    return (
        <div>
            <CInputGroup className={inputLine ? "oline-inputgroup oline-inputgroup-border" : "oline-inputgroup o-linija"} >
                <span 
                    title={showLineActions(line)}
                    className={getLineOptionsClass(line)}
                    style={editEpisodeReadOnlyCell}>
                    {inputLine && selectedLineIndex === -1 ? line.lineNumber + 1 : line.lineNumber}
                </span>
                <span style={editEpisodeReadOnlyCell}>O</span>
                <span className="col-sm-1 cursor" style={editEpisodeReadOnlyCell}>{line.clothes1Name}</span>
                <span className="col-sm-1 cursor" style={editEpisodeReadOnlyCell}>{line.clothes2Name}</span>
                <span className="col-sm-1 cursor" style={editEpisodeReadOnlyCell}>{line.clothes3Name}</span>
                <span className="col-sm-1 cursor" style={editEpisodeReadOnlyCell}>{line.clothes4Name}</span>
                <span className="col-sm-1 cursor" style={editEpisodeReadOnlyCell}>{line.clothes5Name}</span>
                <span className="col-sm-1 cursor" style={editEpisodeReadOnlyCell}>{line.clothes6Name}</span>
                <input disabled className="col" style={editEpisodeReadOnlyCell}/>
                {line.comment && !inputLine? <span className="tooltip-comment-exist" style={{backgroundColor: colorLineWithComment(line)}}></span> : ''}

                <LineSidebar  
                    dispatch={dispatch} 
                    lines={lines} 
                    line={line} 
                    selectedLineIndex={selectedLineIndex} 
                    inputLine={inputLine} 
                    isPreviewInModal={isPreviewInModal}
                    modalPopupBool={modalPopupBool}
                    setYesnomodal={setYesnomodal}
                    editEpisodePassParam={editEpisodePassParam}
                    index={index}
                    pickedActionColor={pickedActionColor}
                    episodeName={episodeName}
                    serialNumber={serialNumber}
                    linesFrame={linesFrame}
                />
                
            </CInputGroup>
            <DeleteMultipleLinesConfirmationModal modal={yesnomodal} setModal={setYesnomodal} methodForYes={() => DeleteLines(dispatch, alert, yesnomodal.array, index, linesFrame, setYesnomodal, yesnomodal.nextLineDocId) } title="Delete confirmation" message="Are you sure you want to delete selected line or line block?"/>
        </div>)
}

export const OutfitLine2 = ({ line, inputLine, index, isPreviewInModal, inPreviewIndex }: any) => 
{
    const { selectedLineIndex, maxLineNumber, pickedActionColor, lines, linesFrame, editEpisodePassParam } = useSelector((state: any) => state.editEpisodeReducer);
    const { modalPopupBool } = useSelector((state: any) => state.storiesReducer);
    const dispatch = useDispatch();

    return (
        <div>
            <CInputGroup className={inputLine ? "oline-inputgroup oline-inputgroup-border" : "oline-inputgroup o-linija"}>
            <span 
                title={showLineActions(line)}
                className={getLineOptionsClass(line)} 
                style={editEpisodeReadOnlyCell}>{inputLine && selectedLineIndex === -1 ? getInputLineNumber(lines, line, index, maxLineNumber,modalPopupBool.isOpen, modalPopupBool.type) : line.lineNumber}</span>
                <span style={editEpisodeReadOnlyCell}>O</span>
                <span className="col-sm-1 cursor" style={editEpisodeReadOnlyCell}>{line.hairstyle1Name}</span>
                <span className="col-sm-1 cursor" style={editEpisodeReadOnlyCell}>{line.hairstyle2Name}</span>
                <span className="col-sm-1 cursor" style={editEpisodeReadOnlyCell}>{line.hairstyle3Name}</span>
                <input disabled className="col" style={editEpisodeReadOnlyCell}/>
                {line.comment && !inputLine? <span className="tooltip-comment-exist" style={{backgroundColor: colorLineWithComment(line)}}></span> : ''}        
                
                <LineSidebar  
                    dispatch={dispatch} 
                    lines={lines} 
                    line={line} 
                    selectedLineIndex={selectedLineIndex} 
                    inputLine={inputLine} 
                    isPreviewInModal={isPreviewInModal}
                    modalPopupBool={modalPopupBool}
                    setYesnomodal={null}
                    editEpisodePassParam={editEpisodePassParam}
                    index={index}
                    pickedActionColor={pickedActionColor}
                />
                
            </CInputGroup>
        </div>
    )
}

export const OutfitLine3 = ({ line, inputLine, index, isPreviewInModal, inPreviewIndex }: any) => {
    const { selectedLineIndex, defaultSkinColors, maxLineNumber, lines, pickedActionColor, linesFrame, editEpisodePassParam } = useSelector((state: any) => state.editEpisodeReducer);
    const { modalPopupBool } = useSelector((state: any) => state.storiesReducer);
    const dispatch = useDispatch();
    return (
        <div>
            <CInputGroup className={inputLine ? "oline-inputgroup oline-inputgroup-border" : "oline-inputgroup o-linija"}>
            <span 
                title={showLineActions(line)}
                className={getLineOptionsClass(line)} 
                style={editEpisodeReadOnlyCell}>{inputLine && selectedLineIndex === -1 ? getInputLineNumber(lines, line, index,maxLineNumber,modalPopupBool.isOpen, modalPopupBool.type) : line.lineNumber}</span>
                <span style={editEpisodeReadOnlyCell}>O</span>
                <span className="col-sm-1 cursor" style={editEpisodeReadOnlyCell}>{defaultSkinColors[0].label}</span>
                <span className="col-sm-1 cursor" style={editEpisodeReadOnlyCell}>{defaultSkinColors[1].label}</span>
                <span className="col-sm-1 cursor" style={editEpisodeReadOnlyCell}>{defaultSkinColors[2].label}</span>
                <span className="col-sm-1 cursor" style={editEpisodeReadOnlyCell}>{defaultSkinColors[3].label}</span>
                <input disabled className="col input-field-cinematic-gray" style={editEpisodeReadOnlyCell}/>
                {line.comment && !inputLine? <span className="tooltip-comment-exist" style={{backgroundColor: colorLineWithComment(line)}}></span> : ''}

                <LineSidebar  
                    dispatch={dispatch} 
                    lines={lines} 
                    line={line} 
                    selectedLineIndex={selectedLineIndex} 
                    inputLine={inputLine} 
                    isPreviewInModal={isPreviewInModal}
                    modalPopupBool={modalPopupBool}
                    setYesnomodal={null}
                    editEpisodePassParam={editEpisodePassParam}
                    index={index}
                    pickedActionColor={pickedActionColor}
                />
                
            </CInputGroup>
        </div>
    )
}

export const AddOutfitLine = ({ line, inputLine, index, episodeName, serialNumber }: any) => 
{
    const dispatch = useDispatch();

    const [addLoad, setAddLoad] = useState(false);
    const alert = useAlert();

    const { editEpisodePassParam, selectedLineIndex, linesFrame, defaultBranchQandC, updateCloudBool, lines, defaultLineTypes, isOLineAdded, maxLineNumber } = useSelector((state: any) => state.editEpisodeReducer);
    const { modalPopupBool } = useSelector((state: any) => state.storiesReducer);
    const [clearedLine, clearLine] = useState(stateWithClearedLineOutfit(inputLine, selectedLineIndex, lines, line, index, modalPopupBool.isOpen, maxLineNumber, modalPopupBool.clickedLine, modalPopupBool.type));
    let currentLineTypes = createDefaultLineTypeOptions(lines, index, defaultLineTypes, defaultBranchQandC, isOLineAdded, modalPopupBool.isOpen, modalPopupBool.type);

    useEffect(() => 
    {
        clearLine(stateWithClearedLineOutfit(inputLine, selectedLineIndex, lines, line, index, modalPopupBool.isOpen, maxLineNumber, modalPopupBool.clickedLine, modalPopupBool.type))
    }, [line,maxLineNumber])

    return (<Formik initialValues={
        {
            lineNumber: clearedLine.lineNumber, 
            lineType: LineTypes.O1
        }}
        onSubmit={(values: LineType) => 
        {
            if(!inputLine && modalPopupBool.index != index)
            {
                dispatch(ActionCreators.setModalPopupBool({ type: 'normal', isOpen: true, isFirstTimeOpen: true, index: index, currentLine: {...lines[index]} }))
            }
            else if(line.changeIdentityOn && !line.changeIdentityName)
            {
                alert.info('You must fill in the character name in Change Identity, because you have enabled it.')
            }
            else 
            {
                let {properIndex, previousLineDocId} = getProperIndexAndDocId(lines, index, modalPopupBool.isOpen, modalPopupBool.type)
                let previousLineActions;
                var previousLine = lines.find((element : LineType) => element.docId == previousLineDocId);
                if(previousLine && modalPopupBool.isOpen && modalPopupBool.type == 'above')
                {
                    previousLineActions = {
                        backgroundId: previousLine.backgroundId,
                        backgroundPath: previousLine.backgroundPath,
                        backgroundName: previousLine.backgroundName,
                        backgroundDescription: previousLine.backgroundDescription,
                        musicId: previousLine.musicId,
                        musicPath: previousLine.musicPath,
                        musicName: previousLine.musicName,
                        musicDescription: previousLine.musicDescription,
                        shortSoundId: previousLine.shortSoundId,
                        shortSoundPath: previousLine.shortSoundPath,
                        shortSoundName: previousLine.shortSoundName,
                        shortSoundDescription: previousLine.shortSoundDescription,
                        shortSoundEnabled: previousLine.shortSoundEnabled,
                        itemId: previousLine.itemId,
                        itemName: previousLine.itemName,
                        itemDescription: previousLine.itemDescription,
                        avatarEnabled: previousLine.avatarEnabled,
                        callPhoneEnabled: previousLine.callPhoneEnabled,
                        callSmsEnabled: previousLine.callSmsEnabled,
                        callerCharacterId: previousLine.callerCharacterId,
                        callerCharacterName: previousLine.callerCharacterName,
                        actionChangeFlag: previousLine.actionChangeFlag,
                        charmingPoint: previousLine.charmingPoint,
                        premiumChoiceText: previousLine.premiumChoiceText,
                        premiumChoiceDescription: previousLine.premiumChoiceDescription,
                        charactersOutfit: previousLine.charactersOutfit
                    }
                }

                //citamo actions iz reducera, smestamo u objekat i radimo new line
                let o1Line = { 
                    ...clearedLine,
                    ...previousLineActions,
                    episodeId: line.episodeId,
                    storyId: line.storyId,
                    storyTranslationId: line.storyTranslationId,
                    lineType: LineTypes.O1,
                    episodeName: episodeName,
                    previousLineDocId: previousLineDocId,
                    episodeSerialNumber: serialNumber
                }

                dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updating'));

                var isChangedByAction = validateActionChange(dispatch, o1Line, lines, lines[lines.length-1], true)
                o1Line = {...o1Line, actionChangeFlag: isChangedByAction}
            
                SaveOLine(o1Line, properIndex, linesFrame, selectedLineIndex, dispatch, alert, modalPopupBool.type, modalPopupBool.isOpen);
            }
        }}>
        {({ dirty, isValid, handleSubmit }) => 
        {
            return (
            <div>
                <CForm id="add-line-form-submit" className="row" 
                onSubmit={(e : any) => 
                { 
                    if(updateCloudBool === 'updating' || (!modalPopupBool.isOpen && selectedLineIndex !== -1 && inputLine)) 
                    {
                        e.preventDefault();
                        return;
                    } 
                    e.preventDefault(); handleSubmit(); 
                }}>
                    <CInputGroup className={inputLine ? 'col border-input-line' : 'col'} 
                        style={{ 
                            marginLeft: `${line.depth * parseInt(editEpisodeReadOnlyCell.minWidth.split("px")[0])}px` ,
                            border: (selectedLineIndex === index) ? `4px solid #bdbdbd` : "",
                            padding: "0",
                            paddingLeft: `2px`
                        }}>
                        <Field 
                            {...{
                                    as: undefined,
                                    className: getLineOptionsClass(line),
                                    name: "lineNumber",
                                    style: { backgroundColor: "rgb(216, 219, 224)", maxWidth: "50px" }
                                }
                            }
                            name="find"
                            type="text"
                            autoComplete="off"
                            value={clearedLine.lineNumber}
                            disabled>
                        </Field>
                        <Field 
                            {...{
                                    as: "select",
                                    name: `lineType`,
                                    className:"col-auto cursor",
                                    value: line.lineType,
                                    onChange:(event: any) => 
                                    {
                                        if(modalPopupBool.isOpen)
                                        {
                                            dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine: {...lines[index], lineType: parseInt(event.target.value)}, isFirstTimeOpen: false }))
                                        }
                                        else
                                        {
                                            dispatch(ActionCreatorsForEditEpisode.updateLineTypeParam(parseInt(event.target.value)))
                                        }
                                    }
                                }
                            }
                            {...((!modalPopupBool.isOpen) && (inputLine && selectedLineIndex!==-1) ? {style: { backgroundColor: "rgb(216, 219, 224)" }} : {} )}
                            type="text"
                            disabled={ (!modalPopupBool.isOpen) && ( !inputLine || selectedLineIndex !== -1)}>
                            {currentLineTypes}
                        </Field>
                        <input disabled className="col input-field-cinematic-gray"/>
                    </CInputGroup >
                    {modalPopupBool.isOpen && inputLine && modalPopupBool.index === index ? <CButton className="ml-5" type="submit" color="primary" size='lg'>Add line</CButton> : '' }
                    
                    <LineSidebar  
                        dispatch={dispatch} 
                        lines={lines} 
                        line={line} 
                        selectedLineIndex={selectedLineIndex} 
                        inputLine={inputLine} 
                        isPreviewInModal={null}
                        modalPopupBool={modalPopupBool}
                        setYesnomodal={null}
                        editEpisodePassParam={editEpisodePassParam}
                        index={index}
                        pickedActionColor={null}
                    />
                    
                </CForm>
            </div>)
        }}
    </Formik>)
}