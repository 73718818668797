import React, { useEffect, useState } from 'react';
import { CButton } from '@coreui/react';
import Select from 'react-select';
import { GetSelectValuesForAssets, UpdateAssets } from '../../../services/ArtCatalog';
import { useDispatch } from 'react-redux';
import { ActionCreatorsForArtCatalog } from '../../../store/ArtCatalogReducer';
import { selectStylesForField } from '../Lines/_Helpers';
import { SaveChangesSessionLogger } from '../../../modules/firebaseDetailedLoggers';

export const AccessoryPictureBody = ({accessoryTypes, visible, setVisible, alert} : any) =>
{
    const dispatch = useDispatch();
    
    return(
        <div>
            <label>Type</label>
            <Select
                styles={selectStylesForField}
                menuPortalTarget={document.querySelector('body')}
                options={accessoryTypes} 
                value={accessoryTypes.find((element : any) => element.value === visible.object.accessoryType)}
                onChange={(value : any) => 
                { 
                    setVisible({...visible, object: {...visible.object, accessoryType: value.value }}) 
                }}
            /> 
            <CButton 
                color="primary" 
                className="mt-5"
                onClick={() => 
                {
                    const saveChangesSession = new SaveChangesSessionLogger();
                                    
                    saveChangesSession.asset_name = 'Accessory'
                    saveChangesSession.timestamp = new Date().toUTCString();
                    saveChangesSession.session_id = localStorage.getItem('sessionId') ?? '';
                    saveChangesSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                    saveChangesSession.user_id = localStorage.getItem('uid') ?? '';
                    
                    saveChangesSession.logEvent();

                    UpdateAssets('api/ArtCatalog/Update_AccessoryAssetTags', 
                        { 
                            docId: visible.object?.docId, 
                            accessoryType: parseInt(visible.object?.accessoryType)
                        }, alert)
                    dispatch(ActionCreatorsForArtCatalog.updateAccessories({id: visible.object?.docId, accessoryType: parseInt(visible.object?.accessoryType)}))
                }}
            >Save Changes</CButton>
        </div>
    )
}