export const eventNames = {
    storyStatusChanged: 'story_status_changed',
    episodeAdded: 'new_episode_added',
    episodeStatusChanged: 'episode_status_changed',
    episodeSubmitted: 'episode_submitted',
    writtingSesssion: 'writting_session',
    setUpSession: 'set_up_session',
    reviewSession: 'review_session',
    translationSession: 'translation_session',
    proofreadSession: 'proof_read_session',

    //CLICK SESSIONS
    logoutClickSession: 'click_logout',

    storySelectionClickSession: 'click_stories_story_selection',
    episodeSelectionClickSession: 'click_stories_edit_episode',
    storiesSetupStoryClickSession: 'click_stories_setup_story',

    storyDropdownClickSession: 'click_edit_episode_story_dropdown',
    episodeDropdownClickSession: 'click_edit_episode_episode_dropdown',
    editEpisodeSetupStoryClickSession: 'click_edit_episode_setup_story',
    findTextSession: 'click_edit_episode_find_text',
    highlightSession: 'click_edit_episode_highlight',
    resetToDefaultSession: 'click_edit_episode_reset_to_default',
    updateLineSession: 'click_edit_episode_update_line',

    tabChangeSession: 'click_setup_story_tab_change',
    setupStoryEditSession: 'click_setup_story_edit',
    setupStoryDeleteSession: 'click_setup_story_delete',

    translationsStorySelectionClickSession: 'click_translations_story_selection',
    translationsEpisodeSelectionClickSession: 'click_translations_edit_episode',
    editLanguageSession: 'click_translations_edit_language',
    uploadSession: 'click_translations_upload',
    downloadSession: 'click_translations_download',
    scriptAppendixTabSession: 'click_translations_script_appendix_tab',
    translationsStoryDropdownClickSession: 'click_translations_story_dropdown',
    translationsEpisodeDropdownClickSession: 'click_translations_episode_dropdown',
    translationsFindTextSession: 'click_translations_find_text',

    applyFiltersSession: 'click_art_catalog_apply_filters',
    artCatalogTabChangeSession: 'click_art_catalog_tab_change',
    openImageSession: 'click_art_catalog_open_image',
    saveChangesSession: 'click_art_catalog_save_changes'
}

export const wgDetailedSeparator = 'wg_'