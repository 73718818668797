import { CForm, CInputGroup } from '@coreui/react';
import React, { useEffect } from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorsForTranslations } from '../../../../../store/TranslationsReducer';
import { editEpisodeReadOnlyCell } from '../../../Lines/_Helpers';
import { calculateMovementOnClickLine } from '../_TranslationsHelpers';

export const FashionTranslationLine = ({ line, index, inputLine, isPreview } : any) =>
{
    const { lineTypes, selectedLineIndex, serbianTranslate, englishTranslate, clothingCombinations, otherLanguageTranslate, linesFrame, defaultLine } = useSelector((state: any) => state.translationsReducer);
    const dispatch = useDispatch();

    useEffect(() => 
    {
        let lineNumber = document.querySelector(`span[id="lineNumber${index}"]`);
        if(lineNumber) lineNumber.innerHTML = line.lineNumber;
        let lineType = document.querySelector(`span[id="lineType${index}"]`);
        if(lineType) 
        { 
            let types = lineTypes?.filter((x:any) => x.id == line.lineType);
            lineType.innerHTML = types.length > 0 ? types[0].label : '';
        }

        var array = [];
        let fashion1 = clothingCombinations.find((element : any) => element.id == line.f_ClothingComb1)?.label
        if(fashion1) array.push(fashion1)
        let fashion2 = clothingCombinations.find((element : any) => element.id == line.f_ClothingComb2)?.label
        if(fashion2) array.push(fashion2)
        let fashion3 = clothingCombinations.find((element : any) => element.id == line.f_ClothingComb3)?.label
        if(fashion3) array.push(fashion3)
        let fashion4 = clothingCombinations.find((element : any) => element.id == line.f_ClothingComb4)?.label
        if(fashion4) array.push(fashion4)
        let fashion5 = clothingCombinations.find((element : any) => element.id == line.f_ClothingComb5)?.label
        if(fashion5) array.push(fashion5)

        let fashion = document.querySelector(`span[id="fashion${index}"]`);
        if(fashion) fashion.innerHTML = array.map((element : any) => element).join(', ')
    }, [line, lineTypes, clothingCombinations])

    return(
    <div>
        <CForm className={inputLine ? 'is-input-translations row' : 'row'}>
                <CInputGroup className='col c-line-trns-clth ' style={{marginLeft: !inputLine ? `${line.depth * parseInt(editEpisodeReadOnlyCell.minWidth.split("px")[0])}px` : "" ,}}
            onClick={() =>
                {
                    if(!inputLine && !isPreview)
                    {
                        let movement = calculateMovementOnClickLine(index, selectedLineIndex, linesFrame, serbianTranslate, englishTranslate)

                        dispatch(ActionCreatorsForTranslations.setCurrentLineForTranslations(
                        {
                            currentLineSerbian: serbianTranslate && serbianTranslate?.length != 0 && !serbianTranslate[0]?.isDefault ? serbianTranslate[movement] : defaultLine,
                            currentLineEnglish: englishTranslate && englishTranslate?.length != 0 && !englishTranslate[0]?.isDefault ? englishTranslate[movement] : defaultLine,
                            currentLineOther: otherLanguageTranslate && otherLanguageTranslate?.length != 0 && !otherLanguageTranslate[0]?.isDefault ? otherLanguageTranslate[movement] : defaultLine,
                            currentLineIndex: movement, 
                            linesStartIndex: movement + 1 > linesFrame ? movement + 1 - linesFrame : 0
                        }))
                    }
                }}
            >
                <span style={editEpisodeReadOnlyCell} id={`lineNumber${index}`}></span>
                    <span style={editEpisodeReadOnlyCell}  id={`lineType${index}`}></span>
                    <span style={editEpisodeReadOnlyCell} className="col c-line-trns" id={`fashion${index}`}></span>
            </CInputGroup>
        </CForm>
    </div>
    )
}

export default FashionTranslationLine;