const initialState = 
{
    loadingLine: false
}

export const ActionTypes =
{
    SET_LOADING_LINE: 'SET_LOADING_LINE'
}

export const ActionCreatorsForAddingLine = 
{
    setLoadingLine: (payload: boolean) => ({type: ActionTypes.SET_LOADING_LINE, payload})
}

export default function addingLineReducer(state=initialState, action: any)
{
    switch(action.type)
    {
        case ActionTypes.SET_LOADING_LINE:
            return {...state, loadingLine: action.payload};
        default:
            return state;
    }
}