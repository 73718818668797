import React, { useRef } from 'react';
import { CDataTable, CForm, CTooltip } from '@coreui/react';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { TranslateCertainPart } from '../../../services/TranslationsServices';
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorsForTranslations } from '../../../store/TranslationsReducer';
import { AiFillCopy, AiOutlineCopy } from 'react-icons/ai';

let textYupSchema = yup.object().shape({
    text: yup.string()
});

export const AppendixTitleDescriptionTable = ({ columns, data, storyId, serialNumber, language, nativeLanguage, languageData } : any) =>
{
    const { currentTranslationStatus } = useSelector((state: any) => state.translationsReducer);
    
    const alert = useAlert();
    const dispatch = useDispatch();
    
    return(<div>
        <CDataTable
            addTableClasses='stories-data-table'
            items={data}
            fields={columns}
            striped  
            hover
            sorter
            pagination
            responsive={true}
            scopedSlots={{
                'serbianDescription':
                (item : any, index : any) => 
                {
                    var isReadOnly = language != 0 || currentTranslationStatus == 7;
                    return(
                    <td>
                        <Formik 
                            enableReinitialize
                            initialValues={{text: item?.serbianDescription ?? ''}}
                            onSubmit={(values) => 
                            { 
                                if (!isReadOnly)
                                {
                                    switch(index)
                                    {
                                        case 0:
                                            TranslateCertainPart('api/Translations/TranslateStoryTitle', {
                                                storyId: storyId, 
                                                language: 0,
                                                text: values.text
                                            }, alert)
                                            break;
                                        case 1:
                                            TranslateCertainPart('api/Translations/TranslateStoryFeatureText', {
                                                storyId: storyId, 
                                                language: 0,
                                                text: values.text
                                            }, alert)
                                            break;
                                        case 2:
                                            TranslateCertainPart('api/Translations/TranslateEpisodeDescription', {
                                                storyId: storyId, 
                                                language: 0,
                                                serialNum: parseInt(serialNumber),
                                                text: values.text
                                            }, alert)
                                            break;
                                        default: 
                                            return;
                                    }
                                }
                            }
                            }
                            validationSchema={textYupSchema}>
                            {({ dirty, isValid, handleSubmit }) => 
                            {
                                return(
                                    <CForm onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                        <div className="row-style mt-1">
                                            <CTooltip placement="top" content={item?.serbianDescription ? item?.serbianDescription?.replace('\n', '<br>') : 'Field is empty.'}>
                                            <Field
                                                className="form-control"
                                                name="text"
                                                type="text"
                                                readOnly={isReadOnly}
                                                autoComplete="off"
                                                onBlurCapture={(e : any) => 
                                                {
                                                    if (!isReadOnly)
                                                    {
                                                        let value = e.target.value
                                                        if(value != item.serbianDescription)
                                                        {
                                                            switch(index)
                                                            {
                                                                case 0:
                                                                    dispatch(ActionCreatorsForTranslations.updateSerbianDescription({index, value}))
                                                                    TranslateCertainPart('api/Translations/TranslateStoryTitle', {
                                                                        storyId: storyId, 
                                                                        language: 0,
                                                                        text: value
                                                                    }, alert)
                                                                    break;
                                                                case 1:
                                                                    dispatch(ActionCreatorsForTranslations.updateSerbianDescription({index, value}))
                                                                    TranslateCertainPart('api/Translations/TranslateStoryFeatureText', {
                                                                        storyId: storyId, 
                                                                        language: 0,
                                                                        text: value
                                                                    }, alert)
                                                                    break;
                                                                case 2:
                                                                    dispatch(ActionCreatorsForTranslations.updateSerbianDescription({index, value}))
                                                                    TranslateCertainPart('api/Translations/TranslateEpisodeDescription', {
                                                                        storyId: storyId, 
                                                                        language: 0,
                                                                        serialNum: parseInt(serialNumber),
                                                                        text: value
                                                                    }, alert)
                                                                    break;
                                                                default: 
                                                                    return;
                                                            }
                                                        }
                                                    }
                                                }} >
                                            </Field>
                                            </CTooltip>
                                            { item?.serbianDescription ? <AiFillCopy className="cursor mt-2 ml-1" color="blue" size={19} onClick={() => navigator.clipboard.writeText(item?.serbianDescription)}/> :  <AiFillCopy style={{visibility: 'hidden'}} className="cursor mt-2 ml-1" color="blue" size={19} /> }
                                        </div>
                                    </CForm>
                                )
                            }}
                        </Formik>
                    </td>)
                },
                'englishDescription':
                (item : any, index : any) => 
                {
                    var isReadOnly = language != 1 || currentTranslationStatus == 7;
                    return(
                    <td>
                        <Formik 
                            enableReinitialize
                            initialValues={{text: item?.englishDescription ?? ''}}
                            onSubmit={(values) => 
                            { 
                                if (!isReadOnly)
                                {
                                    switch(index)
                                    {
                                        case 0:
                                            TranslateCertainPart('api/Translations/TranslateStoryTitle', {
                                                storyId: storyId, 
                                                language: 1,
                                                text: values.text
                                            }, alert)
                                            break;
                                        case 1:
                                            TranslateCertainPart('api/Translations/TranslateStoryFeatureText', {
                                                storyId: storyId, 
                                                language: 1,
                                                text: values.text
                                            }, alert)
                                            break;
                                        case 2:
                                            TranslateCertainPart('api/Translations/TranslateEpisodeDescription', {
                                                storyId: storyId, 
                                                language: 1,
                                                serialNum: parseInt(serialNumber),
                                                text: values.text
                                            }, alert)
                                            break;
                                        default: 
                                            return;
                                    }
                                }
                            }
                            }
                            validationSchema={textYupSchema}>
                            {({ dirty, isValid, handleSubmit, values }) => 
                            {
                                return(
                                    <CForm onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                        <div className="row-style mt-1">
                                            <CTooltip placement="top" content={item?.englishDescription ? item?.englishDescription?.replace('\n', '<br>') : 'Field is empty.'}>
                                            <Field
                                                className="form-control"
                                                name="text"
                                                type="text"
                                                readOnly={isReadOnly}
                                                autoComplete="off"
                                                onBlurCapture={(e : any) => 
                                                {
                                                    if (!isReadOnly)
                                                    {
                                                        let value = e.target.value
                                                        if(value != item.englishDescription)
                                                        {
                                                            switch(index)
                                                            {
                                                                case 0:
                                                                    dispatch(ActionCreatorsForTranslations.updateEnglishDescription({index, value}))
                                                                    TranslateCertainPart('api/Translations/TranslateStoryTitle', {
                                                                        storyId: storyId, 
                                                                        language: 1,
                                                                        text: value
                                                                    }, alert)
                                                                    break;
                                                                case 1:
                                                                    dispatch(ActionCreatorsForTranslations.updateEnglishDescription({index, value}))
                                                                    TranslateCertainPart('api/Translations/TranslateStoryFeatureText', {
                                                                        storyId: storyId, 
                                                                        language: 1,
                                                                        text: value
                                                                    }, alert)
                                                                    break;
                                                                case 2:
                                                                    dispatch(ActionCreatorsForTranslations.updateEnglishDescription({index, value}))
                                                                    
                                                                    TranslateCertainPart('api/Translations/TranslateEpisodeDescription', {
                                                                        storyId: storyId, 
                                                                        language: 1,
                                                                        serialNum: parseInt(serialNumber),
                                                                        text: value
                                                                    }, alert)
                                                                    break;
                                                                default: 
                                                                    return;
                                                            }
                                                        }
                                                    }
                                                }}  
                                            >
                                            </Field>
                                            </CTooltip>
                                            { item?.englishDescription ? <AiFillCopy className="cursor mt-2 ml-1" color="blue" size={19} onClick={() => navigator.clipboard.writeText(item?.englishDescription)}/> :  <AiFillCopy style={{visibility: 'hidden'}} className="cursor mt-2 ml-1" color="blue" size={19} /> }
                                        </div>
                                    </CForm>
                                )
                            }}
                        </Formik>
                    </td>)
                },
                'otherDescription':
                (item : any, index : any) => 
                {
                    var isReadOnly = (language == 0 || language == 1) || currentTranslationStatus == 7;
                    return(
                    <td>
                        <Formik 
                            enableReinitialize
                            initialValues={{text: item?.otherDescription ?? ''}}
                            onSubmit={(values) => 
                            { 
                                if (!isReadOnly)
                                {
                                    switch(index)
                                    {
                                        case 0:
                                            TranslateCertainPart('api/Translations/TranslateStoryTitle', {
                                                storyId: storyId, 
                                                language: parseInt(language),
                                                text: values.text
                                            }, alert)
                                            break;
                                        case 1:
                                            TranslateCertainPart('api/Translations/TranslateStoryFeatureText', {
                                                storyId: storyId, 
                                                language: parseInt(language),
                                                text: values.text
                                            }, alert)
                                            break;
                                        case 2:
                                            TranslateCertainPart('api/Translations/TranslateEpisodeDescription', {
                                                storyId: storyId, 
                                                language: parseInt(language),
                                                serialNum: parseInt(serialNumber),
                                                text: values.text
                                            }, alert)
                                            break;
                                        default: 
                                            return;
                                    }
                                }
                            }
                            }
                            validationSchema={textYupSchema}>
                            {({ dirty, isValid, handleSubmit }) => 
                            {
                                return(
                                    <CForm onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                        <div className="row-style mt-1">
                                            <CTooltip placement="top" content={item?.otherDescription ? item.otherDescription?.replace('\n', '<br>') : 'Field is empty.'}>
                                            <Field
                                                className="form-control"
                                                name="text"
                                                type="text"
                                                readOnly={isReadOnly}
                                                autoComplete="off"
                                                onBlurCapture={(e : any) => 
                                                {
                                                    if (!isReadOnly)
                                                    {
                                                        let value = e.target.value
                                                        if(value != item.otherDescription)
                                                        {
                                                            switch(index)
                                                            {
                                                                case 0:
                                                                    dispatch(ActionCreatorsForTranslations.updateOtherDescription({index, value}))
                                                                    TranslateCertainPart('api/Translations/TranslateStoryTitle', {
                                                                        storyId: storyId, 
                                                                        language: parseInt(language),
                                                                        text: value
                                                                    }, alert)
                                                                    break;
                                                                case 1:
                                                                    dispatch(ActionCreatorsForTranslations.updateOtherDescription({index, value}))
                                                                    TranslateCertainPart('api/Translations/TranslateStoryFeatureText', {
                                                                        storyId: storyId, 
                                                                        language: parseInt(language),
                                                                        text: value
                                                                    }, alert)
                                                                    break;
                                                                case 2:
                                                                    dispatch(ActionCreatorsForTranslations.updateOtherDescription({index, value}))
                                                                    TranslateCertainPart('api/Translations/TranslateEpisodeDescription', {
                                                                        storyId: storyId, 
                                                                        language: parseInt(language),
                                                                        serialNum: parseInt(serialNumber),
                                                                        text: value
                                                                    }, alert)
                                                                    break;
                                                                default: 
                                                                    return;
                                                            }
                                                        }
                                                    }
                                                }} 
                                            >
                                            </Field>
                                            </CTooltip>
                                            { item?.otherDescription ? <AiFillCopy className="cursor mt-2 ml-1" color="blue" size={19} onClick={() => navigator.clipboard.writeText(item?.otherDescription)}/> :  <AiFillCopy style={{visibility: 'hidden'}} className="cursor mt-2 ml-1" color="blue" size={19} /> }
                                        </div>
                                    </CForm>
                                )
                            }}
                        </Formik>
                    </td>)
                }
            }}
        />
    </div>)
}

//OTHERS DATA TABLE -----------------------------------------------------------------------------------------------------------------

export const AppendixOthersDataTable = ({ columns, data, storyId, serialNumber, language, nativeLanguage, languageData, urlForApi, tableFixedId } : any) =>
{
    const { currentTranslationStatus } = useSelector((state: any) => state.translationsReducer);
    
    const alert = useAlert();
    const dispatch = useDispatch();

    return(<div>
        <CDataTable
            addTableClasses='stories-data-table'
            items={data}
            fields={columns}
            striped  
            hover
            sorter
            pagination
            responsive={true}
            scopedSlots={{
                'serbianName':
                (item : any, index : any) => 
                {
                    var isReadOnly = language != 0 || currentTranslationStatus == 7;
                    return(
                        <td>
                            <Formik 
                                enableReinitialize
                                initialValues={{text: item?.serbianName ?? ''}}
                                onSubmit={(values) => 
                                { 
                                    if (!isReadOnly)
                                    {
                                        var objectForApi : any =
                                        { 
                                            storyId: storyId, 
                                            language: 0
                                        };

                                        switch(tableFixedId)
                                        {
                                            case 1:
                                                objectForApi = 
                                                {
                                                    ...objectForApi, 
                                                    episodeSerialNum: parseInt(serialNumber), 
                                                    text: values.text, 
                                                    lineId: parseInt(item.lineId)
                                                }
                                                break;
                                            case 2:
                                                objectForApi = 
                                                {
                                                    ...objectForApi, 
                                                    episodeSerialNum: parseInt(serialNumber), 
                                                    text: values.text, 
                                                    lineId: parseInt(item.lineId)
                                                }
                                                break;
                                            case 3:
                                                objectForApi = 
                                                {
                                                    ...objectForApi, 
                                                    name: values.text,
                                                    entityid: item.characterDocId
                                                }
                                                break;
                                            case 4:
                                                objectForApi = 
                                                {
                                                    ...objectForApi, 
                                                    name: values.text,
                                                    entityid: item.hairstyleDocId
                                                }
                                                break;
                                            case 5:
                                                objectForApi = 
                                                {
                                                    ...objectForApi, 
                                                    name: values.text,
                                                    entityid: item.clothesDocId
                                                }
                                                break;
                                            case 6:
                                                objectForApi = 
                                                {
                                                    ...objectForApi, 
                                                    episodeSerialNum: parseInt(serialNumber), 
                                                    text: values.text, 
                                                    lineId: parseInt(item.lineId)
                                                }
                                                break;
                                            case 7:
                                                objectForApi =
                                                {
                                                    ...objectForApi, 
                                                    episodeSerialNum: parseInt(serialNumber),
                                                    name: values.text, 
                                                    entityid: item.backgroundDocId
                                                }
                                                break;
                                            default: 
                                                return;
                                        }

                                        TranslateCertainPart(urlForApi, objectForApi, alert)
                                    }
                                }
                                }
                                validationSchema={textYupSchema}>
                                {({ dirty, isValid, handleSubmit }) => 
                                {
                                    return(
                                        <CForm onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                            <div className="row-style mt-1">
                                                <CTooltip placement="top" content={item?.serbianName ? item?.serbianName?.replace('\n', '<br>') : 'Field is empty.'}>
                                                <Field
                                                    className="form-control"
                                                    name="text"
                                                    type="text"
                                                    readOnly={isReadOnly}
                                                    autoComplete="off"
                                                    onBlurCapture={(e : any) => 
                                                    {
                                                        if (!isReadOnly)
                                                        {
                                                            let value = e.target.value
                                                            if(value != item.serbianName)
                                                            {
                                                                var objectForApi : any =
                                                                { 
                                                                    storyId: storyId, 
                                                                    language: 0
                                                                };
                                                                switch(tableFixedId)
                                                                {
                                                                    case 1:
                                                                        objectForApi = 
                                                                        {
                                                                            ...objectForApi, 
                                                                            episodeSerialNum: parseInt(serialNumber), 
                                                                            text: value, 
                                                                            lineId: parseInt(item.lineId)
                                                                        }
                                                                        dispatch(ActionCreatorsForTranslations.updateSerbianChapterSummary({index, value}))
                                                                        break;
                                                                    case 2:
                                                                        objectForApi = 
                                                                        {
                                                                            ...objectForApi, 
                                                                            episodeSerialNum: parseInt(serialNumber), 
                                                                            text: value, 
                                                                            lineId: parseInt(item.lineId)
                                                                        }
                                                                        dispatch(ActionCreatorsForTranslations.updateSerbianCharmingPoint({index, value}))
                                                                        break;
                                                                    case 3:
                                                                        objectForApi = 
                                                                        {
                                                                            ...objectForApi, 
                                                                            name: value,
                                                                            entityid: item.characterDocId
                                                                        }
                                                                        dispatch(ActionCreatorsForTranslations.updateSerbianCharacters({index, value}))
                                                                        break;
                                                                    case 4:
                                                                        objectForApi = 
                                                                        {
                                                                            ...objectForApi, 
                                                                            name: value,
                                                                            entityid: item.hairstyleDocId
                                                                        }
                                                                        dispatch(ActionCreatorsForTranslations.updateSerbianHairstyles({index, value}))
                                                                        break;
                                                                    case 5:
                                                                        objectForApi = 
                                                                        {
                                                                            ...objectForApi, 
                                                                            name: value,
                                                                            entityid: item.clothesDocId
                                                                        }
                                                                        dispatch(ActionCreatorsForTranslations.updateSerbianClothes({index, value}))
                                                                        break;
                                                                    case 6:
                                                                        objectForApi = 
                                                                        {
                                                                            ...objectForApi, 
                                                                            episodeSerialNum: parseInt(serialNumber), 
                                                                            text: value, 
                                                                            lineId: parseInt(item.lineId)
                                                                        }
                                                                        dispatch(ActionCreatorsForTranslations.updateSerbianDescriptionBubble({index, value}))
                                                                        break;
                                                                    case 7: 
                                                                        objectForApi = 
                                                                        {
                                                                            ...objectForApi, 
                                                                            name: value,
                                                                            entityid: item.backgroundDocId
                                                                        }
                                                                        dispatch(ActionCreatorsForTranslations.updateSerbianBackgroundName({index, value}))
                                                                        break;
                                                                    default: 
                                                                        return;
                                                                }
                            
                                                                TranslateCertainPart(urlForApi, objectForApi, alert)
                                                            }
                                                        }
                                                    }}
                                                >
                                                </Field>
                                                </CTooltip>
                                                { item?.serbianName ? <AiFillCopy className="cursor mt-2 ml-1" color="blue" size={19} onClick={() => navigator.clipboard.writeText(item?.serbianName)}/> :  <AiFillCopy style={{visibility: 'hidden'}} className="cursor mt-2 ml-1" color="blue" size={19} /> }
                                            </div>
                                        </CForm>
                                    )
                                }}
                            </Formik>
                        </td>
                    )
                },
                'englishName':
                (item : any, index : any) => 
                {
                    var isReadOnly = language != 1 || currentTranslationStatus == 7;
                    return(
                        <td>
                            <Formik 
                                enableReinitialize
                                initialValues={{text: item?.englishName ?? ''}}
                                onSubmit={(values) => 
                                { 
                                    if (!isReadOnly)
                                    {
                                        var objectForApi : any =
                                        { 
                                            storyId: storyId, 
                                            language: 1
                                        };

                                        switch(tableFixedId)
                                        {
                                            case 1:
                                                objectForApi = 
                                                {
                                                    ...objectForApi, 
                                                    episodeSerialNum: parseInt(serialNumber), 
                                                    text: values.text, 
                                                    lineId: parseInt(item.lineId)
                                                }
                                                break;
                                            case 2:
                                                objectForApi = 
                                                {
                                                    ...objectForApi, 
                                                    episodeSerialNum: parseInt(serialNumber), 
                                                    text: values.text, 
                                                    lineId: parseInt(item.lineId)
                                                }
                                                break;
                                            case 3:
                                                objectForApi = 
                                                {
                                                    ...objectForApi, 
                                                    name: values.text,
                                                    entityid: item.characterDocId
                                                }
                                                break;
                                            case 4:
                                                objectForApi = 
                                                {
                                                    ...objectForApi, 
                                                    name: values.text,
                                                    entityid: item.hairstyleDocId
                                                }
                                                break;
                                            case 5:
                                                objectForApi = 
                                                {
                                                    ...objectForApi, 
                                                    name: values.text,
                                                    entityid: item.clothesDocId
                                                }
                                                break;
                                            case 6:
                                                    objectForApi = 
                                                    {
                                                        ...objectForApi, 
                                                        episodeSerialNum: parseInt(serialNumber), 
                                                        text: values.text, 
                                                        lineId: parseInt(item.lineId)
                                                    }
                                                    break;
                                            case 7: 
                                                objectForApi = 
                                                {
                                                    ...objectForApi, 
                                                    name: values.text,
                                                    entityid: item.backgroundDocId
                                                }
                                                break;
                                            default: 
                                                return;
                                        }

                                        TranslateCertainPart(urlForApi, objectForApi, alert)
                                    }
                                }
                                }
                                validationSchema={textYupSchema}>
                                {({ dirty, isValid, handleSubmit }) => 
                                {
                                    return(
                                        <CForm onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                            <div className="row-style mt-1">
                                                <CTooltip placement="top" content={item?.englishName ? item?.englishName?.replace('\n', '<br>') : 'Field is empty.'}>
                                                <Field
                                                    className="form-control"
                                                    name="text"
                                                    type="text"
                                                    readOnly={isReadOnly}
                                                    autoComplete="off"
                                                    onBlurCapture={(e : any) => 
                                                    {
                                                        if (!isReadOnly)
                                                        {
                                                            let value = e.target.value
                                                            if(value != item.englishName)
                                                            {
                                                                var objectForApi : any =
                                                                { 
                                                                    storyId: storyId, 
                                                                    language: 1
                                                                };
                            
                                                                switch(tableFixedId)
                                                                {
                                                                    case 1:
                                                                        objectForApi = 
                                                                        {
                                                                            ...objectForApi, 
                                                                            episodeSerialNum: parseInt(serialNumber), 
                                                                            text: value, 
                                                                            lineId: parseInt(item.lineId)
                                                                        }
                                                                        dispatch(ActionCreatorsForTranslations.updateEnglishChapterSummary({index, value}))
                                                                        break;
                                                                    case 2:
                                                                        objectForApi = 
                                                                        {
                                                                            ...objectForApi, 
                                                                            episodeSerialNum: parseInt(serialNumber), 
                                                                            text: value, 
                                                                            lineId: parseInt(item.lineId)
                                                                        }
                                                                        dispatch(ActionCreatorsForTranslations.updateEnglishCharmingPoint({index, value}))
                                                                        break;
                                                                    case 3:
                                                                        objectForApi = 
                                                                        {
                                                                            ...objectForApi, 
                                                                            name: value,
                                                                            entityid: item.characterDocId
                                                                        }
                                                                        dispatch(ActionCreatorsForTranslations.updateEnglishCharacters({index, value}))
                                                                        break;
                                                                    case 4:
                                                                        objectForApi = 
                                                                        {
                                                                            ...objectForApi, 
                                                                            name: value,
                                                                            entityid: item.hairstyleDocId
                                                                        }
                                                                        dispatch(ActionCreatorsForTranslations.updateEnglishHairstyles({index, value}))
                                                                        break;
                                                                    case 5:
                                                                        objectForApi = 
                                                                        {
                                                                            ...objectForApi, 
                                                                            name: value,
                                                                            entityid: item.clothesDocId
                                                                        }
                                                                        dispatch(ActionCreatorsForTranslations.updateEnglishClothes({index, value}))
                                                                        break;
                                                                    case 6:
                                                                        objectForApi = 
                                                                        {
                                                                            ...objectForApi, 
                                                                            episodeSerialNum: parseInt(serialNumber), 
                                                                            text: value, 
                                                                            lineId: parseInt(item.lineId)
                                                                        }
                                                                        dispatch(ActionCreatorsForTranslations.updateEnglishDescriptionBubble({index, value}))
                                                                        break;
                                                                    case 7: 
                                                                        objectForApi = 
                                                                        {
                                                                            ...objectForApi, 
                                                                            name: value,
                                                                            entityid: item.backgroundDocId
                                                                        }
                                                                        dispatch(ActionCreatorsForTranslations.updateEnglishBackgroundName({index, value}))
                                                                        break;
                                                                    default: 
                                                                        return;
                                                                }
                            
                                                                TranslateCertainPart(urlForApi, objectForApi, alert)
                                                            }
                                                        }
                                                    }}
                                                >
                                                </Field>
                                                </CTooltip>
                                                { item?.englishName ? <AiFillCopy className="cursor mt-2 ml-1" color="blue" size={19} onClick={() => navigator.clipboard.writeText(item?.englishName)}/> :  <AiFillCopy style={{visibility: 'hidden'}} className="cursor mt-2 ml-1" color="blue" size={19} /> }
                                            </div>
                                        </CForm>
                                    )
                                }}
                            </Formik>
                        </td>
                    )
                },
                'otherName':
                (item : any, index : any) => 
                {
                    var isReadOnly = (language == 0 || language == 1) || currentTranslationStatus == 7;
                    return(
                        <td>
                            <Formik 
                                enableReinitialize
                                initialValues={{text: item?.otherName ?? ''}}
                                onSubmit={(values) => 
                                { 
                                    if (!isReadOnly)
                                    {
                                        var objectForApi : any =
                                        { 
                                            storyId: storyId, 
                                            language: parseInt(language)
                                        };

                                        switch(tableFixedId)
                                        {
                                            case 1:
                                                objectForApi = 
                                                {
                                                    ...objectForApi, 
                                                    episodeSerialNum: parseInt(serialNumber), 
                                                    text: values.text, 
                                                    lineId: parseInt(item.lineId)
                                                }
                                                break;
                                            case 2:
                                                objectForApi = 
                                                {
                                                    ...objectForApi, 
                                                    episodeSerialNum: parseInt(serialNumber), 
                                                    text: values.text, 
                                                    lineId: parseInt(item.lineId)
                                                }
                                                break;
                                            case 3:
                                                objectForApi = 
                                                {
                                                    ...objectForApi, 
                                                    name: values.text,
                                                    entityid: item.characterDocId
                                                }
                                                break;
                                            case 4:
                                                objectForApi = 
                                                {
                                                    ...objectForApi, 
                                                    name: values.text,
                                                    entityid: item.hairstyleDocId
                                                }
                                                break;
                                            case 5:
                                                objectForApi = 
                                                {
                                                    ...objectForApi, 
                                                    name: values.text,
                                                    entityid: item.clothesDocId
                                                }
                                                break;
                                            case 6:
                                                objectForApi = 
                                                {
                                                    ...objectForApi, 
                                                    episodeSerialNum: parseInt(serialNumber), 
                                                    text: values.text, 
                                                    lineId: parseInt(item.lineId)
                                                }
                                                break;
                                            case 7:
                                                objectForApi = 
                                                {
                                                    ...objectForApi, 
                                                    name: values.text,
                                                    entityid: item.backgroundDocId
                                                }
                                                break;
                                            default: 
                                                return;
                                        }

                                        TranslateCertainPart(urlForApi, objectForApi, alert)
                                    }
                                }
                                }
                                validationSchema={textYupSchema}>
                                {({ dirty, isValid, handleSubmit }) => 
                                {
                                    return(
                                        <CForm onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                            <div className="row-style mt-1">
                                                <CTooltip placement="top" content={item?.otherName ? item?.otherName?.replace('\n', '<br>') : 'Field is empty.'}>
                                                <Field
                                                    className="form-control"
                                                    name="text"
                                                    type="text"
                                                    readOnly={isReadOnly}
                                                    autoComplete="off"
                                                    onBlurCapture={(e : any) => 
                                                    {
                                                        if (!isReadOnly)
                                                        {
                                                            let value = e.target.value
                                                            if(value != item.otherName)
                                                            {
                                                                var objectForApi : any =
                                                                { 
                                                                    storyId: storyId, 
                                                                    language: parseInt(language)
                                                                };
                            
                                                                switch(tableFixedId)
                                                                {
                                                                    case 1:
                                                                        objectForApi = 
                                                                        {
                                                                            ...objectForApi, 
                                                                            episodeSerialNum: parseInt(serialNumber), 
                                                                            text: value, 
                                                                            lineId: parseInt(item.lineId)
                                                                        }
                                                                        dispatch(ActionCreatorsForTranslations.updateOtherChapterSummary({index, value}))
                                                                        break;
                                                                    case 2:
                                                                        objectForApi = 
                                                                        {
                                                                            ...objectForApi, 
                                                                            episodeSerialNum: parseInt(serialNumber), 
                                                                            text: value, 
                                                                            lineId: parseInt(item.lineId)
                                                                        }
                                                                        dispatch(ActionCreatorsForTranslations.updateOtherCharmingPoint({index, value}))
                                                                        break;
                                                                    case 3:
                                                                        objectForApi = 
                                                                        {
                                                                            ...objectForApi, 
                                                                            name: value,
                                                                            entityid: item.characterDocId
                                                                        }
                                                                        dispatch(ActionCreatorsForTranslations.updateOtherCharacters({index, value}))
                                                                        break;
                                                                    case 4:
                                                                        objectForApi = 
                                                                        {
                                                                            ...objectForApi, 
                                                                            name: value,
                                                                            entityid: item.hairstyleDocId
                                                                        }
                                                                        dispatch(ActionCreatorsForTranslations.updateOtherHairstyles({index, value}))
                                                                        break;
                                                                    case 5:
                                                                        objectForApi = 
                                                                        {
                                                                            ...objectForApi, 
                                                                            name: value,
                                                                            entityid: item.clothesDocId
                                                                        }
                                                                        dispatch(ActionCreatorsForTranslations.updateOtherClothes({index, value}))
                                                                        break;
                                                                    case 6:
                                                                        objectForApi = 
                                                                        {
                                                                            ...objectForApi, 
                                                                            episodeSerialNum: parseInt(serialNumber), 
                                                                            text: value, 
                                                                            lineId: parseInt(item.lineId)
                                                                        }
                                                                        dispatch(ActionCreatorsForTranslations.updateOtherDescriptionBubble({index, value}))
                                                                        break;
                                                                    case 7: 
                                                                        objectForApi = 
                                                                        {
                                                                            ...objectForApi, 
                                                                            name: value,
                                                                            entityid: item.backgroundDocId
                                                                        }
                                                                        dispatch(ActionCreatorsForTranslations.updateOtherBackgroundName({index, value}))
                                                                        break;
                                                                    default: 
                                                                        return;
                                                                }
                            
                                                                TranslateCertainPart(urlForApi, objectForApi, alert)
                                                            }
                                                        }
                                                    }}
                                                >
                                                </Field>
                                                </CTooltip>
                                                { item?.otherName ? <AiFillCopy className="cursor mt-2 ml-1" color="blue" size={19} onClick={() => navigator.clipboard.writeText(item?.otherName)}/> : <AiFillCopy style={{visibility: 'hidden'}} className="cursor mt-2 ml-1" color="blue" size={19} /> }
                                            </div>
                                        </CForm>
                                    )
                                }}
                            </Formik>
                        </td>
                    )
                },
            }}
        />
    </div>)
}
