export enum ActionColorTypes {
    Backgrounds,
    MusicThemes,
    Calls,
    ShortSounds,
    ChangeClothes,
    ChangeHairs,
    ChangeAccessories,
    CharmingPoints,
    SMS
}