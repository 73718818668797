import { axiosInstance } from '../axios/key';
import { ErrorHandler } from '../components/Helpers/ErrorHandler';

export const GetAllStoriesForArtCatalog = async () =>
{
    try
    {
        const { data } = await axiosInstance.get('api/ArtCatalog/GetStories')
        return data.map((value : any) => { return { id: value.id, label: value.title, value: value.translationId}});
    }
    catch
    {
        console.log('There was an error while trying to get stories.');
    }
}

export const GetEpisodesForArtCatalog = async (storyId: any, translationId: any) =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/ArtCatalog/GetStoryEpisodes?storyId=${storyId}&storyTranslationId=${translationId}`);
        return data.map((value : any) => { return { id: value.id, label: value.title, value: value.id}});
    }
    catch
    {
        console.log('Error in getting all stories');
    }
}

export const GetSelectValuesForAssets = async (url: any) =>
{
    try
    {
        const { data } = await axiosInstance.get(url);
        return data;
    }
    catch
    {
        console.log('Error in getting audio types.')
    }
}

export const GetAssets = async (url: any, body: any) =>
{
    try
    {
        const { data } = await axiosInstance.post(url, body);
        return data;
    }
    catch
    {
        console.log('Error in getting audio types.')
    }
}

export const UpdateAssets = async (url: any, body: any, alert : any) =>
{
    try
    {
        const { data } = await axiosInstance.post(url, body);
        alert.success('Successfully updated assets.')
        return data;
    }
    catch(e)
    {
        ErrorHandler(e, 'Error occured while trying to update assets', alert)
    }
}