import React, { useState } from 'react';
import { CButton, CCard, CCardBody, CInput, CLabel } from '@coreui/react';
import { useEffect } from 'react';
import { GetAllStoriesForHeader, GetEpisodesForHeader } from '../../../services/StoriesServices';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorsForArtCatalog } from '../../../store/ArtCatalogReducer';
import { GetAssets, GetEpisodesForArtCatalog, GetSelectValuesForAssets } from '../../../services/ArtCatalog';
import { ApplyFiltersSessionLogger } from '../../../modules/firebaseDetailedLoggers';

export const Cinematics = ({setPage, data, activeKey} : any) =>
{
    const dispatch = useDispatch();
    
    const [searchText, setSearchText] = useState('');

    const { stories, episodes } = useSelector((state: any) => state.artCatalogReducer);

    const locations = useSelector((state: any) => state.artCatalogReducer.cinematicLocations);
    const objects = useSelector((state: any) => state.artCatalogReducer.cinematicActorObjects);
    const plots = useSelector((state: any) => state.artCatalogReducer.cinematicPlots);
    
    const [chosenObject, setChosenObject] = useState({ chosenStory: null as any, chosenEpisode: null as any, chosenLocation: null as any, chosenObj: null as any, chosenPlot: null as any })

    useEffect(() => 
    {
        GetSelectValuesForAssets('api/ArtCatalog/GetCinematicLocations').then((response) => 
        { 
            dispatch(ActionCreatorsForArtCatalog.setCinematicLocations(response));
        })
        GetSelectValuesForAssets('api/ArtCatalog/GetCinematicActorObject').then((response) => 
        { 
            dispatch(ActionCreatorsForArtCatalog.setCinematicActorObjects(response));
        })
        GetSelectValuesForAssets('api/ArtCatalog/GetCinematicPlots').then((response) => 
        { 
            dispatch(ActionCreatorsForArtCatalog.setCinematicPlots(response));
        })
    }, [])

    useEffect(() => 
    {
        const timeOutId = setTimeout(() => 
        {
            let filteredLines = data?.map((l: any, i: number) => {
                return {...l, lineIndex: i}
            }).filter((l: any) => 
            {
                if(searchText && l.name?.toLowerCase().includes(searchText.toLowerCase()))
                {
                    return l;
                }
            })
            if(searchText) 
            {
                dispatch(ActionCreatorsForArtCatalog.setCinematics(filteredLines)) 
                setPage(0);
            } 
            else
            {
                dispatch(ActionCreatorsForArtCatalog.setLoadingImages(true));
                GetAssets('api/ArtCatalog/GetAssets_Cinematics', { storyId: null, episodeId: null, location: null, object: null, plot: null, searchValue: null }).then((response) => 
                { 
                    dispatch(ActionCreatorsForArtCatalog.setCinematics(response)) 
                })
            }
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [searchText])

    useEffect(() => 
    { 
        if(data.length === 0)
            setChosenObject({ chosenStory: null, chosenEpisode: null, chosenLocation: null, chosenObj: null, chosenPlot: null }) 
    }, [activeKey])

    return(<div className=" mt-3 form-filter-top-art-catalog">
        <div className="row-style art-cat-filters-row" style={{width: '1500px'}}>
            <div className="form-group-name col-md">
                <CLabel htmlFor="story">Find</CLabel>
                <CInput
                    className="form-control"
                    name="episode"
                    placeholder="Search for a cinematic"
                    value={searchText}
                    onChange={(e : any) => setSearchText(e.target.value) }
                    type="text"
                    autoComplete="off"
                    required>
                </CInput>
            </div>
            <div className="form-group-name col-md ">
                <CLabel htmlFor="story">Story</CLabel>
                <Select
                    options={stories}
                    value={chosenObject.chosenStory}
                    isClearable
                    onChange={(value : any) => 
                    { 
                        if(value != null)
                        {
                            GetEpisodesForArtCatalog(value.id, value.value).then((response) => 
                            { 
                                dispatch(ActionCreatorsForArtCatalog.setEpisodes(response))
                                setChosenObject({...chosenObject, chosenStory: value, chosenEpisode: null});
                            });
                        }
                        else
                        {
                            setChosenObject({...chosenObject, chosenStory: null, chosenEpisode: null});
                        }
                    }}
                    as="select"
                    autoComplete="off"
                    placeholder="Choose a story..."
                    required >
                </Select>
            </div>
            <div className="form-group-name col-md ">
                <CLabel htmlFor="episode">Episode</CLabel>
                <Select
                    options={episodes}
                    value={chosenObject.chosenEpisode}
                    isClearable
                    onChange={(value : any) => 
                    { 
                        setChosenObject({...chosenObject, chosenEpisode: value});
                    }}
                    name="episode"
                    as="select"
                    autoComplete="off"
                    placeholder="Enter episode..."
                    required>
                </Select>
            </div>
            <div className="form-group-name col-md ">
                <CLabel htmlFor="episode">Location</CLabel>
                <Select
                    options={locations}
                    value={chosenObject.chosenLocation}
                    isClearable
                    onChange={(value : any) => 
                    { 
                        setChosenObject({...chosenObject, chosenLocation: value});
                    }}
                    name="episode"
                    as="select"
                    autoComplete="off"
                    required>
                </Select>
            </div>
            <div className="form-group-name col-md ">
                <CLabel htmlFor="episode">Object</CLabel>
                <Select
                    options={objects}
                    value={chosenObject.chosenObj}
                    isClearable
                    onChange={(value : any) => 
                    { 
                        setChosenObject({...chosenObject, chosenObj: value});
                    }}
                    name="episode"
                    as="select"
                    autoComplete="off"
                    required>
                </Select>
            </div>
            <div className="form-group-name col-md ">
                <CLabel htmlFor="episode">Plot</CLabel>
                <Select
                    options={plots}
                    value={chosenObject.chosenPlot}
                    isClearable
                    onChange={(value : any) => 
                    { 
                        setChosenObject({...chosenObject, chosenPlot: value});
                    }}
                    name="episode"
                    as="select"
                    autoComplete="off"
                    required>
                </Select>
            </div>
            <CButton className="btn-primary-sm wide btn-fin-review-btn float-right" onClick={() =>
            {
                dispatch(ActionCreatorsForArtCatalog.setLoadingImages(true));

                const applyFiltersSession = new ApplyFiltersSessionLogger();

                applyFiltersSession.asset_name = 'Cinematics';
                applyFiltersSession.query_string = searchText ?? '';
                applyFiltersSession.story_name = chosenObject?.chosenStory?.label ?? '';
                applyFiltersSession.episode_name = chosenObject?.chosenEpisode?.label ?? '';
                applyFiltersSession.location = chosenObject?.chosenLocation?.label ?? '';
                applyFiltersSession.object = chosenObject?.chosenObj?.label ?? '';
                applyFiltersSession.plot = chosenObject?.chosenPlot?.label ?? '';
                applyFiltersSession.timestamp = new Date().toUTCString();
                applyFiltersSession.session_id = localStorage.getItem('sessionId') ?? '';
                applyFiltersSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                applyFiltersSession.user_id = localStorage.getItem('uid') ?? '';
                
                applyFiltersSession.logEvent();
                
                GetAssets('api/ArtCatalog/GetAssets_Cinematics', 
                { 
                    storyId: chosenObject.chosenStory != null ? chosenObject.chosenStory.value : null, 
                    episodeId: chosenObject.chosenEpisode != null ? chosenObject.chosenEpisode.value : null, 
                    location: chosenObject.chosenLocation != null ? chosenObject.chosenLocation.value : null,
                    object: chosenObject.chosenObj != null ? chosenObject.chosenObj.value : null,
                    plot: chosenObject.chosenPlot != null ? chosenObject.chosenPlot.value : null,
                    searchValue: searchText ?? null
                })
                .then((response) => 
                    { 
                        dispatch(ActionCreatorsForArtCatalog.setCinematics(response)) 
                    })
            }}>Apply filters</CButton>
        </div>
    </div>
)
}

export default Cinematics;