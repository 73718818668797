import { CInputGroup } from '@coreui/react';
import React from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorsForTranslations } from '../../../../store/TranslationsReducer';
import { editEpisodeReadOnlyCell } from '../../Lines/_Helpers';
import { calculateMovementOnClickLine } from './_TranslationsHelpers';

export const OutfitTranslationLine1 = ({ line, index, inputLine, isPreview } : any) =>
{
    const { selectedLineIndex, serbianTranslate, englishTranslate, otherLanguageTranslate, linesFrame, defaultLine } = useSelector((state: any) => state.translationsReducer);
    const dispatch = useDispatch();
    
    return(
    <div >
        <CInputGroup className={inputLine ? "oline-inputgroup o-input-translations row" : "oline-inputgroup row"}
         onClick={() =>
            {
                if(!inputLine && !isPreview)
                {
                    let movement = calculateMovementOnClickLine(index, selectedLineIndex, linesFrame, serbianTranslate, englishTranslate)

                    dispatch(ActionCreatorsForTranslations.setCurrentLineForTranslations(
                    {
                        currentLineSerbian: serbianTranslate && serbianTranslate?.length != 0 && !serbianTranslate[0]?.isDefault ? serbianTranslate[movement] : defaultLine,
                        currentLineEnglish: englishTranslate && englishTranslate?.length != 0 && !englishTranslate[0]?.isDefault ? englishTranslate[movement] : defaultLine,
                        currentLineOther: otherLanguageTranslate && otherLanguageTranslate?.length != 0 && !otherLanguageTranslate[0]?.isDefault ? otherLanguageTranslate[movement] : defaultLine,
                        currentLineIndex: movement, 
                        linesStartIndex: movement + 1 > linesFrame ? movement + 1 - linesFrame : 0
                    }))
                }
            }}
        >
            <span style={editEpisodeReadOnlyCell}>{line.lineNumber}</span>
            <span style={editEpisodeReadOnlyCell}>O</span>
            <span className="col cursor" style={editEpisodeReadOnlyCell}>{line.clothes1Name}</span>
            <span className="col cursor" style={editEpisodeReadOnlyCell}>{line.clothes2Name}</span>
            <span className="col cursor" style={editEpisodeReadOnlyCell}>{line.clothes3Name}</span>
            <span className="col cursor" style={editEpisodeReadOnlyCell}>{line.clothes4Name}</span>
            <span className="col cursor" style={editEpisodeReadOnlyCell}>{line.clothes5Name}</span>
            <span className="col cursor" style={editEpisodeReadOnlyCell}>{line.clothes6Name}</span>
            <input disabled className="col" style={{ ...editEpisodeReadOnlyCell, flexGrow: 1 }} />
        </CInputGroup>
    </div>)
}

export const OutfitTranslationLine2 = ({ line, index, inputLine, isPreview } : any) =>
{
    const { selectedLineIndex, serbianTranslate, englishTranslate, otherLanguageTranslate, linesFrame, defaultLine } = useSelector((state: any) => state.translationsReducer);
    const dispatch = useDispatch();

    return(
    <div>
        <CInputGroup  className={inputLine ? "oline-inputgroup o-input-translations" : "oline-inputgroup"}
         onClick={() =>
            {
                if(!inputLine && !isPreview)
                {
                    let movement = calculateMovementOnClickLine(index, selectedLineIndex, linesFrame, serbianTranslate, englishTranslate)

                    dispatch(ActionCreatorsForTranslations.setCurrentLineForTranslations(
                    {
                        currentLineSerbian: serbianTranslate && serbianTranslate?.length != 0 && !serbianTranslate[0]?.isDefault ? serbianTranslate[movement] : defaultLine,
                        currentLineEnglish: englishTranslate && englishTranslate?.length != 0 && !englishTranslate[0]?.isDefault ? englishTranslate[movement] : defaultLine,
                        currentLineOther: otherLanguageTranslate && otherLanguageTranslate?.length != 0 && !otherLanguageTranslate[0]?.isDefault ? otherLanguageTranslate[movement] : defaultLine,
                        currentLineIndex: movement, 
                        linesStartIndex: movement + 1 > linesFrame ? movement + 1 - linesFrame : 0
                    }))
                }
            }}
        >
            <span style={editEpisodeReadOnlyCell}>{line.lineNumber}</span>
            <span style={editEpisodeReadOnlyCell}>O</span>
            <span className="col cursor" style={editEpisodeReadOnlyCell}>{line.hairstyle1Name}</span>
            <span className="col cursor" style={editEpisodeReadOnlyCell}>{line.hairstyle2Name}</span>
            <span className="col cursor" style={editEpisodeReadOnlyCell}>{line.hairstyle3Name}</span>
            <input disabled className="col" style={{...editEpisodeReadOnlyCell, flexGrow: 1}}/>        
        </CInputGroup>
    </div>)
}

export const OutfitTranslationLine3 = ({ line, index, inputLine, isPreview } : any) =>
{
    const { skinColors, selectedLineIndex, serbianTranslate, englishTranslate, otherLanguageTranslate, linesFrame, defaultLine } = useSelector((state: any) => state.translationsReducer);
    const dispatch = useDispatch();
    
    return(
    <div>
        <CInputGroup className={inputLine ? "oline-inputgroup o-input-translations" : "oline-inputgroup"}
         onClick={() =>
            {
                if(!inputLine && !isPreview)
                {
                    let movement = calculateMovementOnClickLine(index, selectedLineIndex, linesFrame, serbianTranslate, englishTranslate)

                    dispatch(ActionCreatorsForTranslations.setCurrentLineForTranslations(
                    {
                        currentLineSerbian: serbianTranslate && serbianTranslate?.length != 0 && !serbianTranslate[0]?.isDefault ? serbianTranslate[movement] : defaultLine,
                        currentLineEnglish: englishTranslate && englishTranslate?.length != 0 && !englishTranslate[0]?.isDefault ? englishTranslate[movement] : defaultLine,
                        currentLineOther: otherLanguageTranslate && otherLanguageTranslate?.length != 0 && !otherLanguageTranslate[0]?.isDefault ? otherLanguageTranslate[movement] : defaultLine,
                        currentLineIndex: movement, 
                        linesStartIndex: movement + 1 > linesFrame ? movement + 1 - linesFrame : 0
                    }))
                }
            }}
        >
            <span style={editEpisodeReadOnlyCell}>{line.lineNumber}</span>
            <span style={editEpisodeReadOnlyCell}>O</span>
            <span className="col cursor" style={editEpisodeReadOnlyCell}></span>
            <span className="col cursor" style={editEpisodeReadOnlyCell}></span>
            <span className="col cursor" style={editEpisodeReadOnlyCell}></span>
            <span className="col cursor" style={editEpisodeReadOnlyCell}></span>
            <input disabled className="col input-field-cinematic-gray" style={{...editEpisodeReadOnlyCell, flexGrow: 1}}/>
        </CInputGroup>
    </div>)
}
