import React, { useEffect, useState } from "react";
import { CButton, CForm } from "@coreui/react";
import { ErrorMessage, Field, Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { 
  SaveAccessories,
  GetBodyTypes,
  GetAssetsForSetupStory,
} from "../../../services/SetupStoryServices";
import ReactLoading from 'react-loading';
import { customStyles } from "../../Helpers/CustomStyles";
import { selectStylesForField } from "../Lines/_Helpers";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from 'react-alert';
import { ActionCreators } from "../../../store/StoriesReducer";

export const AddAccessoriesModalBody = ({ selectedData, data, setData, setVisible, storyId }: any) => 
{
  const dispatch = useDispatch();
  const alert = useAlert();
  const [bodyTypes, setBodyTypes] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [dirtySelect, setDirtySelect] = useState(false);

  const [assets, setAssets] = useState([]);

  let addAccessoriesSchema = yup.object().shape({
    name: yup.string().required("You must enter name."),
  });
  
  const { accessoryTypes } = useSelector((state: any) => state.editEpisodeReducer);
  //const [isAssetDisabled, setAssetDisabled] = useState(selectedData.assetDocId == null ? false : true); //??? no type for this entity

  const [accessoriesSelectedObject, setAccessoriesSelectedObject] = useState({
    id: selectedData.id,
    storyId: selectedData.storyId,
    counter: selectedData.counter,
    episode: selectedData.episode,
    name: selectedData.name,
    soName: selectedData.soName,
    character: selectedData.character,
    bodyType: selectedData.bodyType,
    description: selectedData.description,
    reference: selectedData.reference,
    assetDocId: selectedData.assetDocId,
    assetPath: selectedData.assetPath,
    assetName: selectedData.assetName,
    accessoryType: selectedData.accessoryType
  });

  useEffect(() => {
    GetBodyTypes(storyId).then((result: any) => {
      setBodyTypes(result?.map((option: any) => 
      { 
        return { id: option.id, value: option.value, label: option.label }; 
      }));
    });

    GetAssetsForSetupStory(`api/SetupStory/GetAccessoryAssets?storyId=${storyId}`).then((result : any) => 
    {
      setAssets(result?.map((option : any) => { return { id: option.id, value: option.id, label: option.name, type: option.accessoryType, src: option.path } }))
    })
  }, []);

  return (
    <Formik
      initialValues={{
        episode: selectedData.episode,
        name: selectedData.name,
        soName: selectedData.soName,
        character: selectedData.character,
        bodyType: selectedData.bodyType,
        description: selectedData.description,
        reference: selectedData.reference,
        assetDocId: selectedData.assetDocId,
        assetPath: selectedData.assetPath,
        assetName: selectedData.assetName,
        accessoryType: selectedData.accessoryType
      }}
      onSubmit={(values) => {
        setLoading(true);
        SaveAccessories({ 
          ...values,
          id: accessoriesSelectedObject.id,
          storyId: accessoriesSelectedObject.storyId,
          counter: accessoriesSelectedObject.counter,
          bodyType: accessoriesSelectedObject.bodyType,
          assetDocId: accessoriesSelectedObject.assetDocId,
          assetPath: accessoriesSelectedObject.assetPath,
          assetName: accessoriesSelectedObject.assetName,
          accessoryType: accessoriesSelectedObject.accessoryType
        }, data, setData, setVisible, setLoading, alert).then(() => 
        {
          sessionStorage.setItem('accessoriesNum', (parseInt(sessionStorage.getItem('accessoriesNum') ?? '0') + 1).toString())
        });
      }}
      validationSchema={addAccessoriesSchema}
    >
      {({ dirty, isValid, handleSubmit }) => {
        return (
          <CForm onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
            { loading ? <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1000, position: 'absolute', top: '0%', left: '0%', width: '100%', height: '100%'}}>
                            <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1001, position: 'absolute', top: '45%', left: '45%'}}>
                              <ReactLoading className="mt-3 mb-5" type='spin' color='#0080FF'/>
                              </div>
                        </div> : <div></div> }
            <div className="row-style modal-row-stp-story col-sm-12 row">
              <div className="form-group-name col-sm-6 ">
                <label htmlFor="episode">Episode</label>
                <Field
                  className="form-control"
                  name="episode"
                  type="text"
                  disabled
                  autoComplete="off"
                ></Field>
              </div>
            </div>

            <div className="row-style modal-row-stp-story col-sm-12 row">
              <div className="form-group-name col-sm-6 ">
                <label htmlFor="name">Name</label>
                <Field
                  className="form-control"
                  name="name"
                  type="text"
                  autoComplete="off"
                  required
                ></Field>
                <ErrorMessage
                  component="p"
                  className="field-colorchange"
                  name="name"
                />
              </div>
              <div className="form-group-name col-sm-6">
                <label htmlFor="character">Character</label>
                <Field
                  className="form-control"
                  name="character"
                  type="text"
                  disabled
                  autoComplete="off"
                ></Field>
                <ErrorMessage
                  component="p"
                  className="field-colorchange"
                  name="character"
                />
              </div>
            </div>

            <div className="row-style modal-row-stp-story col-sm-12 row">
              <div className="form-group-name col-sm-6">
                <label htmlFor="soName">SO Name</label>
                <Field
                  className="form-control"
                  name="soName"
                  type="text"
                  disabled
                  autoComplete="off"
                ></Field>
              </div>
              <div className="form-group-name col-sm-6 ">
                <label htmlFor="bodyType">Body Type</label>
                <Field
                  as={Select}
                  options={bodyTypes}
                  styles={selectStylesForField}
                  menuPortalTarget={document.querySelector('body')}
                  value={bodyTypes?.find(
                    (x: any) => x.value == accessoriesSelectedObject.bodyType
                  )}
                  onChange={(value: any) => {
                    setAccessoriesSelectedObject({
                      ...accessoriesSelectedObject,
                      bodyType: value.value,
                    });
                    setDirtySelect(true);
                  }}
                  name="bodyType"
                  autoComplete="off"
                  required
                  isDisabled={true}
                ></Field>
                <ErrorMessage
                  component="p"
                  className="field-colorchange"
                  name="bodyType"
                />
              </div>
              <div className="form-group-name col-sm-6 ">
                <label htmlFor="accessoryType">Accessory Type</label>
                <Field
                  as={Select}
                  options={accessoryTypes}
                  styles={selectStylesForField}
                  menuPortalTarget={document.querySelector('body')}
                  value={accessoryTypes?.find(
                    (x: any) => x.id == accessoriesSelectedObject.accessoryType
                  )}
                  onChange={(value: any) => {
                    setAccessoriesSelectedObject({
                      ...accessoriesSelectedObject,
                      assetDocId: null,
                      assetName: null,
                      assetPath: null,
                      accessoryType: value.id,
                    });
                    setDirtySelect(true);
                  }}
                  name="accessoryType"
                  autoComplete="off"
                  required
                ></Field>
                <ErrorMessage
                  component="p"
                  className="field-colorchange"
                  name="accessoryType"
                />
              </div>
            </div>

            <div className="row-style modal-row-stp-story col-sm-12 row">
              <div className="form-group-name col-sm-6 ">
                <label htmlFor="description">Description</label>
                <Field
                  className="form-control"
                  name="description"
                  type="text"
                  autoComplete="off"
                ></Field>
                <ErrorMessage
                  component="p"
                  className="field-colorchange"
                  name="description"
                />
              </div>
              <div className="form-group-name col-sm-6 ">
                <label htmlFor="reference">Reference</label>
                <Field
                  className="form-control"
                  name="reference"
                  type="text"
                  autoComplete="off"
                ></Field>
                <ErrorMessage
                  component="p"
                  className="field-colorchange"
                  name="reference"
                />
              </div>
            </div>

            <div className="form-group-name col-sm-12 modal-row-stp-story">
                <label htmlFor="assets" className="mt-3">Catalogue</label>
                <Field
                  as={Select}
                  options={assets?.filter((x: any) => x.type == accessoriesSelectedObject.accessoryType)}
                  styles={{...customStyles, ...selectStylesForField}}
                  menuPortalTarget={document.querySelector('body')}
                  value={assets?.filter((x: any) => x.type == accessoriesSelectedObject.accessoryType)?.find(
                    (x: any) => x.value == accessoriesSelectedObject.assetDocId
                  ) ?? ""}
                  isClearable
                  onChange={(value: any) => 
                  {
                    setAccessoriesSelectedObject({
                      ...accessoriesSelectedObject,
                      assetDocId: value != null ? value.value : null,
                      assetName: value != null ? value.label : null,
                      assetPath: value != null ? value.src : null,
                      //type: value != null ? 1 : 0 //??? no type for this entity
                    });
                    //setAssetDisabled(value != null ? true : false); //??? no type for this entity
                    setDirtySelect(true);
                  }}
                  name="assets"
                  autoComplete="off"
                ></Field>
                <ErrorMessage
                  component="p"
                  className="field-colorchange"
                  name="assets"
                />
            </div>

            <div className="modal-buttons-container float-right">
              <CButton
                type="submit"
                disabled={!(dirty || dirtySelect) || !isValid}
                className="mr-2 wide"
                color="primary"
              >
                Save
              </CButton>
              <CButton className="mr-2 wide" color="secondary" onClick={() => setVisible(false)}>Cancel</CButton>
            </div>
          </CForm>
        );
      }}
    </Formik>
  );
};
