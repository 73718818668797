import { configureStore } from '@reduxjs/toolkit';

//project imports
import landingPageReducer from './LandingPageReducer';
import storiesReducer from './StoriesReducer';
import translationsReducer from './TranslationsReducer';
import artCatalogReducer from './ArtCatalogReducer';
import editEpisodeReducer from './EditEpisodeReducer';
import addingLineReducer from './AddingLineReducer';
import userManagementReducer from './UserManagementReducer';

export const store = configureStore({
    reducer: 
    {
      landingPageReducer: landingPageReducer,
      storiesReducer: storiesReducer,
      translationsReducer: translationsReducer,
      artCatalogReducer: artCatalogReducer,
      editEpisodeReducer: editEpisodeReducer,
      addingLineReducer: addingLineReducer,
      userManagementReducer: userManagementReducer
    },
});

export default store;