import React from 'react';
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';
import { HiOutlineLogout } from 'react-icons/hi';
import { useHistory } from 'react-router';
import firebase from 'firebase';
import { LogoutSessionLogger } from '../../modules/firebaseDetailedLoggers';

export const TopBar = () =>
{
    const history = useHistory();

    return(
        <div className="top-bar shadow">
            
            <CDropdown className="top-bar-username">
                <CDropdownToggle caret className="user-menu-button" color="light"><span className="static-text-top-bar">Logged in as</span><span className="text-user-top-bar"> {localStorage.getItem('user')}</span></CDropdownToggle>
                <CDropdownMenu>
                    <CDropdownItem className="cursor" header onClick={() => 
                    { 
                        const logoutSession = new LogoutSessionLogger();
                        
                        logoutSession.timestamp = new Date().toUTCString();
                        logoutSession.session_id = localStorage.getItem('sessionId') ?? '';
                        logoutSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                        logoutSession.user_id = localStorage.getItem('uid') ?? '';

                        logoutSession.logEvent()
                        
                        var eventParams = {
                            eventSuffix: localStorage.getItem('eventSuffix') ?? '',
                            eventEnabled: localStorage.getItem('eventEnabled') ?? '',
                            eventSessionDuration: localStorage.getItem('eventSessionDuration') ?? ''
                        }

                        firebase.app().auth().signOut();
                        localStorage.clear();

                        localStorage.setItem('eventSuffix', eventParams.eventSuffix);
                        localStorage.setItem('eventEnabled', eventParams.eventEnabled);
                        localStorage.setItem('eventSessionDuration', eventParams.eventSessionDuration);

                        history.push('');
                    }}
                    ><HiOutlineLogout size={19} color="black"/>&nbsp; Logout</CDropdownItem>
                </CDropdownMenu>
            </CDropdown>
        </div>
    )
}

export default TopBar;