import { axiosInstance } from '../axios/key';
import { ErrorHandler } from '../components/Helpers/ErrorHandler';
import { ActionCreators } from '../store/StoriesReducer';
import { ActionCreatorsForUserManagement } from '../store/UserManagementReducer';

export const GetUsersFromFirestore = async (alert : any) =>
{
    try
    {
        const { data } = await axiosInstance.get('api/Admin/GetUsersFromFirestore')
        return data;    
    }
    catch(e)
    {
        ErrorHandler(e, 'There was an error while trying to get users from firestore.', alert);
    }
}

export const GetCurrentUsers = async (alert : any, dispatch : any) =>
{
    try
    {
        const { data } = await axiosInstance.get('api/Admin/GetCurrentUsers')
        dispatch(ActionCreatorsForUserManagement.getCurrentUsers(data));

        return data;    
    }
    catch(e)
    {
        ErrorHandler(e, 'There was an error while trying to get current users.', alert);
    }
}

export const UpdateUsersFromFirestore = async (alert : any) =>
{
    try
    {
        const { data } = await axiosInstance.get('api/Admin/UpdateUsersFromFirestore')
        alert.success("Current users successfully synced.");        
        return data;    
    }
    catch(e)
    {
        ErrorHandler(e, 'There was an error while trying to get current users.', alert);
    }
}

export const GetAllLanguages = async (alert : any) =>
{
    try
    {
        const { data } = await axiosInstance.get('api/Admin/GetAllLanguages')
        return data;    
    }
    catch(e)
    {
        ErrorHandler(e, 'There was an error while trying to get all languages.', alert);
    }
}

export const GetAllRoles = async (alert : any) =>
{
    try
    {
        const { data } = await axiosInstance.get('api/Admin/GetAllRoles')
        return data;    
    }
    catch(e)
    {
        ErrorHandler(e, 'There was an error while trying to get all roles.', alert);
    }
}