import firebase from "firebase/app"
import 'firebase/analytics'
import { eventNames, wgDetailedSeparator } from '../Constants/firebaseAnalyticsEventNames';

export class StoryStatusChangedLogger{
    story_name: string = ''
    author_name: string = ''
    language: string = ''
    character_type: string = ''
    category: string = ''
    internal_writer: boolean = false
    user_id: string = ''
    game_type: string = ''
    new_status: string = ''
    number_of_episodes: number = 0
    logged_in_user_name: string = ''

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const currentDate = new Date()
        const params = {
            wgp_app_log_utc_time: currentDate.toUTCString(),
            wgp_story_name: this.story_name,
            wgp_author_name: this.author_name,
            wgp_language: this.language,
            wgp_character_type: this.character_type,
            wgp_category: this.category,
            wgp_internal_writer: this.internal_writer,
            wgp_user_id: this.user_id,
            wgp_game_type: this.game_type,
            wgp_new_status: this.new_status,
            wgp_number_of_episodes: this.number_of_episodes,
            wgp_logged_in_user_name: this.logged_in_user_name,
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.storyStatusChanged+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Story status changed loging error!')
        }
    }
}

export class EpisodeAddedLogger{
    story_name: string = ''
    game_type: string = ''
    logged_in_user_name: string = ''
    user_id: string = ''
    episode_number: number = -1
    episode_title: string = ''

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix');
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const currentDate = new Date()
        const params = {
            wgp_app_log_utc_time: currentDate.toUTCString(),
            wgp_story_name: this.story_name,
            wgp_game_type: this.game_type,
            wgp_logged_in_user_name: this.logged_in_user_name,
            wgp_user_id: this.user_id,
            wgp_episode_number: this.episode_number,
            wgp_episode_title: this.episode_title
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled){
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.episodeAdded+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Episode added loging error!')
        }
    }  
}

export class EpisodeStatusChangedLogger{
    story_name: string = ''
    logged_in_user_name: string = ''
    user_id: string = ''
    game_type: string = ''
    episode_number: number = -1
    from_status: string = ''
    to_status: string = ''

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const currentDate = new Date()
        const params = {
            wgp_app_log_utc_time: currentDate.toUTCString(),
            wgp_story_name: this.story_name,
            wgp_logged_in_user_name: this.logged_in_user_name,
            wgp_user_id: this.user_id,
            wgp_game_type: this.game_type,
            wgp_episode_number: this.episode_number,
            wgp_from_status: this.from_status,
            wgp_to_status: this.to_status
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled){
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.episodeStatusChanged+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Episode status changed loging error!')
        }
    }  
}

export class EpisodeSubmittedLogger{
    story_name: string = ''
    logged_in_user_name: string = ''
    user_id: string = ''
    episode_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const currentDate = new Date()
        const params = {
            wgp_app_log_utc_time: currentDate.toUTCString(),
            wgp_story_name: this.story_name,
            wgp_logged_in_user_name: this.logged_in_user_name,
            wgp_user_id: this.user_id,
            wgp_episode_number: this.episode_number
        }   
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled){
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.episodeSubmitted+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Episode submitted loging error!')
        }
    }  
}

export class WrittingSessionLogger{
    timerValue: number = -1

    activity_start_timestamp: string = ''
    activity_end_timestamp: string = ''
    story_name: string = ''
    logged_in_user_name: string = ''
    user_id: string = ''
    episode_number: number = -1

    session_id: string = ''
    session_number: number = -1

    words_num_start: number = 0
    words_num_end: number = 0
    lines_num_start: number = 0
    lines_num_end: number = 0
    choices_num_start: number = 0
    choices_num_end: number = 0
    cust_choices_num_start: number = 0
    cust_choices_num_end: number = 0
    paid_answers_num_start: number = 0
    paid_answers_num_end: number = 0
    cinematics_num_start: number = 0
    cinematics_num_end: number = 0

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
        this.timerValue = parseInt(localStorage.getItem('eventSessionDuration') ?? '-1' )
    }

    private prepareEventParams(){
        const currentDate = new Date()
        const params = {
            wgp_app_log_utc_time: currentDate.toUTCString(),
            wgp_activity_start_timestamp: this.activity_start_timestamp,
            wgp_activity_end_timestamp: this.activity_end_timestamp,
            wgp_story_name: this.story_name,
            wgp_logged_in_user_name: this.logged_in_user_name,
            wgp_user_id: this.user_id,
            wgp_episode_number: this.episode_number,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number,
            wgp_statistic: '{0}, {1}, {2}, {3}, {4}, {5}'
        }   
        params.wgp_statistic = params.wgp_statistic.replace('{0}', (this.words_num_end - this.words_num_start).toString())
                                           .replace('{1}', (this.lines_num_end - this.lines_num_start).toString())
                                           .replace('{2}', (this.choices_num_end - this.choices_num_start).toString())
                                           .replace('{3}', (this.cust_choices_num_end - this.cust_choices_num_start).toString())
                                           .replace('{4}', (this.paid_answers_num_end - this.paid_answers_num_start).toString())
                                           .replace('{5}', (this.cinematics_num_end - this.cinematics_num_start).toString())
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled){
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.writtingSesssion+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Writting session loging error!')
        }
    }  
}

export class SetUpSessionLogger{
    timerValue: number = -1

    activity_start_timestamp: string = ''
    activity_end_timestamp: string = ''
    story_name: string = ''
    logged_in_user_name: string = ''
    user_id: string = ''

    session_id: string = ''
    session_number: number = -1

    characters_num_start: number = 0
    characters_num_end: number = 0
    hairstyles_num_start: number = 0
    hairstyles_num_end: number = 0
    clothes_num_start: number = 0
    clothes_num_end: number = 0
    accessories_num_start: number = 0
    accessories_num_end: number = 0
    backgrounds_num_start: number = 0
    backgrounds_num_end: number = 0
    cinematics_num_start: number = 0
    cinematics_num_end: number = 0
    audios_num_start: number = 0
    audios_num_end: number = 0

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
        this.timerValue = parseInt(localStorage.getItem('eventSessionDuration') ?? '-1' )
    }

    private prepareEventParams(){
        const currentDate = new Date()
        const params = {
            wgp_app_log_utc_time: currentDate.toUTCString(),
            wgp_activity_start_timestamp: this.activity_start_timestamp,
            wgp_activity_end_timestamp: this.activity_end_timestamp,
            wgp_story_name: this.story_name,
            wgp_logged_in_user_name: this.logged_in_user_name,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number,
            wgp_statistic: '{0}, {1}, {2}, {3}, {4}, {5}, {6}'
        }   

        params.wgp_statistic = params.wgp_statistic.replace('{0}', (this.characters_num_end -  this.characters_num_start) .toString())
                                           .replace('{1}', (this.hairstyles_num_end -  this.hairstyles_num_start) .toString())
                                           .replace('{2}', (this.clothes_num_end -  this.clothes_num_start) .toString())
                                           .replace('{3}', (this.accessories_num_end -  this.accessories_num_start) .toString())
                                           .replace('{4}', (this.backgrounds_num_end -  this.backgrounds_num_start) .toString())
                                           .replace('{5}', (this.cinematics_num_end -  this.cinematics_num_start) .toString())
                                           .replace('{6}', (this.audios_num_end -  this.audios_num_start) .toString())

        return params
    }

    logEvent(){
        try {
            if(this.logEnabled){
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.setUpSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Set up session loging error!')
        }
    }  
}

export class ReviewSessionLogger{
    timerValue: number = -1

    activity_start_timestamp: string = ''
    activity_end_timestamp: string = ''
    story_name: string = ''
    logged_in_user_name: string = ''
    user_id: string = ''
    episode_number: number = -1
    comment_start_num: number = 0
    comment_end_num: number = 0

    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
        this.timerValue = parseInt(localStorage.getItem('eventSessionDuration') ?? '-1' )
    }

    private prepareEventParams(){
        const currentDate = new Date()
        const params = {
            wgp_app_log_utc_time: currentDate.toUTCString(),
            wgp_activity_start_timestamp: this.activity_start_timestamp,
            wgp_activity_end_timestamp: this.activity_end_timestamp,
            wgp_story_name: this.story_name,
            wgp_logged_in_user_name: this.logged_in_user_name,
            wgp_user_id: this.user_id,
            wgp_episode_number: this.episode_number,
            wgp_comment_num: this.comment_end_num - this.comment_start_num,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }   
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled){
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.reviewSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Review session loging error!')
        }
    }  
}

export class TranslationSessionLogger{
    timerValue: number = -1

    activity_start_timestamp: string = ''
    activity_end_timestamp: string = ''
    story_name: string = ''
    logged_in_user_name: string = ''
    user_id: string = ''
    episode_number: number = -1

    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
        this.timerValue =  parseInt(localStorage.getItem('eventSessionDuration') ?? '-1' )
    }

    private prepareEventParams(){
        const currentDate = new Date()
        const params = {
            wgp_app_log_utc_time: currentDate.toUTCString(),
            wgp_activity_start_timestamp: this.activity_start_timestamp,
            wgp_activity_end_timestamp: this.activity_end_timestamp,
            wgp_story_name: this.story_name,
            wgp_logged_in_user_name: this.logged_in_user_name,
            wgp_user_id: this.user_id,
            wgp_episode_number: this.episode_number,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }   
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled){
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.translationSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Translation session loging error!')
        }
    }  
}

export class ProofreadSessionLogger{
    timerValue: number = -1
    
    activity_start_timestamp: string = ''
    activity_end_timestamp: string = ''
    story_name: string = ''
    logged_in_user_name: string = ''
    user_id: string = ''
    episode_number: number = -1

    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
        this.timerValue = parseInt(localStorage.getItem('eventSessionDuration') ?? '-1' )
    }

    private prepareEventParams(){
        const currentDate = new Date()
        const params = {
            wgp_app_log_utc_time: currentDate.toUTCString(),
            wgp_activity_start_timestamp: this.activity_start_timestamp,
            wgp_activity_end_timestamp: this.activity_end_timestamp,
            wgp_story_name: this.story_name,
            wgp_logged_in_user_name: this.logged_in_user_name,
            wgp_user_id: this.user_id,
            wgp_episode_number: this.episode_number,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }   
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled){
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.proofreadSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Proofread session loging error!')
        }
    }  
}