import React from 'react';
import { CInputGroup, CInput } from '@coreui/react';

export const PlaceholderLine = () => {
    return ( <CInputGroup style={{marginBottom: "2px"}}>
        <CInput
            className="form-control col-sm-12 empty-line-edit"
            style={{background: "white", color: "white", border: "white"}}
            disabled={true}>
        </CInput>
    </CInputGroup>)
}