import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { GetEpisodeDetails } from '../../services/EpisodeServices';
import { EditEpisodeActions } from '../Models/EditEpisodeActions';
import { EditEpisodeBody } from '../Models/EditEpisodeBody';
import { EditEpisodeComment } from '../Models/EditEpisodeComment';
import { EditEpisodeHeader } from '../Models/EditEpisodeHeader';
import { AiOutlineComment, AiOutlineControl } from 'react-icons/ai';
import { ActionCreatorsForEditEpisode } from '../../store/EditEpisodeReducer';
import { GetLanguages } from '../../services/LanguageServices';
import { handleNameConvert } from '../Helpers/NameConvert';
import { GetPossibleEpisodeStatuses } from '../../services/StateMachine';
import { ActionCreators } from '../../store/StoriesReducer';
import { EpisodeStatus } from '../Common/Enums/EpisodeStatus';
import { ReviewSessionLogger, WrittingSessionLogger } from '../../modules/firebaseAnalyticsLoggers';
import { GetCurrentSessionId } from '../../services/CommonServices';
import IdleTimerForebaseLogsContainer from '../IdleTimer/IdleTimerFirebaseLogs';

export const EditEpisode = () => 
{
    const { storyId, storyTransId, episodeId } : any = useParams();
    const dispatch = useDispatch();

    const episode = useSelector((state: any) => state.editEpisodeReducer.editEpisodePassParam);
    const episodeSummary = useSelector((state: any) => state.editEpisodeReducer.episodeSummary);
    const numberOfAddedCommentsInSession = useSelector((state: any) => state.editEpisodeReducer.numberOfAddedCommentsInSession);

    const [loading, setLoading] = useState(true);

    /*const logWrittingSession = () => 
    {
        return true;
    }*/
    let writtingSessionLogger : any = null;
    let reviewSessionLogger : any = null;

    const logWrittingSession = () => {
        writtingSessionLogger.words_num_end = parseInt(sessionStorage.getItem('wordsNum') ?? '');
        writtingSessionLogger.lines_num_end = parseInt(sessionStorage.getItem('linesNum') ?? '');
        writtingSessionLogger.choices_num_end = parseInt(sessionStorage.getItem('choicesNum') ?? '');
        writtingSessionLogger.cust_choices_num_end = parseInt(sessionStorage.getItem('custChoicesNum') ?? '');
        writtingSessionLogger.paid_answers_num_end = parseInt(sessionStorage.getItem('paidAnswersNum') ?? ''); 
        writtingSessionLogger.cinematics_num_end = parseInt(sessionStorage.getItem('cinematicsNum') ?? '');

        writtingSessionLogger.activity_end_timestamp = new Date().toUTCString();
        
        writtingSessionLogger.logEvent();

        writtingSessionLogger.words_num_start = writtingSessionLogger.words_num_end
        writtingSessionLogger.lines_num_start = writtingSessionLogger.lines_num_end
        writtingSessionLogger.choices_num_start = writtingSessionLogger.choices_num_end
        writtingSessionLogger.cust_choices_num_start = writtingSessionLogger.cust_choices_num_end
        writtingSessionLogger.paid_answers_num_start = writtingSessionLogger.paid_answers_num_end
        writtingSessionLogger.cinematics_num_start = writtingSessionLogger.cinematics_num_end

        writtingSessionLogger.activity_start_timestamp = writtingSessionLogger.activity_end_timestamp
    }

    const logReviewSession = () =>{
        reviewSessionLogger.activity_end_timestamp = new Date().toUTCString();
        reviewSessionLogger.comment_end_num = parseInt(sessionStorage.getItem('commentNum') ?? '');

        reviewSessionLogger.logEvent();

        reviewSessionLogger.activity_start_timestamp = reviewSessionLogger.activity_end_timestamp;
        reviewSessionLogger.comment_start_num = reviewSessionLogger.comment_end_num;
    }

    useEffect(() => {
        if(episode.episodeStatusEnum == EpisodeStatus.InProgress)
        {
            sessionStorage.setItem('wordsNum', episodeSummary.wordsNum);
            sessionStorage.setItem('linesNum', episodeSummary.linesNum);
            sessionStorage.setItem('choicesNum', episodeSummary.questionsNum);
            sessionStorage.setItem('custChoicesNum', episodeSummary.customizationNum);
            sessionStorage.setItem('paidAnswersNum', episodeSummary.paidAnswersNum);
            sessionStorage.setItem('cinematicsNum', episodeSummary.cinematicsNum);

            writtingSessionLogger = new WrittingSessionLogger();

            writtingSessionLogger.activity_start_timestamp = new Date().toUTCString();
            writtingSessionLogger.story_name = episode.storyName;
            writtingSessionLogger.logged_in_user_name = localStorage.getItem('user') ?? '';
            writtingSessionLogger.user_id = localStorage.getItem('uid') ?? '';
            writtingSessionLogger.episode_number = episode.serialNumber;

            writtingSessionLogger.session_id = localStorage.getItem('sessionId');
            writtingSessionLogger.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');

            writtingSessionLogger.words_num_start = episodeSummary.wordsNum;
            writtingSessionLogger.lines_num_start = episodeSummary.linesNum;
            writtingSessionLogger.choices_num_start = episodeSummary.questionsNum;
            writtingSessionLogger.cust_choices_num_start = episodeSummary.customizationNum;
            writtingSessionLogger.paid_answers_num_start = episodeSummary.paidAnswersNum;
            writtingSessionLogger.cinematics_num_start = episodeSummary.cinematicsNum;
            const timer = setInterval(() => {
               logWrittingSession()
            }, writtingSessionLogger.timerValue)

            return () => { clearInterval(timer) }
        }
        else if(episode.episodeStatusEnum == EpisodeStatus.InReview)
        {
            sessionStorage.setItem('commentNum', '0');

            reviewSessionLogger = new ReviewSessionLogger();

            reviewSessionLogger.activity_start_timestamp = new Date().toUTCString();
            reviewSessionLogger.story_name = episode.storyName;
            reviewSessionLogger.logged_in_user_name = localStorage.getItem('user') ?? '';
            reviewSessionLogger.user_id = localStorage.getItem('uid') ?? '';
            reviewSessionLogger.episode_number = episode.serialNumber;
            reviewSessionLogger.comment_start_num = 0
        
            reviewSessionLogger.session_id = localStorage.getItem('sessionId');
            reviewSessionLogger.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');

            const timer = setInterval(() => {
                logReviewSession()
            }, reviewSessionLogger.timerValue)
            
            return () => { clearInterval(timer) }
        }
    }, [episode])

    useEffect(() =>
    {
        document.title = 'Story Maker - Edit Episode'
        setLoading(true);
        
        GetEpisodeDetails(dispatch, { storyId: storyId, translationId: storyTransId, episodeId: episodeId }).then((response : any) => 
        {
            setLoading(false);
            dispatch(ActionCreators.setGameType(response?.storyGameType))
            GetPossibleEpisodeStatuses(dispatch, {storyId: response.storyId, translationId: response.storyTranslationId, episodeId: response.id})
            
            GetLanguages(alert).then((resFromLanguage) => 
            { 
                handleNameConvert(resFromLanguage);
                var res = resFromLanguage?.filter((value : any) => value.id === response.language)
                if(res.length != 0)
                {
                    dispatch(ActionCreatorsForEditEpisode.setEditEpisodePassParam({...response, languageName: res[0].label}));
                }
                else
                {
                    dispatch(ActionCreatorsForEditEpisode.setEditEpisodePassParam({...response, languageName: 'N/A'}));
                }
            });
        })
    }, [storyId, storyTransId, episodeId])

    return (
        <div className="column-style mt-3 ml-1">
            {/* <IdleTimerForebaseLogsContainer handleOnIdle =
                {  
                episode.episodeStatusEnum == EpisodeStatus.InProgress ? logWrittingSession :
                episode.episodeStatusEnum == EpisodeStatus.InReview ? logReviewSession : () => {}
                }
            /> */}
            <EditEpisodeHeader episode={episode}/>
            { loading ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <ReactLoading className="mt-5" type='spin' color='#0080FF'/>
                        </div> 
            : <div>
                <EditEpisodeBody storyId={storyId} storyTransId={storyTransId} episodeId={episodeId} storyName={episode.storyName} episodeTitle={episode.title} serialNumber={episode.serialNumber}/>
                { episode.episodeStatusEnum != 1 ? <EditEpisodeComment storyId={storyId} storyTransId={storyTransId} episodeId={episodeId} episode={episode}/> : '' }
                {/*<EditEpisodeActions storyId={storyId} storyTransId={storyTransId} episodeId={episodeId} storyName={episode.storyName} episodeName={episode.title}/>*/}
              </div>
            }
        </div>
    )
}

export default EditEpisode;
