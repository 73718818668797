
export const defaultLine = {
    isDefault: true,
    docId: undefined,
    storyId: undefined,
    storyTranslationId: undefined,
    episodeId: undefined,
    lineNumber: 0,
    depth: 0,
    lineType: 0,
    lineFormat: 0,

    characterId: undefined,
    characterName: undefined,
    emotion: 1,
    text: '',

    clothes1Name: '',
    clothes2Name: '',
    clothes3Name: '',
    clothes4Name: '',
    clothes5Name: '',
    clothes6Name: '',
    hairstyle1Name: undefined,
    hairstyle2Name: undefined,
    hairstyle3Name: undefined,
    skinColor1Name: undefined,
    skinColor2Name: undefined,
    skinColor3Name: undefined,
    skinColor4Name: undefined,

    payed: false,

    animationPath: undefined,
    animationName: undefined,

    questionDocId: undefined,

    comment: '',
    commentReporterName: undefined,
    commentReporterUID: undefined,
    commentAssignedToName: undefined,
    commentAssignedToUID: undefined,
    commentResolved: false,

    backgroundId: undefined,
    backgroundPath: undefined,
    backgroundName: undefined,
    backgroundDescription: undefined,
    musicId: undefined,
    musicPath: undefined,
    musicName: undefined,
    musicDescription: undefined,
    shortSoundId: undefined,
    shortSoundPath: undefined,
    shortSoundName: undefined,
    shortSoundDescription: undefined,
    shortSoundEnabled: false,
    itemId: undefined,
    itemName: undefined,
    itemDescription: undefined,
    avatarEnabled: false,
    callPhoneEnabled: false,
    callSmsEnabled: false,
    callerCharacterId: undefined,
    callerCharacterName: undefined,
    actionChangeFlag: false,
    charmingPoint: undefined,
    premiumChoiceText: undefined,
    premiumChoiceDescription: undefined,
    charactersOutfit: [] as any[],
    answerQuestionId: undefined,

    branchType: undefined,
    branchValue: undefined,
    filtered: false
}

const initialState = 
{
    translations: [] as any[],
    translationEpisodes: [] as any[],
    episodeTranslations: [] as any[],
    serbianTranslate: [defaultLine],
    englishTranslate: [defaultLine],
    otherLanguageTranslate: [defaultLine],

    characters: [] as any[],
    emotions: [] as any[],
    animations: [] as any[],
    clothingCombinations: [] as any[],
    skinColors: [] as any[],
    lineTypes: [] as any[],
    lineFormats: [] as any [],

    selectedLineIndex: -1,
    currentLineSerbian: defaultLine,
    currentLineEnglish: defaultLine,
    currentLineOther: defaultLine,
    linesStartIndex: 0,
    linesFrame: 10,

    updatingBool: 'not updating',

    appendix: {
        serbianStoryTitle: "",
        serbianFeatureText: "",
        englishStoryTitle: "",
        englishFeatureText: "",
        otherStoryTitle: "",
        otherFeatureText: "",
        episodeAppendix: [
        {
            storyId: "",
            episodeSerialNumber: 0,
            serbianDescription: "",
            englishDescription: "",
            otherDescription: ""
        }
        ],
        charmingPointsAppendixList: [
          {
            storyId: "",
            episodeSerialNumber: 0,
            lineNumber: 0,
            serbianLineDocId: "",
            serbianCharmingPoint: "",
            englishLineDocId: "",
            englishCharmingPoint: "",
            otherLineDocId: "",
            otherCharmingPoint: ""
          }
        ],
        chaptersSummaryAppendixList: [
            {
                storyId: "",
                episodeSerialNumber: 0,
                lineNumber: 0,
                serbianLineDocId: "",
                serbianPremiumChoiceText: "",
                englishLineDocId: "",
                englishPremiumChoiceText: "",
                otherLineDocId: "",
                otherPremiumChoiceText: ""
            }
        ],
        charactersAppendix: [
          {
            storyId: "",
            episodeSerialNumber: 0,
            characterDocId: "",
            serbianName: "",
            englishName: "",
            otherName: ""
          }
        ],
        hairstyleAppendix: [
          {
            storyId: "",
            episodeSerialNumber: 0,
            hairstyleDocId: "",
            serbianName: "",
            englishName: "",
            otherName: ""
          }
        ],
        clothesAppendix: [
          {
            storyId: "",
            episodeSerialNumber: 0,
            clothesDocId: "",
            serbianName: "",
            englishName: "",
            otherName: ""
          }
        ],
        backgroundAppendix: [
          {
            storyId: "",
            episodeSerialNumber: 0,
            backgroundDocId: "",
            serbianName: "",
            englishName: "",
            otherName: ""
          }
        ],
        descriptionBubbleAppendixList:
        [
            {
                englishLineDocId: null,
                englishName: null,
                episodeColumnVal: "",
                episodeSerialNumber: null,
                lineId: null,
                lineNumber: null,
                otherLineDocId: null,
                otherName: null,
                serbianLineDocId: "",
                serbianName: "",
                storyId: ""
            }
        ]
    },
    translationStatuses: [] as any[],
    currentTranslationStatus: null
}

export const ActionTypes =
{
    SET_TRANSLATIONS: 'SET_TRANSLATIONS',
    SET_TRANSLATION_EPISODES: 'SET_TRANSLATION_EPISODES',
    SET_EPISODE_TRANSLATIONS: 'SET_EPISODE_TRANSLATIONS',
    SET_LANGUAGES_LINES: 'SET_LANGUAGES_LINES',

    SET_CHARACTERS: 'SET_CHARACTERS',
    SET_EMOTIONS: 'SET_EMOTIONS',
    SET_ANIMATIONS: 'SET_ANIMATIONS',
    SET_CLOTHING_COMBINATIONS: 'SET_CLOTHING_COMBINATIONS',
    SET_SKIN_COLORS: 'SET_SKIN_COLORS',
    SET_LINE_TYPES: 'SET_LINE_TYPES',
    SET_LINE_FORMATS: 'SET_LINE_FORMATS',

    SET_CURRENT_LINE_FOR_TRANSLATIONS: 'SET_CURRENT_LINE_FOR_TRANSLATIONS',
    UPDATE_LINE_AFTER_TRANSLATE: 'UPDATE_LINE_AFTER_TRANSLATE',
    SET_UPDATING_BOOL: 'SET_UPDATING_BOOL',

    UPDATE_ENGLISH_DESCRIPTION: 'UPDATE_ENGLISH_DESCRIPTION',
    UPDATE_SERBIAN_DESCRIPTION: 'UPDATE_SERBIAN_DESCRIPTION',
    UPDATE_OTHER_DESCRIPTION: 'UPDATE_OTHER_DESCRIPTION',

    UPDATE_ENGLISH_CHAPTER_SUMMARY: 'UPDATE_ENGLISH_CHAPTER_SUMMARY',
    UPDATE_SERBIAN_CHAPTER_SUMMARY: 'UPDATE_SERBIAN_CHAPTER_SUMMARY',
    UPDATE_OTHER_CHAPTER_SUMMARY: 'UPDATE_OTHER_CHAPTER_SUMMARY',

    UPDATE_ENGLISH_CHARMING_POINT: 'UPDATE_ENGLISH_CHARMING_POINT',
    UPDATE_SERBIAN_CHARMING_POINT: 'UPDATE_SERBIAN_CHARMING_POINT',
    UPDATE_OTHER_CHARMING_POINT: 'UPDATE_OTHER_CHARMING_POINT',

    UPDATE_ENGLISH_CHARACTERS: 'UPDATE_ENGLISH_CHARACTERS',
    UPDATE_SERBIAN_CHARACTERS: 'UPDATE_SERBIAN_CHARACTERS',
    UPDATE_OTHER_CHARACTERS: 'UPDATE_OTHER_CHARACTERS',

    UPDATE_ENGLISH_HAIRSTYLES: 'UPDATE_ENGLISH_HAIRSTYLES',
    UPDATE_SERBIAN_HAIRSTYLES: 'UPDATE_SERBIAN_HAIRSTYLES',
    UPDATE_OTHER_HAIRSTYLES: 'UPDATE_OTHER_HAIRSTYLES',

    UPDATE_ENGLISH_CLOTHES: 'UPDATE_ENGLISH_CLOTHES',
    UPDATE_SERBIAN_CLOTHES: 'UPDATE_SERBIAN_CLOTHES',
    UPDATE_OTHER_CLOTHES: 'UPDATE_OTHER_CLOTHES',

    UPDATE_ENGLISH_DESCRIPTION_BUBBLE: 'UPDATE_ENGLISH_DESCRIPTION_BUBBLE',
    UPDATE_SERBIAN_DESCRIPTION_BUBBLE: 'UPDATE_SERBIAN_DESCRIPTION_BUBBLE',
    UPDATE_OTHER_DESCRIPTION_BUBBLE: 'UPDATE_OTHER_DESCRIPTION_BUBBLE',

    UPDATE_ENGLISH_BACKGROUND_NAME: 'UPDATE_ENGLISH_BACKGROUND_NAME',
    UPDATE_SERBIAN_BACKGROUND_NAME: 'UPDATE_SERBIAN_BACKGROUND_NAME',
    UPDATE_OTHER_BACKGROUND_NAME: 'UPDATE_OTHER_BACKGROUND_NAME',  

    SET_APPENDIX: 'SET_APPENDIX',

    SET_TRANSLATION_STATUSES: 'SET_TRANSLATION_STATUSES',
    UPDATE_STATUS_OF_LANGUAGE_IN_TRANSLATIONS: 'UPDATE_STATUS_OF_LANGUAGE_IN_TRANSLATIONS',

    FIND_TEXT_SERBIAN: 'FIND_TEXT_SERBIAN',
    FIND_TEXT_ENGLISH: 'FIND_TEXT_ENGLISH',
    FIND_TEXT_OTHER: 'FIND_TEXT_OTHER'
}

export const ActionCreatorsForTranslations = 
{
    setTranslations: (payload: any) => ({type: ActionTypes.SET_TRANSLATIONS, payload}),
    setTranslationEpisodes: (payload : any) => ({type: ActionTypes.SET_TRANSLATION_EPISODES, payload}),
    setEpisodeTranslatuions: (payload : any) => ({type: ActionTypes.SET_EPISODE_TRANSLATIONS, payload}),
    setLanguagesLines: (payload : any) => ({type: ActionTypes.SET_LANGUAGES_LINES, payload}),

    setCharacters: (payload : any) => ({type: ActionTypes.SET_CHARACTERS, payload}),
    setEmotions: (payload : any) => ({type: ActionTypes.SET_EMOTIONS, payload}),
    setAnimations: (payload : any) => ({type: ActionTypes.SET_ANIMATIONS, payload}),
    setClothingCombination: (payload : any) => ({type: ActionTypes.SET_CLOTHING_COMBINATIONS, payload}),
    setSkinColors: (payload : any) => ({type: ActionTypes.SET_SKIN_COLORS, payload}),
    setLineTypes: (payload : any) => ({type: ActionTypes.SET_LINE_TYPES, payload}),
    setLineFormats: (payload : any) => ({type: ActionTypes.SET_LINE_FORMATS, payload}),

    setCurrentLineForTranslations: (payload : any) => ({type: ActionTypes.SET_CURRENT_LINE_FOR_TRANSLATIONS, payload}),

    updateEnglishDescription: (payload : any) => ({type: ActionTypes.UPDATE_ENGLISH_DESCRIPTION, payload}),
    updateSerbianDescription: (payload : any) => ({type: ActionTypes.UPDATE_SERBIAN_DESCRIPTION, payload}),
    updateOtherDescription: (payload : any) => ({type: ActionTypes.UPDATE_OTHER_DESCRIPTION, payload}),

    updateEnglishChapterSummary: (payload : any) => ({type: ActionTypes.UPDATE_ENGLISH_CHAPTER_SUMMARY, payload}),
    updateSerbianChapterSummary: (payload : any) => ({type: ActionTypes.UPDATE_SERBIAN_CHAPTER_SUMMARY, payload}),
    updateOtherChapterSummary: (payload : any) => ({type: ActionTypes.UPDATE_OTHER_CHAPTER_SUMMARY, payload}),

    updateEnglishCharmingPoint: (payload : any) => ({type: ActionTypes.UPDATE_ENGLISH_CHARMING_POINT, payload}),
    updateSerbianCharmingPoint: (payload : any) => ({type: ActionTypes.UPDATE_SERBIAN_CHARMING_POINT, payload}),
    updateOtherCharmingPoint: (payload : any) => ({type: ActionTypes.UPDATE_OTHER_CHARMING_POINT, payload}),

    updateEnglishCharacters: (payload : any) => ({type: ActionTypes.UPDATE_ENGLISH_CHARACTERS, payload}),
    updateSerbianCharacters: (payload : any) => ({type: ActionTypes.UPDATE_SERBIAN_CHARACTERS, payload}),
    updateOtherCharacters: (payload : any) => ({type: ActionTypes.UPDATE_OTHER_CHARACTERS, payload}),

    updateEnglishHairstyles: (payload : any) => ({type: ActionTypes.UPDATE_ENGLISH_HAIRSTYLES, payload}),
    updateSerbianHairstyles: (payload : any) => ({type: ActionTypes.UPDATE_SERBIAN_HAIRSTYLES, payload}),
    updateOtherHairstyles: (payload : any) => ({type: ActionTypes.UPDATE_OTHER_HAIRSTYLES, payload}),

    updateEnglishClothes: (payload : any) => ({type: ActionTypes.UPDATE_ENGLISH_CLOTHES, payload}),
    updateSerbianClothes: (payload : any) => ({type: ActionTypes.UPDATE_SERBIAN_CLOTHES, payload}),
    updateOtherClothes: (payload : any) => ({type: ActionTypes.UPDATE_OTHER_CLOTHES, payload}),

    updateEnglishDescriptionBubble: (payload : any) => ({type: ActionTypes.UPDATE_ENGLISH_DESCRIPTION_BUBBLE, payload}),
    updateSerbianDescriptionBubble: (payload : any) => ({type: ActionTypes.UPDATE_SERBIAN_DESCRIPTION_BUBBLE, payload}),
    updateOtherDescriptionBubble: (payload : any) => ({type: ActionTypes.UPDATE_OTHER_DESCRIPTION_BUBBLE, payload}),

    updateEnglishBackgroundName: (payload : any) => ({type: ActionTypes.UPDATE_ENGLISH_BACKGROUND_NAME, payload}),
    updateSerbianBackgroundName: (payload : any) => ({type: ActionTypes.UPDATE_SERBIAN_BACKGROUND_NAME, payload}),
    updateOtherBackgroundName: (payload : any) => ({type: ActionTypes.UPDATE_OTHER_BACKGROUND_NAME, payload}),

    updateLineAfterTranslate: (payload : any) => ({type: ActionTypes.UPDATE_LINE_AFTER_TRANSLATE, payload}),

    setUpdatingBool: (payload : any) => ({type: ActionTypes.SET_UPDATING_BOOL, payload}),

    setAppendix: (payload : any) => ({type: ActionTypes.SET_APPENDIX, payload}),

    setTranslationStatuses: (payload : any) => ({type: ActionTypes.SET_TRANSLATION_STATUSES, payload}),
    updateStatusOfLanguageInTranslations: (payload : any) => ({type: ActionTypes.UPDATE_STATUS_OF_LANGUAGE_IN_TRANSLATIONS, payload}),

    findTextSerbian: (payload : any) => ({type: ActionTypes.FIND_TEXT_SERBIAN, payload}),
    findTextEnglish: (payload : any) => ({type: ActionTypes.FIND_TEXT_ENGLISH, payload}),
    findTextOther: (payload : any) => ({type: ActionTypes.FIND_TEXT_OTHER, payload}),
}

export default function translationsReducer(state=initialState, action: any)
{
    switch(action.type)
    {
        case ActionTypes.FIND_TEXT_SERBIAN:
            let updatedStoredArrSerbian = state.serbianTranslate.map(line => 
            {
                const exists = action.payload.find((content : any) => line.docId == content.docId);
                return exists ? ({...line, filtered: true}) : {...line, filtered: false};
            });
            return {...state, serbianTranslate: updatedStoredArrSerbian}       
        case ActionTypes.FIND_TEXT_ENGLISH:
            let updatedStoredArrEnglish = state.englishTranslate.map(line => 
            {
                const exists = action.payload.find((content : any) => line.docId == content.docId);
                return exists ? ({...line, filtered: true}) : {...line, filtered: false};
            });
            return {...state, englishTranslate: updatedStoredArrEnglish}
        case ActionTypes.FIND_TEXT_OTHER:
            let updatedStoredArrOther = state.otherLanguageTranslate.map(line => 
            {
                const exists = action.payload.find((content : any) => line.docId == content.docId);
                return exists ? ({...line, filtered: true}) : {...line, filtered: false};
            });
            return {...state, otherLanguageTranslate: updatedStoredArrOther}
        case ActionTypes.SET_TRANSLATIONS:
            return {...state, translations: [...action.payload]};
        case ActionTypes.SET_TRANSLATION_EPISODES:
            return {...state, translationEpisodes: [...action.payload]}
        case ActionTypes.SET_EPISODE_TRANSLATIONS:
            return {...state, episodeTranslations: [...action.payload]}
        case ActionTypes.SET_LANGUAGES_LINES:
            return { ...state, 
                serbianTranslate: action.payload.serbianTranslate ? [...action.payload.serbianTranslate] : [defaultLine], 
                englishTranslate: action.payload.englishTranslate ? [...action.payload.englishTranslate] : [defaultLine], 
                otherLanguageTranslate: action.payload.otherLanguageTranslate ? [...action.payload.otherLanguageTranslate] : [defaultLine] }

        case ActionTypes.SET_CURRENT_LINE_FOR_TRANSLATIONS:
            return { ...state, 
                currentLineSerbian: action.payload.currentLineSerbian, 
                currentLineEnglish: action.payload.currentLineEnglish,
                currentLineOther: action.payload.currentLineOther,
                selectedLineIndex: action.payload.currentLineIndex, 
                linesStartIndex: action.payload.linesStartIndex 
            }
        case ActionTypes.SET_CHARACTERS:
            return {...state, characters: [...action.payload]};
        case ActionTypes.SET_EMOTIONS:
            return {...state, emotions: [...action.payload]};
        case ActionTypes.SET_ANIMATIONS:
            return {...state, animations: [...action.payload]};
        case ActionTypes.SET_CLOTHING_COMBINATIONS:
            return {...state, clothingCombinations: [...action.payload]};
        case ActionTypes.SET_SKIN_COLORS:
            return {...state, skinColors: [...action.payload]};
        case ActionTypes.SET_LINE_TYPES:
            return {...state, lineTypes: [...action.payload]};
        case ActionTypes.SET_LINE_FORMATS:
            return {...state, lineFormats: [...action.payload]};

                       
        case ActionTypes.UPDATE_ENGLISH_DESCRIPTION:
            return {...state, appendix: {...state.appendix, episodeAppendix: state.appendix.episodeAppendix?.map((content: any, i : any) => i == action.payload.index ?
                {...content, englishDescription: action.payload.value} : content)}};
        case ActionTypes.UPDATE_SERBIAN_DESCRIPTION:
            return {...state, appendix: {...state.appendix, episodeAppendix: state.appendix.episodeAppendix?.map((content: any, i : any) => i == action.payload.index ?
                {...content, serbianDescription: action.payload.value} : content)}};
        case ActionTypes.UPDATE_OTHER_DESCRIPTION:
            return {...state, appendix: {...state.appendix, episodeAppendix: state.appendix.episodeAppendix?.map((content: any, i : any) => i == action.payload.index ?
                {...content, otherDescription: action.payload.value} : content)}};
                
        case ActionTypes.UPDATE_ENGLISH_CHAPTER_SUMMARY:
            return {...state, appendix: {...state.appendix, chaptersSummaryAppendixList: state.appendix.chaptersSummaryAppendixList?.map((content: any, i : any) => i == action.payload.index ?
                {...content, englishName: action.payload.value} : content)}};
        case ActionTypes.UPDATE_SERBIAN_CHAPTER_SUMMARY:
            return {...state, appendix: {...state.appendix, episodeAppendix: state.appendix.episodeAppendix?.map((content: any, i : any) => i == action.payload.index ?
                {...content, serbianName: action.payload.value} : content)}};
        case ActionTypes.UPDATE_OTHER_CHAPTER_SUMMARY:
            return {...state, appendix: {...state.appendix, episodeAppendix: state.appendix.episodeAppendix?.map((content: any, i : any) => i == action.payload.index ?
                {...content, otherName: action.payload.value} : content)}};
                
        case ActionTypes.UPDATE_ENGLISH_CHARMING_POINT:
            return {...state, appendix: {...state.appendix, charmingPointsAppendixList: state.appendix.charmingPointsAppendixList?.map((content: any, i : any) => i == action.payload.index ?
                {...content, englishName: action.payload.value} : content)}};
        case ActionTypes.UPDATE_SERBIAN_CHARMING_POINT:
            return {...state, appendix: {...state.appendix, charmingPointsAppendixList: state.appendix.charmingPointsAppendixList?.map((content: any, i : any) => i == action.payload.index ?
                {...content, serbianName: action.payload.value} : content)}};
        case ActionTypes.UPDATE_OTHER_CHARMING_POINT:
            return {...state, appendix: {...state.appendix, charmingPointsAppendixList: state.appendix.charmingPointsAppendixList?.map((content: any, i : any) => i == action.payload.index ?
                {...content, otherName: action.payload.value} : content)}};
                       
        case ActionTypes.UPDATE_ENGLISH_CHARACTERS:
            return {...state, appendix: {...state.appendix, charactersAppendix: state.appendix.charactersAppendix?.map((content: any, i : any) => i == action.payload.index ?
                {...content, englishName: action.payload.value} : content)}};
        case ActionTypes.UPDATE_SERBIAN_CHARACTERS:
            return {...state, appendix: {...state.appendix, charactersAppendix: state.appendix.charactersAppendix?.map((content: any, i : any) => i == action.payload.index ?
                {...content, serbianName: action.payload.value} : content)}};
        case ActionTypes.UPDATE_OTHER_CHARACTERS:
            return {...state, appendix: {...state.appendix, charactersAppendix: state.appendix.charactersAppendix?.map((content: any, i : any) => i == action.payload.index ?
                {...content, otherName: action.payload.value} : content)}};
                
        case ActionTypes.UPDATE_ENGLISH_HAIRSTYLES:
            return {...state, appendix: {...state.appendix, hairstyleAppendix: state.appendix.hairstyleAppendix?.map((content: any, i : any) => i == action.payload.index ?
                {...content, englishName: action.payload.value} : content)}};
        case ActionTypes.UPDATE_SERBIAN_HAIRSTYLES:
            return {...state, appendix: {...state.appendix, hairstyleAppendix: state.appendix.hairstyleAppendix?.map((content: any, i : any) => i == action.payload.index ?
                {...content, serbianName: action.payload.value} : content)}};
        case ActionTypes.UPDATE_OTHER_HAIRSTYLES:
            return {...state, appendix: {...state.appendix, hairstyleAppendix: state.appendix.hairstyleAppendix?.map((content: any, i : any) => i == action.payload.index ?
                {...content, otherName: action.payload.value} : content)}};
                        
        case ActionTypes.UPDATE_ENGLISH_CLOTHES:
            return {...state, appendix: {...state.appendix, clothesAppendix: state.appendix.clothesAppendix?.map((content: any, i : any) => i == action.payload.index ?
                {...content, englishName: action.payload.value} : content)}};
        case ActionTypes.UPDATE_SERBIAN_CLOTHES:
            return {...state, appendix: {...state.appendix, clothesAppendix: state.appendix.clothesAppendix?.map((content: any, i : any) => i == action.payload.index ?
                {...content, serbianName: action.payload.value} : content)}};
        case ActionTypes.UPDATE_OTHER_CLOTHES:
            return {...state, appendix: {...state.appendix, clothesAppendix: state.appendix.clothesAppendix?.map((content: any, i : any) => i == action.payload.index ?
                {...content, otherName: action.payload.value} : content)}};

        case ActionTypes.UPDATE_ENGLISH_DESCRIPTION_BUBBLE:
            return {...state, appendix: {...state.appendix, descriptionBubbleAppendixList: state.appendix.descriptionBubbleAppendixList?.map((content: any, i : any) => i == action.payload.index ?
                {...content, englishName: action.payload.value} : content)}};
        case ActionTypes.UPDATE_SERBIAN_DESCRIPTION_BUBBLE:
            return {...state, appendix: {...state.appendix, descriptionBubbleAppendixList: state.appendix.descriptionBubbleAppendixList?.map((content: any, i : any) => i == action.payload.index ?
                {...content, serbianName: action.payload.value} : content)}};
        case ActionTypes.UPDATE_OTHER_DESCRIPTION_BUBBLE:
            return {...state, appendix: {...state.appendix, descriptionBubbleAppendixList: state.appendix.descriptionBubbleAppendixList?.map((content: any, i : any) => i == action.payload.index ?
                {...content, otherName: action.payload.value} : content)}};

        case ActionTypes.UPDATE_ENGLISH_BACKGROUND_NAME:
            return {...state, appendix: {...state.appendix, backgroundAppendix: state.appendix.backgroundAppendix?.map((content: any, i : any) => i == action.payload.index ?
                {...content, englishName: action.payload.value} : content)}};
        case ActionTypes.UPDATE_SERBIAN_BACKGROUND_NAME:
            return {...state, appendix: {...state.appendix, backgroundAppendix: state.appendix.backgroundAppendix?.map((content: any, i : any) => i == action.payload.index ?
                {...content, serbianName: action.payload.value} : content)}};
        case ActionTypes.UPDATE_OTHER_BACKGROUND_NAME:
            return {...state, appendix: {...state.appendix, backgroundAppendix: state.appendix.backgroundAppendix?.map((content: any, i : any) => i == action.payload.index ?
                {...content, otherName: action.payload.value} : content)}};

        case ActionTypes.UPDATE_LINE_AFTER_TRANSLATE:
                if (action.payload.language == 0) {
                    return {...state, 
                        updatingBool: 'not updating',
                        serbianTranslate: state.serbianTranslate.map((content: any, i : any) => content.docId === action.payload.lineId 
                        ? {...content, text: action.payload.text} : content )
                    };
                }
                else if (action.payload.language == 1) {
                    return {...state, 
                        updatingBool: 'not updating',
                        englishTranslate: state.englishTranslate.map((content: any, i : any) => content.docId === action.payload.lineId 
                        ? {...content, text: action.payload.text} : content )
                    };
                }
                else {
                    return {...state, 
                        updatingBool: 'not updating',
                        otherLanguageTranslate: state.otherLanguageTranslate.map((content: any, i : any) => content.docId === action.payload.lineId 
                        ? {...content, text: action.payload.text} : content)
                    };
                }
        case ActionTypes.SET_UPDATING_BOOL:
            return {...state, updatingBool: action.payload}
        case ActionTypes.SET_APPENDIX:
            return {...state, appendix: {...action.payload}}
        case ActionTypes.SET_TRANSLATION_STATUSES:
            return { ...state, translationStatuses: [...action.payload.statuses], currentTranslationStatus: action.payload.currentStatus }
        case ActionTypes.UPDATE_STATUS_OF_LANGUAGE_IN_TRANSLATIONS:
            return { ...state, episodeTranslations: state.episodeTranslations.map((content: any, i : any) => i == action.payload.index ? 
                {...content, 
                    episodeStatusEnum: action.payload.newStatus, 
                    episodeStatus: action.payload.newStatusString
                } 
                : content )};
        default:
            return state;
    }
}