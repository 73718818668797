import React, { useEffect, useState } from 'react';
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators } from '../../../store/StoriesReducer';
import { BasicLine } from './Basic';
import { BranchLine } from './Branch';
import { CinematicLine } from './Cinematic';
import { CustomizationLine } from './Customization1';
import { GotoMainLine } from './GotoMain';
import { AddOutfitLine, OutfitLine1, OutfitLine2, OutfitLine3 } from './Outfit';
import { AnswerLine, QuestionLine } from './QuestionAnswer';
import { hideTypesBasedOnPreviousLine, showMultipleLinesAddBasedOnCurrentLineType } from './_Helpers';
import { BsArrowBarRight, BsPlusSquareFill } from 'react-icons/bs';
import { ActionCreatorsForEditEpisode } from '../../../store/EditEpisodeReducer';
import { GrChatOption } from 'react-icons/gr';
import { AddMultipleLLines } from '../../../services/EpisodeServices';
import { useAlert} from 'react-alert';
import Select from 'react-select';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { CustomizationLine2 } from './Customization2';
import EndOfLevel from './LunescapeAdditionalLines/EndOfLevel';
import TimedChoice from './TimedChoice';
import TimedChoiceAnswer from './TimedChoiceAnswer';
import { LineTypes } from '../Enums/LineTypes';
import { LineFormats } from '../Enums/LineFormats';
import VisualChoice from './VisualChoice';
import VisualChoiceAnswer from './VisualChoiceAnswer';
import Fashion from './LunescapeAdditionalLines/Fashion';

export const AddNewLinePopup = ({ visible, clickedLineIndex, type, storyCharacters, animations, episodeName, serialNumber, storyId } : any) =>
{
    const dispatch = useDispatch();
    const alert = useAlert();

    const { isOLineAdded, defaultLine, lines, linesFrame, selectedLineIndex } = useSelector((state: any) => state.editEpisodeReducer);
    let { modalPopupBool } = useSelector((state : any) => state.storiesReducer)

    let currentLine = modalPopupBool?.currentLine ?? defaultLine;

    let line = {...currentLine, 
        charmingPoint: undefined,
        premiumChoiceText: undefined,
        premiumChoiceDescription: undefined,
        itemId: undefined,
        itemName: undefined,
        itemDescription: undefined
    };
    
    let basicLine = {...defaultLine};;
    if([LineTypes.A, LineTypes.C2, LineTypes.BA, LineTypes.TA, LineTypes.VA].includes(currentLine.lineType ?? 0) 
    || (modalPopupBool?.clickedLine?.lineType === LineTypes.Q) 
    || (modalPopupBool?.clickedLine?.lineType === LineTypes.T)
    || (modalPopupBool?.clickedLine?.lineType === LineTypes.V)) 
    { 
        basicLine.depth = currentLine.depth;

        if(currentLine.lineType === LineTypes.Q) {
            basicLine.lineType = LineTypes.A;
            basicLine.answerNumber = 1;
            basicLine.answerQuestionId = currentLine.docId;
        }

        if(currentLine.lineType === LineTypes.T) {
            basicLine.lineType = LineTypes.TA;
            basicLine.answerNumber = 1;
            basicLine.answerTimedChoiceId = currentLine.docId;
        }

        if(currentLine.lineType === LineTypes.V) {
            basicLine.lineType = LineTypes.VA;
            basicLine.answerNumber = 1;
            basicLine.answerVisualChoiceId = currentLine.docId;
        }

        basicLine.lineNumber = currentLine.lineNumber;

        basicLine.lineFormat = currentLine.lineFormat;

        basicLine.docId = currentLine.docId;
        basicLine.storyId = currentLine.storyId;
        basicLine.storyTranslationId = currentLine.storyTranslationId;
        basicLine.episodeId = currentLine.episodeId;
        basicLine.episodeName = currentLine.episodeName;
        basicLine.previousLineDocId = currentLine.docId;
        basicLine.episodeSerialNumber = currentLine.episodeSerialNumber;

        basicLine.characterId = currentLine.characterId ?? undefined;
        basicLine.characterName = currentLine.characterName ?? undefined;
        basicLine.characterPath = currentLine.characterPath ?? undefined;
        basicLine.emotion = currentLine.emotion;

        basicLine.musicId = currentLine.musicId ?? undefined;
        basicLine.musicName = currentLine.musicName ?? undefined;
        basicLine.musicDescription = currentLine.musicDescription ?? undefined;

        basicLine.shortSoundEnabled = currentLine.shortSoundEnabled ?? false;
        basicLine.shortSoundId = currentLine.shortSoundEnabled ? currentLine.shortSoundId : undefined;
        basicLine.shortSoundName = currentLine.shortSoundEnabled? currentLine.shortSoundName : undefined;
        basicLine.shortSoundDescription = currentLine.shortSoundEnabled ? currentLine.shortSoundDescription : undefined;


        basicLine.avatarEnabled = currentLine.avatarEnabled ?? undefined;
        basicLine.callPhoneEnabled = currentLine.callPhoneEnabled ?? undefined;
        basicLine.callSmsEnabled = currentLine.callSmsEnabled ?? undefined;

        basicLine.callerCharacterId = currentLine.callerCharacterId ?? undefined;
        basicLine.callerCharacterName = currentLine.callerCharacterName ?? undefined;

        basicLine.charactersOutfit = currentLine.charactersOutfit;
        basicLine.backgroundId = currentLine.backgroundId ?? undefined;
        basicLine.backgroundName = currentLine.backgroundName ?? undefined;
        basicLine.backgroundDescription = currentLine.backgroundDescription ?? undefined;

        basicLine.visualName = currentLine.visualName ?? undefined;
        basicLine.visualId = currentLine.visualId ?? undefined;

        basicLine.charmingPoint = undefined;
        basicLine.premiumChoiceText = undefined;
        basicLine.premiumChoiceDescription = undefined;
        basicLine.itemId = undefined;
        basicLine.itemName = undefined;
        basicLine.itemDescription = undefined;
    }
    const [decideWhatToAdd, setDecideWhatToAdd] = useState(0);
    const [numberOfLLines, setNumberOfLLines] = useState(10);
    const numberOptions = [{id: 1, value: 1, label: '1'},{id: 2, value: 2, label: '2'},{id: 3, value: 3, label: '3'},{id: 4, value: 4, label: '4'},
    {id: 5, value: 5, label: '5'},{id: 6, value: 6, label: '6'},{id: 7, value: 7, label: '7'},{id: 8, value: 8, label: '8'},{id: 9, value: 9, label: '9'},{id: 10, value: 10, label: '10'}]

    useEffect(() =>
    {
        setDecideWhatToAdd(0);
    }, [visible])

    return(<div>
        <CModal 
            show={visible} 
            className="popup-modal remove-width add-new-line-modal"
            fade 
            onClose={() => dispatch(ActionCreators.setModalPopupBool({ type: 'none', isOpen: false, index: undefined, currentLine: defaultLine, clickedLine: defaultLine })) } 
            closeOnBackdrop={false}>
                <CModalHeader closeButton>
                    <CModalTitle><BsArrowBarRight color="white" size={18} />&nbsp;&nbsp;Add New Line</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    {
                        modalPopupBool.type == 'normal' ? <div>
                        { decideWhatToAdd == 0 ? 
                            <div className="column-style">
                                <div className="column-style mt-3 ml-1" >
                                    <h6>Clicked line preview: </h6>
                                    {modalPopupBool?.clickedLine?.lineType === LineTypes.O1 ? <OutfitLine3 line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.L ? <BasicLine line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.O2 ? <OutfitLine2 line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.O3 ? <OutfitLine3 line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.Q ? <QuestionLine line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.A ? <AnswerLine line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.M ? <CinematicLine line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} animations={animations} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.C1 ? <CustomizationLine line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} storyId={storyId} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.C2 ? <CustomizationLine2 line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} storyId={storyId} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.B ? <BranchLine line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.BA ? <AnswerLine line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isBranchAnswer isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.G ? <GotoMainLine line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        //End Of Level Line
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.E ? <EndOfLevel line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} animations={animations} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.T ? <TimedChoice line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.TA ? <TimedChoiceAnswer line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.V ? <VisualChoice line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.VA ? <VisualChoiceAnswer line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.F ? <Fashion line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} storyId={storyId} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                    <div/>
                                    }
                                </div>
                                <div className="center row-style mt-5">
                                
                                <CButton className='mr-5 button-modal-choose-addline-option' type="primary"
                                onClick={() => setDecideWhatToAdd(1)}>Add one line</CButton>
                                
                                { showMultipleLinesAddBasedOnCurrentLineType(currentLine) ? <h2>OR</h2> : '' }
                                { showMultipleLinesAddBasedOnCurrentLineType(currentLine) ?
                                <div className="row-style ml-5">
                                    <CButton type="primary" className="button-modal-choose-addline-option" onClick={() => 
                                    {
                                        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updating'))
            
                                        var array = new Array(numberOfLLines).fill(null).map((element : any) => { return Object.assign({}, 
                                            {...defaultLine,
                                                isDefault: undefined,
                                                storyId: currentLine.storyId,
                                                depth: [LineTypes.A, LineTypes.VA, LineTypes.TA, LineTypes.BA, LineTypes.C2].includes(currentLine.lineType) 
                                                    ? currentLine.depth + 1 : currentLine.depth,
                                                storyTranslationId: currentLine.storyTranslationId,
                                                episodeId: currentLine.episodeId,
                                                episodeName: currentLine.episodeName,
                                                lineFormat: LineFormats.S,
                                                emotion: 1,
                                                lineType: LineTypes.L,
                                                previousLineDocId: currentLine.docId,
                                                episodeSerialNumber: currentLine.episodeSerialNumber,
                                                lineNumber: currentLine.lineNumber,

                                                musicId: currentLine.musicId ?? undefined,
                                                musicName: currentLine.musicName ?? undefined,
                                                musicDescription: currentLine.musicDescription ?? undefined,
                                                shortSoundEnabled: currentLine.shortSoundEnabled ?? undefined,
                                                shortSoundId: currentLine.shortSoundEnabled ? currentLine.shortSoundId : undefined,
                                                shortSoundName: currentLine.shortSoundEnabled? currentLine.shortSoundName : undefined,
                                                shortSoundDescription: currentLine.shortSoundEnabled ? currentLine.shortSoundDescription : undefined,
                                                avatarEnabled: currentLine.avatarEnabled ?? undefined,
                                                callPhoneEnabled: currentLine.callPhoneEnabled ?? undefined,
                                                callSmsEnabled: currentLine.callSmsEnabled ?? undefined,
                                                callerCharacterId: currentLine.callerCharacterId ?? undefined,
                                                callerCharacterName: currentLine.callerCharacterName ?? undefined,
                                        
                                                charactersOutfit: currentLine.charactersOutfit,
                                                backgroundId: currentLine.backgroundId ?? undefined,
                                                backgroundName: currentLine.backgroundName ?? undefined,
                                                backgroundDescription: currentLine.backgroundDescription ?? undefined,

                                                itemId: undefined,
                                                itemName: undefined,
                                                itemDescription: undefined,
                                                charmingPoint: undefined,
                                                premiumChoiceText: undefined,
                                                premiumChoiceDescription: undefined
                                            }) });
                                        
                                        AddMultipleLLines(array, clickedLineIndex, linesFrame, selectedLineIndex, dispatch, alert)
                                    }}>Add multiple empty L lines</CButton>
                                    
                                    <div style={{width: '75px', marginTop: '7px', marginLeft: '15px'}}>
                                        <Select  options={numberOptions} value={{id: numberOfLLines, value: numberOfLLines, label: numberOfLLines.toString()}} onChange={(value : any) => { setNumberOfLLines(value.value)}}/>
                                    </div>
                                    
                                </div> 
                            : '' }
                            </div>
                            </div>
                            : decideWhatToAdd == 1 ?
                            <div>
                                <p className="cursor" onClick={() => { setDecideWhatToAdd(0)}}><FaArrowCircleLeft />&nbsp;Go back to selection</p>
                                <p className="modal-q-add-line   text-center" style={{fontSize: '1.2rem'}}>Are you sure you want to add this line?</p>
                                <p className="modal-confirm text-muted text-center" style={{fontSize: '1.0em'}}>Please select the line type you wish to add.</p>
            
                                <div className="column-style mt-3 ml-1" >
                                    <h6>Clicked line preview: </h6>
                                    {modalPopupBool?.clickedLine?.lineType === LineTypes.O1 ? <OutfitLine3 line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.L ? <BasicLine line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.O2 ? <OutfitLine2 line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.O3 ? <OutfitLine3 line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.Q ? <QuestionLine line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.A ? <AnswerLine line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.M ? <CinematicLine line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} animations={animations} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.C1 ? <CustomizationLine line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} storyId={storyId} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.C2 ? <CustomizationLine2 line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} storyId={storyId} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.B ? <BranchLine line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.BA ? <AnswerLine line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isBranchAnswer isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.G ? <GotoMainLine line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.E ? <EndOfLevel  line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} animations={animations} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.T ? <TimedChoice line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.TA ? <TimedChoiceAnswer line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.V ? <VisualChoice line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.VA ? <VisualChoiceAnswer line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                        modalPopupBool?.clickedLine?.lineType === LineTypes.F ? <Fashion line={{...lines[clickedLineIndex], depth: 0}} inputLine={false} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber} storyId={storyId} isPreviewInModal={true} inPreviewIndex='previewIndex' /> :
                                    <div/>
                                    }
                                </div>
            
                                <div className="column-style mt-5 ml-1" >
                                    {line.lineType === LineTypes.L ? <BasicLine line={line} inputLine={true} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber}/> :
                                        (line.lineType === LineTypes.O1 || line.lineType === LineTypes.O2 || line.lineType === LineTypes.O3) ? <AddOutfitLine line={line} inputLine={true} index={clickedLineIndex} episodeName={episodeName} serialNumber={serialNumber}/> :
                                        
                                            line.lineType === LineTypes.A && modalPopupBool.isFirstTimeOpen ? <BasicLine line={basicLine} inputLine={true} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber}/> :
                                            line.lineType === LineTypes.A && !hideTypesBasedOnPreviousLine(lines, clickedLineIndex, modalPopupBool.type).includes(5) ? <AnswerLine line={line} inputLine={true} index={clickedLineIndex} serialNumber={serialNumber} episodeName={episodeName}/> :
                                            line.lineType === LineTypes.A && hideTypesBasedOnPreviousLine(lines, clickedLineIndex, modalPopupBool.type).includes(5) ? <BasicLine line={basicLine} inputLine={true} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber}/> :
            
                                            line.lineType === LineTypes.TA && modalPopupBool.isFirstTimeOpen ? <BasicLine line={basicLine} inputLine={true} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber}/> :
                                            line.lineType === LineTypes.TA && !hideTypesBasedOnPreviousLine(lines, clickedLineIndex, modalPopupBool.type).includes(14) ? <TimedChoiceAnswer line={line} inputLine={true} index={clickedLineIndex} serialNumber={serialNumber} episodeName={episodeName}/> :
                                            line.lineType === LineTypes.TA && hideTypesBasedOnPreviousLine(lines, clickedLineIndex, modalPopupBool.type).includes(14) ? <BasicLine line={basicLine} inputLine={true} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber}/> :
                                            
                                            line.lineType === LineTypes.VA && modalPopupBool.isFirstTimeOpen ? <BasicLine line={basicLine} inputLine={true} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber}/> :
                                            line.lineType === LineTypes.VA && !hideTypesBasedOnPreviousLine(lines, clickedLineIndex, modalPopupBool.type).includes(16) ? <VisualChoiceAnswer line={line} inputLine={true} index={clickedLineIndex} serialNumber={serialNumber} episodeName={episodeName}/> :
                                            line.lineType === LineTypes.VA && hideTypesBasedOnPreviousLine(lines, clickedLineIndex, modalPopupBool.type).includes(16) ? <BasicLine line={basicLine} inputLine={true} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber}/> :
            
                                            line.lineType === LineTypes.M ? <CinematicLine line={line} inputLine={true} animations={animations} index={clickedLineIndex}  episodeName={episodeName} serialNumber={serialNumber}/> :
                                            line.lineType === LineTypes.C1 ? <CustomizationLine line={line} index={clickedLineIndex} inputLine={true} episodeName={episodeName} serialNumber={serialNumber} storyId={storyId}/> :
                                            line.lineType === LineTypes.C2 ? <BasicLine line={basicLine} inputLine={true} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber}/> :
                                            line.lineType === LineTypes.B ? <BranchLine line={line} inputLine={true} index={clickedLineIndex} serialNumber={serialNumber}/> :
                                            line.lineType === LineTypes.BA ? <BasicLine line={basicLine} inputLine={true} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber}/> :    
                                            line.lineType === LineTypes.G ? <GotoMainLine line={line} inputLine={true} index={clickedLineIndex} episodeName={episodeName} serialNumber={serialNumber}/> :
                                            line.lineType === LineTypes.E ? <EndOfLevel line={line} inputLine={true} animations={animations} index={clickedLineIndex}  episodeName={episodeName} serialNumber={serialNumber}/> :
                                            modalPopupBool?.clickedLine?.lineType === LineTypes.Q ? <AnswerLine line={basicLine} inputLine={true} index={clickedLineIndex} serialNumber={serialNumber} episodeName={episodeName}/> :
                                            modalPopupBool?.clickedLine?.lineType === LineTypes.T ? <TimedChoiceAnswer line={basicLine} inputLine={true} index={clickedLineIndex} serialNumber={serialNumber} episodeName={episodeName}/> :
                                            modalPopupBool?.clickedLine?.lineType === LineTypes.V ? <VisualChoiceAnswer line={basicLine} inputLine={true} index={clickedLineIndex} serialNumber={serialNumber} episodeName={episodeName}/> :
                                            line.lineType === LineTypes.Q ? <QuestionLine line={line} inputLine={true} index={clickedLineIndex} storyCharacters={storyCharacters} serialNumber={serialNumber} episodeName={episodeName}/> :
                                            line.lineType === LineTypes.T ? <TimedChoice line={line} inputLine={true} index={clickedLineIndex} storyCharacters={storyCharacters} serialNumber={serialNumber} episodeName={episodeName}/> :
                                            line.lineType === LineTypes.V ? <VisualChoice line={line} inputLine={true} index={clickedLineIndex} storyCharacters={storyCharacters} serialNumber={serialNumber} episodeName={episodeName}/> :
                                            line.lineType === LineTypes.F ? <Fashion line={line} index={clickedLineIndex} inputLine={true} episodeName={episodeName} serialNumber={serialNumber} storyId={storyId}/> 
                                        : <div/>
                                    }
                                </div>
                            </div> : ''}</div>
                        : modalPopupBool.type == 'above' ?
                        <div>
                            <h5 className="center">By using this option, you will add a line or block of lines&nbsp;<span style={{color: 'blue', fontWeight: 'bold'}}>ABOVE</span>&nbsp;the one you clicked.</h5>
                            <div className="column-style mt-5 ml-1" >
                            {
                                (line.lineType === LineTypes.O1 || line.lineType === LineTypes.O2 || line.lineType === LineTypes.O3) ? <AddOutfitLine line={line} inputLine={true} index={clickedLineIndex} episodeName={episodeName} serialNumber={serialNumber}/> :
                                line.lineType === LineTypes.L ? <BasicLine line={line} inputLine={true} index={clickedLineIndex} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber}/> :
                                    line.lineType === LineTypes.M ? <CinematicLine line={line} inputLine={true} animations={animations} index={clickedLineIndex}  episodeName={episodeName} serialNumber={serialNumber}/> :
                                        line.lineType === LineTypes.C1 ? <CustomizationLine line={line} index={clickedLineIndex} inputLine={true} episodeName={episodeName} serialNumber={serialNumber} storyId={storyId}/> :
                                            line.lineType === LineTypes.B ? <BranchLine line={line} inputLine={true} index={clickedLineIndex} serialNumber={serialNumber}/> :
                                                line.lineType === LineTypes.G ? <GotoMainLine line={line} inputLine={true} index={clickedLineIndex} episodeName={episodeName} serialNumber={serialNumber}/> :
                                                    line.lineType === LineTypes.E ? <EndOfLevel line={line} inputLine={true} animations={animations} index={clickedLineIndex}  episodeName={episodeName} serialNumber={serialNumber}/> :
                                                line.lineType === LineTypes.Q ? <QuestionLine line={line} inputLine={true} index={clickedLineIndex} storyCharacters={storyCharacters} serialNumber={serialNumber} episodeName={episodeName}/> :
                                            line.lineType === LineTypes.T ? <TimedChoice line={line} inputLine={true} index={clickedLineIndex} storyCharacters={storyCharacters} serialNumber={serialNumber} episodeName={episodeName}/> :
                                        line.lineType === LineTypes.V ? <VisualChoice line={line} inputLine={true} index={clickedLineIndex} storyCharacters={storyCharacters} serialNumber={serialNumber} episodeName={episodeName}/> :
                                    line.lineType === LineTypes.F ? <Fashion line={line} index={clickedLineIndex} inputLine={true} episodeName={episodeName} serialNumber={serialNumber} storyId={storyId}/> 
                                : <div/>
                            }
                            </div>
                        </div>
                        : <div/>
                    }
                </CModalBody>
                <CModalFooter/>
        </CModal>
    </div>)
}

export default AddNewLinePopup;