import React, { useEffect, useState } from 'react';
import { CInputGroup, CForm, CButton } from '@coreui/react';
import { Field, Formik } from 'formik';
import { SaveBranchLine } from '../../../services/EpisodeServices';
import { useAlert } from 'react-alert';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorsForEditEpisode } from '../../../store/EditEpisodeReducer';
import { colorLineInsideAnswer, colorLineWithComment, createDefaultBranchQandCOptions, createDefaultLineTypeOptions, editEpisodeReadOnlyCell, getInputLineNumber, isLineOptionChanged, showLineActions, validateActionChange } from './_Helpers';
import { LineType } from '../../../interfaces/lineType';
import { ActionCreators } from '../../../store/StoriesReducer';
import { DeleteLines } from '../../../services/LineServices';
import { DeleteMultipleLinesConfirmationModal } from '../../Helpers/YesNoModal';
import '../../../styles/Lines.css'
import { LineTypes } from '../Enums/LineTypes';
import LineSidebar from '../LineSidebar';

const stateWithClearedLineBranch = (inputLine: boolean, selectedLineIndex: number, lines: LineType[], line: LineType, index: number, isRenderedByAction: boolean, isModalOpen: boolean, maxLineNumber: number, modalType : string) => 
{
    return (inputLine && selectedLineIndex === -1) || isModalOpen ? 
    { 
        docId: line.docId,
        storyId: line.storyId,
        storyTranslationId: line.storyTranslationId,
        episodeId: line.episodeId,
        lineNumber: getInputLineNumber(lines, line, index, maxLineNumber,isModalOpen, modalType),
        depth: isModalOpen ? line.depth: 0,
        lineType: line.lineType,
        lineFormat: undefined,
        episodeName: line.episodeName,
        previousLineDocId: line.previousLineDocId,
        episodeSerialNumber: line.episodeSerialNumber,

        characterId: line.characterId,
        characterName: line.characterName,
        characterPath: line.characterPath,
        emotion: line.emotion,
        text: undefined,

        animationId: undefined,
        animationName: undefined,
        payed: undefined,
        answerNumber: undefined,
        answerQuestionId: undefined,
        questionDocId: undefined,
    
        comment: undefined,
        commentReporterName: undefined,
        commentReporterUID: undefined,
        commentAssignedToName: undefined,
        commentAssignedToUID: undefined,
        commentResolved: undefined,

        musicId: line.musicId,
        musicName: line.musicName,
        musicDescription: line.musicDescription,

        shortSoundEnabled: line.shortSoundEnabled,
        shortSoundId: line.shortSoundId,
        shortSoundName: line.shortSoundName,
        shortSoundDescription: line.shortSoundDescription,

        itemId: line.itemId ?? undefined,
        itemName: line.itemName ?? undefined,
        itemDescription: line.itemDescription ?? undefined,
        
        avatarEnabled: line.avatarEnabled,

        changeIdentityOn: line.changeIdentityOn,
        changeIdentityName: line.changeIdentityName,

        callPhoneEnabled: line.callPhoneEnabled != null ? line.callPhoneEnabled : undefined,
        callSmsEnabled: line.callSmsEnabled != null ? line.callSmsEnabled : undefined,
        
        callerCharacterId: line.callerCharacterId,
        callerCharacterName: line.callerCharacterName,

        charmingPoint: line.charmingPoint != null ? line.charmingPoint : undefined,
        premiumChoiceText: line.premiumChoiceText != null ? line.premiumChoiceText : undefined,
        premiumChoiceDescription: line.premiumChoiceDescription != null ? line.premiumChoiceDescription : undefined,
        charactersOutfit: line.charactersOutfit,

        backgroundId: line.backgroundId,
        backgroundName: line.backgroundName,
        backgroundDescription: line.backgroundDescription,
    
        clothes1Name: undefined,
        clothes2Name: undefined,
        clothes3Name: undefined,
        clothes4Name: undefined,
        clothes5Name: undefined,
        clothes6Name: undefined,
        hairstyle1Name: undefined,
        hairstyle2Name: undefined,
        hairstyle3Name: undefined,
    
        clothes1Id: undefined,
        clothes2Id: undefined,
        clothes3Id: undefined,
        clothes4Id: undefined,
        clothes5Id: undefined,
        clothes6Id: undefined,
        hairstyle1Id: undefined,
        hairstyle2Id: undefined,
        hairstyle3Id: undefined
    } 
    : 
    line
}

export const BranchLine = ({ line, inputLine, index, episodeName, serialNumber, isPreviewInModal, inPreviewIndex  }: any) => 
{
    const dispatch = useDispatch();
    const alert = useAlert(); 
    const { selectedLineIndex, linesFrame, initEditEpisodeBody, lines, linesStartIndex, 
        defaultLineTypes, defaultBranchQandC,
        isRenderedByAction, updateCloudBool, isOLineAdded, maxLineNumber, pickedActionColor, editEpisodePassParam, pickedColorType } = useSelector((state: any) => state.editEpisodeReducer);
    const { modalPopupBool } = useSelector((state: any) => state.storiesReducer);

    const [yesnomodal, setYesnomodal] = useState({boolean: false, array: [], nextLineDocId: null});

    const [clearedLine, clearLine] = useState(stateWithClearedLineBranch(inputLine, selectedLineIndex, lines, line, index, isRenderedByAction, modalPopupBool.isOpen, maxLineNumber, modalPopupBool.type));
    let currentLineTypes = createDefaultLineTypeOptions(lines, index, defaultLineTypes, defaultBranchQandC, isOLineAdded, modalPopupBool.isOpen, modalPopupBool.type);
    let currentBranchQandC = createDefaultBranchQandCOptions(lines, index, serialNumber, defaultBranchQandC);

    let addLineSchema = yup.object().shape(
    {
        //lineNumber: yup.string().required("Required."),
        //lineType: yup.string().required("Required."),
        //questionId: yup.string().required("Required."),
    });

    const setupSpanElements = () => {
        let lineNumber = document.querySelector(`span[name="lineNumber${isPreviewInModal ? inPreviewIndex : index}"]`);
        if(lineNumber) lineNumber.innerHTML = '-';
        let lineType = document.querySelector(`span[name="lineType${isPreviewInModal ? inPreviewIndex : index}"]`);
        if(lineType) { 
            let types = defaultLineTypes?.filter((x:any) => x.id == line.lineType);
            lineType.innerHTML = types.length > 0 ? types[0].label : '';
        }
        let text = document.querySelector(`span[name="branchQandCId${isPreviewInModal ? inPreviewIndex : index}"]`);
        if(text) text.innerHTML = line.text ?? "";
    }

    useEffect(() => { 
        if(inputLine && !initEditEpisodeBody && !isRenderedByAction) { 
            let episodeWrapper = document.querySelector('.editEpisodeWrapper');
            if(episodeWrapper) {
                //episodeWrapper.scrollIntoView({block: 'end', behavior: 'smooth'}); 
            }
        }
        if(!inputLine) {
            setupSpanElements()
        }                
    }, [line])

    useEffect(() => 
    {
        clearLine(stateWithClearedLineBranch(inputLine, selectedLineIndex, lines, line, index, isRenderedByAction, modalPopupBool.isOpen, maxLineNumber, modalPopupBool.type))
    }, [line,maxLineNumber])

    return (
        <Formik initialValues={{ lineNumber: getInputLineNumber(lines, line, index, maxLineNumber, modalPopupBool.isOpen, modalPopupBool.type), lineType: line.lineType }}
            onSubmit={(values: LineType) => {
                if(line.changeIdentityOn && !line.changeIdentityName)
                {
                    alert.info('You must fill in the character name in Change Identity, because you have enabled it.')
                }
                else
                {
                    dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updating'));
                    let previousLine = lines[
                        index != null && modalPopupBool.isOpen && modalPopupBool.type == 'above' ? index - 1 
                        : index != null ? index : lines.length - 1];
                        
                    let previousLineActions;
                    if(previousLine && modalPopupBool.isOpen && modalPopupBool.type == 'above')
                    {
                        previousLineActions = {
                            backgroundId: previousLine.backgroundId,
                            backgroundPath: previousLine.backgroundPath,
                            backgroundName: previousLine.backgroundName,
                            backgroundDescription: previousLine.backgroundDescription,
                            musicId: previousLine.musicId,
                            musicPath: previousLine.musicPath,
                            musicName: previousLine.musicName,
                            musicDescription: previousLine.musicDescription,
                            shortSoundId: previousLine.shortSoundId,
                            shortSoundPath: previousLine.shortSoundPath,
                            shortSoundName: previousLine.shortSoundName,
                            shortSoundDescription: previousLine.shortSoundDescription,
                            shortSoundEnabled: previousLine.shortSoundEnabled,
                            itemId: previousLine.itemId,
                            itemName: previousLine.itemName,
                            itemDescription: previousLine.itemDescription,
                            avatarEnabled: previousLine.avatarEnabled,
                            callPhoneEnabled: previousLine.callPhoneEnabled,
                            callSmsEnabled: previousLine.callSmsEnabled,
                            callerCharacterId: previousLine.callerCharacterId,
                            callerCharacterName: previousLine.callerCharacterName,
                            actionChangeFlag: previousLine.actionChangeFlag,
                            optionChangeFlag: previousLine.optionChangeFlag,
                            charmingPoint: previousLine.charmingPoint,
                            premiumChoiceText: previousLine.premiumChoiceText,
                            premiumChoiceDescription: previousLine.premiumChoiceDescription,
                            charactersOutfit: previousLine.charactersOutfit
                        }
                    }
                    values = {
                        ...values,
                        ...clearedLine,
                        ...previousLineActions,
                        previousLineDocId: previousLine?.docId,
                        episodeName: episodeName,
                        episodeSerialNumber: serialNumber,
                        b_QuestionId: line.branchType != null ? (line.branchType == LineTypes.Q ? line.branchValue : undefined) : (currentBranchQandC[0].props.datatype == LineTypes.Q ? currentBranchQandC[0].key : undefined),
                        b_CustomizationId: line.branchType != null ? (line.branchType == LineTypes.C1 ? line.branchValue : undefined) : (currentBranchQandC[0].props.datatype == LineTypes.C1 ? currentBranchQandC[0].key : undefined),
                        b_TimedChoiceId: line.branchType != null ? (line.branchType == LineTypes.T ? line.branchValue : undefined) : (currentBranchQandC[0].props.datatype == LineTypes.T ? currentBranchQandC[0].key : undefined),
                        b_VisualChoiceId: line.branchType != null ? (line.branchType == LineTypes.V ? line.branchValue : undefined) : (currentBranchQandC[0].props.datatype == LineTypes.V ? currentBranchQandC[0].key : undefined),
                        b_SrcEpisodeId: line.srcEpisodeId ?? currentBranchQandC[0].props["data-episodeid"],
                        b_EpisodeSerialNum: line.srcEpisodeSerialNumber ?? currentBranchQandC[0].props["data-episodeserialsumber"],
                        text: line.text ?? currentBranchQandC[0].props.children
                    }
                    var isChangedByAction = validateActionChange(dispatch, values, lines, lines[lines.length-1], true)
                    values = {...values, actionChangeFlag: isChangedByAction}
                    SaveBranchLine(values, index, linesFrame, selectedLineIndex, dispatch, alert, modalPopupBool.isOpen, modalPopupBool.type)
                }
            }}
            validationSchema={addLineSchema}>
            {({ dirty, isValid, handleSubmit }) => {
                return (
                    <div>
                    <CForm id="add-line-form-submit" className='row' onSubmit={(e: any) => { 
                            if(updateCloudBool === 'updating' || (!modalPopupBool.isOpen && selectedLineIndex !== -1 && inputLine)) {
                                e.preventDefault();
                                return;
                            }
                            e.preventDefault(); 
                            handleSubmit(); 
                        }}>
                        <CInputGroup className={inputLine ? 'col border-input-line' : 'col'} style={
                            { 
                                marginLeft: !inputLine ? `${line.depth * parseInt(editEpisodeReadOnlyCell.minWidth.split("px")[0])}px` : "" ,
                                border: (selectedLineIndex === index) ? `4px solid #bdbdbd` : "",
                                padding: "0",
                                paddingLeft: !inputLine ? `4px` : "0"
                            }}
                            onClick={() => { 
                                if(!inputLine && !modalPopupBool.isOpen)
                                    dispatch(ActionCreatorsForEditEpisode.setCurrentLine(
                                        {currentLine: line, currentLineIndex: index, linesStartIndex: index + 1 > linesFrame ? index + 1 - linesFrame : 0, linesSliceParam: index})) 
                            }}>
                            <Field
                                {...(!inputLine ? 
                                    {
                                        as:"span",
                                        title: showLineActions(line),
                                        style: { ...editEpisodeReadOnlyCell, backgroundColor: colorLineInsideAnswer(lines, index, pickedColorType, {...editEpisodeReadOnlyCell, backgroundColor: pickedColorType.colors.B}) },
                                        name: `lineNumber${isPreviewInModal ? inPreviewIndex : index}`,
                                    } 
                                    : 
                                    {
                                        as: undefined,
                                        name: "lineNumber",
                                        className: 'col-auto',
                                        style: { backgroundColor: "rgb(216, 219, 224)", maxWidth: "50px" }
                                    }
                                )}
                                type="text"
                                value='-'
                                disabled>
                            </Field>
                            <Field
                                {...(!inputLine ? 
                                    {
                                        as:"span",
                                        style: { ...editEpisodeReadOnlyCell, backgroundColor: colorLineInsideAnswer(lines, index, pickedColorType, {...editEpisodeReadOnlyCell, backgroundColor: pickedColorType.colors.B}) },
                                        name: `lineType${isPreviewInModal ? inPreviewIndex : index}`,
                                        className: "col-auto cursor"
                                    } 
                                    : 
                                    {
                                        as: "select",
                                        id: `lineType${index}`,
                                        name: `lineType`,
                                        className:"col-auto cursor",
                                        value:line.lineType,
                                        onChange:(event: any) => 
                                        {
                                            if(modalPopupBool.isOpen)
                                            {
                                                let currentLine = {...lines[index], lineType: parseInt(event.target.value)}
                                                dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine, isFirstTimeOpen: false  }))
                                            }
                                            else
                                            {
                                                dispatch(ActionCreatorsForEditEpisode.updateLineTypeParam(parseInt(event.target.value)))
                                            }
                                        }
                                    }
                                )}
                                {...((!modalPopupBool.isOpen) && (inputLine && selectedLineIndex!==-1) ? {style: { backgroundColor: "rgb(216, 219, 224)" }} : {} )}
                                type="text"
                                disabled={ (!modalPopupBool.isOpen) && (!inputLine || selectedLineIndex !== -1)}>
                                {inputLine ? currentLineTypes : ''}
                            </Field>
                            <Field
                                {...(!inputLine ? 
                                    {
                                        as:"span",
                                        style: editEpisodeReadOnlyCell,
                                        name: `branchQandCId${isPreviewInModal ? inPreviewIndex : index}`,
                                            className: "col-auto cursor b-line-span"
                                    } 
                                    : 
                                    {
                                        as: "select",
                                        id: `branchQandCId${index}`,
                                        name: `branchQandCId`,
                                        className:"col-auto cursor b-line-select",
                                        onChange:(event: any) => 
                                        {
                                            let type = parseInt(event.target[event.target.selectedIndex].attributes.datatype.value)
                                            let value = event.target[event.target.selectedIndex].attributes.value.value
                                            let text = event.target[event.target.selectedIndex].text
                                            let srcEpisodeId = event.target[event.target.selectedIndex].attributes["data-episodeid"].value
                                            let srcEpisodeSerialNumber = parseInt(event.target[event.target.selectedIndex].attributes["data-episodeserialsumber"].value)

                                            if(modalPopupBool.isOpen)
                                            {
                                                dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine: {...modalPopupBool.currentLine, branchType: type, branchValue: value, text, srcEpisodeId, srcEpisodeSerialNumber}, isFirstTimeOpen: false }))
                                            }
                                            else
                                            {
                                                dispatch(ActionCreatorsForEditEpisode.updateBranchQandCIdParam({type, value, text, srcEpisodeId, srcEpisodeSerialNumber}))
                                            }
                                        }
                                    }
                                )}
                                {...((!modalPopupBool.isOpen) && (inputLine && selectedLineIndex!==-1) ? {style: { backgroundColor: "rgb(216, 219, 224)" }} : {} )}
                                type="text"
                                disabled={ (!modalPopupBool.isOpen) && ( !inputLine || selectedLineIndex !== -1)}>
                                {inputLine ? currentBranchQandC : ''}
                            </Field>
                            <input disabled className={inputLine && selectedLineIndex !== -1 ? "col input-field-cinematic-gray" : "col"} style={(inputLine && selectedLineIndex === -1) || modalPopupBool.isOpen ? {} : editEpisodeReadOnlyCell}/>        
                            {line.comment && !inputLine? <span className="tooltip-comment-exist" style={{backgroundColor: colorLineWithComment(line)}}></span> : ''}
                        </CInputGroup >
                        {modalPopupBool.isOpen && inputLine && modalPopupBool.index === index ? <CButton className="ml-5" type="submit" color="primary" size='lg'>Add line</CButton> : '' }
                        
                        <LineSidebar  
                            dispatch={dispatch} 
                            lines={lines} 
                            line={line} 
                            selectedLineIndex={selectedLineIndex} 
                            inputLine={inputLine} 
                            isPreviewInModal={isPreviewInModal}
                            modalPopupBool={modalPopupBool}
                            setYesnomodal={setYesnomodal}
                            editEpisodePassParam={editEpisodePassParam}
                            index={index}
                            pickedActionColor={pickedActionColor}
                            isChangedByOptionFlag={isLineOptionChanged(line)}
                        />
                        
                    </CForm>
                    <DeleteMultipleLinesConfirmationModal modal={yesnomodal} setModal={setYesnomodal} methodForYes={() => DeleteLines(dispatch, alert, yesnomodal.array, index, linesFrame, setYesnomodal, yesnomodal.nextLineDocId) } title="Delete confirmation" message="Are you sure you want to delete selected line or line block?"/>
                </div>)
            }}
        </Formik>)
}