import React, { useEffect, useState } from 'react';
import { CButton } from '@coreui/react';
import Select from 'react-select';
import { GetSelectValuesForAssets, UpdateAssets } from '../../../services/ArtCatalog';
import { useDispatch } from 'react-redux';
import { ActionCreatorsForArtCatalog } from '../../../store/ArtCatalogReducer';
import { selectStylesForField } from '../Lines/_Helpers';
import { SaveChangesSessionLogger } from '../../../modules/firebaseDetailedLoggers';

export const HairstylePictureBody = ({hairstyleTypes, visible, setVisible, alert} : any) =>
{
    const dispatch = useDispatch();
    
    const [hairstyle, setHairstyle] = useState([]);
    
    useEffect(() => 
    {
        if(visible.object.hairstyleType)
        {
            GetSelectValuesForAssets('api/ArtCatalog/GetHairstyleAttributes?hairstyleType='+`${visible.object?.hairstyleType}`).then((res) => 
            { 
                setHairstyle(res);
            })
        }
    }, [visible.object.hairstyleType])

    return(
    <div>
        <label>Type</label>
        <Select
            styles={selectStylesForField}
            menuPortalTarget={document.querySelector('body')}
            options={hairstyleTypes} 
            className="art-cat-filters-row"
            value={hairstyleTypes.find((element : any) => element.value === visible.object.hairstyleType)}
            onChange={(value : any) => 
            { 
                setVisible({...visible, object: {...visible.object, hairstyleType: value.value }})
            }}
        />
        {visible.object?.hairstyleType == "Costume" ? 
        <div>
            <label className="mt-3">Costume Tags</label>
            <Select 
                styles={selectStylesForField}
                menuPortalTarget={document.querySelector('body')}
                isMulti
                options={hairstyle}
                value={visible.object.hairstyleCostumeAttributesTags?.map((el : any, index : any) => { return { id: index, value: index, label: el }})}
                onChange={(value : any) => 
                { 
                    setVisible({...visible, object: {...visible.object, hairstyleCostumeAttributesTags: value?.map((el : any) => el.label) }})
                }}
            />
        </div> :
        <div>
            <label className="mt-3">Natural Tags</label>
            <Select
                styles={selectStylesForField}
                menuPortalTarget={document.querySelector('body')}
                isMulti
                options={hairstyle} 
                value={visible.object.hairstyleNaturalAttributesTags?.map((el : any, index : any) => { return { id: index, value: index, label: el }})}
                onChange={(value : any) => 
                { 
                    setVisible({...visible, object: {...visible.object, hairstyleNaturalAttributesTags: value?.map((el : any) => el.label) }})
                }}
            />
        </div>}
        <CButton 
            color="primary" 
            className="mt-5" 
            onClick={() => 
            {
                const saveChangesSession = new SaveChangesSessionLogger();
                                
                saveChangesSession.asset_name = 'Hairstyle'
                saveChangesSession.timestamp = new Date().toUTCString();
                saveChangesSession.session_id = localStorage.getItem('sessionId') ?? '';
                saveChangesSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                saveChangesSession.user_id = localStorage.getItem('uid') ?? '';
                
                saveChangesSession.logEvent();
                
                UpdateAssets('api/ArtCatalog/Update_HairstyleAssetTags', 
                    { 
                        docId: visible.object?.docId, 
                        hairstyleType: visible.object?.hairstyleType,
                        hairstyleAttributes: visible.object?.hairstyleType == "Natural" ? visible.object?.hairstyleNaturalAttributesTags : visible.object?.hairstyleCostumeAttributesTags
                    }, alert)
                
                dispatch(ActionCreatorsForArtCatalog.updateHairstyles(
                    {
                        id: visible.object?.docId, 
                        hairstyleType: visible.object?.hairstyleType,
                        hairstyleNaturalAttributesTags: visible.object?.hairstyleNaturalAttributesTags, 
                        hairstyleCostumeAttributesTags: visible.object?.hairstyleCostumeAttributesTags,
                    }))
            }}
        >Save Changes</CButton>
    </div>
    )
}