import React, { useEffect, useState } from 'react';
import { CButton } from '@coreui/react';
import Select from 'react-select';
import { GetSelectValuesForAssets, UpdateAssets } from '../../../services/ArtCatalog';
import { useDispatch } from 'react-redux';
import { ActionCreatorsForArtCatalog } from '../../../store/ArtCatalogReducer';
import { selectStylesForField } from '../Lines/_Helpers';
import { SaveChangesSessionLogger } from '../../../modules/firebaseDetailedLoggers';

export const CharactersPictureBody = ({characterTypes, visible, setVisible, alert} : any) =>
{
    const dispatch = useDispatch();
    
    const [bodyTypesCharacters, setBodyTypesCharacters] = useState([]);
    const [skinColorCharacters, setSkinColorCharacters] = useState([]);

    useEffect(() => 
    {
        GetSelectValuesForAssets('api/ArtCatalog/GetBodyTypes').then((response) => 
        { 
            setBodyTypesCharacters(response)
        })
        GetSelectValuesForAssets('api/ArtCatalog/GetSkinColors').then((response) => 
        { 
            setSkinColorCharacters(response);
        })
    }, [])

    return(
        <div>
            <label>Type</label>
            <Select
                options={characterTypes} 
                styles={selectStylesForField}
                menuPortalTarget={document.querySelector('body')}
                className="art-cat-filters-row"
                value={characterTypes.find((element : any) => element.value === visible.object.assetType)}
                onChange={(value : any) => 
                { 
                    setVisible({...visible, object: {...visible.object, assetType: value.value }})
                }}
            />
            <label className="mt-3">Body Type</label>
            <Select
                options={bodyTypesCharacters} 
                styles={selectStylesForField}
                menuPortalTarget={document.querySelector('body')}
                className="art-cat-filters-row"
                value={bodyTypesCharacters.find((element : any) => element.value === visible.object.characterBodyType)}
                onChange={(value : any) => 
                { 
                    setVisible({...visible, object: {...visible.object, characterBodyType: value.value }})
                }}
            />
            <label className="mt-3">Skin Color</label>
            <Select
                options={skinColorCharacters} 
                styles={selectStylesForField}
                menuPortalTarget={document.querySelector('body')}
                value={skinColorCharacters.find((element : any) => element.value === visible.object.characterSkinColor)}
                onChange={(value : any) => 
                { 
                    setVisible({...visible, object: {...visible.object, characterSkinColor: value.value }})
                }}
            />
            <CButton 
                color="primary" 
                className="mt-5" 
                onClick={() => 
                {
                    const saveChangesSession = new SaveChangesSessionLogger();
                                    
                    saveChangesSession.asset_name = 'Character'
                    saveChangesSession.timestamp = new Date().toUTCString();
                    saveChangesSession.session_id = localStorage.getItem('sessionId') ?? '';
                    saveChangesSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                    saveChangesSession.user_id = localStorage.getItem('uid') ?? '';
                    
                    saveChangesSession.logEvent();
                    
                    UpdateAssets('api/ArtCatalog/Update_CharacterAssetTags', 
                        { 
                            docId: visible.object?.docId, 
                            bodyType: parseInt(visible.object?.characterBodyType),
                            skinColor: parseInt(visible.object?.characterSkinColor)
                        }, alert)
                        
                    dispatch(ActionCreatorsForArtCatalog.updateCharacters(
                        {
                            id: visible.object?.docId, 
                            bodyType: parseInt(visible.object?.characterBodyType),
                            skinColor: parseInt(visible.object?.characterSkinColor)
                        }))
                }}
            >Save Changes</CButton>
        </div> 
    )
}