import { CForm, CInputGroup } from '@coreui/react';
import React, { useEffect } from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorsForTranslations } from '../../../../../store/TranslationsReducer';
import { editEpisodeReadOnlyCell } from '../../../Lines/_Helpers';
import { calculateMovementOnClickLine } from '../_TranslationsHelpers';

export const EndOfLevelTranslationLine = ({ line, index, inputLine } : any) =>
{
    const { lineTypes, selectedLineIndex, serbianTranslate, englishTranslate, otherLanguageTranslate, linesFrame, defaultLine, isPreview } = useSelector((state: any) => state.translationsReducer);
    const dispatch = useDispatch();

    useEffect(() =>
    {
        let lineNumber = document.querySelector(`span[id="lineNumber${index}"]`);
        if(lineNumber) lineNumber.innerHTML = line.lineNumber;
        let lineType = document.querySelector(`span[id="lineType${index}"]`);
        if(lineType) { 
            let types = lineTypes?.filter((x:any) => x.id == line.lineType);
            lineType.innerHTML = types.length > 0 ? types[0].label : '';
        }
        let e_Name = document.querySelector(`span[id="e_Name${index}"]`);
        if(e_Name) e_Name.innerHTML = line.e_Name ?? '';
    }, [line, lineTypes])

    return(
    <div>
        <CForm className={inputLine ? 'is-input-translations row' : 'row'}>
            <CInputGroup className='col' style={{marginLeft: !inputLine ? `${line.depth * parseInt(editEpisodeReadOnlyCell.minWidth.split("px")[0])}px` : "" , }}
            onClick={() =>
                {
                    if(!inputLine && !isPreview)
                    {
                        let movement = calculateMovementOnClickLine(index, selectedLineIndex, linesFrame, serbianTranslate, englishTranslate)

                        dispatch(ActionCreatorsForTranslations.setCurrentLineForTranslations(
                        {
                            currentLineSerbian: serbianTranslate && serbianTranslate?.length != 0 && !serbianTranslate[0]?.isDefault ? serbianTranslate[movement] : defaultLine,
                            currentLineEnglish: englishTranslate && englishTranslate?.length != 0 && !englishTranslate[0]?.isDefault ? englishTranslate[movement] : defaultLine,
                            currentLineOther: otherLanguageTranslate && otherLanguageTranslate?.length != 0 && !otherLanguageTranslate[0]?.isDefault ? otherLanguageTranslate[movement] : defaultLine,
                            currentLineIndex: movement, 
                            linesStartIndex: movement + 1 > linesFrame ? movement + 1 - linesFrame : 0
                        }))
                    }
                }}
            >
                <span style={editEpisodeReadOnlyCell} id={`lineNumber${index}`}></span>
                <span style={editEpisodeReadOnlyCell} id={`lineType${index}`}></span>
                <span style={editEpisodeReadOnlyCell} id={`e_Name${index}`}></span>
                <input style={{flexGrow: 1}} disabled></input>
            </CInputGroup>
        </CForm>
    </div>)
}

export default EndOfLevelTranslationLine;