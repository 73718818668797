import React, { useEffect, useState } from 'react';
import { CInputGroup, CForm, CButton } from '@coreui/react';
import { Field, Formik } from 'formik';
import { SaveCustomizationLine } from '../../../services/EpisodeServices';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorsForEditEpisode } from '../../../store/EditEpisodeReducer';
import { LineType } from '../../../interfaces/lineType';
import { createDefaultLineTypeOptions, editEpisodeReadOnlyCellForCustomizationSpan, FormatObjectForDiamond, editEpisodeReadOnlyCell, FormatObject, getInputLineNumber, validateActionChange, colorLineInsideAnswer, showLineActions, getClassesForCustomizationDisable, colorLineWithComment, isLineOptionChanged } from './_Helpers';
import { useAlert } from 'react-alert';
import { ActionCreators } from '../../../store/StoriesReducer';
import CreatableSelect from 'react-select/creatable';
import { DeleteLines } from '../../../services/LineServices';
import { GiCutDiamond } from 'react-icons/gi';
import { DeleteMultipleLinesConfirmationModal } from '../../Helpers/YesNoModal';
import '../../../styles/Lines.css'
import { LineTypes } from '../Enums/LineTypes';
import LineSidebar from '../LineSidebar';

const stateWithClearedLineC1 = (inputLine: boolean, selectedLineIndex: number, lines: LineType[], line: LineType, index: number, isRenderedByAction: boolean, isModalOpen: boolean, maxLineNumber: number, modalClickedLine: LineType, modalType : string) => 
{
    return (inputLine && selectedLineIndex === -1) || isModalOpen ? 
    { 
        docId: line.docId,
        storyId: line.storyId,
        storyTranslationId: line.storyTranslationId,
        episodeId: line.episodeId,
        lineNumber: getInputLineNumber(lines,line, index,maxLineNumber,isModalOpen, modalType),
        depth: isModalOpen && modalType == 'above' ? line.depth : isModalOpen ? (line.depth ?? 0) + ([LineTypes.A, LineTypes.C2, LineTypes.BA, LineTypes.TA, LineTypes.VA].includes(modalClickedLine.lineType ?? 0) ? 1 : 0) : 0,
        lineType: line.lineType,
        lineFormat: line.lineFormat,
        episodeName: line.episodeName,
        previousLineDocId: line.previousLineDocId,
        episodeSerialNumber: line.episodeSerialNumber,

        characterId: line.characterId,
        characterName: line.characterName,
        characterPath: line.characterPath,
        emotion: line.emotion,
        text: isRenderedByAction ? line.text : undefined,

        animationId: undefined,
        animationName: undefined,
        payed: undefined,
        answerNumber: undefined,
        questionDocId: undefined,
    
        comment: undefined,
        commentReporterName: undefined,
        commentReporterUID: undefined,
        commentAssignedToName: undefined,
        commentAssignedToUID: undefined,
        commentResolved: undefined,

        musicId: line.musicId,
        musicName: line.musicName,
        musicDescription: line.musicDescription,

        shortSoundEnabled: line.shortSoundEnabled,
        shortSoundId: line.shortSoundId,
        shortSoundName: line.shortSoundName,
        shortSoundDescription: line.shortSoundDescription,

        itemId: line.itemId ?? undefined,
        itemName: line.itemName ?? undefined,
        itemDescription: line.itemDescription ?? undefined,

        avatarEnabled: line.avatarEnabled,

        changeIdentityOn: line.changeIdentityOn,
        changeIdentityName: line.changeIdentityName,

        callPhoneEnabled: line.callPhoneEnabled != null ? line.callPhoneEnabled : undefined,
        callSmsEnabled: line.callSmsEnabled != null ? line.callSmsEnabled : undefined,
        callerCharacterId: line.callerCharacterId,
        callerCharacterName: line.callerCharacterName,
        charmingPoint: line.charmingPoint != null ? line.charmingPoint : undefined,
        premiumChoiceText: line.premiumChoiceText != null ? line.premiumChoiceText : undefined,
        premiumChoiceDescription: line.premiumChoiceDescription != null ? line.premiumChoiceDescription : undefined,
        charactersOutfit: line.charactersOutfit,

        backgroundId: line.backgroundId,
        backgroundName: line.backgroundName,
        backgroundDescription: line.backgroundDescription,
    
        clothes1Name: line.clothes1Name,
        clothes2Name: line.clothes2Name,
        clothes3Name: line.clothes3Name,
        clothes4Name: undefined,
        clothes5Name: undefined,
        clothes6Name: undefined,
        hairstyle1Name: undefined,
        hairstyle2Name: undefined,
        hairstyle3Name: undefined,
    
        clothes1Id: line.clothes1Id,
        clothes2Id: line.clothes2Id,
        clothes3Id: line.clothes3Id,

        clothes1Payed: undefined,
        clothes2Payed: undefined,
        clothes3Payed: undefined,
        clothes4Id: undefined,
        clothes5Id: undefined,
        clothes6Id: undefined,
        hairstyle1Id: undefined,
        hairstyle2Id: undefined,
        hairstyle3Id: undefined
    } 
    : 
    line
}

export const CustomizationLine = ({ line, inputLine, animations, index, episodeName, serialNumber, storyId, isPreviewInModal, inPreviewIndex  }: any) => 
{
    const dispatch = useDispatch();
    const alert = useAlert(); 
    const { selectedLineIndex, linesFrame, initEditEpisodeBody, lines, linesStartIndex, 
        defaultLineTypes, defaultBranchQandC, isRenderedByAction, pickedActionColor, updateCloudBool, pickedColorType, isOLineAdded, maxLineNumber, editEpisodePassParam } = useSelector((state: any) => state.editEpisodeReducer);
    const { modalPopupBool } = useSelector((state: any) => state.storiesReducer);

    const [yesnomodal, setYesnomodal] = useState({boolean: false, array: [], nextLineDocId: null});

    const [clearedLine, clearLine] = useState(stateWithClearedLineC1(inputLine, selectedLineIndex,lines, line,index, isRenderedByAction, modalPopupBool.isOpen, maxLineNumber, modalPopupBool.clickedLine, modalPopupBool.type));

    let currentLineTypes = createDefaultLineTypeOptions(lines, index, defaultLineTypes, defaultBranchQandC, isOLineAdded, modalPopupBool.isOpen, modalPopupBool.type);
    
    let addLineSchema = yup.object().shape(
    {
        //lineNumber: yup.string().required("Required."),
        //lineType: yup.string().required("Required."),
        //cclothes1Name: yup.string().required("Required."),
        //cclothes2Name: yup.string().required("Required."),

        //cclothes3Name: yup.string().required("Required."),
    });

    const setupSpanElements = () => {
        let lineNumber = document.querySelector(`span[name="lineNumber${isPreviewInModal ? inPreviewIndex : index}"]`);
        if(lineNumber) lineNumber.innerHTML = line.lineNumber;
        let lineType = document.querySelector(`span[name="lineType${isPreviewInModal ? inPreviewIndex : index}"]`);
        if(lineType) 
        { 
            let types = defaultLineTypes?.filter((x:any) => x.id == line.lineType);
            lineType.innerHTML = types.length > 0 ? types[0].label : '';
        }
        let clothes = document.querySelector(`span[name="clothes"]`)
        if(clothes) clothes.innerHTML = "Clothes"

        let clothes1Name = document.querySelector(`span[name="cclothes1Name${isPreviewInModal ? inPreviewIndex : index}"]`)
        if(clothes1Name) clothes1Name.innerHTML = line.clothes1Name;
        
        let clothes2Name = document.querySelector(`span[name="cclothes2Name${isPreviewInModal ? inPreviewIndex : index}"]`)
        if(clothes2Name) clothes2Name.innerHTML = line.clothes2Name;
        
        let clothes3Name = document.querySelector(`span[name="cclothes3Name${isPreviewInModal ? inPreviewIndex : index}"]`)
        if(clothes3Name) clothes3Name.innerHTML = line.clothes3Name;
    }

    useEffect(() => 
    { 
        if(inputLine && !initEditEpisodeBody && !isRenderedByAction) { 
            let episodeWrapper = document.querySelector('.editEpisodeWrapper');
            if(episodeWrapper) {
                //episodeWrapper.scrollIntoView({block: 'end', behavior: 'smooth'}); 
                let inputText = document.querySelector('input[name="text"]');
                (inputText as HTMLInputElement)?.focus(); 
            }
        }
        if(!inputLine) 
        {
            setupSpanElements()
        }             
    }, [line])
    
    useEffect(() => 
    {
        clearLine(stateWithClearedLineC1(inputLine, selectedLineIndex, lines, line, index, isRenderedByAction, modalPopupBool.isOpen, maxLineNumber, modalPopupBool.clickedLine, modalPopupBool.type))
    }, [line,maxLineNumber])
        
    return (
        <Formik validateOnChange={true} validateOnBlur={false} enableReinitialize initialValues={{ lineType: line.lineType, clothes: "Clothes" }}
            onSubmit={(values : LineType) => 
            {
                if(line.changeIdentityOn && !line.changeIdentityName)
                {
                    alert.info('You must fill in the character name in Change Identity, because you have enabled it.')
                }
                else if(line.clothes1Id != null && line.clothes2Id != null && line.clothes3Id != null)
                {
                    dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updating'));
                    let previousLine = lines[
                        index != null && modalPopupBool.isOpen && modalPopupBool.type == 'above' ? index - 1 
                        : index != null ? index : lines.length - 1];
                    //citamo actions iz reducera, smestamo u objekat i radimo new line

                    let previousLineActions;
                    if(previousLine && modalPopupBool.isOpen && modalPopupBool.type == 'above')
                    {
                        previousLineActions = {
                            backgroundId: previousLine.backgroundId,
                            backgroundPath: previousLine.backgroundPath,
                            backgroundName: previousLine.backgroundName,
                            backgroundDescription: previousLine.backgroundDescription,
                            musicId: previousLine.musicId,
                            musicPath: previousLine.musicPath,
                            musicName: previousLine.musicName,
                            musicDescription: previousLine.musicDescription,
                            shortSoundId: previousLine.shortSoundId,
                            shortSoundPath: previousLine.shortSoundPath,
                            shortSoundName: previousLine.shortSoundName,
                            shortSoundDescription: previousLine.shortSoundDescription,
                            shortSoundEnabled: previousLine.shortSoundEnabled,
                            itemId: previousLine.itemId,
                            itemName: previousLine.itemName,
                            itemDescription: previousLine.itemDescription,
                            avatarEnabled: previousLine.avatarEnabled,
                            callPhoneEnabled: previousLine.callPhoneEnabled,
                            callSmsEnabled: previousLine.callSmsEnabled,
                            callerCharacterId: previousLine.callerCharacterId,
                            callerCharacterName: previousLine.callerCharacterName,
                            actionChangeFlag: previousLine.actionChangeFlag,
                            charmingPoint: previousLine.charmingPoint,
                            premiumChoiceText: previousLine.premiumChoiceText,
                            premiumChoiceDescription: previousLine.premiumChoiceDescription,
                            charactersOutfit: previousLine.charactersOutfit
                        }
                    }

                    values = {
                        ...values,
                        ...clearedLine,
                        ...previousLineActions,
                        clothes1Name: line.clothes1Name,
                        clothes2Name: line.clothes2Name,
                        clothes3Name: line.clothes3Name,
                        
                        clothes1Id: line.clothes1Id,
                        clothes2Id: line.clothes2Id,
                        clothes3Id: line.clothes3Id,

                        clothes1Payed: false,
                        clothes2Payed: false,
                        clothes3Payed: false,

                        previousLineDocId: previousLine?.docId,
                        episodeName: episodeName,
                        episodeSerialNumber: serialNumber
                    }
                    
                    var isChangedByAction = validateActionChange(dispatch, values, lines, lines[lines.length-1], true)
                    values = {...values, actionChangeFlag: isChangedByAction}

                    SaveCustomizationLine(values, index, linesFrame, selectedLineIndex, dispatch, alert, modalPopupBool.isOpen, modalPopupBool.type);
                }
                else
                {
                    alert.error('You must select or create all 3 clothes before trying to add new line.');
                }
            }}
            validationSchema={addLineSchema}>
            {({ dirty, isValid, handleSubmit }) => {
                return (
                <div>
                    <CForm id="add-line-form-submit" className='row' onSubmit={(e : any) => 
                    { 
                        if(updateCloudBool === 'updating' || (!modalPopupBool.isOpen && selectedLineIndex !== -1 && inputLine)) 
                        {
                            e.preventDefault();
                            return;
                        } 
                        e.preventDefault(); handleSubmit(); 
                    }} disabled={!inputLine}>
                    <CInputGroup className={inputLine ? 'col border-input-line' : 'col'} style={
                        { 
                            marginLeft: !inputLine ? `${line.depth * parseInt(editEpisodeReadOnlyCell.minWidth.split("px")[0])}px` : "" ,
                            border: (selectedLineIndex === index) ? `4px solid #bdbdbd` : "",
                            padding: "0",
                            paddingLeft: !inputLine ? `4px` : "0"
                        }}
                        onClick={(event: any) => {
                            if (!inputLine && !modalPopupBool.isOpen)
                                dispatch(ActionCreatorsForEditEpisode.setCurrentLine({currentLine: line, currentLineIndex: index, linesStartIndex: index + 1 > linesFrame ? index + 1 - linesFrame : 0, linesSliceParam: index}))
                        }}>
                            <Field
                                    {...(!inputLine ? 
                                        {
                                            as:"span",
                                            title: showLineActions(line),
                                            style: {...editEpisodeReadOnlyCell, backgroundColor: colorLineInsideAnswer(lines, index, pickedColorType, editEpisodeReadOnlyCell)},
                                            name: `lineNumber${isPreviewInModal ? inPreviewIndex : index}`,
                                        } 
                                        : 
                                        {
                                            as: undefined,
                                            name: "lineNumber",
                                            className: 'col-auto',
                                            style: { backgroundColor: "rgb(216, 219, 224)", maxWidth: "50px" }
                                        }
                                    )}
                                    type="text"
                                    value={(inputLine && selectedLineIndex === -1) || modalPopupBool.isOpen ? getInputLineNumber(lines, line, index, maxLineNumber,modalPopupBool.isOpen, modalPopupBool.type) : line.lineNumber}
                                    disabled>
                            </Field>
                            <Field
                                {...(!inputLine ? 
                                {
                                    as:"span",
                                    style: {...editEpisodeReadOnlyCell, backgroundColor: colorLineInsideAnswer(lines, index, pickedColorType, editEpisodeReadOnlyCell)},
                                    name: `lineType${isPreviewInModal ? inPreviewIndex : index}`,
                                    className: "col-auto cursor"
                                } 
                                : 
                                {
                                    as: "select",
                                    id: `lineType${index}`,
                                    name: `lineType`,
                                    className:"col-auto cursor",
                                    value:line.lineType,
                                    onChange:(event: any) => 
                                    {
                                        if(modalPopupBool.isOpen)
                                        {
                                            dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine: {...lines[index], lineType: parseInt(event.target.value)}, isFirstTimeOpen: false }))
                                        }
                                        else
                                        {
                                            dispatch(ActionCreatorsForEditEpisode.updateLineTypeParam(parseInt(event.target.value)))
                                        }
                                    }
                                }
                            )}
                                {...((!modalPopupBool.isOpen) && (inputLine && selectedLineIndex!==-1) ? {style: { backgroundColor: "rgb(216, 219, 224)" }} : {} )}
                                type="text"
                                disabled={ (!modalPopupBool.isOpen) && ( !inputLine || selectedLineIndex !== -1)}>
                                {inputLine ? currentLineTypes : ''}
                            </Field>
                            <Field
                                name="clothes"
                                className="col-auto border-fix-input"
                                disabled
                                type="text">
                            </Field>
                            <Field
                                {...(!inputLine ? 
                                        {
                                            as:"span",
                                            style: {...editEpisodeReadOnlyCell, borderRight: 0},
                                            className: "col c-line-clh cursor",
                                            name: `cclothes1Name${isPreviewInModal ? inPreviewIndex : index}`,
                                        } 
                                        : 
                                        {
                                            as: CreatableSelect,
                                            className:"col-sm-2 cursor p-0 border-fix-input",
                                            name: "cclothes1Name",
                                            options: [],
                                            noOptionsMessage: () => { return 'Create new clothes' },
                                            isClearable: true,
                                            backspaceRemovesValue: true,
                                            isOptionDisabled: (option : any) => { if(option.isDisabled){ return true } else { return false }},
                                            //value: line.clothes1Id != null ? { id: line.clothes1Id, value: line.clothes1Name, label: line.clothes1Name} : clothes[0],
                                            value: { id: line.clothes1Id, value: line.clothes1Name, label: line.clothes1Name},
                                            onChange:(value: any) => 
                                            {
                                                if(updateCloudBool !== 'updating')
                                                {
                                                    if (value !== null) 
                                                    { 
                                                        if (value.__isNew__ !== undefined) 
                                                        { 
                                                            if(modalPopupBool.isOpen)
                                                            {
                                                                dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine: {...modalPopupBool.currentLine, clothes1Id: -1, clothes1Name: value.label}, isFirstTimeOpen: false }))
                                                            }
                                                            else 
                                                            {
                                                                if(selectedLineIndex != -1)
                                                                {
                                                                    FormatObject(
                                                                        dispatch, 
                                                                        selectedLineIndex, 
                                                                        linesStartIndex,
                                                                        lines, 
                                                                        { lineToUpdateId: line.docId, lineModel: {...line, clothes1Id: -1, clothes1Name: value.label}}, alert)
                                                                }
                                                                dispatch(ActionCreatorsForEditEpisode.updateClothes1Param({ ...value, id: -1 }))
                                                            }
                                                        }
                                                    }
                                                    else
                                                    {
                                                        dispatch(ActionCreatorsForEditEpisode.updateClothes1Param({ id: null, value: null, label: ''}))
                                                    }
                                                }
                                            }}
                                    )
                                }
                                autoComplete="off"
                                type="text"
                                disabled={!inputLine}
                            >
                            </Field>
                            {line.comment && !inputLine? <span className="tooltip-comment-exist" style={{backgroundColor: colorLineWithComment(line)}}></span> : ''}
                            { !inputLine ? <span style={{...editEpisodeReadOnlyCellForCustomizationSpan, borderLeft: 0}}>
                                <GiCutDiamond 
                                    color={line.clothes1Payed ? "blue" : "gray"} 
                                    size={21} 
                                    className={getClassesForCustomizationDisable(editEpisodePassParam.episodeStatusEnum)}
                                    onClick={(e : any) => 
                                        {   
                                            e.stopPropagation();
                                            FormatObjectForDiamond(
                                                dispatch, 
                                                index, 
                                                linesStartIndex,
                                                lines, 
                                                { lineToUpdateId: line.docId, lineModel: {...line, clothes1Payed: !line.clothes1Payed} }, alert)
                                        }}
                                /></span> : ''}
                            <Field
                                {...(!inputLine ? 
                                        {
                                            as:"span",
                                            style: {...editEpisodeReadOnlyCell, borderRight: 0},
                                            className: "col c-line-clh cursor",
                                            name: `cclothes2Name${isPreviewInModal ? inPreviewIndex : index}`,
                                        } 
                                        : 
                                        {
                                            as: CreatableSelect,
                                            className:"col-sm-2 cursor p-0 border-fix-input",
                                            name: "cclothes2Name",
                                            options: [],
                                            noOptionsMessage: () => { return 'Create new clothes' },
                                            isClearable: true,
                                            backspaceRemovesValue: true,
                                            //value: line.clothes2Id != null ? { id: line.clothes2Id, value: line.clothes2Name, label: line.clothes2Name} : clothes[1],
                                            value: { id: line.clothes2Id, value: line.clothes2Name, label: line.clothes2Name},
                                            onChange:(value: any) => 
                                            {
                                                if(updateCloudBool !== 'updating')
                                                {
                                                    if (value !== null) 
                                                    { 
                                                        if (value.__isNew__ !== undefined) 
                                                        { 
                                                            if(modalPopupBool.isOpen)
                                                            {
                                                                dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine: {...modalPopupBool.currentLine, clothes2Id: -1, clothes2Name: value.label}, isFirstTimeOpen: false }))
                                                            }
                                                            else 
                                                            {
                                                                if(selectedLineIndex != -1)
                                                                {
                                                                    FormatObject(
                                                                        dispatch, 
                                                                        selectedLineIndex, 
                                                                        linesStartIndex,
                                                                        lines, 
                                                                        { lineToUpdateId: line.docId, lineModel: {...line, clothes2Id: -1, clothes2Name: value.label}}, alert)
                                                                }
                                                                dispatch(ActionCreatorsForEditEpisode.updateClothes2Param({ ...value, id: -1 }))
                                                            }
                                                        }
                                                    }
                                                    else
                                                    {
                                                        dispatch(ActionCreatorsForEditEpisode.updateClothes2Param({ id: null, value: null, label: ''}))
                                                    }
                                                }
                                            }}
                                    )
                                }
                                autoComplete="off"
                                type="text"
                                disabled={!inputLine}
                            >
                            </Field>
                            { !inputLine ? <span style={{...editEpisodeReadOnlyCellForCustomizationSpan, borderLeft: 0}}>
                                <GiCutDiamond 
                                    color={line.clothes2Payed ? "blue" : "gray"} 
                                    size={21} 
                                    className={getClassesForCustomizationDisable(editEpisodePassParam.episodeStatusEnum)}
                                    onClick={(e : any) => 
                                        {      
                                            e.stopPropagation();
                                            FormatObjectForDiamond(
                                                dispatch, 
                                                index, 
                                                linesStartIndex,
                                                lines, 
                                                { lineToUpdateId: line.docId, lineModel: {...line, clothes2Payed: !line.clothes2Payed} }, alert)
                                        }}
                                    /></span> : ''}
                            <Field
                                {...(!inputLine ? 
                                        {
                                            as:"span",
                                            style: {...editEpisodeReadOnlyCell, borderRight: 0},
                                            className: "col c-line-clh cursor",
                                            name: `cclothes3Name${isPreviewInModal ? inPreviewIndex : index}`,
                                        } 
                                        : 
                                        {
                                            as: CreatableSelect,
                                            className:"col-sm-2 cursor p-0 border-fix-input",
                                            name: "cclothes3Name",
                                            options: [],
                                            noOptionsMessage: () => { return 'Create new clothes' },
                                            isClearable: true,
                                            backspaceRemovesValue: true,
                                            //value: line.clothes3Id != null ? { id: line.clothes3Id, value: line.clothes3Name, label: line.clothes3Name} : clothes[2],
                                            value: { id: line.clothes3Id, value: line.clothes3Name, label: line.clothes3Name},
                                            onChange:(value: any) => 
                                            {
                                                if(updateCloudBool !== 'updating')
                                                {
                                                    if (value !== null) 
                                                    { 
                                                        if (value.__isNew__ !== undefined) 
                                                        { 
                                                            if(modalPopupBool.isOpen)
                                                            {
                                                                dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine: {...modalPopupBool.currentLine, clothes3Id: -1, clothes3Name: value.label}, isFirstTimeOpen: false }))
                                                            }
                                                            else 
                                                            {
                                                                if(selectedLineIndex != -1)
                                                                {
                                                                    FormatObject(
                                                                        dispatch, 
                                                                        selectedLineIndex, 
                                                                        linesStartIndex,
                                                                        lines, 
                                                                        { lineToUpdateId: line.docId, lineModel: {...line, clothes3Id: -1, clothes3Name: value.label}}, alert)
                                                                }
                                                                dispatch(ActionCreatorsForEditEpisode.updateClothes3Param({ ...value, id: -1 }))
                                                            }
                                                        }
                                                    }
                                                    else
                                                    {
                                                        dispatch(ActionCreatorsForEditEpisode.updateClothes3Param({ id: null, value: null, label: ''}))
                                                    }
                                                }
                                            }}
                                    )
                                }
                                autoComplete="off"
                                type="text"
                                disabled={!inputLine}
                            >
                            </Field>
                            { !inputLine ? <span style={{...editEpisodeReadOnlyCellForCustomizationSpan, borderLeft: 0}}>
                                <GiCutDiamond 
                                    color={line.clothes3Payed ? "blue" : "gray"} 
                                    size={21} 
                                    className={getClassesForCustomizationDisable(editEpisodePassParam.episodeStatusEnum)}
                                    onClick={(e : any) => 
                                        {    
                                            e.stopPropagation();
                                            FormatObjectForDiamond(
                                                dispatch, 
                                                index, 
                                                linesStartIndex,
                                                lines, 
                                                { lineToUpdateId: line.docId, lineModel: {...line, clothes3Payed: !line.clothes3Payed} }, alert)
                                        }}/></span> : ''}
                            <input disabled className={inputLine ? "col input-field-cinematic-gray" : "col"} 
                            style={inputLine ? {} : editEpisodeReadOnlyCell}/>
                        </CInputGroup >
                        {modalPopupBool.isOpen && inputLine && modalPopupBool.index === index ? <CButton className="ml-5" type="submit" color="primary" size='lg'>Add line</CButton> : '' }
                        
                        <LineSidebar  
                            dispatch={dispatch} 
                            lines={lines} 
                            line={line} 
                            selectedLineIndex={selectedLineIndex} 
                            inputLine={inputLine} 
                            isPreviewInModal={isPreviewInModal}
                            modalPopupBool={modalPopupBool}
                            setYesnomodal={setYesnomodal}
                            editEpisodePassParam={editEpisodePassParam}
                            index={index}
                            pickedActionColor={pickedActionColor}
                            isChangedByOptionFlag={isLineOptionChanged(line)}
                        />
                        
                    </CForm>
                    <DeleteMultipleLinesConfirmationModal modal={yesnomodal} setModal={setYesnomodal} methodForYes={() => DeleteLines(dispatch, alert, yesnomodal.array, index, linesFrame, setYesnomodal, yesnomodal.nextLineDocId) } title="Delete confirmation" message="Are you sure you want to delete selected line block?"/>
                </div>)
            }}
        </Formik>)
}