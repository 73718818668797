import * as React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useDispatch } from 'react-redux';
//project imports
import './custom.css'

import LandingPage from '../src/components/LandingPage';
import Stories from '../src/components/Models/Stories';
import Translations from '../src/components/Models/Translations';
import ArtCatalog from '../src/components/Models/ArtCatalog';
import Analytics from '../src/components/Models/Analytics';
import GettingStarted from '../src/components/Guide/TranslatorsGuide';
import TranslatorsGuide from '../src/components/Guide/TranslatorsGuide';
import WritersGuide from '../src/components/Guide/WritersGuide';
import ArtistsGuide from '../src/components/Guide/ArtistsGuide';
import MainLayout from '../src/components/Layout/MainLayout';
import SetupStory from './components/Models/SetupStory';
import EditEpisode from './components/Models/EditEpisode';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import TranslationTranslate from './components/Models/TranslationTranslate';
import TranslationsBody from './components/Models/TranslationsBody';
import UserManagement from './components/Models/UserManagement';
import { GetCurrentSessionId, GetLogsConfig } from './services/CommonServices';
import TranslationsPreviewLines from './components/Models/TranslationsPreviewLines';

export const App = () =>
{
    const history = createBrowserHistory();
    
    GetLogsConfig().then((response)=> 
    { 
        localStorage.setItem('eventSuffix', response.suffix)
        localStorage.setItem('eventEnabled', response.auditLogConfig.enabled)
        localStorage.setItem('eventSessionDuration', response.auditLogConfig.sessionDurationInMinutes)
    })

    localStorage.setItem('sessionNumber', '0')
    GetCurrentSessionId().then((response : any) => localStorage.setItem('sessionId', response))

    history.listen(() => {
        if(!localStorage.getItem('sessionId'))
        {
            GetCurrentSessionId().then((response : any) =>
            {
                localStorage.setItem('sessionId', response);
            })
        }
        var number = parseInt(localStorage.getItem('sessionNumber') ?? '-1');
        localStorage.setItem('sessionNumber', (number + 1).toString());
    })

    return(
    <Router history={history}>
        <Switch>
            <Route exact path='/'>
                <LandingPage/>
            </Route>
            <Route>
              <MainLayout>
                  <Switch>
                    <ProtectedRoute path="/stories" exact component={Stories}/>
                    <ProtectedRoute path="/translations" exact component={Translations}/>
                    <ProtectedRoute path={`/translations-translate/&storyId=:storyId&storyTransId=:storyTransId&episodeId=:episodeId`} exact component={TranslationTranslate}/>
                    <ProtectedRoute 
                        path={`/translations-body/&storyId=:storyId&serialNumber=:serialNumber&language=:language&nativeLanguage=:nativeLanguage`} 
                        exact 
                        component={TranslationsBody}/>
                    <ProtectedRoute path="/translations/preview-lines" exact component={TranslationsPreviewLines}/>
                    <ProtectedRoute path="/art-catalog" exact component={ArtCatalog}/>
                    <ProtectedRoute path="/analytics" exact component={Analytics}/>
                    <ProtectedRoute path="/getting-started" exact component={GettingStarted}/>
                    <ProtectedRoute path="/writers-guide" exact component={WritersGuide}/>
                    <ProtectedRoute path="/translators-guide" exact component={TranslatorsGuide}/>
                    <ProtectedRoute path="/artists-guide" exact component={ArtistsGuide}/>
                    <ProtectedRoute path={`/setup-story/&storyId=:storyId&storyTransId=:storyTransId`} exact component={SetupStory}/>
                    <ProtectedRoute path={`/edit-episode/&storyId=:storyId&storyTransId=:storyTransId&episodeId=:episodeId`} exact component={EditEpisode}/>
                    <ProtectedRoute path="/user-management" exact component={UserManagement}/>
                  </Switch>
                </MainLayout>
            </Route>
        </Switch>
    </Router>)
};
