import React, { useEffect, useState } from 'react';
import { CButton, CDataTable } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { MdGTranslate } from 'react-icons/md';
import { AiOutlineDownload, AiOutlineRollback, AiOutlinePicRight, AiOutlinePicCenter } from 'react-icons/ai';
import { useHistory, useParams } from 'react-router-dom';
import { GetAllStoriesForTranslationsSelect } from '../../services/StoriesServices';
import { useAlert } from 'react-alert';
import Select from 'react-select';
import { GetEpisodesForTranslationsSelect } from '../../services/EpisodeServices';
import { ChangeEpisodeStatusToCorrectionRequired, DownloadTranslation, GetEpisodeTranslations, UploadFile } from '../../services/TranslationsServices';
import { GetLanguages } from '../../services/LanguageServices';
import { handleNameConvert } from '../Helpers/NameConvert';
import { FaFileUpload } from 'react-icons/fa';
import ReactLoading from 'react-loading';
import { ActionCreatorsForTranslations } from '../../store/TranslationsReducer';
import { isTranslatorExternal, isTranslatorLead } from '../Auth/AuthConfig';
import { contentForChangeEpisodeStatus } from '../..';
import { DownloadSessionLogger, EditLanguageSessionLogger, UploadSessionLogger } from '../../modules/firebaseDetailedLoggers';
import { YesNoModal } from '../Helpers/YesNoModal';

export const TranslationTranslate = () =>
{
    const dispatch = useDispatch();
    const alert = useAlert();
    const alertWithClose = useAlert(contentForChangeEpisodeStatus);
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const [languageData, setLanguageData] = useState([]);

    const { episodeTranslations } = useSelector((state: any) => state.translationsReducer);

    const { storyId, storyTransId, episodeId } : any = useParams();

    const [stories, setStories] = useState<any[]>([]);
    const [episodes, setEpisodes] = useState<any[]>([]);

    const [chosenStory, setChosenStory] = useState<any>({}) 
    const [chosenEpisode, setChosenEpisode] = useState<any>({}) 

    const [selectedFileFinalVersion, setSelectedFileFinalVersion] = useState();
    const [isFilePickedFinalVersion, setIsFilePickedFinalVersion] = useState(false);
    const [openConfirmationForChangeStatus, setOpenConfirmationForChangeStatus] = useState({modalOpen: false, modalLoading: false}); 

    const { updatingBool } = useSelector((state: any) => state.translationsReducer);

    //table columns
    const columns = 
    [
        {
            key: 'language',      
            sorter: false
        },
        {
            key: 'episodeStatus',
            label: 'Status',      
            sorter: false
        },
        {
            key: 'edit',
            sorter: false
        },
        {
            key: 'upload',
            sorter: false,
            _style: {width: '20%'}
        },
        {
            key: 'download',
            sorter: false
        }   
    ]

    const changeHandler1 = (event: any) => {
        setSelectedFileFinalVersion(event.target.files[0]);
        setIsFilePickedFinalVersion(true);
    };

    const clickHandler1 = (event: any) => {
        event.target.value = null;
    };
    
    useEffect(() =>
    { 
        setChosenStory(stories.find(element => element.id == storyId));
        setChosenEpisode(episodes.find(element => element.id === episodeId))
    }, [storyId, storyTransId, episodeId, stories, episodes])

    useEffect(() => 
    { 
        GetLanguages(alert).then((response) => 
        { 
            handleNameConvert(response);

            setLanguageData(response);
        }); 

        GetAllStoriesForTranslationsSelect(alert).then((response) => { setStories(response); })
        GetEpisodesForTranslationsSelect(storyId, alert).then((response) => { setEpisodes(response); })
    }, [])

    useEffect(() => 
    {
        setLoading(true);
        GetEpisodeTranslations(storyId, storyTransId, episodeId, alert, dispatch).then(() => { setLoading(false); });
    }, [storyId, storyTransId, episodeId])

	const downloadTranslate = (storyId: string, serialNumber: string, language: number) => {
        DownloadTranslation(storyId,serialNumber,language, alert)
			.then(response => {
                if(response != null) {
                    //let url = window.URL.createObjectURL(new Blob([JSON.stringify(data, null, 2)], {type: "application/json"}));
                    let url = window.URL.createObjectURL(new Blob([response?.data], {type: response.headers["content-type"]}));
                    let a = document.createElement('a');
                    //a.style.display = 'none';
                    a.href = url;
                    a.download = response.headers['content-disposition'].split('filename=')[1].split(';')[0].replace(/^"|"+$/g, '');
                    //document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            dispatch(ActionCreatorsForTranslations.setUpdatingBool('not updating'))
		});
	}

    return(
        <div className="column-style mt-3"> 
            <div className="page-title-container">
                <div className="page-title-icon"><MdGTranslate color="#745DEC" size={26} className="icon-margin" /></div><div className="page-title">Translate</div>
            </div>
            <div className="register-signin-left-panel col-sm-12 rounded shadow translation-translate">

            { updatingBool === 'updating' ? <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1000, position: 'fixed', top: '0%', left: '0%', width: '100%', height: '2000px', backgroundColor: 'rgb(115,115,115,0.5)'}}>
                <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1001, position: 'absolute', top: '20%', left: '45%'}}>
                    <ReactLoading height="100px" width="100px" className="mt-3 mb-5" type='spin' color='#0080FF'/>
                </div>
            </div> : <div/> }

            { loading ? 
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <ReactLoading height="75px" width="75px" className="mt-3 mb-5" type='spin' color='#0080FF'/>
                </div> : 
                
                <div className="row-style row ml-2 top-search-translate">
                    <div className="form-group-name trans-story-cont col-sm-6 ">
                        <label htmlFor="register_author_firstname" className="label-info-box"><AiOutlinePicRight size="21" />&nbsp;Story</label>
                    <Select
                        options={stories}
                        value={chosenStory}
                        onChange={(value : any) => 
                        { 
                            setLoading(true);
                            GetEpisodesForTranslationsSelect(value.id, alert).then((response) => 
                            { 
                                setEpisodes(response); 
                                setChosenStory(value);
                                history.push(`/translations-translate/&storyId=${value.id}&storyTransId=${value.value}&episodeId=${response[0]?.id}`)
                                setLoading(false);
                            })
                        }}
                        as="select"
                        autoComplete="off"
                        placeholder="Choose a story..."
                        required >
                    </Select>
                </div>
    
                <div className="form-group-name trans-episode-cont col-sm-2 ml-1">
                    <label htmlFor="register_author_firstname " className="label-info-box"><AiOutlinePicCenter size="21" />&nbsp;Episode</label>
                    <Select
                        options={episodes}
                        value={chosenEpisode}
                        onChange={(value : any) => 
                        { 
                            setChosenEpisode(value);
                            history.push(`/translations-translate/&storyId=${chosenStory.id}&storyTransId=${chosenStory.value}&episodeId=${value.id}`)
                        }}
                        name="episode"
                        as="select"
                        autoComplete="off"
                        placeholder="Enter episode..."
                        required>
                    </Select>
                </div>

                <div className="form-group-name trans-episode-cont col-sm-2 ml-1 return-to-cr-button">
                    { isTranslatorLead() ? <CButton shape="pill" color="primary" onClick={() => {
                        setOpenConfirmationForChangeStatus({...openConfirmationForChangeStatus, modalOpen: true});
                    }}>Change Episode status to Correction Required</CButton> : '' }
                </div>
            </div> }
            </div>
            <div className="register-signin-left-panel col-sm-12 rounded shadow stories">
                <CDataTable 
                    clickableRows
                    addTableClasses="translations-translate-table"
                    items={episodeTranslations}
                    fields={columns}
                    loading={loading}
                    striped 
                    hover
                    outlined
                    sorter
                    pagination
                    responsive={true}
                    scopedSlots = {{
                        'language':
                        (item : any, index : any) => 
                        {
                            var itemForLabel : any = languageData.find((element : any) => element.id == item.language);
                            return(
                                <td className="py-2">
                                    <div className="row-style mt-1">
                                        <div>{itemForLabel?.label} <span>{item?.isNativeLanguage ? '(Native language)' : ''}</span></div>
                                    </div>
                                </td>
                            )
                        },
                        'edit':
                        (item : any, index : any) => 
                        {
                            return(
                                <td className="py-2">
                                    { item.enabledForTranslation && !isTranslatorExternal() && item.episodeStatusEnum >= 4 ? <div className="row-style mt-2">
                                        <MdGTranslate  size={21} className="action-icons" 
                                        onMouseDown={(e : any) => { if(e.button == 1) {
                                            e.preventDefault();

                                            const editLanguageSession = new EditLanguageSessionLogger();
                                            
                                            editLanguageSession.timestamp = new Date().toUTCString();
                                            editLanguageSession.session_id = localStorage.getItem('sessionId') ?? '';
                                            editLanguageSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                                            editLanguageSession.user_id = localStorage.getItem('uid') ?? '';
    
                                            editLanguageSession.logEvent();

                                            window.open(`/translations-body/&storyId=${item.storyId}&serialNumber=${item.serialNumber}&language=${item.language}&nativeLanguage=${item.nativeLanguage}`, '_blank')
                                        }} }
                                        onClick={() => 
                                        {   
                                            const editLanguageSession = new EditLanguageSessionLogger();
                                            
                                            editLanguageSession.timestamp = new Date().toUTCString();
                                            editLanguageSession.session_id = localStorage.getItem('sessionId') ?? '';
                                            editLanguageSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                                            editLanguageSession.user_id = localStorage.getItem('uid') ?? '';
    
                                            editLanguageSession.logEvent();

                                            history.push(`/translations-body/&storyId=${item.storyId}&serialNumber=${item.serialNumber}&language=${item.language}&nativeLanguage=${item.nativeLanguage}`)
                                        }}/>
                                    </div> : <div className="center mt-2">N/A</div> }
                                </td>
                            )
                        },
                        'upload': 
                        (item: any, index: any) =>
                        {
                            return(
                                <td className="py-2 center">
                                    { item.enabledForTranslation && (chosenStory != null && chosenStory?.language != item.language && item.episodeStatusEnum == 4) ? <input  className="input-upload-final" type="file" name="file1" id="fileUpload1" onClick={clickHandler1} onChange={changeHandler1} /> : 'N/A'}
                                    { item.enabledForTranslation && (chosenStory != null && chosenStory?.language != item.language && item.episodeStatusEnum == 4) ? <button className="btn-upload-final" onClick={() => 
                                    { 
                                        dispatch(ActionCreatorsForTranslations.setUpdatingBool('updating'))

                                        const uploadSession = new UploadSessionLogger();
                                        
                                        uploadSession.timestamp = new Date().toUTCString();
                                        uploadSession.session_id = localStorage.getItem('sessionId') ?? '';
                                        uploadSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                                        uploadSession.user_id = localStorage.getItem('uid') ?? '';

                                        uploadSession.logEvent();

                                        UploadFile({ file: selectedFileFinalVersion, storyId: item.storyId, serialNumber: item.serialNumber, language: item.language }, alertWithClose, dispatch, index)
                                    }}><FaFileUpload size={21} color="blue" className="project-upload-icons" /></button> : ''}
                                </td>
                            )
                        },
                        'download': 
                        (item: any, index: any) =>
                        {
                            return(
                                <td className="py-2">
                                    <div className="row-style mt-1">
                                        { (item.enabledForTranslation && chosenStory != null && chosenStory?.language != item.language) ? <AiOutlineDownload onClick={() =>
                                        {
                                            dispatch(ActionCreatorsForTranslations.setUpdatingBool('updating'))

                                            const downloadSession = new DownloadSessionLogger();
                                            
                                            downloadSession.timestamp = new Date().toUTCString();
                                            downloadSession.session_id = localStorage.getItem('sessionId') ?? '';
                                            downloadSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                                            downloadSession.user_id = localStorage.getItem('uid') ?? '';
    
                                            downloadSession.logEvent();

                                            downloadTranslate(item.storyId, item.serialNumber, item.language)
                                        }} 
                                        className="action-icons" size={21}/> : 'N/A'}
                                    </div>
                                </td>
                            )
                        }
                    }}
                />
            </div>
            <YesNoModal modal={openConfirmationForChangeStatus} setModal={setOpenConfirmationForChangeStatus} methodForYes={() => {
                setOpenConfirmationForChangeStatus({...openConfirmationForChangeStatus, modalOpen: false});
                dispatch(ActionCreatorsForTranslations.setUpdatingBool('updating'));
                ChangeEpisodeStatusToCorrectionRequired(storyId, chosenEpisode.serialNumber).then((response) => 
                { 
                    if(response?.success) 
                    {
                        history.push('/translations');
                    }
                    alert.info(response?.message);
                    dispatch(ActionCreatorsForTranslations.setUpdatingBool('not updating'));
                });
            }} title="Episode status change" message="Are you sure you want to change the status of this episode to Correction Required?" />
    </div>)
}

export default TranslationTranslate;