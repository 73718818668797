import React, { useEffect } from 'react';

export const Analytics = () =>
{
    useEffect(() => {
        document.title = 'Story Maker - Analytics'
    }, [])
    return(<div>Analytics</div>)
}

export default Analytics;