import { axiosInstance } from '../axios/key';
import { ErrorHandler } from '../components/Helpers/ErrorHandler';
import { LineType } from '../interfaces/lineType';
import { ActionCreatorsForEditEpisode } from '../store/EditEpisodeReducer';

export const GetAllWritters = async (alert : any) =>
{
    try
    {
        const { data } = await axiosInstance.get('api/Line/GetAllWritters');
        return data;
    }
    catch(e)
    {
        ErrorHandler(e, 'There was an error while trying to get all writters.', alert);
    }
}

export const GetCommentDropDownUsers = async (storyId: any, alert : any) =>
{
    try
    {
        const { data } = await axiosInstance.get('api/Line/GetCommentDropdownUsers?storyId='+`${storyId}`);
        return data;
    }
    catch(e)
    {
        ErrorHandler(e, 'There was an error while trying to get all writters for comments.', alert);
    }
}

export const GetLineTypes = async (storyId: string, alert: any) =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/Line/GetLineTypes?storyId=${storyId}`);
        return data;
    }
    catch(e)
    {
        ErrorHandler(e, 'Error in getting all types.', alert);
    }
}

export const GetLineFormats = async (storyId: any, alert: any) => {
    try {
        const { data } = await axiosInstance.get(`api/Line/GetLineFormats?storyId=${storyId}`);
        return data;
    }
    catch(e)
    {
        ErrorHandler(e, 'Error in getting all formats.', alert);
    }
}

export const GetStoryCharacters = async (storyId: any, alert: any) => {
    try {
        const { data } = await axiosInstance.get(`api/Line/GetStoryCharacters?storyId=${storyId}`);
        return data;
    }
    catch (e) {
        ErrorHandler(e, 'An error occured while trying to get story characters.', alert);
    }
}

export const GetStoryItems = async (storyId: any, alert: any) => {
    try {
        const { data } = await axiosInstance.get(`api/Line/GetStoryItems?storyId=${storyId}`);
        return data;
    }
    catch (e) {
        ErrorHandler(e, 'An error occured while trying to get story items.', alert);
    }
}

export const GetStoryCharactersForOutfitChange = async (storyId: any, alert: any) => {
    try {
        const { data } = await axiosInstance.get(`api/Line/GetStoryCharactersForOutfitChange?storyId=${storyId}`);
        return data;
    }
    catch (e) {
        ErrorHandler(e, 'An error occured while trying to get story characters for outfit change.', alert);
    }
}


export const GetEmotions = async (alert: any) => {
    try {
        const { data } = await axiosInstance.get('api/Line/GetEmotions');
        return data;
    }
    catch (e) {
        ErrorHandler(e, 'An error occured while trying to get emotions.', alert);
    }
}

export const GetAnimations = async (storyId: any, alert: any) => {
    try {
        const { data } = await axiosInstance.get(`api/Line/GetStoryAnimations?storyId=${storyId}`);
        return data;
    }
    catch (e) {
        ErrorHandler(e, 'An error occured while trying to get animations.', alert);
    }
}

export const DeleteLine = async (dispatch: any, alert: any, line: any, index: number, linesFrame: number, selectedLineIndex: number, setYesnomodal : any, nextLineDocId: any) =>
{
    try
    {
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updating'));
        setYesnomodal(false);
        await axiosInstance.post('api/Line/DeleteLine', {storyId: line.storyId, storyTranslationId: line.storyTranslationId, episodeId: line.episodeId, lineId: line.docId, lineType: line.lineType}).then(async (response : any) => 
        {
            if(nextLineDocId && line.actionChangeFlag)
            {
                await axiosInstance.post('api/Line/UpdateActionChangeFlag', {storyId: line.storyId, translationId: line.storyTranslationId, episodeId: line.episodeId, lineId: nextLineDocId, actionChangeFlag: true});
                dispatch(ActionCreatorsForEditEpisode.updateActionChangeFlagOnDelete(nextLineDocId));
            }
    
            dispatch(ActionCreatorsForEditEpisode.removeLine({clickedLineIndex: index, linesFrame, selectedLineIndex}));
            
            if(response.data?.eLines && response.data?.eLines.length != 0)
            {
                dispatch(ActionCreatorsForEditEpisode.populateELines(response.data?.eLines))
            }
            dispatch(ActionCreatorsForEditEpisode.setEpisodeSummary(response.data));
            dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updated'));
            alert.success('You have successfully deleted the line.');
        });
    }
    catch(e)
    {
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('error'));
        ErrorHandler(e, 'Error in deleting line', alert);
    }
}

export const DeleteLines = async (dispatch: any, alert: any, lines: LineType[], index: number, linesFrame: any, setYesnomodal : any, nextLineDocId: any) =>
{
    try
    {
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updating'));        
        setYesnomodal({boolean: false, array: [], nextLineDocId: null});
        alert.info('Waiting for server response...');
        await axiosInstance.post('api/Line/DeleteMultipleLines', lines).then(async (response : any) => 
        {
            if (nextLineDocId)
            {
                let actionFlagSet = false;
                lines.forEach((element) => { if (element.actionChangeFlag) actionFlagSet = true; } );
                if (actionFlagSet)
                {
                    await axiosInstance.post('api/Line/UpdateActionChangeFlag', {storyId: lines[0].storyId, translationId: lines[0].storyTranslationId, episodeId: lines[0].episodeId, lineId: nextLineDocId, actionChangeFlag: true});
                    dispatch(ActionCreatorsForEditEpisode.updateActionChangeFlagOnDelete(nextLineDocId));
                }
            }
    
            dispatch(ActionCreatorsForEditEpisode.removeLines({lines, index, linesFrame, isOLineAdded: lines[0].lineType === 1 ? false : undefined }));
            
            if(response.data?.eLines && response.data?.eLines.length != 0)
            {
                dispatch(ActionCreatorsForEditEpisode.populateELines(response.data?.eLines))
            }
            dispatch(ActionCreatorsForEditEpisode.setEpisodeSummary(response.data));
            dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updated'));
            alert.success('You have successfully deleted the line.');
        });
    }
    catch(e)
    {
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('error'));
        ErrorHandler(e, 'Error in deleting line', alert);
    }
}

export const GetChangeOutfitTypes = async (dispatch : any, alert : any) =>
{
    try
    {
        const { data } = await axiosInstance.get('api/Line/GetChangeOutfitTypes');
        dispatch(ActionCreatorsForEditEpisode.setChangeOutfitTypes(data))
    }
    catch(e)
    {
        ErrorHandler(e, 'Error in getting change outfit types.', alert);
    }
}

export const GetStoryAnimations = async (dispatch : any, storyId : any, serialNumber : any) =>
{ 
    try
    {
        const { data } = await axiosInstance.get(`api/Line/GetStoryAnimations?storyId=${storyId}`)
        dispatch(ActionCreatorsForEditEpisode.setCinematics(
            [{ id: -1, value: -1, label: 'Create new cinematic' }]
            .concat(data?.filter((element : any) =>
                element.name.substring(0, element.name.lastIndexOf('_')) == serialNumber
            ).map((element : any) => { return { id: element.id, value: element.id, label: element.name }}))
        ))
    }
    catch(e)
    {
        console.log('Error in getting story animations');
    }
}