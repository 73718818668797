import React, { useEffect, useState } from "react"
import { CButton, CInput, CLabel, CModal, CModalBody, CModalFooter, CModalHeader, CTextarea } from "@coreui/react"
import { AiOutlineControl } from "react-icons/ai"
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators } from "../../store/StoriesReducer";
import { GameType } from "../Common/Enums/GameType";
import { ActionCreatorsForEditEpisode, defaultLine } from "../../store/EditEpisodeReducer";
import { LineTypes } from "../Common/Enums/LineTypes";
import CreatableSelect from 'react-select/creatable';
import { FormatObject, selectStylesForField, validateEpisodeStatusForDisable, validateLineOptionsChange } from "../Common/Lines/_Helpers";
import EpisodeActionsEffect from "../Common/EpisodeActionsEffects";
import { UpdateLineSessionLogger } from "../../modules/firebaseDetailedLoggers";
import { useAlert } from 'react-alert';

export const EditEpisodeOptionsPopup = ({storyName, episodeName, modal, actionObject, previousLineUpdate, previousStateActions, selectedLineIndex }: any) => 
{
    const alert = useAlert();
    const customStyles =
    {
        control: (styles : any) => ({ ...styles, backgroundColor: 'white' }),
        // we don't want to render images for now
        //option: (styles : any, { data } : any) => 
        //{
        //    return({ ...styles, ...addImage(data) })
        //}
    }

    const dispatch = useDispatch();
    const gameType = useSelector((state : any) => state.storiesReducer.gameType);
    const { linesStartIndex, lines, updateCloudBool, storyItems, editEpisodePassParam } = useSelector((state: any) => state.editEpisodeReducer);
    
    const [notificationCharmingPoint, setNotificationCharmingPoint] = useState(actionObject.charmingPoint);
    const [premiumChoiceText, setPremiumChoiceText] = useState(actionObject.premiumChoiceText);
    const [premiumChoiceDescription, setPremiumChoiceDescription] = useState(actionObject.premiumChoiceDescription);
    const [showItemDescription, setShowItemDescription] = useState(actionObject.itemDescription);
    
    return(
        <CModal show={modal} closeOnBackdrop={false} onClose={()=> dispatch(ActionCreators.setModalPopupBoolOption({isOpen: false, line: actionObject, index: selectedLineIndex }))}>
            <CModalHeader closeButton><AiOutlineControl size={21}/>&nbsp;&nbsp;Options</CModalHeader>
            <CModalBody>
            <div>
            <fieldset style={validateEpisodeStatusForDisable(editEpisodePassParam.episodeStatusEnum) ? 
                { 
                    pointerEvents: 'none', 
                    opacity: 0.7,
                    border: '5px solid rgba(0, 0, 154, 0.5)',
                    borderStyle: 'ridge',  
                    boxSizing: 'border-box'
                } : {}} className="register-signin-left-panel col-sm-12 rounded shadow edit-episode comments options">
                {validateEpisodeStatusForDisable(editEpisodePassParam.episodeStatusEnum) ? <legend className="rounded nt-info legend-actions shadow">Options are disabled due to the current status of the episode.</legend> : ''}
                <div>
                <div className="action-col2 mt-4 column-style">
                    <CLabel className="action-col-title">Line options</CLabel>
                    <CLabel className="ac-sec-lbl" htmlFor="text1">Notification for charming point</CLabel>
                    <CTextarea
                        className="form-control"
                        name="text1"
                        type="text"
                        as="textarea"
                        value={notificationCharmingPoint ?? ''}
                        onChange={(e : any) => 
                        { 
                            if(e !== null) 
                            {   
                                setNotificationCharmingPoint(e.target.value)
                            } 
                            
                        }}
                        autoComplete="off"
                        required>
                    </CTextarea>
                    <CLabel className="ac-sec-lbl" htmlFor="text2">Premium choice text</CLabel>
                    <CTextarea
                        className="form-control"
                        name="text2"
                        type="text"
                        as="textarea"
                        value={premiumChoiceText ?? ''}
                        disabled={selectedLineIndex == -1 || actionObject.lineType != LineTypes.A || (actionObject.lineType == LineTypes.A && !actionObject.payed)}
                        onChange={(e : any) => 
                        { 
                            if(e !== null) 
                            {
                                setPremiumChoiceText(e.target.value)
                            } 
                        }}
                        autoComplete="off"
                        required>
                    </CTextarea>

                    <p className="font-weight-light text-muted" style={{fontSize: '0.8rem'}}>Available only for payed A line.</p>
                    
                    <CInput
                        className="form-control"
                        name="text2"
                        type="text"
                        value={premiumChoiceDescription ?? ''}
                        placeholder="Premium choice description bubble"
                        disabled={selectedLineIndex == -1 || actionObject.lineType != LineTypes.A || (actionObject.lineType == LineTypes.A && !actionObject.payed)}
                        onChange={(e : any) => 
                        { 
                            if(e !== null) 
                            {   
                                setPremiumChoiceDescription(e.target.value)
                            } 
                        }}
                        autoComplete="off"
                        required>
                    </CInput>
                    <p className="font-weight-light text-muted" style={{fontSize: '0.8rem'}}>Available only for payed A line.</p>
                    

                    { gameType == GameType.Lunescape && actionObject.lineType == LineTypes.L ? 
                    <>
                        <CLabel className="ac-sec-lbl" htmlFor="assignedTo">Show Item</CLabel>
                        <CreatableSelect 
                            createOptionPosition="first"
                            options={storyItems}
                            styles={{...customStyles, ...selectStylesForField}}
                            menuPortalTarget={document.querySelector('body')}
                            value={actionObject.itemId != null ? {id: actionObject.itemId, value: actionObject.itemName, label: actionObject.itemName} : {} }
                            onChange={(e : any) => 
                                { 
                                    if(e !== null)
                                    {   
                                        if(previousLineUpdate)
                                        {
                                            dispatch(ActionCreators.updateShowItemInPopup(e));
                                            dispatch(ActionCreators.updateShowItemDescriptionInPopup(e.description));
                                        }
                                        else
                                        {
                                            dispatch(ActionCreatorsForEditEpisode.updateShowItem(e))
                                            dispatch(ActionCreatorsForEditEpisode.updateShowItemDescription(e.description))
                                        }
                                    }
                                    else
                                    {
                                        if(previousLineUpdate)
                                        {
                                            dispatch(ActionCreators.updateShowItemInPopup({id: null, value: '', label: ''}));
                                            dispatch(ActionCreators.updateShowItemDescriptionInPopup(''));
                                        }
                                        else
                                        {
                                            dispatch(ActionCreatorsForEditEpisode.updateShowItem({id: null, label: '', description: ''}))
                                            dispatch(ActionCreatorsForEditEpisode.updateShowItemDescription(''))
                                        }
                                    }
                                }}
                            onCreateOption={(value : any) => 
                            {
                                if(previousLineUpdate)
                                    dispatch(ActionCreators.updateShowItemInPopup({ id: '-1', value: value, label: value }))
                                else
                                    dispatch(ActionCreatorsForEditEpisode.updateShowItem({ id: '-1', value: value, label: value }))
                            }}
                            isClearable
                            backspaceRemovesValue
                            name="background"
                            autoComplete="off"
                            placeholder="Choose item..."
                            required>
                        </CreatableSelect>
                        <CInput
                            className="form-control desc-input"
                            name="assignedTo"
                            type="text"
                            disabled={actionObject.itemId == '' ? true : actionObject.itemId == null ? true : actionObject.itemId === '-1' ? false : true}
                            value={showItemDescription}
                            onChange={(e : any) => { if(updateCloudBool !== 'updating') setShowItemDescription(e.target.value) } }
                            autoComplete="off"
                            placeholder="Description"
                            required>
                        </CInput>
                    </> : ''}
                    { selectedLineIndex != -1 ? 
                    <CButton 
                        color="primary" 
                        type="submit" 
                        style={{marginTop:'15px'}}
                        onClick={()=> 
                        {
                            var isChangedByOption = validateLineOptionsChange(dispatch, actionObject, lines, previousStateActions, false);
                            
                            if(isChangedByOption)
                            {
                                const updateLineSession = new UpdateLineSessionLogger();

                                updateLineSession.story_name = storyName ?? '';
                                updateLineSession.episode_name = episodeName ?? '';
                                updateLineSession.timestamp = new Date().toUTCString();
                                updateLineSession.session_id = localStorage.getItem('sessionId') ?? '';
                                updateLineSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                                updateLineSession.user_id = localStorage.getItem('uid') ?? '';

                                updateLineSession.logEvent();

                                FormatObject(
                                    dispatch, 
                                    selectedLineIndex, 
                                    linesStartIndex,
                                    lines, 
                                    { lineToUpdateId: actionObject.docId, lineModel: actionObject }, 
                                    alert, previousLineUpdate, true
                                );
                            }
                            else
                            {
                                alert.show('Validation of option changes failed. Please, try again.')
                            }

                        }} 
                    >Update line</CButton> : '' }
                    <EpisodeActionsEffect 
                        actionObject={actionObject}
                        backgroundDescription={null} 
                        musicThemeDescription={null} 
                        shortSoundDescription={null}
                        showItemDescription={showItemDescription}
                        notificationCharmingPoint={notificationCharmingPoint}
                        premiumChoiceText={premiumChoiceText}
                        premiumChoiceDescription={premiumChoiceDescription}
                        setBackgroundDescription={null}
                        setMusicThemeDescription={null}
                        setShortSoundDescription={null}
                        setShowItemDescription={setShowItemDescription}
                        setNotificationCharmingPoint={setNotificationCharmingPoint}
                        setPremiumChoiceText={setPremiumChoiceText}
                        setPremiumChoiceDescription={setPremiumChoiceDescription}
                        previousLineUpdate={previousLineUpdate}
                    />
                </div>
                </div></fieldset></div>
        </CModalBody>
    </CModal>)
}