import { CLabel } from "@coreui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CTooltip } from '@coreui/react';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { ActionCreatorsForEditEpisode } from "../../store/EditEpisodeReducer";

export const EditEpisodeSummary = () => {
    const dispatch = useDispatch();
    const { wordsNum, linesNum, questionsNum, customizationNum, paidAnswersNum, cinematicsNum } = useSelector((state: any) => state.editEpisodeReducer.episodeSummary);
    const { linesFrame, selectedLineIndex, lines, isRenderedByAction, currentLine } = useSelector((state: any) => state.editEpisodeReducer);
    let lineWords = selectedLineIndex === -1 && !isRenderedByAction ? 0 : (currentLine.text?.trim().split(' ').filter((x: any) => x !== "").length ?? 0);
    let wordsNumComplete = selectedLineIndex === -1 ? wordsNum + lineWords : wordsNum - (lines[selectedLineIndex]?.text?.trim().split(' ').filter((x: any) => x !== "").length ?? 0) + lineWords
    
    useEffect(() => { sessionStorage.setItem('wordsNum', wordsNum) }, [wordsNum]);
    useEffect(() => { sessionStorage.setItem('linesNum', linesNum) }, [linesNum]);
    useEffect(() => { sessionStorage.setItem('choicesNum', questionsNum) }, [questionsNum]);
    useEffect(() => { sessionStorage.setItem('custChoicesNum', customizationNum) }, [customizationNum]);
    useEffect(() => { sessionStorage.setItem('paidAnswersNum', paidAnswersNum) }, [paidAnswersNum]);
    useEffect(() => { sessionStorage.setItem('cinematicsNum', cinematicsNum) }, [cinematicsNum]);
    
    return (
    <div className="mt-3">
        <CLabel className="text-left mt-3" style={{float: 'left'}}>
            {currentLine.text?.length ?? 0} chars
        </CLabel>
        <span className="cursor" style={{float: 'right', marginTop: "16px", marginBottom: "8px", marginRight: "-25px", marginLeft: "10px"}}>
            <CTooltip content="Go to the start.">
                <AiOutlineArrowUp size={21} color="darkorange" onClick={() => {
                    let newCurrentLine = {...lines[0]}
                    let newCurrentLineIndex = 0
                    dispatch(ActionCreatorsForEditEpisode.setCurrentLine({
                        currentLine: newCurrentLine, 
                        currentLineIndex: newCurrentLineIndex, 
                        linesStartIndex: newCurrentLineIndex + 1 > linesFrame ? newCurrentLineIndex + 1 - linesFrame : (newCurrentLineIndex === -1 && lines.length + 1 > linesFrame ? lines.length - linesFrame + 1 : 0),
                        linesSliceParam: 0
                    }))
                }}/>
            </CTooltip>
            <CTooltip content="Go to the end.">
                <AiOutlineArrowDown size={21} color="darkorange" onClick={() => {
                    let newCurrentLine = {...lines[lines.length - 1], text: undefined}
                    let newCurrentLineIndex = -1
                    dispatch(ActionCreatorsForEditEpisode.setCurrentLine({
                        currentLine: newCurrentLine, 
                        currentLineIndex: newCurrentLineIndex, 
                        linesStartIndex: newCurrentLineIndex + 1 > linesFrame ? newCurrentLineIndex + 1 - linesFrame : (newCurrentLineIndex === -1 && lines.length + 1 > linesFrame ? lines.length - linesFrame + 1 : 0),
                        linesSliceParam: lines.length
                    }))
                }}/>
            </CTooltip>
        </span>
        <CLabel className="text-right mt-3" style={{float: 'right'}}>
            {wordsNumComplete} word(s), {linesNum} line(s), {questionsNum} choice(s), {customizationNum} customization choice(s), {paidAnswersNum} paid answer(s), {cinematicsNum} cinematic(s)
        </CLabel>
    </div>)
}