import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorsForTranslations, defaultLine } from '../../../store/TranslationsReducer';
import { PlaceholderLine } from '../Lines';
import BasicTranslationLine from './TranslationLines/BasicTranslationLine';
import { useAlert } from 'react-alert';
import { OutfitTranslationLine1, OutfitTranslationLine2, OutfitTranslationLine3 } from './TranslationLines/OutfitTranslationLine';
import { AnswerTranslationLine, QuestionTranslationLine } from './TranslationLines/QuestionAnswerTranslationLine';
import CinematicTranslationLine from './TranslationLines/CinematicTranslationLine';
import Customization1TranslationLine from './TranslationLines/Customization1TranslationLine';
import Customization2TranslationLine from './TranslationLines/Customization2TranslationLine';
import BranchTranslationLine from './TranslationLines/BranchTranslationLine';
import GotoMainTranslationLine from './TranslationLines/GotoMainTranslationLine';
import SelectedLine from './TranslationLines/SelectedLine';
import Loader from '../Loader';
import { CButton, CTooltip } from '@coreui/react';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { VscPreview } from 'react-icons/vsc';
import { calculateMaxAmountLines, calculateNumberOfShowingLines } from './TranslationLines/_TranslationsHelpers';
import { GetLanguages } from '../../../services/LanguageServices';
import { handleNameConvert } from '../../Helpers/NameConvert';
import EndOfLevelTranslationLine from './TranslationLines/LunescapeAdditionalLines/EndOfLevelTranslationLine';
import { TimedChoiceTranslationLine } from './TranslationLines/TimedChoiceTranslationLine';
import { TimedChoiceAnswerTranslationLine } from './TranslationLines/TimedChoiceAnswerTranslationLine';
import { VisualChoiceTranslationLine } from './TranslationLines/VisualChoiceTranslationLine';
import { VisualChoiceAnswerTranslationLine } from './TranslationLines/VisualChoiceAnswerTranslationLine';
import FashionTranslationLine from './TranslationLines/LunescapeAdditionalLines/FashionTranslationLine';
import { useHistory } from 'react-router-dom';

export const ScriptBody = ({storyId, serialNumber, language, firstLanguage, secondLanguage, thirdLanguage, nativeLanguage, loadingForScript } : any) =>
{
    const history = useHistory();

    const alert = useAlert();
    const dispatch = useDispatch();

    const [primaryLanguage, setPrimaryLanguage] = useState({id: 0, value: "", label: ""});
    const [otherLanguage, setOtherLanguage] = useState({id: 0, value: "", label: ""});

    const { selectedLineIndex, linesFrame, linesStartIndex, currentLineSerbian, currentLineEnglish, currentLineOther, updatingBool } = useSelector((state: any) => state.translationsReducer);
    
    const handleKeyPress = (event : any) =>
    {
        let currentLineIndex;
        let currentLineFirst;
        let currentLineSecond;
        let currentLineThird;

        switch(event.keyCode)
        {
            case 38:      
                event.preventDefault();     
                if (selectedLineIndex == 0) return;    
                if (selectedLineIndex == -1) 
                    currentLineIndex = firstLanguage?.length - 1;
                else 
                    currentLineIndex = selectedLineIndex - 1;

                currentLineFirst = firstLanguage && firstLanguage?.length != 0 ? firstLanguage[currentLineIndex == -1 ? firstLanguage?.length - 1 : currentLineIndex] : defaultLine
                currentLineSecond = secondLanguage && secondLanguage?.length != 0 ? secondLanguage[currentLineIndex == -1 ? secondLanguage?.length - 1 : currentLineIndex] : defaultLine
                currentLineThird = thirdLanguage && thirdLanguage?.length != 0 ? thirdLanguage[currentLineIndex == -1 ? thirdLanguage?.length - 1 : currentLineIndex] : defaultLine
                dispatch(ActionCreatorsForTranslations.setCurrentLineForTranslations(
                {
                    currentLineSerbian: nativeLanguage == 0 ? currentLineFirst : currentLineSecond, 
                    currentLineEnglish: nativeLanguage == 0 ? currentLineSecond : currentLineFirst, 
                    currentLineOther: currentLineThird,
                    currentLineIndex, 
                    linesStartIndex: currentLineIndex + 1 > linesFrame ? currentLineIndex + 1 - linesFrame : 0
                }))
                break;
            case 40:  
                event.preventDefault();  
                if (selectedLineIndex == -1) return;            
                if ( selectedLineIndex == firstLanguage?.length - 1)
                    currentLineIndex = -1;
                else 
                    currentLineIndex =  selectedLineIndex + 1;
                
                currentLineFirst = firstLanguage && firstLanguage?.length != 0 ? firstLanguage[currentLineIndex == -1 ? firstLanguage?.length - 1 : currentLineIndex] : defaultLine
                currentLineSecond = secondLanguage && secondLanguage?.length != 0 ? secondLanguage[currentLineIndex == -1 ? secondLanguage?.length - 1 : currentLineIndex] : defaultLine
                currentLineThird = thirdLanguage && thirdLanguage?.length != 0 ? thirdLanguage[currentLineIndex == -1 ? thirdLanguage?.length - 1 : currentLineIndex] : defaultLine
                dispatch(ActionCreatorsForTranslations.setCurrentLineForTranslations({
                    currentLineSerbian: nativeLanguage == 0 ? currentLineFirst : currentLineSecond, 
                    currentLineEnglish: nativeLanguage == 0 ? currentLineSecond : currentLineFirst, 
                    currentLineOther: currentLineThird, 
                    currentLineIndex, 
                    linesStartIndex: currentLineIndex + 1 > linesFrame ? currentLineIndex + 1 - linesFrame : (currentLineIndex === -1 && firstLanguage.length + 1 > linesFrame ? firstLanguage.length - linesFrame + 1 : 0)}))
                break;
            default:
                return;

        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    })
   
    useEffect(() => 
    { 
        GetLanguages(alert).then((resFromLanguage) => 
            { 
                handleNameConvert(resFromLanguage);
                setPrimaryLanguage(resFromLanguage.find((element : any) => element.id == parseInt(nativeLanguage)));
                setOtherLanguage(resFromLanguage.find((element : any) => element.id == parseInt(language)));
            });  
    }, [])
    
    const placeholderLines = selectedLineIndex !== -1 ? 
        new Array(selectedLineIndex < linesFrame - 1 ? linesFrame - 1 - selectedLineIndex : 0).fill(false) 
        : ( firstLanguage[0]?.isDefault ? new Array(linesFrame - 1).fill(false) : (linesFrame - 1 > firstLanguage.length ? new Array(linesFrame - 1 - firstLanguage.length).fill(false) : null));
    return(
        <div className="register-signin-left-panel col-sm-12 rounded shadow edit-episode comments translations-lines-nt">
            <Loader updatingBool={updatingBool}/>
            <div className="column-style edit-episode-header">
                <div className="row-style" style={{display: 'flex', justifyContent: 'space-between'}}>
                    <h6 className="lang-label">Language: {primaryLanguage?.label} (Native language) 
                        <CTooltip placement="top" content="Preview all lines">
                            <CButton onClick={() => {
                                history.push({
                                    pathname: '/translations/preview-lines',
                                    state: 
                                    { 
                                        lines: firstLanguage,
                                        storyId,
                                        serialNumber,
                                        language
                                    }
                                })
                            }}>
                                <VscPreview size={24} color="blue"/>
                            </CButton>
                        </CTooltip>
                    </h6>
                    <div className="row-style">
                        <span className="action-icon arrow-left cursor mt-4 mr-4">
                        <CTooltip placement="top" content="Go to the start.">
                            <AiOutlineArrowUp size={21} onClick={() => 
                            {
                                let currentLineFirst = firstLanguage && firstLanguage?.length != 0 && !firstLanguage[0]?.isDefault ? firstLanguage[0] : defaultLine;
                                let currentLineSecond = secondLanguage && secondLanguage?.length != 0 && !secondLanguage[0]?.isDefault ? secondLanguage[0] : defaultLine;
                                let currentLineThird = thirdLanguage && thirdLanguage?.length != 0 && !thirdLanguage[0]?.isDefault ? thirdLanguage[0] : defaultLine;
                                dispatch(ActionCreatorsForTranslations.setCurrentLineForTranslations(
                                {
                                    currentLineSerbian: nativeLanguage == 0 ? currentLineFirst : currentLineSecond, 
                                    currentLineEnglish: nativeLanguage == 0 ? currentLineSecond : currentLineFirst, 
                                    currentLineOther: currentLineThird, 
                                    currentLineIndex: 0, 
                                    linesStartIndex: 0
                                }))
                            }}/>
                        </CTooltip>
                        <CTooltip placement="top" content="Go to the end.">
                            <AiOutlineArrowDown size={21} onClick={() => 
                            {
                                let currentLineIndex = -1;
                                let currentLineFirst = firstLanguage && firstLanguage?.length != 0 && !firstLanguage[0]?.isDefault ? {...firstLanguage[firstLanguage.length - 1], text: undefined} : defaultLine;
                                let currentLineSecond = secondLanguage && secondLanguage?.length != 0 && !secondLanguage[0]?.isDefault ? {...secondLanguage[secondLanguage.length - 1], text: undefined} : defaultLine;
                                let currentLineThird = thirdLanguage && thirdLanguage?.length != 0 && !thirdLanguage[0]?.isDefault ? {...thirdLanguage[thirdLanguage.length - 1], text: undefined} : defaultLine;
                                dispatch(ActionCreatorsForTranslations.setCurrentLineForTranslations(
                                {
                                    currentLineSerbian: nativeLanguage == 0 ? currentLineFirst : currentLineSecond, 
                                    currentLineEnglish: nativeLanguage == 0 ? currentLineSecond : currentLineFirst, 
                                    currentLineOther: currentLineThird,
                                    currentLineIndex: currentLineIndex, 
                                    linesStartIndex: currentLineIndex + 1 > linesFrame ? currentLineIndex + 1 - linesFrame : (currentLineIndex === -1 && firstLanguage.length + 1 > linesFrame ? firstLanguage.length - linesFrame + 1 : 0)
                                }))
                            }}/>
                        </CTooltip>
                    </span>
                    <h6 className="lang-label mr-4">
                        Showing {calculateNumberOfShowingLines(firstLanguage, selectedLineIndex, linesFrame)} out of {calculateMaxAmountLines(firstLanguage, selectedLineIndex, linesFrame)} lines
                    </h6>
                    </div>
                </div>
                {
                    placeholderLines?.map((line: any, index: any) => <PlaceholderLine key={-index-2}/>)
                }
                {loadingForScript ? <Loader updatingBool={loadingForScript}/> : firstLanguage
                ?.slice(linesStartIndex, linesStartIndex + linesFrame - 1 - (placeholderLines != null ? placeholderLines.length : 0))
                ?.map((line: any, index: any) =>
                    line.lineType === 0 ? <BasicTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> : 
                        line.lineType === 1 ? <OutfitTranslationLine1 key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                            line.lineType === 2 ? <OutfitTranslationLine2 key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                line.lineType === 3 ? <OutfitTranslationLine3 key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                    line.lineType === 4 ? <QuestionTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                        line.lineType === 5 ? <AnswerTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isBranchAnswer={false} isPreview={false}/> :
                                            line.lineType === 6 ? <CinematicTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                line.lineType === 7 ? <Customization1TranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                    line.lineType === 8 ? <Customization2TranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                        line.lineType === 9 ? <BranchTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                            line.lineType === 10 ? <AnswerTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isBranchAnswer={true} isPreview={false}/> :
                                                                line.lineType === 11 ? <GotoMainTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                                    line.lineType === 12 ? <EndOfLevelTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> : 
                                                                        line.lineType === 13 ? <TimedChoiceTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                                            line.lineType === 14 ? <TimedChoiceAnswerTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isBranchAnswer={false} isPreview={false}/> : 
                                                                                line.lineType === 15 ? <VisualChoiceTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                                                    line.lineType === 16 ? <VisualChoiceAnswerTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isBranchAnswer={false} isPreview={false}/> :
                                                                                        line.lineType === 17 ? <FashionTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> 
                    : <div/>
                )}
                {selectedLineIndex != -1 
                    ? 
                    <SelectedLine 
                        currentLine={nativeLanguage == 0 ? currentLineSerbian : currentLineEnglish} 
                        selectedLineIndex={selectedLineIndex} 
                        inputLine={(secondLanguage[0]?.isDefault && thirdLanguage[0]?.isDefault)}
                        storyId={storyId} 
                        serialNumber={serialNumber} 
                        language={language}
                    /> 
                    : ''}
            </div>
            { !secondLanguage[0]?.isDefault ? 
            <div className="column-style edit-episode-header mt-4">
                <h6 className="lang-label">Language: {primaryLanguage?.id == 0 ? 'English US' : 'Serbian'}
                    <CTooltip placement="top" content="Preview all lines">
                        <CButton onClick={() => {
                            history.push({
                                pathname: '/translations/preview-lines',
                                state: 
                                { 
                                    lines: secondLanguage,
                                    storyId,
                                    serialNumber,
                                    language
                                }
                            })
                        }}>
                            <VscPreview size={24} color="blue"/>
                        </CButton>
                    </CTooltip>
                </h6>
                {
                    placeholderLines?.map((line: any, index: any) => <PlaceholderLine key={-index-2}/>)
                }
                {loadingForScript ? <Loader updatingBool={loadingForScript}/> : secondLanguage?.slice(linesStartIndex, linesStartIndex + linesFrame - 1 - (placeholderLines != null ? placeholderLines.length : 0))?.map((line: any, indexFromArray: any) =>
                {
                    let index = firstLanguage?.length + indexFromArray;
                    return(
                        line.lineType === 0 ? <BasicTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> : 
                            line.lineType === 1 ? <OutfitTranslationLine1 key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                line.lineType === 2 ? <OutfitTranslationLine2 key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                    line.lineType === 3 ? <OutfitTranslationLine3 key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                        line.lineType === 4 ? <QuestionTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                            line.lineType === 5 ? <AnswerTranslationLine key={index} line={line} index={index} inputLine={false}  storyId={storyId} serialNumber={serialNumber} language={language} isBranchAnswer={false} isPreview={false}/> :
                                                line.lineType === 6 ? <CinematicTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                    line.lineType === 7 ? <Customization1TranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                        line.lineType === 8 ? <Customization2TranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                            line.lineType === 9 ? <BranchTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                                line.lineType === 10 ? <AnswerTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isBranchAnswer={true} isPreview={false}/> :
                                                                    line.lineType === 11 ? <GotoMainTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                                        line.lineType === 12 ? <EndOfLevelTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                                            line.lineType === 13 ? <TimedChoiceTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                                                line.lineType === 14 ? <TimedChoiceAnswerTranslationLine key={index} line={line} index={index} inputLine={false}  storyId={storyId} serialNumber={serialNumber} language={language} isBranchAnswer={false} isPreview={false}/> :
                                                                                    line.lineType === 15 ? <VisualChoiceTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                                                        line.lineType === 16 ? <VisualChoiceAnswerTranslationLine key={index} line={line} index={index} inputLine={false}  storyId={storyId} serialNumber={serialNumber} language={language} isBranchAnswer={false} isPreview={false}/> :
                                                                                            line.lineType === 17 ? <FashionTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/>  
                        : <div/>)
                }
                )}
                {selectedLineIndex != -1 
                    ? 
                    <SelectedLine 
                        currentLine={nativeLanguage == 0 ? currentLineEnglish : currentLineSerbian} 
                        selectedLineIndex={selectedLineIndex + firstLanguage?.length} 
                        inputLine={!thirdLanguage[0]?.isDefault ? false : true}
                        storyId={storyId} 
                        serialNumber={serialNumber} 
                        language={language} 
                    /> 
                    : ''}
            </div> : '' }
            { !thirdLanguage[0]?.isDefault ? 
            <div className="column-style edit-episode-header mt-4">
                <h6 className="lang-label">Language: {otherLanguage?.label}
                    <CTooltip placement="top" content="Preview all lines">
                        <CButton onClick={() => {
                            history.push({
                                pathname: '/translations/preview-lines',
                                state: 
                                { 
                                    lines: thirdLanguage,
                                    storyId,
                                    serialNumber,
                                    language
                                }
                            })
                        }}>
                            <VscPreview size={24} color="blue"/>
                        </CButton>
                    </CTooltip>
                </h6>
                {
                    placeholderLines?.map((line: any, index: any) => <PlaceholderLine key={-index-2}/>)
                }
                {loadingForScript ? <Loader updatingBool={loadingForScript}/> : thirdLanguage?.slice(linesStartIndex, linesStartIndex + linesFrame - 1 - (placeholderLines != null ? placeholderLines.length : 0))?.map((line: any, indexFromArray: any) =>
                {
                    let index = secondLanguage?.length + firstLanguage?.length + indexFromArray;
                    
                    return(
                        line.lineType === 0 ? <BasicTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> : 
                            line.lineType === 1 ? <OutfitTranslationLine1 key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                line.lineType === 2 ? <OutfitTranslationLine2 key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                    line.lineType === 3 ? <OutfitTranslationLine3 key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                        line.lineType === 4 ? <QuestionTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                            line.lineType === 5 ? <AnswerTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isBranchAnswer={false} isPreview={false}/> :
                                                line.lineType === 6 ? <CinematicTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                    line.lineType === 7 ? <Customization1TranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                        line.lineType === 8 ? <Customization2TranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                            line.lineType === 9 ? <BranchTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                                line.lineType === 10 ? <AnswerTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isBranchAnswer={true} isPreview={false}/> :
                                                                    line.lineType === 11 ? <GotoMainTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> : 
                                                                        line.lineType === 12 ? <EndOfLevelTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                                            line.lineType === 13 ? <TimedChoiceTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                                                line.lineType === 14 ? <TimedChoiceAnswerTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isBranchAnswer={false} isPreview={false}/>:
                                                                                    line.lineType === 15 ? <VisualChoiceTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/> :
                                                                                        line.lineType === 16 ? <VisualChoiceAnswerTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isBranchAnswer={false} isPreview={false}/> :
                                                                                            line.lineType === 17 ? <FashionTranslationLine key={index} line={line} index={index} inputLine={false} storyId={storyId} serialNumber={serialNumber} language={language} isPreview={false}/>  
                        : <div/>)
                }
                )}
                {!thirdLanguage[0]?.isDefault && selectedLineIndex != -1
                    ? <SelectedLine 
                        currentLine={currentLineOther} 
                        selectedLineIndex={selectedLineIndex + firstLanguage?.length + secondLanguage?.length} 
                        inputLine={true}
                        storyId={storyId} 
                        serialNumber={serialNumber} 
                        language={language} 
                    /> 
                    : ''}
            </div> : '' }
        </div>)
}

export default ScriptBody;