import { CDataTable, CSpinner } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { AiOutlineExport } from 'react-icons/ai';
import { ExportEpisode, GetEpisodesForTasksExport } from '../../services/StoriesServices';
import { useAlert } from 'react-alert'; 
import { contentForChangeEpisodeStatus } from '../..';
import { useDispatch } from 'react-redux';
import { ActionCreators } from '../../store/StoriesReducer';

const fields = [
    { key: 'episodeTitle', label: 'Episode Title', _style: { width: '70%'} },
    { key: 'allowExport', label: 'Export Allowed', _style: { width: '20%'} },
    { key: 'export', label: '', _style: { width: '10%'} }
]

export const SetupStoryExport = ({storyId, storyTranslationId} : any) =>
{
    const dispatch = useDispatch();

    const alert = useAlert(contentForChangeEpisodeStatus);

    const [loading, setLoading] = useState(false);
    const [episodes, setEpisodes] = useState<any[]>([]);

    useEffect(() => 
    {
        setLoading(true);
        GetEpisodesForTasksExport(storyId, storyTranslationId).then((response : any) => { setEpisodes(response.map((element : any, index : number) => { return {...element, id: index}})); setLoading(false); });
    }, [])

    return(
    <div className="mt-4 register-signin-left-panel col-sm-12 rounded shadow setup-stories-info d-none d-md-none d-lg-block ">
        <h5>Export</h5>
        <div className="info-form-container">
            <div className="column-style setup-story-info-top">
                <CDataTable
                    loading={loading}
                    items={episodes}
                    fields={fields}
                    itemsPerPageSelect
                    itemsPerPage={10}
                    hover
                    pagination
                    scopedSlots={{
                        'export':
                        (item : any) => (
                            <td className="center">
                                { item.allowExport ? 
                                <AiOutlineExport size={21} className="mr-3 action-icons cursor" onClick={() => 
                                {
                                    dispatch(ActionCreators.setSetupStoryExportLoading(true));
                                    ExportEpisode(storyId, storyTranslationId, item.episodeSerialNumber, alert, episodes, setEpisodes)
                                    .then(() => {
                                        dispatch(ActionCreators.setSetupStoryExportLoading(false));
                                    })
                                }}/> : '' }
                            </td>
                        )
                    }}
                /> 
            </div>
        </div>
    </div>)
}

export default SetupStoryExport;