import React, { useEffect, useState } from 'react';
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react';
import ArtCatalogTabPane from '../Common/ArtCatalogTabPane';
import { AiOutlinePicture } from 'react-icons/ai';
import Characters from '../Common/ArtCatalogHeaders/Characters';
import Hairstyles from '../Common/ArtCatalogHeaders/Hairstyles';
import Clothes from '../Common/ArtCatalogHeaders/Clothes';
import Accessories from '../Common/ArtCatalogHeaders/Accessories';
import Backgrounds from '../Common/ArtCatalogHeaders/Backgrounds';
import Cinematics from '../Common/ArtCatalogHeaders/Cinematics';
import Audio from '../Common/ArtCatalogHeaders/Audio';
import { GetAllStoriesForHeader, GetEpisodesForHeader } from '../../services/StoriesServices';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorsForArtCatalog } from '../../store/ArtCatalogReducer';
import { GetAllStoriesForArtCatalog, GetAssets, GetEpisodesForArtCatalog, GetSelectValuesForAssets } from '../../services/ArtCatalog';
import { ApplyFiltersSessionLogger, ArtCatalogTabChangeSessionLogger } from '../../modules/firebaseDetailedLoggers';

export const ArtCatalog = () =>
{
    const dispatch = useDispatch();
    const [activeKey, setActiveKey] = useState(1)
    
    const { characters, bodyTypes, hairstyles, clothes, accessories, avatars, backgrounds, cinematics, audio, loadingImages, stories, episodes } = useSelector((state: any) => state.artCatalogReducer);

    const [page, setPage] = useState(0);

    useEffect(() => 
    {
        document.title = 'Story Maker - Art Catalog'
        dispatch(ActionCreatorsForArtCatalog.setLoadingImages(true));
        GetAssets('api/ArtCatalog/GetAssets_Characters', { storyId: null, episodeId: null, type: null, bodyType: null, skinColor: null, display: null, searchValue: null }).then((response) => { dispatch(ActionCreatorsForArtCatalog.setCharacters(response)) })
        GetAllStoriesForArtCatalog().then((response) => 
        { 
            dispatch(ActionCreatorsForArtCatalog.setStories(response))
            if(response.length != 0)
            {
                GetEpisodesForArtCatalog(response[0].id, response[0].value).then((response) => 
                { 
                    dispatch(ActionCreatorsForArtCatalog.setEpisodes(response))
                });
            }
        })
    }, [])   
    
    return(
        <div className="mt-3">
            <div className="page-title-container">
                <div className="page-title-icon"><AiOutlinePicture color="#745DEC" size={26} className="icon-margin" /></div><div className="page-title">Art Catalog</div>
                {/*<div className="page-subtitle">Lorem ipsum dolor sit amet</div>*/}
            </div>
            <CNav variant="tabs" className="table-width dashboard art-catalog-tabs-container">
                <CNavItem>
                    <CNavLink className="nav-link-art-catalog" active={activeKey === 1} onClick={() => 
                    { 
                        setActiveKey(1);

                        const artCatalogTabChangeSession = new ArtCatalogTabChangeSessionLogger();

                        artCatalogTabChangeSession.asset_name = 'Characters'
                        artCatalogTabChangeSession.timestamp = new Date().toUTCString();
                        artCatalogTabChangeSession.session_id = localStorage.getItem('sessionId') ?? '';
                        artCatalogTabChangeSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                        artCatalogTabChangeSession.user_id = localStorage.getItem('uid') ?? '';

                        artCatalogTabChangeSession.logEvent();
                        
                        if(characters?.length === 0)
                        {
                            dispatch(ActionCreatorsForArtCatalog.setLoadingImages(true));
                            GetAssets('api/ArtCatalog/GetAssets_Characters', { storyId: null, episodeId: null, type: null, bodyType: null, skinColor: null, display: null, searchValue: null }).then((response) => 
                            { 
                                dispatch(ActionCreatorsForArtCatalog.setCharacters(response)) 
                            })
                        }
                    }}>Characters</CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink className="nav-link-art-catalog" active={activeKey === 2} onClick={() => 
                    { 
                        setActiveKey(2);

                        const artCatalogTabChangeSession = new ArtCatalogTabChangeSessionLogger();

                        artCatalogTabChangeSession.asset_name = 'Body Types'
                        artCatalogTabChangeSession.timestamp = new Date().toUTCString();
                        artCatalogTabChangeSession.session_id = localStorage.getItem('sessionId') ?? '';
                        artCatalogTabChangeSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                        artCatalogTabChangeSession.user_id = localStorage.getItem('uid') ?? '';

                        artCatalogTabChangeSession.logEvent();

                        if(bodyTypes?.length === 0)
                        {
                            dispatch(ActionCreatorsForArtCatalog.setLoadingImages(true));
                            GetAssets('api/ArtCatalog/GetAssets_BodyTypes', { storyId: null, episodeId: null, hairstyleType: null, hairstyleAttribute: null, searchValue: null }).then((response) => 
                            { 
                                dispatch(ActionCreatorsForArtCatalog.setBodyTypes(response)) 
                            })
                        }
                    }}>Body Types</CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink className="nav-link-art-catalog" active={activeKey === 3} onClick={() => 
                    { 
                        setActiveKey(3);
                        
                        const artCatalogTabChangeSession = new ArtCatalogTabChangeSessionLogger();

                        artCatalogTabChangeSession.asset_name = 'Hairstyles'
                        artCatalogTabChangeSession.timestamp = new Date().toUTCString();
                        artCatalogTabChangeSession.session_id = localStorage.getItem('sessionId') ?? '';
                        artCatalogTabChangeSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                        artCatalogTabChangeSession.user_id = localStorage.getItem('uid') ?? '';

                        artCatalogTabChangeSession.logEvent();

                        if(hairstyles?.length === 0)
                        {
                            dispatch(ActionCreatorsForArtCatalog.setLoadingImages(true));
                            GetAssets('api/ArtCatalog/GetAssets_Hairstyles', { storyId: null, episodeId: null, hairstyleType: null, hairstyleAttribute: null, searchValue: null }).then((response) => 
                            { 
                                dispatch(ActionCreatorsForArtCatalog.setHairstyles(response)) 
                            })
                        }
                    }}>Hairstyles</CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink className="nav-link-art-catalog" active={activeKey === 4} onClick={() => 
                    { 
                        setActiveKey(4);

                        const artCatalogTabChangeSession = new ArtCatalogTabChangeSessionLogger();

                        artCatalogTabChangeSession.asset_name = 'Clothes'
                        artCatalogTabChangeSession.timestamp = new Date().toUTCString();
                        artCatalogTabChangeSession.session_id = localStorage.getItem('sessionId') ?? '';
                        artCatalogTabChangeSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                        artCatalogTabChangeSession.user_id = localStorage.getItem('uid') ?? '';

                        artCatalogTabChangeSession.logEvent();

                        if(clothes?.length === 0)
                        {
                            dispatch(ActionCreatorsForArtCatalog.setLoadingImages(true));
                            GetAssets('api/ArtCatalog/GetAssets_Clothes', { storyId: null, episodeId: null, clothesType: null, attribute: null, searchValue: null }).then((response) => 
                            { 
                                dispatch(ActionCreatorsForArtCatalog.setClothes(response)) 
                            })
                        }
                    }}>Clothes</CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink className="nav-link-art-catalog" active={activeKey === 5} onClick={() => 
                    { 
                        setActiveKey(5);

                        const artCatalogTabChangeSession = new ArtCatalogTabChangeSessionLogger();

                        artCatalogTabChangeSession.asset_name = 'Accessories'
                        artCatalogTabChangeSession.timestamp = new Date().toUTCString();
                        artCatalogTabChangeSession.session_id = localStorage.getItem('sessionId') ?? '';
                        artCatalogTabChangeSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                        artCatalogTabChangeSession.user_id = localStorage.getItem('uid') ?? '';

                        artCatalogTabChangeSession.logEvent();

                        if(accessories?.length === 0)
                        {
                            dispatch(ActionCreatorsForArtCatalog.setLoadingImages(true));
                            GetAssets('api/ArtCatalog/GetAssets_Accessories', { storyId: null, episodeId: null, type: null, searchValue: null }).then((response) => 
                            { 
                                dispatch(ActionCreatorsForArtCatalog.setAccessories(response)) 
                            })
                        }
                    }}>Accessories</CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink className="nav-link-art-catalog" active={activeKey === 6} onClick={() =>  
                    {
                        setActiveKey(6);

                        const artCatalogTabChangeSession = new ArtCatalogTabChangeSessionLogger();

                        artCatalogTabChangeSession.asset_name = 'Avatars'
                        artCatalogTabChangeSession.timestamp = new Date().toUTCString();
                        artCatalogTabChangeSession.session_id = localStorage.getItem('sessionId') ?? '';
                        artCatalogTabChangeSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                        artCatalogTabChangeSession.user_id = localStorage.getItem('uid') ?? '';

                        artCatalogTabChangeSession.logEvent();

                        if(avatars?.length === 0)
                        {
                            dispatch(ActionCreatorsForArtCatalog.setLoadingImages(true));
                            GetAssets('api/ArtCatalog/GetAssets_Avatars', {}).then((response) => 
                            { 
                                dispatch(ActionCreatorsForArtCatalog.setAvatars(response)) 
                            })
                        }
                    }}>Avatars</CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink className="nav-link-art-catalog" active={activeKey === 7} onClick={() => 
                    { 
                        setActiveKey(7);
                        
                        const artCatalogTabChangeSession = new ArtCatalogTabChangeSessionLogger();

                        artCatalogTabChangeSession.asset_name = 'Backgrounds'
                        artCatalogTabChangeSession.timestamp = new Date().toUTCString();
                        artCatalogTabChangeSession.session_id = localStorage.getItem('sessionId') ?? '';
                        artCatalogTabChangeSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                        artCatalogTabChangeSession.user_id = localStorage.getItem('uid') ?? '';

                        artCatalogTabChangeSession.logEvent();

                        if(backgrounds?.length === 0)
                        {
                            dispatch(ActionCreatorsForArtCatalog.setLoadingImages(true));
                            GetAssets('api/ArtCatalog/GetAssets_Backgrounds', { storyId: null, episodeId: null, interio: null, exterio: null, searchValue: null }).then((response) => 
                            { 
                                dispatch(ActionCreatorsForArtCatalog.setBackgrounds(response)) 
                            })
                        }
                    }}>Backgrounds</CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink className="nav-link-art-catalog" active={activeKey === 8} onClick={() => 
                    { 
                        setActiveKey(8);
                        
                        const artCatalogTabChangeSession = new ArtCatalogTabChangeSessionLogger();

                        artCatalogTabChangeSession.asset_name = 'Cinematics'
                        artCatalogTabChangeSession.timestamp = new Date().toUTCString();
                        artCatalogTabChangeSession.session_id = localStorage.getItem('sessionId') ?? '';
                        artCatalogTabChangeSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                        artCatalogTabChangeSession.user_id = localStorage.getItem('uid') ?? '';

                        artCatalogTabChangeSession.logEvent();

                        if(cinematics?.length === 0)
                        {
                            dispatch(ActionCreatorsForArtCatalog.setLoadingImages(true));
                            GetAssets('api/ArtCatalog/GetAssets_Cinematics', { storyId: null, episodeId: null, location: null, object: null, plot: null, searchValue: null }).then((response) => 
                            { 
                                dispatch(ActionCreatorsForArtCatalog.setCinematics(response)) 
                            })
                        }
                    }}>Cinematics</CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink className="nav-link-art-catalog" active={activeKey === 9} onClick={() => 
                    { 
                        setActiveKey(9);
                        
                        const artCatalogTabChangeSession = new ArtCatalogTabChangeSessionLogger();

                        artCatalogTabChangeSession.asset_name = 'Audio'
                        artCatalogTabChangeSession.timestamp = new Date().toUTCString();
                        artCatalogTabChangeSession.session_id = localStorage.getItem('sessionId') ?? '';
                        artCatalogTabChangeSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                        artCatalogTabChangeSession.user_id = localStorage.getItem('uid') ?? '';

                        artCatalogTabChangeSession.logEvent();

                        if(audio?.length === 0)
                        {
                            dispatch(ActionCreatorsForArtCatalog.setLoadingImages(true));
                            GetAssets('api/ArtCatalog/GetAssets_Audios', { storyId: null, episodeId: null, type: null, shortSoundTag: null, musicThemeTag: null, searchValue: null }).then((response) => 
                            { 
                                dispatch(ActionCreatorsForArtCatalog.setAudio(response)) 
                            })
                        }
                    }}>Audio</CNavLink>
                </CNavItem>
            </CNav>

            {/* Komponente za ART katalog */}
            <CTabContent className="table-width dashboard art-catalog-tabs-container">
                <CTabPane className="tab-pan-art-catalog" active={activeKey === 1}>
                    <ArtCatalogTabPane header={<Characters setPage={setPage} data={characters} activeKey={activeKey}/>} page={page} setPage={setPage} data={characters} loading={loadingImages} name="characters"/>
                </CTabPane>
                <CTabPane className="tab-pan-art-catalog" active={activeKey === 2}>
                    <ArtCatalogTabPane header={<div/>} page={page} setPage={setPage} data={bodyTypes} loading={loadingImages} name="bodytypes"/>
                </CTabPane>
                <CTabPane className="tab-pan-art-catalog" active={activeKey === 3}>
                    <ArtCatalogTabPane header={<Hairstyles setPage={setPage} data={hairstyles} activeKey={activeKey}/>} page={page} setPage={setPage}  data={hairstyles} loading={loadingImages} name="hairstyles"/>
                </CTabPane>
                <CTabPane className="tab-pan-art-catalog" active={activeKey === 4}>
                    <ArtCatalogTabPane header={<Clothes setPage={setPage} data={clothes} activeKey={activeKey}/>} page={page} setPage={setPage} data={clothes} loading={loadingImages} name="clothes"/>
                </CTabPane>
                <CTabPane className="tab-pan-art-catalog" active={activeKey === 5}>
                    <ArtCatalogTabPane header={<Accessories setPage={setPage} data={accessories} activeKey={activeKey}/>} page={page} setPage={setPage} data={accessories} loading={loadingImages} name="accessories"/>
                </CTabPane>
                <CTabPane className="tab-pan-art-catalog" active={activeKey === 6}>
                    <ArtCatalogTabPane header={<div/>} page={page} setPage={setPage} data={avatars} loading={loadingImages} name="avatars"/>
                </CTabPane>
                <CTabPane className="tab-pan-art-catalog" active={activeKey === 7}>
                    <ArtCatalogTabPane header={<Backgrounds setPage={setPage} data={backgrounds} activeKey={activeKey}/>} page={page} setPage={setPage} data={backgrounds} loading={loadingImages} name="backgrounds"/>
                </CTabPane>
                <CTabPane className="tab-pan-art-catalog" active={activeKey === 8}>
                    <ArtCatalogTabPane header={<Cinematics setPage={setPage} data={cinematics} activeKey={activeKey}/>} page={page} setPage={setPage} data={cinematics} loading={loadingImages} name="cinematics"/>
                </CTabPane>
                <CTabPane className="tab-pan-art-catalog" active={activeKey === 9}>
                    <ArtCatalogTabPane header={<Audio setPage={setPage} data={audio} activeKey={activeKey}/>} page={page} setPage={setPage} data={audio} loading={loadingImages} name="audio"/>
                </CTabPane>
            </CTabContent>
        </div>
    )
}

export default ArtCatalog;