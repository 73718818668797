import React, { useEffect, useState } from 'react';
import { CDataTable, CTooltip } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlinePaperClip, AiOutlineFileSearch, AiOutlineFileSync } from 'react-icons/ai';
import { useHistory } from 'react-router';
import { useAlert } from 'react-alert';
import { GetEpisodesForTranslations } from '../../services/EpisodeServices';
import { TranslationsEpisodeSelectionSessionLogger } from '../../modules/firebaseDetailedLoggers';

export const Translations = ({translation} : any) =>
{
    const history = useHistory();
    const dispatch = useDispatch();
    const alert = useAlert();

    const [loading, setLoading] = useState(false);
    
    //reducer state for episodes
    const data = useSelector((state: any) => state.translationsReducer.translationEpisodes);

    //table columns
    const columns = 
    [
        {
            key: 'title',      
            sorter: false
        },
        {
            key: 'episodeStatusOverall',    
            label: 'Status',  
            sorter: false
        },
        {
            key: 'translationDueDate',
            label: 'Translation Due',
            sorter: false
        },
        {
            key: 'proofreadDueDate',
            label: 'Proofread Due',
            sorter: false
        },
        {
            key: 'releaseDate',
            sorter: false
        },
        {
            key: 'edit',
            sorter: false
        },
    ]
    
    useEffect(() =>
    {
        setLoading(true); 
        GetEpisodesForTranslations(translation.id, translation.translationId, dispatch, alert).then(() => { setLoading(false) })
    }, [translation.id, translation.translationId])

    return(
        <div className="mt-3 column-style">
            <div className="register-signin-left-panel col-sm-12 rounded shadow stories"> 
                <CDataTable
                    addTableClasses="episodes-translation"
                    loading={loading}
                    items={data}
                    fields={columns}
                    tableFilter
                    itemsPerPageSelect
                    itemsPerPage={10}
                    striped 
                    hover
                    sorter
                    pagination
                    responsive={true}
                    scopedSlots = {{
                'title': (item: any, index: any) =>
                {
                    return(
                        <td className="py-2">
                            <p 
                                className="cursor"
                                onMouseDown={(e : any) => { if(e.button == 1)
                                {
                                    e.preventDefault();
                                    if(item.episodeStatusOverallEnum >= 4)
                                    {
                                        const translationsEpisodeSelectionSession = new TranslationsEpisodeSelectionSessionLogger();
                                        
                                        translationsEpisodeSelectionSession.episode_name = item.title ?? '';
                                        translationsEpisodeSelectionSession.timestamp = new Date().toUTCString();
                                        translationsEpisodeSelectionSession.session_id = localStorage.getItem('sessionId') ?? '';
                                        translationsEpisodeSelectionSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                                        translationsEpisodeSelectionSession.user_id = localStorage.getItem('uid') ?? '';

                                        translationsEpisodeSelectionSession.logEvent();

                                        window.open(`/translations-translate/&storyId=${item.storyId}&storyTransId=${item.storyTranslationId}&episodeId=${item.id}`, '_blank')
                                    }
                                }} } 
                                onClick={() => 
                                { 
                                    if(item.episodeStatusOverallEnum >= 4)
                                    {
                                        const translationsEpisodeSelectionSession = new TranslationsEpisodeSelectionSessionLogger();
                                        
                                        translationsEpisodeSelectionSession.episode_name = item.title ?? '';
                                        translationsEpisodeSelectionSession.timestamp = new Date().toUTCString();
                                        translationsEpisodeSelectionSession.session_id = localStorage.getItem('sessionId') ?? '';
                                        translationsEpisodeSelectionSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                                        translationsEpisodeSelectionSession.user_id = localStorage.getItem('uid') ?? '';

                                        translationsEpisodeSelectionSession.logEvent();

                                        history.push(`/translations-translate/&storyId=${item.storyId}&storyTransId=${item.storyTranslationId}&episodeId=${item.id}`) 
                                    }
                                }}>{item.title}</p>
                        </td>)
                },
                'translationDueDate': (item: any, index: any) =>
                {
                    return(
                        <td className="py-2">
                            {item.translationDueDate ? <p>{item.translationDueDate}</p> : 'N/A'}
                        </td>)
                },
                'proofreadDueDate': (item: any, index: any) =>
                {
                    return(
                        <td className="py-2">
                            {item.proofreadDueDate ? <p>{item.proofreadDueDate}</p> : 'N/A'}
                        </td>)
                },
                'releaseDate': (item: any, index: any) =>
                {
                    return(
                        <td className="py-2">
                            {item.releaseDate ? <p>{item.releaseDate}</p> : 'N/A'}
                        </td>)
                },
                'edit':
                (item : any, index : any) => 
                {
                    return(
                        <td className="center py-2">
                            { item.episodeStatusOverallEnum >= 4 ? 
                                <CTooltip placement="top" content="Translate/Proofread">
                                    <AiOutlineFileSync size={21} className="action-icons mr-3 cursor" 
                                        onMouseDown={(e : any) => { if(e.button == 1) 
                                        {
                                            e.preventDefault();

                                            const translationsEpisodeSelectionSession = new TranslationsEpisodeSelectionSessionLogger();
                                            
                                            translationsEpisodeSelectionSession.episode_name = item.title ?? '';
                                            translationsEpisodeSelectionSession.timestamp = new Date().toUTCString();
                                            translationsEpisodeSelectionSession.session_id = localStorage.getItem('sessionId') ?? '';
                                            translationsEpisodeSelectionSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                                            translationsEpisodeSelectionSession.user_id = localStorage.getItem('uid') ?? '';
    
                                            translationsEpisodeSelectionSession.logEvent();

                                            window.open(`/translations-translate/&storyId=${item.storyId}&storyTransId=${item.storyTranslationId}&episodeId=${item.id}`, '_blank')
                                        }} } 
                                    onClick={() => 
                                    { 
                                        const translationsEpisodeSelectionSession = new TranslationsEpisodeSelectionSessionLogger();
                                        
                                        translationsEpisodeSelectionSession.episode_name = item.title ?? '';
                                        translationsEpisodeSelectionSession.timestamp = new Date().toUTCString();
                                        translationsEpisodeSelectionSession.session_id = localStorage.getItem('sessionId') ?? '';
                                        translationsEpisodeSelectionSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                                        translationsEpisodeSelectionSession.user_id = localStorage.getItem('uid') ?? '';

                                        translationsEpisodeSelectionSession.logEvent();

                                        history.push(`/translations-translate/&storyId=${item.storyId}&storyTransId=${item.storyTranslationId}&episodeId=${item.id}`) 
                                    }}/>
                                </CTooltip>
                                : 
                                <CTooltip placement="top" content="Translate/Proofread is not available for episodes in the following statuses: Created, In progress, In review and Correction required">
                                    <AiOutlineFileSync size={21} className="action-icons mr-3" color="gray"/> 
                                </CTooltip>
                            }
                        </td>
                    )
                }
            }}
                />
                </div>
    </div>)
}

export default Translations;