import React, { useEffect, useState } from 'react';
import { CButton, CInput, CLabel } from '@coreui/react';
import { AiOutlinePicCenter, AiOutlinePicRight, AiOutlineSearch, AiOutlineTranslation, AiOutlineException } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { GetEpisodesForTranslationsSelect } from '../../../services/EpisodeServices';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeEpisodeStatusForTranslate, GetPossibleStatusesForTranslate } from '../../../services/TranslationsServices';
import { useAlert } from 'react-alert';
import { ActionCreatorsForTranslations } from '../../../store/TranslationsReducer';
import { contentForChangeEpisodeStatus } from '../../..';
import { TranslationsEpisodeDropdownSessionLogger, TranslationsFindTextSessionLogger, TranslationsStoryDropdownSessionLogger } from '../../../modules/firebaseDetailedLoggers';

export const TranslationsHeader = ({ 
    stories, episodes, chosenStory, 
    chosenEpisode, setStories, setEpisodes, 
    setChosenStory, setChosenEpisode, serialNumber, language, 
    loadingForScript, setLoadingForScript, loadingForAppendix, setLoadingForAppendix,
    languageData, setLanguageData, nativeLanguage,
    serbianTranslate,
    englishTranslate,
    otherLanguageTranslate
} : any) =>
{
    const dispatch = useDispatch();
    const alert = useAlert();
    const alertWithClose = useAlert(contentForChangeEpisodeStatus);
    const history = useHistory();

    const statuses = useSelector((state: any) => state.translationsReducer.translationStatuses);
    const currentTranslationStatus = useSelector((state: any) => state.translationsReducer.currentTranslationStatus);

    const [text, setText] = useState('');
    
    useEffect(() => 
    {
        const timeOutId = setTimeout(() => 
        {
            let filteredLinesSerbian = serbianTranslate?.map((l: any, i: number) => {
                return {...l, lineIndex: i}
            }).filter((l: any) => 
            {
                if(text && l.text?.toLowerCase().includes(text.toLowerCase()))
                {
                    return l;
                }
            })
            dispatch(ActionCreatorsForTranslations.findTextSerbian(filteredLinesSerbian));

            let filteredLinesEnglish = englishTranslate?.map((l: any, i: number) => {
                return {...l, lineIndex: i}
            }).filter((l: any) => 
            {
                if(text && l.text?.toLowerCase().includes(text.toLowerCase()))
                {
                    return l;
                }
            })
            dispatch(ActionCreatorsForTranslations.findTextEnglish(filteredLinesEnglish));
            
            let filteredLinesOther = otherLanguageTranslate?.map((l: any, i: number) => {
                return {...l, lineIndex: i}
            }).filter((l: any) => 
            {
                if(text && l.text?.toLowerCase().includes(text.toLowerCase()))
                {
                    return l;
                }
            })
            dispatch(ActionCreatorsForTranslations.findTextOther(filteredLinesOther));

            if(text)
            {
                const findTextSession = new TranslationsFindTextSessionLogger();
            
                findTextSession.query_string = text ?? '';
                findTextSession.timestamp = new Date().toUTCString();
                findTextSession.session_id = localStorage.getItem('sessionId') ?? '';
                findTextSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                findTextSession.user_id = localStorage.getItem('uid') ?? '';

                findTextSession.logEvent();
            }

        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [text])
    
    return(
    <div className="row-style row register-signin-left-panel top-search-translate mb-2">
        <div className="form-group-name trans-story-cont col story-name-trans">
            <label htmlFor="register_author_firstname" className="label-info-box"><AiOutlinePicRight size="21" />&nbsp;Story</label>
            <Select
                options={stories}
                value={chosenStory}
                onChange={(value : any) => 
                { 
                    setLoadingForScript(true);
                    setLoadingForAppendix(true);

                    const translationStoryDropdownSession = new TranslationsStoryDropdownSessionLogger();

                    translationStoryDropdownSession.story_name = value.label ?? '';
                    translationStoryDropdownSession.timestamp = new Date().toUTCString();
                    translationStoryDropdownSession.session_id = localStorage.getItem('sessionId') ?? '';
                    translationStoryDropdownSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                    translationStoryDropdownSession.user_id = localStorage.getItem('uid') ?? '';

                    translationStoryDropdownSession.logEvent();

                    GetEpisodesForTranslationsSelect(value.id, alert).then((response) => 
                    { 
                        setEpisodes(response); 
                        setChosenStory(value);
                        history.push(`/translations-body/&storyId=${value.id}&serialNumber=1&language=${value.language}&nativeLanguage=${value.language}`)
                        setLoadingForScript(false);
                        setLoadingForAppendix(false);
                    })
                }}
                as="select"
                autoComplete="off"
                placeholder="Choose a story..."
                required >
            </Select>
        </div>

        <div className="form-group-name trans-episode-cont episode-name-trans col-sm-2 ml-1">
            <label htmlFor="register_author_firstname " className="label-info-box"><AiOutlinePicCenter size="21" />&nbsp;Episode</label>
            <Select
                options={episodes}
                value={chosenEpisode}
                onChange={(value : any) => 
                { 
                    setChosenEpisode(value);
                    
                    const translationEpisodeDropdownSession = new TranslationsEpisodeDropdownSessionLogger();

                    translationEpisodeDropdownSession.episode_name = value.label ?? '';
                    translationEpisodeDropdownSession.timestamp = new Date().toUTCString();
                    translationEpisodeDropdownSession.session_id = localStorage.getItem('sessionId') ?? '';
                    translationEpisodeDropdownSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                    translationEpisodeDropdownSession.user_id = localStorage.getItem('uid') ?? '';

                    translationEpisodeDropdownSession.logEvent();

                    history.push(`/translations-body/&storyId=${chosenStory.id}&serialNumber=${value.serialNumber}&language=${value.language}&nativeLanguage=${value.language}`);
                }}
                name="episode"
                as="select"
                autoComplete="off"
                placeholder="Enter episode..."
                required>
            </Select>
        </div>
        <div className="form-group-name trans-episode-cont find-trans col ml-1">
            
            <label htmlFor="find register_author_firstname" className="label-info-box"><AiOutlineSearch size="21" />&nbsp;Find</label>
            <CInput
                className="form-control"
                name="find"
                type="text"
                autoComplete="off"
                placeholder="Find text..."
                value={text}
                onChange={(e : any) => { setText(e.target.value) }}
                required>
            </CInput>
        </div>
        <div className="form-group-name trans-episode-cont proofread-trans col-sm-2 ml-1">
            <label htmlFor="register_author_firstname " className="label-info-box"><AiOutlineTranslation size="21" />&nbsp;Proofread Language</label>
            <Select
                options={languageData}
                value={languageData.find((element : any) => element.id == language)}
                onChange={(value : any) => 
                { 
                    history.push(`/translations-body/&storyId=${chosenStory.id}&serialNumber=${serialNumber}&language=${value.id}&nativeLanguage=${nativeLanguage}`);
                }}
                name="episode"
                as="select"
                autoComplete="off"
                placeholder=""
                required>
            </Select>
        </div>
        <div className="form-group-name trans-episode-cont col-sm-2 ml-1">
            <CLabel htmlFor="episode"><AiOutlineException size="21" />&nbsp;Translation Status</CLabel> 
            <Select
                options={statuses}
                value={statuses.find((element : any) => element.value == (currentTranslationStatus ?? 0))}
                onChange={(value : any) => 
                { 
                    if(chosenStory && serialNumber && language)
                    {
                        dispatch(ActionCreatorsForTranslations.setUpdatingBool('updating'))
                        ChangeEpisodeStatusForTranslate(chosenStory.id, serialNumber, language, value.value).then((response) =>
                        {
                            if(response.success)
                            {
                                GetPossibleStatusesForTranslate(chosenStory.id, serialNumber, language, dispatch).then(() => 
                                {
                                    dispatch(ActionCreatorsForTranslations.setUpdatingBool('not updating'))
                                });
                            }
                            else
                            {
                                dispatch(ActionCreatorsForTranslations.setUpdatingBool('not updating'))
                                alertWithClose.info(response?.message);
                            }
                        })
                    }
                    else
                    {
                        alert.info('Please wait until all data is loaded.');
                    }
                }}
                name="episode"
                as="select"
                autoComplete="off"
                required>
            </Select>
        </div>
    </div>)
}

export default TranslationsHeader;