import firebase from "firebase/app"
import 'firebase/analytics'
import { eventNames, wgDetailedSeparator } from '../Constants/firebaseAnalyticsEventNames';

//GENERAL
export class LogoutSessionLogger {
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.logoutClickSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Logout loging error!')
        }
    }
}

//STORIES
export class StorySelectionSessionLogger {
    story_name: string = ''
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_story_name: this.story_name,
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.storySelectionClickSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Story selection loging error!')
        }
    }
}

export class EpisodeSelectionSessionLogger {
    episode_name: string = ''
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_episode_name: this.episode_name,
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.episodeSelectionClickSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Episode selection loging error!')
        }
    }
}

export class StoriesSetupStoryClickSessionLogger {
    story_name: string = ''
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_story_name: this.story_name,
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.storiesSetupStoryClickSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Setup story click loging error!')
        }
    }
}

//EDIT EPISODE
export class StoryDropdownSessionLogger {
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.storyDropdownClickSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Story dropdown click loging error!')
        }
    }
}

export class EpisodeDropdownSessionLogger {
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.episodeDropdownClickSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Episode dropdown click loging error!')
        }
    }
}

export class FindTextSessionLogger {
    query_string: string = ''
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_query_string: this.query_string,
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.findTextSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Find text loging error!')
        }
    }
}

export class EditEpisodeSetupStoryClickSessionLogger {
    story_name: string = ''
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_story_name: this.story_name,
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.editEpisodeSetupStoryClickSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Setup story click loging error!')
        }
    }
}

export class HighlightSessionLogger {
    story_name: string = ''
    episode_name: string = ''
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_story_name: this.story_name,
            wgp_episode_name: this.episode_name,
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.highlightSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Highlight loging error!')
        }
    }
}

export class ResetToDefaultSessionLogger {
    story_name: string = ''
    episode_name: string = ''
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_story_name: this.story_name,
            wgp_episode_name: this.episode_name,
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.resetToDefaultSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Reset to default loging error!')
        }
    }
}

export class UpdateLineSessionLogger {
    story_name: string = ''
    episode_name: string = ''
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_story_name: this.story_name,
            wgp_episode_name: this.episode_name,
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.updateLineSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Update line loging error!')
        }
    }
}

//SETUP STORY
export class TabChangeSessionLogger {
    asset_name: string = ''
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_asset_name: this.asset_name,
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.tabChangeSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Tab change loging error!')
        }
    }
}

export class SetupStoryEditSessionLogger {
    asset_name: string = ''
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_asset_name: this.asset_name,
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.setupStoryEditSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Setup story edit loging error!')
        }
    }
}

export class SetupStoryDeleteSessionLogger {
    asset_name: string = ''
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_asset_name: this.asset_name,
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.setupStoryDeleteSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Setup story delete loging error!')
        }
    }
}

//TRANSLATIONS
export class TranslationsStorySelectionSessionLogger {
    story_name: string = ''
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_story_name: this.story_name,
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.translationsStorySelectionClickSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Translations story selection loging error!')
        }
    }
}

export class TranslationsEpisodeSelectionSessionLogger {
    episode_name: string = ''
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_episode_name: this.episode_name,
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.translationsEpisodeSelectionClickSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Translations episode selection loging error!')
        }
    }
}

export class EditLanguageSessionLogger {
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.editLanguageSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Edit language loging error!')
        }
    }
}

export class UploadSessionLogger {
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.uploadSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Upload loging error!')
        }
    }
}

export class DownloadSessionLogger {
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.downloadSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Download loging error!')
        }
    }
}

export class ScriptAppendixTabSessionLogger {
    tab_name: string = ''
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_tab_name: this.tab_name,
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.scriptAppendixTabSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Script/Appendix tab change loging error!')
        }
    }
}

export class TranslationsStoryDropdownSessionLogger {
    story_name: string = ''
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_story_name: this.story_name,
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.translationsStoryDropdownClickSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Translations story dropdown click loging error!')
        }
    }
}

export class TranslationsEpisodeDropdownSessionLogger {
    episode_name: string = ''
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_episode_name: this.episode_name,
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.translationsEpisodeDropdownClickSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Translations episode dropdown click loging error!')
        }
    }
}

export class TranslationsFindTextSessionLogger {
    query_string: string = ''
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_query_string: this.query_string,
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.translationsFindTextSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Translations find text loging error!')
        }
    }
}

//ART CATALOG
export class ArtCatalogTabChangeSessionLogger {
    asset_name: string = ''
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_asset_name: this.asset_name,
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.artCatalogTabChangeSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Art catalog tab change loging error!')
        }
    }
}

export class ApplyFiltersSessionLogger {
    asset_name: string = ''
    query_string: string = ''
    story_name: string = ''
    episode_name: string = ''
    display?: string = ''
    type?: string = ''
    body?: string = ''
    skin?: string = ''
    tag?: string = ''
    exterior?: string = ''
    interior?: string = ''
    location?: string = ''
    object?: string = ''
    plot?: string = ''
    attribute?: string = ''
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_asset_name: this.asset_name,
            wgp_query_string: this.query_string,
            wgp_story_name: this.story_name,
            wgp_episode_name: this.episode_name,
            wgp_display: this.display,
            wgp_type: this.type,
            wgp_body: this.body,
            wgp_skin: this.skin,
            wgp_tag: this.tag,
            wgp_exterior: this.exterior,
            wgp_interior: this.interior,
            wgp_location: this.location,
            wgp_object: this.object,
            wgp_plot: this.plot,
            wgp_attribute: this.attribute,
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.applyFiltersSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Apply filters loging error!')
        }
    }
}

export class OpenImageSessionLogger {
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.openImageSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Open image loging error!')
        }
    }
}

export class SaveChangesSessionLogger {
    asset_name: string = ''
    timestamp: string = ''
    user_id: string = ''
    session_id: string = ''
    session_number: number = -1

    private environmentPrefix: string | null = ''
    private logEnabled: boolean = false

    constructor(){
        this.environmentPrefix = localStorage.getItem('eventSuffix')
        this.logEnabled = localStorage.getItem('eventEnabled')?.toLocaleLowerCase() === 'true'
    }

    private prepareEventParams(){
        const params = {
            wgp_asset_name: this.asset_name,
            wgp_timestamp: this.timestamp,
            wgp_user_id: this.user_id,
            wgp_session_id: this.session_id,
            wgp_session_number: this.session_number
        }
        return params
    }

    logEvent(){
        try {
            if(this.logEnabled) {
                firebase.analytics().logEvent(wgDetailedSeparator+eventNames.saveChangesSession+this.environmentPrefix, this.prepareEventParams())
            }
        }
        catch(e) {
            console.log('Save changes loging error!')
        }
    }
}