import React, { useEffect, useState } from "react";
import {
  CButton,
  CDataTable,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CTooltip,
} from "@coreui/react";
import { AddAccessoriesModalBody } from "./SetupStoryModels/AddAccessoriesModalBody";
import { AddAudioModalBody } from "./SetupStoryModels/AddAudioModalBody";
import { AddBackgroundsModalBody } from "./SetupStoryModels/AddBackgroundsModalBody";
import { AddCharacterModalBody } from "./SetupStoryModels/AddCharacterModalBody";
import { AddCinematicsModalBody } from "./SetupStoryModels/AddCinematicsModalBody";
import { AddHairstyleModalBody } from "./SetupStoryModels/AddHairstyleModalBody";
import { AddClothesModalBody } from "./SetupStoryModels/AddClothesModalBody";
import { RiDeleteBinFill } from "react-icons/ri";
import { SetupStoryDelete } from "../../services/SetupStoryServices";
import { AiOutlineEdit, AiOutlineCheckSquare, AiOutlineBorder } from "react-icons/ai";
import { useAlert } from 'react-alert';
import { GetAccessoryTypes } from "../../services/EpisodeServices";
import { useDispatch, useSelector } from "react-redux";
import { BsFillExclamationTriangleFill, BsFillExclamationCircleFill } from "react-icons/bs";
import AddVisualChoicesModalBody from "./SetupStoryModels/AddVisualChoicesModalBody";
import AddItemModalBody from "./SetupStoryModels/AddItemModalBody";

import Select from 'react-select'
import { SetupStoryDeleteSessionLogger, SetupStoryEditSessionLogger } from "../../modules/firebaseDetailedLoggers";
import { generateTabNameForLogger } from "./Lines/_Helpers";

export const SetupStoryTable = ({ data, setData, tableLoading, setTableLoading, buttonName, columns, modalBody, headerIcon, baseURL, deleteURL, storyId}: any) => 
{
  const dispatch = useDispatch();
  const alert = useAlert();
  //modal state
  const [visible, setVisible] = useState(false);
  
  const { accessoryTypes } = useSelector((state: any) => state.editEpisodeReducer);

  const [selectedData, setSelectedData] = useState({});

  useEffect(() =>
  {
    if(modalBody === "accessories")
    {
      GetAccessoryTypes(dispatch, alert);
    }
  }, []) 

  const [episodeNames, setEpisodeNames] = useState<any[]>([])
  const [selectedEpisode, setSelectedEpisode] = useState({ id: null, value: null, label: ''})

  useEffect(() => 
  {
    if(data)
    {
      setEpisodeNames(data.slice().reverse()
        .filter((v : any, i : any, a : any) => a.findIndex((t : any) => (t.episode === v.episode && t.episode && v.episode)) === i).reverse()
        .map((content : any, index : any) => { return { id: index, value: index, label: content.episode}}))
    }
  }, [data])

  return (
    <div className="register-signin-left-panel rounded ">
      
      <Select isClearable className="mt-1 setup-story-width-select" options={episodeNames} onChange={(value : any) => { 
        setSelectedEpisode(value) 
      }}/>

      <CDataTable
        items={data}
        loading={tableLoading}
        fields={columns}
        scopedSlots= {{
          'body':
          (item : any, index : any) => 
          {
            if(item.body !== undefined && item.body === true) {
              return(
                <td className="py-2">
                    <div className="row-style ml-4 mt-1">
                        <AiOutlineCheckSquare size={24}/>
                    </div>
                </td>
              )
            }
            else {
              return(
                <td className="py-2">
                    <div className="row-style ml-4 mt-1">
                        <AiOutlineBorder size={24}/>
                    </div>
                </td>
              )
            }
          },
          'paid':
          (item : any) =>
          {
              return(<td className="center"><CButton shape="pill" size="sm" color={item.payed ? 'info' : 'warning'}>{item.payed ? 'Paid' : 'Free'}</CButton></td>)
          },
          'description':
          (item : any) =>
          {
              return(<td>{item.description ?? ""}</td>)
          },
          'accessoryType':
          (item : any) =>
          {
              return(<td>{accessoryTypes.find((element : any) => element.id == item.accessoryType)?.label}</td>)
          },
          'avatar':
          (item : any, index : any) => 
          {
            if(item.avatar !== undefined && item.avatar === true) {
              return(
                <td className="py-2">
                    <div className="row-style ml-4 mt-1">
                        <AiOutlineCheckSquare size={24}/>
                    </div>
                </td>
              )
            }
            else {
              return(
                <td className="py-2">
                    <div className="row-style ml-4 mt-1">
                        <AiOutlineBorder size={24}/>
                    </div>
                </td>
              )
            }
          },
          'edit':
          (item : any, index : any) => 
          {
            if((item.isNarratorCharacter !== undefined && item.isNarratorCharacter === true)
                || (item.isNoneCharacter !== undefined && item.isNoneCharacter === true)) {
              return(
                <td className="py-2">
                    <div className="row-style ml-4 mt-1">
                    N/A
                    </div>
                </td>
              )
            }
            else {
              return(
                  <td className="py-2">
                      <div className="row-style ml-4 mt-1">
                          <AiOutlineEdit size={24} className="action-icons cursor" 
                          onClick={() => { 
                            const setupStoryEditSession = new SetupStoryEditSessionLogger();
                            
                            setupStoryEditSession.asset_name = generateTabNameForLogger(modalBody);
                            setupStoryEditSession.timestamp = new Date().toUTCString();
                            setupStoryEditSession.session_id = localStorage.getItem('sessionId') ?? '';
                            setupStoryEditSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                            setupStoryEditSession.user_id = localStorage.getItem('uid') ?? '';
    
                            setupStoryEditSession.logEvent();

                            setSelectedData(item); 
                            setVisible(true); 
                          }}/>
                          {!item.setup ? <CTooltip content={item.setupErrorMessage ?? "Please finish the setup of this asset."}><BsFillExclamationTriangleFill size={19} color="red" className="ml-3"/></CTooltip> : ''}
                          {(item.setup && !item.setupWarning) ? <CTooltip content={item.setupErrorMessage ?? "Please finish the setup of this asset."}><BsFillExclamationCircleFill size={19} color="orange" className="ml-3"/></CTooltip> : ''}
                      </div>
                  </td>
              )
            }
          },
          'delete': 
          (item: any, index: any) =>
          {
            if((item.isMeCharacter !== undefined && item.isMeCharacter === true) 
                || (item.isNarratorCharacter !== undefined && item.isNarratorCharacter === true)
                || (item.isNoneCharacter !== undefined && item.isNoneCharacter === true)) {
              return(
                <td className="py-2">
                    <div className="row-style ml-3 mt-1">
                    N/A
                    </div>
                </td>
              )
            }
            else {
              return(
                <td className="py-2">
                    <div className="row-style ml-3 mt-1">
                        <RiDeleteBinFill size={24} className="action-icons cursor" onClick={() =>
                        { 
                          const setupStoryDeleteSession = new SetupStoryDeleteSessionLogger();
                          
                          setupStoryDeleteSession.asset_name = generateTabNameForLogger(modalBody);
                          setupStoryDeleteSession.timestamp = new Date().toUTCString();
                          setupStoryDeleteSession.session_id = localStorage.getItem('sessionId') ?? '';
                          setupStoryDeleteSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                          setupStoryDeleteSession.user_id = localStorage.getItem('uid') ?? '';
  
                          setupStoryDeleteSession.logEvent();

                          SetupStoryDelete(item.id, deleteURL, alert, data, setData, modalBody, setTableLoading) 
                        }}/>
                    </div>
                </td>
              )
            }
          },
      }}
        striped
        hover
        sorter
        pagination
        responsive={true}
        tableFilter
        tableFilterValue={selectedEpisode?.label}
        itemsPerPageSelect
        itemsPerPage={10}
      />
      {visible ? (
        <CModal
          show={visible}
          fade
          onClose={() => setVisible(false)}
          closeOnBackdrop={false}
        >
          <CModalHeader closeButton>
            <CModalTitle>
              {headerIcon}&nbsp;&nbsp;{buttonName}
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            {modalBody === "character" ? (
              <AddCharacterModalBody selectedData={selectedData} data={data} setData={setData} setVisible={setVisible} storyId={storyId}/>
            ) : modalBody === "hairstyle" ? (
              <AddHairstyleModalBody selectedData={selectedData} data={data} setData={setData} setVisible={setVisible} storyId={storyId}/>
            ) : modalBody === "clothes" ? (
              <AddClothesModalBody selectedData={selectedData} data={data} setData={setData} setVisible={setVisible} storyId={storyId}/>
            ) : modalBody === "accessories" ? (
              <AddAccessoriesModalBody selectedData={selectedData} data={data} setData={setData} setVisible={setVisible} storyId={storyId}/>
            ) : modalBody === "backgrounds" ? (
              <AddBackgroundsModalBody selectedData={selectedData} data={data} setData={setData} setVisible={setVisible} storyId={storyId}/>
            ) : modalBody === "cinematic" ? (
              <AddCinematicsModalBody selectedData={selectedData} data={data} setData={setData} setVisible={setVisible} storyId={storyId}/>
            ) : modalBody === "audio" ? (
              <AddAudioModalBody selectedData={selectedData} data={data} setData={setData} setVisible={setVisible} storyId={storyId}/>
            ) : modalBody === "visuals" ? (
              <AddVisualChoicesModalBody selectedData={selectedData} data={data} setData={setData} setVisible={setVisible} storyId={storyId}/>
            ) : modalBody === "items" ? (
              <AddItemModalBody selectedData={selectedData} data={data} setData={setData} setVisible={setVisible} storyId={storyId}/>
            ) : (
              ""
            )}
          </CModalBody>
        </CModal>
      ) : (
        <div />
      )}
    </div>
  );
};

export default SetupStoryTable;
