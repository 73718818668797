import React from 'react';
import BasicTranslationLine from './BasicTranslationLine';
import BranchTranslationLine from './BranchTranslationLine';
import CinematicTranslationLine from './CinematicTranslationLine';
import Customization1TranslationLine from './Customization1TranslationLine';
import Customization2TranslationLine from './Customization2TranslationLine';
import GotoMainTranslationLine from './GotoMainTranslationLine';
import EndOfLevelTranslationLine from './LunescapeAdditionalLines/EndOfLevelTranslationLine';
import FashionTranslationLine from './LunescapeAdditionalLines/FashionTranslationLine';
import { OutfitTranslationLine1, OutfitTranslationLine2, OutfitTranslationLine3 } from './OutfitTranslationLine';
import { AnswerTranslationLine, QuestionTranslationLine } from './QuestionAnswerTranslationLine';
import { TimedChoiceAnswerTranslationLine } from './TimedChoiceAnswerTranslationLine';
import { TimedChoiceTranslationLine } from './TimedChoiceTranslationLine';
import { VisualChoiceAnswerTranslationLine } from './VisualChoiceAnswerTranslationLine';
import { VisualChoiceTranslationLine } from './VisualChoiceTranslationLine';

export const SelectedLine = ({ currentLine, selectedLineIndex, inputLine, storyId, serialNumber, language, updatingArray } : any) =>
{
    return(
    <div className="mt-2">
        {currentLine.lineType === 0 ? <BasicTranslationLine line={currentLine} index={selectedLineIndex} inputLine={true} storyId={storyId} serialNumber={serialNumber} language={language} enableInput={inputLine} updatingArray={updatingArray} isPreview={false}/> : 
            currentLine.lineType === 1 ? <OutfitTranslationLine1 line={currentLine} index={selectedLineIndex} inputLine={true} storyId={storyId} serialNumber={serialNumber} language={language} enableInput={inputLine} updatingArray={updatingArray} isPreview={false}/> :
                currentLine.lineType === 2 ? <OutfitTranslationLine2 line={currentLine} index={selectedLineIndex} inputLine={true} storyId={storyId} serialNumber={serialNumber} language={language} enableInput={inputLine} updatingArray={updatingArray} isPreview={false}/> :
                    currentLine.lineType === 3 ? <OutfitTranslationLine3 line={currentLine} index={selectedLineIndex} inputLine={true} storyId={storyId} serialNumber={serialNumber} language={language} enableInput={inputLine} updatingArray={updatingArray} isPreview={false}/> :
                        currentLine.lineType === 4 ? <QuestionTranslationLine line={currentLine} index={selectedLineIndex} inputLine={true} storyId={storyId} serialNumber={serialNumber} language={language} enableInput={inputLine} updatingArray={updatingArray} isPreview={false}/> :
                            currentLine.lineType === 5 ? <AnswerTranslationLine line={currentLine} index={selectedLineIndex} inputLine={true} storyId={storyId} serialNumber={serialNumber} language={language} enableInput={inputLine} updatingArray={updatingArray} isBranchAnswer={false} isPreview={false}/> :
                                currentLine.lineType === 6 ? <CinematicTranslationLine line={currentLine} index={selectedLineIndex} inputLine={true} storyId={storyId} serialNumber={serialNumber} language={language} enableInput={inputLine} updatingArray={updatingArray} isPreview={false}/> :
                                    currentLine.lineType === 7 ? <Customization1TranslationLine line={currentLine} index={selectedLineIndex} inputLine={true} storyId={storyId} serialNumber={serialNumber} language={language} enableInput={inputLine} updatingArray={updatingArray} isPreview={false}/> :
                                        currentLine.lineType === 8 ? <Customization2TranslationLine line={currentLine} index={selectedLineIndex} inputLine={true} storyId={storyId} serialNumber={serialNumber} language={language} enableInput={inputLine} updatingArray={updatingArray} isPreview={false}/> :
                                            currentLine.lineType === 9 ? <BranchTranslationLine line={currentLine} index={selectedLineIndex} inputLine={true} storyId={storyId} serialNumber={serialNumber} language={language} enableInput={inputLine} updatingArray={updatingArray} isPreview={false}/> :
                                                currentLine.lineType === 10 ? <AnswerTranslationLine line={currentLine} index={selectedLineIndex} inputLine={true} storyId={storyId} serialNumber={serialNumber} language={language} enableInput={inputLine} updatingArray={updatingArray} isBranchAnswer={true} isPreview={false}/> :
                                                    currentLine.lineType === 11 ? <GotoMainTranslationLine line={currentLine} index={selectedLineIndex} inputLine={true} storyId={storyId} serialNumber={serialNumber} language={language} enableInput={inputLine} updatingArray={updatingArray} isPreview={false}/> :
                                                        currentLine.lineType === 12 ? <EndOfLevelTranslationLine line={currentLine} index={selectedLineIndex} inputLine={true} storyId={storyId} serialNumber={serialNumber} language={language} enableInput={inputLine} updatingArray={updatingArray} isPreview={false}/> :
                                                            currentLine.lineType === 13 ? <TimedChoiceTranslationLine line={currentLine} index={selectedLineIndex} inputLine={true} storyId={storyId} serialNumber={serialNumber} language={language} enableInput={inputLine} updatingArray={updatingArray} isPreview={false}/> :
                                                                currentLine.lineType === 14 ? <TimedChoiceAnswerTranslationLine line={currentLine} index={selectedLineIndex} inputLine={true} storyId={storyId} serialNumber={serialNumber} language={language} enableInput={inputLine} updatingArray={updatingArray} isBranchAnswer={false} isPreview={false}/> :
                                                                    currentLine.lineType === 15 ? <VisualChoiceTranslationLine line={currentLine} index={selectedLineIndex} inputLine={true} storyId={storyId} serialNumber={serialNumber} language={language} enableInput={inputLine} updatingArray={updatingArray} isPreview={false}/> :
                                                                        currentLine.lineType === 16 ? <VisualChoiceAnswerTranslationLine line={currentLine} index={selectedLineIndex} inputLine={true} storyId={storyId} serialNumber={serialNumber} language={language} enableInput={inputLine} updatingArray={updatingArray} isBranchAnswer={false} isPreview={false}/> :
                                                                            currentLine.lineType === 17 ? <FashionTranslationLine line={currentLine} index={selectedLineIndex} inputLine={true} storyId={storyId} serialNumber={serialNumber} language={language} enableInput={inputLine} updatingArray={updatingArray} isPreview={false}/> :
                                                <div/>}
    </div>)
}

export default SelectedLine;