import React, { useEffect, useState } from 'react';
import { CInputGroup, CForm, CButton } from '@coreui/react';
import { Field, Formik } from 'formik';
import { AddNewLine } from '../../../services/EpisodeServices';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorsForEditEpisode } from '../../../store/EditEpisodeReducer';
import { useAlert } from 'react-alert';
import { LineType } from '../../../interfaces/lineType';
import { createDefaultLineTypeOptions, editEpisodeReadOnlyCell, emotionStyle, FormatObject, validations, getInputLineNumber, validateActionChange, validateEpisodeStatusForDisable, colorLineInsideAnswer, showLineActions, colorLineWithComment, isLineOptionChanged } from './_Helpers';
import _ from 'lodash';
import { DeleteLines } from '../../../services/LineServices';
import { ActionCreators } from '../../../store/StoriesReducer';
import { DeleteMultipleLinesConfirmationModal } from '../../Helpers/YesNoModal';
import '../../../styles/Lines.css'
import { LineTypes } from '../Enums/LineTypes';
import LineSidebar from '../LineSidebar';

const stateWithClearedLineTimedChoiceAnswer = (inputLine: boolean, selectedLineIndex: number, lines: LineType[], line: LineType, index: number, isRenderedByAction: boolean, isModalOpen: boolean, maxLineNumber: number, modalClickedLine: LineType, modalType : string) => 
{
    return (inputLine && selectedLineIndex === -1) || isModalOpen ? 
    { 
        docId: line.docId,
        storyId: line.storyId,
        storyTranslationId: line.storyTranslationId,
        episodeId: line.episodeId,
        lineNumber: getInputLineNumber(lines,line,index,maxLineNumber,isModalOpen, modalType),
        depth: isModalOpen ? (line.depth ?? 0) + ([LineTypes.Q, LineTypes.A, LineTypes.BA, LineTypes.T, LineTypes.TA, LineTypes.V, LineTypes.VA].includes(modalClickedLine.lineType ?? 0) ? 0 : -1) : 0,
        lineType: line.lineType,
        lineFormat: line.lineFormat,
        episodeName: line.episodeName,
        previousLineDocId: line.previousLineDocId,
        episodeSerialNumber: line.episodeSerialNumber,

        characterId: line.characterId,
        characterName: line.characterName,
        characterPath: line.characterPath,
        emotion: line.emotion,
        text: isRenderedByAction ? line.text : undefined,

        animationId: undefined,
        animationName: undefined,
        payed: line.payed,
        answerNumber: line.answerNumber,
        answerQuestionId: line.answerQuestionId,
        questionDocId: undefined,
    
        comment: undefined,
        commentReporterName: undefined,
        commentReporterUID: undefined,
        commentAssignedToName: undefined,
        commentAssignedToUID: undefined,
        commentResolved: undefined,

        musicId: line.musicId,
        musicName: line.musicName,
        musicDescription: line.musicDescription,

        shortSoundEnabled: line.shortSoundEnabled,
        shortSoundId: line.shortSoundId,
        shortSoundName: line.shortSoundName,
        shortSoundDescription: line.shortSoundDescription,

        itemId: line.itemId ?? undefined,
        itemName: line.itemName ?? undefined,
        itemDescription: line.itemDescription ?? undefined,

        avatarEnabled: line.avatarEnabled,
        
        changeIdentityOn: line.changeIdentityOn,
        changeIdentityName: line.changeIdentityName,

        callPhoneEnabled: line.callPhoneEnabled != null ? line.callPhoneEnabled : undefined,
        callSmsEnabled: line.callSmsEnabled != null ? line.callSmsEnabled : undefined,
        callerCharacterId: line.callerCharacterId,
        callerCharacterName: line.callerCharacterName,
        charmingPoint: line.charmingPoint != null ? line.charmingPoint : undefined,
        premiumChoiceText: line.premiumChoiceText != null ? line.premiumChoiceText : undefined,
        premiumChoiceDescription: line.premiumChoiceDescription != null ? line.premiumChoiceDescription : undefined,
        charactersOutfit: line.charactersOutfit,

        backgroundId: line.backgroundId,
        backgroundName: line.backgroundName,
        backgroundDescription: line.backgroundDescription,
    
        clothes1Name: undefined,
        clothes2Name: undefined,
        clothes3Name: undefined,
        clothes4Name: undefined,
        clothes5Name: undefined,
        clothes6Name: undefined,
        hairstyle1Name: undefined,
        hairstyle2Name: undefined,
        hairstyle3Name: undefined,
    
        clothes1Id: undefined,
        clothes2Id: undefined,
        clothes3Id: undefined,
        clothes4Id: undefined,
        clothes5Id: undefined,
        clothes6Id: undefined,
        hairstyle1Id: undefined,
        hairstyle2Id: undefined,
        hairstyle3Id: undefined,
        answerTimedChoiceId: line.answerTimedChoiceId,
        answerTimedChoiceDefault: line.answerTimedChoiceDefault
    } 
    : 
    line
}

export const TimedChoiceAnswer = ({ line, inputLine, index, serialNumber, episodeName, isBranchAnswer, isPreviewInModal, inPreviewIndex }: any) =>
{
    const dispatch = useDispatch();
    const alert = useAlert();

    const { lines, selectedLineIndex, linesFrame, defaultLineTypes, pickedActionColor, isOLineAdded, defaultBranchQandC, defaultLineFormats, pickedColorType,
        initEditEpisodeBody, linesStartIndex, isRenderedByAction, updateCloudBool, maxLineNumber, editEpisodePassParam } = useSelector((state: any) => state.editEpisodeReducer);
    const { modalPopupBool } = useSelector((state: any) => state.storiesReducer);

    let currentLineTypes = createDefaultLineTypeOptions(lines, index, defaultLineTypes, defaultBranchQandC, isOLineAdded, modalPopupBool.isOpen, modalPopupBool.type);

    const [yesnomodal, setYesnomodal] = useState({boolean: false, array: [], nextLineDocId: null})

    const [clearedLine, clearLine] = useState(stateWithClearedLineTimedChoiceAnswer(inputLine, selectedLineIndex, lines, line, index, isRenderedByAction, modalPopupBool.isOpen, maxLineNumber, modalPopupBool.clickedLine, modalPopupBool.type));

    let addLineSchema = yup.object().shape(
    {
        //lineNumber: yup.string(),
        //lineType: yup.string(),
        //characterName: yup.string(),
        //emotion: yup.string(),
        text: yup.string().max(validations.Q.length, validations.Q.text)
    });

    const setupSpanElements = () => 
    {
        let answerNumber = document.querySelector(`span[name="answerNumber${isPreviewInModal ? inPreviewIndex : index}"]`)
        if(answerNumber) 
        {
            answerNumber.innerHTML = lines[index ?? selectedLineIndex]?.answerNumber;
        }
        
        let lineNumber = document.querySelector(`span[name="lineNumber${isPreviewInModal ? inPreviewIndex : index}"]`);
        if(lineNumber) lineNumber.innerHTML = isBranchAnswer ? '-' : line.lineNumber;
        let lineType = document.querySelector(`span[name="lineType${isPreviewInModal ? inPreviewIndex : index}"]`);
        if(lineType) 
        { 
            let types = defaultLineTypes?.filter((x:any) => x.id == line.lineType);
            lineType.innerHTML = types.length > 0 ? (isBranchAnswer ? 'A' : types[0].label) : '';
        }

        let text = document.querySelector(`input[id="text${isPreviewInModal ? inPreviewIndex : index}"]`) as HTMLInputElement;
        if(text) text.value = line.text ?? "";
    }

    useEffect(() => { 
        if(inputLine && !isBranchAnswer && !initEditEpisodeBody && !isRenderedByAction) { 
            let episodeWrapper = document.querySelector('.editEpisodeWrapper');
            if(episodeWrapper) {
                //episodeWrapper.scrollIntoView({block: 'end', behavior: 'smooth'}); 
                let inputText = document.querySelector('input[name="text"]');
                (inputText as HTMLInputElement)?.focus(); 
            }
        }
        if(!inputLine || isBranchAnswer) {
            setupSpanElements()
        }             
    }, [line])

    useEffect(() => 
    {
        clearLine(stateWithClearedLineTimedChoiceAnswer(inputLine, selectedLineIndex, lines, line, index, isRenderedByAction, modalPopupBool.isOpen, maxLineNumber, modalPopupBool.clickedLine, modalPopupBool.type))
    }, [line,maxLineNumber])

    return(
        <Formik validateOnChange={true} validateOnBlur={false} enableReinitialize initialValues={
            {
                lineNumber: line.lineNumber,
                lineType: line.lineType, 
                answerNumber: line.answerNumber, 
                text: modalPopupBool.isOpen && inputLine && modalPopupBool.index === index 
                ? ((modalPopupBool.clickedLine.text === modalPopupBool.currentLine.text) ? "" : modalPopupBool.currentLine.text) 
                : (inputLine && selectedLineIndex === -1 && !isRenderedByAction) ? "" : (line.text ?? "")
            }}
            onSubmit={(values: LineType) => 
            {
                if((!inputLine || isBranchAnswer) && modalPopupBool.index != index)
                {
                    let currentLine = {...lines[index], answerNumber: lines[index].answerNumber + 1};
                    dispatch(ActionCreators.setModalPopupBool({ type: 'normal', isOpen: true, isFirstTimeOpen: true,  index: index, currentLine, clickedLine: currentLine }))
                }
                else if(inputLine && !isBranchAnswer && selectedLineIndex !== -1 && !modalPopupBool.isOpen) 
                { 
                    FormatObject(
                        dispatch, 
                        selectedLineIndex, 
                        linesStartIndex,
                        lines, 
                        { lineToUpdateId: line.docId, lineModel: {...line, text: values.text} }, alert)
                }
                else if(line.changeIdentityOn && !line.changeIdentityName)
                {
                    alert.info('You must fill in the character name in Change Identity, because you have enabled it.')
                }
                else
                { 
                    dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updating'));

                    let properIndex = index;
                    let previousLineDocId = lines[lines.length - 1]?.docId;
                    if (index != null) {
                        let counter = index + 1;
                        previousLineDocId = lines[index]?.docId;
                        while(counter < lines.length && (lines[counter].depth > lines[index].depth))
                        {
                            previousLineDocId = lines[counter]?.docId;
                            properIndex = counter;
                            counter = counter + 1;
                        }
                    }
                    
                    //citamo actions iz reducera, smestamo u objekat i radimo new line
                    values = 
                    {
                        ...clearedLine,
                        ...values,
                        lineNumber: index != null ? index + 2 : (lines[0].isDefault ? 1 : lines.length + 1),       
                        answerNumber: lines[index].answerNumber + 1,
                        episodeName: episodeName,
                        previousLineDocId: previousLineDocId,
                        episodeSerialNumber: serialNumber,                   
                    }

                    var isChangedByAction = validateActionChange(dispatch, values, lines, lines[lines.length-1], true)
                    values = {...values, actionChangeFlag: isChangedByAction}
                    
                    AddNewLine(values, properIndex, linesFrame, selectedLineIndex, dispatch, alert, modalPopupBool.type, modalPopupBool.isOpen)
                }
            }}
            validationSchema={addLineSchema}>
            {({ dirty, isValid, handleSubmit }) => {
                return (
                    <div>
                    <CForm id="add-line-form-submit" className="row" onSubmit={(e : any) => 
                    { 
                        if(!isValid) alert.error('Line you are trying to add is not valid.')
                        if(updateCloudBool === 'updating' || (!modalPopupBool.isOpen && selectedLineIndex !== -1 && e.keyCode == 13)) 
                        {
                            e.preventDefault();
                            return;
                        } 
                        e.preventDefault(); handleSubmit(); 
                    }} disabled={!inputLine || isBranchAnswer}>
                        <CInputGroup className={inputLine && !isBranchAnswer ? 'col border-input-line' : 'col'} style={
                            { 
                                marginLeft: !inputLine || isBranchAnswer ? `${line.depth * parseInt(editEpisodeReadOnlyCell.minWidth.split("px")[0])}px` : "" ,
                                border: (selectedLineIndex === index) ? `4px solid #bdbdbd` : "",
                                padding: "0",
                                paddingLeft: !inputLine || isBranchAnswer ? `4px` : "0"
                            }}
                            onClick={(event: any) => {
                                if (!inputLine && !modalPopupBool.isOpen)
                                    dispatch(ActionCreatorsForEditEpisode.setCurrentLine({currentLine: line, currentLineIndex: index, linesStartIndex: index + 1 > linesFrame ? index + 1 - linesFrame : 0, linesSliceParam: index}))
                            }}>
                            <Field
                                {...(!inputLine || isBranchAnswer ? 
                                    {
                                        as:"span",
                                        title: showLineActions(line),
                                        style: {...editEpisodeReadOnlyCell, backgroundColor: colorLineInsideAnswer(lines, index, pickedColorType, editEpisodeReadOnlyCell)},
                                        name: `lineNumber${isPreviewInModal ? inPreviewIndex : index}`,
                                    } 
                                    : 
                                    {
                                        as: undefined,
                                        name: "lineNumber",
                                        className: 'col-auto',
                                        style: { backgroundColor: "rgb(216, 219, 224)", maxWidth: "50px", minHeight: "35px" }
                                    }
                                )}
                                type="text"
                                value={getInputLineNumber(lines,line,index,maxLineNumber,modalPopupBool.isOpen, modalPopupBool.type)}
                                disabled>
                            </Field>
                            <Field
                                {...(!inputLine || isBranchAnswer ? 
                                {
                                    as:"span",
                                    style: {...editEpisodeReadOnlyCell, backgroundColor: colorLineInsideAnswer(lines, index, pickedColorType, editEpisodeReadOnlyCell)},
                                    name: `lineType${isPreviewInModal ? inPreviewIndex : index}`,
                                    className: "col-auto cursor"
                                } 
                                : 
                                {
                                    as: "select",
                                    id: `lineType${index}`,
                                    name: `lineType`,
                                    className:"col-auto cursor",
                                    value:line.lineType,
                                    onChange:(event: any) => 
                                    {
                                        if(modalPopupBool.isOpen)
                                        {
                                            dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine: {...modalPopupBool.currentLine, lineType: parseInt(event.target.value)}, isFirstTimeOpen: false }))
                                        }
                                        else 
                                        { 
                                            dispatch(ActionCreatorsForEditEpisode.updateLineTypeParam(parseInt(event.target.value))) 
                                        }
                                    }
                                }
                            )}
                                {...((!modalPopupBool.isOpen) && (inputLine && !isBranchAnswer && selectedLineIndex!==-1) ? {style: { backgroundColor: "rgb(216, 219, 224)" }} : {} )}
                                type="text"
                                disabled={ (!modalPopupBool.isOpen) && ( !inputLine || isBranchAnswer || selectedLineIndex !== -1)}>
                                {inputLine && !isBranchAnswer ? currentLineTypes : ''}
                            </Field>
                            <Field
                                {...(!inputLine || isBranchAnswer ? 
                                    {
                                        as:"span",
                                        style: {...editEpisodeReadOnlyCell, backgroundColor: colorLineInsideAnswer(lines, index, pickedColorType, editEpisodeReadOnlyCell)},
                                        name: `answerNumber${isPreviewInModal ? inPreviewIndex : index}`,
                                        className: "col-sm-1 cursor"
                                    } 
                                    : 
                                    {
                                        type: 'text',
                                        className:"col-sm-1 cursor",
                                        style: {...emotionStyle, backgroundColor: "rgb(216, 219, 224)", cursor: 'auto',borderColor: "rgba(118, 118, 118, 0.3)", borderStyle: "solid", borderWidth: "1px", },
                                        name: `answerNumber`,
                                        value: line.answerNumber
                                    }
                                )}
                                type="text"
                                disabled>
                            </Field>
                            <Field type="text" name="text">
                            { ({ 
                                field, 
                                meta: { touched, error } 
                            } : any) => 
                            {
                                //if(error && touched) alert.error('Your line is empty or bigger then expected!');
                                return(
                                    <input 
                                        id={`text${isPreviewInModal ? inPreviewIndex : index}`}
                                        autoComplete="off" 
                                        placeholder="Enter answer."
                                        disabled={!inputLine || isBranchAnswer || validateEpisodeStatusForDisable(editEpisodePassParam.episodeStatusEnum) || line.answerTimedChoiceDefault}  
                                        className={ !isValid ? "validate-color-input col cursor" : line.filtered ? 'filtered-color col cursor"' : "col cursor" } 
                                        style={isBranchAnswer ? editEpisodeReadOnlyCell : undefined}
                                        onBlurCapture={(e : any) => 
                                        {
                                            let value = e.target.value

                                            if(modalPopupBool.isOpen)
                                            {
                                                dispatch(ActionCreators.updateTextInPopup(value))
                                            }
                                            else
                                            {
                                                dispatch(ActionCreatorsForEditEpisode.updateText(value))
                                            }
                                        }}
                                        { ...field } />
                                )
                            }}
                            </Field>
                            {line.comment && !inputLine? <span className="tooltip-comment-exist" style={{backgroundColor: colorLineWithComment(line)}}></span> : ''}
                        </CInputGroup >
                        {modalPopupBool.isOpen && inputLine && modalPopupBool.index === index  ? <CButton className="ml-5" type="submit" color="primary" size='lg'>Add line</CButton> : ''}
                       
                        <LineSidebar  
                            dispatch={dispatch} 
                            lines={lines} 
                            line={line} 
                            selectedLineIndex={selectedLineIndex} 
                            inputLine={inputLine} 
                            isPreviewInModal={isPreviewInModal}
                            modalPopupBool={modalPopupBool}
                            setYesnomodal={setYesnomodal}
                            editEpisodePassParam={editEpisodePassParam}
                            index={index}
                            pickedActionColor={pickedActionColor}
                            isChangedByOptionFlag={isLineOptionChanged(line)}
                        />
                        
                    </CForm>
                    <DeleteMultipleLinesConfirmationModal modal={yesnomodal} setModal={setYesnomodal} methodForYes={() => DeleteLines(dispatch, alert, yesnomodal.array, index, linesFrame, setYesnomodal, yesnomodal.nextLineDocId) } title="Delete confirmation" message="Are you sure you want to delete selected line or line block?"/>
                </div>)
            }}
        </Formik>)
}

export default TimedChoiceAnswer;