export enum EpisodeStatus
{
    Created,
    InProgress,
    InReview,
    CorrectionRequired,
    ReadyForTranslation,
    Translated,
    Proofread,
    Finished
}