import React, { useEffect, useState } from "react";
import { CButton, CForm } from "@coreui/react";
import { ErrorMessage, Field, Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import {
  SaveAudio,
  GetTypesForSetupStory,
  GetAssetsForSetupStory, 
} from "../../../services/SetupStoryServices";
import ReactLoading from 'react-loading';
import { customStyles } from "../../Helpers/CustomStyles";
import { selectStylesForField } from "../Lines/_Helpers";
import { useAlert } from 'react-alert';
import { ActionCreators } from "../../../store/StoriesReducer";
import { useDispatch } from "react-redux";

export const AddAudioModalBody = ({ selectedData, data, setData, setVisible, storyId }: any) => 
{
  const dispatch = useDispatch();
  const alert = useAlert();
  const [type, setType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dirtySelect, setDirtySelect] = useState(false);

  const [assets, setAssets] = useState([]);

  let addAudioSchema = yup.object().shape({
    name: yup.string().required("You must enter name."),
  });

  const [isAssetDisabled, setAssetDisabled] = useState((selectedData.assetDocId == null || selectedData.assetDocId == '') ? false : true);

  const [audioSelectedObject, setAudioSelectedObject] = useState({
    id: selectedData.id,
    storyId: selectedData.storyId,
    counter: selectedData.counter,
    episode: selectedData.episode,
    name: selectedData.name,
    soName: selectedData.soName,
    description: selectedData.description,
    type: selectedData.type,
    reference: selectedData.reference,
    assetDocId: selectedData.assetDocId,
    assetPath: selectedData.assetPath,
    assetName: selectedData.assetName
  });

  useEffect(() => 
  {
    GetTypesForSetupStory('api/SetupStory/SetupStory_GetMusicTypes').then((result: any) => {
      setType(
        result?.map((option: any) => 
        {
          if(audioSelectedObject.assetDocId != null && option.id === 0)
          {
            return { id: option.id, value: option.value, label: option.label, isDisabled: true };
          }  
          return { id: option.id, value: option.value, label: option.label };
        })
      );
    });

    GetAssetsForSetupStory(`api/SetupStory/GetMusicAssets?storyId=${storyId}`).then((result : any) => 
    {
      setAssets(result?.map((option : any) => { return { id: option.id, value: option.id, label: option.name, src: option.path } }))
    })
  }, []);

  return (
    <Formik
      initialValues={{
        episode: selectedData.episode,
        name: selectedData.name,
        soName: selectedData.soName,
        description: selectedData.description,
        type: selectedData.type,
        reference: selectedData.reference,
        assetDocId: selectedData.assetDocId,
        assetPath: selectedData.assetPath,
        assetName: selectedData.assetName
      }}
      onSubmit={(values) => { 
        setLoading(true);
        SaveAudio({
          ...values,
          id: audioSelectedObject.id,
          storyId: audioSelectedObject.storyId,
          counter: audioSelectedObject.counter,
          type: audioSelectedObject.type,
          assetDocId: audioSelectedObject.assetDocId,
          assetPath: audioSelectedObject.assetPath,
          assetName: audioSelectedObject.assetName
        }, data, setData, setVisible, setLoading, alert).then(() => {
          sessionStorage.setItem('audiosNum', (parseInt(sessionStorage.getItem('audiosNum') ?? '0') + 1).toString())
        });
      }}
      validationSchema={addAudioSchema}
    >
      {({ dirty, isValid, handleSubmit }) => {
        return (
          <CForm onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
            { loading ? <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1000, position: 'absolute', top: '0%', left: '0%', width: '100%', height: '100%'}}>
                            <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1001, position: 'absolute', top: '45%', left: '45%'}}>
                              <ReactLoading className="mt-3 mb-5" type='spin' color='#0080FF'/>
                              </div>
                        </div> : <div></div> }
            <div className="row-style modal-row-stp-story col-sm-12 row">
              <div className="form-group-name col-sm-6 ">
                <label htmlFor="episode">Episode</label>
                <Field
                  className="form-control"
                  name="episode"
                  type="text"
                  disabled
                  autoComplete="off"
                ></Field>
              </div>

              <div className="form-group-name col-sm-6">
                <label htmlFor="name">Name</label>
                <Field
                  className="form-control"
                  name="name"
                  type="text"
                  autoComplete="off"
                  required
                ></Field>
                <ErrorMessage
                  component="p"
                  className="field-colorchange"
                  name="name"
                />
              </div>
            </div>
            <div className="row-style modal-row-stp-story col-sm-12 row">
              <div className="form-group-name col-sm-6 ">
                <label htmlFor="soName">SO Name</label>
                <Field
                  className="form-control"
                  name="soName"
                  type="text"
                  disabled
                  autoComplete="off"
                ></Field>
                <ErrorMessage
                  component="p"
                  className="field-colorchange"
                  name="soName"
                />
              </div>

              <div className="form-group-name col-sm-6">
                <label htmlFor="type">Type</label>
                <Field
                  as={Select}
                  options={type}
                  styles={selectStylesForField}
                  menuPortalTarget={document.querySelector('body')}
                  value={type?.find(
                    (x: any) => x.value == audioSelectedObject.type
                  )}
                  onChange={(value: any) => 
                  {
                    setAudioSelectedObject({
                      ...audioSelectedObject,
                      type: value.value,
                      assetDocId: value.value == 1 || value.value == 2 ? audioSelectedObject.assetDocId : null,
                      assetName: value.value == 1 || value.value == 2 ? audioSelectedObject.assetName : null,
                      assetPath: value.value == 1 || value.value == 2 ? audioSelectedObject.assetPath : null,
                    });
                    setDirtySelect(true);
                  }}
                  isOptionDisabled={(option : any) => { return false } }
                  name="type"
                  autoComplete="off"
                  required
                ></Field>
                <ErrorMessage
                  component="p"
                  className="field-colorchange"
                  name="type"
                />
              </div>
            </div>
            {audioSelectedObject.type  !== 1 ? (
              <div className="row-style modal-row-stp-story col-sm-12 row">
                <div className="form-group-name col-sm-6 ">
                  <label htmlFor="description">Description</label>
                  <Field
                    className="form-control"
                    name="description"
                    type="text"
                    autoComplete="off"
                  ></Field>
                  <ErrorMessage
                    component="p"
                    className="field-colorchange"
                    name="description"
                  />
                </div>

                <div className="form-group-name col-sm-6 ">
                  <label htmlFor="reference">Reference</label>
                  <Field
                    className="form-control"
                    name="reference"
                    type="text"
                    autoComplete="off"
                  ></Field>
                  <ErrorMessage
                    component="p"
                    className="field-colorchange"
                    name="reference"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            
            <div className="form-group-name col-sm-12 modal-row-stp-story">
                <label htmlFor="assets" className="mt-3">Catalogue</label>
                <Field
                  as={Select}
                  options={assets}
                  styles={selectStylesForField}
                  menuPortalTarget={document.querySelector('body')}
                  value={assets?.find(
                    (x: any) => x.value == audioSelectedObject.assetDocId
                  ) ?? ''}
                  isClearable
                  onChange={(value: any) => 
                  {
                    if(value)
                    {
                      setAudioSelectedObject({
                        ...audioSelectedObject,
                        assetDocId: value.value  ,
                        assetName: value.label,
                        assetPath: value.src ,
                        type: audioSelectedObject.type == 2 ? audioSelectedObject.type : 1
                      });
                    }
                    else{
                      setAudioSelectedObject({
                        ...audioSelectedObject,
                        assetDocId: null ,
                        assetName: null,
                        assetPath: null,
                        type: 0
                      });
                    }
                    setAssetDisabled(value != null ? true : false);
                    setDirtySelect(true);
                  }}
                  name="assets"
                  autoComplete="off"
                ></Field>
                <ErrorMessage
                  component="p"
                  className="field-colorchange"
                  name="assets"
                />
            </div>

            <div className="modal-buttons-container float-right">
              <CButton
                type="submit"
                disabled={!(dirty || dirtySelect) || !isValid}
                className="mr-2 wide"
                color="primary"
              >
                Save
              </CButton>
              <CButton className="mr-2 wide" color="secondary" onClick={() => setVisible(false)}>Cancel</CButton>
            </div>
          </CForm>
        );
      }}
    </Formik>
  );
};
