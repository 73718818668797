import { LineType } from "../interfaces/lineType";
import { defaultLine } from "./EditEpisodeReducer";

export interface PopupProps
{
    isOpen: boolean,
    line: LineType,
    clickedLineIndex: number,
    previousStateObject: LineType
}

const initialState = 
{
    stories: 
    [
        {
            id: 0,
            translationId: 0,
            title: '',
            authorName: '',
            authorUID: '',
            status: '',
            storyStatusEnum: 0,
            releaseDate: '',
            description: '',
            translationNotes: '',
            marketingNotes: '',
            language: '',
            episodesNum: 0,
            characters: []
        }
    ],
    concreteStory:
    [
        {
          id: "",
          storyId: "",
          language: 0,
          createdDate: "",
          episodeStatus: "",
          inReviewDate: "",
          readyForTranslationDate: "",
          title: ""
        }
    ],
    writers:
    [
        {
            id: 0,
            value: "Dima",
            label: 'Dima'
        },
        {
            id: 1,
            value: "Cimi",
            label: 'Cimi'
        }
    ],
    storyDetails: {},
    modalPopupBool: { type: 'none', isOpen: false, isFirstTimeOpen: false, index: undefined, currentLine: defaultLine, clickedLine: defaultLine },
    modalPopupBoolAction: { isOpen: false, line: defaultLine, clickedLineIndex: null, previousStateObject: defaultLine },
    modalPopupBoolOption: { isOpen: false, line: defaultLine, clickedLineIndex: null, previousStateObject: defaultLine  },
    gameType: undefined,

    charactersNum: 0,
    hairstylesNum: 0,
    clothesNum: 0,
    accessoriesNum: 0,
    backgroundsNum: 0,
    cinematicsNum: 0,
    audiosNum: 0,

    setupStoryExportLoading: false,
}

export const ActionTypes =
{
    SET_STORIES: 'SET_STORIES',
    UPDATE_STORY: 'UPDATE_STORY',
    SET_CONCRETE_STORY: 'SET_CONCRETE_STORY',
    ADD_NEW_STORY: 'ADD_NEW_STORY',
    ADD_EPISODE: 'ADD_EPISODE',
    SET_STORY_DETAILS: 'SET_STORY_DETAILS',
    SET_MODAL_POPUP_BOOL: 'SET_MODAL_POPUP_BOOL',
    UPDATE_TEXT_IN_POPUP: 'UPDATE_TEXT_IN_POPUP',
    UPDATE_EPISODE_TITLE: 'UPDATE_EPISODE_TITLE',
    SET_GAME_TYPE: 'SET_GAME_TYPE',

    SET_CHARACTERS_NUM: 'SET_CHARACTERS_NUM',
    SET_HAIRSTYLE_NUM: 'SET_HAIRSTYLE_NUM',
    SET_CLOTHES_NUM: 'SET_CLOTHES_NUM',
    SET_ACCESSORIES_NUM: 'SET_ACCESSORIES_NUM',
    SET_BACKGROUNDS_NUM: 'SET_BACKGROUNDS_NUM',
    SET_CINEMATICS_NUM: 'SET_CINEMATICS_NUM',
    SET_AUDIOS_NUM: 'SET_AUDIOS_NUM',

    SET_SETUP_STORY_EXPORT_LOADING: 'SET_SETUP_STORY_EXPORT_LOADING',
    SET_MODAL_POPUP_BOOL_ACTIONS:'SET_MODAL_POPUP_BOOL_ACTIONS',
    SET_MODAL_POPUP_BOOL_OPTIONS:'SET_MODAL_POPUP_BOOL_OPTIONS',

    //MODAL UPDATE LINE TYPES
    UPDATE_PHONE_CALL_BOOL_IN_POPUP: 'UPDATE_PHONE_CALL_BOOL_IN_POPUP',
    UPDATE_SMS_BOOL_IN_POPUP: 'UPDATE_SMS_BOOL_IN_POPUP',

    UPDATE_BACKGROUND_PARAM_IN_POPUP: 'UPDATE_BACKGROUND_PARAM_IN_POPUP',
    UPDATE_BACKGROUND_DESCRIPTION_IN_POPUP: 'UPDATE_BACKGROUND_DESCRIPTION_IN_POPUP',

    UPDATE_MUSIC_THEME_PARAM_IN_POPUP: 'UPDATE_MUSIC_THEME_PARAM_IN_POPUP',
    UPDATE_MUSIC_THEME_DESCRIPTION_IN_POPUP: 'UPDATE_MUSIC_THEME_DESCRIPTION_IN_POPUP',

    UPDATE_SHORT_SOUND_PARAM_IN_POPUP: 'UPDATE_SHORT_SOUND_PARAM_IN_POPUP',
    UPDATE_SHORT_SOUND_DESCRIPTION_IN_POPUP: 'UPDATE_SHORT_SOUND_DESCRIPTION_IN_POPUP',
    UPDATE_SHORT_SOUND_BOOL_IN_POPUP: 'UPDATE_SHORT_SOUND_BOOL_IN_POPUP',

    UPDATE_SHOW_ITEM_PARAM_IN_POPUP: 'UPDATE_SHOW_ITEM_PARAM_IN_POPUP',
    UPDATE_SHOW_ITEM_DESCRIPTION_IN_POPUP: 'UPDATE_SHOW_ITEM_DESCRIPTION_IN_POPUP',

    UPDATE_CALLER_NAME_IN_POPUP: 'UPDATE_CALLER_NAME_IN_POPUP',
    UPDATE_AVATAR_BOOL_IN_POPUP: 'UPDATE_AVATAR_BOOL_IN_POPUP',
    UPDATE_CHARMING_POINT_IN_POPUP: 'UPDATE_CHARMING_POINT_IN_POPUP',
    UPDATE_PREMIUM_CHOICE_TEXT_IN_POPUP: 'UPDATE_PREMIUM_CHOICE_TEXT_IN_POPUP',
    UPDATE_PREMIUM_CHOICE_DESCRIPTION_IN_POPUP: 'UPDATE_PREMIUM_CHOICE_DESCRIPTION_IN_POPUP',
    UPDATE_CHARACTERS_OUTFIT_IN_POPUP: 'UPDATE_CHARACTERS_OUTFIT_IN_POPUP',
    REPLACE_CHARACTER_OUTFIT_IN_POPUP: 'REPLACE_CHARACTER_OUTFIT_IN_POPUP',
    REMOVE_CHARACTERS_OUTFIT_IN_POPUP: 'REMOVE_CHARACTERS_OUTFIT_IN_POPUP',
    UPDATE_SPECIFIC_CHARACTER_OUTFIT_IN_POPUP: 'UPDATE_SPECIFIC_CHARACTER_OUTFIT_IN_POPUP',
    UPDATE_CHARACTER_IDENTITY_ENABLED_IN_POPUP: 'UPDATE_CHARACTER_IDENTITY_ENABLED_IN_POPUP',
    UPDATE_CHARACTER_IDENTITY_NAME_IN_POPUP: 'UPDATE_CHARACTER_IDENTITY_NAME_IN_POPUP',
    
    SET_PHONE_CALL_BOOL_IN_POPUP: 'SET_PHONE_CALL_BOOL_IN_POPUP',
    SET_SMS_BOOL_IN_POPUP: 'SET_SMS_BOOL_IN_POPUP',
    SET_BACKGROUND_PARAM_IN_POPUP: '',
    SET_BACKGROUND_DESCRIPTION_IN_POPUP: '',
    SET_MUSIC_THEME_PARAM_IN_POPUP: '',
    SET_MUSIC_THEME_DESCRIPTION_IN_POPUP: '',
    SET_SHORT_SOUND_PARAM_IN_POPUP: '',
    SET_SHORT_SOUND_DESCRIPTION_IN_POPUP: '',
    SET_SHORT_SOUND_BOOL_IN_POPUP: '',
    SET_SHOW_ITEM_PARAM_IN_POPUP: '',
    SET_SHOW_ITEM_DESCRIPTION_IN_POPUP: '',
    SET_CALLER_NAME_IN_POPUP: '',
    SET_AVATAR_BOOL_IN_POPUP: '',
    SET_CHARMING_POINT_IN_POPUP: '',
    SET_PREMIUM_CHOICE_TEXT_IN_POPUP: '',
    SET_PREMIUM_CHOICE_DESCRIPTION_IN_POPUP: '',
    SET_CHARACTERS_OUTFIT_IN_POPUP: '',
    SET_CHARACTER_OUTFIT_IN_POPUP: '',
    SET_CHARACTER_IDENTITY_ENABLED_IN_POPUP: '',
    SET_CHARACTER_IDENTITY_NAME_IN_POPUP: ''
}

export const ActionCreators = 
{
    setStories: (payload: any) => ({type: ActionTypes.SET_STORIES, payload}),
    updateStory: (payload: any) => ({type: ActionTypes.UPDATE_STORY, payload}),
    setConcreteStory: (payload: any) => ({type: ActionTypes.SET_CONCRETE_STORY, payload}),
    addNewStory: (payload: any) => ({type: ActionTypes.ADD_NEW_STORY, payload}),
    addEpisode: (payload: any) => ({type: ActionTypes.ADD_EPISODE, payload}),
    setStoryDetails: (payload : any) => ({type: ActionTypes.SET_STORY_DETAILS, payload}),
    setModalPopupBool: (payload : any) => ({type: ActionTypes.SET_MODAL_POPUP_BOOL, payload}),
    updateTextInPopup: (payload : any) => ({type: ActionTypes.UPDATE_TEXT_IN_POPUP, payload}),
    updateEpisodeTitle: (payload : any) => ({type: ActionTypes.UPDATE_EPISODE_TITLE, payload}),
    setGameType: (payload : any) => ({type: ActionTypes.SET_GAME_TYPE, payload}),

    setCharactersNum: () => ({type: ActionTypes.SET_CHARACTERS_NUM}),
    setHairstyleNum: () => ({type: ActionTypes.SET_HAIRSTYLE_NUM}),
    setClothesNum: () => ({type: ActionTypes.SET_CLOTHES_NUM}),
    setAccessoriesNum: () => ({type: ActionTypes.SET_ACCESSORIES_NUM}),
    setBackgroundsNum: () => ({type: ActionTypes.SET_BACKGROUNDS_NUM}),
    setCinematicsNum: () => ({type: ActionTypes.SET_CINEMATICS_NUM}),
    setAudiosNum: () => ({type: ActionTypes.SET_AUDIOS_NUM}),

    setSetupStoryExportLoading: (payload : any) => ({type: ActionTypes.SET_SETUP_STORY_EXPORT_LOADING, payload}),
    setModalPopupBoolAction: (payload : any) => ({type: ActionTypes.SET_MODAL_POPUP_BOOL_ACTIONS, payload}),
    setModalPopupBoolOption: (payload : any) => ({type: ActionTypes.SET_MODAL_POPUP_BOOL_OPTIONS, payload}),

    //MODAL UPDATE LINE FUNCTIONS
    updatePhoneCallInPopup: (payload : any) => ({type: ActionTypes.UPDATE_PHONE_CALL_BOOL_IN_POPUP, payload}),
    updateSMSInPopup: (payload : any) => ({type: ActionTypes.UPDATE_SMS_BOOL_IN_POPUP, payload}),

    updateBackgroundInPopup: (payload : any) => ({type: ActionTypes.UPDATE_BACKGROUND_PARAM_IN_POPUP, payload}),
    updateBackgroundDescriptionInPopup: (payload : any) => ({type: ActionTypes.UPDATE_BACKGROUND_DESCRIPTION_IN_POPUP, payload}),

    updateMusicThemeInPopup: (payload : any) => ({type: ActionTypes.UPDATE_MUSIC_THEME_PARAM_IN_POPUP, payload}),
    updateMusicThemeDescriptionInPopup: (payload : any) => ({type: ActionTypes.UPDATE_MUSIC_THEME_DESCRIPTION_IN_POPUP, payload}),

    updateShortSoundInPopup: (payload : any) => ({type: ActionTypes.UPDATE_SHORT_SOUND_PARAM_IN_POPUP, payload}),
    updateShortSoundDescriptionInPopup: (payload : any) => ({type: ActionTypes.UPDATE_SHORT_SOUND_DESCRIPTION_IN_POPUP, payload}),
    updateShortSoundBoolInPopup: (payload : any) => ({type: ActionTypes.UPDATE_SHORT_SOUND_BOOL_IN_POPUP, payload}),

    updateShowItemInPopup: (payload : any) => ({type: ActionTypes.UPDATE_SHOW_ITEM_PARAM_IN_POPUP, payload}),
    updateShowItemDescriptionInPopup: (payload : any) => ({type: ActionTypes.UPDATE_SHOW_ITEM_DESCRIPTION_IN_POPUP, payload}),
    
    updateCallerNameInPopup: (payload : any) => ({type: ActionTypes.UPDATE_CALLER_NAME_IN_POPUP, payload}),
    updateAvatarBoolInPopup: (payload : any) => ({type: ActionTypes.UPDATE_AVATAR_BOOL_IN_POPUP, payload}),
    updateCharmingPointInPopup: (payload : any) => ({type: ActionTypes.UPDATE_CHARMING_POINT_IN_POPUP, payload}),
    updatePremiumChoiceTextInPopup: (payload : any) => ({type: ActionTypes.UPDATE_PREMIUM_CHOICE_TEXT_IN_POPUP, payload}),
    updatePremiumChoiceTextDescriptionInPopup: (payload : any) => ({type: ActionTypes.UPDATE_PREMIUM_CHOICE_DESCRIPTION_IN_POPUP, payload}),
    updateCharacterOutfitInPopup: (payload : any) => ({type: ActionTypes.UPDATE_CHARACTERS_OUTFIT_IN_POPUP, payload}),
    replaceCharacterOufitInPopup: (payload : any) => ({type: ActionTypes.REPLACE_CHARACTER_OUTFIT_IN_POPUP, payload}),
    updateSpecificCharacterOufitInPopup: (payload : any) => ({type: ActionTypes.UPDATE_SPECIFIC_CHARACTER_OUTFIT_IN_POPUP, payload}),
    removeCharacterOutfitInPopup: (payload : any) => ({type: ActionTypes.REMOVE_CHARACTERS_OUTFIT_IN_POPUP, payload}),
    updateCharacterIdentityEnabledInPopup: (payload : any) => ({type: ActionTypes.UPDATE_CHARACTER_IDENTITY_ENABLED_IN_POPUP, payload}),
    updateCharacterIdentityNameInPopup: (payload : any) => ({type: ActionTypes.UPDATE_CHARACTER_IDENTITY_NAME_IN_POPUP, payload}),

    setPhoneCallInPopup: (payload : any) => ({type: ActionTypes.SET_PHONE_CALL_BOOL_IN_POPUP, payload}),
    setSMSInPopup: (payload : any) => ({type: ActionTypes.SET_SMS_BOOL_IN_POPUP, payload}),
    setBackgroundInPopup: (payload : any) => ({type: ActionTypes.SET_BACKGROUND_PARAM_IN_POPUP, payload}),
    setBackgroundDescriptionInPopup: (payload : any) => ({type: ActionTypes.SET_BACKGROUND_DESCRIPTION_IN_POPUP, payload}),
    setMusicThemeInPopup: (payload : any) => ({type: ActionTypes.SET_MUSIC_THEME_PARAM_IN_POPUP, payload}),
    setMusicThemeDescriptionInPopup: (payload : any) => ({type: ActionTypes.SET_MUSIC_THEME_DESCRIPTION_IN_POPUP, payload}),
    setShortSoundInPopup: (payload : any) => ({type: ActionTypes.SET_SHORT_SOUND_PARAM_IN_POPUP, payload}),
    setShortSoundDescriptionInPopup: (payload : any) => ({type: ActionTypes.SET_SHORT_SOUND_DESCRIPTION_IN_POPUP, payload}),
    setShortSoundBoolInPopup: (payload : any) => ({type: ActionTypes.SET_SHORT_SOUND_BOOL_IN_POPUP, payload}),
    setShowItemInPopup: (payload : any) => ({type: ActionTypes.SET_SHOW_ITEM_PARAM_IN_POPUP, payload}),
    setShowItemDescriptionInPopup: (payload : any) => ({type: ActionTypes.SET_SHOW_ITEM_DESCRIPTION_IN_POPUP, payload}),
    setCallerNameInPopup: (payload : any) => ({type: ActionTypes.SET_CALLER_NAME_IN_POPUP, payload}),
    setAvatarBoolInPopup: (payload : any) => ({type: ActionTypes.SET_AVATAR_BOOL_IN_POPUP, payload}),
    setCharmingPointInPopup: (payload : any) => ({type: ActionTypes.SET_CHARMING_POINT_IN_POPUP, payload}),
    setPremiumChoiceTextInPopup: (payload : any) => ({type: ActionTypes.SET_PREMIUM_CHOICE_TEXT_IN_POPUP, payload}),
    setPremiumChoiceTextDescriptionInPopup: (payload : any) => ({type: ActionTypes.SET_PREMIUM_CHOICE_DESCRIPTION_IN_POPUP, payload}),
    setCharacterOutfitInPopup: (payload : any) => ({type: ActionTypes.SET_CHARACTERS_OUTFIT_IN_POPUP, payload}),
    setCharacterIdentityEnabledInPopup: (payload : any) => ({type: ActionTypes.SET_CHARACTER_IDENTITY_ENABLED_IN_POPUP, payload}),
    setCharacterIdentityNameInPopup: (payload : any) => ({type: ActionTypes.SET_CHARACTER_IDENTITY_NAME_IN_POPUP, payload})
}

export default function storiesReducer(state=initialState, action: any)
{
    switch(action.type)
    {
        case ActionTypes.SET_STORIES:
            return {...state, stories: [...action.payload]};
        case ActionTypes.UPDATE_STORY:
            return { ...state, stories: state.stories.map((content: any, i : any) => 
                content.id === action.payload.storyId ? { ...content, status: 'In progress', episodesNum: content.episodesNum + 1} : content)}
        case ActionTypes.SET_CONCRETE_STORY:
            return {...state, concreteStory: [...action.payload]};
        case ActionTypes.ADD_NEW_STORY:
            return { ...state, stories: [...state.stories, action.payload] }
        case ActionTypes.ADD_EPISODE:
            return { ...state, concreteStory: [...state.concreteStory, action.payload] }
        case ActionTypes.SET_STORY_DETAILS:
            return {...state, storyDetails: {...action.payload}};
        case ActionTypes.SET_MODAL_POPUP_BOOL:
            return { ...state, modalPopupBool: action.payload }
        case ActionTypes.UPDATE_TEXT_IN_POPUP:
            return { ...state, modalPopupBool: {...state.modalPopupBool, currentLine: {...state.modalPopupBool.currentLine, text: action.payload }}}
        case ActionTypes.UPDATE_EPISODE_TITLE:
            return {...state, concreteStory: [...state.concreteStory.map((element : any) => 
                element.id == action.payload.episodeId ? {...element, title: action.payload.text} : element
            )]}
        case ActionTypes.SET_GAME_TYPE:
            return {...state, gameType: action.payload}

        case ActionTypes.SET_CHARACTERS_NUM:
            return {...state, charactersNum: state.charactersNum + 1}
        case ActionTypes.SET_HAIRSTYLE_NUM:
            return {...state, hairstylesNum: state.hairstylesNum + 1}
        case ActionTypes.SET_CLOTHES_NUM:
            return {...state, clothesNum: state.clothesNum + 1}
        case ActionTypes.SET_ACCESSORIES_NUM:
            return {...state, accessoriesNum: state.accessoriesNum + 1}
        case ActionTypes.SET_BACKGROUNDS_NUM:
            return {...state, backgroundsNum: state.backgroundsNum + 1}
        case ActionTypes.SET_CINEMATICS_NUM:
            return {...state, cinematicsNum: state.cinematicsNum + 1}
        case ActionTypes.SET_AUDIOS_NUM:
            return {...state, audiosNum: state.audiosNum + 1}
        
        case ActionTypes.SET_SETUP_STORY_EXPORT_LOADING:
            return {...state, setupStoryExportLoading: action.payload }
        case ActionTypes.SET_MODAL_POPUP_BOOL_ACTIONS:
            return {...state, modalPopupBoolAction: {
                ...state.modalPopupBoolAction, 
                isOpen: action.payload.isOpen, 
                line: action.payload.line, 
                clickedLineIndex: action.payload.index, 
                previousStateObject: action.payload.line
            }}
        case ActionTypes.SET_MODAL_POPUP_BOOL_OPTIONS:
            return {...state, modalPopupBoolOption: {
                ...state.modalPopupBoolOption, 
                isOpen: action.payload.isOpen, 
                line: action.payload.line, 
                clickedLineIndex: action.payload.index, 
                previousStateObject: action.payload.line
            }}
        case ActionTypes.UPDATE_PHONE_CALL_BOOL_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, line: {...state.modalPopupBoolAction.line, callPhoneEnabled: action.payload}}}
        case ActionTypes.UPDATE_SMS_BOOL_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, line: {...state.modalPopupBoolAction.line, callSmsEnabled: action.payload}}}
        case ActionTypes.UPDATE_BACKGROUND_PARAM_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, line: {...state.modalPopupBoolAction.line, backgroundId: action.payload.id, backgroundName: action.payload.label}}}
        case ActionTypes.UPDATE_BACKGROUND_DESCRIPTION_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, line: {...state.modalPopupBoolAction.line, backgroundDescription: action.payload}}}
            
        case ActionTypes.UPDATE_MUSIC_THEME_PARAM_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, line: {...state.modalPopupBoolAction.line, musicId: action.payload.id, musicName: action.payload.label}}}
        case ActionTypes.UPDATE_MUSIC_THEME_DESCRIPTION_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, line: {...state.modalPopupBoolAction.line, musicDescription: action.payload}}}
            
        case ActionTypes.UPDATE_SHORT_SOUND_PARAM_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, line: {...state.modalPopupBoolAction.line, shortSoundId: action.payload.id, shortSoundName: action.payload.label}}}
        case ActionTypes.UPDATE_SHORT_SOUND_DESCRIPTION_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, line: {...state.modalPopupBoolAction.line, shortSoundDescription: action.payload}}}
        case ActionTypes.UPDATE_SHORT_SOUND_BOOL_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, line: {...state.modalPopupBoolAction.line, shortSoundEnabled: action.payload}}}
            
        case ActionTypes.UPDATE_SHOW_ITEM_PARAM_IN_POPUP:
            return {...state, modalPopupBoolOption: {...state.modalPopupBoolOption, line: {...state.modalPopupBoolOption.line, itemId: action.payload.id, itemName: action.payload.label}}}
        case ActionTypes.UPDATE_SHOW_ITEM_DESCRIPTION_IN_POPUP:
            return {...state, modalPopupBoolOption: {...state.modalPopupBoolOption, line: {...state.modalPopupBoolOption.line, itemDescription: action.payload}}}

        case ActionTypes.UPDATE_CALLER_NAME_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, line: {...state.modalPopupBoolAction.line, callerCharacterId: action.payload.id, callerCharacterName: action.payload.label}}}
        case ActionTypes.UPDATE_AVATAR_BOOL_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, line: {...state.modalPopupBoolAction.line, avatarEnabled: action.payload}}}
        case ActionTypes.UPDATE_CHARMING_POINT_IN_POPUP:
            return {...state, modalPopupBoolOption: {...state.modalPopupBoolOption, line: {...state.modalPopupBoolOption.line, charmingPoint: action.payload}}}
        case ActionTypes.UPDATE_PREMIUM_CHOICE_TEXT_IN_POPUP:
            return {...state, modalPopupBoolOption: {...state.modalPopupBoolOption, line: {...state.modalPopupBoolOption.line, premiumChoiceText: action.payload}}}
        case ActionTypes.UPDATE_PREMIUM_CHOICE_DESCRIPTION_IN_POPUP:
            return {...state, modalPopupBoolOption: {...state.modalPopupBoolOption, line: {...state.modalPopupBoolOption.line, premiumChoiceDescription: action.payload}}}
        case ActionTypes.UPDATE_CHARACTERS_OUTFIT_IN_POPUP:
            return { ...state, modalPopupBoolAction: { ...state.modalPopupBoolAction, line: {...state.modalPopupBoolAction.line, charactersOutfit: [...state.modalPopupBoolAction?.line?.charactersOutfit, action.payload ]}  }}
        case ActionTypes.REPLACE_CHARACTER_OUTFIT_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, line: {...state.modalPopupBoolAction.line, charactersOufit: action.payload}}}
        case ActionTypes.UPDATE_SPECIFIC_CHARACTER_OUTFIT_IN_POPUP:
            return { ...state, modalPopupBoolAction: { ...state.modalPopupBoolAction, line: {
                ...state.modalPopupBoolAction.line, charactersOutfit: state.modalPopupBoolAction?.line?.charactersOutfit.map((content : any, i : any) => 
                content.characterId === action.payload.characterId ? {...content, 
                        characterId: action.payload.characterId,
                        characterName: action.payload.characterName,
                        clothesId: action.payload.clothesId,
                        clothesName: action.payload.clothesName,
                        clothesDescription: action.payload.clothesDescription,
                        hairstyleId: action.payload.hairstyleId,
                        hairstyleName: action.payload.hairstyleName,
                        hairstyleDescription: action.payload.hairstyleDescription,
                        accessoryEnabled: action.payload.accessoryEnabled,
                        accessoryId: action.payload.accessoryId,
                        accessoryName: action.payload.accessoryName,
                        accessoryDescription: action.payload.accessoryDescription,
                        accessoryType: action.payload.accessoryType,
                        changeOutfitType: action.payload.changeOutfitType
                } : content )}} 
            }
          
        case ActionTypes.REMOVE_CHARACTERS_OUTFIT_IN_POPUP:
            return { 
                ...state, 
                modalPopupBoolAction: { ...state.modalPopupBoolAction, line: {...state.modalPopupBoolAction.line, charactersOutfit: [...state.modalPopupBoolAction?.line?.charactersOutfit.filter((item : any) => item.characterId !== action.payload)]}}
            }      
            
        case ActionTypes.UPDATE_CHARACTER_IDENTITY_ENABLED_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, line: {...state.modalPopupBoolAction.line, changeIdentityOn: action.payload}}}
        case ActionTypes.UPDATE_CHARACTER_IDENTITY_NAME_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, line: {...state.modalPopupBoolAction.line, changeIdentityName: action.payload}}}

        case ActionTypes.SET_PHONE_CALL_BOOL_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, previousStateObject: {...state.modalPopupBoolAction.previousStateObject, callPhoneEnabled: action.payload}}}
        case ActionTypes.SET_SMS_BOOL_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, previousStateObject: {...state.modalPopupBoolAction.previousStateObject, callSmsEnabled: action.payload}}}
        case ActionTypes.SET_BACKGROUND_PARAM_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, previousStateObject: {...state.modalPopupBoolAction.previousStateObject, backgroundId: action.payload.id, backgroundName: action.payload.label}}}
        case ActionTypes.SET_BACKGROUND_DESCRIPTION_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, previousStateObject: {...state.modalPopupBoolAction.previousStateObject, backgroundDescription: action.payload}}}    
        case ActionTypes.SET_MUSIC_THEME_PARAM_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, previousStateObject: {...state.modalPopupBoolAction.previousStateObject, musicId: action.payload.id, musicName: action.payload.label}}}
        case ActionTypes.SET_MUSIC_THEME_DESCRIPTION_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, previousStateObject: {...state.modalPopupBoolAction.previousStateObject, musicDescription: action.payload}}}  
        case ActionTypes.SET_SHORT_SOUND_PARAM_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, previousStateObject: {...state.modalPopupBoolAction.previousStateObject, shortSoundId: action.payload.id, shortSoundName: action.payload.label}}}
        case ActionTypes.SET_SHORT_SOUND_DESCRIPTION_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, previousStateObject: {...state.modalPopupBoolAction.previousStateObject, shortSoundDescription: action.payload}}}
        case ActionTypes.SET_SHORT_SOUND_BOOL_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, previousStateObject: {...state.modalPopupBoolAction.previousStateObject, shortSoundEnabled: action.payload}}}
        case ActionTypes.SET_SHOW_ITEM_PARAM_IN_POPUP:
            return {...state, modalPopupBoolOption: {...state.modalPopupBoolOption, previousStateObject: {...state.modalPopupBoolOption.previousStateObject, itemId: action.payload.id, itemName: action.payload.label}}}
        case ActionTypes.SET_SHOW_ITEM_DESCRIPTION_IN_POPUP:
            return {...state, modalPopupBoolOption: {...state.modalPopupBoolOption, previousStateObject: {...state.modalPopupBoolOption.previousStateObject, itemDescription: action.payload}}}
        case ActionTypes.SET_CALLER_NAME_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, previousStateObject: {...state.modalPopupBoolAction.previousStateObject, callerCharacterId: action.payload.id, callerCharacterName: action.payload.label}}}
        case ActionTypes.SET_AVATAR_BOOL_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, previousStateObject: {...state.modalPopupBoolAction.previousStateObject, avatarEnabled: action.payload}}}
        case ActionTypes.SET_CHARMING_POINT_IN_POPUP:
            return {...state, modalPopupBoolOption: {...state.modalPopupBoolOption, previousStateObject: {...state.modalPopupBoolOption.previousStateObject, charmingPoint: action.payload}}}
        case ActionTypes.SET_PREMIUM_CHOICE_TEXT_IN_POPUP:
            return {...state, modalPopupBoolOption: {...state.modalPopupBoolOption, previousStateObject: {...state.modalPopupBoolOption.previousStateObject, premiumChoiceText: action.payload}}}
        case ActionTypes.SET_PREMIUM_CHOICE_DESCRIPTION_IN_POPUP:
            return {...state, modalPopupBoolOption: {...state.modalPopupBoolOption, previousStateObject: {...state.modalPopupBoolOption.previousStateObject, premiumChoiceDescription: action.payload}}}
        case ActionTypes.SET_CHARACTERS_OUTFIT_IN_POPUP:
            return { ...state, modalPopupBoolAction: { ...state.modalPopupBoolAction, previousStateObject: {...state.modalPopupBoolAction.previousStateObject, charactersOutfit: action.payload}  }}
        case ActionTypes.SET_CHARACTER_OUTFIT_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, previousStateObject: {...state.modalPopupBoolAction.previousStateObject, charactersOufit: action.payload}}}
        case ActionTypes.SET_CHARACTER_IDENTITY_ENABLED_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, previousStateObject: {...state.modalPopupBoolAction.previousStateObject, changeIdentityOn: action.payload}}}
        case ActionTypes.SET_CHARACTER_IDENTITY_NAME_IN_POPUP:
            return {...state, modalPopupBoolAction: {...state.modalPopupBoolAction, previousStateObject: {...state.modalPopupBoolAction.previousStateObject, changeIdentityName: action.payload}}}

        default:
            return state;
    }
}