import { CButton, CDataTable } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import {BiSync} from 'react-icons/bi';
import {useAlert} from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { GetUsersFromFirestore, GetCurrentUsers, UpdateUsersFromFirestore, GetAllLanguages, GetAllRoles } from '../../services/UserManagementServices';

export const UserManagement = () =>
{
    const alert = useAlert();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const currentUsers = useSelector((state: any) => state.userManagementReducer.currentUsers);

    const [usersFromFirestore, setUsersFromFirestore] = useState<any[]>([]);
    const [allLanguages, setAllLanguages] = useState<any[]>([]);
    const [allRoles, setAllRoles] = useState<any[]>([]);

    //table columns
    const currentUsersColumns = 
    [
        {
            key: 'uid',
            label: 'Id',      
            sorter: true,
            _style: {width: '15%'}
        },
        {
            key: 'displayName',
            label: 'Name',      
            sorter: true,
            _style: {width: '15%'}
        },
        {
            key: 'email',
            label: 'Email',      
            sorter: true,
            _style: {width: '15%'}
        },
        {
            key: 'userRoles',
            label: 'User Roles',      
            sorter: true,
            _style: {width: '20%'}
        },
        {
            key: 'userLanguageText',
            label: 'User Language',      
            sorter: true,
            _style: {width: '10%'}
        },
        {
            key: 'translationLanguagesText',
            label: 'Translation Languages',      
            sorter: true,
            _style: {width: '25%'}
        }    
    ]

    const usersFromFirestoreColumns = 
    [
        {
            key: 'docId',
            label: 'docId',      
            sorter: true,
            _style: {width: '15%'}
        },
        {
            key: 'uid',
            label: 'Id',      
            sorter: true,
            _style: {width: '15%'}
        },
        {
            key: 'displayName',
            label: 'Name',      
            sorter: true
        },
        {
            key: 'email',
            label: 'Email',      
            sorter: true
        },
        {
            key: 'userRoles',
            label: 'User Roles',      
            sorter: true
        },
        {
            key: 'userLanguageText',
            label: 'User Language',      
            sorter: true
        },
        {
            key: 'translationLanguagesText',
            label: 'Translation Languages',      
            sorter: true
        }     
    ]

    const allLanguagesColumns = 
    [
        {
            key: 'id',
            label: 'Id',      
            sorter: true,
            _style: {width: '30%'}
        },
        {
            key: 'label',
            label: 'Name',      
            sorter: true
        },
    ]

    const allRolesColumns = 
    [
        {
            key: 'label',
            label: 'Name',      
            sorter: true
        },
    ]

    useEffect(() => 
    { 
        document.title = 'Story Maker - User Management'
        GetCurrentUsers(alert, dispatch);
        GetUsersFromFirestore(alert).then((response) => { setUsersFromFirestore(response); });
        GetAllLanguages(alert).then((response) => { setAllLanguages(response); });
        GetAllRoles(alert).then((response) => { setAllRoles(response); });
    }, [])


    return(
<div>                   
    <div className="register-signin-left-panel col-sm-12 rounded shadow stories"> 
        <h4 className="story-title">Current Users ({currentUsers.length})</h4>
        <CDataTable 
        items={currentUsers}
        loading={loading}
        fields={currentUsersColumns}
        tableFilter
        itemsPerPageSelect
        itemsPerPage={5}
        striped  
        hover
        sorter
        pagination
        responsive={true}
        scopedSlots= {{
            'userRoles': (item: any) =>
            (
                <td>{ item.userRoles === null ? 'N/A' : item.userRoles.map((x: any) => `${x}`).join(', ')}</td>     
            ),
            'translationLanguagesText': (item: any) =>
            (
                <td>{ item.translationLanguagesText === null ? 'N/A' : item.translationLanguagesText.map((x: any) => `${x}`).join(', ')}</td>     
            )
        }}
        /> 
    </div>
    <div>
    <CButton
            size = "lg"
            className={`btn-primary border shadow-lg mt-2 mb-2`}
            onClick={ () => 
            {        setLoading(true);
                     UpdateUsersFromFirestore(alert).then((response) => { 
                        GetCurrentUsers(alert, dispatch);
                        setLoading(false)});
            }} >
            <BiSync size="24"/> Sync current users from Firestore
    </CButton> 
    </div>
    <div className="register-signin-left-panel col-sm-12 rounded shadow stories"> 
        <h4 className="story-title">Users from Firestore ({usersFromFirestore.length})</h4>
        <CDataTable 
        items={usersFromFirestore}
        loading={loading}
        fields={usersFromFirestoreColumns}
        tableFilter
        itemsPerPageSelect
        itemsPerPage={5}
        striped  
        hover
        sorter
        pagination
        responsive={true}
        scopedSlots= {{
            'userRoles': (item: any) =>
            (
                <td>{ item.userRoles === null ? 'N/A' : item.userRoles.map((x: any) => `${x}`).join(', ')}</td>     
            ),
            'translationLanguagesText': (item: any) =>
            (
                <td>{ item.translationLanguagesText === null ? 'N/A' : item.translationLanguagesText.map((x: any) => `${x}`).join(', ')}</td>     
            )
        }}
            /> 
    </div>
    <div className="register-signin-left-panel col-sm-12 row rounded shadow stories mt-4"> 
        <div className="col-sm-6">
            <h5>Languages</h5>
                <CDataTable 
                items={allLanguages}
                loading={loading}
                fields={allLanguagesColumns}
                striped  
                hover
                sorter
                responsive={true}
                    /> 
        </div>
        <div className="col-sm-6">
            <h5>User roles</h5>
                <CDataTable 
                items={allRoles}
                loading={loading}
                fields={allRolesColumns}
                striped  
                hover
                sorter
                responsive={true}
                    /> 
        </div>
    </div>
    
</div>
)
}

export default UserManagement;
