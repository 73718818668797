import React, { Component } from "react";

//project imports
import { TopBar } from '../Common/TopBar';
import { Sidebar } from '../Common/Sidebar';

export default class MainLayout extends Component<{}, { minimize: boolean}> {
    constructor(props : any)
    {
        super(props);
        this.state={ minimize: true }
    }

    modifyState = () =>{
        this.setState({ minimize: !this.state.minimize })   
    }
    render() 
    {
        return (
                <React.Fragment>
                    <div className="flex-style main-container">
                        <div className="sidebar">
                            <Sidebar minimize={this.state.minimize} modifyState={this.modifyState}/>
                        </div>  
                        <div className="app-column column-style app-container" >
                            <TopBar/>
                            <div className={this.state.minimize ? "stories-table-margin-minimized" : "stories-table-margin"}>
                                {this.props.children}
                            </div> 
                        </div>
                    </div>
                </React.Fragment>
        );
    }
}