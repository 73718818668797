import { axiosInstance } from '../axios/key';
import { ErrorHandler } from '../components/Helpers/ErrorHandler';
import { StoryStatusChangedLogger } from '../modules/firebaseAnalyticsLoggers';
import { GetStoryDetailsForSetupStory } from '../services/StoriesServices';

export const SaveStoryInfo = async (story: any, setLoading: any, dispatch: any, alert : any) =>
{
    try
    {
        await axiosInstance.post('api/SetupStory/UpdateStoryInfo', story).then( 
            (result: any) => 
            {
                GetStoryDetailsForSetupStory(dispatch, story.id, story.translationId).then((response) => 
                {
                    if(story.previousStoryStatus !== story.storyStatusEnum)
                    {
                        const storyStatusChangedLogger = new StoryStatusChangedLogger();
                        storyStatusChangedLogger.story_name = story.title;
                        storyStatusChangedLogger.author_name = story.authorName;
                        storyStatusChangedLogger.user_id = localStorage.getItem('uid') ?? '';
                        storyStatusChangedLogger.language = response.languageString;
                        storyStatusChangedLogger.character_type = response.charactersTypeString;
                        storyStatusChangedLogger.category = response.storyGenre;
                        storyStatusChangedLogger.internal_writer = false //currently info not available in the app, to be added
                        storyStatusChangedLogger.game_type = response.storyGameTypeString;
                        storyStatusChangedLogger.new_status = response.status;
                        storyStatusChangedLogger.number_of_episodes = response.episodesNum;
                        storyStatusChangedLogger.logged_in_user_name = localStorage.getItem('user') ?? '';
                        storyStatusChangedLogger.logEvent();
                    }
                    setLoading(false);
                    if (!result.data.success) {
                        ErrorHandler(undefined, result.data.message, alert);
                    }
                });
                // setLoading(false);
                // if (result.data.success) {
                // }
                // else {
                //     ErrorHandler(undefined, result.data.message, alert);
                //     GetStoryDetailsForSetupStory(dispatch, story.id, story.translationId).then(() => setLoading(false));
                // }
            }); 
    }
    catch (e)
    {
        setLoading(false);
        ErrorHandler(e, 'Unhandled error!', alert);
    }
}

export const SaveCharacter = async (character: any, data: any, setData: any, setVisible: any, setLoading: any, alert : any) =>
{
    try
    {
        await axiosInstance.post('api/SetupStory/UpdateCharacter', character).then( 
            (result: any) => {
                setLoading(false);
                if (result.data.success) {
                    setData({...data, characters:
                        data.map((d: any) => {
                            if(d.id == character.id)
                            {
                                d = result.data.model;
                            }      
                            return d;
                        })
                    });
                    setVisible(false);
                }
                else {
                    ErrorHandler(undefined, result.data.message, alert);
                }
            }); 
    }
    catch (e)
    {
        setLoading(false);
        ErrorHandler(e, 'An error occured while trying to delete item.', alert);
    }
}

export const SaveAccessories = async (accessory: any, data: any, setData: any, setVisible: any, setLoading: any, alert : any) => 
{
    try 
    {
        await axiosInstance.post('api/SetupStory/UpdateAccessory', accessory).then( 
            (result: any) => {
                setLoading(false);
                if (result.data.success) {
                    setData({...data, accessories:
                        data.map((d: any) => {
                            if(d.id == accessory.id)
                            {
                                d = result.data.model;
                            }      
                            return d;
                        })
                    });
                    setVisible(false); 
                } 
                else {
                    ErrorHandler(undefined, result.data.message, alert);
                }
        }); 
    } 
    catch (e)
    {
        setLoading(false);
        ErrorHandler(e, 'An error occured while trying to delete item.', alert);
    }
};

export const SaveCinematic = async (cinematic: any, data: any, setData: any, setVisible: any, setLoading: any, alert : any) => 
{
    try 
    {
        await axiosInstance.post('api/SetupStory/UpdateAnimation', cinematic).then( 
            (result: any) => {
                setLoading(false);
                if (result.data.success) {
                    setData({...data, cinematics:
                        data.map((d: any) => {
                            if(d.id == cinematic.id)
                            {
                                d = result.data.model;
                            }      
                            return d;
                        })
                    });
                    setVisible(false);
                }
                else {
                    ErrorHandler(undefined, result.data.message, alert);
                }
        }); 
    } 
    catch (e)
    {
        setLoading(false);
        ErrorHandler(e, 'An error occured while trying to delete item.', alert);
    }
};

export const SaveVisualChoice = async (visualChoice: any, data: any, setData: any, setVisible: any, setLoading: any, alert : any) => 
{
    try 
    {
        await axiosInstance.post('api/SetupStory/UpdateVisual', visualChoice).then( 
            (result: any) => {
                setLoading(false);
                if (result.data.success) {
                    setData({...data, visuals:
                        data.map((d: any) => {
                            if(d.id == visualChoice.id)
                            {
                                d = result.data.model;
                            }      
                            return d;
                        })
                    });
                    setVisible(false);
                }
                else {
                    ErrorHandler(undefined, result.data.message, alert);
                }
        }); 
    } 
    catch (e)
    {
        setLoading(false);
        ErrorHandler(e, 'An error occured while trying to save visual choice.', alert);
    }
};

export const SaveItem = async (item: any, data: any, setData: any, setVisible: any, setLoading: any, alert : any) => 
{
    try 
    {
        await axiosInstance.post('api/SetupStory/UpdateItem', item).then( 
            (result: any) => {
                setLoading(false);
                if (result.data.success) {
                    setData({...data, items:
                        data.map((d: any) => {
                            if(d.id == item.id)
                            {
                                d = result.data.model;
                            }      
                            return d;
                        })
                    });
                    setVisible(false);
                }
                else {
                    ErrorHandler(undefined, result.data.message, alert);
                }
        }); 
    } 
    catch (e)
    {
        setLoading(false);
        ErrorHandler(e, 'An error occured while trying to save item.', alert);
    }
};


export const SaveClothes = async (clothes: any, data: any, setData: any, setVisible: any, setLoading: any, alert : any) => 
{
    try 
    {
        await axiosInstance.post('api/SetupStory/UpdateClothes', clothes).then( 
            (result: any) => {
                setLoading(false);
                if (result.data.success) {
                    setData({...data, clothes:
                        data.map((d: any) => {
                            if(d.id == clothes.id)
                            {
                                d = result.data.model;
                            }      
                            return d;
                        })
                    });
                    setVisible(false);    
                }
                else {
                    ErrorHandler(undefined, result.data.message, alert);
                }
            }); 
    } 
    catch (e)
    {
        setLoading(false);
        ErrorHandler(e, 'An error occured while trying to delete item.', alert);
    }
};

export const SaveHairstyle = async (hairstyle: any, data: any, setData: any, setVisible: any, setLoading: any, alert : any) =>
{
    try
    {
        await axiosInstance.post('api/SetupStory/UpdateHairstyle', hairstyle).then( 
            (result: any) => {
                setLoading(false);
                if (result.data.success) {
                    setData({...data, hairstyles:
                        data.map((d: any) => {
                            if(d.id == hairstyle.id)
                            {
                                d = result.data.model;
                            }      
                            return d;
                        })
                    });
                    setVisible(false);
                }
                else {
                    ErrorHandler(undefined, result.data.message, alert);
                }
            });   
    }
    catch (e)
    {
        setLoading(false);
        ErrorHandler(e, 'An error occured while trying to delete item.', alert);
    }
}

export const SaveAudio = async (audio: any, data: any, setData: any, setVisible: any, setLoading: any, alert : any) =>
{
    try
    {
        await axiosInstance.post('api/SetupStory/UpdateMusic', audio).then( 
            (result: any) => {
                setLoading(false);
                if (result.data.success) {
                    setData({...data, audio:
                        data.map((d: any) => {
                            if(d.id == audio.id)
                            {
                                d = result.data.model;
                            }      
                            return d;
                        })
                    });
                    setVisible(false);
                }
                else {
                    ErrorHandler(undefined, result.data.message, alert);
                }
            });     
    }
    catch (e)
    {
        setLoading(false);
        ErrorHandler(e, 'An error occured while trying to delete item.', alert);
    }
}

export const SaveBackground = async (background: any, data: any, setData: any, setVisible: any, setLoading: any, alert : any) =>
{
    try
    {
        await axiosInstance.post('api/SetupStory/UpdateBackground', background).then( 
            (result: any) => {
                setLoading(false);
                if (result.data.success) {
                    setData({...data, backgrounds:
                        data.map((d: any) => {
                            if(d.id == background.id)
                            {
                                d = result.data.model;
                            }      
                            return d;
                        })
                    });
                    setVisible(false);
                }
                else {
                    ErrorHandler(undefined, result.data.message, alert);
                }
            });   
    }
    catch (e)
    {
        setLoading(false);
        ErrorHandler(e, 'An error occured while trying to delete item.', alert);
    }
}

export const GetStoryStatuses = async () =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/SetupStory/SetupStory_GetStoryStatuses`);
        return data;
    }
    catch(e)
    {
        console.log('Error in getting body type options.');
    }
}

export const GetPossibleStoryStatuses = async (storyId: any) =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/StateMachine/GetPossibleStoryStatuses?storyId=${storyId}`);
        return data;
    }
    catch(e)
    {
        console.log('Error in getting body type options.');
    }
}

export const GetBodyTypes = async (storyId: any) =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/SetupStory/SetupStory_GetBodyTypes?storyId=${storyId}`);
        return data;
    }
    catch(e)
    {
        console.log('Error in getting body type options.');
    }
}

export const GetSkinColors = async () =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/SetupStory/SetupStory_GetSkinColors`);
        return data;
    }
    catch(e)
    {
        console.log('Error in getting character skin color options.');
    }
}

export const GetOLineSkinColors = async () =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/Line/GetOLineSkinColors`);
        return data;
    }
    catch(e)
    {
        console.log('Error in getting O line skin color options.');
    }
}

export const GetBranchCandQ = async (storyId: any, storyTranslationId: any, episodeSerialNumber: any) =>
{
    try
    {
        const { data } = await axiosInstance.post(`api/Line/GetBranchDropdownData`, {storyId, storyTranslationId, episodeSerialNumber});
        return data;
    }
    catch(e)
    {
        console.log('Error in getting skin color options.');
    }
}

export const GetStoryVisuals = async (storyId: any) =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/Line/GetStoryVisuals?storyId=${storyId}`);
        return data;
    }
    catch(e)
    {
        console.log('Error in getting skin color options.');
    }
}

export const GetClothingCombinations = async () =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/Line/GetClothingCombinations`);
        return data;
    }
    catch(e)
    {
        console.log('Error in getting clothing combinations.');
    }
}

export const GetTypes = async () => {
    try {
        const { data } = await axiosInstance.get(`api/SetupStory/SetupStory_GetCharacterTypes`);
        return data;
    }
    catch (e) {
        console.log('Error in getting character type options.');
    }
}

export const SetupStoryDelete = async (id: string, url: string, alert : any, data: any, setData : any, modalBody: any, setTableLoading: any) =>
{
    try
    {
        setTableLoading(true);
        let res = await axiosInstance.get(url+id);
        setTableLoading(false);
        if (res.data.success) {
            if (modalBody === "character") {
                alert.success('Character successfuly deleted.');
                setData({...data, characters: data.filter((element : any) => element.id != id)});
            }
            else if (modalBody === "hairstyle") {
                alert.success('Hairstyle successfuly deleted.');
                setData({...data, hairstyles: data.filter((element : any) => element.id != id)});
            }
            else if (modalBody === "clothes") {
                alert.success('Clothes successfuly deleted.');
                setData({...data, clothes: data.filter((element : any) => element.id != id)});
            }
            else if (modalBody === "accessories") {
                alert.success('Accessory successfuly deleted.');
                setData({...data, accessories: data.filter((element : any) => element.id != id)});
            }
            else if (modalBody === "backgrounds") {
                alert.success('Background successfuly deleted.');
                setData({...data, backgrounds: data.filter((element : any) => element.id != id)});
            }
            else if (modalBody === "cinematic") {
                alert.success('Cinematic successfuly deleted.');
                setData({...data, cinematics: data.filter((element : any) => element.id != id)});
            }
            else if (modalBody === "audio") {
                alert.success('Audio successfuly deleted.');
                setData({...data, audio: data.filter((element : any) => element.id != id)});
            }
            else if (modalBody === "visuals") {
                alert.success('Visual successfuly deleted.');
                setData({...data, visuals: data.filter((element : any) => element.id != id)});
            }
            else if (modalBody === "items") {
                alert.success('Item successfuly deleted.');
                setData({...data, items: data.filter((element : any) => element.id != id)});
            }
            else {
                alert.success('Item successfuly deleted. Please reload the page');
            }
        }
        else {
            ErrorHandler(undefined, res.data.message, alert);
        }
    }
    catch(e)
    {
        ErrorHandler(e, 'An error occured while trying to delete item.', alert);
    }
}

export const GetTypesForSetupStory = async (url: string) =>
{
    try
    {
        const { data } = await axiosInstance.get(url);
        return data;
    }
    catch
    {
        console.log('Error in getting setup story types.')
    }
}

export const GetAssetsForSetupStory = async (url: string) =>
{
    try
    {
        const { data } = await axiosInstance.get(url);
        return data;
    }
    catch
    {
        console.log('Error in getting assets for setup story.')
    }
}

export const GetStoryGenres = async () => 
{
    try
    {
        const { data } = await axiosInstance.get('api/SetupStory/GetStoryGenres');
        return data;
    }   
    catch(e)
    {
        console.log('Error in getting story genres.');
    }
}