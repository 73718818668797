import { CButton, CForm, CInputGroup } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { HiDotsHorizontal } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { editEpisodeReadOnlyCell, validations } from '../../Lines/_Helpers';
import * as yup from 'yup';
import { Field, Formik, move } from 'formik';
import { TranslateLine } from '../../../../services/TranslationsServices';
import { useAlert } from 'react-alert';
import { ActionCreatorsForTranslations } from '../../../../store/TranslationsReducer';
import { calculateMovementOnClickLine } from './_TranslationsHelpers';

let textValidationSchema = yup.object().shape(
{
    text: yup.string(),
});

export const BasicTranslationLine = ({ line, index, inputLine, enableInput, storyId, serialNumber, language, updatingArray, isPreview } : any) =>
{
    const alert = useAlert();
    const dispatch = useDispatch();

    const { lineFormats, lineTypes, emotions, defaultLine, selectedLineIndex, serbianTranslate, englishTranslate, otherLanguageTranslate, linesFrame, currentTranslationStatus } = useSelector((state: any) => state.translationsReducer);

    const [text, setText] = useState(line.text);
    
    useEffect(() =>
    {
        let lineNumber = document.querySelector(`span[id="lineNumber${index}"]`);
        if(lineNumber) lineNumber.innerHTML = line.lineNumber;
        let lineType = document.querySelector(`span[id="lineType${index}"]`);
        if(lineType) { 
            let types = lineTypes?.filter((x:any) => x.id == line.lineType);
            lineType.innerHTML = types?.length > 0 ? types[0].label : '';
        }
        let lineFormat = document.querySelector(`span[id="lineFormat${index}"]`);
        if(lineFormat) {
            let formats = lineFormats?.filter((x:any) => x.id == line.lineFormat);
            lineFormat.innerHTML = formats?.length > 0 ? formats[0].label : '';
        }
        let characterName = document.querySelector(`span[id="characterName${index}"]`);
        if(characterName) characterName.innerHTML = line.characterName;
        let emotion = document.querySelector(`span[id="emotion${index}"]`);
        if(emotion) {
            let e = emotions?.filter((x:any) => x.id == line.emotion);
            emotion.innerHTML = e?.length > 0 ? e[0].label : '';
        }
        let text = document.querySelector(`input[id="text${index}"]`) as HTMLInputElement;
        if(text) setText(line.text);
    }, [line, lineTypes, lineFormats, emotions])

    return(
    <Formik validateOnChange={true} validateOnBlur={false} enableReinitialize initialValues={
        {
        }}
        onSubmit={(values: any) => 
        {
            dispatch(ActionCreatorsForTranslations.setUpdatingBool('updating'))
            TranslateLine({
                storyId: storyId,
                language: parseInt(language),
                episodeSerialnumber: parseInt(serialNumber),
                lineId: line.docId,
                text: text
            }, alert, dispatch)
        }}
        validationSchema={textValidationSchema}>
        {({ dirty, isValid, handleSubmit, errors }) => {
            return (
        <div>
            <CForm className={inputLine ? 'is-input-translations row' : 'row'} onSubmit={(e : any) => 
            { 
                e.preventDefault();
                handleSubmit();
            }}>
                <CInputGroup className='col' style={{marginLeft: !inputLine ? `${line.depth * parseInt(editEpisodeReadOnlyCell.minWidth.split("px")[0])}px` : "",}}
                    onClick={() =>
                    {
                        if(!inputLine && !isPreview)
                        {
                            let movement = calculateMovementOnClickLine(index, selectedLineIndex, linesFrame, serbianTranslate, englishTranslate)

                            dispatch(ActionCreatorsForTranslations.setCurrentLineForTranslations(
                            {
                                currentLineSerbian: serbianTranslate && serbianTranslate?.length != 0 && !serbianTranslate[0]?.isDefault ? serbianTranslate[movement] : defaultLine,
                                currentLineEnglish: englishTranslate && englishTranslate?.length != 0 && !englishTranslate[0]?.isDefault ? englishTranslate[movement] : defaultLine,
                                currentLineOther: otherLanguageTranslate && otherLanguageTranslate?.length != 0 && !otherLanguageTranslate[0]?.isDefault ? otherLanguageTranslate[movement] : defaultLine,
                                currentLineIndex: movement, 
                                linesStartIndex: movement + 1 > linesFrame ? movement + 1 - linesFrame : 0
                            }))
                        }
                    }}>
                    <span style={editEpisodeReadOnlyCell} id={`lineNumber${index}`}></span>
                    <span style={editEpisodeReadOnlyCell} id={`lineType${index}`}></span>
                    <span style={editEpisodeReadOnlyCell} id={`lineFormat${index}`}></span>
                    <span style={editEpisodeReadOnlyCell} id={`characterName${index}`} className="col-sm-1"></span>
                    <span style={editEpisodeReadOnlyCell} id={`emotion${index}`} className="col-sm-1"></span>
                    <input 
                        style={{flexGrow: 1}} 
                        id={`text${index}`}
                        autoComplete="off" 
                        disabled={enableInput && currentTranslationStatus != 7 ? false : true}  
                        className={ !isValid ? "validate-color-input col cursor" : line.filtered ? 'filtered-color col cursor"' : "col cursor" }
                        type="text"
                        onChange={(e : any) => { setText(e.target.value)}}
                        value={text}
                        autoFocus={inputLine}
                        >
                    </input>
                    <CButton type="submit" style={{display: 'none'}}/>
                </CInputGroup>
            </CForm>
        </div>)}}
    </Formik>)
}

export default BasicTranslationLine;