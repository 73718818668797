import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LineType } from '../../../../interfaces/lineType';
import { colorLineInsideAnswer, colorLineWithComment, createDefaultLineTypeOptions, editEpisodeReadOnlyCell, getInputLineNumber, getLineOptionsClass, isLineOptionChanged, showLineActions, validateActionChange } from '../_Helpers';
import { useAlert } from 'react-alert';
import * as yup from 'yup';
import { Field, Formik } from 'formik';
import { ActionCreators } from '../../../../store/StoriesReducer';
import { ActionCreatorsForEditEpisode } from '../../../../store/EditEpisodeReducer';
import { SaveEndOfLevelLine } from '../../../../services/EpisodeServices';
import { ErrorHandler } from '../../../Helpers/ErrorHandler';
import { CButton, CForm, CInputGroup } from '@coreui/react';
import { DeleteLine } from '../../../../services/LineServices';
import { DeleteLineConfirmationModal } from '../../../Helpers/YesNoModal';
import { LineTypes } from '../../Enums/LineTypes';
import LineSidebar from '../../LineSidebar';

const stateWithClearedLineEndOfLevel = (inputLine: boolean, selectedLineIndex: number, lines: LineType[], line: LineType, index: number, isModalOpen: boolean, maxLineNumber: number, modalClickedLine: LineType, modalType : string) => {
    return (inputLine && selectedLineIndex === -1) || isModalOpen ? 
    { 
        docId: line.docId,
        storyId: line.storyId,
        storyTranslationId: line.storyTranslationId,
        episodeId: line.episodeId,
        lineNumber: getInputLineNumber(lines,line,index,maxLineNumber,isModalOpen, modalType),
        depth: isModalOpen && modalType == 'above' ? line.depth : isModalOpen ? (line.depth ?? 0) + 
        ([LineTypes.A, LineTypes.C2, LineTypes.BA, LineTypes.TA, LineTypes.VA].includes(modalClickedLine.lineType ?? 0) ? 1 : 0) : 0,
        lineType: line.lineType,
        lineFormat: line.lineFormat,
        previousLineDocId: line.previousLineDocId,
        episodeSerialNumber: line.episodeSerialNumber,

        characterId: line.characterId,
        characterName: line.characterName,
        characterPath: line.characterPath,
        emotion: line.emotion,
        text: undefined,

        animationId: line.animationId,
        animationName: line.animationName,
        payed: undefined,
        answerNumber: undefined,
        answerQuestionId: undefined,
        questionDocId: undefined,
    
        comment: undefined,
        commentReporterName: undefined,
        commentReporterUID: undefined,
        commentAssignedToName: undefined,
        commentAssignedToUID: undefined,
        commentResolved: undefined,

        musicId: line.musicId,
        musicName: line.musicName,
        musicDescription: line.musicDescription,

        shortSoundEnabled: line.shortSoundEnabled,
        shortSoundId: line.shortSoundId,
        shortSoundName: line.shortSoundName,
        shortSoundDescription: line.shortSoundDescription,
        
        itemId: line.itemId ?? undefined,
        itemName: line.itemName ?? undefined,
        itemDescription: line.itemDescription ?? undefined,
        
        avatarEnabled: line.avatarEnabled,

        changeIdentityOn: line.changeIdentityOn,
        changeIdentityName: line.changeIdentityName,

        callPhoneEnabled: line.callPhoneEnabled != null ? line.callPhoneEnabled : undefined,
        callSmsEnabled: line.callSmsEnabled != null ? line.callSmsEnabled : undefined,
        callerCharacterId: line.callerCharacterId,
        callerCharacterName: line.callerCharacterName,
        charmingPoint: line.charmingPoint != null ? line.charmingPoint : undefined,
        premiumChoiceText: line.premiumChoiceText != null ? line.premiumChoiceText : undefined,
        premiumChoiceDescription: line.premiumChoiceDescription != null ? line.premiumChoiceDescription : undefined,
        charactersOutfit: line.charactersOutfit,

        backgroundId: line.backgroundId,
        backgroundName: line.backgroundName,
        backgroundDescription: line.backgroundDescription,
    
        clothes1Name: undefined,
        clothes2Name: undefined,
        clothes3Name: undefined,
        clothes4Name: undefined,
        clothes5Name: undefined,
        clothes6Name: undefined,
        hairstyle1Name: undefined,
        hairstyle2Name: undefined,
        hairstyle3Name: undefined,
    
        clothes1Id: undefined,
        clothes2Id: undefined,
        clothes3Id: undefined,
        clothes4Id: undefined,
        clothes5Id: undefined,
        clothes6Id: undefined,
        hairstyle1Id: undefined,
        hairstyle2Id: undefined,
        hairstyle3Id: undefined
    } 
    : 
    line
}

export const EndOfLevel = ({ line, inputLine, animations, index, episodeName, serialNumber, isPreviewInModal, inPreviewIndex  }: any) =>
{
    const dispatch = useDispatch();
    const alert = useAlert();

    let addLineSchema = yup.object().shape(
        {
            animations: yup.string(),
        });

    const [yesnomodal, setYesnomodal] = useState(false);

    const { selectedLineIndex, linesFrame, initEditEpisodeBody, lines, pickedActionColor, isOLineAdded, defaultLineTypes, pickedColorType, defaultBranchQandC, isRenderedByAction, updateCloudBool, linesStartIndex, maxLineNumber, editEpisodePassParam } = useSelector((state: any) => state.editEpisodeReducer);
    const { modalPopupBool } = useSelector((state: any) => state.storiesReducer);
    const [clearedLine, clearLine] = useState(stateWithClearedLineEndOfLevel(inputLine, selectedLineIndex,lines, line, index,modalPopupBool.isOpen, maxLineNumber, modalPopupBool.clickedLine, modalPopupBool.type));

    let currentLineTypes = createDefaultLineTypeOptions(lines, index, defaultLineTypes, defaultBranchQandC, isOLineAdded, modalPopupBool.isOpen, modalPopupBool.type);

    const setupSpanElements = () => {
        let lineNumber = document.querySelector(`span[name="lineNumber${isPreviewInModal ? inPreviewIndex : index}"]`);
        if(lineNumber) lineNumber.innerHTML = line.lineNumber;
        let lineType = document.querySelector(`span[name="lineType${isPreviewInModal ? inPreviewIndex : index}"]`);
        if(lineType) { 
            let types = defaultLineTypes?.filter((x:any) => x.id == line.lineType);
            lineType.innerHTML = types.length > 0 ? types[0].label : '';
        }
        let e_Name = document.querySelector(`span[name="e_Name${isPreviewInModal ? inPreviewIndex : index}"]`);
        if(e_Name) e_Name.innerHTML = line.e_Name;
    }

    useEffect(() => { 
        if(inputLine && !initEditEpisodeBody && !isRenderedByAction) { 
            let episodeWrapper = document.querySelector('.editEpisodeWrapper');
            if(episodeWrapper) {
                //episodeWrapper.scrollIntoView({block: 'end', behavior: 'smooth'}); 
                if(selectedLineIndex != -1)
                {
                    let e_Name = document.querySelector(`span[name="e_Name${isPreviewInModal ? inPreviewIndex : index}"]`);
                    if(e_Name) e_Name.innerHTML = line.e_Name;
                }
            }
        }
        if(!inputLine) {
            setupSpanElements()
        }
    }, [line])

    useEffect(() => 
    {
        clearLine(stateWithClearedLineEndOfLevel(inputLine, selectedLineIndex, lines, line, index, modalPopupBool.isOpen, maxLineNumber, modalPopupBool.clickedLine, modalPopupBool.type))
    }, [line,maxLineNumber])

    return(
        <Formik initialValues={
            {
                lineNumber: (inputLine && selectedLineIndex === -1) || modalPopupBool.isOpen ? getInputLineNumber(lines,line, index,maxLineNumber,modalPopupBool.isOpen, modalPopupBool.type) : line.lineNumber,
                lineType: line.lineType, episodeSerialNumber:serialNumber, e_Name: line.e_Name ?? ""
            }}
            onSubmit={(values: LineType) => 
            {
                if(line.changeIdentityOn && !line.changeIdentityName)
                {
                    alert.info('You must fill in the character name in Change Identity, because you have enabled it.')
                }
                else
                {
                    try
                    {
                        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updating'));
                        let previousLine = lines[
                            index != null && modalPopupBool.isOpen && modalPopupBool.type == 'above' ? index - 1 
                            : index != null ? index : lines.length - 1]

                        let previousLineActions;
                        if(previousLine && modalPopupBool.isOpen && modalPopupBool.type == 'above')
                        {
                            previousLineActions = {
                                backgroundId: previousLine.backgroundId,
                                backgroundPath: previousLine.backgroundPath,
                                backgroundName: previousLine.backgroundName,
                                backgroundDescription: previousLine.backgroundDescription,
                                musicId: previousLine.musicId,
                                musicPath: previousLine.musicPath,
                                musicName: previousLine.musicName,
                                musicDescription: previousLine.musicDescription,
                                shortSoundId: previousLine.shortSoundId,
                                shortSoundPath: previousLine.shortSoundPath,
                                shortSoundName: previousLine.shortSoundName,
                                shortSoundDescription: previousLine.shortSoundDescription,
                                shortSoundEnabled: previousLine.shortSoundEnabled,
                                itemId: previousLine.itemId,
                                itemName: previousLine.itemName,
                                itemDescription: previousLine.itemDescription,
                                avatarEnabled: previousLine.avatarEnabled,
                                callPhoneEnabled: previousLine.callPhoneEnabled,
                                callSmsEnabled: previousLine.callSmsEnabled,
                                callerCharacterId: previousLine.callerCharacterId,
                                callerCharacterName: previousLine.callerCharacterName,
                                actionChangeFlag: previousLine.actionChangeFlag,
                                charmingPoint: previousLine.charmingPoint,
                                premiumChoiceText: previousLine.premiumChoiceText,
                                premiumChoiceDescription: previousLine.premiumChoiceDescription,
                                charactersOutfit: previousLine.charactersOutfit
                            }
                        }

                        values = {
                            ...values,
                            ...clearedLine,
                            ...previousLineActions,
                            previousLineDocId: previousLine?.docId,
                            animationId: line.animationId,
                            animationName: line.animationName,
                            episodeName: episodeName,
                            episodeSerialNumber: serialNumber
                        }
    
                        var isChangedByAction = validateActionChange(dispatch, values, lines, lines[lines.length-1], true)
                        values = {...values, actionChangeFlag: isChangedByAction}
                        
                        SaveEndOfLevelLine(values, index, linesFrame, selectedLineIndex, dispatch, alert, modalPopupBool.isOpen, modalPopupBool.type)
                    }
                    catch
                    {
                        ErrorHandler(undefined, 'Error in adding new End Of Level line.', alert);
                    }
                }
            }}
            validationSchema={addLineSchema}>
            {({ dirty, isValid, handleSubmit }) => {
                return (
                <div>
                    <CForm id="add-line-form-submit" className="row" onSubmit={(e : any) => 
                        { 
                            if(updateCloudBool === 'updating' || (!modalPopupBool.isOpen && selectedLineIndex !== -1 && inputLine)) 
                            {
                                e.preventDefault();
                                return;
                            }   
                            e.preventDefault(); handleSubmit(); 
                        }} disabled={!inputLine}>
                        <CInputGroup className={inputLine ? 'col border-input-line' : 'col'} style={
                            { 
                                marginLeft: !inputLine ? `${line.depth * parseInt(editEpisodeReadOnlyCell.minWidth.split("px")[0])}px` : "" ,
                                border: (selectedLineIndex === index) ? `4px solid #bdbdbd` : "",
                                padding: "0",
                                paddingLeft: !inputLine ? `4px` : "0"
                            }}
                            onClick={() => {
                                if (!inputLine && !modalPopupBool.isOpen)
                                    dispatch(ActionCreatorsForEditEpisode.setCurrentLine({currentLine: line,currentLineIndex: index, linesStartIndex: index + 1 > linesFrame ? index + 1 - linesFrame : 0, linesSliceParam: index}))
                            }} >
                            <Field
                            {...(!inputLine ? 
                                {
                                    as:"span",
                                    title: showLineActions(line),
                                    style: { ...editEpisodeReadOnlyCell, backgroundColor: colorLineInsideAnswer(lines, index, pickedColorType, {...editEpisodeReadOnlyCell, backgroundColor: pickedColorType.colors.E}) },
                                    name: `lineNumber${isPreviewInModal ? inPreviewIndex : index}`,
                                    innerHTML: line.lineNumber,
                                    className: getLineOptionsClass(line)
                                } 
                                : 
                                {
                                    as: undefined,
                                    className: getLineOptionsClass(line),
                                    name: "lineNumber",
                                    style: { backgroundColor: "rgb(216, 219, 224)", maxWidth: "50px" }
                                }
                            )}
                            type="text"
                            value={(inputLine && selectedLineIndex === -1) || modalPopupBool.isOpen ? getInputLineNumber(lines,line,index,maxLineNumber,modalPopupBool.isOpen, modalPopupBool.type) : line.lineNumber}
                            disabled>
                            </Field>
                            <Field
                                {...(!inputLine ? 
                                {
                                    as:"span",
                                    style: { ...editEpisodeReadOnlyCell, backgroundColor: colorLineInsideAnswer(lines, index, pickedColorType, {...editEpisodeReadOnlyCell, backgroundColor: pickedColorType.colors.E}) },
                                    name: `lineType${isPreviewInModal ? inPreviewIndex : index}`,
                                    innerHTML: line.lineType,
                                    className: "col-auto cursor"
                                } 
                                : 
                                {
                                    as: "select",
                                    name: `lineType`,
                                    className:"col-auto cursor",
                                    value:line.lineType,
                                    onChange:(event: any) => 
                                    {
                                        if(modalPopupBool.isOpen)
                                        {
                                            dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine: {...lines[index], lineType: parseInt(event.target.value)}, isFirstTimeOpen: false }))
                                        }
                                        else
                                        {
                                            dispatch(ActionCreatorsForEditEpisode.updateLineTypeParam(parseInt(event.target.value)))
                                        }
                                    }
                                }
                            )}
                                {...((!modalPopupBool.isOpen) && (inputLine && selectedLineIndex!==-1) ? {style: { backgroundColor: "rgb(216, 219, 224)" }} : {} )}
                                type="text"
                                disabled={ (!modalPopupBool.isOpen) && ( !inputLine || selectedLineIndex !== -1)}>
                                {inputLine ? currentLineTypes : ''}
                            </Field>
                            <Field
                                {...(!inputLine || selectedLineIndex != -1 ? 
                                        {
                                            as:"span",
                                            style: editEpisodeReadOnlyCell,
                                            className: "col-sm-1 cursor",
                                            name: `e_Name${isPreviewInModal ? inPreviewIndex : index}`,
                                        } 
                                        :
                                        {
                                            as: 'p'
                                        }
                                    )
                                }
                                autoComplete="off"
                                type="text"
                                disabled={!inputLine}
                            >
                            </Field>
                            <input 
                                disabled 
                                className={inputLine ? "col input-field-cinematic-gray" : "col"} 
                                style={inputLine ? {} : editEpisodeReadOnlyCell}/>
                            {line.comment && !inputLine? <span className="tooltip-comment-exist" style={{backgroundColor: colorLineWithComment(line)}}></span> : ''}        
                        </CInputGroup >
                        {modalPopupBool.isOpen && inputLine && modalPopupBool.index === index ? <CButton className="ml-5" type="submit" color="primary" size='lg'>Add line</CButton> : '' }
                        
                        <LineSidebar  
                            dispatch={dispatch} 
                            lines={lines} 
                            line={line} 
                            selectedLineIndex={selectedLineIndex} 
                            inputLine={inputLine} 
                            isPreviewInModal={isPreviewInModal}
                            modalPopupBool={modalPopupBool}
                            setYesnomodal={setYesnomodal}
                            editEpisodePassParam={editEpisodePassParam}
                            index={index}
                            pickedActionColor={pickedActionColor}
                            isChangedByOptionFlag={isLineOptionChanged(line)}
                        />
                        
                    </CForm>
                    <DeleteLineConfirmationModal modal={yesnomodal} setModal={setYesnomodal} methodForYes={() => DeleteLine(dispatch, alert, line, index, linesFrame, selectedLineIndex, setYesnomodal, lines[index+1]?.docId) } title="Delete confirmation" message="Are you sure you want to delete selected line or line block?"/>
                </div>)
            }}
        </Formik>)
}

export default EndOfLevel;