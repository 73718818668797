import { axiosInstance } from '../axios/key';
import { ErrorHandler } from '../components/Helpers/ErrorHandler';

export const GetLanguages = async (alert : any) =>
{
    try
    {
        const { data } = await axiosInstance.get('api/Language/GetLanguagesList');
        return data;
    }
    catch(e)
    {
        ErrorHandler(e, 'There was an error while trying to get languages.', alert);
    }
}

export const GetLanguagesForStoryCreate = async (alert : any) =>
{
    try
    {
        const { data } = await axiosInstance.get('api/Language/GetCreateStoryLanguagesList');
        return data;
    }
    catch(e)
    {
        ErrorHandler(e, 'There was an error while trying to get languages.', alert);
    }
}
