import React, { useEffect, useState } from "react";
import { CButton, CForm } from "@coreui/react";
import { ErrorMessage, Field, Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import {
  SaveHairstyle,
  GetTypesForSetupStory,
  GetAssetsForSetupStory,
} from "../../../services/SetupStoryServices";
import ReactLoading from 'react-loading';
import { customStyles } from "../../Helpers/CustomStyles";
import { selectStylesForField } from "../Lines/_Helpers";
import { useAlert } from 'react-alert';
import { useDispatch } from "react-redux";
import { ActionCreators } from "../../../store/StoriesReducer";

export const AddHairstyleModalBody = ({ selectedData, data, setData, setVisible, storyId }: any) => 
{
  const dispatch = useDispatch();
  const alert = useAlert();
  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dirtySelect, setDirtySelect] = useState(false);

  const [assets, setAssets] = useState([]);

  let addHairstyleSchema = yup.object().shape({
    name: yup.string().required("You must enter display name."),
  });

  const [isAssetDisabled, setAssetDisabled] = useState((selectedData.assetDocId == null || selectedData.assetDocId == '') ? false : true);

  const [hairStyleSelectedObject, setHairStyleSelectedObject] = useState({
    id: selectedData.id,
    storyId: selectedData.storyId,
    counter: selectedData.counter,
    episode: selectedData.episode,
    name: selectedData.name,
    soName: selectedData.soName,
    character: selectedData.character,
    type: selectedData.type,
    description: selectedData.description,
    reference: selectedData.reference,
    assetDocId: selectedData.assetDocId,
    assetPath: selectedData.assetPath,
    assetName: selectedData.assetName
  });

  console.log(hairStyleSelectedObject);

  useEffect(() => {
    GetTypesForSetupStory(`api/SetupStory/SetupStory_GetHairstyleTypes`).then(
      (result: any) => {
        setTypes(
          result?.map((option: any) => {
            if(hairStyleSelectedObject.assetDocId != null && option.id === 0)
            {
              return { id: option.id, value: option.value, label: option.label, isDisabled: true };
            }  
            return { id: option.id, value: option.value, label: option.label };
          })
        );
      }
    );
    
    GetAssetsForSetupStory(`api/SetupStory/GetHairstyleAssets?storyId=${storyId}`).then((result : any) => 
    {
      setAssets(result?.map((option : any) => { return { id: option.id, value: option.id, label: option.name, src: option.path } }))
    })
  }, []);

  return (
    <Formik
      initialValues={{
        episode: selectedData.episode,
        name: selectedData.name,
        soName: selectedData.soName,
        character: selectedData.character,
        type: selectedData.type,
        description: selectedData.description,
        reference: selectedData.reference,
        assetDocId: selectedData.assetDocId,
        assetPath: selectedData.assetPath,
        assetName: selectedData.assetName
      }}
      onSubmit={(values) => {
        setLoading(true);
        SaveHairstyle({ 
          ...values,
          id: hairStyleSelectedObject.id,
          storyId: hairStyleSelectedObject.storyId,
          counter: hairStyleSelectedObject.counter,
          type: hairStyleSelectedObject.type,
          assetDocId: hairStyleSelectedObject.assetDocId,
          assetPath: hairStyleSelectedObject.assetPath,
          assetName: hairStyleSelectedObject.assetName
        }, data, setData, setVisible, setLoading, alert).then(() => {
          sessionStorage.setItem('hairstylesNum', (parseInt(sessionStorage.getItem('hairstylesNum') ?? '0') + 1).toString())
        });
      }}
      validationSchema={addHairstyleSchema}
    >
      {({ dirty, isValid, handleSubmit }) => {
        return (
          <CForm onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
            { loading ? <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1000, position: 'absolute', top: '0%', left: '0%', width: '100%', height: '100%'}}>
                            <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1001, position: 'absolute', top: '45%', left: '45%'}}>
                              <ReactLoading className="mt-3 mb-5" type='spin' color='#0080FF'/>
                              </div>
                        </div> : <div></div> }
            <div className="row-style modal-row-stp-story col-sm-12 row">
              <div className="form-group-name col-sm-6 ">
                <label htmlFor="episode">Episode</label>
                <Field
                  className="form-control"
                  name="episode"
                  type="text"
                  disabled
                  autoComplete="off"
                ></Field>
              </div>
              <div className="form-group-name col-sm-6 ">
                <label htmlFor="type">Type</label>
                <Field
                  as={Select}
                  styles={selectStylesForField}
                  menuPortalTarget={document.querySelector('body')}
                  options={types}
                  value={types?.find(
                    (x: any) => x.value == hairStyleSelectedObject.type
                  )}
                  onChange={(value: any) => {
                    setHairStyleSelectedObject({
                      ...hairStyleSelectedObject,
                      type: value.value,
                      assetDocId: value.value == 1 || value.value == 2 ? hairStyleSelectedObject.assetDocId : null,
                      assetName: value.value == 1 || value.value == 2 ? hairStyleSelectedObject.assetName : null,
                      assetPath: value.value == 1 || value.value == 2 ? hairStyleSelectedObject.assetPath : null,
                    });
                    setDirtySelect(true);
                  }}
                  isOptionDisabled={(option : any) => {return false } }
                  name="type"
                  autoComplete="off"
                ></Field>
                <ErrorMessage
                  component="p"
                  className="field-colorchange"
                  name="type"
                />
              </div>
            </div>
            <div className="row-style modal-row-stp-story col-sm-12 row">
              <div className="form-group-name col-sm-6 ">
                <label htmlFor="name">Name</label>
                <Field
                  className="form-control"
                  name="name"
                  type="text"
                  autoComplete="off"                  
                  disabled={selectedData.isDefaultHairstyle}
                  required
                ></Field>
                <ErrorMessage
                  component="p"
                  className="field-colorchange"
                  name="name"
                />
              </div>

              <div className="form-group-name col-sm-6">
                <label htmlFor="character">Character</label>
                <Field
                  className="form-control"
                  name="character"
                  type="text"
                  disabled
                  autoComplete="off"
                ></Field>
              </div>
            </div>
            <div className="row-style modal-row-stp-story col-sm-12 row">
              <div className="form-group-name col-sm-6">
                <label htmlFor="soName">SO Name</label>
                <Field
                  className="form-control"
                  name="soName"
                  type="text"
                  disabled
                  autoComplete="off"
                ></Field>
              </div>

              {hairStyleSelectedObject.type === 1 ? (
                <div />
              ) : (
                <div className="form-group-name col-sm-6">
                  <label htmlFor="description">Description</label>
                  <Field
                    className="form-control"
                    name="description"
                    autoComplete="off"
                  ></Field>
                  <ErrorMessage
                    component="p"
                    className="field-colorchange"
                    name="description"
                  />
                </div>
              )}
            </div>
            <div className="row-style modal-row-stp-story col-sm-12 row">
              {hairStyleSelectedObject.type === 1 ? (
                <div />
              ) : (
                <div className="form-group-name col-sm-6 ">
                  <label htmlFor="reference">Reference</label>
                  <Field
                    className="form-control"
                    name="reference"
                    type="text"
                    autoComplete="off"
                  ></Field>
                  <ErrorMessage
                    component="p"
                    className="field-colorchange"
                    name="reference"
                  />
                </div>
              )}
            </div>

            <div className="form-group-name col-sm-12 modal-row-stp-story">
                <label htmlFor="assetName" className="mt-3">Catalogue</label>
                <Field
                  as={Select}
                  options={assets}
                  styles={{...customStyles, ...selectStylesForField}}
                  menuPortalTarget={document.querySelector('body')}
                  value={assets?.find(
                    (x: any) => x.value == hairStyleSelectedObject.assetDocId
                  ) ?? ''}
                  isClearable
                  onChange={(value: any) => 
                  {
                    if(value)
                    {
                      setHairStyleSelectedObject({
                        ...hairStyleSelectedObject,
                        assetDocId: value.value  ,
                        assetName: value.label,
                        assetPath: value.src ,
                        type: hairStyleSelectedObject.type == 2 ? hairStyleSelectedObject.type : 1
                      });
                    }
                    else{
                      setHairStyleSelectedObject({
                        ...hairStyleSelectedObject,
                        assetDocId: null ,
                        assetName: null,
                        assetPath: null,
                        type: 0
                      });
                    }
                    setAssetDisabled(value != null ? true : false);
                    setDirtySelect(true);
                  }}
                  name="assetName"
                  autoComplete="off"
                ></Field>
                <ErrorMessage
                  component="p"
                  className="field-colorchange"
                  name="assetName"
                />
            </div>

            <div className="modal-buttons-container float-right">
              <CButton
                type="submit"
                disabled={!(dirty || dirtySelect) || !isValid}
                className="mr-2 wide"
                color="primary"
              >
                Save
              </CButton>
              <CButton className="mr-2 wide" color="secondary" onClick={() => setVisible(false)}>Cancel</CButton>
            </div>
          </CForm>
        );
      }}
    </Formik>
  );
};
