import React, { useEffect, useState } from 'react';
import { CInputGroup, CForm, CButton } from '@coreui/react';
import { Field, Formik } from 'formik';
import { AddNewLine } from '../../../services/EpisodeServices';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { ActionCreatorsForEditEpisode } from '../../../store/EditEpisodeReducer';
import { LineType } from '../../../interfaces/lineType';
import { DeleteLine } from '../../../services/LineServices';
import { colorLineInsideAnswer, colorLineWithComment, crateDefaultGotoChoices, createDefaultLineTypeOptions, editEpisodeReadOnlyCell, FormatObject, getInputLineNumber, isLineOptionChanged, showLineActions, validateActionChange } from './_Helpers';
import { ActionCreators } from '../../../store/StoriesReducer';
import { DeleteLineConfirmationModal } from '../../Helpers/YesNoModal';
import Select from 'react-select';
import '../../../styles/Lines.css'
import { LineTypes } from '../Enums/LineTypes';
import LineSidebar from '../LineSidebar';

const stateWithClearedLineGoTo = (inputLine: boolean, selectedLineIndex: number, lines: LineType[], line: LineType, index: number, isModalOpen: boolean, maxLineNumber: number, modalClickedLine: LineType, modalType : string) => {
    return (inputLine && selectedLineIndex === -1) || isModalOpen ? 
    { 
        docId: line.docId,
        storyId: line.storyId,
        storyTranslationId: line.storyTranslationId,
        episodeId: line.episodeId,
        lineNumber: getInputLineNumber(lines,line,index,maxLineNumber,isModalOpen,modalType),
        depth: isModalOpen && modalType == 'above' ? line.depth : isModalOpen ? (line.depth ?? 0) + ([LineTypes.A, LineTypes.BA, LineTypes.TA, LineTypes.VA].includes(modalClickedLine.lineType ?? 0) ? 1 : 0) : 0,
        lineType: line.lineType,
        lineFormat: undefined,
        previousLineDocId: line.previousLineDocId,
        episodeSerialNumber: line.episodeSerialNumber,

        characterId: line.characterId,
        characterName: line.characterName,
        characterPath: line.characterPath,
        emotion: line.emotion,
        text: undefined,

        animationId: undefined,
        animationName: undefined,
        payed: undefined,
        answerNumber: undefined,
        answerQuestionId: undefined,
        questionDocId: undefined,
    
        comment: undefined,
        commentReporterName: undefined,
        commentReporterUID: undefined,
        commentAssignedToName: undefined,
        commentAssignedToUID: undefined,
        commentResolved: undefined,

        musicId: line.musicId,
        musicName: line.musicName,
        musicDescription: line.musicDescription,

        shortSoundEnabled: line.shortSoundEnabled,
        shortSoundId: line.shortSoundId,
        shortSoundName: line.shortSoundName,
        shortSoundDescription: line.shortSoundDescription,

        itemId: line.itemId ?? undefined,
        itemName: line.itemName ?? undefined,
        itemDescription: line.itemDescription ?? undefined,
        
        avatarEnabled: line.avatarEnabled,

        changeIdentityOn: line.changeIdentityOn,
        changeIdentityName: line.changeIdentityName,

        callPhoneEnabled: line.callPhoneEnabled != null ? line.callPhoneEnabled : undefined,
        callSmsEnabled: line.callSmsEnabled != null ? line.callSmsEnabled : undefined,
        callerCharacterId: line.callerCharacterId,
        callerCharacterName: line.callerCharacterName,
        charmingPoint: line.charmingPoint != null ? line.charmingPoint : undefined,
        premiumChoiceText: line.premiumChoiceText != null ? line.premiumChoiceText : undefined,
        premiumChoiceDescription: line.premiumChoiceDescription != null ? line.premiumChoiceDescription : undefined,
        charactersOutfit: line.charactersOutfit,

        backgroundId: line.backgroundId,
        backgroundName: line.backgroundName,
        backgroundDescription: line.backgroundDescription,
    
        clothes1Name: undefined,
        clothes2Name: undefined,
        clothes3Name: undefined,
        clothes4Name: undefined,
        clothes5Name: undefined,
        clothes6Name: undefined,
        hairstyle1Name: undefined,
        hairstyle2Name: undefined,
        hairstyle3Name: undefined,
    
        clothes1Id: undefined,
        clothes2Id: undefined,
        clothes3Id: undefined,
        clothes4Id: undefined,
        clothes5Id: undefined,
        clothes6Id: undefined,
        hairstyle1Id: undefined,
        hairstyle2Id: undefined,
        hairstyle3Id: undefined,

        g_JumpTo: line.g_JumpTo
    } 
    : 
    line
}

export const GotoMainLine = ({ line, inputLine, index, episodeName, serialNumber, isPreviewInModal, inPreviewIndex }: any) => 
{
    const dispatch = useDispatch();
    const alert = useAlert();

    let addLineSchema = yup.object().shape(
        {
            
        });

    const { selectedLineIndex, linesFrame, linesStartIndex, initEditEpisodeBody, 
        lines, defaultLineTypes, defaultBranchQandC, updateCloudBool, pickedColorType, pickedActionColor, isRenderedByAction, isOLineAdded, maxLineNumber, editEpisodePassParam } = useSelector((state: any) => state.editEpisodeReducer);
    const { modalPopupBool } = useSelector((state: any) => state.storiesReducer);
    const [clearedLine, clearLine] = useState(stateWithClearedLineGoTo(inputLine, selectedLineIndex, lines, line, index, modalPopupBool.isOpen, maxLineNumber, modalPopupBool.clickedLine, modalPopupBool.type));
    let currentLineTypes = createDefaultLineTypeOptions(lines, index, defaultLineTypes, defaultBranchQandC, isOLineAdded, modalPopupBool.isOpen, modalPopupBool.type);

    let [currentGotoChoices, setCurrentGotoChoices] = useState(crateDefaultGotoChoices(lines, modalPopupBool.isOpen, modalPopupBool.clickedLine));

    const [yesnomodal, setYesnomodal] = useState(false);

    const setupSpanElements = () => {
        let gJumpTo = document.querySelector(`span[name="g_JumpTo${isPreviewInModal ? inPreviewIndex : index}"]`);
        if(gJumpTo) {
            gJumpTo.innerHTML = line.g_JumpTo == 0 || !line.g_JumpTo ? currentGotoChoices[0]?.label : 'A'+line.g_JumpTo;
        }

        let lineNumber = document.querySelector(`span[name="lineNumber${isPreviewInModal ? inPreviewIndex : index}"]`);
        if(lineNumber) lineNumber.innerHTML = '-';
        let lineType = document.querySelector(`span[name="lineType${isPreviewInModal ? inPreviewIndex : index}"]`);
        if(lineType) { 
            let types = defaultLineTypes?.filter((x:any) => x.id == line.lineType);
            lineType.innerHTML = types.length > 0 ? types[0].label : '';
        }
    }

    useEffect(() => { 
        if(inputLine && !initEditEpisodeBody && !isRenderedByAction) { 
            let episodeWrapper = document.querySelector('.editEpisodeWrapper');
            if(episodeWrapper) {
                //episodeWrapper.scrollIntoView({block: 'end', behavior: 'smooth'}); 
                let inputText = document.querySelector('input[name="text"]');
                (inputText as HTMLInputElement)?.focus(); 
            }
        }
        if(!inputLine) {
            setupSpanElements()
        }
    }, [line])

    useEffect(() => 
    {
        clearLine(stateWithClearedLineGoTo(inputLine, selectedLineIndex, lines, line, index, modalPopupBool.isOpen, maxLineNumber, modalPopupBool.clickedLine, modalPopupBool.type))
    }, [line,maxLineNumber])
    
    useEffect(() =>
    {
        setCurrentGotoChoices(crateDefaultGotoChoices(lines, modalPopupBool.isOpen, modalPopupBool.clickedLine))
    }, [modalPopupBool, line, lines])

    return (
        <Formik initialValues={
            {
                lineNumber: getInputLineNumber(lines, line, index, maxLineNumber,modalPopupBool.isOpen, modalPopupBool.type),
                lineType: line.lineType, episodeSerialNumber:serialNumber
            }}
            onSubmit={(values: LineType) => 
                {
                    if(line.changeIdentityOn && !line.changeIdentityName)
                    {
                        alert.info('You must fill in the character name in Change Identity, because you have enabled it.')
                    }
                    else
                    {
                        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updating'));
                        let previousLine = lines[
                            index != null && modalPopupBool.isOpen && modalPopupBool.type == 'above' ? index - 1 
                            : index != null ? index : lines.length - 1]
                        
                        let previousLineActions;
                        if(previousLine && modalPopupBool.isOpen && modalPopupBool.type == 'above')
                        {
                            previousLineActions = {
                                backgroundId: previousLine.backgroundId,
                                backgroundPath: previousLine.backgroundPath,
                                backgroundName: previousLine.backgroundName,
                                backgroundDescription: previousLine.backgroundDescription,
                                musicId: previousLine.musicId,
                                musicPath: previousLine.musicPath,
                                musicName: previousLine.musicName,
                                musicDescription: previousLine.musicDescription,
                                shortSoundId: previousLine.shortSoundId,
                                shortSoundPath: previousLine.shortSoundPath,
                                shortSoundName: previousLine.shortSoundName,
                                shortSoundDescription: previousLine.shortSoundDescription,
                                shortSoundEnabled: previousLine.shortSoundEnabled,
                                itemId: previousLine.itemId,
                                itemName: previousLine.itemName,
                                itemDescription: previousLine.itemDescription,
                                avatarEnabled: previousLine.avatarEnabled,
                                callPhoneEnabled: previousLine.callPhoneEnabled,
                                callSmsEnabled: previousLine.callSmsEnabled,
                                callerCharacterId: previousLine.callerCharacterId,
                                callerCharacterName: previousLine.callerCharacterName,
                                actionChangeFlag: previousLine.actionChangeFlag,
                                charmingPoint: previousLine.charmingPoint,
                                premiumChoiceText: previousLine.premiumChoiceText,
                                premiumChoiceDescription: previousLine.premiumChoiceDescription,
                                charactersOutfit: previousLine.charactersOutfit
                            }
                        }

                        values = {
                            ...values,
                            ...clearedLine,
                            ...previousLineActions,
                            previousLineDocId: previousLine?.docId,
                            episodeName: episodeName,
                            episodeSerialNumber: serialNumber
                        }
        
                        var isChangedByAction = validateActionChange(dispatch, values, lines, lines[lines.length-1], true)
                        values = {...values, actionChangeFlag: isChangedByAction}
                        
                        AddNewLine(values, index, linesFrame, selectedLineIndex, dispatch, alert, modalPopupBool.type, modalPopupBool.isOpen)
                    }
                }}
            validationSchema={addLineSchema}>
            {({ dirty, isValid, handleSubmit }) => {
                return (
                <div>
                    <CForm id="add-line-form-submit" className="row" onSubmit={(e : any) => 
                    { 
                        if(updateCloudBool === 'updating' || (!modalPopupBool.isOpen && selectedLineIndex !== -1 && inputLine)) 
                        {
                            e.preventDefault();
                            return;
                        } 
                        e.preventDefault(); handleSubmit(); 
                    }} disabled={!inputLine}>
                        <CInputGroup className={inputLine ? 'col border-input-line' : 'col'} style={
                            { 
                                marginLeft: !inputLine ? `${line.depth * parseInt(editEpisodeReadOnlyCell.minWidth.split("px")[0])}px` : "" ,
                                border: (selectedLineIndex === index) ? `4px solid #bdbdbd` : "",
                                padding: "0",
                                paddingLeft: !inputLine ? `4px` : "0"
                            }}
                            onClick={() => {
                                if (!inputLine && !modalPopupBool.isOpen)
                                    dispatch(ActionCreatorsForEditEpisode.setCurrentLine({currentLine: line, currentLineIndex: index, linesStartIndex: index + 1 > linesFrame ? index + 1 - linesFrame : 0, linesSliceParam: index }))
                            }} >
                            <Field
                            {...(!inputLine ? 
                                {
                                    as:"span",
                                    title: showLineActions(line),
                                    style: {...editEpisodeReadOnlyCell, backgroundColor: colorLineInsideAnswer(lines, index, pickedColorType, editEpisodeReadOnlyCell)},
                                    name: `lineNumber${isPreviewInModal ? inPreviewIndex : index}`,
                                    innerHTML: line.lineNumber,
                                } 
                                : 
                                {
                                    as: undefined,
                                    name: "lineNumber",
                                    className: 'col-auto',
                                    style: { ...editEpisodeReadOnlyCell, backgroundColor: "rgb(216, 219, 224)", maxWidth: "50px", minWidth: "50px", textAlign: 'center' }
                                }
                            )}
                            type="text"
                            value='-'
                            disabled>
                            </Field>
                            <Field
                                {...(!inputLine ? 
                                {
                                    as:"span",
                                    style: {...editEpisodeReadOnlyCell, backgroundColor: colorLineInsideAnswer(lines, index, pickedColorType, editEpisodeReadOnlyCell)},
                                    name: `lineType${isPreviewInModal ? inPreviewIndex : index}`,
                                    innerHTML: line.lineType,
                                    className: "col-auto cursor"
                                } 
                                : 
                                {
                                    as: "select",
                                    name: `lineType`,
                                    className:"col-auto cursor",
                                    value:line.lineType,
                                    onChange:(event: any) => 
                                    {
                                        if(modalPopupBool.isOpen)
                                        {
                                            dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine: {...lines[index], lineType: parseInt(event.target.value)}, isFirstTimeOpen: false }))
                                        }
                                        else
                                        {
                                            dispatch(ActionCreatorsForEditEpisode.updateLineTypeParam(parseInt(event.target.value)))
                                        }
                                    }
                                }
                            )}
                                {...((!modalPopupBool.isOpen) && (inputLine && selectedLineIndex!==-1) ? {style: { backgroundColor: "rgb(216, 219, 224)" }} : {} )}
                                type="text"
                                disabled={ (!modalPopupBool.isOpen) && ( !inputLine || selectedLineIndex !== -1)}>
                                {inputLine ? currentLineTypes : ''}
                            </Field>
                            
                            <Field
                                {...(!inputLine ? 
                                    {
                                        as:"span",
                                        style: {...editEpisodeReadOnlyCell, justifyContent: 'unset !important'},
                                        name: `g_JumpTo${isPreviewInModal ? inPreviewIndex : index}`,
                                        className: "col-sm-1 cursor"
                                    } 
                                    : 
                                    {
                                        as: Select,
                                        className:"cursor width-fix-goto-dest",
                                        name: `g_JumpTo`,
                                        value: currentGotoChoices.find((element : any) => element.value == line.g_JumpTo) ?? currentGotoChoices[0],
                                        options: currentGotoChoices,
                                        onChange:(value : any) => 
                                        { 
                                            if(modalPopupBool.isOpen)
                                            {
                                                dispatch(ActionCreators.setModalPopupBool(
                                                    {...modalPopupBool, 
                                                        currentLine: 
                                                        {...modalPopupBool.currentLine, 
                                                            g_JumpTo: parseInt(value.value)
                                                        }, isFirstTimeOpen: true 
                                                    } ))
                                            }
                                            else
                                            {
                                                if(selectedLineIndex !== -1)
                                                {
                                                    FormatObject(
                                                        dispatch, 
                                                        selectedLineIndex, 
                                                        linesStartIndex,
                                                        lines, 
                                                        { lineToUpdateId: line.docId, lineModel: {...line, g_JumpTo: parseInt(value.value) } }, alert)
                                                }
                                                dispatch(ActionCreatorsForEditEpisode.updateGJumpToParam(parseInt(value.value)))
                                            }
                                        }
                                    }
                                )}
                                type="text">
                            </Field>
                            <input disabled className={inputLine ? "col input-field-cinematic-gray" : "col"} 
                            style={inputLine ? {} : editEpisodeReadOnlyCell} value={`Go to ${line.g_JumpTo == 0 || !line.g_JumpTo ? currentGotoChoices[0]?.label.toLowerCase() : 'A'+line.g_JumpTo} line.`}/> 
                            {line.comment && !inputLine? <span className="tooltip-comment-exist" style={{backgroundColor: colorLineWithComment(line)}}></span> : ''}       
                        </CInputGroup >
                        {modalPopupBool.isOpen && inputLine && modalPopupBool.index === index  ? <CButton className="ml-5" type="submit" color="primary" size='lg'>Add line</CButton> : '' }
                        
                        <LineSidebar  
                            dispatch={dispatch} 
                            lines={lines} 
                            line={line} 
                            selectedLineIndex={selectedLineIndex} 
                            inputLine={inputLine} 
                            isPreviewInModal={isPreviewInModal}
                            modalPopupBool={modalPopupBool}
                            setYesnomodal={setYesnomodal}
                            editEpisodePassParam={editEpisodePassParam}
                            index={index}
                            pickedActionColor={pickedActionColor}
                            isChangedByOptionFlag={isLineOptionChanged(line)}
                        />
                        
                    </CForm>
                    <DeleteLineConfirmationModal modal={yesnomodal} setModal={setYesnomodal} methodForYes={() => DeleteLine(dispatch, alert, line, index, linesFrame, selectedLineIndex, setYesnomodal, lines[index+1]?.docId)  } title="Delete confirmation" message="Are you sure you want to delete selected line or line block?"/>
                </div>)
            }}
        </Formik>)
}