
export const titleDescriptionColumns = (otherName : string) =>
[
    {
        key: 'episodeSerialNumber',    
        label: 'Episode',
        sorter: false
    },
    {
        key: 'serbianDescription', 
        label: 'Serbian',
        _style: {width: '25%'},
        sorter: false
    },
    {
        key: 'englishDescription', 
        label: 'English',
        _style: {width: '25%'},
        sorter: false
    },
    {
        key: 'otherDescription', 
        label: otherName,
        _style: {width: '25%'},
        sorter: false
    }
]

export const otherTableColumns =  (otherName : string) =>
[
    {
        key: 'episodeColumnVal',    
        label: 'Episode',
        sorter: false
    },
    {
        key: 'serbianName', 
        label: 'Serbian',
        _style: {width: '25%'},
        sorter: false
    },
    {
        key: 'englishName', 
        label: 'English',
        _style: {width: '25%'},
        sorter: false
    },
    {
        key: 'otherName', 
        label: otherName,
        _style: {width: '25%'},
        sorter: false
    }
]

export const calculateMovementOnClickLine = (index : any, selectedLineIndex : any, linesFrame : any, serbianTranslate : any, englishTranslate : any) =>
{
    let movement = selectedLineIndex == -1 && serbianTranslate?.length >= linesFrame ? serbianTranslate?.length - linesFrame : 
    selectedLineIndex < linesFrame && selectedLineIndex != -1 ? -1 : 
        serbianTranslate?.length > linesFrame && selectedLineIndex != -1 ? selectedLineIndex - linesFrame : 
            serbianTranslate?.length > linesFrame ? linesFrame - 1 : 
                serbianTranslate?.length < linesFrame || selectedLineIndex != -1 ? -1 : 0;

    if(index >= serbianTranslate?.length + englishTranslate?.length)
    {
        movement = index - serbianTranslate?.length - englishTranslate?.length + movement + 1;
    }
    else if(index >= serbianTranslate?.length && index <= serbianTranslate?.length + englishTranslate?.length)
    {
        movement = index - serbianTranslate?.length + movement + 1;
    }
    else
    {
        movement = index + movement + 1;
    }

    return movement;
}

export const calculateNumberOfShowingLines = (language : any, selectedLineIndex : any, linesFrame : any) =>
{
    var numberOfLines = 0;

    if(language?.length >= linesFrame)
    {
        if(selectedLineIndex == -1)
        {
            numberOfLines = linesFrame;
        }
        else
        {
            if(selectedLineIndex >= linesFrame)
            {
                numberOfLines = linesFrame;
            }
            else
            {
                numberOfLines = selectedLineIndex + 1;
            }
        }
    }
    else
    {
        if(selectedLineIndex == -1)
        {
            numberOfLines = language?.length;
        }
        else
        {
            numberOfLines = selectedLineIndex + 1;
        }
    }

    return numberOfLines;
}

export const calculateMaxAmountLines = (language : any, selectedLineIndex : any, linesFrame : any) =>
{
    return language?.length;
}