import { FirebaseAuthConsumer } from '@react-firebase/auth';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import GoogleButton from 'react-google-button'

//project imports
import '../styles/LandingPage.css'
import { checkUserAuthRole, redirectOnAuth } from './Auth/AuthConfig';

export const LandingPage = () =>
{
    const history = useHistory();

    useEffect(() => {
        document.title = 'Story Maker - Log In'
    }, [])
    return(

    <div className="login-page-container">
        <div className="main-login">
            <div className="sign center title-login "><span className="title-holder typewriter"><span className="stry-txt">Story</span></span><span className="mkr-text"> Maker</span></div>
            <FirebaseAuthConsumer>
                {({ firebase }) => {
                        return (
                            <div className="center column-style google-login-btn">
                                <GoogleButton
                                    onClick={async () => 
                                    {
                                        try 
                                        {
                                            const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
                                            await firebase.auth().signInWithPopup(googleAuthProvider).then((response: any)=> 
                                            {     
                                                localStorage.setItem('token', response.credential.idToken); 
                                                localStorage.setItem('user', response.additionalUserInfo.profile.name);
                                                localStorage.setItem('uid', response.user.uid);

                                                response.user.getIdTokenResult().then((response : any) => 
                                                {
                                                    localStorage.setItem('language', response.claims.language);
                                                    
                                                    checkUserAuthRole(response);
                                                    
                                                    redirectOnAuth(history);
                                                });
                                            });
                                        } 
                                        catch (error) 
                                        {
                                            localStorage.clear();
                                        }
                                }}>Sign In With Google
                            </GoogleButton>
                            
                            </div>
                        )
                }}
            </FirebaseAuthConsumer>
<div className="logo-login"></div>
        </div>

</div>)
}

export default LandingPage;