import React, { useEffect, useState } from 'react';
import { CInputGroup, CForm, CButton } from '@coreui/react';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { useAlert } from 'react-alert';
import '../../../../styles/Lines.css'
import { LineType } from '../../../../interfaces/lineType';
import { LineTypes } from '../../Enums/LineTypes';
import { colorLineInsideAnswer, colorLineWithComment, createDefaultLineTypeOptions, editEpisodeReadOnlyCell, FormatObject, getInputLineNumber, getLineOptionsClass, isLineOptionChanged, showLineActions, validateActionChange } from '../_Helpers';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators } from '../../../../store/StoriesReducer';
import { ActionCreatorsForEditEpisode } from '../../../../store/EditEpisodeReducer';
import { AddNewLine } from '../../../../services/EpisodeServices';
import Select from 'react-select';
import { DeleteLineConfirmationModal } from '../../../Helpers/YesNoModal';
import { DeleteLine } from '../../../../services/LineServices';
import LineSidebar from '../../LineSidebar';

const stateWithClearedLineFashion = (inputLine: boolean, selectedLineIndex: number, lines: LineType[], line: LineType, index: number, isRenderedByAction: boolean, isModalOpen: boolean, maxLineNumber: number, modalClickedLine: LineType, modalType : string) => 
{
    return (inputLine && selectedLineIndex === -1) || isModalOpen ? 
    { 
        docId: line.docId,
        storyId: line.storyId,
        storyTranslationId: line.storyTranslationId,
        episodeId: line.episodeId,
        lineNumber: getInputLineNumber(lines,line, index,maxLineNumber,isModalOpen, modalType),
        depth: isModalOpen && modalType == 'above' ? line.depth : isModalOpen ? (line.depth ?? 0) + ([LineTypes.A, LineTypes.C2, LineTypes.BA, LineTypes.TA, LineTypes.VA].includes(modalClickedLine.lineType ?? 0) ? 1 : 0) : 0,
        lineType: line.lineType,
        lineFormat: line.lineFormat,
        episodeName: line.episodeName,
        previousLineDocId: line.previousLineDocId,
        episodeSerialNumber: line.episodeSerialNumber,

        characterId: line.characterId,
        characterName: line.characterName,
        characterPath: line.characterPath,
        emotion: line.emotion,
        text: isRenderedByAction ? line.text : undefined,

        animationId: undefined,
        animationName: undefined,
        payed: undefined,
        answerNumber: undefined,
        questionDocId: undefined,
    
        comment: undefined,
        commentReporterName: undefined,
        commentReporterUID: undefined,
        commentAssignedToName: undefined,
        commentAssignedToUID: undefined,
        commentResolved: undefined,

        musicId: line.musicId,
        musicName: line.musicName,
        musicDescription: line.musicDescription,

        shortSoundEnabled: line.shortSoundEnabled,
        shortSoundId: line.shortSoundId,
        shortSoundName: line.shortSoundName,
        shortSoundDescription: line.shortSoundDescription,

        itemId: line.itemId ?? undefined,
        itemName: line.itemName ?? undefined,
        itemDescription: line.itemDescription ?? undefined,
        
        avatarEnabled: line.avatarEnabled,

        changeIdentityOn: line.changeIdentityOn,
        changeIdentityName: line.changeIdentityName,

        callPhoneEnabled: line.callPhoneEnabled != null ? line.callPhoneEnabled : undefined,
        callSmsEnabled: line.callSmsEnabled != null ? line.callSmsEnabled : undefined,
        callerCharacterId: line.callerCharacterId,
        callerCharacterName: line.callerCharacterName,
        charmingPoint: line.charmingPoint != null ? line.charmingPoint : undefined,
        premiumChoiceText: line.premiumChoiceText != null ? line.premiumChoiceText : undefined,
        premiumChoiceDescription: line.premiumChoiceDescription != null ? line.premiumChoiceDescription : undefined,
        charactersOutfit: line.charactersOutfit,

        backgroundId: line.backgroundId,
        backgroundName: line.backgroundName,
        backgroundDescription: line.backgroundDescription,
    
        clothes1Name: line.clothes1Name,
        clothes2Name: line.clothes2Name,
        clothes3Name: line.clothes3Name,
        clothes4Name: undefined,
        clothes5Name: undefined,
        clothes6Name: undefined,
        hairstyle1Name: undefined,
        hairstyle2Name: undefined,
        hairstyle3Name: undefined,
    
        clothes1Id: line.clothes1Id,
        clothes2Id: line.clothes2Id,
        clothes3Id: line.clothes3Id,

        clothes1Payed: undefined,
        clothes2Payed: undefined,
        clothes3Payed: undefined,
        clothes4Id: undefined,
        clothes5Id: undefined,
        clothes6Id: undefined,
        hairstyle1Id: undefined,
        hairstyle2Id: undefined,
        hairstyle3Id: undefined,

        f_ClothingComb1: line.f_ClothingComb1,
        f_ClothingComb2: line.f_ClothingComb2,
        f_ClothingComb3: line.f_ClothingComb3,
        f_ClothingComb4: line.f_ClothingComb4,
        f_ClothingComb5: line.f_ClothingComb5,
    } 
    : 
    line
}

export const Fashion = ({ line, inputLine, animations, index, episodeName, serialNumber, storyId, isPreviewInModal, inPreviewIndex  }: any) =>
{
    const dispatch = useDispatch();
    const alert = useAlert(); 
    const { selectedLineIndex, linesFrame, initEditEpisodeBody, lines, pickedActionColor, linesStartIndex, defaultClothingCombinations, pickedColorType,
        defaultLineTypes, defaultBranchQandC, isRenderedByAction, updateCloudBool, isOLineAdded, maxLineNumber, editEpisodePassParam } = useSelector((state: any) => state.editEpisodeReducer);
    const { modalPopupBool } = useSelector((state: any) => state.storiesReducer);

    const [yesnomodal, setYesnomodal] = useState(false);

    const [clearedLine, clearLine] = useState(stateWithClearedLineFashion(inputLine, selectedLineIndex,lines, line,index, isRenderedByAction, modalPopupBool.isOpen, maxLineNumber, modalPopupBool.clickedLine, modalPopupBool.type));

    let currentLineTypes = createDefaultLineTypeOptions(lines, index, defaultLineTypes, defaultBranchQandC, isOLineAdded, modalPopupBool.isOpen, modalPopupBool.type);
    
    let addLineSchema = yup.object().shape(
    {
        //lineNumber: yup.string().required("Required."),
        //lineType: yup.string().required("Required."),
        //cclothes1Name: yup.string().required("Required."),
        //cclothes2Name: yup.string().required("Required."),
    });

    const setupSpanElements = () => {
        let lineNumber = document.querySelector(`span[name="lineNumber${isPreviewInModal ? inPreviewIndex : index}"]`);
        if(lineNumber) lineNumber.innerHTML = line.lineNumber;
        let lineType = document.querySelector(`span[name="lineType${isPreviewInModal ? inPreviewIndex : index}"]`);
        if(lineType) 
        { 
            let types = defaultLineTypes?.filter((x:any) => x.id == line.lineType);
            lineType.innerHTML = types.length > 0 ? types[0].label : '';
        }

        var array = [];
        let fashion1 = defaultClothingCombinations.find((element : any) => element.id == line.f_ClothingComb1)?.label
        if(fashion1) array.push(fashion1)
        let fashion2 = defaultClothingCombinations.find((element : any) => element.id == line.f_ClothingComb2)?.label
        if(fashion2) array.push(fashion2)
        let fashion3 = defaultClothingCombinations.find((element : any) => element.id == line.f_ClothingComb3)?.label
        if(fashion3) array.push(fashion3)
        let fashion4 = defaultClothingCombinations.find((element : any) => element.id == line.f_ClothingComb4)?.label
        if(fashion4) array.push(fashion4)
        let fashion5 = defaultClothingCombinations.find((element : any) => element.id == line.f_ClothingComb5)?.label
        if(fashion5) array.push(fashion5)

        let fashion = document.querySelector(`span[name="fashion${isPreviewInModal ? inPreviewIndex : index}"]`);
        if(fashion) fashion.innerHTML = array.map((element : any) => element).join(', ')
    }
    

    useEffect(() => 
    { 
        if(!inputLine) 
            setupSpanElements()            
    }, [line])
    
    useEffect(() => 
    {
        clearLine(stateWithClearedLineFashion(inputLine, selectedLineIndex, lines, line, index, isRenderedByAction, modalPopupBool.isOpen, maxLineNumber, modalPopupBool.clickedLine, modalPopupBool.type))
    }, [line,maxLineNumber])

    return(
        <Formik validateOnChange={true} validateOnBlur={false} enableReinitialize initialValues={{ lineType: line.lineType }}
            onSubmit={(values : LineType) => 
            {
                if(line.changeIdentityOn && !line.changeIdentityName)
                {
                    alert.info('You must fill in the character name in Change Identity, because you have enabled it.')
                }
                else if(line.f_ClothingComb1 != undefined 
                    || line.f_ClothingComb2 != undefined 
                    || line.f_ClothingComb3 != undefined 
                    || line.f_ClothingComb4 != undefined
                    || line.f_ClothingComb5 != undefined)
                {
                    dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updating'));

                    let previousLine = lines[
                        index != null && modalPopupBool.isOpen && modalPopupBool.type == 'above' ? index - 1 
                        : index != null ? index : lines.length - 1]
                    let previousLineActions;
                    if(previousLine && modalPopupBool.isOpen && modalPopupBool.type == 'above')
                    {
                        previousLineActions = {
                            backgroundId: previousLine.backgroundId,
                            backgroundPath: previousLine.backgroundPath,
                            backgroundName: previousLine.backgroundName,
                            backgroundDescription: previousLine.backgroundDescription,
                            musicId: previousLine.musicId,
                            musicPath: previousLine.musicPath,
                            musicName: previousLine.musicName,
                            musicDescription: previousLine.musicDescription,
                            shortSoundId: previousLine.shortSoundId,
                            shortSoundPath: previousLine.shortSoundPath,
                            shortSoundName: previousLine.shortSoundName,
                            shortSoundDescription: previousLine.shortSoundDescription,
                            shortSoundEnabled: previousLine.shortSoundEnabled,
                            itemId: previousLine.itemId,
                            itemName: previousLine.itemName,
                            itemDescription: previousLine.itemDescription,
                            avatarEnabled: previousLine.avatarEnabled,
                            callPhoneEnabled: previousLine.callPhoneEnabled,
                            callSmsEnabled: previousLine.callSmsEnabled,
                            callerCharacterId: previousLine.callerCharacterId,
                            callerCharacterName: previousLine.callerCharacterName,
                            actionChangeFlag: previousLine.actionChangeFlag,
                            charmingPoint: previousLine.charmingPoint,
                            premiumChoiceText: previousLine.premiumChoiceText,
                            premiumChoiceDescription: previousLine.premiumChoiceDescription,
                            charactersOutfit: previousLine.charactersOutfit
                        }
                    }
                    values = {
                        ...values,
                        ...clearedLine,
                        ...previousLineActions,
                        f_ClothingComb1: line.f_ClothingComb1,
                        f_ClothingComb2: line.f_ClothingComb2,
                        f_ClothingComb3: line.f_ClothingComb3,
                        f_ClothingComb4: line.f_ClothingComb4,
                        f_ClothingComb5: line.f_ClothingComb5,

                        previousLineDocId: previousLine?.docId,
                        episodeName: episodeName,
                        episodeSerialNumber: serialNumber
                    }
                    
                    var isChangedByAction = validateActionChange(dispatch, values, lines, lines[lines.length-1], true)
                    values = {...values, actionChangeFlag: isChangedByAction}

                    AddNewLine(values, index, linesFrame, selectedLineIndex, dispatch, alert, modalPopupBool.type, modalPopupBool.isOpen)
                }
                else
                {
                    alert.error('You must select clothing before trying to add new line.');
                }
            }}
            validationSchema={addLineSchema}>
            {({ dirty, isValid, handleSubmit }) => {
                return (
                <div>
                    <CForm id="add-line-form-submit" className='row' onSubmit={(e : any) => 
                    { 
                        if(updateCloudBool === 'updating' || (!modalPopupBool.isOpen && selectedLineIndex !== -1 && inputLine)) 
                        {
                            e.preventDefault();
                            return;
                        } 
                        e.preventDefault(); handleSubmit(); 
                    }} disabled={!inputLine}>
                    <CInputGroup className={inputLine ? 'col border-input-line' : 'col'} style={
                        { 
                            marginLeft: !inputLine ? `${line.depth * parseInt(editEpisodeReadOnlyCell.minWidth.split("px")[0])}px` : "" ,
                            border: (selectedLineIndex === index) ? `4px solid #bdbdbd` : "",
                            padding: "0",
                            paddingLeft: !inputLine ? `4px` : "0"
                        }}
                        onClick={(event: any) => {
                            if (!inputLine && !modalPopupBool.isOpen)
                                dispatch(ActionCreatorsForEditEpisode.setCurrentLine({currentLine: line, currentLineIndex: index, linesStartIndex: index + 1 > linesFrame ? index + 1 - linesFrame : 0, linesSliceParam: index}))
                        }}>
                            <Field
                                {...(!inputLine ? 
                                    {
                                        as:"span",
                                        title: showLineActions(line),
                                        style: {...editEpisodeReadOnlyCell, backgroundColor: colorLineInsideAnswer(lines, index, pickedColorType, editEpisodeReadOnlyCell)},
                                        name: `lineNumber${isPreviewInModal ? inPreviewIndex : index}`,
                                        className: getLineOptionsClass(line)
                                    } 
                                    : 
                                    {
                                        as: undefined,
                                        className: getLineOptionsClass(line),
                                        name: "lineNumber",
                                        style: { backgroundColor: "rgb(216, 219, 224)", maxWidth: "50px" }
                                    }
                                )}
                                type="text"
                                value={(inputLine && selectedLineIndex === -1) || modalPopupBool.isOpen ? getInputLineNumber(lines, line, index, maxLineNumber,modalPopupBool.isOpen, modalPopupBool.type) : line.lineNumber}
                                disabled>
                            </Field>
                            <Field
                                {...(!inputLine ? 
                                {
                                    as:"span",
                                    style: {...editEpisodeReadOnlyCell, backgroundColor: colorLineInsideAnswer(lines, index, pickedColorType, editEpisodeReadOnlyCell)},
                                    name: `lineType${isPreviewInModal ? inPreviewIndex : index}`,
                                    className: "col-auto cursor"
                                } 
                                : 
                                {
                                    as: "select",
                                    id: `lineType${index}`,
                                    name: `lineType`,
                                    className:"col-auto cursor",
                                    value:line.lineType,
                                    onChange:(event: any) => 
                                    {
                                        if(modalPopupBool.isOpen)
                                        {
                                            dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine: {...lines[index], lineType: parseInt(event.target.value)}, isFirstTimeOpen: false }))
                                        }
                                        else
                                        {
                                            dispatch(ActionCreatorsForEditEpisode.updateLineTypeParam(parseInt(event.target.value)))
                                        }
                                    }
                                }
                            )}
                                {...((!modalPopupBool.isOpen) && (inputLine && selectedLineIndex!==-1) ? {style: { backgroundColor: "rgb(216, 219, 224)" }} : {} )}
                                type="text"
                                disabled={ (!modalPopupBool.isOpen) && ( !inputLine || selectedLineIndex !== -1)}>
                                {inputLine ? currentLineTypes : ''}
                            </Field>
                            <Field
                                {...(!inputLine ? 
                                        {
                                            as:"span",
                                            style: {...editEpisodeReadOnlyCell, borderRight: 0},
                                            className: "col c-line-clh cursor",
                                            name: `fashion${isPreviewInModal ? inPreviewIndex : index}`,
                                        } 
                                        : 
                                        {
                                            as: Select,
                                            className:"col-sm-6 cursor p-0 border-fix-input",
                                            name: "fashion",
                                            options: defaultClothingCombinations,
                                            isClearable: false,
                                            isMulti: true,
                                            backspaceRemovesValue: true,
                                            isOptionDisabled: (option : any) => { if(option.isDisabled){ return true } else { return false }},
                                            //value: line.clothes1Id != null ? { id: line.clothes1Id, value: line.clothes1Name, label: line.clothes1Name} : clothes[0],
                                            value: defaultClothingCombinations.filter((element : any) => 
                                                element.id == line.f_ClothingComb1 
                                                || element.id == line.f_ClothingComb2 
                                                || element.id == line.f_ClothingComb3 
                                                || element.id == line.f_ClothingComb4 
                                                || element.id == line.f_ClothingComb5),
                                            onChange:(value: any) => 
                                            {
                                                if(updateCloudBool !== 'updating')
                                                {
                                                    if (value) 
                                                    { 
                                                        if(value.length <= 5)
                                                        {
                                                            var object = {
                                                                f_ClothingComb1: value[0]?.id ?? undefined,
                                                                f_ClothingComb2: value[1]?.id ?? undefined,
                                                                f_ClothingComb3: value[2]?.id ?? undefined,
                                                                f_ClothingComb4: value[3]?.id ?? undefined,
                                                                f_ClothingComb5: value[4]?.id ?? undefined
                                                            }
                                                            if(modalPopupBool.isOpen)
                                                            {
                                                                dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, 
                                                                    currentLine: {...modalPopupBool.currentLine, ...object}, isFirstTimeOpen: false }))
                                                            }
                                                            else 
                                                            {
                                                                if(selectedLineIndex != -1)
                                                                {
                                                                    FormatObject(
                                                                        dispatch, 
                                                                        selectedLineIndex, 
                                                                        linesStartIndex,
                                                                        lines, 
                                                                        { lineToUpdateId: line.docId, lineModel: {...line, ...object}}, alert)
                                                                }
                                                                dispatch(ActionCreatorsForEditEpisode.updateClothingCombParam(object))
                                                            }
                                                        }
                                                        else{
                                                            alert.info('The maximum number of selected options is 5.')
                                                        }
                                                    }
                                                    else
                                                    {
                                                        dispatch(ActionCreatorsForEditEpisode.updateClothingCombParam(undefined))
                                                    }
                                                }
                                            }}
                                    )
                                }
                                autoComplete="off"
                                type="text"
                                disabled={!inputLine}
                            >
                            </Field>
                            <input disabled className={inputLine ? "col input-field-cinematic-gray" : "col"} style={inputLine ? {} : editEpisodeReadOnlyCell}/>
                            {line.comment && !inputLine? <span className="tooltip-comment-exist" style={{backgroundColor: colorLineWithComment(line)}}></span> : ''}
                        </CInputGroup >
                        {modalPopupBool.isOpen && inputLine && modalPopupBool.index === index ? <CButton className="ml-5" type="submit" color="primary" size='lg'>Add line</CButton> : '' }
                        
                        <LineSidebar  
                            dispatch={dispatch} 
                            lines={lines} 
                            line={line} 
                            selectedLineIndex={selectedLineIndex} 
                            inputLine={inputLine} 
                            isPreviewInModal={isPreviewInModal}
                            modalPopupBool={modalPopupBool}
                            setYesnomodal={setYesnomodal}
                            editEpisodePassParam={editEpisodePassParam}
                            index={index}
                            pickedActionColor={pickedActionColor}
                            isChangedByOptionFlag={isLineOptionChanged(line)}
                        />
                        
                    </CForm>
                    <DeleteLineConfirmationModal modal={yesnomodal} setModal={setYesnomodal} methodForYes={() => DeleteLine(dispatch, alert, line, index, linesFrame, selectedLineIndex, setYesnomodal, lines[index+1]?.docId) } title="Delete confirmation" message="Are you sure you want to delete selected line or line block?"/>
                </div>)
            }}
        </Formik>)
}

export default Fashion;