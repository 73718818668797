import React, { useState } from 'react';
import { CSidebar, CSidebarNav, CNavItem, CNavLink, CSidebarHeader, CSidebarFooter, CToggler } from '@coreui/react'
import { MdGTranslate } from 'react-icons/md';
import { AiOutlinePicture, AiOutlineBarChart, AiOutlinePicLeft } from 'react-icons/ai';
import { IoIosRocket } from 'react-icons/io';
import { GiBlackBook } from 'react-icons/gi';
import { GiWhiteBook } from 'react-icons/gi';
import { FaUsersCog } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi';
import { isAnyTranslatorAndReviewer, isHidden, isNarrativeDesigner, isReviewer, isTranslator, isTranslatorExternal, isTranslatorLead, isWriter, isWriterExternal, isWriterLead } from '../Auth/AuthConfig';

export const Sidebar = (props : any) =>
{
  const history = useHistory();
  
  return(
        <CSidebar show="responsive" fixed minimize={props.minimize}>
            <CSidebarNav>
                <div className="center mt-5"><GiWhiteBook size={32} color="red"/></div>
                { props.minimize ? '' : 
                <span className="sidebar-title-header">
                    <p className="titile-bold-caps">Story</p><p className="title-light-reg">Maker</p></span> }
                <div className="sidebar-nav-items-container">
                    <div className="sidebar-menu-items-container">
                        { isWriter() || isWriterExternal() || isWriterLead() || isNarrativeDesigner() || isReviewer() || isAnyTranslatorAndReviewer() ? 
                            <CNavItem>
                                { props.minimize ?  
                                <AiOutlinePicLeft color="#481FCA" size={23} className="icon-margin cursor mt-3"  
                                    onClick={() => { history.push('/stories') }} onMouseDown={(e : any) => { if(e.button == 1) {
                                        e.preventDefault();
                                        window.open('/stories', '_blank')
                                    }} }/> 
                                : 
                                <CNavLink onClick={() => { history.push('/stories') }} onMouseDown={(e : any) => { if(e.button == 1) {
                                    e.preventDefault();
                                    window.open('/stories', '_blank')
                                }} }>
                                    <AiOutlinePicLeft color="white" size={23} className="icon-margin" />&nbsp;&nbsp;&nbsp;Stories
                                </CNavLink> }        
                            </CNavItem> : ''
                        }
                        { isTranslator() || isTranslatorLead() || isTranslatorExternal() ? 
                            <CNavItem>
                            { props.minimize ? 
                            <MdGTranslate color="#481FCA" size={23} className="icon-margin cursor mt-3" onClick={() => { history.push('/translations') }} 
                            onMouseDown={(e : any) => { if(e.button == 1) {
                                e.preventDefault();
                                window.open('/translations', '_blank')
                            }} }/> : 
                                <CNavLink onClick={() => { history.push('/translations') }} onMouseDown={(e : any) => { if(e.button == 1) {
                                    e.preventDefault();
                                    window.open('/translations', '_blank')
                                }} }>
                                    <MdGTranslate color="white" size={23} className="icon-margin" />&nbsp;&nbsp;&nbsp;Translations
                                </CNavLink> }
                            </CNavItem> : '' 
                        }
                        { isWriter() || isWriterLead() || isNarrativeDesigner() ?
                            <CNavItem>
                            { props.minimize ? <AiOutlinePicture color="#481FCA" size={23} className="icon-margin cursor mt-3"  
                            onClick={() => { history.push('/art-catalog') }} onMouseDown={(e : any) => { if(e.button == 1) {
                                e.preventDefault();
                                window.open('/art-catalog', '_blank')
                            }} }/> :
                                <CNavLink onClick={() => { history.push('/art-catalog') }} 
                                onMouseDown={(e : any) => { if(e.button == 1) {
                                    e.preventDefault();
                                    window.open('/art-catalog', '_blank')
                                }} }><AiOutlinePicture color="white" size={23} className="icon-margin" />&nbsp;&nbsp;&nbsp;Art Catalog</CNavLink> }
                            </CNavItem> : ''
                        }
                                    
                        { !isHidden() ?
                            <CNavItem>
                                { props.minimize ? <AiOutlineBarChart color="#481FCA" size={23} className="icon-margin cursor mt-3"  
                                onClick={() => { history.push('/analytics') }}
                                onMouseDown={(e : any) => { if(e.button == 1) {
                                    e.preventDefault();
                                    window.open('/analytics', '_blank')
                                }} }/> :
                                <CNavLink onClick={() => { history.push('/analytics') }} 
                                onMouseDown={(e : any) => { if(e.button == 1) {
                                    e.preventDefault();
                                    window.open('/analytics', '_blank')
                                }} }><AiOutlineBarChart color="white" size={23} className="icon-margin" />&nbsp;&nbsp;&nbsp;Analytics</CNavLink> }
                            </CNavItem> : '' 
                        }

                        { isTranslatorLead() && isNarrativeDesigner() && isWriterLead() ?
                            <CNavItem>
                                { props.minimize ? <FaUsersCog color="#481FCA" size={23} className="icon-margin cursor mt-3"  onClick={() => { history.push('/user-management') }}
                                onMouseDown={(e : any) => { if(e.button == 1) {
                                    e.preventDefault();
                                    window.open('/user-management', '_blank')
                                }} }
                                /> :
                                <CNavLink onClick={() => { history.push('/user-management') }}
                                onMouseDown={(e : any) => { if(e.button == 1) {
                                    e.preventDefault();
                                    window.open('/user-management', '_blank')
                                }} }><FaUsersCog color="white" size={23} className="icon-margin" />&nbsp;&nbsp;&nbsp;User Management</CNavLink> }
                            </CNavItem> : '' 
                        }
                    </div>
                    <div className="sidebar-menu-items-footer-container">
                        <CSidebarFooter style={{}}>
                            <CNavItem>
                                <CNavLink onClick={() => { history.push('/getting-started') }} 
                                onMouseDown={(e : any) => { if(e.button == 1) {
                                    e.preventDefault();
                                    window.open('/getting-started', '_blank')
                                }} }><IoIosRocket color="white" size={21} className="icon-margin" />&nbsp;&nbsp;&nbsp;Getting Started</CNavLink>
                            </CNavItem>
                            { isWriter() || isWriterExternal() || isWriterLead() || isNarrativeDesigner() ? 
                                <CNavItem>
                                    <CNavLink 
                                onMouseDown={(e : any) => { if(e.button == 1) {
                                    e.preventDefault();
                                    window.open('/writers-guide', '_blank')
                                }} } onClick={() => { history.push('/writers-guide') }}><GiBlackBook color="white" size={21} className="icon-margin" />&nbsp;&nbsp;&nbsp;Writer's guide</CNavLink>
                                </CNavItem> : '' 
                            } 
                            {/* treba li ovde da se napravi reviewer guide???? */}
                            { isTranslator() || isTranslatorLead() ? 
                                <CNavItem>
                                    <CNavLink onClick={() => { history.push('/translators-guide') }} 
                                onMouseDown={(e : any) => { if(e.button == 1) {
                                    e.preventDefault();
                                    window.open('/translators-guide', '_blank')
                                }} } ><GiBlackBook color="white" size={21} className="icon-margin" />&nbsp;&nbsp;&nbsp;Translator's guide</CNavLink>
                                </CNavItem> : '' 
                            }
                            { isWriter() || isWriterExternal() || isWriterLead() || isNarrativeDesigner() ? 
                                <CNavItem>
                                    <CNavLink onClick={() => { history.push('/artists-guide') }} 
                                onMouseDown={(e : any) => { if(e.button == 1) {
                                    e.preventDefault();
                                    window.open('/artists-guide', '_blank')
                                }} } ><GiBlackBook color="white" size={21} className="icon-margin" />&nbsp;&nbsp;&nbsp;Artist's guide</CNavLink>
                                </CNavItem> : '' 
                            }
                        </CSidebarFooter>
                        <div className="sidebar-hide">
                            <span className={props.minimize ? "mt-5 cursor center" : "cursor"} onClick={() => { props.modifyState() }}>
                                { props.minimize ? <FiMaximize2 size={24} className="ml-3" /> : <FiMinimize2 size={24}/>}
                            </span>
                        </div>
                    </div>
                </div>
            </CSidebarNav>
        </CSidebar>
    )
}

export default Sidebar;