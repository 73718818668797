export const config = 
{
    apiKey: "AIzaSyA-xZiKJfw92Ss5uWmtGyoh21_qE6WZ0J4",
    projectId: "wg-story-maker",
    databaseURL: "localhost",
    authDomain: "wg-story-maker.firebaseapp.com",
};

export const checkUserAuthRole = (response : any) =>
{
    if(response.claims.writer)
        localStorage.setItem('role=writer', 'writer');
    if(response.claims.narrativeDesigner)
        localStorage.setItem('role=narrativeDesigner', 'narrativeDesigner');
    if(response.claims.translator)
        localStorage.setItem('role=translator', 'translator');
    if(response.claims.writerLead)
        localStorage.setItem('role=writerLead', 'writerLead');
    if(response.claims.writerExternal)
        localStorage.setItem('role=writerExternal', 'writerExternal');
    if(response.claims.translatorLead)
        localStorage.setItem('role=translatorLead', 'translatorLead');
    if(response.claims.translatorExternal)
        localStorage.setItem('role=translatorExternal', 'translatorExternal');
    if(response.claims.reviewer)
        localStorage.setItem('role=reviewer', 'reviewer');
}

export const isWriter = () =>
{
    if(localStorage.getItem('role=writer') !== null)
        return true;
    else
        return false;
}

export const isNarrativeDesigner = () =>
{
    if(localStorage.getItem('role=narrativeDesigner') !== null)
        return true;
    else
        return false;
}

export const isTranslator = () =>
{
    if(localStorage.getItem('role=translator') !== null)
        return true;
    else
        return false;
}

export const isWriterLead = () =>
{
    if(localStorage.getItem('role=writerLead') !== null)
        return true;
    else
        return false;
}

export const isWriterExternal = () =>
{
    if(localStorage.getItem('role=writerExternal') !== null)
        return true;
    else
        return false;
}

export const isTranslatorLead = () =>
{
    if(localStorage.getItem('role=translatorLead') !== null)
        return true;
    else
        return false;
}

export const isTranslatorExternal = () =>
{
    if(localStorage.getItem('role=translatorExternal') !== null)
        return true;
    else
        return false;
}

export const isReviewer = () => 
{ 
    if(localStorage.getItem('role=reviewer') !== null)
        return true;
    else 
        return false;
}

export const isOnlyReviewer = () => {
    if(!localStorage.getItem('role=writer') && !localStorage.getItem('role=writerLead') &&
        !localStorage.getItem('role=writerExternal') && !localStorage.getItem('role=translator') && 
        !localStorage.getItem('role=translatorLead') && !localStorage.getItem('role=translatorExternal') && 
        !localStorage.getItem('role=narrativeDesigner') && localStorage.getItem('role=reviewer'))
        return true;
    else 
        return false;
}

export const isAnyTranslatorAndReviewer = () => {
    if((localStorage.getItem('role=translator') || localStorage.getItem('role=translatorExternal') || localStorage.getItem('role=translatorLead')) && localStorage.getItem('role=reviewer'))
        return true; 
    else 
        return false;
}

export const isAnyWriter = () => { 
    if(localStorage.getItem('role=writer') || localStorage.getItem('role=writerExternal') || localStorage.getItem('role=writerLead') || localStorage.getItem('role=narrativeDesigner'))
        return true; 
    else
        return false; 
}

export const isHidden = () =>
{
    return true;
}

export const redirectOnAuth = (history : any) =>
{
    isWriter() || isWriterExternal() || isWriterLead() || isNarrativeDesigner() || isReviewer() ? history.push('/stories') : 
    isTranslator() || isTranslatorLead() || isTranslatorExternal() ? history.push('/translations') : history.push('/getting-started')
}

