import React, { useEffect, useState } from 'react';
import { CButton } from '@coreui/react';
import Select from 'react-select';
import { GetSelectValuesForAssets, UpdateAssets } from '../../../services/ArtCatalog';
import { useDispatch } from 'react-redux';
import { ActionCreatorsForArtCatalog } from '../../../store/ArtCatalogReducer';
import { selectStylesForField } from '../Lines/_Helpers';
import { SaveChangesSessionLogger } from '../../../modules/firebaseDetailedLoggers';

export const AudioPictureBody = ({audioTypes, visible, setVisible, alert} : any) =>
{
    const dispatch = useDispatch();
    
    const [tags, setTags] = useState([]);

    return(
        <div>
            <label>Type</label>
            <Select
                styles={selectStylesForField}
                menuPortalTarget={document.querySelector('body')}
                options={audioTypes} 
                className="art-cat-filters-row"
                value={audioTypes.find((element : any) => element.value === visible.object.audioType)}
                onChange={(value : any) => 
                { 
                    GetSelectValuesForAssets('api/ArtCatalog/GetAudioTags?audioType='+`${value.label}`).then((res) => 
                    { 
                        setTags(res)
                    })
                    setVisible({...visible, object: {...visible.object, audioType: value.value }}) 
                }}
            />
            <label className="mt-3">Audio Tags</label>
            <Select 
                styles={selectStylesForField}
                menuPortalTarget={document.querySelector('body')}
                isMulti 
                options={tags} 
                value={visible.object.audioTagAttributes?.map((el : any, index : any) => { return { id: index, value: index, label: el }})}
                onChange={(value : any) => 
                { 
                    setVisible({...visible, object: {...visible.object, audioTagAttributes: value?.map((el : any) => el.label) }})
                }}
            />
            <CButton 
                color="primary" 
                className="mt-5" 
                onClick={() => 
                {
                    const saveChangesSession = new SaveChangesSessionLogger();
                                    
                    saveChangesSession.asset_name = 'Audio'
                    saveChangesSession.timestamp = new Date().toUTCString();
                    saveChangesSession.session_id = localStorage.getItem('sessionId') ?? '';
                    saveChangesSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                    saveChangesSession.user_id = localStorage.getItem('uid') ?? '';
                    
                    saveChangesSession.logEvent();

                    UpdateAssets('api/ArtCatalog/Update_AudioAssetTags', 
                        { 
                            docId: visible.object?.docId, 
                            type: parseInt(visible.object?.audioType),
                            shortSoundTags: parseInt(visible.object?.audioType) == 1 ? visible.object?.audioTagAttributes : undefined,
                            musicThemeTags: parseInt(visible.object?.audioType) == 2 ? visible.object?.audioTagAttributes : undefined,
                        }, alert)
                    dispatch(ActionCreatorsForArtCatalog.updateAudio(
                    {
                        id: visible.object?.docId, 
                        type: parseInt(visible.object?.audioType), 
                        audioTagAttributes: visible.object?.audioTagAttributes, 
                    }))
                }}
            >Save Changes</CButton>
        </div>
    )
}