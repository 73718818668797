import React, { useState } from 'react';
import { IoClose, IoCloseCircle, IoInformationCircleSharp } from "react-icons/io5";
import { transitions, positions } from 'react-alert';

import '../../styles/AlertTemplate.css'

export const options = 
{
    position: positions.TOP_CENTER,
    timeout: 5000,
    offset: '30px',
    transition: transitions.SCALE,
    containerStyle: {
        zIndex: 10001
    }
}

export const optionsForChangeStatus = 
{
    position: positions.TOP_CENTER,
    timeout: 0,
    offset: '30px',
    transition: transitions.SCALE,
    containerStyle: {
        zIndex: 10001,
        pointerEvents: 'none !important'
    }
}

export const AlertTemplate = ({ style, options, message, close }: any) =>
{
    return(
            <div className="row">
                <div className="col-xs-12 col-sm-6 col-sm-offset-3">
                    <div className="new-message-box">
                        <div className={`new-message-box-${options.type === 'error' ? 'danger' : options.type}`}>
                            <div className={`tip-box-${options.type === 'error' ? 'danger' : options.type}`}> 
                                <IoInformationCircleSharp size="24" /> 
                                &nbsp;<div>{message?.split("<br/>")?.map((place : any, index : any) => <p key={index}>{place}</p>)}</div>
                            </div>
                         </div>
                     </div>
                </div>
            </div>)
}

export const AlertTemplateForChangeStatus = ({ style, options, message, close }: any) =>
{
    return(
            <div className="row">
                <div className="col-xs-12 col-sm-6 col-sm-offset-3">
                    <div className="new-message-box">
                        <div className={`new-message-box-${options.type === 'error' ? 'danger' : options.type}`}>
                            <div className={`tip-box-${options.type === 'error' ? 'danger' : options.type}`}> 
                                <IoInformationCircleSharp size="24" /> 
                                <IoCloseCircle size="24" style={{float: 'right'}} className="cursor" onClick={close}>X</IoCloseCircle>
                                &nbsp;<div>{message.split("<br/>").map((place : any, index : any) => <p key={index}>{place}</p>)}</div>
                            </div>
                         </div>
                     </div>
                </div>
            </div>)
}

export default AlertTemplate;

