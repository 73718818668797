import '@coreui/coreui/dist/css/coreui.min.css'
import * as React from 'react';
import { createContext } from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { FirebaseAuthProvider } from '@react-firebase/auth'
import { BrowserRouter } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import firebase from "firebase/app"
import 'firebase/analytics'
import "firebase/auth";
import { Provider as AlertProvider } from 'react-alert';
import { useDispatch } from 'react-redux';

//project imports
import store from './store/store'
import { App } from './App';
import { config } from './components/Auth/AuthConfig';
import AlertTemplate, { AlertTemplateForChangeStatus, options, optionsForChangeStatus } from './components/Layout/AlertTemplate';
import { setupScriptsForGA } from './components/Common/Lines/_Helpers';
import { GetGAScript, GetGASrcURL } from './services/GAServices';
import {firebaseConfig} from './config/firebaseConfig'

export const contentForChangeEpisodeStatus = createContext(null);

GetGASrcURL().then((srcUrlResponse) => 
{
  GetGAScript().then((scrScriptResponse) => 
  {
    if(srcUrlResponse && scrScriptResponse) 
      setupScriptsForGA(srcUrlResponse, scrScriptResponse);
  });
});

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...options}>
    <AlertProvider template={AlertTemplateForChangeStatus} {...optionsForChangeStatus} context={contentForChangeEpisodeStatus}>
      <Provider store={store}>
        <FirebaseAuthProvider firebase={firebase} {...config}>
          <BrowserRouter >
            <App />
          </BrowserRouter>
        </FirebaseAuthProvider>
      </Provider>
    </AlertProvider>
  </AlertProvider>,
  document.getElementById('root'));

registerServiceWorker();
