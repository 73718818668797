import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreatorsForEditEpisode } from '../../store/EditEpisodeReducer';
import { FormatObject, validateActionChange } from './Lines/_Helpers';
import { useAlert } from 'react-alert';
import { ActionCreators } from '../../store/StoriesReducer';

export const EpisodeActionsEffect = ({
    actionObject, 
    backgroundDescription, 
    musicThemeDescription, 
    shortSoundDescription, 
    showItemDescription,
    notificationCharmingPoint, 
    premiumChoiceText, 
    premiumChoiceDescription, 
    setBackgroundDescription,
    setMusicThemeDescription,
    setShortSoundDescription,
    setShowItemDescription,
    setNotificationCharmingPoint,
    setPremiumChoiceText,
    setPremiumChoiceDescription,
    previousLineUpdate} : any) =>
{
    const dispatch = useDispatch();
    
    useEffect(() => 
    {
        const delayDebounce = setTimeout(() => 
        { 
            if(backgroundDescription && backgroundDescription !== actionObject.backgroundDescription) 
            {
                if(previousLineUpdate)
                {
                    dispatch(ActionCreators.updateBackgroundDescriptionInPopup(backgroundDescription)); 
                }
                else
                {
                    dispatch(ActionCreatorsForEditEpisode.updateBackgroundDescription(backgroundDescription)); 
                }
            }
        }, 1500)
        return () => clearTimeout(delayDebounce);
    }, [backgroundDescription]);

    useEffect(() => 
    {
        const delayDebounce = setTimeout(() => 
        { 
            if(musicThemeDescription && musicThemeDescription !== actionObject.musicDescription) 
            {
                if(previousLineUpdate)
                {
                    dispatch(ActionCreators.updateMusicThemeDescriptionInPopup(musicThemeDescription));
                }
                else
                {
                    dispatch(ActionCreatorsForEditEpisode.updateMusicThemeDescription(musicThemeDescription))
                }
            }
        }, 1500)
        return () => clearTimeout(delayDebounce);
    }, [musicThemeDescription]);

    useEffect(() => 
    {
        const delayDebounce = setTimeout(() => 
        { 
            if(shortSoundDescription && shortSoundDescription !== actionObject.shortSoundDescription)
            {
                if(previousLineUpdate)
                {
                    dispatch(ActionCreators.updateShortSoundDescriptionInPopup(shortSoundDescription));
                }
                else
                {
                    dispatch(ActionCreatorsForEditEpisode.updateShortSoundDescription(shortSoundDescription));
                }
            } 
        }, 1500)
        return () => clearTimeout(delayDebounce);
    }, [shortSoundDescription]);

    useEffect(() => 
    {
        const delayDebounce = setTimeout(() => 
        { 
            if(showItemDescription && showItemDescription !== actionObject.itemDescription)
            {
                if(previousLineUpdate)
                {
                    dispatch(ActionCreators.updateShowItemDescriptionInPopup(showItemDescription));
                }
                else
                {
                    dispatch(ActionCreatorsForEditEpisode.updateShowItemDescription(showItemDescription));
                }
            } 
        }, 500)
        return () => clearTimeout(delayDebounce);
    }, [showItemDescription]);
    
    useEffect(() => 
    {
        const delayDebounce = setTimeout(() => 
        { 
            if(notificationCharmingPoint !== actionObject.charmingPoint) 
            {
                if(previousLineUpdate)
                {
                    dispatch(ActionCreators.updateCharmingPointInPopup(notificationCharmingPoint))
                }
                else
                {
                    dispatch(ActionCreatorsForEditEpisode.updateCharmingPoint(notificationCharmingPoint));
                }
            }
        }, 500)
        return () => clearTimeout(delayDebounce);
    }, [notificationCharmingPoint]);
    
    useEffect(() => 
    {
        const delayDebounce = setTimeout(() => 
        { 
            if(premiumChoiceText !== actionObject.premiumChoiceText) 
            {
                if(previousLineUpdate)
                {
                    dispatch(ActionCreators.updatePremiumChoiceTextInPopup(premiumChoiceText));
                }
                else
                {
                    dispatch(ActionCreatorsForEditEpisode.updatePremiumChoiceText(premiumChoiceText));
                }
            }
        }, 500)
        return () => clearTimeout(delayDebounce);
    }, [premiumChoiceText]);

    useEffect(() => 
    {
        const delayDebounce = setTimeout(() => 
        { 
            if(premiumChoiceDescription !== actionObject.premiumChoiceDescription) 
            {   
                if(previousLineUpdate)
                {
                    dispatch(ActionCreators.updatePremiumChoiceTextDescriptionInPopup(premiumChoiceDescription));
                }
                else
                {
                    dispatch(ActionCreatorsForEditEpisode.updatePremiumChoiceDescription(premiumChoiceDescription));
                }
            }
        }, 500)
        return () => clearTimeout(delayDebounce);
    }, [premiumChoiceDescription]);

    useEffect(() => 
    {
        if(setBackgroundDescription)
            setBackgroundDescription(actionObject.backgroundDescription);
    }, [actionObject.backgroundDescription])
 
    useEffect(() => 
    {
        if(setMusicThemeDescription)
            setMusicThemeDescription(actionObject.musicDescription);
    }, [actionObject.musicDescription]) 
    
    useEffect(() => 
    {
        if(setShortSoundDescription)
            setShortSoundDescription(actionObject.shortSoundDescription);
    }, [actionObject.shortSoundDescription])

    useEffect(() => 
    {
        if(setShowItemDescription)
            setShowItemDescription(actionObject.itemDescription);
    }, [actionObject.itemDescription])
    
    useEffect(() => 
    {
        if(setNotificationCharmingPoint)
            setNotificationCharmingPoint(actionObject.charmingPoint);
    }, [actionObject.charmingPoint])  
       
    useEffect(() => 
    {
        if(setPremiumChoiceText)
            setPremiumChoiceText(actionObject.premiumChoiceText);
    }, [actionObject.premiumChoiceText]) 

    useEffect(() => 
    {
        if(setPremiumChoiceDescription)
            setPremiumChoiceDescription(actionObject.premiumChoiceDescription);
    }, [actionObject.premiumChoiceDescription]) 

    return(<div/>)
}

export default EpisodeActionsEffect;