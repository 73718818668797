import { axiosInstance } from '../axios/key';
import { GameType } from '../components/Common/Enums/GameType';
import { generateBAnumbers, generateLineNumbers, generateQAnumbers, generateTAnumbers, generateVAnumbers, updateLine } from '../components/Common/Lines/_Helpers';
import { ErrorHandler } from '../components/Helpers/ErrorHandler';
import { LineType } from '../interfaces/lineType';
import { EpisodeAddedLogger, StoryStatusChangedLogger } from '../modules/firebaseAnalyticsLoggers';
import { ActionCreatorsForEditEpisode, defaultLine } from '../store/EditEpisodeReducer';
import { ActionCreators } from '../store/StoriesReducer';
import { ActionCreatorsForTranslations } from '../store/TranslationsReducer';
import { GetStoryDetailsForSetupStory } from './StoriesServices';

export const AddEpisode = async (episode: any, dispatch: any, alert: any, history: any, isFirstEpisode : boolean, authorName : string)  => 
{
    try
    {
        const { data } = await axiosInstance.post('api/Episode/AddStoryEpisode', episode);

        dispatch(ActionCreators.addEpisode(data));
        dispatch(ActionCreators.updateStory(data));

        const episodeAddedLogger = new EpisodeAddedLogger()
        episodeAddedLogger.story_name = episode.storyName;
        episodeAddedLogger.game_type = episode.storyGameTypeString;
        episodeAddedLogger.logged_in_user_name = localStorage.getItem('user') ?? '';
        episodeAddedLogger.user_id = localStorage.getItem('uid') ?? '';
        episodeAddedLogger.episode_number = data.serialNumber;
        episodeAddedLogger.episode_title = episode.title;
        episodeAddedLogger.logEvent()

        if(isFirstEpisode)
        {
            GetStoryDetailsForSetupStory(dispatch, episode.storyId, episode.storyTranslationId).then((response) => 
            {
                const storyStatusChangedLogger = new StoryStatusChangedLogger();
                storyStatusChangedLogger.story_name = episode.storyName;
                storyStatusChangedLogger.author_name = authorName;
                storyStatusChangedLogger.user_id = localStorage.getItem('uid') ?? '';
                storyStatusChangedLogger.language = response.languageString;
                storyStatusChangedLogger.character_type = response.charactersTypeString;
                storyStatusChangedLogger.category = response.storyGenre;
                storyStatusChangedLogger.internal_writer = false; //currently info not available in the app, to be added
                storyStatusChangedLogger.game_type = response.storyGameTypeString;
                storyStatusChangedLogger.new_status = response.status;
                storyStatusChangedLogger.number_of_episodes = response.episodesNum;
                storyStatusChangedLogger.logged_in_user_name = localStorage.getItem('user') ?? '';

                storyStatusChangedLogger.logEvent()
            });
        }

        alert.success('Successfuly added a new episode.');
        
        history.push(`/edit-episode/&storyId=${episode.storyId}&storyTransId=${episode.storyTranslationId}&episodeId=${data.id}`);

    }
    catch(e)
    {
        ErrorHandler(e, 'An error occured while trying to add new episode.', alert);
    }
}

export const GetConcreteStory = async (storyId: any, translationId: any, dispatch : any, alert : any) =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/Episode/GetStoryEpisodes?storyId=${storyId}&storyTranslationId=${translationId}`);
        dispatch(ActionCreators.setConcreteStory(data));
    }
    catch(e)
    {
        ErrorHandler(e, 'There was an error while trying to get concrete story.', alert);
    }
}

export const ExportEpisode = async (storyId: any, translationId: any, episodeId: any, alert : any, gameType : number) => {
    try
    {
        if(gameType == GameType.LoveStory)
        {
            return await axiosInstance.get(`api/Admin/LovestoryExport?storyId=${storyId}&translationId=${translationId}&episodeId=${episodeId}`);
        }
        else if(gameType == GameType.Lunescape)
        {
            return await axiosInstance.get(`api/Admin/LunescapeExport?storyId=${storyId}&translationId=${translationId}&episodeId=${episodeId}`);
        }
        else return;
    }
    catch(e)
    {
        ErrorHandler(e, 'There was an error while trying to get concrete story.', alert);
        return null;
    }
}

export const GetEpisodesForTranslations = async (storyId: any, translationId: any, dispatch : any, alert : any) =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/Translations/GetStoryEpisodes?storyId=${storyId}&storyTranslationId=${translationId}`);
        dispatch(ActionCreatorsForTranslations.setTranslationEpisodes(data));
    }
    catch(e)
    {
        ErrorHandler(e, 'There was an error while trying to get episodes for translations.', alert);
    }
}

export const GetEpisodesForTranslationsSelect = async (storyId: any, alert : any) =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/Translations/GetStoryEpisodesForSelect?storyId=${storyId}`);
        return data.map((value : any) => { return { id: value.id, label: value.title, value: value.id, serialNumber: value.serialNumber, language: value.language, episodeStatusEnum: value.episodeStatusEnum }});
    }
    catch(e)
    {
        ErrorHandler(e, 'There was an error while trying to get episodes for translations.', alert);
    }
}

export const AddNewLine = async (newLine: LineType, clickedLineIndex: number, linesFrame: number, selectedLineIndex: number, dispatch: any, alert: any, modalType : string, modalOpen : boolean) =>
{
    try
    {  
        dispatch(ActionCreators.setModalPopupBool({ type: 'none', isOpen: false, index: undefined, currentLine: defaultLine, clickedLine: defaultLine })) 
        await axiosInstance.post('api/Line/SaveLine', newLine).then((result: any) => {
            if (result.data.success) {
                var data = result.data.model;
                updateLine(dispatch, data, newLine);
                dispatch(ActionCreatorsForEditEpisode.addNewLine({newLine, clickedLineIndex, linesFrame, selectedLineIndex, modalOpen, modalType}));
                dispatch(ActionCreatorsForEditEpisode.setEpisodeSummary(data.episodeSummary));
                //dispatch(ActionCreatorsForEditEpisode.addCharactersInLine({id: newLine.characterId, value: newLine.characterId, label: newLine.characterName, path: undefined}))
                
                dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updated'));
            }
            else {
                dispatch(ActionCreators.setModalPopupBool({ type: 'none', isOpen: false, index: undefined, currentLine: defaultLine, clickedLine: defaultLine  })) 
                dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('error'));
                ErrorHandler(undefined, result.data.message, alert);
            }
        });
    }
    catch (e)
    {
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('error'));
        ErrorHandler(e, 'An error occured while trying to add new line.', alert);
    }
}

export const AddNewLines = async (newLines: LineType [], clickedLineIndex: number, linesFrame: number, selectedLineIndex: number, dispatch: any, alert: any, modalType : string, modalOpen : boolean) =>
{
    try
    {
        dispatch(ActionCreators.setModalPopupBool({ type: 'none', isOpen: false, index: undefined, currentLine: defaultLine, clickedLine: defaultLine })) 
        const { data } = await axiosInstance.post('api/Line/SaveMultipleLines', newLines);
        newLines.map((line: LineType, index: number) => {
            updateLine(dispatch, data[index], line);
        });
        //dispatch(ActionCreatorsForAddingLine.setLoadingLine(false));
        dispatch(ActionCreatorsForEditEpisode.addNewLines({newLines, clickedLineIndex, linesFrame, selectedLineIndex, modalOpen, modalType}));
        dispatch(ActionCreatorsForEditEpisode.setEpisodeSummary(data[newLines.length - 1].episodeSummary));
        
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updated'));
    }
    catch (e)
    {
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('error'));
        ErrorHandler(e, 'An error occured while trying to add new line.', alert);
    }
}

export const GetEpisodeDetails = async (dispatch: any, episodeDetails: any) =>
{
    try
    {         
        const { data } = await axiosInstance.post('api/Episode/GetEpisode', episodeDetails)
        return data;
    }
    catch
    {
        console.log('Error in getting episode details.')
    }
}

export const GetAllLines = async (dispatch: any, episodeDetails: any) =>
{
    try
    {
        const { data } = await axiosInstance.post('api/Line/GetEpisodeLines', episodeDetails)
        dispatch(ActionCreatorsForEditEpisode.setInitEditEpisodeBody(false))
        if(data.length !== 0)
        {
            let maxLineNumber = generateLineNumbers(data, 0, 0, data); 
            let currentLine = {
                ...data[data.length - 1], 
                    changeIdentityOn: data[data.length - 1].changeIdentityOn ? false : data[data.length - 1].changeIdentityOn,
                    changeIdentityName: data[data.length - 1].changeIdentityOn ? null : data[data.length - 1].changeIdentityName,
                    avatarEnabled: true,
                    lineFormat: data[data.length - 1].lineFormat == 2 || data[data.length - 1].lineFormat == 3 ? 0 : data[data.length - 1].lineFormat,
                    shortSoundId: !data[data.length - 1].shortSoundEnabled ? undefined : data[data.length - 1].shortSoundId,
                    shortSoundName: !data[data.length - 1].shortSoundEnabled ? undefined : data[data.length - 1].shortSoundName,
                    shortSoundDescription: !data[data.length - 1].shortSoundEnabled ? undefined : data[data.length - 1].shortSoundDescription,

                    itemId: undefined,
                    itemName: undefined,
                    itemDescription: undefined,
                    premiumChoiceText: undefined,
                    premiumChoiceDescription: undefined,
                    charmingPoint: undefined
                }

            currentLine.text = undefined;
            dispatch(ActionCreatorsForEditEpisode.setCurrentLine(
                {currentLine, currentLineIndex: -1, linesStartIndex: data.length + 1 > episodeDetails.linesFrame ? data.length - episodeDetails.linesFrame + 1 : 0, linesSliceParam: data.length }))
            

            let arrayWithAnswerNumbers = generateQAnumbers(data);
            arrayWithAnswerNumbers = generateTAnumbers(arrayWithAnswerNumbers);
            arrayWithAnswerNumbers = generateVAnumbers(arrayWithAnswerNumbers);
            arrayWithAnswerNumbers = generateBAnumbers(arrayWithAnswerNumbers);
            dispatch(ActionCreatorsForEditEpisode.setLines({lines: arrayWithAnswerNumbers, maxLineNumber}));
        }
        else {
            dispatch(ActionCreatorsForEditEpisode.setDefaultLine(episodeDetails));
        }
    }
    catch(e: any)
    {
        console.log(e)
        console.log('Error in getting all lines.')
    }
}

export const GetActionBackgrounds = async (storyId : string) =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/Line/GetStoryBackgrounds?storyId=${storyId}`);
        return data;
    }
    catch(e)
    {
        console.log('Error in getting select background options.');
    }
}

export const GetActionMusicThemes = async (storyId : string) =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/Line/GetStoryMusic?storyId=${storyId}`);
        return data;
    }
    catch(e)
    {
        console.log('Error in getting select music themes options.');
    }
}

export const GetActionShortSounds = async (storyId : string) =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/Line/GetStoryMusic?storyId=${storyId}`);
        return data;
    }
    catch(e)
    {
        console.log('Error in getting select music themes options.');
    }
}


export const GetActionCharacters = async (storyId : string) =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/Line/GetStoryCharacters?storyId=${storyId}`);
        return data;
    }
    catch(e)
    {
        console.log('Error in getting select characters options.');
    }
}

export const GetActionClothes = async (storyId: string, characterId: string) =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/Line/GetStoryClothes?storyId=${storyId}&characterId=${characterId}`);
        return data;
    }
    catch(e)
    {
        console.log('Error in getting select clothes options.');
    }
}

export const GetActionHairstyles = async (storyId: string, characterId: string) =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/Line/GetStoryHairstyles?storyId=${storyId}&characterId=${characterId}`);
        return data;
    }
    catch(e)
    {
        console.log('Error in getting select hairstyles options.');
    }
}

export const GetActionAccessories = async (storyId: string, characterId: string) =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/Line/GetStoryAccessories?storyId=${storyId}&characterId=${characterId}`);
        return data;
    }
    catch(e)
    {
        console.log('Error in getting select accessories options.');
    }
}

export const DeleteEpisode = async (storyId: string, storyTranslationId : string, episodeId : string, alert : any, setLoading: any, setYesnomodal: any) =>
{
    try
    {
        setYesnomodal({ modalOpen: true, itemId: "", modalLoading: true}); 
        await axiosInstance.post(`api/Episode/DeleteEpisode`, { storyId: storyId, translationId: storyTranslationId, episodeId: episodeId }).then( 
            (result: any) => {
                setYesnomodal({ modalOpen: false, itemId: "", modalLoading: false}); 
                if (result.data) {
                    alert.success('All episode lines are deleted.')
                }
                else {
                    console.log(result.data.message);
                }
            }); 
    }
    catch(e)
    {
        setYesnomodal({ modalOpen: true, itemId: "", modalLoading: true}); 
        ErrorHandler(e, 'An error occured on delete.', alert);
    }
}

export const SaveQuestionLine = async (dispatch : any, line : any, clickedLineIndex: number, linesFrame: number, selectedLineIndex: number, alert : any, isOpen : boolean, type: string) =>
{
    try
    {
        dispatch(ActionCreators.setModalPopupBool({ type: 'none', isOpen: false, index: undefined, currentLine: defaultLine, clickedLine: defaultLine })) 
        const { data } = await axiosInstance.post('api/Line/SaveQuestionLine', line);

        // SM-386 - patch za dodavanje novog karaktera u listu
        if (line.characterId && data.lines[0].characterId != line.characterId) 
        {
            dispatch(ActionCreatorsForEditEpisode.addCharacters({ id: data.lines[0].characterId, value: data.lines[0].characterId, label: data.lines[0].characterName, path: '', changeOutfitDisabled: false }));
            dispatch(ActionCreatorsForEditEpisode.addCharactersForOutfitChange({ id: data.lines[0].characterId, value: data.lines[0].characterId, label: data.lines[0].characterName, path: '', changeOutfitDisabled: false }));
        }

        data.lines[1].answerNumber = 1;
        data.lines[2].answerNumber = 2;

        dispatch(ActionCreatorsForEditEpisode.addNewLines({newLines: data.lines, clickedLineIndex, linesFrame, selectedLineIndex, isOpen, type}));
        dispatch(ActionCreatorsForEditEpisode.setEpisodeSummary(data.episodeSummary));
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updated'));
    }
    catch(e)
    {
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('error'));
        ErrorHandler(e, 'An error occured while trying to add Q/A line type.', alert);
    }
}

export const SaveTimedChoiceLine = async (dispatch : any, line : any, clickedLineIndex: number, linesFrame: number, selectedLineIndex: number, alert : any, isOpen: boolean, type: string) =>
{
    try
    {
        dispatch(ActionCreators.setModalPopupBool({ type: 'none', isOpen: false, index: undefined, currentLine: defaultLine, clickedLine: defaultLine })) 
        const { data } = await axiosInstance.post('api/Line/SaveTimedChoiceLine', line);

        // SM-386 - patch za dodavanje novog karaktera u listu
        if (line.characterId && data.lines[0].characterId != line.characterId) 
        {
            dispatch(ActionCreatorsForEditEpisode.addCharacters({ id: data.lines[0].characterId, value: data.lines[0].characterId, label: data.lines[0].characterName, path: '', changeOutfitDisabled: false }));
            dispatch(ActionCreatorsForEditEpisode.addCharactersForOutfitChange({ id: data.lines[0].characterId, value: data.lines[0].characterId, label: data.lines[0].characterName, path: '', changeOutfitDisabled: false }));
        }

        data.lines[1].answerNumber = 1;
        data.lines[2].answerNumber = 2;
        data.lines[3].answerNumber = 3;

        dispatch(ActionCreatorsForEditEpisode.addNewLines({newLines: data.lines, clickedLineIndex, linesFrame, selectedLineIndex, isOpen, type}));
        dispatch(ActionCreatorsForEditEpisode.setEpisodeSummary(data.episodeSummary));
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updated'));
    }
    catch(e)
    {
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('error'));
        ErrorHandler(e, 'An error occured while trying to add T/A line type.', alert);
    }
}

export const SaveVisualChoiceLine = async (dispatch : any, line : any, clickedLineIndex: number, linesFrame: number, selectedLineIndex: number, alert : any, isOpen : boolean, type: string) =>
{
    try
    {
        dispatch(ActionCreators.setModalPopupBool({ type: 'none', isOpen: false, index: undefined, currentLine: defaultLine, clickedLine: defaultLine })) 
        const { data } = await axiosInstance.post('api/Line/SaveVisualChoiceLine', line);

        // SM-386 - patch za dodavanje novog karaktera u listu
        if (line.characterId && data.lines[0].characterId != line.characterId) 
        {
            dispatch(ActionCreatorsForEditEpisode.addCharacters({ id: data.lines[0].characterId, value: data.lines[0].characterId, label: data.lines[0].characterName, path: '', changeOutfitDisabled: false }));
            dispatch(ActionCreatorsForEditEpisode.addCharactersForOutfitChange({ id: data.lines[0].characterId, value: data.lines[0].characterId, label: data.lines[0].characterName, path: '', changeOutfitDisabled: false }));
        }

        data.lines[1].answerNumber = 1;
        data.lines[2].answerNumber = 2;

        dispatch(ActionCreatorsForEditEpisode.addNewLines({newLines: data.lines, clickedLineIndex, linesFrame, selectedLineIndex, isOpen, type}));
        dispatch(ActionCreatorsForEditEpisode.setEpisodeSummary(data.episodeSummary));
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updated'));
    }
    catch(e)
    {
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('error'));
        ErrorHandler(e, 'An error occured while trying to add V/A line type.', alert);
    }
}

export const SaveCustomizationLine = async (line : any, clickedLineIndex: number, linesFrame: number, selectedLineIndex: number, dispatch: any, alert: any, isOpen : boolean, type : string) =>
{
    try
    {
        dispatch(ActionCreators.setModalPopupBool({ type: 'none', isOpen: false, index: undefined, currentLine: defaultLine, clickedLine: defaultLine })) 
        const { data } = await axiosInstance.post('api/Line/SaveCustomizationLine', line);

        data.lines[4].answerNumber = 1;
        data.lines[5].answerNumber = 2;
        
        //dispatch(ActionCreatorsForAddingLine.setLoadingLine(false));

        dispatch(ActionCreatorsForEditEpisode.addNewLines({newLines: data.lines, clickedLineIndex, linesFrame, selectedLineIndex, isOpen, type}));
        dispatch(ActionCreatorsForEditEpisode.setEpisodeSummary(data.episodeSummary));
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updated'));
    }
    catch (e)
    {
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('error'));
        ErrorHandler(e, 'An error occured while trying to add new line.', alert);
    }
}

export const SaveEndOfLevelLine = async (line : any, clickedLineIndex: number, linesFrame : number, selectedLineIndex : number, dispatch : any, alert : any, isOpen : boolean, type: string) =>
{
    try
    {
        dispatch(ActionCreators.setModalPopupBool({ type: 'none', isOpen: false, index: undefined, currentLine: defaultLine, clickedLine: defaultLine })) 
        const { data } = await axiosInstance.post('api/Line/SaveEndOfLevelLine', line)
       
        dispatch(ActionCreatorsForEditEpisode.addNewLines({newLines: data.lines, clickedLineIndex, linesFrame, selectedLineIndex, isOpen, type}));
         
        if(data.episodeSummary?.eLines && data.episodeSummary?.eLines.length != 0)
        {
            dispatch(ActionCreatorsForEditEpisode.populateELines(data.episodeSummary?.eLines))
        }
        dispatch(ActionCreatorsForEditEpisode.setEpisodeSummary(data.episodeSummary));
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updated'));
    }
    catch(e)
    {
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('error'))
        ErrorHandler(e, 'An error occured while trying to add end of level line.', alert);
    }
}

export const SaveBranchLine = async (line : any, clickedLineIndex: number, linesFrame: number, selectedLineIndex: number, dispatch: any, alert: any, isOpen: boolean, type: string) =>
{
    try
    {
        dispatch(ActionCreators.setModalPopupBool({ type: 'none', isOpen: false, index: undefined, currentLine: defaultLine, clickedLine: defaultLine })) 
        const { data } = await axiosInstance.post('api/Line/SaveBranchLine', line);
        
        //dispatch(ActionCreatorsForAddingLine.setLoadingLine(false));

        let arrayWithAnswerNumbers = generateBAnumbers(data.lines);
        dispatch(ActionCreatorsForEditEpisode.addNewLines({newLines: arrayWithAnswerNumbers, clickedLineIndex, linesFrame, selectedLineIndex, isOpen, type}));
        dispatch(ActionCreatorsForEditEpisode.setEpisodeSummary(data.episodeSummary));
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updated'));
    }
    catch (e)
    {
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('error'));
        ErrorHandler(e, 'An error occured while trying to add new line.', alert);
    }
}

export const SaveOLine = async (line : any, clickedLineIndex: number, linesFrame: number, selectedLineIndex: number, dispatch: any, alert: any, type : string, isOpen : boolean) =>
{
    try
    {
        dispatch(ActionCreators.setModalPopupBool({ type: 'none', isOpen: false, index: undefined, currentLine: defaultLine, clickedLine: defaultLine })) 
        const { data } = await axiosInstance.post('api/Line/SaveOLine', line);
        
        //dispatch(ActionCreatorsForAddingLine.setLoadingLine(false));
        
        dispatch(ActionCreatorsForEditEpisode.addNewLines({newLines: data.lines, clickedLineIndex, linesFrame, selectedLineIndex, isOpen, type}));
        dispatch(ActionCreatorsForEditEpisode.setEpisodeSummary(data.episodeSummary));
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updated'));
    }
    catch (e)
    {
        console.log(e)
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('error'));
        ErrorHandler(e, 'An error occured while trying to add new line.', alert);
    }
}

export const SaveComment = async (body : any, alert : any, dispatch : any) =>
{
    try
    {
        await axiosInstance.post('api/Line/SaveComment', body);
        dispatch(ActionCreatorsForEditEpisode.updateCommentState(body));
        sessionStorage.setItem('commentNum', (parseInt(sessionStorage.getItem('commentNum') ?? '0') + 1).toString())
        alert.success('Successfully added new comment.');
    }
    catch(e)
    {
        ErrorHandler(e, 'Error in adding new commnent', alert);
    }
}

export const ResolveComment = async (body : any, alert : any, dispatch : any) =>
{
    try
    {
        await axiosInstance.post('api/Line/ResolveComment', body);
        dispatch(ActionCreatorsForEditEpisode.updateCommentResolvedState(body));
        alert.success('Resolving/unresolving comment was successful.')
    }
    catch(e)
    {
        ErrorHandler(e, 'Error in resolving comment', alert);
    }
}

export const GetAccessoryTypes = async (dispatch : any, alert : any) =>
{
    try
    {
        const { data } = await axiosInstance.get('api/Line/GetAccessoryTypes');
        dispatch(ActionCreatorsForEditEpisode.setAccessoryTypes(data));
    }
    catch(e)
    {
        ErrorHandler(e, 'Error in getting accessory types', alert);
    }
}

export const AddMultipleLLines = async (line : any, clickedLineIndex: number, linesFrame: number, selectedLineIndex: number, dispatch : any, alert : any) =>
{
    try
    {
        dispatch(ActionCreators.setModalPopupBool({ type: 'none', isOpen: false, index: undefined, currentLine: defaultLine, clickedLine: defaultLine })) 
        const { data } = await axiosInstance.post('api/Line/SaveMultipleLines', line);

        if(data.length != 0)
        {
            line.map((element : any, index : any) => 
            {
                updateLine(dispatch, data[index].model, element);
            })
        }
        dispatch(ActionCreatorsForEditEpisode.addNewLines({newLines: line, clickedLineIndex, linesFrame, selectedLineIndex}));
        
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updated'));
    }
    catch(e)
    {
        ErrorHandler(e, 'Error in adding multiple lines', alert)
    }
}

export const ChangeEpisodeTitle = async (body : any, dispatch : any, alert : any, setChangeEpisodeTitlePopup : any) =>
{
    try
    {
        await axiosInstance.post('api/Episode/UpdateEpisodeTitle', body)
        dispatch(ActionCreators.updateEpisodeTitle(body));
        setChangeEpisodeTitlePopup({isOpen: false, title: '', episodeId: ''})
    }
    catch(e)
    {
        ErrorHandler(e, 'Error in changing episode title', alert);
    }
}

export const UpdateEpisodeDescription = async (body : any, dispatch : any, alert : any, setChangeEpisodeDescriptionPopup : any) =>
{
    try
    {
        await axiosInstance.post('api/Episode/UpdateEpisodeDescription', body)
        dispatch(ActionCreatorsForEditEpisode.updateEpisodeDescription(body));
        setChangeEpisodeDescriptionPopup({isOpen: false, description: '', episodeId: ''})
    }
    catch(e)
    {
        ErrorHandler(e, 'Error in changing episode title description', alert);
    }
}