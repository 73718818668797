import React from 'react'
import Select from 'react-select'

const selectStyles = {
  menuPortal: (base : any) => ({ ...base, zIndex: 9999 }),
  menu: (provided : any) => ({ ...provided, zIndex: "9999 !important" })
};

export const SelectField = ({options, field, form, value} : any) => 
{
  var fieldValue = field.value ?? undefined;
  var isFound = options?.find((option : any) => option.id === fieldValue);

  return(<Select
      options={options}
      name={field.name}
      styles={selectStyles}
      menuPortalTarget={document.querySelector('body')}
      value={isFound != undefined ? isFound : null}
      onChange={(option: any) => form.setFieldValue(field.name, option.id)}
      onBlur={field.onBlur}
    />
  )
}

export const SelectFieldForComment = ({options, field, form, value} : any) => 
{
  var fieldValue = field.value?.value ?? field.value;
  var isFound = options?.find((option : any) => option.value === fieldValue);

  return(
    <Select
      options={options}
      name={field.name}
      styles={selectStyles}
      menuPortalTarget={document.querySelector('body')}
      value={isFound != undefined ? isFound : null}
      onChange={(option: any) => form.setFieldValue(field.name, option)}
      onBlur={field.onBlur}
    />
)}