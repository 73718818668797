import { axiosInstance } from '../axios/key';

export const GetLogsConfig = async () => {
    try
    {
        const { data } = await axiosInstance.get('api/Common/GetLogsConfig'); 
        return data;
    }
    catch(e)
    {
        console.log('Error in getting GA Src URL.');
    }
}

export const GetCurrentSessionId = async () =>
{
    try
    {
        const { data } = await axiosInstance.get('api/Common/GetCurrentSessionId');
        return data;
    }
    catch(e)
    {
        console.log('Error in getting session id.')
    }
}