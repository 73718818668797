import React, { useState } from "react";
import { CModal, CModalHeader, CModalBody, CModalFooter, CButton } from '@coreui/react';
import ReactLoading from 'react-loading';

export const YesNoModal = ({ modal, setModal, methodForYes, title, message }: any) => 
{
    const toggle = ()=>{ setModal({modalOpen: !modal.modalOpen, itemId: "", modalLoading: false}); }

    return (
          <CModal
            show={modal.modalOpen}
            onClose={toggle}
            closeOnBackdrop={false}
          >
              { modal.modalLoading ? <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1000, position: 'absolute', top: '0%', left: '0%', width: '100%', height: '100%'}}>
                            <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1001, position: 'absolute', top: '45%', left: '45%'}}>
                              <ReactLoading className="mt-3 mb-5" type='spin' color='#0080FF'/>
                              </div>
                        </div> : <div></div> }
            <CModalHeader closeButton>{ title }</CModalHeader>
            <CModalBody>
              { message }
            </CModalBody>
            <CModalFooter>
              <CButton color="primary" onClick={methodForYes} >Yes</CButton>{' '}
              <CButton
                color="secondary"
                onClick={toggle}
              >Cancel</CButton>
            </CModalFooter>
          </CModal>
      )
}


export const DeleteLineConfirmationModal = ({ modal, setModal, methodForYes, title, message }: any) => 
{
    const toggle = ()=>{ setModal(false); }

    return (
          <CModal
            show={modal}
            onClose={toggle}
            closeOnBackdrop={false}
          >
            <CModalHeader closeButton>{ title }</CModalHeader>
            <CModalBody>
              { message }
            </CModalBody>
            <CModalFooter>
              <CButton color="primary" onClick={methodForYes}>Yes</CButton>{' '}
              <CButton
                color="secondary"
                onClick={toggle}
              >Cancel</CButton>
            </CModalFooter>
          </CModal>
      )
}

export const DeleteMultipleLinesConfirmationModal = ({ modal, setModal, methodForYes, title, message }: any) => 
{
    const toggle = ()=>{ setModal({boolean: false, array: []}); }

    return (
          <CModal
            show={modal.boolean}
            onClose={toggle}
            closeOnBackdrop={false}
          >
            <CModalHeader closeButton>{ title }</CModalHeader>
            <CModalBody>
              { message }
            </CModalBody>
            <CModalFooter>
              <CButton color="primary" onClick={methodForYes}>Yes</CButton>{' '}
              <CButton
                color="secondary"
                onClick={toggle}
              >Cancel</CButton>
            </CModalFooter>
          </CModal>
      )
}