import React, { useEffect, useRef, useState } from 'react';
import { CButton, CLabel } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import ReactLoading from 'react-loading';
import { BasicLine, OutfitLine1, OutfitLine2, OutfitLine3, CustomizationLine, QuestionLine, AnswerLine, CinematicLine, BranchLine, PlaceholderLine, GotoMainLine, CustomizationLine2 } from '../Common/Lines';

import { AddLine } from '../Common/AddLine';
import { GetLineTypes, GetLineFormats, GetStoryCharacters, GetEmotions, GetAnimations, GetStoryAnimations, GetStoryCharactersForOutfitChange, GetStoryItems } from '../../services/LineServices';
import { GetAllLines } from '../../services/EpisodeServices';
import { ActionCreatorsForEditEpisode } from '../../store/EditEpisodeReducer';
import { GetBranchCandQ, GetClothingCombinations, GetOLineSkinColors, GetStoryVisuals } from '../../services/SetupStoryServices';
import { AiFillCloud, AiOutlineSolution } from 'react-icons/ai';
import { FaCloudversify } from 'react-icons/fa';
import { IoMdCloudyNight } from 'react-icons/io';
import Select from 'react-select';
import { FormatObject, getCurrentLineWhenInPopup, isPreviousLineUpdating, jumpToLastLine, linesFrameArray, selectStylesForField, validateEpisodeStatusForDisable } from '../Common/Lines/_Helpers';
import { EditEpisodeSummary } from './EditEpisodeSummary';
import AddNewLinePopup from '../Common/Lines/AddNewLinePopup';
import { IoCloudDoneOutline } from 'react-icons/io5';
import { isNarrativeDesigner, isOnlyReviewer, isWriter, isWriterLead } from '../Auth/AuthConfig';
import { useHistory } from 'react-router-dom';
import EndOfLevel from '../Common/Lines/LunescapeAdditionalLines/EndOfLevel';
import TimedChoice from '../Common/Lines/TimedChoice';
import TimedChoiceAnswer from '../Common/Lines/TimedChoiceAnswer';
import { LineTypes } from '../Common/Enums/LineTypes';
import VisualChoice from '../Common/Lines/VisualChoice';
import VisualChoiceAnswer from '../Common/Lines/VisualChoiceAnswer';
import Fashion from '../Common/Lines/LunescapeAdditionalLines/Fashion';
import { HighlightSessionLogger, EditEpisodeSetupStoryClickSessionLogger } from '../../modules/firebaseDetailedLoggers';
import EditEpisodeActionsPopup from './EditEpisodeActionsPopup';
import { EditEpisodeOptionsPopup } from './EditEpisodeOptionsPopup';

var Infinite = require('react-infinite');

export const EditEpisodeBody = ({ storyId, storyTransId, episodeId, storyName, episodeTitle, serialNumber }: any) =>
{
    const history = useHistory();
    
    const dispatch = useDispatch();
    const { currentLine, initEditEpisodeBody, selectedLineIndex, lines, previousStateActions, linesStartIndex, linesFrame, characters, animations, updateCloudBool, editEpisodePassParam, linesSliceParam, lineColors, pickedActionColor } = useSelector((state: any) => state.editEpisodeReducer);
    const { modalPopupBool, modalPopupBoolAction, modalPopupBoolOption } = useSelector((state: any) => state.storiesReducer);

    const handleKeyPress = (event: any) => 
    {
        if(event.altKey && event.keyCode == 13 && selectedLineIndex != -1)
        {
            var newInputValue = (document.getElementById('textundefined') as HTMLInputElement)?.value;
            if(newInputValue && newInputValue != currentLine.text)
            {
                FormatObject(
                    dispatch, 
                    selectedLineIndex, 
                    linesStartIndex,
                    lines, 
                    { lineToUpdateId: currentLine.docId, lineModel: {...currentLine, text: newInputValue} }, alert)
                    .then(() =>
                    {
                        jumpToLastLine(lines, dispatch, linesFrame);
                    })
            }
            else
            {
                jumpToLastLine(lines, dispatch, linesFrame);
            }
        }
        else
        {
            let currentLineIndex;
            let currentLine;

            switch (event.keyCode) 
            {
                // case 37:
                //     //left
                //     event.preventDefault()
                //     if (lines[0].isDefault) return;
                //     break;
                case 38:
                    //up
                    event.preventDefault();
                    if (lines[0].isDefault || selectedLineIndex == 0 || updateCloudBool === 'updating') return;
                    if(selectedLineIndex !== -1 && lines[selectedLineIndex].lineType === LineTypes.L && !lines[selectedLineIndex].text) 
                    {   
                        alert.info('You must fill in the text before leaving this line.')
                        return;
                    }
                    if (selectedLineIndex == -1) {
                        currentLineIndex = lines.length - 1;
                    }
                    else {
                        currentLineIndex = selectedLineIndex - 1;
                    }

                    dispatch(ActionCreatorsForEditEpisode.setPreviousDefaultState({
                        backgroundId: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.backgroundId,
                        backgroundName: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.backgroundName,
                        backgroundDescription: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.backgroundDescription,
                        musicId: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.musicId,
                        musicName: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.musicName,
                        musicDescription: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.musicDescription,
                        shortSoundId: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.shortSoundId,
                        shortSoundName: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.shortSoundName,
                        shortSoundDescription: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.shortSoundDescription,
                        shortSoundEnabled: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.shortSoundEnabled,

                        itemId: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.itemId,
                        itemName: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.itemName,
                        itemDescription: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.itemDescription,

                        charmingPoint: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.charmingPoint,
                        premiumChoiceText: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.permiumChoiceText,
                        premiumChoiceDesription: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.premiumChoiceDesription,
                        avatarEnabled: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.avatarEnabled,
                        callPhoneEnabled: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.callPhoneEnabled,
                        callSmsEnabled: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.callSmsEnabled,
                        callerCharacterId: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.callerCharacterId,
                        callerCharacterName: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.callerCharacterName,
                        changeIdentityOn: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.changeIdentityOn,
                        changeIdentityName: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.changeIdentityName,
                        charactersOutfit: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.charactersOutfit,
                    }))
                    dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('not updating'));
                    dispatch(ActionCreatorsForEditEpisode.setCurrentLine(
                    { 
                        currentLine: currentLineIndex == -1 ? {...lines[lines.length - 1], changeIdentityOn: false, changeIdentityName: null} : lines[currentLineIndex], 
                        currentLineIndex, 
                        linesStartIndex: currentLineIndex + 1 > linesFrame ? currentLineIndex + 1 - linesFrame : 0,
                        linesSliceParam: currentLineIndex
                    }))
                    break;
                case 40:
                    //down
                    event.preventDefault()
                    if (lines[0].isDefault ||  selectedLineIndex == -1 || updateCloudBool === 'updating') return;
                    if(lines[selectedLineIndex].lineType === LineTypes.L && !lines[selectedLineIndex].text) 
                    {
                        alert.info('You must fill in the text before leaving this line.')
                        return;
                    }
                    if ( selectedLineIndex == lines.length - 1) {
                        currentLineIndex = -1;
                    }
                    else {
                        currentLineIndex =  selectedLineIndex + 1;
                    }

                    dispatch(ActionCreatorsForEditEpisode.setPreviousDefaultState({
                        backgroundId: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.backgroundId,
                        backgroundName: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.backgroundName,
                        backgroundDescription: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.backgroundDescription,
                        musicId: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.musicId,
                        musicName: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.musicName,
                        musicDescription: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.musicDescription,
                        shortSoundId: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.shortSoundId,
                        shortSoundName: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.shortSoundName,
                        shortSoundDescription: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.shortSoundDescription,
                        shortSoundEnabled: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.shortSoundEnabled,

                        itemId: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.itemId,
                        itemName: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.itemName,
                        itemDescription: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.itemDescription,
                        
                        charmingPoint: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.charmingPoint,
                        premiumChoiceText: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.permiumChoiceText,
                        premiumChoiceDesription: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.premiumChoiceDesription,
                        avatarEnabled: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.avatarEnabled,
                        callPhoneEnabled: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.callPhoneEnabled,
                        callSmsEnabled: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.callSmsEnabled,
                        callerCharacterId: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.callerCharacterId,
                        callerCharacterName: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.callerCharacterName,
                        changeIdentityOn: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.changeIdentityOn,
                        changeIdentityName: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.changeIdentityName,
                        charactersOutfit: lines[currentLineIndex == -1 ? lines.length - 1 : currentLineIndex]?.charactersOutfit,
                    }))

                    currentLine = currentLineIndex == -1 ? {...lines[lines.length - 1], changeIdentityOn: false, changeIdentityName: null} : lines[currentLineIndex];
                    if(currentLineIndex === -1) currentLine.text = undefined;
                    dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('not updating'));
                    dispatch(ActionCreatorsForEditEpisode.setCurrentLine({
                        currentLine, 
                        currentLineIndex, 
                        linesStartIndex: currentLineIndex + 1 > linesFrame ? currentLineIndex + 1 - linesFrame : (currentLineIndex === -1 && lines.length + 1 > linesFrame ? lines.length - linesFrame + 1 : 0),
                        linesSliceParam: selectedLineIndex == lines.length - 1 ? lines.length : currentLineIndex
                    }))
                    break;
                default:
                    return;
            }
        }
    }

    const customStyles =
    {
        control: (styles : any) => ({ ...styles, backgroundColor: 'white' }),
    }

    const [loading, setLoading] = useState(true);
    const [loadingForPromises, setLoadingForPromises] = useState(true);

    const alert = useAlert();

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    })

    useEffect(() => {
        setLoading(true);
        dispatch(ActionCreatorsForEditEpisode.setInitEditEpisodeBody(true));
        GetAllLines(dispatch, { storyId, storyTranslationId: storyTransId, episodeId, linesFrame }).then(() => { setLoading(false); });
    }, [])

    useEffect(() => {
        async function initialStateFunction() {
            setLoadingForPromises(true);
            
            GetStoryAnimations(dispatch, storyId, serialNumber)
            const lineTypes = await GetLineTypes(storyId, alert);
            const lineFormats = await GetLineFormats(storyId, alert);
            const storyCharacters = await GetStoryCharacters(storyId, alert);
            const storyCharactersForOufitChange = await GetStoryCharactersForOutfitChange(storyId, alert);
            const emotions = await GetEmotions(alert);
            const animations = await GetAnimations(storyId, alert);
            const skinColorsPromise = await GetOLineSkinColors();
            const branchCandQ = await GetBranchCandQ(storyId, storyTransId, serialNumber);
            const storyVisuals = await GetStoryVisuals(storyId);
            const fashions = await GetClothingCombinations();

            let lineTypesPromise = lineTypes?.map((lineType: any) => {
                return ({ id: lineType.id, value: lineType.id, label: lineType.label, tooltip: lineType.tooltip })
            })

            let lineFormatsPromise = lineFormats?.map((lineFormat: any) => {
                return ({ id: lineFormat.id, value: lineFormat.id, label: lineFormat.label, tooltip: lineFormat.tooltip })
            })

            let storyCharactersPromise = storyCharacters?.map((storyCharacter: any) => {
                return ({ id: storyCharacter.id, value: storyCharacter.id, label: storyCharacter.name, path: storyCharacter.path, changeOutfitDisabled: storyCharacter.changeOutfitDisabled, isMeCharacter: storyCharacter.isMeCharacter, isNarratorCharacter: storyCharacter.isNarratorCharacter})
            })

            let storyCharactersForOufitChangePromise = storyCharactersForOufitChange?.map((storyCharacter: any) => {
                return ({ id: storyCharacter.id, value: storyCharacter.id, label: storyCharacter.name, path: storyCharacter.path, changeOutfitDisabled: storyCharacter.changeOutfitDisabled, isMeCharacter: storyCharacter.isMeCharacter, isNarratorCharacter: storyCharacter.isNarratorCharacter })
            })

            let emotionsPromise = emotions?.map((emotion: any) => {
                return ({ id: emotion.id, value: emotion.id, label: emotion.label })
            })

            let animationsPromise = animations?.map((animation: any) => {
                return ({ id: animation.id, value: animation.id, label: animation.name })
            })

            let branchCandQPromise = branchCandQ?.map((branchResponse: any) => {
                var id = branchResponse.b_QuestionId;
                var type = LineTypes.Q;
                if (id == null)
                {
                    id = branchResponse.b_CustomizationId;
                    type = LineTypes.C1;
                }
                if (id == null)
                {
                    id = branchResponse.b_TimedChoiceId;
                    type = LineTypes.T;
                }
                if (id == null)
                {
                    id = branchResponse.b_VisualChoiceId;
                    type = LineTypes.V;
                }

                return ({ 
                    id: id, 
                    value: id, 
                    label: branchResponse.text, 
                    type: type,
                    episodeId: branchResponse.episodeId,
                    episodeSerialNumber: branchResponse.episodeSerialNum
                })
            })

            const storyVisualPromise = storyVisuals?.map((visual : any) => {
                return ({ id: visual.id, value: visual.id, label: visual.name })
            })

            const fashionsPromise = fashions?.map((fashion : any) => {
                return ({ id: fashion.id, value: fashion.id, label: fashion.label })
            })

            return { lineTypesPromise, lineFormatsPromise, storyCharactersPromise, storyCharactersForOufitChangePromise, emotionsPromise, animationsPromise, skinColorsPromise, branchCandQPromise, storyVisualPromise, fashionsPromise };
        }

        initialStateFunction().then((response: any) => { 
            dispatch(ActionCreatorsForEditEpisode.setCharacters(response.storyCharactersPromise));
            dispatch(ActionCreatorsForEditEpisode.setCharactersForOutfitChange(response.storyCharactersForOufitChangePromise));
            dispatch(ActionCreatorsForEditEpisode.setAnimations(response.animationsPromise));
            dispatch(ActionCreatorsForEditEpisode.setDefaultLineTypes(response.lineTypesPromise));
            dispatch(ActionCreatorsForEditEpisode.setDefaultLineFormats(response.lineFormatsPromise));
            dispatch(ActionCreatorsForEditEpisode.setDefaultEmotions(response.emotionsPromise));
            dispatch(ActionCreatorsForEditEpisode.setDefaultSkinColors(response.skinColorsPromise));
            dispatch(ActionCreatorsForEditEpisode.setDefaultBranchCandQ(response.branchCandQPromise));
            dispatch(ActionCreatorsForEditEpisode.setDefaultVisualChoices(response.storyVisualPromise))
            dispatch(ActionCreatorsForEditEpisode.setDefaultClothingCombinations(response.fashionsPromise))
            setLoadingForPromises(false);
        });
    }, [serialNumber])

    const placeholderLines = selectedLineIndex !== -1 ? 
        new Array(selectedLineIndex < linesFrame - 1 ? linesFrame - 1 - selectedLineIndex : 0).fill(false) 
        : ( lines[0]?.isDefault ? new Array(linesFrame - 1).fill(false) : (linesFrame - 1 > lines.length ? new Array(linesFrame - 1 - lines.length).fill(false) : null));
    return (
        <div className="column-style mt-3 ml-1 editEpisodeWrapper">
            { updateCloudBool === 'updating' ? <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1000, position: 'fixed', top: '0%', left: '0%', width: '100%', height: '2000px', backgroundColor: 'rgb(115,115,115,0.5)'}}>
                <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1001, position: 'absolute', top: '20%', left: '45%'}}>
                    <ReactLoading height="100px" width="100px" className="mt-3 mb-5" type='spin' color='#0080FF'/>
                </div>
            </div> : <div/> }
            {loading || loadingForPromises ? <div className="center"><ReactLoading height="100px" width="100px" className="mt-3 mb-5" type='bubbles' color='#0080FF'/></div> :
            <div>
                <fieldset style={validateEpisodeStatusForDisable(editEpisodePassParam.episodeStatusEnum) ? 
                    { 
                        border: '5px solid rgba(0, 0, 154, 0.5)',
                        borderStyle: 'ridge',  
                        boxSizing: 'border-box',
                        textAlign: 'center'
                    } : {}} className="register-signin-left-panel col-sm-12 rounded shadow edit-episode scrollable-body">  
                    {validateEpisodeStatusForDisable(editEpisodePassParam.episodeStatusEnum) ? <legend className="legend-body nt-info rounded shadow">Adding and deleting lines is disabled due to the current status of the episode.</legend> : '' }
                    <div className='row top-row-n' style={{ marginBottom: '25px', marginTop: '-28px', marginRight: '-29px' }}>
                        <div className="row-style">
                            {isWriter() || isWriterLead() || isNarrativeDesigner() ?
                            <CButton 
                            className="createable-select-width-small ml-1 mt-2 mr-2 select-width-new btn-primary-sm wide stp-story"
                            onMouseDown={(e : any) => { if(e.button == 1) {
                                e.preventDefault();

                                const setupStoryLogger = new EditEpisodeSetupStoryClickSessionLogger();
                
                                setupStoryLogger.story_name = storyName ?? '';
                                setupStoryLogger.timestamp = new Date().toUTCString();
                                setupStoryLogger.session_id = localStorage.getItem('sessionId') ?? '';
                                setupStoryLogger.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                                setupStoryLogger.user_id = localStorage.getItem('uid') ?? '';
                
                                setupStoryLogger.logEvent();

                                window.open(`/setup-story/&storyId=${storyId}&storyTransId=${storyTransId}`, '_blank')
                            }} }
                            onClick={() => {
                                const setupStoryLogger = new EditEpisodeSetupStoryClickSessionLogger();
                
                                setupStoryLogger.story_name = storyName ?? '';
                                setupStoryLogger.timestamp = new Date().toUTCString();
                                setupStoryLogger.session_id = localStorage.getItem('sessionId') ?? '';
                                setupStoryLogger.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                                setupStoryLogger.user_id = localStorage.getItem('uid') ?? '';
                
                                setupStoryLogger.logEvent();

                                history.push(`/setup-story/&storyId=${storyId}&storyTransId=${storyTransId}`)
                            }}>
                                <AiOutlineSolution className="icon-primary-btn" />
                            &nbsp;Setup Story</CButton> : ''}
                             {/* : <CButton  */}
                            {/* className="createable-select-width-small ml-1 mt-2 mr-2 select-width-new btn-primary-sm wide stp-story" disabled/>} */}
                            
                            {!isOnlyReviewer() ? <Select 
                                className="color-select-width"
                                options={lineColors} 
                                isClearable
                                styles={{...customStyles, ...selectStylesForField}}
                                menuPortalTarget={document.querySelector('body')}
                                value={pickedActionColor}
                                onChange={(value : any) => 
                                { 
                                    const highlightSession = new HighlightSessionLogger();

                                    highlightSession.story_name = storyName ?? '';
                                    highlightSession.episode_name = episodeTitle ?? '';
                                    highlightSession.timestamp = new Date().toUTCString();
                                    highlightSession.session_id = localStorage.getItem('sessionId') ?? '';
                                    highlightSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                                    highlightSession.user_id = localStorage.getItem('uid') ?? '';

                                    highlightSession.logEvent();

                                    dispatch(ActionCreatorsForEditEpisode.setPickedActionColor(value)) 
                                } }
                            /> : ''}
                        </div>

                        {updateCloudBool === 'updating' ? <IoCloudDoneOutline style={{marginLeft: ''}} size={45} color="orange"/> 
                        : updateCloudBool === 'updated' ? <IoCloudDoneOutline style={{marginLeft: ''}} size={45} color="lightgreen"/> 
                        : updateCloudBool === 'error' ? <IoCloudDoneOutline style={{marginLeft: ''}} size={45} color="red"/> : 
                        <IoCloudDoneOutline style={{marginLeft: ''}} size={45}/>}
                    </div>
                    <div>
                        <Infinite useWindowAsScrollContainer elementHeight={25}>
                        {lines?.slice(0, linesSliceParam)?.map((line: any, index: any) =>
                            line?.isDefault === true ? <div key={linesStartIndex + index}/> : 
                                line.lineType === LineTypes.L ? <BasicLine key={linesStartIndex + index} line={line} inputLine={false} storyCharacters={characters} index={index} episodeName={episodeTitle} serialNumber={serialNumber}/> :
                                    line.lineType === LineTypes.O1 ? <OutfitLine1 key={linesStartIndex + index} line={line} inputLine={false} index={index} episodeName={episodeTitle} serialNumber={serialNumber}/> :
                                        line.lineType === LineTypes.O2 ? <OutfitLine2 key={linesStartIndex + index} line={line} inputLine={false} index={index} episodeName={episodeTitle} serialNumber={serialNumber}/> :
                                            line.lineType === LineTypes.O3 ? <OutfitLine3 key={linesStartIndex + index} line={line} inputLine={false} index={index} episodeName={episodeTitle} serialNumber={serialNumber}/> :
                                                line.lineType === LineTypes.Q ? <QuestionLine key={linesStartIndex + index} line={line} inputLine={false} storyCharacters={characters} index={index} episodeName={episodeTitle} serialNumber={serialNumber}/> :
                                                    line.lineType === LineTypes.A ? <AnswerLine key={linesStartIndex + index} line={line} inputLine={false} index={index} episodeName={episodeTitle} serialNumber={serialNumber}/> :
                                                        line.lineType === LineTypes.M ? <CinematicLine key={linesStartIndex + index} line={line} inputLine={false} animations={animations} index={index} episodeName={episodeTitle} serialNumber={serialNumber}/> :
                                                            line.lineType === LineTypes.C1 ? <CustomizationLine key={linesStartIndex + index} line={line} inputLine={false} index={index} episodeName={episodeTitle} serialNumber={serialNumber} storyId={storyId}/> :
                                                                line.lineType === LineTypes.C2 ? <CustomizationLine2 key={linesStartIndex + index} line={line} inputLine={false} index={index} episodeName={episodeTitle} serialNumber={serialNumber} storyId={storyId}/> :
                                                                    line.lineType === LineTypes.B ? <BranchLine key={linesStartIndex + index} line={line} inputLine={false} index={index} episodeName={episodeTitle} serialNumber={serialNumber}/> :
                                                                        line.lineType === LineTypes.BA ? <AnswerLine key={linesStartIndex + index} line={line} inputLine={false} index={index} episodeName={episodeTitle} serialNumber={serialNumber} isBranchAnswer/> :
                                                                            line.lineType === LineTypes.G ? <GotoMainLine key={linesStartIndex + index} line={line} inputLine={false} index={index} episodeName={episodeTitle} serialNumber={serialNumber}/> :
                                                                                //End Of Level line
                                                                                line.lineType === LineTypes.E ? <EndOfLevel key={linesStartIndex + index} line={line} inputLine={false} animations={animations} index={index} episodeName={episodeTitle} serialNumber={serialNumber}/> :
                                                                                    line.lineType === LineTypes.T ? <TimedChoice key={linesStartIndex + index} line={line} inputLine={false} storyCharacters={characters} index={index} episodeName={episodeTitle} serialNumber={serialNumber}/> :
                                                                                        line.lineType === LineTypes.TA ? <TimedChoiceAnswer key={linesStartIndex + index} line={line} inputLine={false} index={index} episodeName={episodeTitle} serialNumber={serialNumber}/> :
                                                                                            line.lineType === LineTypes.V ? <VisualChoice key={linesStartIndex + index} line={line} inputLine={false} storyCharacters={characters} index={index} episodeName={episodeTitle} serialNumber={serialNumber}/> :
                                                                                                line.lineType === LineTypes.VA ? <VisualChoiceAnswer key={linesStartIndex + index} line={line} inputLine={false} index={index} episodeName={episodeTitle} serialNumber={serialNumber}/> :
                                                                                                    line.lineType === LineTypes.F ? <Fashion key={linesStartIndex + index} line={line} inputLine={false} index={index} episodeName={episodeTitle} serialNumber={serialNumber} storyId={storyId}/> :
                                                                    <div key={index} />

                        )}
                        </Infinite>
                        <AddLine 
                            selectedLineIndex={selectedLineIndex} 
                            currentLine={
                                selectedLineIndex === -1 && initEditEpisodeBody ? lines[lines.length - 1] : currentLine
                            }
                            storyCharacters={characters}
                            animations={animations} 
                            episodeName={episodeTitle}
                            serialNumber={serialNumber}
                            storyId={storyId}
                        />

                        <EditEpisodeSummary></EditEpisodeSummary>
                        
                        {/* POPUPS */}
                        <AddNewLinePopup 
                            visible={modalPopupBool.isOpen}
                            clickedLineIndex={modalPopupBool.index}
                            type={modalPopupBool.type}
                            storyCharacters={characters}
                            animations={animations} 
                            episodeName={episodeTitle}
                            serialNumber={serialNumber}
                            storyId={storyId}
                        />
                        
                        <EditEpisodeActionsPopup 
                            storyId={storyId} 
                            storyName={storyName} 
                            episodeName={episodeTitle}
                            modal={modalPopupBoolAction.isOpen} 
                            actionObject={getCurrentLineWhenInPopup(modalPopupBoolAction, modalPopupBoolOption, selectedLineIndex, currentLine)} 
                            previousLineUpdate={isPreviousLineUpdating(modalPopupBoolAction, modalPopupBoolOption, selectedLineIndex)}
                            previousStateActions={
                                isPreviousLineUpdating(modalPopupBoolAction, modalPopupBoolOption, selectedLineIndex) ? modalPopupBoolAction.previousStateObject : previousStateActions
                            }
                            selectedLineIndex={
                                isPreviousLineUpdating(modalPopupBoolAction, modalPopupBoolOption, selectedLineIndex) ? modalPopupBoolAction.clickedLineIndex : selectedLineIndex
                            }
                        /> 
                        <EditEpisodeOptionsPopup 
                            storyName={storyName} 
                            episodeName={episodeTitle}
                            modal={modalPopupBoolOption.isOpen}
                            actionObject={getCurrentLineWhenInPopup(modalPopupBoolAction, modalPopupBoolOption, selectedLineIndex, currentLine)}
                            previousLineUpdate={isPreviousLineUpdating(modalPopupBoolAction, modalPopupBoolOption, selectedLineIndex)} 
                            previousStateActions={
                                isPreviousLineUpdating(modalPopupBoolAction, modalPopupBoolOption, selectedLineIndex) ? modalPopupBoolOption.previousStateObject : previousStateActions
                            }
                            selectedLineIndex={
                                isPreviousLineUpdating(modalPopupBoolAction, modalPopupBoolOption, selectedLineIndex) ? modalPopupBoolOption.clickedLineIndex : selectedLineIndex
                            }
                        /> 
                        
                    </div> 
                </fieldset>
            </div>}
        </div>

    )
}

export default EditEpisodeBody;