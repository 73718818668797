import React, { useState } from 'react';
import { CButton, CCard, CCardBody, CInput, CLabel } from '@coreui/react';
import { useEffect } from 'react';
import { GetAllStoriesForHeader, GetEpisodesForHeader } from '../../../services/StoriesServices';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorsForArtCatalog } from '../../../store/ArtCatalogReducer';
import { GetAssets, GetEpisodesForArtCatalog, GetSelectValuesForAssets } from '../../../services/ArtCatalog';
import { ApplyFiltersSessionLogger } from '../../../modules/firebaseDetailedLoggers';

export const Characters = ({setPage, data, activeKey} : any) =>
{
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState('');

    const { stories, episodes } = useSelector((state: any) => state.artCatalogReducer);

    const bodyTypes  = useSelector((state: any) => state.artCatalogReducer.bodyTypesCharacters);
    const skinColors = useSelector((state: any) => state.artCatalogReducer.skinColorsCharacters);
    const types = useSelector((state: any) => state.artCatalogReducer.characterTypes);
    const displays = useSelector((state: any) => state.artCatalogReducer.characterDisplayTypes);

    const [chosenObject, setChosenObject] = useState({ chosenStory: null as any, chosenEpisode: null as any, chosenDisplay: null as any, chosenType: null as any, chosenBody: null as any, chosenSkin: null as any })
 
    useEffect(() => 
    {
        GetSelectValuesForAssets('api/ArtCatalog/GetBodyTypes').then((response) => 
        { 
            dispatch(ActionCreatorsForArtCatalog.setBodyTypesCharacters(response));
        })
        GetSelectValuesForAssets('api/ArtCatalog/GetSkinColors').then((response) => 
        { 
            dispatch(ActionCreatorsForArtCatalog.setSkinColorsCharacters(response));
        })
        GetSelectValuesForAssets('api/ArtCatalog/GetCharacterTypes').then((response) => 
        { 
            dispatch(ActionCreatorsForArtCatalog.setCharacterTypes(response));
        })
        GetSelectValuesForAssets('api/ArtCatalog/GetCharacterDisplayTypes').then((response) => 
        { 
            dispatch(ActionCreatorsForArtCatalog.setCharacterDisplayTypes(response));
        })
    }, [])

    useEffect(() => 
    {
        const timeOutId = setTimeout(() => 
        {
            let filteredLines = data?.map((l: any, i: number) => {
                return {...l, lineIndex: i}
            }).filter((l: any) => 
            {
                if(searchText && l.name?.toLowerCase().includes(searchText.toLowerCase()))
                {
                    return l;
                }
            })
            if(searchText)
            { 
                dispatch(ActionCreatorsForArtCatalog.setCharacters(filteredLines));
                setPage(0);
            }
            else
            {
                dispatch(ActionCreatorsForArtCatalog.setLoadingImages(true));
                GetAssets('api/ArtCatalog/GetAssets_Characters', { storyId: null, episodeId: null, type: null, bodyType: null, skinColor: null, display: null, searchValue: null }).then((response) => 
                { 
                    dispatch(ActionCreatorsForArtCatalog.setCharacters(response)) 
                })
            }
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [searchText])

    useEffect(() => 
    { 
        if(data.length === 0)
            setChosenObject({ chosenStory: null, chosenEpisode: null, chosenDisplay: null, chosenType: null, chosenBody: null, chosenSkin: null }) 
    }, [activeKey])

    return(<div className=" mt-3 form-filter-top-art-catalog">
        <div className="row-style art-cat-filters-row" style={{width: '1300px'}}>
            <div className="form-group-name col-md">
                <CLabel htmlFor="story">Find</CLabel>
                <CInput
                    className="form-control"
                    name="episode"
                    placeholder="Search for a character"
                    value={searchText}
                    onChange={(e : any) => setSearchText(e.target.value) }
                    type="text"
                    autoComplete="off"
                    required>
                </CInput>
            </div>
            <div className="form-group-name col-md ">
                <CLabel htmlFor="story">Story</CLabel>
                <Select
                    options={stories}
                    value={chosenObject.chosenStory}
                    isClearable
                    onChange={(value : any) => 
                    { 
                        if(value != null)
                        {
                            GetEpisodesForArtCatalog(value.id, value.value).then((response) => 
                            { 
                                dispatch(ActionCreatorsForArtCatalog.setEpisodes(response));
                                setChosenObject({...chosenObject, chosenStory: value, chosenEpisode: null });
                            });
                        }
                        else
                        {
                            setChosenObject({...chosenObject, chosenStory: null, chosenEpisode: null });
                        }
                    }}
                    as="select"
                    autoComplete="off"
                    placeholder="Choose a story..."
                    required >
                </Select>
            </div>
            <div className="form-group-name col-md ">
                <CLabel htmlFor="episode">Episode</CLabel>
                <Select
                    options={episodes}
                    value={chosenObject.chosenEpisode}
                    isClearable
                    onChange={(value : any) => 
                    { 
                        setChosenObject({...chosenObject, chosenEpisode: value });
                    }}
                    name="episode"
                    as="select"
                    autoComplete="off"
                    placeholder="Enter episode..."
                    required>
                </Select>
            </div>
            <div className="form-group-name col-md ">
                <CLabel htmlFor="episode">Display</CLabel>
                <Select
                    options={displays}
                    value={chosenObject.chosenDisplay}
                    isClearable
                    onChange={(value : any) => 
                    { 
                        setChosenObject({...chosenObject, chosenDisplay: value})
                    }}
                    name="episode"
                    as="select"
                    autoComplete="off"
                    required>
                </Select>
            </div>
            <div className="form-group-name col-md ">
                <CLabel htmlFor="episode">Type</CLabel>
                <Select
                    options={types}
                    value={chosenObject.chosenType}
                    isClearable
                    onChange={(value : any) => 
                    { 
                        setChosenObject({...chosenObject, chosenType: value})
                    }}
                    name="episode"
                    as="select"
                    autoComplete="off"
                    required>
                </Select>
            </div>
            <div className="form-group-name col-md ">
                <CLabel htmlFor="episode">Body</CLabel>
                <Select
                    options={bodyTypes}
                    value={chosenObject.chosenBody}
                    isClearable
                    onChange={(value : any) => 
                    { 
                        setChosenObject({...chosenObject, chosenBody: value})
                    }}
                    name="episode"
                    as="select"
                    autoComplete="off"
                    required>
                </Select>
            </div>
            <div className="form-group-name col-md ">
                <CLabel htmlFor="episode">Skin</CLabel>
                <Select
                    options={skinColors}
                    value={chosenObject.chosenSkin}
                    isClearable
                    onChange={(value : any) => 
                    { 
                        setChosenObject({...chosenObject, chosenSkin: value})
                    }}
                    name="episode"
                    as="select"
                    autoComplete="off"
                    required>
                </Select>
            </div>
            <CButton className="btn-primary-sm wide btn-fin-review-btn float-right" onClick={() => 
            {
                dispatch(ActionCreatorsForArtCatalog.setLoadingImages(true));
                
                const applyFiltersSession = new ApplyFiltersSessionLogger();

                applyFiltersSession.asset_name = 'Characters';
                applyFiltersSession.query_string = searchText ?? '';
                applyFiltersSession.story_name = chosenObject?.chosenStory?.label ?? '';
                applyFiltersSession.episode_name = chosenObject?.chosenEpisode?.label ?? '';
                applyFiltersSession.display = chosenObject?.chosenDisplay?.label ?? '';
                applyFiltersSession.type = chosenObject?.chosenType?.label ?? '';
                applyFiltersSession.body = chosenObject?.chosenBody?.label ?? '';
                applyFiltersSession.skin = chosenObject?.chosenSkin?.label ?? '';
                applyFiltersSession.timestamp = new Date().toUTCString();
                applyFiltersSession.session_id = localStorage.getItem('sessionId') ?? '';
                applyFiltersSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                applyFiltersSession.user_id = localStorage.getItem('uid') ?? '';
                
                applyFiltersSession.logEvent();
                
                GetAssets('api/ArtCatalog/GetAssets_Characters',
                { 
                    storyId: chosenObject.chosenStory != null ? chosenObject.chosenStory.value : null, 
                    episodeId: chosenObject.chosenEpisode != null ? chosenObject.chosenEpisode.value : null, 
                    type: chosenObject.chosenType != null ? chosenObject.chosenType.value : null,
                    bodyType: chosenObject.chosenBody != null ? chosenObject.chosenBody.value : null,
                    skinColor: chosenObject.chosenSkin != null ? chosenObject.chosenSkin.value : null,
                    display: chosenObject.chosenDisplay != null ? chosenObject.chosenDisplay.value : null,
                    searchValue: searchText ?? null
                })
                .then((response) => 
                    { 
                        dispatch(ActionCreatorsForArtCatalog.setCharacters(response)) 
                    })
            }}>Apply filters</CButton>
        </div>
    </div>
)
}

export default Characters;