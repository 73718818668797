import React, { useEffect, useState } from 'react';
import { CDataTable, CTooltip } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import TranslationsStoryEpisodes from './TranslationsStoryEpisodes';
import { MdGTranslate } from 'react-icons/md';
import { GetAllStoriesForTranslations } from '../../services/StoriesServices';
import { useAlert } from 'react-alert';
import { AiOutlineDownload, AiOutlineExport } from 'react-icons/ai';
import { DownloadAppendix, DownloadTranslation } from '../../services/TranslationsServices';
import ReactLoading from 'react-loading';
import { ActionCreatorsForTranslations } from '../../store/TranslationsReducer';
import { isTranslatorExternal } from '../Auth/AuthConfig';
import { TranslationsStorySelectionSessionLogger } from '../../modules/firebaseDetailedLoggers';
import { GameType } from '../Common/Enums/GameType';

export const Translations = () =>
{
    const alert = useAlert();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    
    //reducer state for translations
    const data = useSelector((state: any) => state.translationsReducer.translations);

    const { updatingBool } = useSelector((state: any) => state.translationsReducer);

    //modal state
    const [onRowClicked, setStateOnRowClicked] = useState({ state: false, values: { id: 0 } });

    //table columns
    const columns = 
    [
        {
            key: 'title',      
            sorter: false
        },
        {
            key: 'episodesNum',
            label: 'Episodes',      
            sorter: false
        },
        {
            key: 'authorName',
            sorter: false
        },
        {
            key: 'status',
            sorter: false
        },
        {
            key: 'actions',
            label: 'Actions',
            sorter: 'false'
        }
    ]
    useEffect(() => 
    {  
        document.title = 'Story Maker - Translations'
        setLoading(true);

        GetAllStoriesForTranslations(dispatch, alert).then(() => { setLoading(false); })
    }, [])

	const downloadStoryTranslations = (storyId: string) => {
        DownloadTranslation(storyId,-1,1000, alert)
			.then(response => {
                if(response != null) {
                    //let url = window.URL.createObjectURL(new Blob([JSON.stringify(data, null, 2)], {type: "application/json"}));
                    let url = window.URL.createObjectURL(new Blob([response?.data], {type: response.headers["content-type"]}));
                    let a = document.createElement('a');
                    //a.style.display = 'none';
                    a.href = url;
                    a.download = response.headers['content-disposition'].split('filename=')[1].split(';')[0].replace(/^"|"+$/g, '');
                    //document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
                dispatch(ActionCreatorsForTranslations.setUpdatingBool('not updating'))
		});
	}

	const downloadAppendix = (storyId: string, storyName : string) => {
        DownloadAppendix(storyId, alert)
			.then(response => {
                if(response != null) {
                    //let url = window.URL.createObjectURL(new Blob([JSON.stringify(data, null, 2)], {type: "application/json"}));
                    let url = window.URL.createObjectURL(new Blob([response?.data], {type: response.headers["content-type"]}));
                    let a = document.createElement('a');
                    //a.style.display = 'none';
                    a.href = url;
                    a.download = `${storyName.split(' ').join('')}_Appendix.json`;
                    //document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
                dispatch(ActionCreatorsForTranslations.setUpdatingBool('not updating'))
		});
	}

    return(
        <div className="mt-3 column-style">
            <div className="page-title-container">
                <div className="page-title-icon"><MdGTranslate color="#745DEC" size={26} className="icon-margin" /></div><div className="page-title">Translations</div>
                {/*<div className="page-subtitle">Lorem ipsum dolor sit amet, consectetur adipiscing</div>*/}
            </div>
            <div className="register-signin-left-panel col-sm-12 rounded shadow stories"> 
                { updatingBool === 'updating' ? <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1000, position: 'fixed', top: '0%', left: '0%', width: '100%', height: '2000px', backgroundColor: 'rgb(115,115,115,0.5)'}}>
                    <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1001, position: 'absolute', top: '20%', left: '45%'}}>
                        <ReactLoading height="100px" width="100px" className="mt-3 mb-5" type='spin' color='#0080FF'/>
                    </div>
                </div> 
                : <div/> }
                <CDataTable
                    addTableClasses="stories-transaltion-table"
                    loading={loading}
                    clickableRows
                    items={data}
                    fields={columns}
                    tableFilter
                    itemsPerPageSelect
                    itemsPerPage={10}
                    striped 
                    hover
                    sorter
                    pagination
                    onRowClick={(row: any) => 
                    { 
                        const translationsStorySelectionSession = new TranslationsStorySelectionSessionLogger();
                        
                        translationsStorySelectionSession.story_name = row.title ?? '';
                        translationsStorySelectionSession.timestamp = new Date().toUTCString();
                        translationsStorySelectionSession.session_id = localStorage.getItem('sessionId') ?? '';
                        translationsStorySelectionSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                        translationsStorySelectionSession.user_id = localStorage.getItem('uid') ?? '';

                        translationsStorySelectionSession.logEvent();

                        setStateOnRowClicked({ state: true, values: row })
                    }}
                    responsive={true}
                    scopedSlots = {{
                        'actions': 
                        (item: any, index: any) =>
                        {
                            return(
                                <td className="py-2">
                                    <div className="row-style center mt-1">
                                        { isTranslatorExternal() ? 'N/A' :<AiOutlineDownload onClick={(e) =>
                                        {
                                            e.stopPropagation();
                                            dispatch(ActionCreatorsForTranslations.setUpdatingBool('updating'))
                                            downloadStoryTranslations(item.id)
                                        }} 
                                        className="action-icons" size={21}/>}
                                        
                                        { item.storyGameType == GameType.Lunescape ? <CTooltip content="Appendix Export"><AiOutlineExport onClick={(e) =>
                                        {
                                            e.stopPropagation();
                                            dispatch(ActionCreatorsForTranslations.setUpdatingBool('updating'))
                                            downloadAppendix(item.id, item.title)
                                        }} 
                                        className="action-icons ml-2" size={21}/></CTooltip> : ''}
                                    </div>
                                </td>
                            )
                        }
                    }}
                />
            </div>
        
            { onRowClicked.state === true ? <TranslationsStoryEpisodes translation={onRowClicked.values}/> : <div/> }
        </div>)
}

export default Translations;