import React from 'react';
import ReactLoading from 'react-loading';

export const Loader = ({updatingBool} : any) =>
{
    return(<div>
        { updatingBool === 'updating' ? <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1000, position: 'fixed', top: '0%', left: '0%', width: '100%', height: '2000px', backgroundColor: 'rgb(115,115,115,0.5)'}}>
        <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1001, position: 'absolute', top: '20%', left: '45%'}}>
            <ReactLoading height="100px" width="100px" className="mt-3 mb-5" type='spin' color='#0080FF'/>
        </div>
        </div> : <div/> }
    </div>)
}

export default Loader;