export const charactersColumns = 
[
    {
        key: 'episode',   
        sorter: true
    },
    {
        key: 'name',   
        sorter: true
    },
    {
        key: 'soName',   
        sorter: true
    },
    {
        key: 'typeString',
        label: 'Type', 
        sorter: true
    },
    {
        key: 'bodyTypeString',
        label: 'Body Type',
        sorter: true
    },
    {
        key: 'skinColorString',
        label: 'Skin Color', 
        sorter: true
    },
    {
        key: 'description',   
        sorter: true
    },
    {
        key: 'body',
        label: 'Body Art',   
        sorter: false
    },
    {
        key: 'avatar',   
        sorter: false
    },
    {
        key: 'counter',
        label: 'Ref Counter',
        sorter: true
    },
    {
        key: 'edit',
        label: '',
        sorter: false,
    },
    {
        key: 'delete',
        label: '',
        sorter: false,
    },
]

export const hairstylesColumns = 
[
    {
        key: 'episode',   
        sorter: true
    },
    {
        key: 'character',   
        sorter: true
    },
    {
        key: 'name',   
        sorter: true
    },
    {
        key: 'soName',   
        sorter: true
    },
    {
        key: 'typeString',
        label: 'Type',
        sorter: true
    },
    {
        key: 'description',   
        sorter: true
    },
    {
        key: 'counter',
        label: 'Ref Counter',
        sorter: true
    },
    {
        key: 'edit',
        label: '',
        sorter: false,
    },
    {
        key: 'delete',
        label: '',
        sorter: false
    }
]

export const clothesColumns = 
[
    {
        key: 'episode',   
        sorter: true
    },
    {
        key: 'character',   
        sorter: true
    },
    {
        key: 'name',   
        sorter: true
    },
    {
        key: 'paid',   
        sorter: false
    },
    {
        key: 'soName',   
        sorter: true
    },
    {
        key: 'typeString',
        label: 'Type',
        sorter: true
    },
    {
        key: 'bodyTypeString',
        label: 'Body Type',
        sorter: true
    },
    {
        key: 'description',   
        sorter: true
    },
    {
        key: 'counter',
        label: 'Ref Counter',
        sorter: true
    },
    {
        key: 'edit',
        label: '',
        sorter: false,
    },
    {
        key: 'delete',
        label: '',
        sorter: false
    }
]

export const accessoriesColumns = 
[
    {
        key: 'episode',   
        sorter: true
    },
    {
        key: 'character',   
        sorter: true
    },
    {
        key: 'name',   
        sorter: true
    },
    {
        key: 'soName',   
        sorter: true
    },
    {
        key: 'accessoryType',   
        sorter: true
    },
    {
        key: 'bodyTypeString',
        label: 'Body Type',
        sorter: true
    },
    {
        key: 'description',   
        sorter: true
    },
    {
        key: 'counter',
        label: 'Ref Counter',
        sorter: true
    },
    {
        key: 'edit',
        label: '',
        sorter: false,
    },
    {
        key: 'delete',
        label: '',
        sorter: false
    }
]

export const backgroundsColumns = 
[
    {
        key: 'episode',   
        sorter: true
    },
    {
        key: 'name',   
        sorter: true
    },
    {
        key: 'soName',   
        sorter: true
    },
    {
        key: 'typeString',
        label: 'Type', 
        sorter: true
    },
    {
        key: 'timeOfDayString',
        label: 'Time Of Day', 
        sorter: true
    },
    {
        key: 'description',   
        sorter: true
    },
    {
        key: 'specialConditions',   
        sorter: true
    },
    {
        key: 'counter',
        label: 'Ref Counter',
        sorter: true
    },
    {
        key: 'edit',
        label: '',
        sorter: false,
    },
    {
        key: 'delete',
        label: '',
        sorter: false
    }
]

export const cinematicsColumns = 
[
    {
        key: 'episode',   
        sorter: true
    },
    {
        key: 'name',   
        sorter: true
    },
    {
        key: 'soName',   
        sorter: true
    },
    {
        key: 'typeString',
        label: 'Type', 
        sorter: true
    },
    {
        key: 'description',   
        sorter: true
    },
    {
        key: 'background',
        sorter: true
    },
    {
        key: 'timeOfDayString',
        label: 'Time Of Day', 
        sorter: true
    },
    {
        key: 'charactersOrItems',   
        sorter: true
    },
    {
        key: 'counter',
        label: 'Ref Counter',
        sorter: true
    },
    {
        key: 'edit',
        label: '',
        sorter: false,
    },
    {
        key: 'delete',
        label: '',
        sorter: false
    }
]

export const audioColumns = 
[
    {
        key: 'episode',   
        sorter: true
    },
    {
        key: 'name',   
        sorter: true
    },
    {
        key: 'soName',   
        sorter: true
    },
    {
        key: 'typeString',
        label: 'Type',
        sorter: true
    },
    {
        key: 'description',   
        sorter: true
    },
    {
        key: 'reference',   
        sorter: true
    },
    {
        key: 'counter',
        label: 'Ref Counter',
        sorter: true
    },
    {
        key: 'edit',
        label: '',
        sorter: false,
    },
    {
        key: 'delete',
        label: '',
        sorter: false
    }
]

export const visualsColumns = 
[
    {
        key: 'episode',   
        sorter: true
    },
    {
        key: 'name',   
        sorter: true
    },
    {
        key: 'soName',   
        sorter: true
    },
    {
        key: 'typeString',
        label: 'Type',
        sorter: true
    },
    {
        key: 'reference',   
        sorter: true
    },
    {
        key: 'counter',
        label: 'Ref Counter',
        sorter: true
    },
    {
        key: 'edit',
        label: '',
        sorter: false,
    },
    {
        key: 'delete',
        label: '',
        sorter: false
    }
]

export const itemsColumns = 
[
    {
        key: 'episode',   
        sorter: true
    },
    {
        key: 'name',   
        sorter: true
    },
    {
        key: 'soName',   
        sorter: true
    },
    {
        key: 'typeString',
        label: 'Type',
        sorter: true
    },
    {
        key: 'description',   
        sorter: true
    },
    {
        key: 'reference',   
        sorter: true
    },
    {
        key: 'counter',
        label: 'Ref Counter',
        sorter: true
    },
    {
        key: 'edit',
        label: '',
        sorter: false,
    },
    {
        key: 'delete',
        label: '',
        sorter: false
    }
]