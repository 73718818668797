const addImage = (data: any) =>
({
    alignItems: 'center',
    display: 'flex',

    ':before':
    {
        backgroundImage: `url('${data.path}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '75px 75px',
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 75,
        width: 75
    }
})

export const customStyles =
{
    control: (styles: any) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles: any, { data }: any) => {
        return ({ ...styles, ...addImage(data) })
    }
}

export const characterInputStyles =
{
    control: (styles: any) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles: any, { data }: any) => {
        return ({ ...styles, ...addImage(data) })
    }
}