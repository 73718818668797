import React, { useEffect, useState } from 'react';
import { CInputGroup, CForm, CButton } from '@coreui/react';
import { Field, Formik } from 'formik';
import { AddNewLine } from '../../../services/EpisodeServices';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorsForEditEpisode } from '../../../store/EditEpisodeReducer';
import CreatableSelect from 'react-select/creatable';
import { useAlert } from 'react-alert';
import { LineType } from '../../../interfaces/lineType';
import { DeleteLine } from '../../../services/LineServices';
import { editEpisodeReadOnlyCell, emotionStyle, validations, FormatObject, createDefaultLineTypeOptions, createDefaultLineFormatOptions, createDefaultEmotionsOptions, getInputLineNumber, validateActionChange, validateIfBorCLineFormatSelectedOrNarrator, validateIfBorCLineFormatSelected, validateIfBorCLine, validateEpisodeStatusForDisable, colorLineInsideAnswer, colorLineBasedOnActions, showLineActions, getLineOptionsClass, getClassesWhenInReviewStatus, colorLineWithComment, validateLineOptionsChange, hasLineOptionsChanged, isLineOptionChanged, sortCharacterArray } from './_Helpers';
import { ErrorHandler } from '../../Helpers/ErrorHandler';
import _ from 'lodash';
import { ActionCreators } from '../../../store/StoriesReducer';
import { DeleteLineConfirmationModal } from '../../Helpers/YesNoModal';
import '../../../styles/Lines.css'
import { LineTypes } from '../Enums/LineTypes';
import LineSidebar from '../LineSidebar';

const stateWithClearedLineBasic = (inputLine: boolean, selectedLineIndex: number, lines: LineType[], line: LineType, index: number, isRenderedByAction: boolean, isModalOpen: boolean, maxLineNumber: number, modalClickedLine: LineType, modalType : string) => 
{
    return (inputLine && selectedLineIndex === -1) || isModalOpen ? 
    { 
        docId: line.docId,
        storyId: line.storyId,
        storyTranslationId: line.storyTranslationId,
        episodeId: line.episodeId,
        lineNumber: getInputLineNumber(lines, line, index, maxLineNumber, isModalOpen, modalType),
        depth: isModalOpen && modalType == 'above' ? line.depth : isModalOpen ? (line.depth ?? 0) + ([LineTypes.A, LineTypes.C2, LineTypes.BA, LineTypes.TA, LineTypes.VA].includes(modalClickedLine.lineType ?? 0) ? 1 : 0) : 0,
        lineType: line.lineType,
        lineFormat: line.lineFormat,
        episodeName: line.episodeName,
        previousLineDocId: line.previousLineDocId,
        episodeSerialNumber: line.episodeSerialNumber,

        characterId: line.characterId,
        characterName: line.characterName,
        characterPath: line.characterPath,
        emotion: line.emotion,
        text: isRenderedByAction ? line.text : undefined,

        animationId: undefined,
        animationName: undefined,
        payed: undefined,
        answerNumber: undefined,
        answerQuestionId: undefined,
        questionDocId: undefined,
    
        comment: undefined,
        commentReporterName: undefined,
        commentReporterUID: undefined,
        commentAssignedToName: undefined,
        commentAssignedToUID: undefined,
        commentResolved: undefined,

        musicId: line.musicId,
        musicName: line.musicName,
        musicDescription: line.musicDescription,

        shortSoundEnabled: line.shortSoundEnabled,
        shortSoundId: line.shortSoundId,
        shortSoundName: line.shortSoundName,
        shortSoundDescription: line.shortSoundDescription,

        itemId: line.itemId ?? undefined,
        itemName: line.itemName ?? undefined,
        itemDescription: line.itemDescription ?? undefined,

        avatarEnabled: line.avatarEnabled,

        changeIdentityOn: line.changeIdentityOn,
        changeIdentityName: line.changeIdentityName,

        callPhoneEnabled: line.callPhoneEnabled != null ? line.callPhoneEnabled : undefined,
        callSmsEnabled: line.callSmsEnabled != null ? line.callSmsEnabled : undefined,

        callerCharacterId: line.callerCharacterId,
        callerCharacterName: line.callerCharacterName,

        charmingPoint: line.charmingPoint != null ? line.charmingPoint : undefined,
        premiumChoiceText: line.premiumChoiceText != null ? line.premiumChoiceText : undefined,
        premiumChoiceDescription: line.premiumChoiceDescription != null ? line.premiumChoiceDescription : undefined,
        charactersOutfit: line.charactersOutfit,

        backgroundId: line.backgroundId,
        backgroundName: line.backgroundName,
        backgroundDescription: line.backgroundDescription,
    
        clothes1Name: undefined,
        clothes2Name: undefined,
        clothes3Name: undefined,
        clothes4Name: undefined,
        clothes5Name: undefined,
        clothes6Name: undefined,
        hairstyle1Name: undefined,
        hairstyle2Name: undefined,
        hairstyle3Name: undefined,
    
        clothes1Id: undefined,
        clothes2Id: undefined,
        clothes3Id: undefined,
        clothes4Id: undefined,
        clothes5Id: undefined,
        clothes6Id: undefined,
        hairstyle1Id: undefined,
        hairstyle2Id: undefined,
        hairstyle3Id: undefined
    } 
    : 
    line
}

export const BasicLine = ({ line, inputLine, storyCharacters, index, episodeName, serialNumber, isPreviewInModal, inPreviewIndex }: any) => {
    {
        const dispatch = useDispatch();
        const alert = useAlert(); 
        const { selectedLineIndex, linesFrame, initEditEpisodeBody, lines, linesStartIndex, 
            defaultLineTypes, defaultLineFormats, defaultEmotions, defaultBranchQandC,
            isRenderedByAction, updateCloudBool, isOLineAdded, maxLineNumber, editEpisodePassParam, pickedColorType, pickedActionColor } = useSelector((state: any) => state.editEpisodeReducer);
        const { modalPopupBool } = useSelector((state: any) => state.storiesReducer);
        const [clearedLine, clearLine] = useState(stateWithClearedLineBasic(inputLine, selectedLineIndex,lines, line, index, isRenderedByAction, modalPopupBool.isOpen, maxLineNumber, modalPopupBool.clickedLine, modalPopupBool.type));
        let currentLineTypes = createDefaultLineTypeOptions(lines, index, defaultLineTypes, defaultBranchQandC, isOLineAdded, modalPopupBool.isOpen, modalPopupBool.type);
        let [currentLineFormats, setCurrentLineFormats] = useState(createDefaultLineFormatOptions(defaultLineFormats, modalPopupBool.isModal ? modalPopupBool.currentLine.characterName : line.characterName, editEpisodePassParam.storyGameType, line.lineType));
        let currentEmotions = createDefaultEmotionsOptions(defaultEmotions);

        const [yesnomodal, setYesnomodal] = useState(false);

        let lengthByLineFormatIndex: "S" | "B" | "T" | "C" | "D" = defaultLineFormats?.filter((x: { id: any; }) => x.id === (line.lineFormat ?? 0))[0].label
        let addLineSchema = yup.object().shape(
            {
                //animations: yup.string(),
                //lineType: yup.string(),
                //characterName: yup.string(),
                //emotion: yup.string(),
                text: yup.string().max(validations.L.lengthByLineFormat[lengthByLineFormatIndex], validations.L.text),
            });

        const setupSpanElements = () => {
            let lineNumber = document.querySelector(`span[name="lineNumber${isPreviewInModal ? inPreviewIndex : index}"]`);
            if(lineNumber) lineNumber.innerHTML = line.lineNumber;
            let lineType = document.querySelector(`span[name="lineType${isPreviewInModal ? inPreviewIndex : index}"]`);
            if(lineType) { 
                let types = defaultLineTypes?.filter((x:any) => x.id == line.lineType);
                lineType.innerHTML = types?.length > 0 ? types[0].label : '';
            }
            let lineFormat = document.querySelector(`span[name="lineFormat${isPreviewInModal ? inPreviewIndex : index}"]`);
            if(lineFormat) {
                let formats = defaultLineFormats?.filter((x:any) => x.id == line.lineFormat);
                lineFormat.innerHTML = formats?.length > 0 ? formats[0].label : '';
            }
            let characterName = document.querySelector(`span[name="characterName${isPreviewInModal ? inPreviewIndex : index}"]`);
            if(characterName) characterName.innerHTML = line.characterName ? line.characterName : storyCharacters.find((element : any) => element.id == line.characterId)?.label;
            let emotion = document.querySelector(`span[name="emotion${isPreviewInModal ? inPreviewIndex : index}"]`);
            if(emotion) {
                let emotions = defaultEmotions?.filter((x:any) => x.id == line.emotion);
                emotion.innerHTML = emotions?.length > 0 ? emotions[0].label : '';
            }
            let text = document.querySelector(`input[id="text${isPreviewInModal ? inPreviewIndex : index}"]`) as HTMLInputElement;
            if(text) text.value = line.text ?? "";
        }

        useEffect(() => { 
            if(inputLine && !initEditEpisodeBody && !isRenderedByAction) { 
                let episodeWrapper = document.querySelector('.editEpisodeWrapper');
                if(episodeWrapper) {
                    //episodeWrapper.scrollIntoView({block: 'end', behavior: 'smooth'}); 
                    let inputText = document.querySelector('input[name="text"]');
                    (inputText as HTMLInputElement)?.focus(); 
                }
            }
            if(!inputLine) {
                setupSpanElements()
            }              
        }, [line])

        useEffect(() => 
        { 
            clearLine(stateWithClearedLineBasic(inputLine, selectedLineIndex, lines, line, index, isRenderedByAction, modalPopupBool.isOpen, maxLineNumber, modalPopupBool.clickedLine, modalPopupBool.type))
        }, [line, maxLineNumber])

        useEffect(() => {
            setCurrentLineFormats(createDefaultLineFormatOptions(defaultLineFormats, modalPopupBool.isModalOpen ? modalPopupBool.currentLine.characterName : line.characterName, editEpisodePassParam.storyGameType, line.lineType));
        }, [modalPopupBool.currentLine.characterName, line.characterName, line.avatarEnabled])

        return (
            <Formik validateOnChange={true} validateOnBlur={false} enableReinitialize initialValues={
                {
                    lineNumber: (inputLine && selectedLineIndex === -1) || modalPopupBool.isOpen ? getInputLineNumber(lines,line,index,maxLineNumber,modalPopupBool.isOpen, modalPopupBool.type) : line.lineNumber, 
                    lineType: line.lineType, 
                    lineFormat: line.lineFormat, characterName: line.characterId, emotion: line.emotion, 
                    text: modalPopupBool.isOpen && inputLine && modalPopupBool.index === index 
                    ? ((modalPopupBool.clickedLine.text === modalPopupBool.currentLine.text) ? "" : modalPopupBool.currentLine.text) 
                    : (inputLine && selectedLineIndex === -1 && !isRenderedByAction) ? "" : (line.text ?? "")
                }}
                onSubmit={(values: LineType) => 
                {
                    if(inputLine && selectedLineIndex !== -1 && !modalPopupBool.isOpen) 
                    { 
                        FormatObject(
                            dispatch, 
                            selectedLineIndex, 
                            linesStartIndex,
                            lines,
                            { lineToUpdateId: line.docId, lineModel: {...line, text: values.text} }
                            , alert)
                    }
                    else if(line.changeIdentityOn && !line.changeIdentityName)
                    {
                        alert.info('You must fill in the character name in Change Identity, because you have enabled it.')
                    }
                    else if(values.text!.length > 0 && values.text!.length <= validations.L.lengthByLineFormat[lengthByLineFormatIndex] ) 
                    {
                        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updating'));
                        let previousLine = lines[ 
                            index != null && modalPopupBool.isOpen && modalPopupBool.type == 'above' ? index - 1 
                            : index != null ? index : lines.length - 1];

                        let previousLineActions;
                        if(previousLine && modalPopupBool.isOpen && modalPopupBool.type == 'above')
                        {
                            previousLineActions = {
                                backgroundId: previousLine.backgroundId,
                                backgroundPath: previousLine.backgroundPath,
                                backgroundName: previousLine.backgroundName,
                                backgroundDescription: previousLine.backgroundDescription,
                                musicId: previousLine.musicId,
                                musicPath: previousLine.musicPath,
                                musicName: previousLine.musicName,
                                musicDescription: previousLine.musicDescription,
                                shortSoundId: previousLine.shortSoundId,
                                shortSoundPath: previousLine.shortSoundPath,
                                shortSoundName: previousLine.shortSoundName,
                                shortSoundDescription: previousLine.shortSoundDescription,
                                shortSoundEnabled: previousLine.shortSoundEnabled,
                                itemId: previousLine.itemId,
                                itemName: previousLine.itemName,
                                itemDescription: previousLine.itemDescription,
                                avatarEnabled: previousLine.avatarEnabled,
                                callPhoneEnabled: previousLine.callPhoneEnabled,
                                callSmsEnabled: previousLine.callSmsEnabled,
                                callerCharacterId: previousLine.callerCharacterId,
                                callerCharacterName: previousLine.callerCharacterName,
                                actionChangeFlag: previousLine.actionChangeFlag,
                                charmingPoint: previousLine.charmingPoint,
                                premiumChoiceText: previousLine.premiumChoiceText,
                                premiumChoiceDescription: previousLine.premiumChoiceDescription,
                                charactersOutfit: previousLine.charactersOutfit
                            }
                        }
                        //citamo actions iz reducera, smestamo u objekat i radimo new line;
                        values = {
                            ...clearedLine,
                            ...values,  
                            ...previousLineActions,
                            lineFormat: line.lineFormat == null ? currentLineFormats[0].props.value : line.lineFormat,
                    
                            characterId: line.characterId == null ? storyCharacters[0]?.value : line.characterId,
                            characterName: line.characterName == null ? storyCharacters[0]?.label : line.characterName,
                            characterPath: line.characterPath == null ? storyCharacters[0]?.path : line.characterPath,
                    
                            emotion: line.emotion == null ? parseInt(currentEmotions[0].props.value) : parseInt(line.emotion),
                            episodeName: episodeName,
                            previousLineDocId: previousLine?.docId,
                            episodeSerialNumber: serialNumber, 
                        }

                        var isChangedByAction = validateActionChange(dispatch, values, lines, lines[lines.length-1], true)
                        values = {...values, actionChangeFlag: isChangedByAction}

                        //if clicked + next to line -> reset text
                        if(!inputLine) values.text = undefined;
                        AddNewLine(values, index, linesFrame, selectedLineIndex, dispatch, alert, modalPopupBool.type, modalPopupBool.isOpen)
                    }
                    else 
                    {
                        ErrorHandler(undefined, validations.L.text, alert);
                    }
                }}
                validationSchema={addLineSchema}>
                {({ dirty, isValid, handleSubmit, values }) => {
                    return (
                        <div>
                        <CForm id="add-line-form-submit" className='row' onSubmit={(e : any) => 
                            { 
                                if(!isValid) alert.error('Line you are trying to add is not valid.')
                                if(updateCloudBool === 'updating' || (!modalPopupBool.isOpen && selectedLineIndex !== -1 && e.keyCode == 13)) {
                                    e.preventDefault();
                                    return;
                                }
                                e.preventDefault(); handleSubmit(); 
                            }} disabled={!inputLine}>
                            <CInputGroup className={inputLine ? 'col border-input-line' : 'col'} style={
                                { 
                                    marginLeft: !inputLine ? `${line.depth * parseInt(editEpisodeReadOnlyCell.minWidth.split("px")[0])}px` : "" ,
                                    border: (selectedLineIndex === index) ? `4px solid #bdbdbd` : "",
                                    padding: "0",
                                    paddingLeft: !inputLine ? `4px` : "0"
                                }}
                                onClick={(event: any) => {
                                    if (!inputLine && !modalPopupBool.isOpen)
                                        dispatch(ActionCreatorsForEditEpisode.setCurrentLine(
                                            {currentLine: line, currentLineIndex: index, linesStartIndex: index + 1 > linesFrame ? index + 1 - linesFrame : 0, linesSliceParam: index}))
                                }}>
                                <Field
                                    {...(!inputLine ? 
                                        {
                                            as:"span",
                                            title: showLineActions(line),
                                            style: {...editEpisodeReadOnlyCell, backgroundColor: colorLineInsideAnswer(lines, index, pickedColorType, editEpisodeReadOnlyCell)},
                                            name: `lineNumber${isPreviewInModal ? inPreviewIndex : index}`,
                                        }
                                        : 
                                        {
                                            as: undefined,
                                            name: "lineNumber",
                                            className: 'col-auto',
                                            style: { backgroundColor: "rgb(216, 219, 224)", maxWidth: "50px" }
                                        }
                                    )}
                                    type="text"
                                    value={(inputLine && selectedLineIndex === -1) || modalPopupBool.isOpen ? getInputLineNumber(lines,line,index,maxLineNumber,modalPopupBool.isOpen, modalPopupBool.type) : line.lineNumber}
                                    disabled>
                                </Field>
                                <Field
                                  {...(!inputLine ? 
                                    {
                                        as:"span",
                                        style: {...editEpisodeReadOnlyCell, backgroundColor: colorLineInsideAnswer(lines, index, pickedColorType, editEpisodeReadOnlyCell)},
                                        name: `lineType${isPreviewInModal ? inPreviewIndex : index}`,
                                        className: "col-auto cursor"
                                    } : 
                                    {
                                        as: "select",
                                        id: `lineType${index}`,
                                        name: `lineType`,
                                        className:"col-auto cursor",
                                        value:line.lineType,
                                        onChange:(event: any) => 
                                        {
                                            if(modalPopupBool.isOpen)
                                            {
                                                dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine: 
                                                    {...lines[index], 
                                                        answerNumber: modalPopupBool.currentLine.answerNumber,
                                                        emotion: line.emotion, 
                                                        lineType: parseInt(event.target.value),
                                                        visualId: null,
                                                        visualName: null
                                                    }, isFirstTimeOpen: false }))
                                            }
                                            else
                                            {
                                                dispatch(ActionCreatorsForEditEpisode.updateLineTypeParam(parseInt(event.target.value)))
                                            }
                                        }
                                    }
                                )}
                                    {...((!modalPopupBool.isOpen) && (inputLine && selectedLineIndex!==-1) ? {style: { backgroundColor: "rgb(216, 219, 224)" }} : {} )}
                                    type="text"
                                    disabled={ (!modalPopupBool.isOpen) && ( !inputLine || selectedLineIndex !== -1)}>
                                    {inputLine ? currentLineTypes : ''}
                                </Field>
                                <Field
                                    {...(!inputLine ? 
                                        {
                                            as:"span",
                                            style: editEpisodeReadOnlyCell,
                                            name: `lineFormat${isPreviewInModal ? inPreviewIndex : index}`,
                                            className: "col-auto cursor"
                                        } 
                                        : 
                                        {
                                            as: "select",
                                            className:"col-auto cursor",
                                            name: `lineFormat`,
                                            value:line.lineFormat,
                                            onChange:(event: any) => 
                                            { 
                                                if(modalPopupBool.isOpen)
                                                {
                                                    dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine: 
                                                    {...modalPopupBool.currentLine, 
                                                        lineFormat: parseInt(event.target.value),

                                                        //clear character and emotion if B and C selected
                                                        characterId: parseInt(event.target.value) == 2 || parseInt(event.target.value) == 3 ? undefined : modalPopupBool.currentLine.characterId,
                                                        characterName: parseInt(event.target.value) == 2 || parseInt(event.target.value) == 3 ? undefined : modalPopupBool.currentLine.characterName,
                                                        emotion: parseInt(event.target.value) == 2 || parseInt(event.target.value) == 3 ? 0 : modalPopupBool.currentLine.emotion
                                                    }, isFirstTimeOpen: true }))
                                                }
                                                else
                                                {   
                                                    dispatch(ActionCreatorsForEditEpisode.updateLineFormatParam(parseInt(event.target.value)))

                                                    //clear character and emotion if B and C selected
                                                    if(parseInt(event.target.value) == 2 || parseInt(event.target.value) == 3)
                                                    {
                                                        dispatch(ActionCreatorsForEditEpisode.updateCharacterParam(storyCharacters[0]))
                                                        dispatch(ActionCreatorsForEditEpisode.updateEmotionParam(0))
                                                    }

                                                    if(selectedLineIndex !== -1)
                                                    {
                                                        FormatObject(
                                                            dispatch, 
                                                            selectedLineIndex, 
                                                            linesStartIndex,
                                                            lines, 
                                                            { lineToUpdateId: line.docId, lineModel: {...line, 
                                                                lineFormat: parseInt(event.target.value),
                                                                characterId: parseInt(event.target.value) == 2 || parseInt(event.target.value) == 3 ? storyCharacters[0].id : line.characterId,
                                                                characterName: parseInt(event.target.value) == 2 || parseInt(event.target.value) == 3 ? storyCharacters[0].label : line.characterName,
                                                                emotion: parseInt(event.target.value) == 2 || parseInt(event.target.value) == 3 ? 0 : line.emotion
                                                            }}, alert)
                                                    }
                                                }
                                            }
                                        }
                                    )}
                                    type="text"
                                    disabled={!inputLine || !line.avatarEnabled}>
                                    {inputLine ? currentLineFormats : ''}
                                </Field>
                                <Field
                                    {...(!inputLine ? 
                                        {
                                            as:"span",
                                            style: {...editEpisodeReadOnlyCell, justifyContent: 'unset !important'},
                                            className: "col-sm-1 cursor",
                                            name: `characterName${isPreviewInModal ? inPreviewIndex : index}`,
                                        } 
                                        : 
                                        {
                                            as: CreatableSelect,
                                            className:"createable-select-width cursor p-0 border-fix-input select-width-new-line",
                                            name: "characterName",
                                            options: sortCharacterArray(storyCharacters),
                                            isClearable: true,
                                            isDisabled: (validateIfBorCLine(line)),
                                            backspaceRemovesValue: true,
                                            value: line.characterId && line.characterName
                                            ? { id: line.characterId, value: line.characterId, label: line.characterName, path: line.characterPath } 
                                            : line.characterId && !line.characterName ? storyCharacters.find((element : any) => element.id == line.characterId)
                                            : storyCharacters[0],
                                            onChange:(value: any) => 
                                            {
                                                if(updateCloudBool !== 'updating')
                                                {
                                                    if (value !== null) 
                                                    {
                                                        if (value.__isNew__ !== undefined) 
                                                        {
                                                            if(modalPopupBool.isOpen)
                                                            {
                                                                dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine: {...modalPopupBool.currentLine, 
                                                                    characterId: -1, characterName: value.label, emotion: 1
                                                                }, isFirstTimeOpen: true }))
                                                            }
                                                            else
                                                            {
                                                                if(selectedLineIndex !== -1)
                                                                {
                                                                    FormatObject(
                                                                        dispatch, 
                                                                        selectedLineIndex, 
                                                                        linesStartIndex,
                                                                        lines, 
                                                                        { lineToUpdateId: line.docId, lineModel: 
                                                                            {...line,
                                                                                characterId: "-1", 
                                                                                characterName: value.label,
                                                                                emotion: 1
                                                                            } }, alert)
                                                                }
                                                                dispatch(ActionCreatorsForEditEpisode.updateCharacterParam({ ...value, id: -1 }))
                                                                dispatch(ActionCreatorsForEditEpisode.updateEmotionParam(1))
                                                            }
                                                        }
                                                        else 
                                                        {
                                                            if(modalPopupBool.isOpen)
                                                            {
                                                                dispatch(ActionCreators.setModalPopupBool(
                                                                {...modalPopupBool, 
                                                                    currentLine: 
                                                                    {...modalPopupBool.currentLine,
                                                                        characterId: value.id, 
                                                                        characterName: value.label,
                                                                        lineFormat: value.label == "Narrator" ? 0 : modalPopupBool.currentLine.lineFormat,
                                                                        emotion: !value.label || value.label == "Narrator" || value.label == "None" ? 0 : 1
                                                                    }, isFirstTimeOpen: true }))

                                                                //disable S line format and clear emotion if selected character is narrator
                                                                if(value.label == "Narrator")
                                                                {
                                                                    setCurrentLineFormats(createDefaultLineFormatOptions(defaultLineFormats, value.label, editEpisodePassParam.storyGameType, line.lineType));
                                                                }
                                                                else setCurrentLineFormats(createDefaultLineFormatOptions(defaultLineFormats, value.label, editEpisodePassParam.storyGameType, line.lineType));
                                                            }
                                                            else
                                                            {
                                                                if(selectedLineIndex !== -1)
                                                                {
                                                                    FormatObject(
                                                                        dispatch, 
                                                                        selectedLineIndex, 
                                                                        linesStartIndex,
                                                                        lines, 
                                                                        { lineToUpdateId: line.docId, lineModel: 
                                                                            {...line, 
                                                                                characterId: !value.label ? storyCharacters[0]?.id : value.id, 
                                                                                characterName: !value.label ? storyCharacters[0]?.label : value.label,
                                                                                emotion: !value.label || value.label == "Narrator" || value.label == "None" ? 0 : 1,
                                                                                lineFormat: !value.label || value.label == "Narrator" || value.label == "None" ? 0 : line.lineFormat
                                                                            } }, alert)
                                                                }

                                                                if(!value.label || value.label === "None" || value.label === "Narrator")
                                                                {
                                                                    dispatch(ActionCreatorsForEditEpisode.updateEmotionParam(0))
                                                                }
                                                                else
                                                                {
                                                                    dispatch(ActionCreatorsForEditEpisode.updateEmotionParam(1))
                                                                }

                                                                if(value.label === "Narrator")
                                                                {
                                                                    dispatch(ActionCreatorsForEditEpisode.updateLineFormatParam(0))
                                                                    setCurrentLineFormats(createDefaultLineFormatOptions(defaultLineFormats, value.label, editEpisodePassParam.storyGameType, line.lineType));
                                                                }
                                                                else setCurrentLineFormats(createDefaultLineFormatOptions(defaultLineFormats, value.label, editEpisodePassParam.storyGameType, line.lineType));
                                                                dispatch(ActionCreatorsForEditEpisode.updateCharacterParam(value))
                                                            }    
                                                        }
                                                    }
                                                    else
                                                    {
                                                        if(selectedLineIndex !== -1)
                                                        {
                                                            FormatObject(
                                                                dispatch, 
                                                                selectedLineIndex, 
                                                                linesStartIndex,
                                                                lines, 
                                                                { lineToUpdateId: line.docId, lineModel: 
                                                                    {...line, 
                                                                        characterId: storyCharacters[0]?.id, 
                                                                        characterName: storyCharacters[0]?.label,
                                                                        emotion: 0,
                                                                        lineFormat: 0
                                                                    } }, alert)
                                                        }

                                                        dispatch(ActionCreatorsForEditEpisode.updateLineFormatParam(0))
                                                        dispatch(ActionCreatorsForEditEpisode.updateText(values.text))
                                                        dispatch(ActionCreatorsForEditEpisode.updateEmotionParam(0))
                                                        dispatch(ActionCreatorsForEditEpisode.updateCharacterParam({id: storyCharacters[0]?.id, label: storyCharacters[0]?.label, path: ''}))
                                                    }
                                                }
                                                
                                            }
                                        }
                                    )}
                                    autoComplete="off"
                                    type="text"
                                    disabled={!inputLine}
                                >
                                </Field>
                                <Field
                                    {...(!inputLine ? 
                                        {
                                            as:"span",
                                            style: {...editEpisodeReadOnlyCell, justifyContent: 'unset !important'},
                                            name: `emotion${isPreviewInModal ? inPreviewIndex : index}`,
                                            className: "col-sm-1 cursor"
                                        } 
                                        : 
                                        {
                                            as: "select",
                                            className:"col-sm-1 cursor",
                                            style: emotionStyle,
                                            name: `emotion`,
                                            value: line.emotion,
                                            onChange:(event: any) => 
                                            { 
                                                if(modalPopupBool.isOpen)
                                                {
                                                    dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine: {...modalPopupBool.currentLine, emotion: parseInt(event.target.value)}, isFirstTimeOpen: true } ))
                                                }
                                                else
                                                {
                                                    if(selectedLineIndex !== -1)
                                                    {
                                                        FormatObject(
                                                            dispatch, 
                                                            selectedLineIndex, 
                                                            linesStartIndex,
                                                            lines, 
                                                            { lineToUpdateId: line.docId, lineModel: {...line, emotion: event.target.value } }, alert)
                                                    }
                                                    dispatch(ActionCreatorsForEditEpisode.updateEmotionParam(event.target.value))
                                                }
                                            }
                                        }
                                    )}
                                    type="text"
                                    disabled={!inputLine || (validateIfBorCLineFormatSelectedOrNarrator(line, storyCharacters)) || !line.avatarEnabled || (!line.characterId && !line.characterName)}>
                                    {inputLine ? currentEmotions : ''}
                                </Field>
                                <Field type="text" name="text">
                                { ({ 
                                    field, 
                                    meta: { touched, error } 
                                } : any) => 
                                {
                                    //if(error && touched) alert.error('Your line is empty or bigger then expected!');
                                    return(
                                        <input 
                                            id={`text${isPreviewInModal ? inPreviewIndex : index}`}
                                            autoComplete="off" 
                                            disabled={!inputLine || validateEpisodeStatusForDisable(editEpisodePassParam.episodeStatusEnum)}  
                                            className={ !isValid ? "validate-color-input col cursor" : line.filtered ? 'filtered-color col cursor"' : "col cursor" }
                                            onBlurCapture={(e : any) => 
                                            {
                                                let value = e.target.value
                                                
                                                if(modalPopupBool.isOpen)
                                                {
                                                    dispatch(ActionCreators.updateTextInPopup(value))
                                                }
                                                else
                                                {
                                                    dispatch(ActionCreatorsForEditEpisode.updateText(value))
                                                }
                                                
                                            }}
                                            { ...field } />
                                    )
                                }}
                                </Field>
                                {line.comment && !inputLine? <span className="tooltip-comment-exist" style={{backgroundColor: colorLineWithComment(line)}}></span> : ''}
                            </CInputGroup >
                            {modalPopupBool.isOpen && inputLine && modalPopupBool.index === index  ? <CButton className="ml-5" type="submit" color="primary" size='lg'>Add line</CButton> : ''}

                            <LineSidebar  
                                dispatch={dispatch} 
                                lines={lines} 
                                line={line} 
                                selectedLineIndex={selectedLineIndex} 
                                inputLine={inputLine} 
                                isPreviewInModal={isPreviewInModal}
                                modalPopupBool={modalPopupBool}
                                setYesnomodal={setYesnomodal}
                                editEpisodePassParam={editEpisodePassParam}
                                index={index}
                                pickedActionColor={pickedActionColor}
                                isChangedByOptionFlag={isLineOptionChanged(line)}
                            />
                            
                        </CForm>
                        <DeleteLineConfirmationModal modal={yesnomodal} setModal={setYesnomodal} methodForYes={() => DeleteLine(dispatch, alert, line, index, linesFrame, selectedLineIndex, setYesnomodal, lines[index+1]?.docId)  } title="Delete confirmation" message="Are you sure you want to delete selected line or line block?"/>
                    </div>)
                }}
            </Formik>)
            
        }
    }