import React, { useState } from 'react';
import { CButton, CForm } from '@coreui/react';
import { ErrorMessage, Field, Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { AiOutlineHighlight, AiOutlineFileText, AiOutlineSolution, AiOutlineException, AiOutlineFileProtect, AiOutlineContainer } from 'react-icons/ai';
import ReactLoading from 'react-loading';
import { GetPossibleStoryStatuses, GetStoryGenres, SaveStoryInfo } from "../../services/SetupStoryServices";
import { GetStoryDetailsForSetupStory } from '../../services/StoriesServices';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { contentForChangeEpisodeStatus } from '../..';
import { SetUpSessionLogger } from '../../modules/firebaseAnalyticsLoggers';
import { isWriterLead } from '../Auth/AuthConfig';

const selectStyles = {
    menuPortal: (base : any) => ({ ...base, zIndex: 9999 }),
    menu: (provided : any) => ({ ...provided, zIndex: "9999 !important" })
};

export const SetupStoryInfo = ({ storyId, storyTransId }: any) => 
{
    const dispatch = useDispatch();
    const [statuses, setStatuses] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [dirtySelect, setDirtySelect] = useState(false);
    const alert = useAlert();
    const alertWithClose = useAlert(contentForChangeEpisodeStatus);

    const [genres, setGenres] = useState([]);

    let addStoryInfoSchema = yup.object().shape({
    });

    const story = useSelector((state: any) => state.storiesReducer.storyDetails);
    const [storyInfoSelectedObject, setStoryInfoSelectedObject] = useState({
        title: "",
        shortName: "",
        authorName: "",
        storyStatusEnum: 0,
        releaseDate: "",
        description: "",
        translationNotes: "",
        marketingNotes: "",
        storyGenreEnum: 0,
        storySubGenreEnum: 0
    });

    let setupStoryLogger : any = null;
    const logSetupStorySession = () =>
    {
        setupStoryLogger.activity_end_timestamp = new Date().toUTCString();

        setupStoryLogger.characters_num_end = parseInt(sessionStorage.getItem('charactersNum') ?? '');
        setupStoryLogger.hairstyles_num_end = parseInt(sessionStorage.getItem('hairstylesNum') ?? '');
        setupStoryLogger.clothes_num_end = parseInt(sessionStorage.getItem('clothesNum') ?? '');
        setupStoryLogger.accessories_num_end = parseInt(sessionStorage.getItem('accessoriesNum') ?? '');
        setupStoryLogger.backgrounds_num_end = parseInt(sessionStorage.getItem('backgroundsNum') ?? '');
        setupStoryLogger.cinematics_num_end = parseInt(sessionStorage.getItem('cinematicsNum') ?? '');
        setupStoryLogger.audios_num_end = parseInt(sessionStorage.getItem('audiosNum') ?? '');

        setupStoryLogger.logEvent();

        setupStoryLogger.characters_num_start = setupStoryLogger.characters_num_end;
        setupStoryLogger.hairstyles_num_start = setupStoryLogger.hairstyles_num_end;
        setupStoryLogger.clothes_num_start = setupStoryLogger.clothes_num_end;
        setupStoryLogger.accessories_num_start =  setupStoryLogger.accessories_num_end;
        setupStoryLogger.backgrounds_num_start = setupStoryLogger.backgrounds_num_end;
        setupStoryLogger.cinematics_num_start = setupStoryLogger.cinematics_num_end;
        setupStoryLogger.audios_num_start = setupStoryLogger.audios_num_end;

        setupStoryLogger.activity_start_timestamp = setupStoryLogger.activity_end_timestamp;
    }

    useEffect(() => {
        setLoading(true);
        GetPossibleStoryStatuses(storyId).then((result: any) => {
            setStatuses(result?.map((option: any) => 
            { 
              return { id: option.id, value: option.value, label: option.label }; 
            }));
        });

        GetStoryDetailsForSetupStory(dispatch, storyId, storyTransId)
            .then((response : any) => 
            {
                setLoading(false);
                
                sessionStorage.setItem('charactersNum', '0');
                sessionStorage.setItem('hairstylesNum', '0');
                sessionStorage.setItem('clothesNum', '0');
                sessionStorage.setItem('accessoriesNum', '0');
                sessionStorage.setItem('backgroundsNum', '0');
                sessionStorage.setItem('cinematicsNum', '0');
                sessionStorage.setItem('audiosNum', '0');
        
                setupStoryLogger = new SetUpSessionLogger();
        
                setupStoryLogger.activity_start_timestamp = new Date().toUTCString();
                setupStoryLogger.story_name = response.title;
                setupStoryLogger.logged_in_user_name = localStorage.getItem('user') ?? '';
                setupStoryLogger.user_id = localStorage.getItem('uid') ?? '';
            
                setupStoryLogger.session_id = localStorage.getItem('sessionId') ?? ''; 
                setupStoryLogger.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
            
                setupStoryLogger.characters_num_start = 0;
                setupStoryLogger.hairstyles_num_start = 0;
                setupStoryLogger.clothes_num_start = 0;
                setupStoryLogger.accessories_num_start = 0;
                setupStoryLogger.backgrounds_num_start = 0;
                setupStoryLogger.cinematics_num_start = 0;
                setupStoryLogger.audios_num_start = 0;
        
                const timer = setInterval(() => {
                    logSetupStorySession()
                }, setupStoryLogger.timerValue)
        
                return () => { clearInterval(timer) }
            });

        GetStoryGenres().then((response : any) => setGenres(response));
    }, []);

    useEffect(() => {
        setStoryInfoSelectedObject({
            ...storyInfoSelectedObject,
            title: story.title,
            shortName: story.shortName ?? '',
            authorName: story.authorName,
            storyStatusEnum: story.storyStatusEnum,
            releaseDate: story.releaseDate,
            description: story.description,
            translationNotes: story.translationNotes,
            marketingNotes: story.marketingNotes,
            storyGenreEnum: story.storyGenreEnum,
            storySubGenreEnum: story.storySubGenreEnum
        });
    }, [story]);

    return (
        <Formik
          initialValues={{
            title: storyInfoSelectedObject.title,
            shortName: storyInfoSelectedObject.shortName ?? '',
            authorName: storyInfoSelectedObject.authorName,
            releaseDate: storyInfoSelectedObject.releaseDate,
            description: storyInfoSelectedObject.description,
            translationNotes: storyInfoSelectedObject.translationNotes,
            marketingNotes: storyInfoSelectedObject.marketingNotes,
            storyGenreEnum: storyInfoSelectedObject.storyGenreEnum,
            storySubGenreEnum: storyInfoSelectedObject.storySubGenreEnum
          }}
          onSubmit={() => {
            setLoading(true);
            SaveStoryInfo({ 
              ...storyInfoSelectedObject,
              previousStoryStatus: story.storyStatusEnum,
              id: story.id,
              translationId: story.translationId,
            }, setLoading, dispatch, alertWithClose);
          }}
          validationSchema={addStoryInfoSchema}
        >
          {({ dirty, isValid, handleSubmit }) => {
            return ( 
                <CForm onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                    { loading ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <ReactLoading className="mt-3 mb-5" type='spin' color='#0080FF'/>
                                </div> : 
                        <div>
                            <div className="register-signin-left-panel col-sm-12 rounded shadow setup-stories-info d-none d-md-none d-lg-block ">
                                <div className="info-form-container">
                                    <div className="row-style setup-story-info-top col-sm-12">
                                        <div className="col-sm-4 padding-remove mr-2">
                                            <label className="label-setup-story" htmlFor="title">< AiOutlineContainer size="21" />&nbsp;Title: &nbsp;</label>
                                            <Field
                                                value={storyInfoSelectedObject.title}
                                                onChange={(e : any) => 
                                                { 
                                                    setStoryInfoSelectedObject({...storyInfoSelectedObject, title: e.target?.value})
                                                    setDirtySelect(true);
                                                }}
                                                className="form-control field-setup-story-bold"
                                                name="title"
                                                type="text"
                                                autoComplete="off"
                                                placeholder=""
                                                required
                                                disabled={!(story?.enableStoryNameChange && isWriterLead())}
                                            ></Field>
                                        </div>
                                        <div className="col-sm-2 padding-remove">
                                            <label className="label-setup-story" htmlFor="title">< AiOutlineContainer size="21" />&nbsp;Short Name: &nbsp;</label>
                                            <Field
                                                value={storyInfoSelectedObject.shortName}
                                                onChange={(e : any) => 
                                                { 
                                                    setStoryInfoSelectedObject({...storyInfoSelectedObject, shortName: e.target?.value})
                                                    setDirtySelect(true);
                                                }}
                                                className="form-control field-setup-story-bold"
                                                name="shortName"
                                                type="text"
                                                autoComplete="off"
                                                placeholder=""
                                                required
                                                disabled={!(story?.enableStoryNameChange && isWriterLead())}
                                            ></Field>
                                        </div>
                                        <div className="col-sm-2">
                                            <label className="label-setup-story" htmlFor="authorName">< AiOutlineSolution size="21" />&nbsp;Author: &nbsp;</label>
                                            <Field
                                                value={storyInfoSelectedObject.authorName}
                                                className="form-control field-setup-story-bold"
                                                name="authorName"
                                                type="text"
                                                autoComplete="off"
                                                placeholder=""
                                                disabled
                                            ></Field>
                                        </div>
                                        <div className="col-sm-2">
                                            <label className="label-setup-story" htmlFor="storyStatusEnum">< AiOutlineException size="21" />&nbsp;Status: &nbsp;</label>
                                            <Field
                                                as={Select}
                                                options={statuses}
                                                value={statuses?.find(
                                                    (x: any) => x.value == storyInfoSelectedObject.storyStatusEnum
                                                )}
                                                onChange={(value: any) => {
                                                    setStoryInfoSelectedObject({
                                                        ...storyInfoSelectedObject,
                                                        storyStatusEnum: value.value,
                                                    });
                                                    setDirtySelect(true);
                                                }}
                                                name="storyStatusEnum"
                                                autoComplete="off"
                                                required
                                            ></Field>
                                            <ErrorMessage
                                                component="p"
                                                className="field-colorchange"
                                                name="storyStatusEnum"
                                            />
                                        </div>
                                        <div className="col-sm-2">
                                            <label className="label-setup-story" htmlFor="releaseDate">< AiOutlineFileProtect size="21" />&nbsp;Release Date: &nbsp;</label>
                                            <Field
                                                value={storyInfoSelectedObject.releaseDate}
                                                onChange={(e : any) => 
                                                { 
                                                    setStoryInfoSelectedObject({...storyInfoSelectedObject, releaseDate: e.target?.value})
                                                    setDirtySelect(true);
                                                }}
                                                className="form-control"
                                                name="releaseDate"
                                                type="date"
                                                autoComplete="off"
                                                placeholder=""
                                            ></Field>
                                            <ErrorMessage
                                                component="p"
                                                className="field-colorchange"
                                                name="releaseDate"
                                            />
                                        </div>
                                    </div>
                                    <div className="row-style col-sm-12 setup-story-genre">
                                        <div className="padding-remove col-sm-3">
                                                <label className="label-setup-story" htmlFor="storyStatusEnum">< AiOutlineException size="21" />&nbsp;Genre: &nbsp;</label>
                                                <Field
                                                    as={Select}
                                                    options={genres}                        
                                                    styles={selectStyles}
                                                    menuPortalTarget={document.querySelector('body')}
                                                    value={genres?.find(
                                                        (x: any) => x.value == storyInfoSelectedObject.storyGenreEnum
                                                    )}
                                                    onChange={(value: any) => {
                                                        setStoryInfoSelectedObject({
                                                            ...storyInfoSelectedObject,
                                                            storyGenreEnum: value.value,
                                                        });
                                                        setDirtySelect(true);
                                                    }}
                                                    name="storyStatusEnum"
                                                    autoComplete="off"
                                                    required
                                                ></Field>
                                                <ErrorMessage
                                                    component="p"
                                                    className="field-colorchange"
                                                    name="storyStatusEnum"
                                                />
                                            </div>
                                        <div className="setup-story-author col-sm-3">
                                                <label className="label-setup-story" htmlFor="storyStatusEnum">< AiOutlineException size="21" />&nbsp;Sub genre: &nbsp;</label>
                                                <Field
                                                    as={Select}
                                                    options={genres}
                                                    styles={selectStyles}
                                                    menuPortalTarget={document.querySelector('body')}
                                                    value={genres?.find(
                                                        (x: any) => x.value == storyInfoSelectedObject.storySubGenreEnum
                                                    )}
                                                    onChange={(value: any) => {
                                                        setStoryInfoSelectedObject({
                                                            ...storyInfoSelectedObject,
                                                            storySubGenreEnum: value.value,
                                                        });
                                                        setDirtySelect(true);
                                                    }}
                                                    name="storyStatusEnum"
                                                    autoComplete="off"
                                                    required
                                                ></Field>
                                                <ErrorMessage
                                                    component="p"
                                                    className="field-colorchange"
                                                    name="storyStatusEnum"
                                                />
                                            </div>
                                        <div className="col-sm-6"/>
                                    </div>
                                    <div className="row-style notes-setup-story">
                                        <div className="setup-story-desciption ">
                                            <label className="label-setup-story" htmlFor="description">< AiOutlineFileText size="21" />&nbsp;Description: &nbsp;</label>
                                            <Field
                                                value={storyInfoSelectedObject.description}
                                                onChange={(e : any) => 
                                                { 
                                                    setStoryInfoSelectedObject({...storyInfoSelectedObject, description: e.target?.value})
                                                    setDirtySelect(true);
                                                }}
                                                className="form-control"
                                                name="description"
                                                type="text"
                                                component="textarea"
                                                rows="3"
                                                autoComplete="off"
                                                placeholder=""
                                            ></Field>
                                            <ErrorMessage
                                                component="p"
                                                className="field-colorchange"
                                                name="description"
                                            />
                                        </div>
                                        <div className="setup-story-transl-notes ">
                                            <label className="label-setup-story" htmlFor="translationNotes">< AiOutlineHighlight size="21" />&nbsp;Translation Notes: &nbsp;</label>
                                            <Field
                                                value={storyInfoSelectedObject.translationNotes}
                                                onChange={(e : any) => 
                                                { 
                                                    setStoryInfoSelectedObject({...storyInfoSelectedObject, translationNotes: e.target?.value})
                                                    setDirtySelect(true);
                                                }}
                                                className="form-control"
                                                name="translationNotes"
                                                type="text"
                                                component="textarea"
                                                rows="3"
                                                autoComplete="off"
                                                placeholder=""
                                            ></Field>
                                            <ErrorMessage
                                                component="p"
                                                className="field-colorchange"
                                                name="translationNotes"
                                            />
                                        </div>
                                        <div className="setup-story-mark-notes ">
                                        <label className="label-setup-story" htmlFor="marketingNotes">< AiOutlineHighlight size="21" />&nbsp;Marketing Notes: &nbsp;</label>
                                            <Field
                                                value={storyInfoSelectedObject.marketingNotes}
                                                onChange={(e : any) => 
                                                { 
                                                    setStoryInfoSelectedObject({...storyInfoSelectedObject, marketingNotes: e.target?.value})
                                                    setDirtySelect(true);
                                                }}
                                                className="form-control"
                                                name="marketingNotes"
                                                type="text"
                                                component="textarea"
                                                rows="3"
                                                autoComplete="off"
                                                placeholder=""
                                            ></Field>
                                            <ErrorMessage
                                                component="p"
                                                className="field-colorchange"
                                                name="marketingNotes"
                                            />
                                        </div>
                                    </div>
                                </div> 
                                <div className="info-form-container">
                                    <div style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                                        <CButton type="submit" color="primary" style={{ float: "right", width: "100px"}} disabled={!(dirty || dirtySelect) || !isValid}>
                                            Save
                                        </CButton>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    }
                </CForm>
            );
        }}
      </Formik>
    )
};