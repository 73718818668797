import React, { useEffect, useState } from 'react';
import { CButton, CDataTable, CForm, CModal, CModalBody, CModalHeader, CModalTitle, CTooltip } from '@coreui/react';
import * as yup from 'yup';
import { ErrorMessage, Field, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import ReactLoading from 'react-loading';
import { useAlert } from 'react-alert';

//project imports 
import { AddEpisode, ChangeEpisodeTitle, DeleteEpisode, ExportEpisode, GetConcreteStory } from '../../services/EpisodeServices';
import { useHistory } from 'react-router-dom';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineSolution, AiOutlinePlusCircle, AiOutlineSave, AiOutlineExport, AiTwotoneEdit } from 'react-icons/ai';

import { isAnyWriter, isNarrativeDesigner, isOnlyReviewer, isReviewer, isWriter, isWriterExternal, isWriterLead } from '../Auth/AuthConfig';
import { BsArrowRightShort } from 'react-icons/bs';
import { YesNoModal } from '../Helpers/YesNoModal';
import { ActionCreatorsForTranslations } from '../../store/TranslationsReducer';
import { ActionCreatorsForEditEpisode } from '../../store/EditEpisodeReducer';
import { EpisodeSelectionSessionLogger, StoriesSetupStoryClickSessionLogger } from '../../modules/firebaseDetailedLoggers';

let createNewEpisodeSchema = yup.object().shape({
    title: yup.string().max(255, 'Maximum amount of characters in episode title is 255.').required("Required."),
    titleDescription: yup.string().max(400, 'Maximum amount of characters for title description is 400.').required("Required."),
});

let changeTitleSchema = yup.object().shape({
    title: yup.string().max(255, 'Maximum amount of characters in episode title is 255.').required("Required.")
});

export const ConcreteStory = ({ story }: any) =>
{   
    const dispatch = useDispatch();
    const history = useHistory();

    const data = useSelector((state: any) => state.storiesReducer.concreteStory);
    const { updateCloudBool } = useSelector((state: any) => state.editEpisodeReducer);
    //loading state
    const [loading, setLoading] = useState(false);
    
    //modal state
    const [visible, setVisible] = useState(false);

    //table columns
    const columns = 
    [
        {
            key: 'serialNumber',    
            sorter: false,
            label: ''
        },
        {
            key: 'title',   
            sorter: false
        },
        {
            key: 'createdDate',   
            sorter: false
        },
        {
            key: 'comments',   
            sorter: false
        },
        {
            key: 'episodeStatusOverall', 
            label: 'Status',    
            sorter: true
        },
        {
            key: 'inReviewDate',    
            label: 'In Review', 
            sorter: true
        },
        {
            key: 'readyForTranslationDate',   
            label: 'Ready for Translation',  
            sorter: false
        },
        {
            key: 'actions',
            label: 'Actions', 
            sorter: false
        }
    ]

    const alert = useAlert();

    const [yesnomodal, setYesnomodal] = useState({modalOpen: false, itemId: "", modalLoading: false});
    const [changeEpisodeTitlePopup, setChangeEpisodeTitlePopup] = useState({isOpen: false, title: '', episodeId: ''});

    useEffect(() =>
    {
        setLoading(true); 
        GetConcreteStory(story.id, story.translationId, dispatch, alert).then(() => setLoading(false));

        if(sessionStorage.getItem('isCreatedBool') !== null)
        {
            setVisible(true);        
            sessionStorage.clear();
        }
    }, [story.id])

	const exportData = (storyId: string, translationId: string, episodeId: string, storyName: any, episodeName: any, episodeNumber : any, gameType : number) => {
        ExportEpisode(storyId,translationId,episodeId, alert, gameType)
			.then(response => {
                if(response != null) {
                    //let url = window.URL.createObjectURL(new Blob([JSON.stringify(data, null, 2)], {type: "application/json"}));
                    let url = window.URL.createObjectURL(new Blob([JSON.stringify(response?.data)], {type: "application/json"}));
                    let a = document.createElement('a');
                    //a.style.display = 'none';
                    a.href = url;
                    a.download = `${storyName.split(' ').join('')}_E${episodeNumber}_ParseDocument.json`;
                    //document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
                dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('not updating'));
		});
	}

    return(
        <div className="register-signin-left-panel col-sm-12 rounded shadow stories-bottom-cont"> 
        
        { updateCloudBool === 'updating' ? <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1000, position: 'fixed', top: '0%', left: '0%', width: '100%', height: '2000px', backgroundColor: 'rgb(115,115,115,0.5)'}}>
            <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1001, position: 'absolute', top: '20%', left: '45%'}}>
                <ReactLoading height="100px" width="100px" className="mt-3 mb-5" type='spin' color='#0080FF'/>
            </div>
        </div> : <div/> }
        <div>
                <h4 className="story-title">{story.title}</h4>
                <div className="row-style mb-4">
                    {isWriter() || isWriterLead() || isNarrativeDesigner() ?
                    <CButton 
                    onMouseDown={(e : any) => { if(e.button == 1) {
                        e.preventDefault();

                        const setupStoryLogger = new StoriesSetupStoryClickSessionLogger();
        
                        setupStoryLogger.story_name = story.title ?? '';
                        setupStoryLogger.timestamp = new Date().toUTCString();
                        setupStoryLogger.session_id = localStorage.getItem('sessionId') ?? '';
                        setupStoryLogger.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                        setupStoryLogger.user_id = localStorage.getItem('uid') ?? '';
        
                        setupStoryLogger.logEvent();

                        window.open(`/setup-story/&storyId=${story.id}&storyTransId=${story.translationId}`, '_blank')
                    }} }
                    onClick={() => { 
                        const setupStoryLogger = new StoriesSetupStoryClickSessionLogger();
        
                        setupStoryLogger.story_name = story.title ?? '';
                        setupStoryLogger.timestamp = new Date().toUTCString();
                        setupStoryLogger.session_id = localStorage.getItem('sessionId') ?? '';
                        setupStoryLogger.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                        setupStoryLogger.user_id = localStorage.getItem('uid') ?? '';
        
                        setupStoryLogger.logEvent();
                        
                        history.push(`/setup-story/&storyId=${story.id}&storyTransId=${story.translationId}`)
                    }} className="btn-primary-sm wide stp-story">
                        <AiOutlineSolution className="icon-primary-btn" />
                    &nbsp;Setup Story</CButton> : ''}
                    {isWriter() || isWriterExternal() || isWriterLead() ? <CButton onClick={() => setVisible(!visible)} className="btn-primary-sm wide add-episode ml-3"><AiOutlinePlusCircle className="icon-primary-btn " />&nbsp;Add Episode</CButton> : '' }
            </div>        
            <CDataTable
                addTableClasses='stories-episodes-data-table'
                items={data}
                loading={loading}
                fields={columns}
                striped 
                tableFilter
                itemsPerPageSelect
                itemsPerPage={5}
                hover
                sorter
                pagination
                responsive={true}
                scopedSlots = {{
                    'title':
                    (item: any, index : any) =>
                    {
                        return(<td className="cursor row-style" style= {{display: 'flex', justifyContent: 'space-between'}}
                            onMouseDown={(e : any) => 
                            { 
                                if(e.button == 1) {
                                    e.preventDefault();

                                    const episodeSelectionLogger = new EpisodeSelectionSessionLogger();
                                    
                                    episodeSelectionLogger.episode_name = item.title ?? '';
                                    episodeSelectionLogger.timestamp = new Date().toUTCString();
                                    episodeSelectionLogger.session_id = localStorage.getItem('sessionId') ?? '';
                                    episodeSelectionLogger.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                                    episodeSelectionLogger.user_id = localStorage.getItem('uid') ?? '';

                                    episodeSelectionLogger.logEvent();
                                    
                                    window.open(`/edit-episode/&storyId=${story.id}&storyTransId=${story.translationId}&episodeId=${item.id}`, '_blank')
                                }
                            } }
                        ><p onClick={() => 
                        {
                            const episodeSelectionLogger = new EpisodeSelectionSessionLogger();
                            
                            episodeSelectionLogger.episode_name = item.title ?? '';
                            episodeSelectionLogger.timestamp = new Date().toUTCString();
                            episodeSelectionLogger.session_id = localStorage.getItem('sessionId') ?? '';
                            episodeSelectionLogger.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                            episodeSelectionLogger.user_id = localStorage.getItem('uid') ?? '';

                            episodeSelectionLogger.logEvent();
                            
                            history.push(`/edit-episode/&storyId=${story.id}&storyTransId=${story.translationId}&episodeId=${item.id}`); 
                        }}>{item.title}</p>
                        {isAnyWriter() ? <AiTwotoneEdit style={{float: 'right'}} size={23} className="cursor" onClick={() => { 
                                if(item.canUpdateTitleAndDescription)
                                    setChangeEpisodeTitlePopup({isOpen: true, title: item.title, episodeId: item.id})
                                else
                                    alert.info('You cannot edit title for this episode.')
                                }}/> : ''}
                        </td> )
                    },
                    'actions':
                    (item : any, index : any) => 
                    {
                        return(
                            <td className="py-2">
                                <div className="row-style center mt-1">
                                    { isAnyWriter() && (item.episodeStatusOverallEnum != 0 && item.episodeStatusOverallEnum != 1 && item.episodeStatusOverallEnum != 3) ? 
                                        <CTooltip placement="top" content="Export episode">
                                            <AiOutlineExport size={21} className="mr-3 action-icons cursor" onClick={() => { 
                                                dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updating'));
                                                exportData(item.storyId, item.storyTranslationId, item.id, item.storyName, item.title, item.serialNumber, item.storyGameType)
                                            }}/>   
                                        </CTooltip>
                                        : 
                                        <CTooltip placement="top" content="Export episode is not available for episodes in the following statuses: Created, In progress and Correction required or you are not authorized for this action.">
                                            <AiOutlineExport size={21} className="mr-3 action-icons" color="gray" />   
                                        </CTooltip>
                                    }
                                    <CTooltip placement="top" content="Edit episode">
                                        <AiOutlineEdit size={21} className="mr-3 action-icons cursor" 
                                            onMouseDown={(e : any) => { if(e.button == 1) 
                                            {
                                                e.preventDefault();

                                                const episodeSelectionLogger = new EpisodeSelectionSessionLogger();
                                                
                                                episodeSelectionLogger.episode_name = item.title ?? '';
                                                episodeSelectionLogger.timestamp = new Date().toUTCString();
                                                episodeSelectionLogger.session_id = localStorage.getItem('sessionId') ?? '';
                                                episodeSelectionLogger.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                                                episodeSelectionLogger.user_id = localStorage.getItem('uid') ?? '';

                                                episodeSelectionLogger.logEvent();
                                                
                                                window.open(`/edit-episode/&storyId=${story.id}&storyTransId=${story.translationId}&episodeId=${item.id}`, '_blank')
                                            }} }
                                            onClick={() => 
                                            { 
                                                const episodeSelectionLogger = new EpisodeSelectionSessionLogger();
                                                
                                                episodeSelectionLogger.episode_name = item.title ?? '';
                                                episodeSelectionLogger.timestamp = new Date().toUTCString();
                                                episodeSelectionLogger.session_id = localStorage.getItem('sessionId') ?? '';
                                                episodeSelectionLogger.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                                                episodeSelectionLogger.user_id = localStorage.getItem('uid') ?? '';
            
                                                episodeSelectionLogger.logEvent();
                                                
                                                history.push(`/edit-episode/&storyId=${story.id}&storyTransId=${story.translationId}&episodeId=${item.id}`); }}/> 
                                    </CTooltip>
                                    { isAnyWriter() ?<CTooltip placement="top" content="Delete episode">
                                        <AiOutlineDelete
                                            className="action-icons cursor" 
                                            size={21} 
                                            onClick={() => 
                                            { 
                                                setYesnomodal({ modalOpen: true, itemId: item.id, modalLoading: false}); 
                                            }}
                                        />
                                    </CTooltip> : '' }
                                </div>
                            </td>
                        )
                    },
                    'inReviewDate':
                    (item : any) => 
                    (
                        <td>{item.inReviewDate === "" ? 'N/A' : item.inReviewDate}</td>
                    ),
                    'readyForTranslationDate':
                    (item : any) => 
                    (
                        <td>{item.readyForTranslationDate === "" ? 'N/A' : item.readyForTranslationDate}</td>
                    ),
                }}
            />
        </div>  
            <CModal show={changeEpisodeTitlePopup.isOpen} className="create-new-episode-modal" fade onClose={() => setChangeEpisodeTitlePopup({isOpen: false, title: '', episodeId: ''})} closeOnBackdrop={false}>
                <CModalHeader closeButton>
                    <CModalTitle>Change episode title</CModalTitle>
                </CModalHeader> 
                <CModalBody>
                    <Formik enableReinitialize initialValues={{ title: changeEpisodeTitlePopup.title }}
                        onSubmit={(values) => 
                        { 
                            ChangeEpisodeTitle({
                                storyId: story.id,
                                translationId: story.translationId,
                                episodeId: changeEpisodeTitlePopup.episodeId,
                                text: values.title
                            }, dispatch, alert, setChangeEpisodeTitlePopup);
                        }}
                        validationSchema={changeTitleSchema}>
                        {({ dirty, isValid, handleSubmit }) => 
                        {
                            return(
                                <CForm onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                    <div className="form-group-name col-sm-12 modal-row">
                                        <label htmlFor="title">Episode Title</label>
                                        <Field
                                            className="form-control"
                                            name="title"
                                            type="text"
                                            autoComplete="off"
                                            placeholder="Enter episode title..."
                                            required>
                                        </Field>
                                        <ErrorMessage component="p" className="field-colorchange" name="title" />
                                    </div>
                                    <div className="modal-buttons-container float-right">
                                        <CButton type="submit" disabled={!dirty || !isValid} className="mr-2" color="primary-sm">Confirm</CButton>
                                        <CButton color="cancel-sm" onClick={() => setChangeEpisodeTitlePopup({isOpen: false, title: '', episodeId: ''})}>Cancel</CButton>
                                    </div>
                                </CForm>
                            )}
                        }
                    </Formik>
                </CModalBody>
            </CModal>
            <YesNoModal modal={yesnomodal} setModal={setYesnomodal} methodForYes={() => DeleteEpisode(story.id, story.translationId, yesnomodal.itemId, alert, setLoading, setYesnomodal) } title="Delete confirmation" message="Are you sure you want to delete all lines of this episode?"/>
            <CModal show={visible} className="create-new-episode-modal" fade onClose={() => setVisible(false)} closeOnBackdrop={false}>
                <CModalHeader closeButton>
                    <CModalTitle>Create New Episode</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <Formik initialValues={{ title: '', titleDescription: '' }}
                        onSubmit={(values) => 
                        { 
                            if(data.filter(function(element : any) { return element.title === values.title }).length === 0)
                            {
                                setVisible(false);
                                AddEpisode({...values, 
                                    storyGameTypeString: story.storyGameTypeString, 
                                    storyName: story.title, 
                                    storyId: story.id, 
                                    storyTranslationId: story.translationId, 
                                    language: story.language
                                }, dispatch, alert, history, !data || data.length == 0, story.authorName);
                            }
                            else
                            {
                                alert.error('Episode with tme same title already exists.')
                            }

                        }}
                        validationSchema={createNewEpisodeSchema}>
                        {({ dirty, isValid, handleSubmit }) => 
                        {
                            return(
                                <CForm onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                    <div className="form-group-name col-sm-6 modal-row">
                                        <label htmlFor="title">Episode Title *</label>
                                        <Field
                                            className="form-control"
                                            name="title"
                                            type="text"
                                            autoComplete="off"
                                            placeholder="Enter episode title..."
                                            required>
                                        </Field>
                                        <ErrorMessage component="p" className="field-colorchange" name="title" />
                                    </div>
                                    <div className="form-group-name col-sm-12 modal-row">
                                        <label htmlFor="titleDescription">Title Description</label>
                                        <Field
                                            className="form-control"
                                            style={{height: '250px'}}
                                            name="titleDescription"
                                            type="text"
                                            as="textarea"
                                            autoComplete="off"
                                            required>
                                        </Field>
                                        <ErrorMessage component="p" className="field-colorchange" name="titleDescription" />
                                    </div>
                                    <div className="modal-buttons-container float-right">
                                        <CButton type="submit" disabled={!dirty || !isValid} className="mr-2" color="primary-sm">Create Episode</CButton>
                                        <CButton color="cancel-sm" onClick={() => setVisible(false)}>Cancel</CButton>
                                    </div>
                                </CForm>
                            )}
                        }
                    </Formik>
                </CModalBody>
            </CModal>
        </div>)
}

export default ConcreteStory;