import { CForm, CInputGroup } from '@coreui/react';
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { editEpisodeReadOnlyCell } from '../../Lines/_Helpers';
import * as yup from 'yup';
import { TranslateLine } from '../../../../services/TranslationsServices';
import { useAlert } from 'react-alert' 
import { ActionCreatorsForTranslations } from '../../../../store/TranslationsReducer';
import { calculateMovementOnClickLine } from './_TranslationsHelpers';

let textValidationSchema = yup.object().shape(
    {
        text: yup.string(),
});

export const VisualChoiceAnswerTranslationLine = ({ line, index, inputLine, enableInput, storyId, serialNumber, language, updatingArray, isBranchAnswer, isPreview } : any ) =>
{
    const dispatch = useDispatch();
    const { lineTypes } = useSelector((state: any) => state.translationsReducer);
    const { selectedLineIndex, serbianTranslate, englishTranslate, otherLanguageTranslate, linesFrame, defaultLine } = useSelector((state: any) => state.translationsReducer);

    useEffect(() => 
    {
        let answerNumber = document.querySelector(`span[id="answerNumber${index}"]`)
        if(answerNumber) 
        {
            answerNumber.innerHTML = line.answerNumber;
        }
        
        let lineNumber = document.querySelector(`span[id="lineNumber${index}"]`);
        if(lineNumber) lineNumber.innerHTML = isBranchAnswer ? '-' : line.lineNumber;
        let lineType = document.querySelector(`span[id="lineType${index}"]`);
        if(lineType) 
        { 
            let types = lineTypes?.filter((x:any) => x.id == line.lineType);
            lineType.innerHTML = types.length > 0 ? (isBranchAnswer ? 'A' : types[0].label) : '';
        }
        let text = document.querySelector(`span[id="visualName${index}"]`);
        if(text) text.innerHTML = line.visualName ?? '';
    }, [line, lineTypes])


    return(
        <Formik 
            validateOnChange={true} 
            validateOnBlur={false} 
            enableReinitialize 
            initialValues={{}}
            onSubmit={(values: any) =>  {}}
            validationSchema={textValidationSchema}>
            {({ dirty, isValid, handleSubmit, errors }) => 
            {
                return (
                <div>
                    <CForm className={inputLine ? 'is-input-translations row' : 'row'} onSubmit={(e : any) => 
                        { 
                            e.preventDefault();
                            handleSubmit();
                        }}>
                        <CInputGroup className='col' style={{marginLeft: !inputLine ? `${line.depth * parseInt(editEpisodeReadOnlyCell.minWidth.split("px")[0])}px` : "" ,}}
                        onClick={() =>
                            {
                                if(!inputLine && !isPreview)
                                {
                                    let movement = calculateMovementOnClickLine(index, selectedLineIndex, linesFrame, serbianTranslate, englishTranslate)

                                    dispatch(ActionCreatorsForTranslations.setCurrentLineForTranslations(
                                    {
                                        currentLineSerbian: serbianTranslate && serbianTranslate?.length != 0 && !serbianTranslate[0]?.isDefault ? serbianTranslate[movement] : defaultLine,
                                        currentLineEnglish: englishTranslate && englishTranslate?.length != 0 && !englishTranslate[0]?.isDefault ? englishTranslate[movement] : defaultLine,
                                        currentLineOther: otherLanguageTranslate && otherLanguageTranslate?.length != 0 && !otherLanguageTranslate[0]?.isDefault ? otherLanguageTranslate[movement] : defaultLine,
                                        currentLineIndex: movement, 
                                        linesStartIndex: movement + 1 > linesFrame ? movement + 1 - linesFrame : 0
                                    }))
                                }
                            }}
                        >          
                            <span style={editEpisodeReadOnlyCell} id={`lineNumber${index}`}></span>
                            <span style={editEpisodeReadOnlyCell} id={`lineType${index}`}></span>
                            <span style={editEpisodeReadOnlyCell} id={`answerNumber${index}`}></span>
                            <span style={editEpisodeReadOnlyCell} id={`visualName${index}`}></span>
                            <input style={{flexGrow: 1}} disabled></input>
                        </CInputGroup>
                    </CForm>
                </div>)}}
        </Formik>)
}
