import { axiosInstance } from '../axios/key';
import { generateBAnumbers, generateLineNumbers, generateQAnumbers, generateTAnumbers, generateVAnumbers } from '../components/Common/Lines/_Helpers';
import { ErrorHandler } from '../components/Helpers/ErrorHandler';
import { ActionCreatorsForTranslations, defaultLine } from '../store/TranslationsReducer';

export const GetEpisodeTranslations = async (storyId : any, storyTransId : any, episodeId : any, alert : any, dispatch : any) =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/Translations/GetEpisodeTranslations?storyId=${storyId}&storyTranslationId=${storyTransId}&episodeId=${episodeId}`)
        dispatch(ActionCreatorsForTranslations.setEpisodeTranslatuions(data));
    }
    catch(e)
    {
        ErrorHandler(e, 'Error in getting episode translations', alert);
    }
}

export const UploadFile = async (body : any, alert : any, dispatch : any, index : any) =>
{
    try
    {
        var formData = new FormData();
        formData.append("file", body.file);
        formData.append("storyId", body.storyId);
        formData.append("serialNumber", body.serialNumber);
        formData.append("language", body.language);
        const { data } = await axiosInstance.post('api/Translations/UploadTranslation', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
        if(data?.success)
        {
            dispatch(ActionCreatorsForTranslations.updateStatusOfLanguageInTranslations({ index: index, newStatus: data?.newStatus, newStatusString: data?.newStatusString }))
        }

        dispatch(ActionCreatorsForTranslations.setUpdatingBool('not updating'));
        alert.info(data?.message ?? 'Successfuly uploaded file.')
    }
    catch(e)
    {
        dispatch(ActionCreatorsForTranslations.setUpdatingBool('not updating'))
        ErrorHandler(e, 'Error in uploading file.', alert);
    }
}

export const DownloadTranslation = async (storyId: any, serialNumber: any, language: any, alert : any) => {
    try
    {
        return await axiosInstance.get(`api/Translations/DownloadTranslation?storyId=${storyId}&serialNumber=${serialNumber}&language=${language}`, {responseType: 'arraybuffer'});
    }
    catch(e)
    {
        ErrorHandler(e, 'There was an error while trying to download translation file.', alert);
        return null;
    }
}

export const DownloadAppendix = async (storyId: any, alert : any) => {
    try
    {
        return await axiosInstance.get(`api/Admin/LunescapeAppendixExport?storyId=${storyId}`, { responseType: 'arraybuffer' });
    }
    catch(e)
    {
        ErrorHandler(e, 'There was an error while trying to download appendix file.', alert);
        return null;
    }
}

export const GetTranslationsLines = async (storyId : any, serialNumber : any, language : any, dispatch : any) =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/Translations/GetTranslationsLines?storyId=${storyId}&serialNumber=${parseInt(serialNumber)}&language=${parseInt(language)}`)
     
        let currentSerbianLine = defaultLine;
        let currentOtherLanguageLine = defaultLine; 
        let currentEnglishLine = defaultLine;

        let serbianArrayWithAnswerNumbers;
        let englishArrayWithAnswerNumbers;
        let otherArrayWithAnswerNumbers;

        var linesStartIndex = 0;
        if(data.serbianTranslate && data.serbianTranslate?.length != 0) 
        {
            generateLineNumbers(data.serbianTranslate, 0, 0, data.serbianTranslate); 
            currentSerbianLine = {...data.serbianTranslate[data.serbianTranslate?.length - 1]}

            serbianArrayWithAnswerNumbers = generateQAnumbers(data.serbianTranslate);
            serbianArrayWithAnswerNumbers = generateVAnumbers(serbianArrayWithAnswerNumbers);
            serbianArrayWithAnswerNumbers = generateTAnumbers(serbianArrayWithAnswerNumbers);
            serbianArrayWithAnswerNumbers = generateBAnumbers(serbianArrayWithAnswerNumbers);

            linesStartIndex = data.serbianTranslate.length + 1 > 10 ? data.serbianTranslate.length - 10 + 1 : 0;
        }

        if(data.englishTranslate && data.englishTranslate?.length != 0) 
        { 
            generateLineNumbers(data.englishTranslate, 0, 0, data.englishTranslate); 
            currentEnglishLine = {...data.englishTranslate[data.englishTranslate?.length - 1]}

            englishArrayWithAnswerNumbers = generateQAnumbers(data.englishTranslate);
            englishArrayWithAnswerNumbers = generateVAnumbers(englishArrayWithAnswerNumbers);
            englishArrayWithAnswerNumbers = generateTAnumbers(englishArrayWithAnswerNumbers);
            englishArrayWithAnswerNumbers = generateBAnumbers(englishArrayWithAnswerNumbers);

            linesStartIndex = data.englishTranslate.length + 1 > 10 ? data.englishTranslate.length - 10 + 1 : 0;
        }

        if(data.otherLanguageTranslate && data.otherLanguageTranslate?.length != 0) 
        {
            generateLineNumbers(data.otherLanguageTranslate, 0, 0, data.otherLanguageTranslate); 
            currentOtherLanguageLine = {...data.otherLanguageTranslate[data.otherLanguageTranslate?.length - 1]}

            otherArrayWithAnswerNumbers = generateQAnumbers(data.otherLanguageTranslate);
            otherArrayWithAnswerNumbers = generateVAnumbers(otherArrayWithAnswerNumbers);
            otherArrayWithAnswerNumbers = generateTAnumbers(otherArrayWithAnswerNumbers);
            otherArrayWithAnswerNumbers = generateBAnumbers(otherArrayWithAnswerNumbers);

            linesStartIndex = data.otherLanguageTranslate.length + 1 > 10 ? data.otherLanguageTranslate.length - 10 + 1 : 0;
        }

        dispatch(ActionCreatorsForTranslations.setCurrentLineForTranslations(
        {
            currentLineSerbian: currentSerbianLine, 
            currentLineEnglish: currentEnglishLine,
            currentLineOther: currentOtherLanguageLine,
            currentLineIndex: -1, 
            linesStartIndex: linesStartIndex
        }))

        dispatch(ActionCreatorsForTranslations.setLanguagesLines(
        {...data, 
            serbianTranslate: serbianArrayWithAnswerNumbers,
            englishTranslate: englishArrayWithAnswerNumbers,
            otherLanguageTranslate: otherArrayWithAnswerNumbers 
        }))
    }
    catch
    {
        console.log('Error in getting translation lines');
    }
}


export const GetTranslationLanguages = async (storyId : any, serialNumber : any, alert : any) =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/Translations/GetTranslationLanguages?storyId=${storyId}&serialNumber=${serialNumber}`);
        return data;
    }
    catch(e)
    {
        ErrorHandler(e, 'There was an error while trying to get languages.', alert);
    }
}

export const TranslateLine = async (body : any, alert : any, dispatch : any) =>
{
    try
    {
        const { data } = await axiosInstance.post('api/Translations/TranslateLine', body);

        dispatch(ActionCreatorsForTranslations.updateLineAfterTranslate(body))
    }
    catch(e)
    {
        dispatch(ActionCreatorsForTranslations.setUpdatingBool('error'))
        ErrorHandler(e, 'Error occured while trying to translate line.', alert)
    }
}

export const GetAppendix = async (storyId : any, serialNumber : any, language : any, alert : any, dispatch : any) =>
{
    try
    {
        const { data } = await axiosInstance.get(`api/Translations/GetAppendix?storyId=${storyId}&serialNumber=${serialNumber}&language=${parseInt(language)}`);
        
        var objectForTitle = 
        { 
            storyId: storyId, 
            episodeSerialNumber: 'Title', 
            serbianDescription: data.serbianStoryTitle,
            englishDescription: data.englishStoryTitle,
            otherDescription: data.otherStoryTitle 
        }

        var objectForFeatureText = 
        { 
            storyId: storyId, 
            episodeSerialNumber: 'Feature Text', 
            serbianDescription: data.serbianFeatureText, 
            englishDescription: data.englishFeatureText, 
            otherDescription: data.otherFeatureText 
        }
        dispatch(ActionCreatorsForTranslations.setAppendix({...data, episodeAppendix: [objectForTitle, objectForFeatureText].concat(data.episodeAppendix)}));   
    }
    catch(e)
    {
        ErrorHandler(e, 'Error in getting appendix', alert);
    }
}

export const TranslateCertainPart = async (url : any, body : any, alert : any) =>
{
    try
    {
        await axiosInstance.post(url, body);
    }
    catch(e)
    {
        ErrorHandler(e, 'Error in translating.', alert);
    }
}

export const GetPossibleStatusesForTranslate = async (storyId : any, serialNumber : any, language : any, dispatch : any) =>
{
    try
    {
        const { data } = await axiosInstance.post(`api/StateMachine/GetPossibleEpisodeStatusesForTranslate?storyId=${storyId}&serialNumber=${serialNumber}&language=${language}`)
        dispatch(ActionCreatorsForTranslations.setTranslationStatuses({statuses: data.statuses, currentStatus: data.currentStatus}));
    }
    catch(e)
    {
        console.log('Error in getting statuses for translate.');
    }
}

export const ChangeEpisodeStatusForTranslate = async (storyId : any, serialNumber : any, language : any, newStatus: any) =>
{
    try
    {
        const { data } = await axiosInstance.post(`api/StateMachine/ChangeEpisodeStatusForTranslate?storyId=${storyId}&serialNumber=${serialNumber}&language=${language}&newStatus=${newStatus}`)
        return data;
    }
    catch(e)
    {
        console.log('Error in changing status for translate.');
    }
}

export const ChangeEpisodeStatusToCorrectionRequired = async (storyId: string, serialNumber: number) => 
{
    try
    {
        const { data } = await axiosInstance.post(`api/StateMachine/ChangeEpisodeStatus_TranslationStateToCorrectionRequired?storyId=${storyId}&serialNumber=${serialNumber}`);
        return data;
    }
    catch(e)
    {
        console.log('Error occured while trying to change episode status to correction required.')
    }
}