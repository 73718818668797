import React, { useEffect, useState } from "react"
import { CButton, CInput, CInputGroup, CLabel, CModal, CModalBody, CModalHeader, CSelect } from "@coreui/react"
import { AiOutlineControl } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { LineType } from "../../interfaces/lineType";
import { useAlert } from 'react-alert';
import { createDefaultActionLinesOptions, FormatObject, handleCallChange, hasLineActionsChanged, hasLineOptionsChanged, isDressCharacterValid, resetLineParamsInPopup, selectStylesForField, sortCharacterArray, updateLineParamsInPopup, validateActionChange, validateEpisodeStatusForDisable, validateLineOptionsChange } from "../Common/Lines/_Helpers";
import { ActionCreatorsForEditEpisode, defaultLine } from "../../store/EditEpisodeReducer";
import { ActionCreators } from "../../store/StoriesReducer";
import { GetAccessoryTypes, GetActionAccessories, GetActionBackgrounds, GetActionClothes, GetActionHairstyles, GetActionMusicThemes, GetActionShortSounds } from "../../services/EpisodeServices";
import { GetChangeOutfitTypes, GetStoryItems } from "../../services/LineServices";
import { ResetToDefaultSessionLogger, UpdateLineSessionLogger } from "../../modules/firebaseDetailedLoggers";
import CreatableSelect from 'react-select/creatable';
import Switch from "react-switch";
import { GameType } from "../Common/Enums/GameType";
import { ImCross } from "react-icons/im";
import EpisodeActionsEffect from "../Common/EpisodeActionsEffects";
import Select from 'react-select';

const getActionLines = (lines: any, selectedLineIndex: number, defaultLineTypes: any) => {
    return lines?.filter((line: LineType, index: number) => line.actionChangeFlag === true && index !== selectedLineIndex)?.map((line: any) => { 
        let text = [0,4,5,9,10].includes(line.lineType ?? 0) ? (line.text ?? "") :
                   line.lineType === 1 ? `${line.clothes1Name ?? ''} ${line.clothes2Name ?? ''} ${line.clothes3Name ?? ''} ${line.clothes4Name ?? ''} ${line.clothes5Name ?? ''} ${line.clothes6Name ?? ''}` :
                   [2,8].includes(line.lineType ?? 0) ? `${line.hairstyle1Name ?? ''} ${line.hairstyle2Name ?? ''} ${line.hairstyle3Name ?? ''}` :
                   line.lineType === 3 ? `None White Black Asian` :
                   line.lineType === 6 ? `${line.animationName ?? ''}` :
                   line.lineType === 7 ? `${line.clothes1Name ?? ''} ${line.clothes2Name ?? ''} ${line.clothes3Name ?? ''}` :
                   ''

        let types = defaultLineTypes?.filter((x:any) => x.id == line.lineType);
        return {
            id: line.docId, 
            value: line.docId, 
            label: `${[9,10,11].includes(types?.length > 0 ? types[0].id : 9) ? '-' : line.lineNumber} - ${types?.length > 0 ? (types[0].label == 'BA' ? 'A' : types[0].label) : ''} - ${text}`
        }
    });
}

const EditEpisodeActionsPopup = ({storyId, storyName, episodeName, modal, actionObject, previousLineUpdate, previousStateActions, selectedLineIndex}: any ) => 
{
    const dispatch = useDispatch();
    const alert = useAlert();

    const gameType = useSelector((state : any) => state.storiesReducer.gameType);
    const {
        backgrounds, 
        musics, 
        shortSounds, 
        clothes, 
        accessories, 
        hairstyles, 
        accessoryTypes, 
        lines, 
        linesStartIndex, 
        updateCloudBool, 
        charactersChangeOufit, 
        editEpisodePassParam, 
        defaultLineTypes, 
        changeOutfitTypes
    } = useSelector((state: any) => state.editEpisodeReducer);

    const [isUpdate, setIsUpdate] = useState(false);
    
    let actionLinesOptions = createDefaultActionLinesOptions(getActionLines(lines, selectedLineIndex, defaultLineTypes))
    
    const [checkedOption, setCheckedOption] = useState(actionObject.callPhoneEnabled ? 'phone' : actionObject.callSmsEnabled ? 'sms' : 'none');
    const [characterParams, setCharacterParams] = useState(
    {
        characterId: '',
        characterName: '',
        clothesId: '',
        clothesName: '',
        clothesDescription: '',
        hairstyleId: '',
        hairstyleName: '',
        hairstyleDescription: '',
        accessoryEnabled: false,
        accessoryId: '',
        accessoryName: '',
        accessoryDescription: '',
        accessoryType: 0,
        changeOutfitClothesType: -1,
        changeOutfitHairstyleType: -1
    });

    const [isMeCharacter, setIsMeCharacter] = useState(false);
    const handleCallOptionChange = (value: string) =>
    {
        switch(value)
        {
            case 'none':
                setCheckedOption('none');
                handleCallChange(previousLineUpdate, false, false, dispatch);
                break;
            case 'phone':
                setCheckedOption('phone');
                handleCallChange(previousLineUpdate, false, true, dispatch);
                break;
            case 'sms':
                setCheckedOption('sms');
                handleCallChange(previousLineUpdate, true, false, dispatch);
                break;
            default:
                return;
        }
    }

    const resetActionLinesOptionsToDefault = () => 
    {
        let line: LineType = lines?.find((x: LineType) => x.docId === actionObject.docId)
        if(line) {
            if(previousLineUpdate)
            {    
                handleCallOptionChange(line.callPhoneEnabled ? 'phone' : line.callSmsEnabled ? 'sms' : 'none');
                updateLineParamsInPopup(line, selectedLineIndex, dispatch);
            }
            else
            {
                dispatch(ActionCreatorsForEditEpisode.updateBackgroundParam({id: line.backgroundId, value: line.backgroundName, label: line.backgroundName}))
                dispatch(ActionCreatorsForEditEpisode.updateBackgroundDescription(line.backgroundDescription))
                dispatch(ActionCreatorsForEditEpisode.updateMusicThemeParam({id: line.musicId, value: line.musicName, label: line.musicName}))
                dispatch(ActionCreatorsForEditEpisode.updateMusicThemeDescription(line.musicDescription))
                dispatch(ActionCreatorsForEditEpisode.updateShortSoundParam(
                    {
                        id: selectedLineIndex == -1 && !line.shortSoundEnabled ? undefined : line.shortSoundId, 
                        value: selectedLineIndex == -1 && !line.shortSoundEnabled ? undefined : line.shortSoundName, 
                        label: selectedLineIndex == -1 && !line.shortSoundEnabled ? undefined : line.shortSoundName
                }))
                dispatch(ActionCreatorsForEditEpisode.updateShortSoundDescription(
                    selectedLineIndex == -1 && !line.shortSoundEnabled ? undefined : line.shortSoundDescription
                ))
                dispatch(ActionCreatorsForEditEpisode.updateShortSoundBool(
                    selectedLineIndex == -1 && !line.shortSoundEnabled ? false : line.shortSoundEnabled
                ))
                
                dispatch(ActionCreatorsForEditEpisode.updateShowItem(
                    {
                        id: selectedLineIndex == -1 ? undefined : line.itemId, 
                        value: selectedLineIndex == -1 ? undefined : line.itemName, 
                        label: selectedLineIndex == -1 ? undefined : line.itemName
                    }
                ))
                dispatch(ActionCreatorsForEditEpisode.updateShowItemDescription(
                    selectedLineIndex == -1 ? undefined : line.itemDescription
                ))
                
                dispatch(ActionCreatorsForEditEpisode.updateCallerName({id: line.callerCharacterId, value: line.callerCharacterId, label: line.callerCharacterName}))
                dispatch(ActionCreatorsForEditEpisode.updateAvatarBool(!line.avatarEnabled && selectedLineIndex == -1 ? true : line.avatarEnabled))
                handleCallOptionChange(line.callPhoneEnabled ? 'phone' : line.callSmsEnabled ? 'sms' : 'none')
                dispatch(ActionCreatorsForEditEpisode.updateCharmingPoint(selectedLineIndex == -1 ? undefined : line.charmingPoint))
                dispatch(ActionCreatorsForEditEpisode.updatePremiumChoiceText(selectedLineIndex == -1 ? undefined : line.premiumChoiceText))
                dispatch(ActionCreatorsForEditEpisode.updatePremiumChoiceDescription(selectedLineIndex == -1 ? undefined : line.premiumChoiceDescription))
                dispatch(ActionCreatorsForEditEpisode.replaceCharactersOutfit(line.charactersOutfit));
    
                dispatch(ActionCreatorsForEditEpisode.updateCharacterIdentityEnabled(selectedLineIndex == -1 ? false : line.changeIdentityOn))
                dispatch(ActionCreatorsForEditEpisode.updateCharacterIdentityName(selectedLineIndex == -1 ? null : line.changeIdentityName))
            }
        }  
        let selectActionsElem = document.querySelector('select[name="actionsFromSpecificLineSelect"]')  
        if(selectActionsElem) {
            (selectActionsElem as HTMLSelectElement).selectedIndex = 0
        };           
    }


    const [backgroundDescription, setBackgroundDescription] = useState(actionObject.backgroundDescription);
    const [musicThemeDescription, setMusicThemeDescription] = useState(actionObject.musicDescription);
    const [shortSoundDescription, setShortSoundDescription] = useState(actionObject.shortSoundDescription);

    const customStyles =
    {
        control: (styles : any) => ({ ...styles, backgroundColor: 'white' }),
        // we don't want to render images for now
        //option: (styles : any, { data } : any) => 
        //{
        //    return({ ...styles, ...addImage(data) })
        //}
    }

    useEffect(() => {
        actionLinesOptions = createDefaultActionLinesOptions(getActionLines(lines, selectedLineIndex, defaultLineTypes))
        //resetActionLinesOptionsToDefault()
    }, [selectedLineIndex])

    useEffect(() =>
    {
        GetActionBackgrounds(storyId).then((result: any) => 
        {
            dispatch(ActionCreatorsForEditEpisode.setBackgrounds(result?.map((option : any) => { return { id: option.id, value: option.name, label: option.name, description: option.description }})));
        })
        GetActionMusicThemes(storyId).then((result: any) => 
        { 
            dispatch(ActionCreatorsForEditEpisode.setMusics(result?.map((option : any) => { return { id: option.id, value: option.name, label: option.name, description: option.description  }})));
        })
        GetActionShortSounds(storyId).then((result: any) => 
        { 
            dispatch(ActionCreatorsForEditEpisode.setShortSounds(result?.map((option : any) => { return { id: option.id, value: option.name, label: option.name, description: option.description  }})));
        })
        GetStoryItems(storyId, alert).then((result : any) => 
        {
            dispatch(ActionCreatorsForEditEpisode.setStoryItems(result?.map((option: any) => { return { id: option.id, value: option.name, label: option.name, description: option.description } })));
        });
        GetAccessoryTypes(dispatch, alert);
        GetChangeOutfitTypes(dispatch, alert);
     }, [])

    useEffect(() => 
    {
        GetActionClothes(storyId, characterParams.characterId).then((result: any) => 
        { 
            var clothes = result?.map((option: any) => { return { id: option.id, value: option.name, label: option.name, description: option.description  } })
            dispatch(ActionCreatorsForEditEpisode.setClothes(clothes));
        })
        GetActionHairstyles(storyId, characterParams.characterId).then((result: any) => 
        { 
            var hairstyles = result?.map((option: any) => { return { id: option.id, value: option.name, label: option.name, description: option.description  } })
            dispatch(ActionCreatorsForEditEpisode.setHairstyles(hairstyles));
        })
        GetActionAccessories(storyId, characterParams.characterId).then((result: any) => 
        {
            var accessories = result?.map((option: any) => { return { id: option.id, value: option.name, label: option.name, description: option.description, accessoryType: option.accessoryType  } })
            dispatch(ActionCreatorsForEditEpisode.setAccessories(accessories))
        })
    }, [characterParams.characterId])

    useEffect(() => 
    {
        setCheckedOption(actionObject.callPhoneEnabled ? 'phone' : actionObject.callSmsEnabled ? 'sms' : 'none')
    }, [actionObject.callPhoneEnabled, actionObject.callSmsEnabled])

    useEffect(() =>
    {
        setCharacterParams({
            characterId: '',
            characterName: '',
            clothesId: '',
            clothesName: '',
            clothesDescription: '',
            hairstyleId: '',
            hairstyleName: '',
            hairstyleDescription: '',
            accessoryEnabled: false,
            accessoryId: '',
            accessoryName: '',
            accessoryDescription: '',
            accessoryType: 0,
            changeOutfitClothesType: 0,
            changeOutfitHairstyleType: 0,
        })
    }, [actionObject.charactersOutfit])

    useEffect(() =>
    {
        if(characterParams.clothesName)
            setCharacterParams({...characterParams, clothesName: clothes.find((el : any) => el.id == characterParams.clothesId)?.label ?? ''});
    }, [clothes])

    {
        return(
        <CModal className="actions-modal" closeOnBackdrop={false} show={modal} onClose={() => dispatch(ActionCreators.setModalPopupBoolAction({isOpen: false, line: actionObject, index: selectedLineIndex}))}>
            <CModalHeader closeButton style={{color:'white', alignItems:'center'}}><AiOutlineControl size={21}/>&nbsp;&nbsp;Actions</CModalHeader>
            <CModalBody>

            <div>
            <fieldset style={validateEpisodeStatusForDisable(editEpisodePassParam.episodeStatusEnum) ? 
                { 
                    pointerEvents: 'none', 
                    opacity: 0.7,
                    border: '5px solid rgba(0, 0, 154, 0.5)',
                    borderStyle: 'ridge',  
                    boxSizing: 'border-box',
                    textAlign: 'left'
                } : {}} className="register-signin-left-panel col-sm-12 rounded shadow edit-episode comments action">
                {validateEpisodeStatusForDisable(editEpisodePassParam.episodeStatusEnum) ? <legend className="rounded nt-info legend-actions shadow">Action are disabled due to the current status of the episode.</legend> : ''}
                <div>
                <div className="column-style mt-3 ml-1">
                    <div className="row-style" style={{marginRight: '-30px'}}>
                        <CSelect
                            name="actionsFromSpecificLineSelect"
                            style={{minWidth: '120px', maxWidth: '350px'}}
                            onChange={(element : any) => 
                                { 
                                    let line: LineType = lines?.find((x: LineType) => x.docId === element.currentTarget.selectedOptions[0].value)
                                    if(line) {
                                        if(previousLineUpdate)
                                        {
                                            handleCallOptionChange(line.callPhoneEnabled ? 'phone' : line.callSmsEnabled ? 'sms' : 'none');
                                            updateLineParamsInPopup(line, selectedLineIndex, dispatch);
                                        }
                                        else
                                        {
                                            dispatch(ActionCreatorsForEditEpisode.updateBackgroundParam({id: line.backgroundId, value: line.backgroundName, label: line.backgroundName}))
                                            dispatch(ActionCreatorsForEditEpisode.updateBackgroundDescription(line.backgroundDescription))
                                            dispatch(ActionCreatorsForEditEpisode.updateMusicThemeParam({id: line.musicId, value: line.musicName, label: line.musicName}))
                                            dispatch(ActionCreatorsForEditEpisode.updateMusicThemeDescription(line.musicDescription))
                                            dispatch(ActionCreatorsForEditEpisode.updateShortSoundParam({id: line.shortSoundId, value: line.shortSoundName, label: line.shortSoundName}))
                                            dispatch(ActionCreatorsForEditEpisode.updateShortSoundDescription(line.shortSoundDescription))
                                            dispatch(ActionCreatorsForEditEpisode.updateShortSoundBool(line.shortSoundEnabled))
    
                                            dispatch(ActionCreatorsForEditEpisode.updateShowItem({id: line.itemId, value: line.itemName, label: line.itemName}))
                                            dispatch(ActionCreatorsForEditEpisode.updateShowItemDescription(line.itemDescription))
    
                                            dispatch(ActionCreatorsForEditEpisode.updateAvatarBool(line.avatarEnabled))
                                            handleCallOptionChange(line.callPhoneEnabled ? 'phone' : line.callSmsEnabled ? 'sms' : 'none')
                                            dispatch(ActionCreatorsForEditEpisode.replaceCharactersOutfit(line.charactersOutfit));
    
                                            dispatch(ActionCreatorsForEditEpisode.updateCallerName({id: line.callerCharacterId, value: line.callerCharacterId, label: line.callerCharacterName}))
                                            dispatch(ActionCreatorsForEditEpisode.updateCharacterIdentityEnabled(line.changeIdentityOn))
                                            dispatch(ActionCreatorsForEditEpisode.updateCharacterIdentityName(line.changeIdentityName))
                                        }
                                    }
                                    else {
                                        if(previousLineUpdate)
                                        {
                                            handleCallOptionChange('none');
                                            resetLineParamsInPopup(dispatch);
                                        }
                                        else
                                        {
                                            dispatch(ActionCreatorsForEditEpisode.updateBackgroundParam({id: null, label: '', path: '', description: ''})) 
                                            dispatch(ActionCreatorsForEditEpisode.updateBackgroundDescription(''))
                                            dispatch(ActionCreatorsForEditEpisode.updateMusicThemeParam({id: null, label: '', path: '', description: ''}))
                                            dispatch(ActionCreatorsForEditEpisode.updateMusicThemeDescription(''))
                                            dispatch(ActionCreatorsForEditEpisode.updateShortSoundParam({id: null, label: '', path: '', description: ''}))
                                            dispatch(ActionCreatorsForEditEpisode.updateShortSoundDescription(''))
                                            dispatch(ActionCreatorsForEditEpisode.updateShortSoundBool(false))
    
                                            dispatch(ActionCreatorsForEditEpisode.updateShowItem({id: null, label: '', description: ''}))
                                            dispatch(ActionCreatorsForEditEpisode.updateShowItemDescription(''))
                                            dispatch(ActionCreatorsForEditEpisode.updateShowItemBool(false))
    
                                            dispatch(ActionCreatorsForEditEpisode.updateCallerName({id: null, value: null, label: null}))
                                            dispatch(ActionCreatorsForEditEpisode.updateAvatarBool(true))
                                            handleCallOptionChange('none')
                                            dispatch(ActionCreatorsForEditEpisode.updateCharmingPoint(''))
                                            dispatch(ActionCreatorsForEditEpisode.updatePremiumChoiceText(''))
                                            dispatch(ActionCreatorsForEditEpisode.updatePremiumChoiceDescription(''))
                                            dispatch(ActionCreatorsForEditEpisode.clearCharactersOutfit());
    
                                            dispatch(ActionCreatorsForEditEpisode.updateCharacterIdentityEnabled(false))
                                            dispatch(ActionCreatorsForEditEpisode.updateCharacterIdentityName(null))
                                        }
                                    }
                                }}
                        >
                            {actionLinesOptions}
                        </CSelect>
                        <CButton
                            className={`btn-primary-sm border shadow ml-1`}
                            disabled={!hasLineActionsChanged(actionObject, lines, previousStateActions, false)}
                            onClick={() => {
                                const resetToDefaultSession = new ResetToDefaultSessionLogger();

                                resetToDefaultSession.story_name = storyName ?? '';
                                resetToDefaultSession.episode_name = episodeName ?? '';
                                resetToDefaultSession.timestamp = new Date().toUTCString();
                                resetToDefaultSession.session_id = localStorage.getItem('sessionId') ?? '';
                                resetToDefaultSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                                resetToDefaultSession.user_id = localStorage.getItem('uid') ?? '';

                                resetToDefaultSession.logEvent();

                                resetActionLinesOptionsToDefault()
                            }}>
                                Reset to default
                        </CButton>
                        <CButton
                            className="btn-primary-sm border shadow ml-auto mr-0"
                            disabled={!previousLineUpdate || (!previousLineUpdate && !hasLineActionsChanged(actionObject, lines, previousStateActions, false))}
                            onClick={() => 
                                {
                                    var isChangedByAction = validateActionChange(dispatch, actionObject, lines, previousStateActions, false, previousLineUpdate);

                                    if(isChangedByAction)
                                    {
                                        const updateLineSession = new UpdateLineSessionLogger();

                                        updateLineSession.story_name = storyName ?? '';
                                        updateLineSession.episode_name = episodeName ?? '';
                                        updateLineSession.timestamp = new Date().toUTCString();
                                        updateLineSession.session_id = localStorage.getItem('sessionId') ?? '';
                                        updateLineSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                                        updateLineSession.user_id = localStorage.getItem('uid') ?? '';

                                        updateLineSession.logEvent();

                                        FormatObject(dispatch, selectedLineIndex, linesStartIndex, lines, { lineToUpdateId: actionObject.docId, lineModel: actionObject }, alert, previousLineUpdate, false);
                                    }
                                    else
                                    {
                                        alert.show('There needs to be a change of action to perform this function.')
                                    }
                                }} >
                                Update line
                        </CButton>
                    </div>
                </div>
                <div className="column-style mt-3 ml-1">
                    <div className="row-style">
                        <div className="column-style form-group-name col-sm-4  action-col1">
                            <CLabel className="action-col-title">Scene actions</CLabel>
                            <CLabel className="ac-top-lbl" htmlFor="background">Background</CLabel>
                            <CreatableSelect 
                                createOptionPosition="first"
                                options={backgrounds}
                                styles={{...customStyles, ...selectStylesForField}}
                                menuPortalTarget={document.querySelector('body')}
                                name="background"
                                value={{id: actionObject.backgroundId, value: actionObject.backgroundName, label: actionObject.backgroundName}}
                                onChange={(value : any) => 
                                { 
                                    if(value !== null)
                                    {
                                        if(previousLineUpdate)
                                        {
                                            dispatch(ActionCreators.updateBackgroundInPopup(value))
                                            dispatch(ActionCreators.updateBackgroundDescriptionInPopup(value.description))
                                        }
                                        else
                                        {
                                            dispatch(ActionCreatorsForEditEpisode.updateBackgroundParam(value))
                                            dispatch(ActionCreatorsForEditEpisode.updateBackgroundDescription(value.description))
                                        }
                                    }
                                    else
                                    {
                                        if(previousLineUpdate)
                                        {
                                            dispatch(ActionCreators.updateBackgroundInPopup({id: null, value: '', label: ''})) 
                                            dispatch(ActionCreators.updateBackgroundDescriptionInPopup(''))
                                        }
                                        else
                                        {
                                            dispatch(ActionCreatorsForEditEpisode.updateBackgroundParam({id: null, label: '', path: '', description: ''})) 
                                            dispatch(ActionCreatorsForEditEpisode.updateBackgroundDescription(''))
                                        }
                                    }
                                }}
                                onCreateOption={(value : any) => 
                                {
                                    if(previousLineUpdate)
                                    {
                                        dispatch(ActionCreators.updateBackgroundInPopup({ id: '-1', value: value, label: value }))
                                    }
                                    else
                                    {
                                        dispatch(ActionCreatorsForEditEpisode.updateBackgroundParam({ id: '-1', value: value, label: value }))
                                    }
                                }}
                                isClearable
                                backspaceRemovesValue
                                autoComplete="off"
                                placeholder="Choose a background...."
                                required>
                            </CreatableSelect>
                            <CInput
                                className="form-control desc-input"
                                name="assignedTo"
                                type="text"
                                disabled={actionObject.backgroundId == '' ? true : actionObject.backgroundId == null ? true : actionObject.backgroundId === '-1' ? false : true}
                                value={backgroundDescription || ''}
                                onChange={(e : any) => { if(updateCloudBool !== 'updating') setBackgroundDescription(e.target.value) } }
                                autoComplete="off"
                                placeholder="Description"
                                required>
                            </CInput>
                            <CLabel className="ac-sec-lbl" htmlFor="assignedTo">Music theme</CLabel>
                            <CreatableSelect 
                                createOptionPosition="first"
                                options={musics}
                                styles={{...customStyles, ...selectStylesForField}}
                                menuPortalTarget={document.querySelector('body')}
                                value={{id: actionObject.musicId, value: actionObject.musicName, label: actionObject.musicName}}
                                onChange={(value : any) => 
                                    { 
                                        if(value !== null)
                                        {
                                            if(previousLineUpdate)
                                            {
                                                dispatch(ActionCreators.updateMusicThemeInPopup(value))
                                                dispatch(ActionCreators.updateMusicThemeDescriptionInPopup(value.description))
                                            }
                                            else
                                            {
                                                dispatch(ActionCreatorsForEditEpisode.updateMusicThemeParam(value))
                                                dispatch(ActionCreatorsForEditEpisode.updateMusicThemeDescription(value.description))
                                            }
                                        }
                                        else
                                        {
                                            if(previousLineUpdate)
                                            {
                                                dispatch(ActionCreators.updateMusicThemeInPopup({id: null, value: '', label: ''}))
                                                dispatch(ActionCreators.updateMusicThemeDescriptionInPopup(''))
                                            }
                                            else
                                            {
                                                dispatch(ActionCreatorsForEditEpisode.updateMusicThemeParam({id: null, label: '', path: '', description: ''}))
                                                dispatch(ActionCreatorsForEditEpisode.updateMusicThemeDescription(''))
                                            }
                                        }
                                    }}
                                onCreateOption={(value : any) => 
                                {
                                    if(previousLineUpdate)
                                    {
                                        dispatch(ActionCreators.updateMusicThemeInPopup({ id: '-1', value: value, label: value }))
                                    }
                                    else
                                    {
                                        dispatch(ActionCreatorsForEditEpisode.updateMusicThemeParam({ id: '-1', value: value, label: value }))
                                    }
                                }}
                                isClearable
                                backspaceRemovesValue
                                name="musicTheme"
                                autoComplete="off"
                                placeholder="Choose a music theme...."
                                required>
                            </CreatableSelect>
                            <CInput
                                className="form-control desc-input"
                                name="assignedTo"
                                type="text"
                                disabled={actionObject.musicId == '' ? true : actionObject.musicId == null ? true : actionObject.musicId === '-1' ? false : true}
                                value={musicThemeDescription || ''}
                                onChange={(e : any) => { if(updateCloudBool !== 'updating') setMusicThemeDescription(e.target.value) } }
                                autoComplete="off"
                                placeholder="Description"
                                required>
                            </CInput>
                            <CLabel className="ac-sec-lbl" htmlFor="assignedTo">Play short sound</CLabel>
                            <CreatableSelect 
                                createOptionPosition="first"
                                options={shortSounds}
                                styles={{...customStyles, ...selectStylesForField}}
                                menuPortalTarget={document.querySelector('body')}
                                value={{id: actionObject.shortSoundId, value: actionObject.shortSoundName, label: actionObject.shortSoundName}}
                                onChange={(value : any) => 
                                    { 
                                        if(value !== null)
                                        {
                                            if(previousLineUpdate)
                                            {
                                                dispatch(ActionCreators.updateShortSoundInPopup(value))
                                                dispatch(ActionCreators.updateShortSoundDescriptionInPopup(value.description))
                                            }
                                            else
                                            {
                                                dispatch(ActionCreatorsForEditEpisode.updateShortSoundParam(value))
                                                dispatch(ActionCreatorsForEditEpisode.updateShortSoundDescription(value.description))
                                            }
                                        }
                                        else
                                        {
                                            if(previousLineUpdate)
                                            {
                                                dispatch(ActionCreators.updateShortSoundInPopup({id: null, value: '', label: ''}))
                                                dispatch(ActionCreators.updateShortSoundDescriptionInPopup(''))
                                            }
                                            else
                                            {
                                                dispatch(ActionCreatorsForEditEpisode.updateShortSoundParam({id: null, label: '', path: '', description: ''}))
                                                dispatch(ActionCreatorsForEditEpisode.updateShortSoundDescription(''))
                                            }
                                        }
                                    }}
                                onCreateOption={(value : any) => 
                                {
                                    if(previousLineUpdate)
                                    {
                                        dispatch(ActionCreators.updateShortSoundInPopup({ id: '-1', value: value, label: value }))
                                    }
                                    else
                                    {
                                        dispatch(ActionCreatorsForEditEpisode.updateShortSoundParam({ id: '-1', value: value, label: value }))
                                    }
                                }}
                                isClearable
                                backspaceRemovesValue
                                name="background"
                                autoComplete="off"
                                placeholder="Choose a short sound...."
                                required>
                            </CreatableSelect>

                            <Switch 
                                className="mt-2 mb-2 center" 
                                checked={actionObject.shortSoundEnabled} 
                                onChange={(v: any) => 
                                { 
                                    if(previousLineUpdate)
                                    {
                                        dispatch(ActionCreators.updateShortSoundBoolInPopup(v))
                                    }
                                    else
                                    {
                                        dispatch(ActionCreatorsForEditEpisode.updateShortSoundBool(v))
                                    }
                                } 
                            }/>
                            <CInput
                                className="form-control desc-input"
                                name="assignedTo"
                                type="text"
                                disabled={actionObject.shortSoundId == '' ? true : actionObject.shortSoundId == null ? true : actionObject.shortSoundId === '-1' ? false : true}
                                value={shortSoundDescription || ''}
                                onChange={(e : any) => { if(updateCloudBool !== 'updating') setShortSoundDescription(e.target.value) } }
                                autoComplete="off"
                                placeholder="Description"
                                required>
                            </CInput>
                        </div>
                        <div className="column-style form-group-name col-sm-4">
                            <div className="action-col2 column-style">
                                <CLabel className="action-col-title">Line format actions</CLabel>
                                <CLabel className="ac-top-lbl" htmlFor="avatar">Character Visibility&nbsp;<span className="font-weight-light text-muted" style={{fontSize: '0.8rem'}}>Disabling character visibility will disable emotions and line format for the current line.</span></CLabel>
                                <Switch 
                                    className="mt-2 mb-2 center" 
                                    checked={actionObject.avatarEnabled} 
                                    onChange={(v: any) => 
                                    { 
                                        if(previousLineUpdate)
                                        {
                                            dispatch(ActionCreators.updateAvatarBoolInPopup(v))
                                        }
                                        else
                                        {
                                            dispatch(ActionCreatorsForEditEpisode.updateAvatarBool(v))
                                        }
                                    }}/>

                                <CLabel className="ac-top-lbl mt-3" htmlFor="avatar">Change Identity</CLabel>
                                    <div className="row-style">
                                        <Switch 
                                            className="mb-2 mt-1 center" 
                                            checked={actionObject.changeIdentityOn} 
                                            onChange={(v: any) => 
                                            { 
                                                if(previousLineUpdate)
                                                {
                                                    if(!v)
                                                    {
                                                        dispatch(ActionCreators.updateCharacterIdentityNameInPopup(null));
                                                    }
                                                    dispatch(ActionCreators.updateCharacterIdentityEnabledInPopup(v));
                                                }
                                                else
                                                {
                                                    if(!v)
                                                    {
                                                        dispatch(ActionCreatorsForEditEpisode.updateCharacterIdentityName(null));
                                                    }
                                                    dispatch(ActionCreatorsForEditEpisode.updateCharacterIdentityEnabled(v));
                                                }
                                            }}
                                        />
                                        <CInput
                                            className="form-control ml-2"
                                            name="assignedTo"
                                            type="text"
                                            disabled={!actionObject.changeIdentityOn}
                                            value={actionObject.changeIdentityName ?? ''}
                                            onChange={(e : any) => { 
                                                if(previousLineUpdate)
                                                {
                                                    dispatch(ActionCreators.updateCharacterIdentityNameInPopup(e.target?.value));
                                                }
                                                else
                                                {
                                                    dispatch(ActionCreatorsForEditEpisode.updateCharacterIdentityName(e.target?.value));
                                                }
                                             } }
                                            autoComplete="off"
                                            placeholder="Display Name"
                                            maxLength={50}
                                            required>
                                        </CInput>
                                    </div>

                                <CLabel className="ac-sec-lbl" htmlFor="call">Call</CLabel>

                                <CInputGroup id="call" className="column-style">
                                    <div className="call-radio-group">
                                        <CInput type="radio" className="radio-btn-call" value="none" id="none" checked={checkedOption === "none"} onChange={(e: any) => 
                                        { 
                                            if(previousLineUpdate)
                                            {
                                                dispatch(ActionCreators.updateCallerNameInPopup({id: null, value: null, label: null}));
                                            }
                                            else
                                            {
                                                dispatch(ActionCreatorsForEditEpisode.updateCallerName({id: null, value: null, label: null}));
                                            }
                                            handleCallOptionChange(e.target.value) 
                                        }}></CInput>
                                        <CLabel type="radio" className="radio-lbl-call" htmlFor="none">None</CLabel>
                                    </div>
                                    <div className="call-radio-group">
                                        <CInput type="radio" className="radio-btn-call" value="phone" id="phone" checked={checkedOption === "phone"} onChange={(e : any) => { handleCallOptionChange(e.target.value) }}></CInput>
                                        <CLabel htmlFor="phone" className="radio-lbl-call">Phone (same as None for V line)</CLabel>
                                    </div>
                                    <div className="call-radio-group">
                                        <CInput type="radio" className="radio-btn-call" value="sms" id="sms" checked={checkedOption === "sms"} onChange={(e : any) => { handleCallOptionChange(e.target.value) }}></CInput>
                                        <CLabel htmlFor="sms" className="radio-lbl-call">SMS (same as None for T and V line)</CLabel>
                                    </div>
                                </CInputGroup>

                                <CLabel className="ac-sec-lbl" htmlFor="text1">{checkedOption !== 'sms' ? 'Caller Name' : 'Phone Owner'}</CLabel>
                                <Select 
                                    options={sortCharacterArray(charactersChangeOufit)}     
                                    styles={{...customStyles, ...selectStylesForField}}
                                    menuPortalTarget={document.querySelector('body')}
                                    value={{id: actionObject.callerCharacterId, value: actionObject.callerCharacterId, label: actionObject.callerCharacterName}}
                                    onChange={(value : any) => 
                                    { 
                                        if(value)
                                        {
                                            if(updateCloudBool !== 'updating')
                                            {
                                                if(previousLineUpdate)
                                                {
                                                    dispatch(ActionCreators.updateCallerNameInPopup(value));
                                                }
                                                else
                                                {
                                                    dispatch(ActionCreatorsForEditEpisode.updateCallerName(value));
                                                }
                                            }
                                        }
                                        else
                                        {
                                            if(previousLineUpdate)
                                            {
                                                dispatch(ActionCreators.updateCallerNameInPopup({id: null, value: null, label: null}));
                                            }
                                            else
                                            {
                                                dispatch(ActionCreatorsForEditEpisode.updateCallerName({id: null, value: null, label: null}));
                                            }
                                        }
                                    }}
                                    isClearable
                                    isDisabled={checkedOption === "none" ? true : false}
                                    backspaceRemovesValue
                                    name="caller"
                                    autoComplete="off"
                                    placeholder="Caller name"
                                    required>
                                </Select>
                            </div>
                        </div>
                        <div className="column-style form-group-name col-sm-4 action-col3">
                            <CLabel className="action-col-title">Change outfit actions</CLabel>
                            <CLabel className="ac-top-lbl" htmlFor="character">Character</CLabel>
                            <Select 
                                options={sortCharacterArray(charactersChangeOufit).filter((ch : any) => ((ch.changeOutfitDisabled === false) && !actionObject.charactersOutfit?.find((el : any) => (el.characterId == ch.id))))}     
                                styles={{...customStyles, ...selectStylesForField}}
                                menuPortalTarget={document.querySelector('body')}
                                value={{id: characterParams.characterId, value: characterParams.characterName, label: characterParams.characterName}}
                                onChange={(value : any) => 
                                { 
                                    if(value !== null)
                                    {
                                        setCharacterParams({...characterParams, characterId: value.id, characterName: value.label})
                                        setIsMeCharacter(value.isMeCharacter ? true : false)
                                    }
                                    else
                                    {
                                        setCharacterParams({
                                            characterId: '',
                                            characterName: '',
                                            clothesId: '',
                                            clothesName: '',
                                            clothesDescription: '',
                                            hairstyleId: '',
                                            hairstyleName: '',
                                            hairstyleDescription: '',
                                            accessoryEnabled: false,
                                            accessoryId: '',
                                            accessoryName: '',
                                            accessoryDescription: '', 
                                            accessoryType: 0,
                                            changeOutfitHairstyleType: 0,
                                            changeOutfitClothesType: 0
                                        })
                                        setIsMeCharacter(false)
                                    }
                                }}
                                isClearable
                                backspaceRemovesValue
                                name="character"
                                autoComplete="off"
                                placeholder="Choose a character...."
                                required>
                            </Select>
                            { characterParams.characterName == "Me" ? 
                            <div className="mt-3">
                                <CLabel className="ac-top-lbl" htmlFor="character">Clothes Outfit type</CLabel>
                                <Select 
                                    options={changeOutfitTypes}     
                                    styles={{...customStyles, ...selectStylesForField}}
                                    menuPortalTarget={document.querySelector('body')}
                                    value={changeOutfitTypes.find((element : any) => element.value == characterParams.changeOutfitClothesType) ?? ''}
                                    onChange={(value : any) => 
                                    { 
                                        if(value !== null)
                                        {
                                            if(value.value == 1 || value.value == 2)
                                            {
                                                setCharacterParams({...characterParams, 
                                                    changeOutfitClothesType: value.value,
                                                    clothesId: '',
                                                    clothesName: '',
                                                    clothesDescription: ''
                                                })
                                            }
                                            else
                                            {
                                                setCharacterParams({...characterParams,
                                                    changeOutfitClothesType: value.value
                                                })
                                            }
                                        }
                                        else
                                        {
                                            setCharacterParams({...characterParams, 
                                                changeOutfitClothesType: 0,
                                                clothesId: '',
                                                clothesName: '',
                                                clothesDescription: ''
                                            })
                                        }

                                    }}
                                    isClearable
                                    backspaceRemovesValue
                                    name="character"
                                    autoComplete="off"
                                    placeholder="Choose clothes oufit type..."
                                    required>
                                </Select>
                            </div> : '' }
                            <CLabel className="ac-sec-lbl" htmlFor="clothes">Clothes</CLabel>
                            <CreatableSelect 
                                createOptionPosition="first"
                                options={clothes}
                                styles={{...customStyles, ...selectStylesForField}}
                                menuPortalTarget={document.querySelector('body')}
                                isDisabled={
                                    characterParams.characterId == '' || 
                                    characterParams.changeOutfitClothesType == 1 || 
                                    characterParams.changeOutfitClothesType == 2 || 
                                    (isMeCharacter && gameType == GameType.Lunescape) ? true : false}
                                value={{id: characterParams.clothesId, value: characterParams.clothesName, label: characterParams.clothesName}}
                                onChange={(value : any) => 
                                    { 
                                        if(value !== null)
                                        {
                                            if(value.__isNew__ !== undefined)
                                            {
                                                setCharacterParams({...characterParams, clothesId: '-1', clothesName: value.label, clothesDescription: value.description })
                                            }
                                            else
                                            {
                                                setCharacterParams({...characterParams, clothesId: value.id, clothesName: value.label, clothesDescription: value.description })
                                            }
                                        }
                                        else
                                        {
                                            setCharacterParams({...characterParams, clothesId: '', clothesName: '', clothesDescription: ''})
                                        }
                                    }}
                                onCreateOption={(value : any) => 
                                {
                                    setCharacterParams({...characterParams, clothesId: '-1', clothesName: value})
                                    dispatch(ActionCreatorsForEditEpisode.addClothes({ id: '-1', value: value, label: value }))
                                }}
                                isClearable
                                backspaceRemovesValue
                                name="background"
                                autoComplete="off"
                                placeholder="Choose a clothes...."
                                required>
                            </CreatableSelect>
                            <CInput
                                className="form-control desc-input"
                                name="clothesdesc"
                                type="text"
                                disabled={
                                    characterParams.characterId == ''  || 
                                    characterParams.changeOutfitClothesType == 1 || 
                                    characterParams.changeOutfitClothesType == 2 || 
                                    (isMeCharacter && gameType == GameType.Lunescape) ? true : 
                                    characterParams.clothesId == null ? false : 
                                    characterParams.clothesId === '-1' ? false : true}
                                value={characterParams.clothesDescription}
                                onChange={(e : any) => { if(updateCloudBool !== 'updating') setCharacterParams({...characterParams, clothesDescription: e.target.value})} }
                                autoComplete="off"
                                placeholder="Description"
                                required>
                            </CInput>
                            { characterParams.characterName == "Me" ? 
                            <div className="mt-3">
                                <CLabel className="ac-top-lbl" htmlFor="character">Hairstyle Outfit type</CLabel>
                                <Select 
                                    options={changeOutfitTypes}     
                                    styles={{...customStyles, ...selectStylesForField}}
                                    menuPortalTarget={document.querySelector('body')}
                                    value={changeOutfitTypes.find((element : any) => element.value == characterParams.changeOutfitHairstyleType) ?? ''}
                                    onChange={(value : any) => 
                                    { 
                                        if(value !== null)
                                        {
                                            if(value.value == 1 || value.value == 2)
                                            {
                                                setCharacterParams({...characterParams, 
                                                    changeOutfitHairstyleType: value.value,
                                                    hairstyleId: '',
                                                    hairstyleName: '',
                                                    hairstyleDescription: ''
                                                })
                                            }
                                            else
                                            {
                                                setCharacterParams({...characterParams,
                                                    changeOutfitHairstyleType: value.value
                                                })
                                            }
                                        }
                                        else
                                        {
                                            setCharacterParams({...characterParams, 
                                                changeOutfitHairstyleType: 0,
                                                hairstyleId: '',
                                                hairstyleName: '',
                                                hairstyleDescription: ''
                                            })
                                        }

                                    }}
                                    isClearable
                                    backspaceRemovesValue
                                    name="character"
                                    autoComplete="off"
                                    placeholder="Choose hairstyle oufit type..."
                                    required>
                                </Select>
                            </div> : '' }
                            <CLabel className="ac-sec-lbl" htmlFor="hairstyle">Hairstyle</CLabel>
                            <CreatableSelect 
                                createOptionPosition="first"
                                options={hairstyles}
                                styles={{...customStyles, ...selectStylesForField}}
                                menuPortalTarget={document.querySelector('body')}
                                isDisabled={
                                    characterParams.characterId == '' || 
                                    characterParams.changeOutfitHairstyleType == 1 || 
                                    characterParams.changeOutfitHairstyleType == 2 || 
                                    (isMeCharacter && gameType == GameType.Lunescape) ? true : false}
                                value={{id: characterParams.hairstyleId, value: characterParams.hairstyleName, label: characterParams.hairstyleName}}
                                onChange={(value : any) => 
                                    { 
                                        if(value !== null)
                                        {
                                            if(value.__isNew__ !== undefined)
                                            {
                                                setCharacterParams({...characterParams, hairstyleId: '-1', hairstyleName: value.label, hairstyleDescription: value.description })
                                            }
                                            else
                                            {
                                                setCharacterParams({...characterParams, hairstyleId: value.id, hairstyleName: value.label, hairstyleDescription: value.description })
                                            }
                                        }
                                        else
                                        {
                                            setCharacterParams({...characterParams, hairstyleId: '', hairstyleName: '', hairstyleDescription: ''})
                                        }
                                    }}
                                
                                onCreateOption={(value : any) => 
                                {
                                    setCharacterParams({...characterParams, hairstyleId: '-1', hairstyleName: value})
                                    dispatch(ActionCreatorsForEditEpisode.addHairstyles({ id: '-1', value: value, label: value }))
                                }}
                                isClearable
                                backspaceRemovesValue
                                name="background"
                                autoComplete="off"
                                placeholder="Choose a hairstyle...."
                                required>
                            </CreatableSelect>
                            <CInput
                                className="form-control desc-input"
                                name="hairstyledesc"
                                type="text"
                                disabled={
                                    characterParams.characterId == '' || 
                                    characterParams.changeOutfitHairstyleType == 1 || 
                                    characterParams.changeOutfitHairstyleType == 2 ||
                                    (isMeCharacter && gameType == GameType.Lunescape) ? true : 
                                    characterParams.hairstyleId == null ? false : 
                                    characterParams.hairstyleId === '-1' ? false : true}
                                value={characterParams.hairstyleDescription}
                                onChange={(e : any) => { if(updateCloudBool !== 'updating') setCharacterParams({...characterParams, hairstyleDescription: e.target.value})} }
                                autoComplete="off"
                                placeholder="Description"
                                required>
                            </CInput>
                            <CLabel className="ac-sec-lbl" htmlFor="accessory">Accessory</CLabel>

                            <Switch 
                                className="mt-2 mb-2 center" 
                                disabled={
                                    characterParams.characterId === ''
                                    ? true : false}
                                checked={characterParams.accessoryEnabled} 
                                onChange={(v: any) => setCharacterParams({...characterParams, accessoryEnabled: v})} />

                            <CreatableSelect 
                                createOptionPosition="first"
                                options={accessories}
                                styles={{...customStyles, ...selectStylesForField}}
                                menuPortalTarget={document.querySelector('body')}
                                isDisabled={
                                    characterParams.characterId == ''
                                    ? true : false}
                                value={{id: characterParams.accessoryId, value: characterParams.accessoryName, label: characterParams.accessoryName}}
                                onChange={(value : any) => 
                                    { 
                                        if(value !== null)
                                        {
                                            if(value.__isNew__ !== undefined)
                                            {
                                                setCharacterParams({...characterParams, 
                                                    accessoryId: '-1', 
                                                    accessoryName: value.label, 
                                                    accessoryDescription: value.description,
                                                    accessoryType: 0
                                                })
                                            }
                                            else
                                            {
                                                setCharacterParams({...characterParams,
                                                    accessoryId: value.id, 
                                                    accessoryName: value.label, 
                                                    accessoryDescription: value.description,
                                                    accessoryType: value.accessoryType
                                                })
                                            }
                                        }
                                        else
                                        {
                                            setCharacterParams({...characterParams, accessoryId: '', accessoryName: '', accessoryDescription: '', accessoryType: 0})
                                        }
                                    }}
                                
                                onCreateOption={(value : any) => 
                                {
                                    setCharacterParams({...characterParams, accessoryId: '-1', accessoryName: value})
                                    dispatch(ActionCreatorsForEditEpisode.addAccessories({ id: '-1', value: value, label: value }))
                                }}
                                isClearable
                                backspaceRemovesValue
                                name="background"
                                autoComplete="off"
                                placeholder="Choose a accessory...."
                                required>
                            </CreatableSelect>
                            
                            <CInput
                                className="form-control desc-input"
                                name="accessorydesc"
                                type="text"
                                disabled={
                                    characterParams.characterId == ''
                                    ? true : 
                                    characterParams.accessoryId == null ? false : characterParams.accessoryId === '-1' ? false : true}
                                value={characterParams.accessoryDescription ?? ''}
                                onChange={(e : any) => { if(updateCloudBool !== 'updating') setCharacterParams({...characterParams, accessoryDescription: e.target.value}) } }
                                autoComplete="off"
                                placeholder="Description"
                                required>
                            </CInput>
                            
                            <Select 
                                options={accessoryTypes}
                                className="mt-1"
                                styles={{...customStyles, ...selectStylesForField}}
                                menuPortalTarget={document.querySelector('body')}
                                isDisabled={
                                    characterParams.characterId == ''
                                    ? true : characterParams.accessoryId == null ? false : characterParams.accessoryId === '-1' ? false : true}
                                value={accessoryTypes.find((element : any) => element.id == characterParams.accessoryType) ?? ''}
                                onChange={(value : any) => 
                                    { 
                                        if(value !== null)
                                        {
                                            setCharacterParams({...characterParams, accessoryType: value.id})
                                        }
                                        else
                                        {
                                            setCharacterParams({...characterParams, accessoryType: 0})
                                        }
                                    }
                                }
                                isClearable
                                backspaceRemovesValue
                                name="background"
                                autoComplete="off"
                                placeholder="Choose a accessory...."
                                required>
                            </Select>
                                <CButton 
                                    onClick={() => 
                                    { 
                                        if(!isDressCharacterValid(characterParams, gameType, isMeCharacter, alert))
                                        {
                                            return;
                                        }
                                        if(isUpdate)
                                        {
                                            if(previousLineUpdate)
                                            {
                                                dispatch(ActionCreators.updateSpecificCharacterOufitInPopup(characterParams))
                                            }
                                            else
                                            {
                                                dispatch(ActionCreatorsForEditEpisode.updateSpecificCharacterOufit(characterParams))
                                            }
                                            setIsUpdate(false);
                                        }
                                        else
                                        {
                                            if(actionObject.charactersOutfit?.some((value: any) => value.characterId === characterParams.characterId))
                                            {
                                                alert.error('Character you are trying to add has already been added.')
                                                return;
                                            }
                                            
                                            if(previousLineUpdate)
                                            {
                                                dispatch(ActionCreators.updateCharacterOutfitInPopup(characterParams))
                                            }
                                            else
                                            {
                                                dispatch(ActionCreatorsForEditEpisode.updateCharactersOutfit(characterParams))
                                            }
                                        }
                                        setCharacterParams({
                                            characterId: '',
                                            characterName: '',
                                            clothesId: '',
                                            clothesName: '',
                                            clothesDescription: '',
                                            hairstyleId: '',
                                            hairstyleName: '',
                                            hairstyleDescription: '',
                                            accessoryEnabled: false,
                                            accessoryId: '',
                                            accessoryName: '',
                                            accessoryDescription: '',
                                            accessoryType: 0,
                                            changeOutfitHairstyleType: 0,
                                            changeOutfitClothesType: 0
                                        })   
                                    }} 
                                    className="button-edit center mt-4">{isUpdate ? 'Update character style' : 'Dress character'}</CButton>
                                    {actionObject.charactersOutfit?.length === 0
                                        ? '' 
                                        : 
                                        <div className="mt-2 row-style" style={{flexWrap: 'wrap'}}>Dressed characters: &nbsp;{actionObject.charactersOutfit?.map((value: any, index: number) => 
                                            <div key={index} className="row-style">
                                                <p  className="cursor" onClick={() => { setIsUpdate(true); setCharacterParams(value) }}>{value.characterName}&nbsp;</p>
                                                <ImCross className="ml-1 mr-5" 
                                                    onClick={() => 
                                                    { 
                                                        if(previousLineUpdate)
                                                        {
                                                            dispatch(ActionCreators.removeCharacterOutfitInPopup(value.characterId));
                                                        }
                                                        else
                                                        {
                                                            dispatch(ActionCreatorsForEditEpisode.removeCharactersOutfit(value.characterId));
                                                        }
                                                        if(isUpdate)
                                                        {
                                                            setCharacterParams({
                                                                characterId: '',
                                                                characterName: '',
                                                                clothesId: '',
                                                                clothesName: '',
                                                                clothesDescription: '',
                                                                hairstyleId: '',
                                                                hairstyleName: '',
                                                                hairstyleDescription: '',
                                                                accessoryEnabled: false,
                                                                accessoryId: '',
                                                                accessoryName: '',
                                                                accessoryDescription: '',
                                                                accessoryType: 0,
                                                                changeOutfitHairstyleType: 0,
                                                                changeOutfitClothesType: 0
                                                            })  
                                                            setIsUpdate(false);
                                                        }
                                                    }}
                                                    color="red"
                                                    style={{marginTop: '3px'}}
                                                />
                                            </div>)}
                                        </div>}
                    </div>
                </div>
                
                <EpisodeActionsEffect 
                    actionObject={actionObject}
                    backgroundDescription={backgroundDescription} 
                    musicThemeDescription={musicThemeDescription} 
                    shortSoundDescription={shortSoundDescription}
                    showItemDescription={null}
                    notificationCharmingPoint={null}
                    premiumChoiceText={null}
                    premiumChoiceDescription={null}
                    setBackgroundDescription={setBackgroundDescription}
                    setMusicThemeDescription={setMusicThemeDescription}
                    setShortSoundDescription={setShortSoundDescription}
                    setShowItemDescription={null}
                    setNotificationCharmingPoint={null}
                    setPremiumChoiceText={null}
                    setPremiumChoiceDescription={null}
                    previousLineUpdate={previousLineUpdate}
                />
            </div>
</div></fieldset></div>
            </CModalBody>
        </CModal>
    )}
}

export default EditEpisodeActionsPopup;