import React, { useState } from 'react';
import { CButton, CInput, CLabel } from '@coreui/react';
import { useEffect } from 'react';
import { GetEpisodesForHeader } from '../../../services/StoriesServices';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorsForArtCatalog } from '../../../store/ArtCatalogReducer';
import { GetAssets, GetEpisodesForArtCatalog, GetSelectValuesForAssets } from '../../../services/ArtCatalog';
import { ApplyFiltersSessionLogger } from '../../../modules/firebaseDetailedLoggers';

export const Backgrounds = ({setPage, data, activeKey} : any) =>
{
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState('');

    const { stories, episodes } = useSelector((state: any) => state.artCatalogReducer);

    const interiors = useSelector((state: any) => state.artCatalogReducer.interiors);
    const exteriors = useSelector((state: any) => state.artCatalogReducer.exteriors);
    
    const [chosenObject, setChosenObject] = useState({ chosenStory: null as any, chosenEpisode: null as any, chosenInterior: null as any, chosenExterior: null as any })

    useEffect(() => 
    {
        GetSelectValuesForAssets('api/ArtCatalog/GetInterior').then((response) => 
        { 
            dispatch(ActionCreatorsForArtCatalog.setInteriors(response)); 
        })
        GetSelectValuesForAssets('api/ArtCatalog/GetExterior').then((response) => 
        { 
            dispatch(ActionCreatorsForArtCatalog.setExteriors(response));
        })
    }, [])

    useEffect(() => 
    {
        const timeOutId = setTimeout(() => 
        {
            let filteredLines = data?.map((l: any, i: number) => {
                return {...l, lineIndex: i}
            }).filter((l: any) => 
            {
                if(searchText && l.name?.toLowerCase().includes(searchText.toLowerCase()))
                {
                    return l;
                }
            })
            if(searchText) 
            {
                dispatch(ActionCreatorsForArtCatalog.setBackgrounds(filteredLines)) 
                setPage(0);
            }
            else
            {
                dispatch(ActionCreatorsForArtCatalog.setLoadingImages(true));
                GetAssets('api/ArtCatalog/GetAssets_Backgrounds', { storyId: null, episodeId: null, interio: null, exterio: null, searchValue: null }).then((response) => 
                { 
                    dispatch(ActionCreatorsForArtCatalog.setBackgrounds(response)) 
                })
            }
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [searchText])
    
    useEffect(() => 
    { 
        if(data.length === 0)
            setChosenObject({ chosenStory: null, chosenEpisode: null, chosenInterior: null, chosenExterior: null }) 
    }, [activeKey])

    return(<div className=" mt-3 form-filter-top-art-catalog">
        <div className="row-style art-cat-filters-row" style={{width: '1500px'}}>
            <div className="form-group-name col-sm-2">
                <CLabel htmlFor="story">Find</CLabel>
                <CInput
                    className="form-control"
                    name="episode"
                    placeholder="Search for a background"
                    value={searchText}
                    onChange={(e : any) => setSearchText(e.target.value) }
                    type="text"
                    autoComplete="off"
                    required>
                </CInput>
            </div>
            <div className="form-group-name col-sm-2 ">
                <CLabel htmlFor="story">Story</CLabel>
                <Select
                    options={stories}
                    value={chosenObject.chosenStory}
                    isClearable
                    onChange={(value : any) => 
                    { 
                        if(value != null)
                        {
                            GetEpisodesForArtCatalog(value.id, value.value).then((response) => 
                            { 
                                dispatch(ActionCreatorsForArtCatalog.setEpisodes(response));
                                setChosenObject({...chosenObject, chosenStory: value, chosenEpisode: null });
                            });
                        }
                        else
                        {
                            setChosenObject({...chosenObject, chosenStory: null, chosenEpisode: null });
                        }
                    }}
                    as="select"
                    autoComplete="off"
                    placeholder="Choose a story..."
                    required >
                </Select>
            </div>
            <div className="form-group-name col-sm-2 ml-1">
                <CLabel htmlFor="episode">Episode</CLabel>
                <Select
                    options={episodes}
                    value={chosenObject.chosenEpisode}
                    isClearable
                    onChange={(value : any) => 
                    { 
                        setChosenObject({...chosenObject, chosenEpisode: value });
                    }}
                    name="episode"
                    as="select"
                    autoComplete="off"
                    placeholder="Enter episode..."
                    required>
                </Select>
            </div>
            <div className="form-group-name col-sm-2 ml-1">
                <CLabel htmlFor="episode">Interior</CLabel>
                <Select
                    options={interiors}
                    value={chosenObject.chosenInterior}
                    isClearable
                    onChange={(value : any) => 
                    { 
                        setChosenObject({...chosenObject, chosenInterior: value, chosenExterior: null });
                    }}
                    name="episode"
                    as="select"
                    autoComplete="off"
                    required>
                </Select>
            </div>
            <div className="form-group-name col-sm-2 ml-1">
                <CLabel htmlFor="episode">Exterior</CLabel>
                <Select
                    options={exteriors}
                    value={chosenObject.chosenExterior}
                    isClearable
                    onChange={(value : any) => 
                    { 
                        setChosenObject({...chosenObject, chosenExterior: value, chosenInterior: null });
                    }}
                    name="episode"
                    as="select"
                    autoComplete="off"
                    required>
                </Select>
            </div>
            <CButton className="btn-primary-sm wide btn-fin-review-btn float-right" onClick={() => 
            {
                dispatch(ActionCreatorsForArtCatalog.setLoadingImages(true));

                const applyFiltersSession = new ApplyFiltersSessionLogger();

                applyFiltersSession.asset_name = 'Backgrounds';
                applyFiltersSession.query_string = searchText ?? '';
                applyFiltersSession.story_name = chosenObject?.chosenStory?.label ?? '';
                applyFiltersSession.episode_name = chosenObject?.chosenEpisode?.label ?? '';
                applyFiltersSession.interior = chosenObject?.chosenInterior?.label ?? '';
                applyFiltersSession.exterior = chosenObject?.chosenExterior?.label ?? '';
                applyFiltersSession.timestamp = new Date().toUTCString();
                applyFiltersSession.session_id = localStorage.getItem('sessionId') ?? '';
                applyFiltersSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                applyFiltersSession.user_id = localStorage.getItem('uid') ?? '';
                
                applyFiltersSession.logEvent();
                
                GetAssets('api/ArtCatalog/GetAssets_Backgrounds', 
                { 
                    storyId: chosenObject.chosenStory != null ? chosenObject.chosenStory.value : null, 
                    episodeId: chosenObject.chosenEpisode != null ? chosenObject.chosenEpisode.value : null, 
                    interio: chosenObject.chosenInterior != null ? chosenObject.chosenInterior.value : null, 
                    exterio: chosenObject.chosenExterior != null ? chosenObject.chosenExterior.value : null,
                    searchValue: searchText ?? null 
                })
                .then((response) => 
                    { 
                        dispatch(ActionCreatorsForArtCatalog.setBackgrounds(response)) 
                    })
            }}>Apply filters</CButton>
        </div>
    </div>
)
}

export default Backgrounds;