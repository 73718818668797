import React, { useRef, useState } from 'react';
import { CFormGroup, CInput, CLabel, CNav, CNavItem, CNavLink, CSelect, CTabContent, CTabPane, CTextarea, CButton } from '@coreui/react';
import { FaUserSecret } from 'react-icons/fa';
import { GiHairStrands, GiPoloShirt, GiHealthPotion } from 'react-icons/gi';
import { ImImage } from 'react-icons/im';
import { IoMdCloudyNight, IoMdPhotos } from 'react-icons/io';
import { IoMusicalNotesSharp } from 'react-icons/io5';
import ReactLoading from 'react-loading';

//project imports
import SetupStoryTable from '../Common/SetupStoryTable';
import { SetupStoryInfo } from '../Models/SetupStoryInfo';
import { charactersColumns, hairstylesColumns, clothesColumns, accessoriesColumns, backgroundsColumns, cinematicsColumns, audioColumns, visualsColumns, itemsColumns } from '../Common/SetupStoryColumns';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GetSetupStoryTableData } from '../../services/StoriesServices';
import { useDispatch, useSelector } from 'react-redux';
import { BiSitemap } from 'react-icons/bi';
import { GetConcreteStory } from '../../services/EpisodeServices';
import { useAlert } from 'react-alert';
import { GameType } from '../Common/Enums/GameType';
import { SetUpSessionLogger } from '../../modules/firebaseAnalyticsLoggers';
import { TabChangeSessionLogger } from '../../modules/firebaseDetailedLoggers';
import SetupStoryExport from './SetupStoryExport';

interface StoryState 
{
    title: string,
    author: string,
    releaseDate: string,       
    description: string,
    translationNotes: string,
    marketingNotes: string,
    created: string,
    status: string,
    inReview: string,
    readyForTranslations: string
}
  
export const SetupStory = () =>
{
    const dispatch = useDispatch();
    const alert = useAlert();

    const { storyId, storyTransId } : any = useParams();

    const [tableLoading, setTableLoading] = useState(false);
    const loading = useSelector((state : any) => state.storiesReducer.setupStoryExportLoading)
    const story = useSelector((state: any) => state.storiesReducer.storyDetails);
    
    //tabs state
    const [activeKey, setActiveKey] = useState(1);

    const [data, setData] = useState({ 
        characters: [],
        hairstyles: [],
        clothes: [],
        accessories: [],
        backgrounds: [],
        cinematics: [],
        audio: [],
        visuals: [],
        items: []
    })

    useEffect(() =>
    {
        document.title = 'Story Maker - Setup Story'
        setActiveKey(1);
        setTableLoading(true);
        GetSetupStoryTableData(`api/SetupStory/GetCharacters?storyId=${storyId}&storyTranslationId=${storyTransId}`).then((response) => { setData({...data, characters: response}); setTableLoading(false); });
    }, [story])

    return(
    <div className=" mt-3 column-style"> 
        { loading ? <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1000, position: 'fixed', top: '0%', left: '0%', width: '100%', height: '2000px', backgroundColor: 'rgb(115,115,115,0.5)'}}>
                <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1001, position: 'absolute', top: '20%', left: '45%'}}>
                    <ReactLoading height="100px" width="100px" className="mt-3 mb-5" type='spin' color='#0080FF'/>
                </div>
            </div> 
        : <div/> }
        <SetupStoryInfo storyId={storyId} storyTransId={storyTransId}/> 

        <CNav variant="tabs" className="table-width dashboard setup-story-nav-links-tabs">
            <CNavItem>
                    <CNavLink className="nav-link-art-catalog" active={activeKey === 1} 
                    onClick={() => 
                    { 
                        const tabChangeLogger = new TabChangeSessionLogger();
                        
                        tabChangeLogger.asset_name = 'Characters'
                        tabChangeLogger.timestamp = new Date().toUTCString();
                        tabChangeLogger.session_id = localStorage.getItem('sessionId') ?? '';
                        tabChangeLogger.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                        tabChangeLogger.user_id = localStorage.getItem('uid') ?? '';

                        tabChangeLogger.logEvent();

                        setTableLoading(true); 
                        GetSetupStoryTableData(`api/SetupStory/GetCharacters?storyId=${storyId}&storyTranslationId=${storyTransId}`).then((response) => { setData({...data, characters: response}); setTableLoading(false); }); setActiveKey(1);
                    }}>Characters</CNavLink>
            </CNavItem>
            <CNavItem>
                    <CNavLink className="nav-link-art-catalog" active={activeKey === 2} onClick={() => 
                    { 
                        const tabChangeLogger = new TabChangeSessionLogger();
                        
                        tabChangeLogger.asset_name = 'Hairstyles'
                        tabChangeLogger.timestamp = new Date().toUTCString();
                        tabChangeLogger.session_id = localStorage.getItem('sessionId') ?? '';
                        tabChangeLogger.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                        tabChangeLogger.user_id = localStorage.getItem('uid') ?? '';

                        tabChangeLogger.logEvent();

                        setTableLoading(true); 
                        GetSetupStoryTableData(`api/SetupStory/GetHairstyles?storyId=${storyId}&storyTranslationId=${storyTransId}`).then((response) => { setData({...data, hairstyles: response}); setTableLoading(false); }); setActiveKey(2); 
                    }}>Hairstyles</CNavLink>
            </CNavItem>
            <CNavItem>
                    <CNavLink className="nav-link-art-catalog" active={activeKey === 3} onClick={() => 
                    {
                        const tabChangeLogger = new TabChangeSessionLogger();
                        
                        tabChangeLogger.asset_name = 'Clothes'
                        tabChangeLogger.timestamp = new Date().toUTCString();
                        tabChangeLogger.session_id = localStorage.getItem('sessionId') ?? '';
                        tabChangeLogger.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                        tabChangeLogger.user_id = localStorage.getItem('uid') ?? '';

                        tabChangeLogger.logEvent();

                        setTableLoading(true); 
                        GetSetupStoryTableData(`api/SetupStory/GetClothes?storyId=${storyId}&storyTranslationId=${storyTransId}`).then((response) => { setData({...data, clothes: response}); setTableLoading(false); }); setActiveKey(3); 
                    }}>Clothes</CNavLink>
            </CNavItem>
            <CNavItem>
                    <CNavLink className="nav-link-art-catalog" active={activeKey === 4} onClick={() => 
                    { 
                        const tabChangeLogger = new TabChangeSessionLogger();
                        
                        tabChangeLogger.asset_name = 'Accessories'
                        tabChangeLogger.timestamp = new Date().toUTCString();
                        tabChangeLogger.session_id = localStorage.getItem('sessionId') ?? '';
                        tabChangeLogger.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                        tabChangeLogger.user_id = localStorage.getItem('uid') ?? '';

                        tabChangeLogger.logEvent();

                        setTableLoading(true); 
                        GetSetupStoryTableData(`api/SetupStory/GetAccessories?storyId=${storyId}&storyTranslationId=${storyTransId}`).then((response) => { setData({...data, accessories: response}); setTableLoading(false); }); setActiveKey(4); 
                    }}>Accessories</CNavLink>
            </CNavItem>
            <CNavItem>
                    <CNavLink className="nav-link-art-catalog" active={activeKey === 5} onClick={() => 
                    { 
                        const tabChangeLogger = new TabChangeSessionLogger();
                        
                        tabChangeLogger.asset_name = 'Backgrounds'
                        tabChangeLogger.timestamp = new Date().toUTCString();
                        tabChangeLogger.session_id = localStorage.getItem('sessionId') ?? '';
                        tabChangeLogger.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                        tabChangeLogger.user_id = localStorage.getItem('uid') ?? '';

                        tabChangeLogger.logEvent();

                        setTableLoading(true); 
                        GetSetupStoryTableData(`api/SetupStory/GetBackgrounds?storyId=${storyId}&storyTranslationId=${storyTransId}`).then((response) => { setData({...data, backgrounds: response}); setTableLoading(false); }); setActiveKey(5); 
                    }}>Backgrounds</CNavLink>
            </CNavItem>
            <CNavItem>
                    <CNavLink className="nav-link-art-catalog" active={activeKey === 6} onClick={() => 
                    { 
                        const tabChangeLogger = new TabChangeSessionLogger();
                        
                        tabChangeLogger.asset_name = 'Cinematics'
                        tabChangeLogger.timestamp = new Date().toUTCString();
                        tabChangeLogger.session_id = localStorage.getItem('sessionId') ?? '';
                        tabChangeLogger.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                        tabChangeLogger.user_id = localStorage.getItem('uid') ?? '';

                        tabChangeLogger.logEvent();

                        setTableLoading(true); 
                        GetSetupStoryTableData(`api/SetupStory/GetAnimations?storyId=${storyId}&storyTranslationId=${storyTransId}`).then((response) => { setData({...data, cinematics: response}); setTableLoading(false); }); setActiveKey(6); 
                    }}>Cinematics</CNavLink>
            </CNavItem>
            <CNavItem>
                    <CNavLink className="nav-link-art-catalog" active={activeKey === 7} onClick={() => 
                    { 
                        const tabChangeLogger = new TabChangeSessionLogger();
                        
                        tabChangeLogger.asset_name = 'Audio'
                        tabChangeLogger.timestamp = new Date().toUTCString();
                        tabChangeLogger.session_id = localStorage.getItem('sessionId') ?? '';
                        tabChangeLogger.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                        tabChangeLogger.user_id = localStorage.getItem('uid') ?? '';

                        tabChangeLogger.logEvent();

                        setTableLoading(true); 
                        GetSetupStoryTableData(`api/SetupStory/GetMusics?storyId=${storyId}&storyTranslationId=${storyTransId}`).then((response) => { setData({...data, audio: response}); setTableLoading(false); }); setActiveKey(7); 
                    }}>Audio</CNavLink>
            </CNavItem>
            <CNavItem>
                { story?.storyGameType == GameType.LoveStory ?
                <CNavLink className="nav-link-art-catalog" active={activeKey === 8} onClick={() => 
                    { 
                        const tabChangeLogger = new TabChangeSessionLogger();
                        
                        tabChangeLogger.asset_name = 'Visual Choices'
                        tabChangeLogger.timestamp = new Date().toUTCString();
                        tabChangeLogger.session_id = localStorage.getItem('sessionId') ?? '';
                        tabChangeLogger.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                        tabChangeLogger.user_id = localStorage.getItem('uid') ?? '';

                        tabChangeLogger.logEvent();

                        setTableLoading(true); 
                        GetSetupStoryTableData(`api/SetupStory/GetVisuals?storyId=${storyId}&storyTranslationId=${storyTransId}`).then((response) => { setData({...data, visuals: response}); setTableLoading(false); }); setActiveKey(8); 
                    }}>Visual Choices</CNavLink> : '' }
            </CNavItem>
            <CNavItem>
                { story?.storyGameType == GameType.Lunescape ? <CNavLink className="nav-link-art-catalog" 
                active={activeKey === 9} onClick={() => { 
                    const tabChangeLogger = new TabChangeSessionLogger();
                    
                    tabChangeLogger.asset_name = 'Items'
                    tabChangeLogger.timestamp = new Date().toUTCString();
                    tabChangeLogger.session_id = localStorage.getItem('sessionId') ?? '';
                    tabChangeLogger.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                    tabChangeLogger.user_id = localStorage.getItem('uid') ?? '';

                    tabChangeLogger.logEvent();

                    setTableLoading(true); 
                    GetSetupStoryTableData(`api/SetupStory/GetItems?storyId=${storyId}&storyTranslationId=${storyTransId}`).then((response) => { setData({...data, items: response}); setTableLoading(false); }); setActiveKey(9); 
                }}>Items</CNavLink> : ''}
            </CNavItem>
        </CNav>
        <CTabContent className="table-width dashboard">
                <CTabPane className="tab-pan-art-catalog" active={activeKey === 1}>
                    <SetupStoryTable 
                        data={data.characters}
                        setData={setData}
                        tableLoading={tableLoading}
                        setTableLoading={setTableLoading}
                        buttonName={'Edit Character'} 
                        headerIcon={<FaUserSecret size="24" opacity="0.5" className="header-icon-modal" />} 
                        columns={charactersColumns}
                        modalBody={"character"} 
                        baseURL={`api/SetupStory/GetCharacters?storyId=${storyId}&storyTranslationId=${storyTransId}`}
                        deleteURL={`api/SetupStory/DeleteCharacter?storyId=${storyId}&storyTranslationId=${storyTransId}&characterId=`}
                        storyId={storyId}
                    />
            </CTabPane>
                <CTabPane className="tab-pan-art-catalog" active={activeKey === 2}>
                    <SetupStoryTable 
                        data={data.hairstyles}
                        setData={setData}
                        tableLoading={tableLoading}
                        setTableLoading={setTableLoading}
                        buttonName={'Edit Hairstyle'} 
                        headerIcon={<GiHairStrands size="24" opacity="0.5" className="header-icon-modal"/>} 
                        columns={hairstylesColumns} 
                        modalBody={"hairstyle"} 
                        baseURL={`api/SetupStory/GetHairstyles?storyId=${storyId}&storyTranslationId=${storyTransId}`}
                        deleteURL={`api/SetupStory/DeleteHairstyle?storyId=${storyId}&storyTranslationId=${storyTransId}&hairstyleId=`}
                        storyId={storyId}
                    />
            </CTabPane>
                <CTabPane className="tab-pan-art-catalog" active={activeKey === 3}>
                    <SetupStoryTable 
                        data={data.clothes}
                        setData={setData}
                        tableLoading={tableLoading}
                        setTableLoading={setTableLoading}
                        buttonName={'Edit Clothes'} 
                        headerIcon={<GiPoloShirt size="24" opacity="0.5" className="header-icon-modal" />}  
                        columns={clothesColumns} 
                        modalBody={"clothes"} 
                        baseURL={`api/SetupStory/GetClothes?storyId=${storyId}&storyTranslationId=${storyTransId}`}
                        deleteURL={`api/SetupStory/DeleteClothes?storyId=${storyId}&storyTranslationId=${storyTransId}&clothesId=`}
                        storyId={storyId}
                    />
            </CTabPane>
                <CTabPane className="tab-pan-art-catalog" active={activeKey === 4}>
                    <SetupStoryTable 
                        data={data.accessories}
                        setData={setData}
                        tableLoading={tableLoading}
                        setTableLoading={setTableLoading}
                        buttonName={'Edit Accessories'} 
                        headerIcon={<GiHealthPotion size="24" opacity="0.5" className="header-icon-modal" />}  
                        columns={accessoriesColumns} 
                        modalBody={"accessories"} 
                        baseURL={`api/SetupStory/GetAccessories?storyId=${storyId}&storyTranslationId=${storyTransId}`}
                        deleteURL={`api/SetupStory/DeleteAccessory?storyId=${storyId}&storyTranslationId=${storyTransId}&accessoryId=`}
                        storyId={storyId}
                    />
            </CTabPane>
                <CTabPane className="tab-pan-art-catalog" active={activeKey === 5}>
                    <SetupStoryTable 
                        data={data.backgrounds}
                        setData={setData}
                        tableLoading={tableLoading}
                        setTableLoading={setTableLoading}
                        buttonName={'Edit Background'} 
                        headerIcon={<ImImage size="23" opacity="0.5" className="header-icon-modal" />}  
                        columns={backgroundsColumns} 
                        modalBody={"backgrounds"} 
                        baseURL={`api/SetupStory/GetBackgrounds?storyId=${storyId}&storyTranslationId=${storyTransId}`}
                        deleteURL={`api/SetupStory/DeleteBackground?storyId=${storyId}&storyTranslationId=${storyTransId}&backgroundId=`}
                        storyId={storyId}
                    />
            </CTabPane>
                <CTabPane className="tab-pan-art-catalog" active={activeKey === 6}>
                    <SetupStoryTable 
                        data={data.cinematics}
                        setData={setData}
                        tableLoading={tableLoading}
                        setTableLoading={setTableLoading}
                        buttonName={'Edit Cinematic'} 
                        headerIcon={<IoMdCloudyNight size="24" opacity="0.5" className="header-icon-modal" />}  
                        columns={cinematicsColumns} 
                        modalBody={"cinematic"} 
                        baseURL={`api/SetupStory/GetAnimations?storyId=${storyId}&storyTranslationId=${storyTransId}`}
                        deleteURL={`api/SetupStory/DeleteAnimation?storyId=${storyId}&storyTranslationId=${storyTransId}&animationId=`}
                        storyId={storyId}
                    />
            </CTabPane>
                <CTabPane className="tab-pan-art-catalog" active={activeKey === 7}>
                    <SetupStoryTable 
                        data={data.audio}
                        setData={setData}
                        tableLoading={tableLoading}
                        setTableLoading={setTableLoading}
                        buttonName={'Edit Audio'} 
                        headerIcon={<IoMusicalNotesSharp size="24" opacity="0.5" className="header-icon-modal" />}  
                        columns={audioColumns} 
                        modalBody={"audio"} 
                        baseURL={`api/SetupStory/GetMusics?storyId=${storyId}&storyTranslationId=${storyTransId}`}
                        deleteURL={`api/SetupStory/DeleteMusic?storyId=${storyId}&storyTranslationId=${storyTransId}&musicId=`}
                        storyId={storyId}
                    />
            </CTabPane>
                <CTabPane className="tab-pan-art-catalog" active={activeKey === 8}>
                    <SetupStoryTable 
                        data={data.visuals}
                        setData={setData}
                        tableLoading={tableLoading}
                        setTableLoading={setTableLoading}
                        buttonName={'Edit Visual Choice'} 
                        headerIcon={<IoMdPhotos size="24" opacity="0.5" className="header-icon-modal" />}  
                        columns={visualsColumns} 
                        modalBody={"visuals"} 
                        baseURL={`api/SetupStory/GetVisuals?storyId=${storyId}&storyTranslationId=${storyTransId}`}
                        deleteURL={`api/SetupStory/DeleteVisual?storyId=${storyId}&storyTranslationId=${storyTransId}&visualId=`}
                        storyId={storyId}
                    />
            </CTabPane>
                <CTabPane className="tab-pan-art-catalog" active={activeKey === 9}>
                    <SetupStoryTable 
                        data={data.items}
                        setData={setData}
                        tableLoading={tableLoading}
                        setTableLoading={setTableLoading}
                        buttonName={'Edit Item'} 
                        headerIcon={<BiSitemap size="24" opacity="0.5" className="header-icon-modal" />}  
                        columns={itemsColumns} 
                        modalBody={"items"} 
                        baseURL={`api/SetupStory/GetItems?storyId=${storyId}&storyTranslationId=${storyTransId}`}
                        deleteURL={`api/SetupStory/DeleteItem?storyId=${storyId}&storyTranslationId=${storyTransId}&itemId=`}
                        storyId={storyId}
                    />
            </CTabPane>
        </CTabContent>
        <SetupStoryExport storyId={storyId} storyTranslationId={storyTransId}/>
    </div>)
}

export default SetupStory;