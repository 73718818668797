import React, { useEffect, useState } from 'react';
import { CInputGroup, CForm } from '@coreui/react';
import { Field, Formik } from 'formik';
import { AddNewLine } from '../../../services/EpisodeServices';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorsForEditEpisode } from '../../../store/EditEpisodeReducer';
import { LineType } from '../../../interfaces/lineType';
import { colorLineInsideAnswer, colorLineWithComment, createDefaultLineTypeOptions, editEpisodeReadOnlyCell, FormatObject, getInputLineNumber, isLineOptionChanged, showLineActions, validateActionChange } from './_Helpers';
import { useAlert } from 'react-alert'
import { ActionCreators } from '../../../store/StoriesReducer';
import CreatableSelect from 'react-select/creatable';
import '../../../styles/Lines.css'
import LineSidebar from '../LineSidebar';

const stateWithClearedLineC2 = (inputLine: boolean, selectedLineIndex: number, lines: LineType[], line: LineType, index: number, isRenderedByAction: boolean, isModalOpen: boolean, maxLineNumber: number, modalType: string) => 
{
    return (inputLine && selectedLineIndex === -1) || isModalOpen ? 
    { 
        docId: line.docId,
        storyId: line.storyId,
        storyTranslationId: line.storyTranslationId,
        episodeId: line.episodeId,
        lineNumber: getInputLineNumber(lines,line,index,maxLineNumber,isModalOpen, modalType),
        depth: isModalOpen ? line.depth : 0,
        lineType: line.lineType,
        lineFormat: line.lineFormat,
        episodeName: line.episodeName,
        previousLineDocId: line.previousLineDocId,
        episodeSerialNumber: line.episodeSerialNumber,

        characterId: line.characterId,
        characterName: line.characterName,
        characterPath: line.characterPath,
        emotion: line.emotion,
        text: isRenderedByAction ? line.text : undefined,

        animationId: undefined,
        animationName: undefined,
        payed: undefined,
        answerNumber: undefined,
        questionDocId: undefined,
    
        comment: undefined,
        commentReporterName: undefined,
        commentReporterUID: undefined,
        commentAssignedToName: undefined,
        commentAssignedToUID: undefined,
        commentResolved: undefined,

        musicId: line.musicId,
        musicName: line.musicName,
        musicDescription: line.musicDescription,

        shortSoundEnabled: line.shortSoundEnabled,
        shortSoundId: line.shortSoundId,
        shortSoundName: line.shortSoundName,
        shortSoundDescription: line.shortSoundDescription,

        itemId: line.itemId ?? undefined,
        itemName: line.itemName ?? undefined,
        itemDescription: line.itemDescription ?? undefined,
        
        avatarEnabled: line.avatarEnabled,
        
        changeIdentityOn: line.changeIdentityOn,
        changeIdentityName: line.changeIdentityName,

        callPhoneEnabled: line.callPhoneEnabled != null ? line.callPhoneEnabled : undefined,
        callSmsEnabled: line.callSmsEnabled != null ? line.callSmsEnabled : undefined,
        callerCharacterId: line.callerCharacterId,
        callerCharacterName: line.callerCharacterName,
        charmingPoint: line.charmingPoint != null ? line.charmingPoint : undefined,
        premiumChoiceText: line.premiumChoiceText != null ? line.premiumChoiceText : undefined,
        premiumChoiceDescription: line.premiumChoiceDescription != null ? line.premiumChoiceDescription : undefined,
        charactersOutfit: line.charactersOutfit,

        backgroundId: line.backgroundId,
        backgroundName: line.backgroundName,
        backgroundDescription: line.backgroundDescription,
    
        clothes1Name: line.clothes1Name,
        clothes2Name: line.clothes2Name,
        clothes3Name: line.clothes3Name,
        clothes4Name: undefined,
        clothes5Name: undefined,
        clothes6Name: undefined,
        hairstyle1Name: line.hairstyle1Name,
        hairstyle2Name: line.hairstyle2Name,
        hairstyle3Name: line.hairstyle3Name,
    
        clothes1Id: line.clothes1Id,
        clothes2Id: line.clothes2Id,
        clothes3Id: line.clothes3Id,
        clothes4Id: undefined,
        clothes5Id: undefined,
        clothes6Id: undefined,
        hairstyle1Id: line.hairstyle1Id,
        hairstyle2Id: line.hairstyle2Id,
        hairstyle3Id: line.hairstyle3Id
    } 
    : 
    line
}

export const CustomizationLine2 = ({ line, inputLine, animations, index, episodeName, serialNumber, storyId, isPreviewInModal, inPreviewIndex }: any) => 
{
    const dispatch = useDispatch();
    const alert = useAlert(); 
    const { selectedLineIndex, linesFrame, initEditEpisodeBody, lines, linesStartIndex, 
        defaultLineTypes, defaultBranchQandC, pickedColorType,
        isRenderedByAction, updateCloudBool, isOLineAdded, maxLineNumber, pickedActionColor, editEpisodePassParam } = useSelector((state: any) => state.editEpisodeReducer);
    const { modalPopupBool } = useSelector((state: any) => state.storiesReducer);

    const [clearedLine, clearLine] = useState(stateWithClearedLineC2(inputLine, selectedLineIndex, lines, line, index, isRenderedByAction, modalPopupBool.isOpen, maxLineNumber, modalPopupBool.type));
    let currentLineTypes = createDefaultLineTypeOptions(lines, index, defaultLineTypes, defaultBranchQandC, isOLineAdded, modalPopupBool.isOpen, modalPopupBool.type);

    let addLineSchema = yup.object().shape(
    {
        //lineNumber: yup.string().required("Required."),
        //lineType: yup.string().required("Required."),
        //cclothes1Name: yup.string().required("Required."),
        //cclothes2Name: yup.string().required("Required."),
        //cclothes3Name: yup.string().required("Required."),
    });

    const setupSpanElements = () => {
        let lineNumber = document.querySelector(`span[name="lineNumber${isPreviewInModal ? inPreviewIndex : index}"]`);
        if(lineNumber) lineNumber.innerHTML = line.lineNumber;
        let lineType = document.querySelector(`span[name="lineType${isPreviewInModal ? inPreviewIndex : index}"]`);
        if(lineType) { 
            let types = defaultLineTypes?.filter((x:any) => x.id == line.lineType);
            lineType.innerHTML = types.length > 0 ? types[0].label : '';
        }
        
        let hairstyle = document.querySelector(`span[name="hairstyle"]`)
        if(hairstyle) hairstyle.innerHTML = "Hair"

        let hairstyle1Name = document.querySelector(`span[name="hairstyle1Name${isPreviewInModal ? inPreviewIndex : index}"]`)
        if(hairstyle1Name) hairstyle1Name.innerHTML = line.hairstyle1Name;
        
        let hairstyle2Name = document.querySelector(`span[name="hairstyle2Name${isPreviewInModal ? inPreviewIndex : index}"]`)
        if(hairstyle2Name) hairstyle2Name.innerHTML = line.hairstyle2Name;
        
        let hairstyle3Name = document.querySelector(`span[name="hairstyle3Name${isPreviewInModal ? inPreviewIndex : index}"]`)
        if(hairstyle3Name) hairstyle3Name.innerHTML = line.hairstyle3Name;
    }

    useEffect(() => 
    { 
        if(inputLine && !initEditEpisodeBody && !isRenderedByAction) { 
            let episodeWrapper = document.querySelector('.editEpisodeWrapper');
            if(episodeWrapper) {
                //episodeWrapper.scrollIntoView({block: 'end', behavior: 'smooth'}); 
                let inputText = document.querySelector('input[name="text"]');
                (inputText as HTMLInputElement)?.focus(); 
            }
        }
        if(!inputLine) {
            setupSpanElements()
        }               
    }, [line])

    useEffect(() => 
    {
        clearLine(stateWithClearedLineC2(inputLine, selectedLineIndex, lines, line, index, isRenderedByAction, modalPopupBool.isOpen, maxLineNumber, modalPopupBool.type))
    }, [line,maxLineNumber])
    
    return (
        <Formik validateOnChange={true} validateOnBlur={false} enableReinitialize initialValues={{ lineType: line.lineType, hairstyle: 'Hair' }}
            onSubmit={(values : LineType) => 
            {
                if(!inputLine && modalPopupBool.index != index)
                {
                    let lineInPopup = {...line};
                    dispatch(ActionCreators.setModalPopupBool({  type: 'normal', isOpen: true, isFirstTimeOpen: true, index: index, currentLine: lineInPopup, clickedLine: lineInPopup }))
                }
                else if(line.changeIdentityOn && !line.changeIdentityName)
                {
                    alert.info('You must fill in the character name in Change Identity, because you have enabled it.')
                }
                else
                {
                    dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updating'));
                    let previousLineDocId = lines[
                        index != null && modalPopupBool.isOpen && modalPopupBool.type == 'above' ? index - 1 
                        : index != null ? index : lines.length - 1]?.docId;
                    //citamo actions iz reducera, smestamo u objekat i radimo new line

                    values = {
                        ...values,
                        ...clearedLine,
                        
                        hairstyle1Name: line.hairstyle1Name,
                        hairstyle2Name: line.hairstyle2Name,
                        hairstyle3Name: line.hairstyle3Name,
                        
                        hairstyle1Id: line.hairstyle1Id,
                        hairstyle2Id: line.hairstyle2Id,
                        hairstyle3Id: line.hairstyle3Id,
                        previousLineDocId: previousLineDocId,
                        episodeName: episodeName,
                        episodeSerialNumber: serialNumber
                    }

                    var isChangedByAction = validateActionChange(dispatch, values, lines, lines[lines.length-1], true)
                    values = {...values, actionChangeFlag: isChangedByAction}
                    
                    AddNewLine({...values, episodeSerialNumber: serialNumber}, index, linesFrame, selectedLineIndex, dispatch, alert, modalPopupBool.modalType, modalPopupBool.isOpen)
                }
            }}
            validationSchema={addLineSchema}>
            {({ dirty, isValid, handleSubmit }) => {
                return (
                    <div><CForm id="add-line-form-submit" className='row' onSubmit={(e : any) => 
                    { 
                        if(updateCloudBool === 'updating' || (!modalPopupBool.isOpen && selectedLineIndex !== -1 && inputLine)) 
                        {
                            e.preventDefault();
                            return;
                        } 
                        e.preventDefault(); handleSubmit(); 
                    }} disabled={!inputLine}>
                    <CInputGroup className={inputLine ? 'col border-input-line' : 'col'} style={
                        { 
                            marginLeft: !inputLine ? `${line.depth * parseInt(editEpisodeReadOnlyCell.minWidth.split("px")[0])}px` : "" ,
                            border: (selectedLineIndex === index) ? `4px solid #bdbdbd` : "",
                            padding: "0",
                            paddingLeft: !inputLine ? `4px` : "0"
                        }}
                        onClick={(event: any) => {
                            if (!inputLine && !modalPopupBool.isOpen)
                                dispatch(ActionCreatorsForEditEpisode.setCurrentLine({currentLine: line, currentLineIndex: index, linesStartIndex: index + 1 > linesFrame ? index + 1 - linesFrame : 0, linesSliceParam: index }))
                        }}>
                        <Field
                                {...(!inputLine ? 
                                    {
                                        as:"span",
                                        title: showLineActions(line),
                                        style: {...editEpisodeReadOnlyCell, backgroundColor: colorLineInsideAnswer(lines, index, pickedColorType, editEpisodeReadOnlyCell)},
                                        name: `lineNumber${isPreviewInModal ? inPreviewIndex : index}`,
                                    } 
                                    : 
                                    {
                                        as: undefined,
                                        name: "lineNumber",
                                        className: 'col-auto',
                                        style: { backgroundColor: "rgb(216, 219, 224)", maxWidth: "50px" }
                                    }
                                )}
                                type="text"
                                value={(inputLine && selectedLineIndex === -1) || modalPopupBool.isOpen ? getInputLineNumber(lines,line,index,maxLineNumber,modalPopupBool.isOpen, modalPopupBool.type) : line.lineNumber}
                                disabled>
                        </Field>
                        <Field
                            {...(!inputLine ? 
                            {
                                as:"span",
                                style: {...editEpisodeReadOnlyCell, backgroundColor: colorLineInsideAnswer(lines, index, pickedColorType, editEpisodeReadOnlyCell)},
                                name: `lineType${isPreviewInModal ? inPreviewIndex : index}`,
                                className: "col-auto cursor"
                            } 
                            : 
                            {
                                as: "select",
                                id: `lineType${index}`,
                                name: `lineType`,
                                className:"col-auto cursor",
                                value:line.lineType,
                                onChange:(event: any) => 
                                {
                                    if(modalPopupBool.isOpen)
                                    {
                                        dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine: {...lines[index], lineType: parseInt(event.target.value)}, isFirstTimeOpen: false }))
                                    }
                                    else
                                    {
                                        dispatch(ActionCreatorsForEditEpisode.updateLineTypeParam(parseInt(event.target.value)))
                                    }
                                }
                            }
                        )}
                            {...((!modalPopupBool.isOpen) && (inputLine && selectedLineIndex!==-1) ? {style: { backgroundColor: "rgb(216, 219, 224)" }} : {} )}
                            type="text"
                            disabled={ (!modalPopupBool.isOpen) && ( !inputLine || selectedLineIndex !== -1)}>
                            {inputLine ? currentLineTypes : ''}
                        </Field>
                        <Field
                            name="hairstyle"
                            className="col-auto border-fix-input"
                            disabled
                            type="text">
                        </Field>
                        <Field
                            {...(!inputLine ? 
                                    {
                                        as:"span",
                                        style: editEpisodeReadOnlyCell,
                                        className: "col hair-line cursor",
                                        name: `hairstyle1Name${isPreviewInModal ? inPreviewIndex : index}`,
                                    } 
                                    : 
                                    {
                                        as: CreatableSelect,
                                        className:"col-sm-2 cursor p-0 border-fix-input",
                                        name: "hairstyle1Name",
                                        options: [],
                                        noOptionsMessage: () => { return 'Create new hairstyle' },
                                        isClearable: true,
                                        backspaceRemovesValue: true,
                                        value: { id: line.hairstyle1Id, value: line.hairstyle1Id, label: line.hairstyle1Name},
                                        onChange:(value: any) => 
                                        {
                                            if(updateCloudBool !== 'updating')
                                            {
                                                if (value !== null) 
                                                { 
                                                    if (value.__isNew__ !== undefined) 
                                                    { 
                                                        if(modalPopupBool.isOpen)
                                                        {
                                                            dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine: {...modalPopupBool.currentLine, hairstyle1Id: -1, hairstyle1Name: value.label}, isFirstTimeOpen: false }))
                                                        }
                                                        else 
                                                        {
                                                            dispatch(ActionCreatorsForEditEpisode.updateHairstyle1Param({ ...value, id: -1 }))
                                                            if(selectedLineIndex != -1)
                                                            {
                                                                FormatObject(
                                                                    dispatch, 
                                                                    selectedLineIndex, 
                                                                    linesStartIndex,
                                                                    lines, 
                                                                    { lineToUpdateId: line.docId, lineModel: {...line, hairstyle1Id: -1, hairstyle1Name: value.label}}, alert)
                                                            }
                                                        }
                                                    }
                                                }
                                                else
                                                {
                                                    dispatch(ActionCreatorsForEditEpisode.updateHairstyle1Param({ id: null, value: null, label: ''}))
                                                }
                                            }
                                        }}
                                )
                            }
                            autoComplete="off"
                            type="text"
                            disabled={!inputLine}
                        >
                        </Field>
                        <Field
                            {...(!inputLine ? 
                                    {
                                        as:"span",
                                        style: editEpisodeReadOnlyCell,
                                        className: "col hair-line cursor",
                                        name: `hairstyle2Name${isPreviewInModal ? inPreviewIndex : index}`,
                                    } 
                                    : 
                                    {
                                        as: CreatableSelect,
                                        className:"col-sm-2 cursor p-0 border-fix-input",
                                        name: "hairstyle2Name",
                                        options: [],
                                        noOptionsMessage: () => { return 'Create new hairstyle' },
                                        isClearable: true,
                                        backspaceRemovesValue: true,
                                        value: { id: line.hairstyle2Id, value: line.hairstyle2Id, label: line.hairstyle2Name},
                                        onChange:(value: any) => 
                                        {
                                            if(updateCloudBool !== 'updating')
                                            {
                                                if (value !== null) 
                                                { 
                                                    if (value.__isNew__ !== undefined) 
                                                    { 
                                                        if(modalPopupBool.isOpen)
                                                        {
                                                            dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine: {...modalPopupBool.currentLine, hairstyle2Id: -1, hairstyle2Name: value.label}, isFirstTimeOpen: false }))
                                                        }
                                                        else 
                                                        {
                                                            dispatch(ActionCreatorsForEditEpisode.updateHairstyle2Param({ ...value, id: -1 }))
                                                            if(selectedLineIndex != -1)
                                                            {
                                                                FormatObject(
                                                                    dispatch, 
                                                                    selectedLineIndex, 
                                                                    linesStartIndex,
                                                                    lines, 
                                                                    { lineToUpdateId: line.docId, lineModel: {...line, hairstyle2Id: -1, hairstyle2Name: value.label}}, alert)
                                                            }
                                                        }
                                                    }
                                                }
                                                else
                                                {
                                                    dispatch(ActionCreatorsForEditEpisode.updateHairstyle2Param({ id: null, value: null, label: ''}))
                                                }
                                            }
                                        }}
                                )
                            }
                            autoComplete="off"
                            type="text"
                            disabled={!inputLine}
                        >
                        </Field>
                        <Field
                            {...(!inputLine ? 
                                    {
                                        as:"span",
                                        style: editEpisodeReadOnlyCell,
                                        className: "col hair-line cursor",
                                        name: `hairstyle3Name${isPreviewInModal ? inPreviewIndex : index}`,
                                    } 
                                    : 
                                    {
                                        as: CreatableSelect,
                                        className:"col-sm-2 cursor p-0 border-fix-input",
                                        name: "hairstyle3Name",
                                        options: [],
                                        noOptionsMessage: () => { return 'Create new hairstyle' },
                                        isClearable: true,
                                        backspaceRemovesValue: true,
                                        value: { id: line.hairstyle3Id, value: line.hairstyle3Id, label: line.hairstyle3Name},
                                        onChange:(value: any) => 
                                        {
                                            if(updateCloudBool !== 'updating')
                                            {
                                                if (value !== null) 
                                                { 
                                                    if (value.__isNew__ !== undefined) 
                                                    { 
                                                        if(modalPopupBool.isOpen)
                                                        {
                                                            dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine: {...modalPopupBool.currentLine, hairstyle3Id: -1, hairstyle3Name: value.label}, isFirstTimeOpen: false }))
                                                        }
                                                        else 
                                                        {
                                                            dispatch(ActionCreatorsForEditEpisode.updateHairstyle3Param({ ...value, id: -1 }))
                                                            if(selectedLineIndex != -1)
                                                            {
                                                                FormatObject(
                                                                    dispatch, 
                                                                    selectedLineIndex, 
                                                                    linesStartIndex,
                                                                    lines, 
                                                                    { lineToUpdateId: line.docId, lineModel: {...line, hairstyle3Id: -1, hairstyle3Name: value.label}}, alert)
                                                            }
                                                        }
                                                    }
                                                }
                                                else
                                                {
                                                    dispatch(ActionCreatorsForEditEpisode.updateHairstyle3Param({ id: null, value: null, label: ''}))
                                                }
                                            }
                                        }}
                                )
                            }
                            autoComplete="off"
                            type="text"
                            disabled={!inputLine}
                        >
                        </Field>
                        <input disabled className={inputLine ? "col input-field-cinematic-gray" : "col"} style={inputLine ? {} : editEpisodeReadOnlyCell}/>
                        {line.comment && !inputLine? <span className="tooltip-comment-exist" style={{backgroundColor: colorLineWithComment(line)}}></span> : ''}
                        </CInputGroup >
                        
                        <LineSidebar  
                            dispatch={dispatch} 
                            lines={lines} 
                            line={line} 
                            selectedLineIndex={selectedLineIndex} 
                            inputLine={inputLine} 
                            isPreviewInModal={isPreviewInModal}
                            modalPopupBool={modalPopupBool}
                            setYesnomodal={null}
                            editEpisodePassParam={editEpisodePassParam}
                            index={index}
                            pickedActionColor={pickedActionColor}
                            isChangedByOptionFlag={isLineOptionChanged(line)}
                        />

                    </CForm>
                </div>)
            }}
        </Formik>)
}