import React, { useEffect, useState } from 'react'
import { CButton, CTabContent, CTabPane, CTooltip } from '@coreui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { TabContent, TabPane } from 'reactstrap';
import BasicTranslationLine from '../Common/Translations/TranslationLines/BasicTranslationLine';
import BranchTranslationLine from '../Common/Translations/TranslationLines/BranchTranslationLine';
import CinematicTranslationLine from '../Common/Translations/TranslationLines/CinematicTranslationLine';
import Customization1TranslationLine from '../Common/Translations/TranslationLines/Customization1TranslationLine';
import Customization2TranslationLine from '../Common/Translations/TranslationLines/Customization2TranslationLine';
import GotoMainTranslationLine from '../Common/Translations/TranslationLines/GotoMainTranslationLine';
import EndOfLevelTranslationLine from '../Common/Translations/TranslationLines/LunescapeAdditionalLines/EndOfLevelTranslationLine';
import FashionTranslationLine from '../Common/Translations/TranslationLines/LunescapeAdditionalLines/FashionTranslationLine';
import { OutfitTranslationLine1, OutfitTranslationLine2, OutfitTranslationLine3 } from '../Common/Translations/TranslationLines/OutfitTranslationLine';
import { AnswerTranslationLine, QuestionTranslationLine } from '../Common/Translations/TranslationLines/QuestionAnswerTranslationLine';
import { TimedChoiceAnswerTranslationLine } from '../Common/Translations/TranslationLines/TimedChoiceAnswerTranslationLine';
import { TimedChoiceTranslationLine } from '../Common/Translations/TranslationLines/TimedChoiceTranslationLine';
import { VisualChoiceAnswerTranslationLine } from '../Common/Translations/TranslationLines/VisualChoiceAnswerTranslationLine';
import { VisualChoiceTranslationLine } from '../Common/Translations/TranslationLines/VisualChoiceTranslationLine';
import { IoArrowBackCircleSharp } from 'react-icons/io5';

var Infinite = require('react-infinite');

const TranslationsPreviewLines = () =>
{
    const history = useHistory();
    
    const [lines, setLines] = useState([]);
    const [params, setParams] = useState({
        storyId: null,
        serialNumber: null,
        language: null
    })
    const location = useLocation();
    
    useEffect(() => {
        if(location.state && location.state.lines)
        {
            setLines(location.state.lines);
            setParams({
                storyId: location.state.storyId,
                serialNumber: location.state.serialNumber,
                language: location.state.language,
            })
        }
    }, [location])

    return(
        <CTabContent className="table-width dashboard">
            <CTabPane className="tab-pan-art-catalog" active={true}>
                <CTooltip content="Go back.">
                    <CButton className="mr-4 mb-2" onClick={() => { history.goBack() }}><IoArrowBackCircleSharp size={24}/></CButton>
                </CTooltip>
                <Infinite useWindowAsScrollContainer elementHeight={25}>
                    {
                        lines?.map((line: any, index: any) =>
                            line.lineType === 0 ? <BasicTranslationLine key={index} line={line} index={index} inputLine={false} storyId={params.storyId} serialNumber={params.serialNumber} language={params.language} isPreview={true}/> : 
                                line.lineType === 1 ? <OutfitTranslationLine1 key={index} line={line} index={index} inputLine={false} params={params.storyId} serialNumber={params.serialNumber} language={params.language}  isPreview={true}/> :
                                    line.lineType === 2 ? <OutfitTranslationLine2 key={index} line={line} index={index} inputLine={false} storyId={params.storyId} serialNumber={params.serialNumber} language={params.language}  isPreview={true}/> :
                                        line.lineType === 3 ? <OutfitTranslationLine3 key={index} line={line} index={index} inputLine={false} storyId={params.storyId} serialNumber={params.serialNumber} language={params.language}  isPreview={true}/> :
                                            line.lineType === 4 ? <QuestionTranslationLine key={index} line={line} index={index} inputLine={false} storyId={params.storyId} serialNumber={params.serialNumber} language={params.language}  isPreview={true}/> :
                                                line.lineType === 5 ? <AnswerTranslationLine key={index} line={line} index={index} inputLine={false} storyId={params.storyId} serialNumber={params.serialNumber} language={params.language} isBranchAnswer={false} isPreview={true}/> :
                                                    line.lineType === 6 ? <CinematicTranslationLine key={index} line={line} index={index} inputLine={false} storyId={params.storyId} serialNumber={params.serialNumber} language={params.language}  isPreview={true}/> :
                                                        line.lineType === 7 ? <Customization1TranslationLine key={index} line={line} index={index} inputLine={false} storyId={params.storyId} serialNumber={params.serialNumber} language={params.language}  isPreview={true}/> :
                                                            line.lineType === 8 ? <Customization2TranslationLine key={index} line={line} index={index} inputLine={false} storyId={params.storyId} serialNumber={params.serialNumber} language={params.language}  isPreview={true}/> :
                                                                line.lineType === 9 ? <BranchTranslationLine key={index} line={line} index={index} inputLine={false} storyId={params.storyId} serialNumber={params.serialNumber} language={params.language}  isPreview={true}/> :
                                                                    line.lineType === 10 ? <AnswerTranslationLine key={index} line={line} index={index} inputLine={false} storyId={params.storyId} serialNumber={params.serialNumber} language={params.language} isBranchAnswer={true} isPreview={true}/> :
                                                                        line.lineType === 11 ? <GotoMainTranslationLine key={index} line={line} index={index} inputLine={false} storyId={params.storyId} serialNumber={params.serialNumber} language={params.language}  isPreview={true}/> :
                                                                            line.lineType === 12 ? <EndOfLevelTranslationLine key={index} line={line} index={index} inputLine={false} storyId={params.storyId} serialNumber={params.serialNumber} language={params.language}  isPreview={true}/> : 
                                                                                line.lineType === 13 ? <TimedChoiceTranslationLine key={index} line={line} index={index} inputLine={false} storyId={params.storyId} serialNumber={params.serialNumber} language={params.language}  isPreview={true}/> :
                                                                                    line.lineType === 14 ? <TimedChoiceAnswerTranslationLine key={index} line={line} index={index} inputLine={false} storyId={params.storyId} serialNumber={params.serialNumber} language={params.language} isBranchAnswer={false} isPreview={true}/> : 
                                                                                        line.lineType === 15 ? <VisualChoiceTranslationLine key={index} line={line} index={index} inputLine={false} storyId={params.storyId} serialNumber={params.serialNumber} language={params.language}  isPreview={true}/> :
                                                                                            line.lineType === 16 ? <VisualChoiceAnswerTranslationLine key={index} line={line} index={index} inputLine={false} storyId={params.storyId} serialNumber={params.serialNumber} language={params.language} isBranchAnswer={false} isPreview={true}/> :
                                                                                                line.lineType === 17 ? <FashionTranslationLine key={index} line={line} index={index} inputLine={false} storyId={params.storyId} serialNumber={params.serialNumber} language={params.language} isPreview={true}/> 
                            : <div/>
                    )}
                </Infinite>
            </CTabPane>
        </CTabContent>
    )
}

export default TranslationsPreviewLines;
