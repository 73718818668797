import React from 'react'
import { axiosInstance } from "../../../axios/key";
import { LineType } from "../../../interfaces/lineType"
import { AddNewLine } from '../../../services/EpisodeServices';
import { ActionCreatorsForEditEpisode, defaultLine } from "../../../store/EditEpisodeReducer";
import { ActionCreators, PopupProps } from '../../../store/StoriesReducer';
import { isAnyWriter, isReviewer } from '../../Auth/AuthConfig';
import { ErrorHandler } from '../../Helpers/ErrorHandler';
import { ActionColorTypes } from '../Enums/ActionColorTypes';
import { ChangeOutfitType } from '../Enums/ChangeOutfitType';
import { EpisodeStatus } from '../Enums/EpisodeStatus';
import { GameType } from '../Enums/GameType';
import { LineFormats } from '../Enums/LineFormats';
import { LineTypes } from '../Enums/LineTypes';
import { characterInputStyles } from "../ImageConvertor";

export const generateTabNameForLogger = (modalBody : any) =>
{
    if (modalBody === "character") 
        return 'Characters'
    else if (modalBody === "hairstyle")
        return 'Hairstyles'
    else if (modalBody === "clothes") 
        return 'Clothes'
    else if (modalBody === "accessories") 
        return 'Accessories'
    else if (modalBody === "backgrounds") 
        return 'Backgrounds'
    else if (modalBody === "cinematic")
        return 'Cinematics'
    else if (modalBody === "audio")
        return 'Audio'
    else if (modalBody === "visuals") 
        return 'Visual Choices'
    else if (modalBody === "items") 
        return 'Items'
    else 
        return ''
}

export const setupScriptsForGA = (srcUrlResponse : string, scrScriptResponse : string) =>
{
  let gtm = document.createElement('script');
  gtm.src = srcUrlResponse;
  document.body.appendChild(gtm);

  let gtmWithBody = document.createElement('script');
  var scriptLine1 = document.createTextNode(scrScriptResponse)

  gtmWithBody.appendChild(scriptLine1);
  
  document.body.appendChild(gtmWithBody);
}

export const editEpisodeReadOnlyCell = {
    backgroundColor: "rgb(216, 219, 224)",
    color: "rgb(84, 84, 84)",
    borderColor: "rgba(118, 118, 118, 0.3)",
    borderStyle: "solid",
    borderWidth: "1px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "60px",
    minHeight: '35px'
}

const commentUnresolved = { 
    //color: "#a83232"
    color: "red"
}

const commentResolved = { 
    //color: "#44a832"
    color: "green"
}

export const editEpisodeReadOnlyCellForCustomizationSpan = {
    backgroundColor: "rgb(216, 219, 224)",
    color: "rgb(84, 84, 84)",
    borderColor: "rgba(118, 118, 118, 0.3)",
    borderStyle: "solid",
    borderWidth: "1px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "35px",
    minHeight: '35px'
}

export const characterStyle = characterInputStyles

export const emotionStyle = {
    paddingLeft: "15px",
    paddingRight: "10px",
    maxWidth: "120px",
    minWidth: "120px"
}

export const linesFrameArray =
[
    { id: 0, value: 5, label: '5' },
    { id: 1, value: 10, label: '10' },
    { id: 2, value: 15, label: '15' },
    { id: 3, value: 20, label: '20' },
    { id: 4, value: 25, label: '25' },
    { id: 5, value: 50, label: '50' },
    { id: 6, value: 100, label: '100' },
    { id: 7, value: 150, label: '150' },
    { id: 8, value: 250, label: '250' },
    { id: 9, value: 500, label: '500' }
]

export const validations = {
    L: 
    {
        lengthByLineFormat: {
            S: 115,
            T: 115,
            B: 450,
            C: 150,
            D: 150
        },
        text: "Your line is empty or bigger then expected! Please, correct it before you submit it again."
    },
    M: 
    {
        text: 'You need to select or create cinematic before adding line.'
    },
    Q:
    {
        length: 450,
        text: 'Error in adding Q line.'
    },
    T:
    {
        length: 450,
        text: 'Error in adding T line.'
    },
    V:
    {
        length: 450,
        text: 'Error in adding V line.'
    }
}

export const generateLineNumbers = (lines: LineType [], previousLineNumber: number, clickedLineIndex: number, allLines: LineType []) => {
    let lineNumber = previousLineNumber;
    lines.map( (line: LineType, index: number) => { 
        line.filtered = false;
        lineNumber++;
        //A or TA
        if((line.lineType ?? 0) == LineTypes.A || (line.lineType ?? 0) == LineTypes.TA || (line.lineType ?? 0) == LineTypes.VA) {
            lineNumber--;
            let qIndex = index - 1;
            if(qIndex > -1) {
                while((line.depth ?? 0) < (lines[qIndex]?.depth ?? 0)) {
                    if(--qIndex == -1) break;
                }
            }
            if(qIndex == -1) {
                    qIndex = clickedLineIndex;
                    if(qIndex > -1) {
                        while((line.depth ?? 0) < (allLines[qIndex]?.depth ?? 0)) {
                            if(--qIndex == -1) break;
                        }
                        if(qIndex > -1) line.lineNumber = allLines[qIndex]?.lineNumber ?? 0;
                        else {
                            console.log(`Line number for line type A for index ${clickedLineIndex > -1 ? clickedLineIndex + index + 1 : index} not found`)
                        }
                    }
            }
            else {
                //found in our array
                line.lineNumber = lines[qIndex]?.lineNumber ?? 0;
            }
        }
        //BA
        else if((line.lineType ?? 0) == LineTypes.BA) {
            lineNumber--;
            let bIndex = index - 1;
            if(bIndex > -1) {
                while((line.depth ?? 0) < (lines[bIndex]?.depth ?? 0)) {
                    if(--bIndex == -1) break;
                }
            }
            if(bIndex == -1) {
                bIndex = clickedLineIndex;
                if(bIndex > -1) {
                    while((line.depth ?? 0) < (allLines[bIndex]?.depth ?? 0)) {
                        if(--bIndex == -1) break;
                    }
                    if(bIndex > -1) line.lineNumber = allLines[bIndex]?.lineNumber ?? 0;
                    else {
                        console.log(`Line number for line type A in B for index ${clickedLineIndex > -1 ? clickedLineIndex + index + 1 : index} not found`)
                    }
                }

            }
            else {
                //found in our array
                line.lineNumber = lines[bIndex]?.lineNumber ?? 0;
            }
        }
        //B,G
        else if([LineTypes.B, LineTypes.G].includes(line.lineType ?? 0))
        {
            lineNumber--;
            line.lineNumber = lineNumber;
        }
        //other
        else {
            line.lineNumber = lineNumber;
        }
    });

    //regenerate answer numbers (index 0 is the first line that needs an update)
    let index = 0;
    if(lines[index].lineType === LineTypes.A) {
        while(lines[++index])
        {
            if(lines[index].lineType === LineTypes.A && lines[index].answerQuestionId == lines[0].answerQuestionId)
                lines[index].answerNumber = (lines[index].answerNumber ?? 0) + 1
        }
    }

    //regenerate branch answer numbers
    index = 0;
    if(lines[index].lineType === LineTypes.BA) {
        while(lines[++index])
        {
            if(lines[index].lineType === LineTypes.BA && lines[index].b_BranchId == lines[0].b_BranchId) 
                lines[index].answerNumber = (lines[index].answerNumber ?? 0) + 1
        }
    }

    return lineNumber
}

export const generateQAnumbers = (data : any) =>
{
    let qs = data?.filter((line: any) => line.lineType == LineTypes.Q)?.map((q: any) => {
        let number = 1;
        data = data?.map((content : any, index : any) => {
            if(content.lineType === LineTypes.A && content.answerQuestionId == q.docId) {
                if(data[index-1]?.lineType === LineTypes.Q) {
                    number = 1;
                    return { ...content, answerNumber: number }
                }
                else if(data[index-1]?.lineType === LineTypes.A) return { ...content, answerNumber: ++number }
                else if((content.depth ?? 0) < (data[index-1]?.depth ?? 0)) return { ...content, answerNumber: ++number }
            }
            return content
        })
    })
    return data;
}

export const generateTAnumbers = (data : any) =>
{
    let qs = data?.filter((line: any) => line.lineType == LineTypes.T)?.map((q: any) => {
        let number = 1;
        data = data?.map((content : any, index : any) => {
            if(content.lineType === LineTypes.TA && content.answerTimedChoiceId == q.docId) {
                if(data[index-1]?.lineType === LineTypes.T) {
                    number = 1;
                    return { ...content, answerNumber: number }
                }
                else if(data[index-1]?.lineType === LineTypes.TA) return { ...content, answerNumber: ++number }
                else if((content.depth ?? 0) < (data[index-1]?.depth ?? 0)) return { ...content, answerNumber: ++number }
            }
            return content
        })
    })
    return data;
}

export const generateVAnumbers = (data : any) =>
{
    let qs = data?.filter((line: any) => line.lineType == LineTypes.V)?.map((q: any) => {
        let number = 1;
        data = data?.map((content : any, index : any) => {
            if(content.lineType === LineTypes.VA && content.answerVisualChoiceId == q.docId) {
                if(data[index-1]?.lineType === LineTypes.V) {
                    number = 1;
                    return { ...content, answerNumber: number }
                }
                else if(data[index-1]?.lineType === LineTypes.VA) return { ...content, answerNumber: ++number }
                else if((content.depth ?? 0) < (data[index-1]?.depth ?? 0)) return { ...content, answerNumber: ++number }
            }
            return content
        })
    })
    return data;
}

export const generateBAnumbers = (data : any) =>
{
    let bs = data?.filter((line: any) => line.lineType == LineTypes.B)?.map((b: any) => {
        let number = 1;
        data = data?.map((content : any, index : any) => {
            if(content.lineType === LineTypes.BA && content.b_BranchId == b.docId) {
                var indexToSubstract = 1;

                if(data[index-1]?.lineType === LineTypes.B) {
                    number = 1;
                    return { ...content, answerNumber: number }
                }
                else if(data[index-1]?.lineType === LineTypes.BA) return { ...content, answerNumber: ++number }
                else if((content.depth ?? 0) < (data[index-1]?.depth ?? 0)) return { ...content, answerNumber: ++number }

                while(data[index-indexToSubstract]?.lineType != LineTypes.BA)
                {
                    indexToSubstract++;
                }
                return { ...content, answerNumber: ++number }
            }
            return content
        })
    })
    return data;
}

export const getInputLineNumber = (lines: LineType [], line: LineType, index: number, maxLineNumber: number, isModalOpen: boolean, modalType : string): number => {
    if ([LineTypes.A, LineTypes.BA, LineTypes.G, LineTypes.VA, LineTypes.TA].includes(line.lineType ?? 0)) {
        return isModalOpen ? (line.lineNumber ?? 0) : getLineNumber(line, maxLineNumber)
    }
    return isModalOpen ? 
    ((index == null || index < 1 || (lines[index - 1].lineNumber ?? 0) <= (line.lineNumber ?? 0)) 
        ? (modalType == 'above' ? (line.lineNumber ?? 0) - 1 <= 0 ? 1 : (line.lineNumber ?? 0) - 1 : (line.lineNumber ?? 0) + 1) 
        : (modalType == 'above' ? (lines[index - 1].lineNumber ?? 0) - 1 <= 0 ? 1 : (lines[index - 1].lineNumber ?? 0) - 1 : (lines[index - 1].lineNumber ?? 0) + 1)
    ) 
    : (getLineNumber(line, maxLineNumber) + 1)
}

export const getLineNumber = (line: LineType, maxLineNumber: number): number => {
    if([LineTypes.A, LineTypes.BA, LineTypes.VA, LineTypes.TA].includes(line.lineType ?? 0)) {
        return line.lineNumber ?? 0
    }
    return maxLineNumber
}

export const updateLine = (dispatch : any, data : any, newLine : LineType) => 
{
    if(newLine.docId != data.lineId)
    {
        newLine.docId = data.lineId;
    }
    if (data.backgroundId && newLine.backgroundId != data.backgroundId) 
    {
        newLine.backgroundId = data.backgroundId;
        dispatch(ActionCreatorsForEditEpisode.addBackgrounds({ id: data.backgroundId, value: data.backgroundId, label: newLine.backgroundName, description: newLine.backgroundDescription }));
    }
    if (data.musicId && newLine.musicId != data.musicId) 
    {
        newLine.musicId = data.musicId;
        dispatch(ActionCreatorsForEditEpisode.addMusics({ id: data.musicId, value: data.musicId, label: newLine.musicName, description: newLine.musicDescription  }));
    }
    if (data.characterId && newLine.characterId != data.characterId) 
    {
        newLine.characterId = data.characterId;
        dispatch(ActionCreatorsForEditEpisode.addCharacters({ id: data.characterId, value: data.characterId, label: newLine.characterName, path: '', changeOutfitDisabled: false }));
        dispatch(ActionCreatorsForEditEpisode.addCharactersForOutfitChange({ id: data.characterId, value: data.characterId, label: newLine.characterName, path: '', changeOutfitDisabled: false }));
    }
    
    if(data.charactersOutfit?.length != 0)
    {
        newLine.charactersOutfit = data.charactersOutfit?.map((x : any) => Object.assign({},x))
        var newArray = data.charactersOutfit?.map((element : any, index : any) => 
        {
            if(data.charactersOutfit[index].accessoryId != null && data.charactersOutfit[index].accessoryId != element.accessoryId) 
            {
                element.accessoryId = data.charactersOutfit[index].accessoryId;
                dispatch(ActionCreatorsForEditEpisode.updateAccessories({ id: element.accessoryId, value: element.accessoryName, label: element.accessoryName }))
            } 
            if(data.charactersOutfit[index].hairstyleId != null && data.charactersOutfit[index].hairstyleId != element.hairstyleId)
            {
                element.hairstyleId = data.charactersOutfit[index].hairstyleId;
                dispatch(ActionCreatorsForEditEpisode.updateHairstyles({ id: data.charactersOutfit[index].hairstyleId, value: element.hairstyleName, label: element.hairstyleName }))
            }
            if(data.charactersOutfit[index].clothesId != null && data.charactersOutfit[index].clothesId != element.clothesId)
            {
                element.clothesId = data.charactersOutfit[index].clothesId;
                dispatch(ActionCreatorsForEditEpisode.updateClothes( { id: data.charactersOutfit[index].clothesId, value: element.clothesName, label: element.clothesName }))
            } 
            if(data.charactersOutfit[index].clothesName != element.clothesName)
            {
                element.clothesName = data.charactersOutfit[index].clothesName;
                dispatch(ActionCreatorsForEditEpisode.updateClothes( { id: element.clothesId, value: element.clothesName, label: element.clothesName }))
            }
            return element;
        })
        newLine.charactersOutfit = newArray;
    }

    if (data.clothes1Id && newLine.clothes1Id != data.clothes1Id) 
    {
        newLine.clothes1Id = data.clothes1Id;
    }
    if(newLine.clothes1Name != data.clothes1Name)
    {
        newLine.clothes1Name = data.clothes1Name;
        dispatch(ActionCreatorsForEditEpisode.updateCLineClothes1(newLine))
    }

    if (data.clothes2Id && newLine.clothes2Id != data.clothes2Id) 
    {
        newLine.clothes2Id = data.clothes2Id;
    }
    if(newLine.clothes2Name != data.clothes2Name)
    {
        newLine.clothes2Name = data.clothes2Name;
        dispatch(ActionCreatorsForEditEpisode.updateCLineClothes2(newLine))
    }
    
    if (data.clothes3Id && newLine.clothes3Id != data.clothes3Id) 
    {
        newLine.clothes3Id = data.clothes3Id;
    }
    if(newLine.clothes3Name != data.clothes3Name)
    {
        newLine.clothes3Name = data.clothes3Name;
        dispatch(ActionCreatorsForEditEpisode.updateCLineClothes3(newLine))
    }
    
    if (data.clothes4Id && newLine.clothes4Id != data.clothes4Id) 
    {
        newLine.clothes4Id = data.clothes4Id;
    }
    if (data.clothes5Id && newLine.clothes5Id != data.clothes5Id) 
    {
        newLine.clothes5Id = data.clothes5Id;
    }
    if (data.clothes6Id && newLine.clothes6Id != data.clothes6Id) 
    {
        newLine.clothes6Id = data.clothes6Id;
    }
    if (data.hairstyle1Id && newLine.hairstyle1Id != data.hairstyle1Id) 
    {
        newLine.hairstyle1Id = data.hairstyle1Id;
    }
    if (data.hairstyle2Id && newLine.hairstyle2Id != data.hairstyle2Id) 
    {
        newLine.hairstyle2Id = data.hairstyle2Id;
    }
    if (data.hairstyle3Id && newLine.hairstyle3Id != data.hairstyle3Id) 
    {
        newLine.hairstyle3Id = data.hairstyle3Id;
    }

    if (data.animationId && newLine.animationId != data.animationId) 
    {
        newLine.animationId = data.animationId;
        dispatch(ActionCreatorsForEditEpisode.addCinematics({ id: data.animationId, value: data.animationId, label: newLine.animationName }));
    }

    if (data.shortSoundId && newLine.shortSoundId != data.shortSoundId) 
    {
        newLine.shortSoundId = data.shortSoundId;
        dispatch(ActionCreatorsForEditEpisode.addShortSounds({ id: data.shortSoundId, value: data.shortSoundId, label: newLine.shortSoundName, description: newLine.shortSoundDescription }));
    }

    if (data.visualId && newLine.visualId != data.visualId) 
    {
        newLine.visualId = data.visualId;
        dispatch(ActionCreatorsForEditEpisode.addVisualAnswers({ id: data.visualId, value: data.visualId, label: newLine.visualName }));
    }
    
    if (data.itemId && newLine.itemId != data.itemId) 
    {
        newLine.itemId = data.itemId;
        dispatch(ActionCreatorsForEditEpisode.addStoryItem({ id: data.itemId, value: data.itemId, label: newLine.itemName, description: newLine.itemDescription }));
    }


    return newLine;
}

export const FormatObject = async (dispatch : any, selectedLineIndex : number, linesStartIndex: number, lines : any, actionObject: any, alert : any, previousLineUpdate? : boolean, doNotSetCurrentLine?: boolean) =>
{
    try
    {
        dispatch(ActionCreators.setModalPopupBoolAction({isOpen: false, line: defaultLine, index: null}));
        dispatch(ActionCreators.setModalPopupBoolOption({isOpen: false, line: defaultLine, index: null}));
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updating'));

        let previousLineDocId = undefined;
        if(selectedLineIndex == -1 && !lines[0].isDefault) previousLineDocId = lines[lines.length - 1]?.docId;
        if(selectedLineIndex == 0) previousLineDocId = "-1";
        if(selectedLineIndex > 0) previousLineDocId = lines[selectedLineIndex - 1]?.docId;
        
        var isChangedByAction = validateActionChange(dispatch, actionObject.lineModel, lines, lines[selectedLineIndex], false, previousLineUpdate);

        if(isChangedByAction) 
            var arrayOfDocIds = findLinesToUpdate(lines, selectedLineIndex);
        
        if(!actionObject.lineModel.actionChangeFlag)
        {
            actionObject = {...actionObject, lineModel: {...actionObject.lineModel, actionChangeFlag: isChangedByAction}}
        }  
        
        var line = { lineDTO: {...actionObject, lineModel: {...actionObject.lineModel, previousLineDocId: previousLineDocId}}, lineIds: arrayOfDocIds}

        await UpdateLine(dispatch, alert, line, selectedLineIndex, doNotSetCurrentLine);
    }
    catch
    {
        console.log('Error in formating object');
    }
}

export const UpdateLine = async (dispatch : any, alert : any, line : any, selectedLineIndex : any, doNotSetCurrentLine?: boolean) =>
{
    try
    {
        const { data } = await axiosInstance.post('api/Line/UpdateLine', line);

        var newLine = updateLine(dispatch, data, line.lineDTO.lineModel)
        
        if(!doNotSetCurrentLine)
        { 
            dispatch(ActionCreatorsForEditEpisode.updateChangeActionFlag({currentLineIndex: selectedLineIndex, returnValue: line.lineDTO.lineModel.actionChangeFlag}));
            dispatch(ActionCreatorsForEditEpisode.updateLineAndPropagateActions({currentLine: newLine, currentLineIndex: selectedLineIndex, setCurrentLine: false, lineIds: line.lineIds}))
        }
        else{
            dispatch(ActionCreatorsForEditEpisode.updateLineOptions({currentLine: newLine}))
        }
        //dispatch(ActionCreatorsForEditEpisode.updateLine({currentLine: newLine, currentLineIndex: selectedLineIndex, setCurrentLine: !doNotSetCurrentLine}))
        dispatch(ActionCreatorsForEditEpisode.setEpisodeSummary(data.episodeSummary));
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updated'));
    }
    catch(e)
    {
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('error'));
        ErrorHandler(e, 'An error occured while trying to update the line.', alert);
    }
}


const validateIdProperties = (currentLine : any, previousStateObject : any, linesObj : any, idParam: any, nameParam : any) =>
{
    if((currentLine && previousStateObject && linesObj)
        && (currentLine[idParam] || previousStateObject[idParam] || linesObj[idParam])
        && (currentLine[nameParam] || previousStateObject[nameParam] || linesObj[nameParam])
        && currentLine[idParam] != previousStateObject[idParam]
        && currentLine[nameParam] != previousStateObject[nameParam]
        && currentLine[idParam] != linesObj[idParam] 
        && currentLine[nameParam] != linesObj[nameParam])
        return true;
    else 
        return false;
}

const validateDescriptionProperties = (currentLine : any, previousStateObject : any, linesObj : any, descriptionParam : any) =>
{
    if((currentLine && previousStateObject && linesObj)
    && (currentLine[descriptionParam] || previousStateObject[descriptionParam] || linesObj[descriptionParam]) 
    && currentLine[descriptionParam] != previousStateObject[descriptionParam] 
    && currentLine[descriptionParam] != linesObj[descriptionParam])
        return true;
    else 
        return false;
}

const validateBoolProperties = (currentLine : any, previousStateObject : any, linesObj: any, boolParam : any) =>
{
    if((currentLine && previousStateObject && linesObj) && currentLine[boolParam] != previousStateObject[boolParam] && currentLine[boolParam] != linesObj[boolParam])
        return true;
    else 
        return false;
}

const updateCharacterOutfit = (dispatch : any, previousLineUpdate : any, outfit: any) =>
{
    if(previousLineUpdate)
    {
        dispatch(ActionCreators.setCharacterOutfitInPopup(outfit))
    }
    else 
    {
        dispatch(ActionCreatorsForEditEpisode.setCharactersOutfitState(outfit))
    }
}

export const hasLineOptionsChanged = (currentLine: any, lines : any, previousStateObject: any, isLineAdding: boolean) =>
{
    var newArray = lines.map((element : any) => element.docId)
    var currentLineIndex = newArray.indexOf(!isLineAdding ? currentLine.docId : currentLine.previousLineDocId);

    var returnValue = false;
        
    if(
        validateDescriptionProperties(currentLine, previousStateObject, lines[currentLineIndex], 'premiumChoiceText') ||
        validateDescriptionProperties(currentLine, previousStateObject, lines[currentLineIndex], 'premiumChoiceDescription') ||
        validateDescriptionProperties(currentLine, previousStateObject, lines[currentLineIndex], 'charmingPoint') ||
        validateIdProperties(currentLine, previousStateObject, lines[currentLineIndex], 'itemId', 'itemName') ||
        validateDescriptionProperties(currentLine, previousStateObject, lines[currentLineIndex], 'itemDescription')
    )
    {
        returnValue = true;
    }

    return returnValue;
}

export const isLineOptionChanged = (line: LineType) => {
    if(line.charmingPoint || line.premiumChoiceText || line.premiumChoiceDescription || line.itemId || line.itemDescription) return true;
}

export const hasLineActionsChanged = (currentLine: any, lines : any, previousStateObject: any, isLineAdding: boolean) =>
{
    var newArray = lines.map((element : any) => element.docId)
    var currentLineIndex = newArray.indexOf(!isLineAdding ? currentLine.docId : currentLine.previousLineDocId);

    var returnValue = false;

    if(
        validateIdProperties(currentLine, previousStateObject, lines[currentLineIndex], 'backgroundId', 'backgroundName') ||
        validateDescriptionProperties(currentLine, previousStateObject, lines[currentLineIndex], 'backgroundDescription') ||
        validateIdProperties(currentLine, previousStateObject, lines[currentLineIndex], 'musicId', 'musicName') || 
        validateDescriptionProperties(currentLine, previousStateObject, lines[currentLineIndex], 'musicDescription') ||
        validateIdProperties(currentLine, previousStateObject, lines[currentLineIndex], 'shortSoundId', 'shortSoundName') ||
        validateDescriptionProperties(currentLine, previousStateObject, lines[currentLineIndex], 'shortSoundDescription') ||
        validateBoolProperties(currentLine, previousStateObject, lines[currentLineIndex], 'shortSoundEnabled') ||
        validateBoolProperties(currentLine, previousStateObject, lines[currentLineIndex], 'avatarEnabled') ||
        validateBoolProperties(currentLine, previousStateObject, lines[currentLineIndex], 'callPhoneEnabled') ||
        validateBoolProperties(currentLine, previousStateObject, lines[currentLineIndex], 'callSmsEnabled') ||
        validateIdProperties(currentLine, previousStateObject, lines[currentLineIndex], 'callerCharacterId', 'callerCharacterName') ||
        validateBoolProperties(currentLine, previousStateObject, lines[currentLineIndex], 'changeIdentityOn') ||
        validateBoolProperties(currentLine, previousStateObject, lines[currentLineIndex], 'changeIdentityName')   
    )
    {
        returnValue = true;
    }   

    if(previousStateObject.charactersOutfit?.length != currentLine.charactersOutfit?.length && lines[currentLineIndex]?.charactersOutfit?.length != currentLine.charactersOutfit?.length)
    {
        returnValue = true;
    }

    currentLine.charactersOutfit?.forEach((element : any) => {
        var previousCharacter = previousStateObject.charactersOutfit?.find((e : any) => e.characterId == element.characterId);
        var linesCharacter = lines[currentLineIndex]?.charactersOutfit?.find((e : any) => e.characterId == element.characterId);

        if(
            validateIdProperties(element, previousCharacter, linesCharacter, 'clothesId', 'clothesName') ||
            validateDescriptionProperties(element, previousCharacter, linesCharacter, 'clothesDescription') ||
            validateDescriptionProperties(element, previousCharacter, linesCharacter, 'changeOutfitClothesType') ||
            validateIdProperties(element, previousCharacter, linesCharacter, 'hairstyleId', 'hairstyleName') ||
            validateDescriptionProperties(element, previousCharacter, linesCharacter, 'hairstyleDescription') ||
            validateDescriptionProperties(element, previousCharacter, linesCharacter, 'changeOutfitHairstyleType') ||
            validateIdProperties(element, previousCharacter, linesCharacter, 'accessoryId', 'accessoryName') ||
            validateDescriptionProperties(element, previousCharacter, linesCharacter, 'accessoryDescription') ||
            validateDescriptionProperties(element, previousCharacter, linesCharacter, 'accessoryEnabled') ||
            validateDescriptionProperties(element, previousCharacter, linesCharacter, 'accessoryType')
        )
        {
            returnValue = true;
        }
    });

        
    return returnValue;
}

export const validateActionChange = (dispatch: any, currentLine: any, lines : any, previousStateObject: any, isLineAdding: boolean, previousLineUpdate? : boolean | undefined) =>
{
    var newArray = lines.map((element : any) => element.docId)
    var currentLineIndex = newArray.indexOf(!isLineAdding ? currentLine.docId : currentLine.previousLineDocId);

    var returnValue = false;

    if(validateIdProperties(currentLine, previousStateObject, lines[currentLineIndex], 'backgroundId', 'backgroundName'))
    {
        if(previousLineUpdate)
        {
            dispatch(ActionCreators.setBackgroundInPopup({id: currentLine.backgroundId, label: currentLine.backgroundName}))
        }
        else 
        {
            dispatch(ActionCreatorsForEditEpisode.setBackgroundState({backgroundId: currentLine.backgroundId, backgroundName: currentLine.backgroundName}))
        }
        returnValue = true;
    }   
    
    if(validateDescriptionProperties(currentLine, previousStateObject, lines[currentLineIndex], 'backgroundDescription'))
    {
        if(previousLineUpdate)
        {
            dispatch(ActionCreators.setBackgroundDescriptionInPopup(currentLine.backgroundDescription))
        }
        else 
        {
            dispatch(ActionCreatorsForEditEpisode.setBackgroundDescState(currentLine.backgroundDescription))
        }
        returnValue = true;
    }
    
    if(validateIdProperties(currentLine, previousStateObject, lines[currentLineIndex], 'musicId', 'musicName'))
    {
        if(previousLineUpdate)
        {
            dispatch(ActionCreators.setMusicThemeInPopup({id: currentLine.musicId, label: currentLine.musicName}))
        }
        else 
        {
            dispatch(ActionCreatorsForEditEpisode.setMusicState({musicId: currentLine.musicId, musicName: currentLine.musicName}))
        }
        returnValue = true;
    }
    
    if(validateDescriptionProperties(currentLine, previousStateObject, lines[currentLineIndex], 'musicDescription'))
    {
        if(previousLineUpdate)
        {
            dispatch(ActionCreators.setMusicThemeDescriptionInPopup(currentLine.musicDescription))
        }
        else 
        {
            dispatch(ActionCreatorsForEditEpisode.setMusicDescState(currentLine.musicDescription))
        }
        returnValue = true;
    }
    
    if(validateIdProperties(currentLine, previousStateObject, lines[currentLineIndex], 'shortSoundId', 'shortSoundName'))
    {
        if(previousLineUpdate)
        {
            dispatch(ActionCreators.setShortSoundInPopup({id: currentLine.shortSoundId, label: currentLine.shortSoundName}))
        }
        else 
        {
            dispatch(ActionCreatorsForEditEpisode.setShortSoundState({shortSoundId: currentLine.shortSoundId, shortSoundName: currentLine.shortSoundName}))
        }
        returnValue = true;
    }
    
    if(validateDescriptionProperties(currentLine, previousStateObject, lines[currentLineIndex], 'shortSoundDescription'))
    {
        if(previousLineUpdate)
        {
            dispatch(ActionCreators.setShortSoundDescriptionInPopup(currentLine.shortSoundDescription))
        }
        else 
        {
            dispatch(ActionCreatorsForEditEpisode.setShortSoundDesc(currentLine.shortSoundDescription))
        }
        returnValue = true;
    }
    
    if(validateBoolProperties(currentLine, previousStateObject, lines[currentLineIndex], 'shortSoundEnabled'))
    {
        if(previousLineUpdate)
        {
            dispatch(ActionCreators.setShortSoundBoolInPopup(currentLine.shortSoundEnabled))
        }
        else 
        {
            dispatch(ActionCreatorsForEditEpisode.setShortSoundEnabled(currentLine.shortSoundEnabled))
        }
        returnValue = true;
    }
    
    if(validateBoolProperties(currentLine, previousStateObject, lines[currentLineIndex], 'avatarEnabled'))
    {
        if(previousLineUpdate)
        {
            dispatch(ActionCreators.setAvatarBoolInPopup(currentLine.avatarEnabled))
        }
        else 
        {
            dispatch(ActionCreatorsForEditEpisode.setAvatarState(currentLine.avatarEnabled))
        }
        returnValue = true;
    }
    
    if(validateBoolProperties(currentLine, previousStateObject, lines[currentLineIndex], 'callPhoneEnabled'))
    {
        if(previousLineUpdate)
        {
            dispatch(ActionCreators.setPhoneCallInPopup(currentLine.callPhoneEnabled))
        }
        else 
        {
            dispatch(ActionCreatorsForEditEpisode.setCallPhoneState(currentLine.callPhoneEnabled))
        }
        returnValue = true;
    }

    if(validateBoolProperties(currentLine, previousStateObject, lines[currentLineIndex], 'callSmsEnabled'))
    {
        if(previousLineUpdate)
        {
            dispatch(ActionCreators.setSMSInPopup(currentLine.callSmsEnabled))
        }
        else 
        {
            dispatch(ActionCreatorsForEditEpisode.setCallSmsState(currentLine.callSmsEnabled))
        }
        returnValue = true;
    }

    if(validateIdProperties(currentLine, previousStateObject, lines[currentLineIndex], 'callerCharacterId', 'callerCharacterName'))
    {
        if(previousLineUpdate)
        {
            dispatch(ActionCreators.setCallerNameInPopup({id: currentLine.callerCharacterId, label: currentLine.callerCharacterName}))
        }
        else 
        {
            dispatch(ActionCreatorsForEditEpisode.setCallerName({callerCharacterId: currentLine.callerCharacterId, callerCharacterName: currentLine.callerCharacterName}))
        }
        returnValue = true;
    }

    if(validateBoolProperties(currentLine, previousStateObject, lines[currentLineIndex], 'changeIdentityOn'))
    {
        if(previousLineUpdate)
        {
            dispatch(ActionCreators.setCharacterIdentityEnabledInPopup(currentLine.changeIdentityOn))
        }
        else 
        {
            dispatch(ActionCreatorsForEditEpisode.setChangeIndetityEnabled(currentLine.changeIdentityOn))
        }
        returnValue = true;
    }

    if(validateBoolProperties(currentLine, previousStateObject, lines[currentLineIndex], 'changeIdentityName'))
    {
        if(previousLineUpdate)
        {
            dispatch(ActionCreators.setCharacterIdentityNameInPopup(currentLine.changeIdentityName))
        }
        else 
        {
            dispatch(ActionCreatorsForEditEpisode.setChangeIndetityName(currentLine.changeIdentityName))
        }
        returnValue = true;
    }

    if(previousStateObject.charactersOutfit?.length != currentLine.charactersOutfit?.length && lines[currentLineIndex]?.charactersOutfit?.length != currentLine.charactersOutfit?.length)
    {
        updateCharacterOutfit(dispatch, previousLineUpdate, currentLine.charactersOutfit);
        returnValue = true;
    }

    currentLine.charactersOutfit?.forEach((element : any) => {
        var previousCharacter = previousStateObject.charactersOutfit?.find((e : any) => e.characterId == element.characterId);
        var linesCharacter = lines[currentLineIndex]?.charactersOutfit?.find((e : any) => e.characterId == element.characterId);

        if(
            validateIdProperties(element, previousCharacter, linesCharacter, 'clothesId', 'clothesName') ||
            validateDescriptionProperties(element, previousCharacter, linesCharacter, 'clothesDescription') ||
            validateDescriptionProperties(element, previousCharacter, linesCharacter, 'changeOutfitClothesType') ||
            validateIdProperties(element, previousCharacter, linesCharacter, 'hairstyleId', 'hairstyleName') ||
            validateDescriptionProperties(element, previousCharacter, linesCharacter, 'hairstyleDescription') ||
            validateDescriptionProperties(element, previousCharacter, linesCharacter, 'changeOutfitHairstyleType') ||
            validateIdProperties(element, previousCharacter, linesCharacter, 'accessoryId', 'accessoryName') ||
            validateDescriptionProperties(element, previousCharacter, linesCharacter, 'accessoryDescription') ||
            validateDescriptionProperties(element, previousCharacter, linesCharacter, 'accessoryEnabled') ||
            validateDescriptionProperties(element, previousCharacter, linesCharacter, 'accessoryType')
        )
        {
            updateCharacterOutfit(dispatch, previousLineUpdate, currentLine.charactersOutfit);
            returnValue = true;
        }
    });

    if(currentLine.characterId == '-1')
    {
        returnValue = true;
    }

    return returnValue;
}

export const validateLineOptionsChange = (dispatch : any, currentLine: any, lines : any, previousStateObject: any, isLineAdding: boolean, previousLineUpdate? : boolean) =>
{
    var newArray = lines.map((element : any) => element.docId)
    var currentLineIndex = newArray.indexOf(!isLineAdding ? currentLine.docId : currentLine.previousLineDocId);

    var returnValue = false;

    if(validateDescriptionProperties(currentLine, previousStateObject, lines[currentLineIndex], 'premiumChoiceText'))
    {
        if(previousLineUpdate)
        {
            dispatch(ActionCreators.setPremiumChoiceTextInPopup(currentLine.premiumChoiceText))
        }
        else
        {
            dispatch(ActionCreatorsForEditEpisode.setPremiumChoiceState(currentLine.premiumChoiceText))
        }
        returnValue = true;
    } 

    if(validateDescriptionProperties(currentLine, previousStateObject, lines[currentLineIndex], 'premiumChoiceDescription'))
    {
        if(previousLineUpdate)
        {
            dispatch(ActionCreators.setPremiumChoiceTextDescriptionInPopup(currentLine.premiumChoiceDescription))
        }
        else
        {
            dispatch(ActionCreatorsForEditEpisode.setPremiumChoiceDescriptionState(currentLine.premiumChoiceDescription))
        }
        returnValue = true;
    }
        
    if(validateDescriptionProperties(currentLine, previousStateObject, lines[currentLineIndex], 'charmingPoint'))
    {
        if(previousLineUpdate)
        {
            dispatch(ActionCreators.setCharmingPointInPopup(currentLine.charmingPoint))
        }
        else
        {
            dispatch(ActionCreatorsForEditEpisode.setCharmingPointState(currentLine.charmingPoint))
        }
        returnValue = true;
    }
    
    if(validateIdProperties(currentLine, previousStateObject, lines[currentLineIndex], 'itemId', 'itemName'))
    {
        if(previousLineUpdate)
        {
            dispatch(ActionCreators.setShowItemInPopup({id: currentLine.itemId, label: currentLine.itemName}))
        }
        else
        {
            dispatch(ActionCreatorsForEditEpisode.setShowItemState({itemId: currentLine.itemId, itemName: currentLine.itemName}))
        }
        returnValue = true;
    }
    
    if(validateDescriptionProperties(currentLine, previousStateObject, lines[currentLineIndex], 'itemDescription'))
    {
        if(previousLineUpdate)
        {
            dispatch(ActionCreators.setShowItemDescriptionInPopup(currentLine.itemDescription))
        }
        else
        {
            dispatch(ActionCreatorsForEditEpisode.setShowItemDesc(currentLine.itemDescription))
        }
        returnValue = true;
    }

    return returnValue;
}

export const createDefaultLineTypeOptions = (lines: any, index: number, defaultLineTypes: any, defaultBranchQandC: any, isOLineAdded: boolean, isModalOpen : any, modalType : string) => 
{
    //exclude O if needed
    if(isOLineAdded) {
        return defaultLineTypes?.map((lineType: any) => 
        {
            let newLineType = Object.assign({}, lineType)
            if(newLineType.value == LineTypes.C1) newLineType.label = "C";
            const isModalOpenDefaultHidden = 
            [
                LineTypes.O1,
                LineTypes.O2,
                LineTypes.O3,
                LineTypes.C2,
                LineTypes.BA
            ];
            let hiddenArray = isModalOpen 
                ? (index == undefined
                    ? isModalOpenDefaultHidden : [...isModalOpenDefaultHidden, ...hideTypesBasedOnPreviousLine(lines, index, modalType)]) 
                    : [
                        LineTypes.O1, 
                        LineTypes.O2,
                        LineTypes.O3,
                        LineTypes.A,
                        LineTypes.C2,
                        LineTypes.BA,
                        LineTypes.TA,
                        LineTypes.VA
                    ]
            if(createDefaultBranchQandCOptions(lines, index, -1, defaultBranchQandC).length == 0) hiddenArray.push(LineTypes.B);
            return (<option key={newLineType.id} title={newLineType.tooltip} value={newLineType.value} hidden={hiddenArray.includes(newLineType.value)}>{newLineType.label}</option>)
        });
    }

    let newLineTypes = defaultLineTypes?.map((lineType: any) => 
    {
        let newLineType = Object.assign({}, lineType)
        if(newLineType.value==1) newLineType.label = "O";
        else if(newLineType.value==7) newLineType.label = "C";
        const isModalOpenDefaultHidden = [
            LineTypes.O2,
            LineTypes.O3,
            LineTypes.C2,
            LineTypes.BA
        ];
        let hiddenArray = isModalOpen 
            ? (index == undefined ? isModalOpenDefaultHidden : [...isModalOpenDefaultHidden, ...hideTypesBasedOnPreviousLine(lines, index, modalType)]) 
            : [
                LineTypes.O2,
                LineTypes.O3,
                LineTypes.A,
                LineTypes.C2,
                LineTypes.BA,
                LineTypes.TA,
                LineTypes.VA
            ]
        if(createDefaultBranchQandCOptions(lines, index, -1, defaultBranchQandC).length == 0) hiddenArray.push(LineTypes.B);
        return (<option key={newLineType.id} title={newLineType.tooltip} value={newLineType.value} hidden={hiddenArray.includes(newLineType.value)}>{newLineType.label}</option>)
    });
    return newLineTypes;
}    

export const hideTypesBasedOnPreviousLine = (lines: any, previousLineIndex: number, modalType : string) => 
{
    let hiddenTypes = [] as any[];
    let previousLine = lines[previousLineIndex];
    if(previousLine.depth > 0) { //hide O and E if we are not on level 0
        hiddenTypes.push(LineTypes.O1, LineTypes.O2, LineTypes.O3, LineTypes.E);
    }
    if(lines[previousLineIndex].answerTimedChoiceDefault == true)
    {
        hiddenTypes = [...hiddenTypes, LineTypes.TA] 
    }
    if(modalType == 'above')
    {
        hiddenTypes = [...hiddenTypes, 
            LineTypes.A,
            LineTypes.TA,
            LineTypes.VA,
            LineTypes.BA,
            LineTypes.C2
        ] 
    }
    else if(previousLine.lineType === LineTypes.Q) //Q
    {
        hiddenTypes = [...hiddenTypes, 
            LineTypes.L,
            LineTypes.O1,
            LineTypes.O2,
            LineTypes.O3,
            LineTypes.Q,
            LineTypes.M,
            LineTypes.C1,
            LineTypes.C2,
            LineTypes.B,
            LineTypes.BA,
            LineTypes.G,
            LineTypes.E,
            LineTypes.T,
            LineTypes.TA,
            LineTypes.V,
            LineTypes.VA,
            LineTypes.F
        ] //only A
    }
    else if(previousLine.lineType === LineTypes.T) //T
    {
        hiddenTypes = [...hiddenTypes, 
            LineTypes.L,
            LineTypes.O1,
            LineTypes.O2,
            LineTypes.O3,
            LineTypes.Q,
            LineTypes.A,
            LineTypes.M,
            LineTypes.C1,
            LineTypes.C2,
            LineTypes.B,
            LineTypes.BA,
            LineTypes.G,
            LineTypes.E,
            LineTypes.T,
            LineTypes.V,
            LineTypes.VA,
            LineTypes.F
        ] //only TA
    }
    else if(previousLine.lineType === LineTypes.V) //T
    {
        hiddenTypes = [...hiddenTypes, 
            LineTypes.L,
            LineTypes.O1,
            LineTypes.O2,
            LineTypes.O3,
            LineTypes.Q,
            LineTypes.A,
            LineTypes.M,
            LineTypes.C1,
            LineTypes.C2,
            LineTypes.B,
            LineTypes.BA,
            LineTypes.G,
            LineTypes.E,
            LineTypes.T,
            LineTypes.TA,
            LineTypes.V,
            LineTypes.F
        ] //only VA
    }
    else if(previousLine.lineType === LineTypes.A) //A
    {
        let numberOfAs = 1;
        let shouldhideA = false;
        let counterBack = previousLineIndex - 1; let backIf = counterBack != -1;
        let counterForward = previousLineIndex + 1; let fwdIf = counterForward < lines.length;
        while(!shouldhideA && (backIf || fwdIf)) {
            if(backIf) {
                if(lines[counterBack]?.lineType === LineTypes.A && lines[counterBack].depth === previousLine.depth && ++numberOfAs == 4) shouldhideA = true
                else if((lines[counterBack]?.lineType === LineTypes.Q && lines[counterBack].depth === previousLine.depth) || !lines[--counterBack]) backIf = false
            }
            if(fwdIf) {
                if(lines[counterForward]?.lineType === LineTypes.A && lines[counterForward].depth === previousLine.depth && ++numberOfAs == 4) shouldhideA = true
                else if((lines[counterForward]?.lineType !== LineTypes.A && lines[counterForward].depth === previousLine.depth) || !lines[++counterForward]) fwdIf = false
            }
        }
        if(shouldhideA) {
            hiddenTypes.push(LineTypes.A);
        }
        hiddenTypes.push(LineTypes.B);
        hiddenTypes.push(LineTypes.TA);
        hiddenTypes.push(LineTypes.VA);
    }
    else if(previousLine.lineType === LineTypes.TA) //TA
    {
        let numberOfAs = 1;
        let shouldhideTA = false;
        let counterBack = previousLineIndex - 1; let backIf = counterBack != -1;
        let counterForward = previousLineIndex + 1; let fwdIf = counterForward < lines.length;
        while(!shouldhideTA && (backIf || fwdIf)) {
            if(backIf) {
                if(lines[counterBack].lineType === LineTypes.TA && lines[counterBack].depth === previousLine.depth && ++numberOfAs == 4) shouldhideTA = true
                else if((lines[counterBack].lineType === LineTypes.T && lines[counterBack].depth === previousLine.depth) || !lines[--counterBack]) backIf = false
            }
            if(fwdIf) {
                if(lines[counterForward].lineType === LineTypes.TA && lines[counterForward].depth === previousLine.depth && ++numberOfAs == 4) shouldhideTA = true
                else if((lines[counterForward].lineType !== LineTypes.TA && lines[counterForward].depth === previousLine.depth) || !lines[++counterForward]) fwdIf = false
            }
        }
        if(shouldhideTA) {
            hiddenTypes.push(LineTypes.TA);
        }
        hiddenTypes.push(LineTypes.B);
        hiddenTypes.push(LineTypes.A);
        hiddenTypes.push(LineTypes.VA);
    }
    else if(previousLine.lineType === LineTypes.VA) //VA
    {
        let numberOfAs = 1;
        let shouldhideVA = false;
        let counterBack = previousLineIndex - 1; let backIf = counterBack != -1;
        let counterForward = previousLineIndex + 1; let fwdIf = counterForward < lines.length;
        while(!shouldhideVA && (backIf || fwdIf)) {
            if(backIf) {
                if(lines[counterBack].lineType === LineTypes.VA && lines[counterBack].depth === previousLine.depth && ++numberOfAs == 4) shouldhideVA = true
                else if((lines[counterBack].lineType === LineTypes.V && lines[counterBack].depth === previousLine.depth) || !lines[--counterBack]) backIf = false
            }
            if(fwdIf) {
                if(lines[counterForward].lineType === LineTypes.VA && lines[counterForward].depth === previousLine.depth && ++numberOfAs == 4) shouldhideVA = true
                else if((lines[counterForward].lineType !== LineTypes.VA && lines[counterForward].depth === previousLine.depth) || !lines[++counterForward]) fwdIf = false
            }
        }
        if(shouldhideVA) {
            hiddenTypes.push(LineTypes.VA);
        }
        hiddenTypes.push(LineTypes.B);
        hiddenTypes.push(LineTypes.A);
        hiddenTypes.push(LineTypes.TA);
    }
    else if([LineTypes.C1, LineTypes.BA].includes(previousLine.lineType ?? 0))
    {
        hiddenTypes.push(LineTypes.A); 
        hiddenTypes.push(LineTypes.B);
        hiddenTypes.push(LineTypes.TA);
        hiddenTypes.push(LineTypes.VA);
    }
    else if(previousLine.lineType === LineTypes.C2)
    {
        hiddenTypes.push(LineTypes.B); 
        hiddenTypes.push(LineTypes.A); 
        hiddenTypes.push(LineTypes.G); 
        hiddenTypes.push(LineTypes.TA); 
        hiddenTypes.push(LineTypes.VA);
    }
    else {
        hiddenTypes.push(LineTypes.A);
        hiddenTypes.push(LineTypes.TA); 
        hiddenTypes.push(LineTypes.VA);
    }
    return hiddenTypes;
}

export const createDefaultLineFormatOptions = (defaultLineFormats: any, line : any, gameType : GameType, lineType : LineTypes) => 
{
    if(lineType == LineTypes.Q && gameType == GameType.Lunescape)
    {
        return defaultLineFormats?.map((lineFormat : any) => 
        { 
            if([LineFormats.S, LineFormats.T, LineFormats.D].includes(lineFormat.value))
            {
                if(lineFormat.value == LineFormats.T && (line == "Narrator" || line == undefined))
                {
                    return (<option key={lineFormat.id} value={lineFormat.value} disabled title={lineFormat.tooltip}>{lineFormat.label}</option>)
                }
                return (<option key={lineFormat.id} value={lineFormat.value} title={lineFormat.tooltip}>{lineFormat.label}</option>)
            }
        });
    }
    return defaultLineFormats?.map((lineFormat: any) => 
    {
        if(lineFormat.label == "T" && (line == "Narrator" || line == undefined))
        {
            return (<option key={lineFormat.id} value={lineFormat.value} disabled title={lineFormat.tooltip}>{lineFormat.label}</option>)
        }
        return (<option key={lineFormat.id} value={lineFormat.value} title={lineFormat.tooltip}>{lineFormat.label}</option>)
    });
} 
export const createDefaultEmotionsOptions = (defaultEmotions: any) => {
    return defaultEmotions?.map((emotion: any) => {
        return (<option key={emotion.id} value={emotion.value}>{emotion.label}</option>)
    });
}
export const createDefaultBranchQandCOptions = (lines: any, index: number, serialNumber: number, defaultBranchQandC: any) => 
{
    let interestedInLines = index > -1
        ? lines?.filter((l: LineType, i: number) => i <= index 
        && (l.lineType === LineTypes.Q || l.lineType === LineTypes.C1 || l.lineType == LineTypes.T || l.lineType == LineTypes.V)) 
        : lines?.filter((l: LineType) => l.lineType === LineTypes.Q || l.lineType === LineTypes.C1 || l.lineType == LineTypes.T || l.lineType == LineTypes.V)
    let episodeQandC = interestedInLines?.map((l: LineType) => {
        if(l.lineType === LineTypes.Q)
            return { id: l.docId, value: l.docId, label: `${serialNumber} - ${l.text ?? ""}`, type: LineTypes.Q, episodeId: l.episodeId, episodeSerialNumber: serialNumber }
        else if(l.lineType === LineTypes.T)
            return { id: l.docId, value: l.docId, label: `${serialNumber} - ${l.text ?? ""}`, type: LineTypes.T, episodeId: l.episodeId, episodeSerialNumber: serialNumber }
        else if(l.lineType === LineTypes.V)
            return { id: l.docId, value: l.docId, label: `${serialNumber} - ${l.text ?? ""}`, type: LineTypes.V, episodeId: l.episodeId, episodeSerialNumber: serialNumber }
        else 
            return { id: l.docId, value: l.docId, label: `${serialNumber} - ${l.clothes1Name ?? ""} ${l.clothes2Name ?? ""} ${l.clothes3Name ?? ""}`, type: 7, episodeId: l.episodeId, episodeSerialNumber: serialNumber }
    })
    let branchQandC = [...defaultBranchQandC, ...episodeQandC]
    let newBranchQandC = branchQandC?.map((bopt: any) => 
    {
        let newBopt = Object.assign({}, bopt)
        return (<option key={newBopt.id} value={newBopt.value} datatype={newBopt.type} data-episodeid={newBopt.episodeId} data-episodeserialsumber={newBopt.episodeSerialNumber} >{newBopt.label}</option>)
    });
    return newBranchQandC;
}  
export const createDefaultActionLinesOptions = (defaultActionLines: any) => {
    let placeholderOpt = (<option key={-100} value={-100} disabled selected hidden>{'Choose actions from line...'}</option>)
    let noneOpt = (<option key={-1} value={-1}>{''}</option>)
    return [
        placeholderOpt,
        noneOpt,
        ...defaultActionLines?.map((line: any) => {
            return (<option key={line.id} value={line.value}>{line.label}</option>)
        })
    ];
}

export const FormatObjectForDiamond = (dispatch : any, selectedLineIndex : number, linesStartIndex: number, lines : any, actionObject: any, alert : any) =>
{
    try
    {
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updating'));

        let previousLineDocId = null;
        if(selectedLineIndex == -1 && !lines[0].isDefault) previousLineDocId = lines[lines.length - 1]?.docId;
        if(selectedLineIndex == 0) previousLineDocId = "-1";
        if(selectedLineIndex > 0) previousLineDocId = lines[selectedLineIndex - 1]?.docId;
      
        let line = { lineDTO: {...actionObject, lineModel: {...actionObject.lineModel, previousLineDocId: previousLineDocId}}, lineIds: undefined}
        UpdateLineForDiamond(dispatch, line, selectedLineIndex, linesStartIndex, alert)
    }
    catch
    {
        console.log('Error in formating object');
    }
}

export const UpdateLineForDiamond = async (dispatch : any, line : any, selectedLineIndex : any, linesStartIndex : any, alert : any) =>
{
    try
    {
        const { data } = await axiosInstance.post('api/Line/UpdateLine', line);

        var newLine = updateLine(dispatch, data, line.lineDTO.lineModel);
        
        dispatch(ActionCreatorsForEditEpisode.updateLine({currentLine: newLine, currentLineIndex: selectedLineIndex, setCurrentLine: false}))
        dispatch(ActionCreatorsForEditEpisode.setEpisodeSummary(data.episodeSummary));
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updated'));
    }
    catch(e)
    {
        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('error'));
        ErrorHandler(e, 'An error occured while trying to change diamond state.', alert);
    }
}

export const findLinesToUpdate = (lines : any, selectedLineIndex : any) =>
{
    var index = selectedLineIndex + 1;
    var arrayOfDocIds : LineType[] = [];
    while(index <= lines?.length - 1 && !lines[index].actionChangeFlag)
    {
        arrayOfDocIds.push(lines[index]);
        index++;
    }
    if(arrayOfDocIds.length != 0)
    {
        return arrayOfDocIds?.map((content) => content?.docId)
    }
}

export const validateIfBorCLine = (line : any) =>
{
    if(line?.lineFormat == LineFormats.B || line?.lineFormat == LineFormats.C) return true;
    return false; 
}

export const validateIfBorCLineFormatSelected = (line : any) =>
{
    if(line?.lineFormat == LineFormats.B || line?.lineFormat == LineFormats.C) return 0;
    return line?.lineFormat; 
}

export const validateIfBorCLineFormatSelectedOrNarrator = (line : any, storyCharacters : any) =>
{
    if(line?.lineFormat == LineFormats.B || line?.lineFormat == LineFormats.C || line?.characterName == "Narrator" || storyCharacters.find((element : any) => element.id == line.characterId)?.label == "Narrator") return true;
    return false; 
}

export const validateEpisodeStatusForDisable = (currentStatus : number) =>
{
    if(isReviewer() && !isAnyWriter())
        return true;
    if(currentStatus == EpisodeStatus.InProgress || currentStatus == EpisodeStatus.CorrectionRequired)
        return false;
    else
        return true;
}

export const validateEpisodeCommentStatusForDisable = (currentStatus : number) =>
{
    if(currentStatus == EpisodeStatus.InReview || currentStatus == EpisodeStatus.CorrectionRequired)
        return false;
    else
        return true;
}

export const selectStylesForField = {
    menuPortal: (base : any) => ({ ...base, zIndex: 9999 }),
    menu: (provided : any) => ({ ...provided, zIndex: "9999 !important" })
};

export const jumpToLastLine = (lines : any, dispatch : any, linesFrame : any) =>
{
    let currentLineForJump = {...lines[lines.length - 1], text: undefined}
    let currentLineIndexForJump = -1
    dispatch(ActionCreatorsForEditEpisode.setCurrentLine({
        currentLine: currentLineForJump, 
        currentLineIndex: currentLineIndexForJump, 
        linesStartIndex: currentLineIndexForJump + 1 > linesFrame ? currentLineIndexForJump + 1 - linesFrame : (currentLineIndexForJump === -1 && lines.length + 1 > linesFrame ? lines.length - linesFrame + 1 : 0),
        linesSliceParam: lines.length
    }))
}

export const createCinematicsArray = (lines : any, serialNumber : any) =>
{
    var array =  [{ id: -1, value: -1, label: 'Create new cinematic' }].concat(lines.filter((element : any) => {
        if(element.lineType == LineTypes.M)
            return element
    }).map((element : any, index : any) => 
        { 
            return { id: element.animationId, value: element.animationId, label: element.animationName }
        })
    .slice().filter((v : any, i : any, a : any) => a.findIndex((t : any) => (t.label == v.label)) == i).map((element : any) => 
    { 
        return { id: element.id, value: element.id, label: element.label} 
    }));

    return array
}

const getArrayOnClickedAnswerLine = (lines : any, clickedLineInPopup : any, concreteObjectParam : any) =>
{
    return lines.filter((element : any) => (element[concreteObjectParam] == clickedLineInPopup[concreteObjectParam] && element.docId != clickedLineInPopup.docId))
}

const getArrayIfNotClickedOnAnswerLine = (lines : any, indexInArray : any, concreteObjectParam : any) =>
{
    return lines.filter((element : any) => (element[concreteObjectParam] == lines[indexInArray][concreteObjectParam] && element.docId != lines[indexInArray].docId))
}

const filteredArrayIfNotClickedOnAnswerLine = (lines : any, clickedLineInPopup : any) =>
{
    var indexInArray = lines.findIndex((element : any) => element.docId == clickedLineInPopup.docId);
      
    while(indexInArray >= 0 && lines[indexInArray].depth > 0 && (
            !lines[indexInArray].b_BranchId &&
            !lines[indexInArray].answerQuestionId &&
            !lines[indexInArray].answerTimedChoiceId &&
            !lines[indexInArray].answerVisualChoiceId)
    )
    {
        indexInArray--;
    }
    if (indexInArray >= 0)
    {
        if(lines[indexInArray].b_BranchId) //filter if not clicked on ba but ba found
            return lines.filter((element : any) => (element.b_BranchId == lines[indexInArray].b_BranchId && element.b_AnswerId != lines[indexInArray].b_AnswerId) || (element.b_BranchId == lines[indexInArray].b_BranchId && element.docId != lines[indexInArray].docId))
        else if(lines[indexInArray].answerQuestionId)
            return getArrayIfNotClickedOnAnswerLine(lines, indexInArray, 'answerQuestionId')
        else if(lines[indexInArray].answerTimedChoiceId)
            return getArrayIfNotClickedOnAnswerLine(lines, indexInArray, 'answerTimedChoiceId')
        else if(lines[indexInArray].answerVisualChoiceId)
            return getArrayIfNotClickedOnAnswerLine(lines, indexInArray, 'answerVisualChoiceId')
    }
}

export const crateDefaultGotoChoices = (lines : any, isPopupOpen : any, clickedLineInPopup : any) =>
{
    var startArray = [{id: 0, value: 0, label: 'Main'}];
    if(!isPopupOpen)
        return startArray;
    else
    {
        var filteredArray = [];

        if(clickedLineInPopup.b_BranchId)
        {
            //filter if clicked on BA
            filteredArray = lines.filter((element : any) => (element.b_BranchId == clickedLineInPopup.b_BranchId && element.b_AnswerId != clickedLineInPopup.b_AnswerId) || (element.b_BranchId == clickedLineInPopup.b_BranchId && element.docId != clickedLineInPopup.docId))
        }
        else if(clickedLineInPopup.answerQuestionId)
            filteredArray = getArrayOnClickedAnswerLine(lines, clickedLineInPopup, 'answerQuestionId');
        else if(clickedLineInPopup.answerTimedChoiceId)
            filteredArray = getArrayOnClickedAnswerLine(lines, clickedLineInPopup, 'answerTimedChoiceId');
        else if(clickedLineInPopup.answerVisualChoiceId)
            filteredArray = getArrayOnClickedAnswerLine(lines, clickedLineInPopup, 'answerVisualChoiceId');
        else
            filteredArray = filteredArrayIfNotClickedOnAnswerLine(lines, clickedLineInPopup);

        if(filteredArray && filteredArray.length > 0)
        {
            return startArray.concat(filteredArray.map((element : any, index : any) =>
            {
                return { id: element.answerNumber, value: element.answerNumber, label: 'A' + element.answerNumber}
            }))
        }
        return startArray;
    }
}

export const hidePlusOnMaximumAnswers = (lines : any, line : any, index : number) =>
{
    if(line.lineType == LineTypes.Q || line.lineType == LineTypes.T || line.lineType == LineTypes.V)
    {
        let counter = index + 1;
        let numberOfAnswers = 0;
        while(counter < lines.length)
        {
            if(lines[counter].depth > line.depth)
            {
                counter++;
            }
            else if(lines[counter].lineType == LineTypes.TA || lines[counter].lineType == LineTypes.A || lines[counter].lineType == LineTypes.VA)
            {
                numberOfAnswers++;
                counter++;
            }
            else
            {
                counter = lines.length + 1;
            }
        }
        if(numberOfAnswers == 4)
        {
            return true;
        }
        else
        {
            return false;
        }
    }
}

export const colorALine = (answerNumber : any, pickedColorType : any) => 
{
    switch(answerNumber)
    {
        case 1:
            return pickedColorType.colors.A1
        case 2:
            return pickedColorType.colors.A2
        case 3:
            return pickedColorType.colors.A3
        case 4:
            return pickedColorType.colors.A4
        default:
            return;
    }
}

export const colorLineInsideAnswer = (lines : any, index : any, pickedColorType : any, editEpisodeReadOnlyCell : any) => 
{
    if(index) {
        if(lines[index].depth > 0)
        {
            var currentLineIndex = index;
            var isThereAnswerOnDepth = false;
            while(currentLineIndex >= 0 && lines[currentLineIndex].depth > 0)
            {
                if(lines[currentLineIndex].lineType == LineTypes.A || lines[currentLineIndex].lineType == LineTypes.BA || lines[currentLineIndex].lineType == LineTypes.TA || lines[currentLineIndex].lineType == LineTypes.VA)
                    isThereAnswerOnDepth = true;
                currentLineIndex--;
            }
            if(lines[currentLineIndex].lineType == LineTypes.A || lines[currentLineIndex].lineType == LineTypes.BA || lines[currentLineIndex].lineType == LineTypes.TA || lines[currentLineIndex].lineType == LineTypes.VA)
                return colorALine(lines[currentLineIndex].answerNumber, pickedColorType)
            else if(isThereAnswerOnDepth)
            {
                var firstAnswer = index;
                while(firstAnswer >= 0 && lines[firstAnswer].depth > 0 && lines[firstAnswer].lineType != LineTypes.A && lines[firstAnswer].lineType != LineTypes.BA && lines[firstAnswer].lineType != LineTypes.TA && lines[firstAnswer].lineType != LineTypes.VA)
                    firstAnswer--;
                if(lines[firstAnswer].lineType == LineTypes.A || lines[firstAnswer].lineType == LineTypes.BA || lines[firstAnswer].lineType == LineTypes.TA || lines[firstAnswer].lineType == LineTypes.VA)
                    return colorALine(lines[firstAnswer].answerNumber, pickedColorType);
                else 
                {
                    return editEpisodeReadOnlyCell.backgroundColor
                }
            }
            else return editEpisodeReadOnlyCell.backgroundColor
        } 
        if(lines[index].depth == 0 && (lines[index].lineType == LineTypes.A || lines[index].lineType == LineTypes.BA || lines[index].lineType == LineTypes.TA || lines[index].lineType == LineTypes.VA))
            return colorALine(lines[index].answerNumber, pickedColorType);
        return editEpisodeReadOnlyCell.backgroundColor
    }
    else return editEpisodeReadOnlyCell.backgroundColor
}

export const colorLineWithComment = (line: any) => {
        if(line.commentResolved == true) return commentResolved.color
        else return commentUnresolved.color
}

const compareObjectWhenColoringAllLines = (lineParam : any, line : any) =>
{
    if(lineParam)
    {
        return true;
    }
    return false;
}

const compareObjectWhenColoringCharmingPointLines= (lineParam : any) =>
{
    if(lineParam)
    {
        return true;
    }
    return false;
}

const compareObjectActionsWhenColoringFirstLine = (line : any, index : number, lines: any, compareProp : string) =>
{
    var previousLineIndex : number = index == 0 ? 0 : index - 1;

    while(previousLineIndex > 0 && !lines[previousLineIndex][compareProp]) 
    { 
        previousLineIndex--;
    }

    if(previousLineIndex >= 0)
    {
        var previousLine = lines[previousLineIndex];
        if(previousLineIndex > 0 && previousLine[compareProp])
        {
            if(line.actionChangeFlag && line[compareProp] && line[compareProp] != previousLine[compareProp])
            {
                return true;
            }
            return false;
        }
        else
        {
            if(line.actionChangeFlag && line[compareProp])
            {
                return true;
            }
            return false;
        }
    }
    else
    {
        return false;
    }
}

const compareCharacterOufitLinesWhenColoring = (lines : any, line: any, index: number, compareProp : string) =>
{
    var previousLineIndex : number = index == 0 ? 0 : index - 1;

    while(previousLineIndex > 0 && !lines[previousLineIndex].charactersOutfit.find((element : any) => element[compareProp]) && !lines[previousLineIndex].actionChangeFlag) 
    { 
        previousLineIndex--;
    }
    
    if(previousLineIndex >= 0)
    {
        var previousLine = lines[previousLineIndex];

        if(previousLineIndex > 0 && previousLine.charactersOutfit.find((element : any) => element[compareProp]))
        {
            const results = previousLine.charactersOutfit.filter(function(obj : any) {
                return !line.charactersOutfit.some(function(obj2 : any) {
                    return obj[compareProp] == obj2[compareProp] && obj2[compareProp] && line.charactersOutfit.length <= previousLine.charactersOutfit.length
                });
            })

            if(results.length != 0 && results.find((element : any) => element[compareProp]))
                return true;
            else
                return false;
        }
        else
        {
            if(line.actionChangeFlag && line.charactersOutfit.find((element : any) => element[compareProp]))
            {
                return true;
            }
            return false;
        }
    }
    else
        return false;
}

const compareAccessoriesWhenColoring = (line: LineType) =>
{
    if(line.charactersOutfit && line.charactersOutfit?.find((element) => element.accessoryId))   
        return true;
    else
        return false;
}

export  const colorLineBasedOnActions = (lines : any, line : LineType, index : any, pickedActionColor : any, editEpisodeReadOnlyCell : any) =>
{
    switch(pickedActionColor.value)
    {
        case ActionColorTypes.Backgrounds:
            if(compareObjectActionsWhenColoringFirstLine(line, index, lines, 'backgroundId'))
                return pickedActionColor.color;
            else
            {
                if(editEpisodeReadOnlyCell)
                {
                    return editEpisodeReadOnlyCell.backgroundColor
                }
                return;
            }
        case ActionColorTypes.MusicThemes:
            if(compareObjectActionsWhenColoringFirstLine(line, index, lines, 'musicId'))
                return pickedActionColor.color;
            else
            {
                if(editEpisodeReadOnlyCell)
                {
                    return editEpisodeReadOnlyCell.backgroundColor
                }
                return;
            }
        case ActionColorTypes.CharmingPoints:
            if(compareObjectWhenColoringCharmingPointLines(line.charmingPoint))
                return pickedActionColor.color;
            else
            {
                if(editEpisodeReadOnlyCell)
                {
                    return editEpisodeReadOnlyCell.backgroundColor
                }
                return;
            }
        case ActionColorTypes.Calls:
            if(compareObjectWhenColoringAllLines(line.callPhoneEnabled, line))
                return pickedActionColor.color;
            else
            {
                if(editEpisodeReadOnlyCell)
                {
                    return editEpisodeReadOnlyCell.borderColor
                }
                return;
            }
        case ActionColorTypes.ShortSounds:
            if(compareObjectWhenColoringAllLines(line.shortSoundId, line))
                return pickedActionColor.color;
            else
            {
                if(editEpisodeReadOnlyCell)
                {
                    return editEpisodeReadOnlyCell.backgroundColor
                }
                return;
            }
        case ActionColorTypes.SMS:
            if(compareObjectWhenColoringAllLines(line.callSmsEnabled, line))
                return pickedActionColor.color;
            else
            {
                if(editEpisodeReadOnlyCell)
                {
                    return editEpisodeReadOnlyCell.backgroundColor
                }
                return;
            }
        case ActionColorTypes.ChangeClothes:
            if(compareCharacterOufitLinesWhenColoring(lines, line, index, 'clothesId'))
            {
                return pickedActionColor.color;
            }
            else
            {
                if(editEpisodeReadOnlyCell)
                {
                    return editEpisodeReadOnlyCell.backgroundColor
                }
                return;
            }
        case ActionColorTypes.ChangeHairs:
            if(compareCharacterOufitLinesWhenColoring(lines, line, index, 'hairstyleId'))
            {
                return pickedActionColor.color;
            }
            else
            {
                if(editEpisodeReadOnlyCell)
                {
                    return editEpisodeReadOnlyCell.backgroundColor
                }
                return;
            }
        case ActionColorTypes.ChangeAccessories:
            if(compareAccessoriesWhenColoring(line))
            {
                return pickedActionColor.color;
            }
            else
            {
                if(editEpisodeReadOnlyCell)
                {
                    return editEpisodeReadOnlyCell.backgroundColor
                }
                return;
            }
        default: 
            if(editEpisodeReadOnlyCell)
            {
                return editEpisodeReadOnlyCell.backgroundColor
            }
            return;
    }
}

export const showLineActions = (line : LineType) =>
{
    var actions = '';

    if(line.backgroundId && line.backgroundName)
        actions = 'Background: ' + line.backgroundName;

    if(line.musicId && line.musicName)
        actions = actions + '\n' + 'Music: ' + line.musicName;
        
    if(line.shortSoundId && line.shortSoundName)
        actions = actions + '\n' + 'Short sound: ' + line.shortSoundName;
    
    if(line.avatarEnabled)
        actions = actions + '\n' + 'Character Visibility enabled. ';    
   
    if(line.changeIdentityOn)
        actions = actions + '\n' + 'Change Identity: ' + line.changeIdentityName; 

    if(line.callPhoneEnabled && !line.callSmsEnabled)
        actions = actions + '\n' + 'Phone call enabled.';  

    if(!line.callPhoneEnabled && line.callSmsEnabled)
        actions = actions + '\n' + 'SMS enabled.';  

    if(line.callerCharacterId && line.callerCharacterName)
        actions = actions + '\n' + 'Caller Name: ' + line.callerCharacterName;

    if(line.charmingPoint)
        actions = actions + '\n' + 'Charming Point: ' + line.charmingPoint; 

    if(line.premiumChoiceText)
        actions = actions + '\n' + 'Premium Choice Text: ' + line.premiumChoiceText;

    if(line.premiumChoiceDescription)
        actions = actions + '\n' + 'Premium Choice Description: ' + line.premiumChoiceDescription;

    if(line.itemId && line.itemName)
        actions = actions + '\n' + 'Item: ' + line.itemName; 

    if(line.charactersOutfit && line.charactersOutfit?.length != 0)
    {
        actions = actions + '\n' + 'Oufit: ' + '\n';

        line.charactersOutfit?.map((element : any) => {
            if(element.characterId && element.characterName) {
                actions = actions + '_____________________________' + '\n';
                actions = actions + 'Character: ' + element.characterName + '\n';
            }

            if(element.clothesId && element.clothesName)
            {
                actions = actions + 'Clothes: ' + element.clothesName + '\n';
            }

            if(element.hairstyleId && element.hairstyleName)
            {
                actions = actions + 'Hairstyle:  ' + element.hairstyleName + '\n';
            }

            if(element.accessoryId && element.accessoryName)
            {
                actions = actions + 'Accessory:  ' + element.accessoryName + '\n';
            }
        })
    }

    return actions;
}

export const getLineOptionsClass = (line : LineType) =>
{
    if(line.charmingPoint || line.premiumChoiceText || (line.itemId && line.itemName))
    {
        return 'col-auto'
    }
    else
    {
        return 'col-auto'
    }
}

export const showMultipleLinesAddBasedOnCurrentLineType = (line : LineType) =>
{
    if(line.lineType == LineTypes.Q || line.lineType == LineTypes.V || line.lineType == LineTypes.T || line.lineType == LineTypes.B || line.lineType == LineTypes.C1)
        return false;
    else
        return true;
}

export const isDressCharacterValid = (characterParams : any, gameType : any, isMeCharacter : any, alert : any) =>
{
    if(!characterParams.characterId) {
        alert.info('Please choose a character before dressing.')
        return false;
    }
    else if(
        gameType == GameType.Lunescape && 
        isMeCharacter && 
        characterParams.changeOutfitHairstyleType == ChangeOutfitType.Custom && 
        characterParams.changeOutfitClothesType == ChangeOutfitType.Custom && 
        !characterParams.accessoryId)
    {
        alert.info('Please choose an accessory before dressing.')
        return false;
    }
    else if(
        gameType == GameType.LoveStory && 
        isMeCharacter && 
        (characterParams.changeOutfitHairstyleType == ChangeOutfitType.Custom || characterParams.changeOutfitHairstyleType == ChangeOutfitType.Custom) && 
        (!characterParams.clothesId || !characterParams.hairstyleId)
    )
    {
        alert.info('Please choose clothes/hairstyle before dressing.')
        return false;
    }
    else if(!isMeCharacter && (!characterParams.clothesId || !characterParams.hairstyleId))
    {
        alert.info('Please choose clothes and hairstyle before dressing.')
        return false;
    }
    else
        return true;
}

export const getClassesWhenInReviewStatus = (status : number) =>
{
    if(status == EpisodeStatus.InProgress || status == EpisodeStatus.CorrectionRequired) 
        return "col-auto action-div-cont"
    else 
        return "col-auto action-div-cont review-blocker" 
}

export const getClassesForCustomizationDisable = (status : number) =>
{
    if(status == EpisodeStatus.InProgress || status == EpisodeStatus.CorrectionRequired) 
        return "ml-2 mr-2 cursor"
    else 
        return "ml-2 mr-2 cursor review-blocker-diamonds" 
}

export const hideButtonBasedOnLineType = (lineType : LineType) =>
{
    if(lineType == LineTypes.L || lineType == LineTypes.C2 || lineType == LineTypes.O2 || lineType == LineTypes.O3 || lineType == LineTypes.Q)
        return true;
    else
        return false;
}

export const hideSectionBasedOnLineType = (lineType : LineType) =>
{
    if(lineType == LineTypes.O1 && lineType == LineTypes.O2 && lineType == LineTypes.O3)
        return false;
    else
        return true;
}

export const findMultipleLinesToDelete = (lines : any, index : any, lineType: LineTypes) =>
{
    let linesToDelete : any = [];
    linesToDelete.push(lines[index]);
    let counter = index + 1;
    while(counter < lines.length 
        && ((lines[counter].lineType === lineType && lines[counter].depth >= linesToDelete[0].depth) || lines[counter].depth > linesToDelete[0].depth)
        )
    {
        linesToDelete.push(lines[counter]);
        counter = counter + 1;
    }
    let mappedLinesForDelete = linesToDelete.map((x: LineType) => { return { 
        storyId: x.storyId, 
        storyTranslationId: x.storyTranslationId, 
        episodeId: x.episodeId, 
        lineId: x.docId, 
        lineType: x.lineType,
        lineNumber: x.lineNumber,
        actionChangeFlag: x.actionChangeFlag
    } });

    return {mappedLinesForDelete, counter};
}

export const findMultipleLinesToDeleteWithoutLineType = (lines : any, index : any) =>
{
    let linesToDelete : any = [];
    linesToDelete.push(lines[index]);
    let counter = index + 1;
    while(counter < lines.length && lines[counter].depth > linesToDelete[0].depth)
    {
        linesToDelete.push(lines[counter]);
        counter = counter + 1;
    }
    let mappedLinesForDelete = linesToDelete.map((x: LineType) => { return { 
        storyId: x.storyId, 
        storyTranslationId: x.storyTranslationId, 
        episodeId: x.episodeId, 
        lineId: x.docId, 
        lineType: x.lineType,
        lineNumber: x.lineNumber,
        actionChangeFlag: x.actionChangeFlag
    } });

    return {mappedLinesForDelete, counter};
}

export const showDiamondBasedOnLineType = (lineType : any) =>
{
    if([LineTypes.C2, LineTypes.A].includes(lineType))
        return false;
    else 
        return true;
}

export const addNewOLine = (dispatch : any, alert : any, selectedLineIndex : any, lines : any, line : any, index : any, inputLine : any, modalPopupBool : any, defaultLine : any, episodeName: any, serialNumber : any, linesFrame : any) => 
{
    let o3lineIndex = index + 2;
    let previousLineDocId = lines[o3lineIndex]?.docId;
    let values : any = {
        ...defaultLine,
        isDefault: false,
        lineNumber: o3lineIndex + 1,
        previousLineDocId: previousLineDocId,
        depth: 0,
        storyId: line.storyId,
        episodeId: line.episodeId,
        storyTranslationId: line.storyTranslationId,
        episodeName: episodeName,
        episodeSerialNumber: serialNumber,

        text: undefined,

        animationId: undefined,
        animationName: undefined,
        payed: undefined,
        answerNumber: undefined,
        answerQuestionId: undefined,
        questionDocId: undefined,
    
        comment: undefined,
        commentReporterName: undefined,
        commentReporterUID: undefined,
        commentAssignedToName: undefined,
        commentAssignedToUID: undefined,
        commentResolved: undefined,

        musicId: line.musicId,
        musicName: line.musicName,
        musicDescription: line.musicDescription,

        shortSoundEnabled: line.shortSoundEnabled,
        shortSoundId: line.shortSoundId,
        shortSoundName: line.shortSoundName,
        shortSoundDescription: line.shortSoundDescription ,

        itemId: line.itemId ?? undefined,
        itemName: line.itemName ?? undefined,
        itemDescription: line.itemDescription ?? undefined,
        
        avatarEnabled: line.avatarEnabled,

        changeIdentityOn: line.changeIdentityOn,
        changeIdentityName: line.changeIdentityName,

        callPhoneEnabled: line.callPhoneEnabled != null ? line.callPhoneEnabled : undefined,
        callSmsEnabled: line.callSmsEnabled != null ? line.callSmsEnabled : undefined,
        callerCharacterId: line.callerCharacterId,
        callerCharacterName: line.callerCharacterName,
        charmingPoint: line.charmingPoint != null ? line.charmingPoint : undefined,
        premiumChoiceText: line.premiumChoiceText != null ? line.premiumChoiceText : undefined,
        premiumChoiceDescription: line.premiumChoiceDescription != null ? line.premiumChoiceDescription : undefined,
        charactersOutfit: line.charactersOutfit,

        backgroundId: line.backgroundId,
        backgroundName: line.backgroundName,
        backgroundDescription: line.backgroundDescription,
        
        clothes1Name: undefined,
        clothes2Name: undefined,
        clothes3Name: undefined,
        clothes4Name: undefined,
        clothes5Name: undefined,
        clothes6Name: undefined,
        hairstyle1Name: undefined,
        hairstyle2Name: undefined,
        hairstyle3Name: undefined,
    
        clothes1Id: undefined,
        clothes2Id: undefined,
        clothes3Id: undefined,
        clothes4Id: undefined,
        clothes5Id: undefined,
        clothes6Id: undefined,
        hairstyle1Id: undefined,
        hairstyle2Id: undefined,
        hairstyle3Id: undefined
    }
    if(!inputLine && modalPopupBool.index != o3lineIndex)
    {
        let lineInPopup = values;
        dispatch(ActionCreators.setModalPopupBool({ type: 'normal', isOpen: true, isFirstTimeOpen: true, index: o3lineIndex, currentLine: lineInPopup, clickedLine: {...lineInPopup, 
            lineType: line.lineType
        } }))
    }
    else if(line.changeIdentityOn && !line.changeIdentityName)
    {
        alert.info('You must fill in the character name in Change Identity, because you have enabled it.')
    }
    else
    {
        var isChangedByAction = validateActionChange(dispatch, values, lines, lines[lines.length-1], true)
        values = {...values, actionChangeFlag: isChangedByAction}
        
        AddNewLine(values, o3lineIndex, linesFrame, selectedLineIndex, dispatch, alert, modalPopupBool.type, modalPopupBool.isOpen)
    }
}

export const getCurrentLineWhenInPopup = (modalPopupBoolAction : PopupProps, modalPopupBoolOption: PopupProps, selectedLineIndex : number, currentLine : LineType) => 
{
    if(modalPopupBoolAction.isOpen && modalPopupBoolAction.clickedLineIndex != selectedLineIndex)
    {
        return modalPopupBoolAction.line;
    }
    else if(modalPopupBoolOption.isOpen && modalPopupBoolOption.clickedLineIndex != selectedLineIndex) 
    {
        return modalPopupBoolOption.line;
    }
    else 
        return currentLine;
}

export const handleCallChange = (previousLineUpdate : boolean, sms : boolean, phoneCall : boolean, dispatch : any) =>
{
    if(previousLineUpdate)
    {
        //update funckije za line u popupu
        dispatch(ActionCreators.updatePhoneCallInPopup(phoneCall));
        dispatch(ActionCreators.updateSMSInPopup(sms));
    }
    else
    {
        dispatch(ActionCreatorsForEditEpisode.updatePhoneBool(phoneCall));
        dispatch(ActionCreatorsForEditEpisode.updateSmsBool(sms));
    }
} 

export const isPreviousLineUpdating = (modalPopupBoolAction : PopupProps, modalPopupBoolOption: PopupProps, selectedLineIndex : number) =>
{
    if(modalPopupBoolAction.isOpen && selectedLineIndex != modalPopupBoolAction.clickedLineIndex)
    {
        return true;
    }
    else if(modalPopupBoolOption.isOpen && selectedLineIndex != modalPopupBoolOption.clickedLineIndex)
    {
        return true;
    }
    else 
    {
        return false;
    }
}

export const updateLineParamsInPopup = (line : LineType, selectedLineIndex : number, dispatch : any) =>
{
    dispatch(ActionCreators.updateBackgroundInPopup({id: line.backgroundId, value: line.backgroundName, label: line.backgroundName}))
    dispatch(ActionCreators.updateBackgroundDescriptionInPopup(line.backgroundDescription))
    dispatch(ActionCreators.updateMusicThemeInPopup({id: line.musicId, value: line.musicName, label: line.musicName}))
    dispatch(ActionCreators.updateMusicThemeDescriptionInPopup(line.musicDescription))
    dispatch(ActionCreators.updateShortSoundInPopup({
        id: selectedLineIndex == -1 && !line.shortSoundEnabled ? undefined : line.shortSoundId, 
        value: selectedLineIndex == -1 && !line.shortSoundEnabled ? undefined : line.shortSoundName,
        label: selectedLineIndex == -1 && !line.shortSoundEnabled ? undefined : line.shortSoundName
    }))
    dispatch(ActionCreators.updateShortSoundDescriptionInPopup(
        selectedLineIndex == -1 && !line.shortSoundEnabled ? undefined : line.shortSoundDescription))
    dispatch(ActionCreators.updateShortSoundBoolInPopup(
        selectedLineIndex == -1 && !line.shortSoundEnabled ? false : line.shortSoundEnabled))
    dispatch(ActionCreators.updateShowItemInPopup({
        id: selectedLineIndex == -1 ? undefined : line.itemId, 
        value: selectedLineIndex == -1 ? undefined : line.itemName, 
        label: selectedLineIndex == -1 ? undefined : line.itemName
    }))
    dispatch(ActionCreators.updateShowItemDescriptionInPopup(
        selectedLineIndex == -1 ? undefined : line.itemDescription
    ))
    dispatch(ActionCreators.updateCallerNameInPopup(
        {id: line.callerCharacterId, value: line.callerCharacterName, label: line.callerCharacterName}
    ))
    dispatch(ActionCreators.updateAvatarBoolInPopup(!line.avatarEnabled && selectedLineIndex == -1 ? true : line.avatarEnabled));
    dispatch(ActionCreators.updateCharmingPointInPopup(selectedLineIndex == -1 ? undefined : line.charmingPoint))
    dispatch(ActionCreators.updatePremiumChoiceTextInPopup(selectedLineIndex == -1 ? undefined : line.premiumChoiceText))
    dispatch(ActionCreators.updatePremiumChoiceTextDescriptionInPopup(selectedLineIndex == -1 ? undefined : line.premiumChoiceDescription))
    dispatch(ActionCreators.replaceCharacterOufitInPopup(line.charactersOutfit))
    dispatch(ActionCreators.updateCharacterIdentityEnabledInPopup(selectedLineIndex == -1 ? false : line.changeIdentityOn))
    dispatch(ActionCreators.updateCharacterIdentityNameInPopup(selectedLineIndex == -1 ? false : line.changeIdentityName))
}

export const resetLineParamsInPopup = (dispatch : any) =>
{
    dispatch(ActionCreators.updateBackgroundInPopup({id: null, value: '', label: ''}))
    dispatch(ActionCreators.updateBackgroundDescriptionInPopup(''))
    dispatch(ActionCreators.updateMusicThemeInPopup({id: null, value: '', label: ''}))
    dispatch(ActionCreators.updateMusicThemeDescriptionInPopup(''))
    dispatch(ActionCreators.updateShortSoundInPopup({id: null, value: '', label: ''}))
    dispatch(ActionCreators.updateShortSoundDescriptionInPopup(''))
    dispatch(ActionCreators.updateShortSoundBoolInPopup(false))
    dispatch(ActionCreators.updateShowItemInPopup({id: null, value: '', label: ''}))
    dispatch(ActionCreators.updateShowItemDescriptionInPopup(''))
    dispatch(ActionCreators.updateCallerNameInPopup({id: null, value: '', label: ''}))
    dispatch(ActionCreators.updateAvatarBoolInPopup(false));
    dispatch(ActionCreators.updateCharmingPointInPopup(''))
    dispatch(ActionCreators.updatePremiumChoiceTextInPopup(''))
    dispatch(ActionCreators.updatePremiumChoiceTextDescriptionInPopup(''))
    dispatch(ActionCreators.replaceCharacterOufitInPopup([]))
    dispatch(ActionCreators.updateCharacterIdentityEnabledInPopup(false))
    dispatch(ActionCreators.updateCharacterIdentityNameInPopup(null))
}

export const sortCharacterArray = (array : any[]) =>
{
    var me = null;
    var narrator = null;
    var returnArray = [];
    returnArray = array?.map((element : any) => 
    {
        if(element.isMeCharacter)
            me = element;
        else if(element.isNarratorCharacter)
            narrator = element;
        else
            return element;
    }).sort((a : any, b : any) => a?.label.localeCompare(b?.label));

    if(me != null)
        returnArray.unshift(me);
    if(narrator != null)
        returnArray.unshift(narrator);

    return returnArray?.filter((element : any) => element);
}