import { CButton } from '@coreui/react';
import { CgInsertBefore } from '@meronex/icons/cg';
import React, { useState } from 'react';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { GiCutDiamond } from 'react-icons/gi';
import { RiCharacterRecognitionFill, RiCharacterRecognitionLine } from 'react-icons/ri';
import { LineType } from '../../interfaces/lineType';
import { AddNewLine } from '../../services/EpisodeServices';
import { defaultLine } from '../../store/EditEpisodeReducer';
import { ActionCreators } from '../../store/StoriesReducer';
import { LineTypes } from './Enums/LineTypes';
import { useAlert } from 'react-alert';
import { addNewOLine, colorLineBasedOnActions, findMultipleLinesToDelete, findMultipleLinesToDeleteWithoutLineType, FormatObjectForDiamond, getClassesWhenInReviewStatus, hasLineActionsChanged, hideButtonBasedOnLineType, hidePlusOnMaximumAnswers, hideSectionBasedOnLineType, showDiamondBasedOnLineType, validateActionChange, validateIfBorCLineFormatSelected } from './Lines/_Helpers';
import { SiJson } from 'react-icons/si'
import EditEpisodeActionsPopup from '../Models/EditEpisodeActionsPopup';
import { isAnyWriter } from '../Auth/AuthConfig';

const LineSidebar = ({ 
    dispatch, 
    lines, 
    line, 
    selectedLineIndex, 
    inputLine, 
    isPreviewInModal, 
    modalPopupBool, 
    setYesnomodal, 
    editEpisodePassParam, 
    index, 
    pickedActionColor, 
    episodeName, 
    serialNumber, 
    linesFrame,
    isBranchAnswer,
    linesStartIndex,
    isChangedByOptionFlag
} : any) =>
{
    const alert = useAlert();
  
    return(
    <>
        { hideSectionBasedOnLineType(line.lineType) ? 
            <div className={getClassesWhenInReviewStatus(editEpisodePassParam.episodeStatusEnum)} style={inputLine || isPreviewInModal  ? { display: 'none' } : { display: 'block', marginRight: [LineTypes.O1, LineTypes.O2, LineTypes.O3].includes(line.lineType) ? "-41px" : "-15px", backgroundColor: colorLineBasedOnActions(lines, line, index, pickedActionColor, null) ?? undefined }}>
                
                {/*ACTION ICON*/}
                { line.actionChangeFlag ? 
                  <span className="action-icon capa cursor" style={{marginRight:'5px', pointerEvents: 'all'}} ><RiCharacterRecognitionFill onClick={()=> dispatch(ActionCreators.setModalPopupBoolAction({isOpen: true, line, index}))} size={21}/></span> : 
                  <span className="no-action-icon capa cursor" style={{marginRight:'5px', pointerEvents: 'all'}}><RiCharacterRecognitionFill onClick={()=> dispatch(ActionCreators.setModalPopupBoolAction({isOpen: true, line, index}))} size={21}/></span> }

                {/* OPTION ICON */}
                { isChangedByOptionFlag ? 
                    <span className="action-icon capa cursor" style={{marginRight:'5px', pointerEvents: 'all'}} ><SiJson onClick={()=> { dispatch(ActionCreators.setModalPopupBoolOption({isOpen: true, line, index}))}} size={14} /></span> :
                    <span className="no-action-icon capa cursor" style={{marginRight:'5px', pointerEvents: 'all'}} ><SiJson onClick={()=> dispatch(ActionCreators.setModalPopupBoolOption({isOpen: true, line, index}))} size={14} /></span> }
                
                {/*DIAMOND ICON*/}
                { line.lineType == LineTypes.A && !isBranchAnswer ? 
                <span className="action-icon diamond">
                    { line.payed ? 
                        <GiCutDiamond color="#481fca" size={21} className="mr-2 cursor"
                            onClick={() => 
                                {   
                                    if(!isAnyWriter()) { alert.error('Unauthorized.'); return; }
                                    if(isBranchAnswer) return;
                                    FormatObjectForDiamond(
                                        dispatch, 
                                        index, 
                                        linesStartIndex, 
                                        lines, 
                                        { lineToUpdateId: line.docId, lineModel: {...line, payed: false} }, alert)
                                }}
                        /> 
                        :
                        <GiCutDiamond color="gray" size={21} className="mr-2 cursor"
                            onClick={() => 
                            { 
                                if(!isAnyWriter()) { alert.error('Unauthorized.'); return; }
                                if(isBranchAnswer) return;
                                FormatObjectForDiamond(
                                    dispatch, 
                                    index, 
                                    linesStartIndex,
                                    lines, 
                                    { lineToUpdateId: line.docId, lineModel: {...line, payed: true} }, alert)
                            }}
                        />
                    }
                </span> : 
                <span className="action-icon diamond" style={showDiamondBasedOnLineType(line.lineType) ? {visibility: 'hidden'} : {}}><GiCutDiamond color={line.lineType == LineTypes.C2 ? "#481fca" : "gray"} size={21} className="mr-2 cursor"></GiCutDiamond></span> }
                
                {/*INSERT BEFORE ICON*/}
                <span 
                    className="action-icon arrow-left" 
                    style={[LineTypes.O1, LineTypes.O2, LineTypes.O3, LineTypes.A, LineTypes.TA, LineTypes.VA, LineTypes.C2, LineTypes.BA].includes(line.lineType) ? {visibility: 'hidden'} : {}}>
                    <CgInsertBefore onClick={() => {
                        if(!isAnyWriter())
                        {
                            alert.error('Unauthorized.');
                        }
                        else
                        {
                            if([LineTypes.Q, LineTypes.T, LineTypes.V].includes(line.lineType))
                            {
                                let lineInPopup = {...lines[index], answerNumber: lines[index].answerNumber + 1};
                                dispatch(ActionCreators.setModalPopupBool({ type: 'above', isOpen: true, isFirstTimeOpen: true, index: index, currentLine: lineInPopup, clickedLine: lineInPopup }))
                            }
                            else if(line.lineType != LineTypes.C2 && line.lineType != LineTypes.O3)
                            {
                                let lineInPopup = {...lines[index], 
                                    //check if clicked line lineformat is B or C, if yes set it to S
                                    lineFormat: validateIfBorCLineFormatSelected(lines[index])};
                                dispatch(ActionCreators.setModalPopupBool({ type: 'above', isOpen: true, isFirstTimeOpen: true, index: index, currentLine: lineInPopup, clickedLine: lineInPopup }))
                            }
                        }
                    }} size={21} className="cursor" />
                </span>

                {/*PLUS ICON*/}
                <span 
                    className="action-icon plus" 
                    style={[LineTypes.O2, LineTypes.O3].includes(line.lineType) || line.forbidLineAdding || hidePlusOnMaximumAnswers(lines, line, index) ? {visibility: 'hidden'} : {}}>
                    <CButton onClick={(e : any) => {
                        if(!isAnyWriter())
                        {
                            alert.error('Unauthorized.');
                        }
                        else
                        {
                            if([LineTypes.Q, LineTypes.T, LineTypes.V].includes(line.lineType))
                            {
                                let answerLineInPopup = {...lines[index], answerNumber: lines[index].answerNumber + 1};
                                dispatch(ActionCreators.setModalPopupBool({ type: 'normal', isOpen: true, isFirstTimeOpen: true, index: index, currentLine: answerLineInPopup, clickedLine: answerLineInPopup }))
                            }
                            else if(line.lineType == LineTypes.O1)
                            {
                                e.preventDefault();  
                                addNewOLine(dispatch, alert, selectedLineIndex, lines, line, index, inputLine, modalPopupBool, defaultLine, episodeName, serialNumber, linesFrame);
                            }
                            else if(line.lineType != LineTypes.C2 && line.lineType != LineTypes.O3)
                            {
                                let lineInPopup = {...lines[index], 
                                    //check if clicked line lineformat is B or C, if yes set it to S
                                    lineFormat: validateIfBorCLineFormatSelected(lines[index])};
                                dispatch(ActionCreators.setModalPopupBool({ type: 'normal', isOpen: true, isFirstTimeOpen: true, index: index, currentLine: lineInPopup, clickedLine: lineInPopup }))
                            }
                        }
                    }}>
                        <AiOutlinePlusCircle size={21} className="cursor"/>
                    </CButton>
                </span>

                {/*MINUS ICON*/}
                <span className="action-icon minus"
                    style={(line.depth && line.depth > 0 && lines[index-1]?.lineType === LineTypes.C2) || [LineTypes.C2, LineTypes.O2, LineTypes.O3].includes(line.lineType) || line.payed || line.answerTimedChoiceDefault  ? {visibility: 'hidden'} : {}}>
                    <AiOutlineMinusCircle onClick={(e : any) => 
                    { 
                        if(!isAnyWriter())
                        {
                            alert.error('Unauthorized.');
                        }
                        else
                        {
                            e.stopPropagation();
                            if([LineTypes.B, LineTypes.Q, LineTypes.T, LineTypes.V].includes(line.lineType))
                            {
                                var lineTypeWhenDeleting = line.lineType == LineTypes.B ? LineTypes.BA 
                                                : line.lineType == LineTypes.Q ? LineTypes.A 
                                                : line.lineType == LineTypes.V ? LineTypes.VA 
                                                : line.lineType == LineTypes.T ? LineTypes.TA 
                                                : null;
                                if(lineTypeWhenDeleting)
                                {
                                    let foundLines = findMultipleLinesToDelete(lines, index, lineTypeWhenDeleting);
                                    setYesnomodal({boolean: true, array: foundLines.mappedLinesForDelete, nextLineDocId: lines[foundLines.counter]?.docId});
                                }
                            }
                            else if([LineTypes.C1, LineTypes.A, LineTypes.TA, LineTypes.VA].includes(line.lineType))
                            {
                                let foundLinesWithoutLineType = findMultipleLinesToDeleteWithoutLineType(lines, index);
                                setYesnomodal({boolean: true, array: foundLinesWithoutLineType.mappedLinesForDelete, nextLineDocId: lines[foundLinesWithoutLineType.counter]?.docId})
                            }
                            else if(line.lineType == LineTypes.O1)
                            {
                                let o1 = lines[index];
                                let o2 = lines[index+1];
                                let o3 = lines[index+2];
                                let olines = [o1,o2,o3].map((x: LineType) => { return { 
                                    storyId: x.storyId, 
                                    storyTranslationId: x.storyTranslationId, 
                                    episodeId: x.episodeId, 
                                    lineId: x.docId, 
                                    lineType: x.lineType,
                                    lineNumber: x.lineNumber,
                                    actionChangeFlag: x.actionChangeFlag
                                } });
                                
                                setYesnomodal({boolean: true, array: olines, nextLineDocId: lines[index+3]?.docId})
                            }
                            else
                            {
                                setYesnomodal(true);
                            }
                        }
                    }} size={21} className="cursor" />
                </span>
            </div> 
        : '' }
        
        <div className={getClassesWhenInReviewStatus(editEpisodePassParam.episodeStatusEnum)} style={inputLine && !modalPopupBool.isOpen ? { display: 'flex', flexGrow: 0.01 } : { display: 'none' }} >
            {/*ACTION ICON*/}
            <span className={hasLineActionsChanged(line, lines, lines[lines.length - 1], false) || (selectedLineIndex != -1 && line.actionChangeFlag) ? "action-icon capa cursor" : "no-action-icon capa cursor"} style={{display:'flex', pointerEvents: 'all'}}><RiCharacterRecognitionFill onClick={()=> dispatch(ActionCreators.setModalPopupBoolAction({isOpen: true, line, index: selectedLineIndex}))} size={21}/></span>
            {/* OPTION ICON */}
            <span className={isChangedByOptionFlag ? "action-icon capa cursor" : "no-action-icon capa cursor"} style={{marginLeft: '6px', pointerEvents: 'all'}}><SiJson onClick={()=> { dispatch(ActionCreators.setModalPopupBoolOption({isOpen: true, line, index: selectedLineIndex})) }} size={24} /></span>
            {/*DIAMOND ICON*/}
            <span className="action-icon diamond" style={{visibility: 'hidden'}}><GiCutDiamond color="gray" size={21} className="mr-2 cursor"></GiCutDiamond></span>
            {/*INSERT BEFORE ICON*/}
            <span className="action-icon arrow-left cursor" style={{visibility: 'hidden'}}><CgInsertBefore size={21}/></span>
            {/*PLUS ICON*/}
            <span className="action-icon plus" style={hideButtonBasedOnLineType(line.lineType) ? {visibility: 'hidden'} : {}}><CButton type="submit"><AiOutlinePlusCircle size={21} /></CButton></span>
            {/*MINUS ICON*/}
            <span className="action-icon minus"  style={{visibility: 'hidden'}}><AiOutlineMinusCircle size={21}/></span>
        </div>
    </>
    )
}

export default LineSidebar;