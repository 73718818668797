import React, { useEffect, useState } from "react";
import { CButton, CForm } from "@coreui/react";
import { ErrorMessage, Field, Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import {
  SaveCharacter,
  GetBodyTypes,
  GetSkinColors,
  GetTypesForSetupStory,
  GetAssetsForSetupStory,
} from "../../../services/SetupStoryServices";
import ReactLoading from 'react-loading';
import { customStyles } from "../../Helpers/CustomStyles";
import { selectStylesForField } from "../Lines/_Helpers";
import { useAlert } from 'react-alert';
import { useDispatch } from "react-redux";
import { ActionCreators } from "../../../store/StoriesReducer";

export const AddCharacterModalBody = ({ selectedData, data, setData, setVisible, storyId }: any) => 
{
  const dispatch = useDispatch();
  const alert = useAlert();
  const [bodyTypes, setBodyTypes] = useState<any[]>([]);
  const [skinColors, setSkinColors] = useState([]);
  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dirtySelect, setDirtySelect] = useState(false);

  const [avatarAssets, setAvatarAssets] = useState([]);
  const [characterAssets, setCharacterAssets] = useState([]);

  let addCharacterSchema = yup.object().shape({
    name: yup.string().required("You must enter name."),
  });

  const [isAssetDisabled, setAssetDisabled] = useState((selectedData.assetDocId == null || selectedData.assetDocId == '') ? false : true);

  const [characterSelectedObject, setCharacterSelectedObject] = useState({
    id: selectedData.id,
    storyId: selectedData.storyId,
    counter: selectedData.counter,
    episode: selectedData.episode,
    isNoneCharacter: selectedData.isNoneCharacter, 
    isMeCharacter: selectedData.isMeCharacter,
    isNarratorCharacter: selectedData.isNarratorCharacter,
    name: selectedData.name,
    soName: selectedData.soName,
    type: selectedData.type,
    bodyType: selectedData.bodyType,
    skinColor: selectedData.skinColor,
    description: selectedData.description,
    body: selectedData.body,
    avatar: selectedData.avatar,
    reference: selectedData.reference,
    assetDocId: selectedData.assetDocId,
    assetPath: selectedData.assetPath,
    assetName: selectedData.assetName
  });

  useEffect(() => {
    GetBodyTypes(storyId).then((result: any) => {
      setBodyTypes(
        result?.map((option: any) => {
          return { id: option.id, value: option.value, label: option.label };
        })
      );
    });

    GetSkinColors().then((result: any) => {
      setSkinColors(
        result?.map((option: any) => {
          return { id: option.id, value: option.value, label: option.label };
        })
      );
    });

    GetTypesForSetupStory(`api/SetupStory/SetupStory_GetCharacterTypes`).then(
      (result: any) => {
        setTypes(
          result?.map((option: any) => 
          {
            if(characterSelectedObject.assetDocId != null && (option.id === 0 || option.id === 3 || option.id === 4 || option.id === 5)) 
            {
              return { id: option.id, value: option.value, label: option.label, isDisabled: true };
            }  
            return { id: option.id, value: option.value, label: option.label };
          })
        );
      }
    );
  }, []);

  useEffect(() => 
  {   
    if(characterSelectedObject.type == 3 && avatarAssets.length == 0)
    {
      GetAssetsForSetupStory(`api/SetupStory/GetAvatarAssets?storyId=${storyId}`).then((result : any) => 
      {
        setAvatarAssets(result?.map((option : any) => { return { id: option.id, value: option.id, label: option.name, src: option.path } }))
        setCharacterAssets([]);
      })
    }
    else if(characterAssets.length == 0)
    {
      GetAssetsForSetupStory(`api/SetupStory/GetCharacterAssets?storyId=${storyId}`).then((result : any) => 
      {
        setCharacterAssets(result?.map((option : any) => { return { id: option.id, value: option.id, label: option.name, src: option.path } }))
        setAvatarAssets([])
      })
    }
  }, [selectedData.type, characterSelectedObject.type])

  return (
    <Formik
    enableReinitialize
      initialValues={{
        episode: characterSelectedObject.episode,
        name: characterSelectedObject.name,
        soName: characterSelectedObject.soName,
        type: characterSelectedObject.type,
        bodyType: characterSelectedObject.bodyType,
        skinColor: characterSelectedObject.skinColor,
        description: characterSelectedObject.description,
        body: characterSelectedObject.body,
        avatar: characterSelectedObject.avatar,
        reference: characterSelectedObject.reference,
        assetDocId: characterSelectedObject.assetDocId,
        assetPath: characterSelectedObject.assetPath,
        assetName: characterSelectedObject.assetName
      }}
      onSubmit={(values) => {  
        setLoading(true);
        SaveCharacter({
          ...values,
          id: characterSelectedObject.id,
          storyId: characterSelectedObject.storyId,
          isNoneCharacter: characterSelectedObject.isNoneCharacter, 
          isMeCharacter: characterSelectedObject.isMeCharacter,
          isNarratorCharacter: characterSelectedObject.isNarratorCharacter,
          counter: characterSelectedObject.counter,
          bodyType: characterSelectedObject.bodyType,
          skinColor: characterSelectedObject.skinColor,
          type: characterSelectedObject.type,
          assetDocId: characterSelectedObject.assetDocId,
          assetPath: characterSelectedObject.assetPath,
          assetName: characterSelectedObject.assetName
        }, data, setData, setVisible, setLoading, alert).then(() => {
          sessionStorage.setItem('charactersNum', (parseInt(sessionStorage.getItem('charactersNum') ?? '0') + 1).toString())
        });
      }}
      validationSchema={addCharacterSchema}
    >
      {({ dirty, isValid, handleSubmit }) => {
        return (
          <CForm onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
            { loading ? <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1000, position: 'absolute', top: '0%', left: '0%', width: '100%', height: '100%'}}>
                            <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1001, position: 'absolute', top: '45%', left: '45%'}}>
                              <ReactLoading className="mt-3 mb-5" type='spin' color='#0080FF'/>
                              </div>
                        </div> : <div></div> }
            <div className="row-style modal-row-stp-story col-sm-12 row">
              <div className="form-group-name col-sm-6 ">
                <label htmlFor="episode">Episode</label>
                <Field
                  className="form-control"
                  name="episode"
                  type="text"
                  disabled
                  autoComplete="off"
                ></Field>
              </div>
              <div className="form-group-name col-sm-6 ">
                <label htmlFor="type">Type</label>
                <Field
                  as={Select}
                  styles={selectStylesForField}
                  menuPortalTarget={document.querySelector('body')}
                  options={types}
                  value={types?.find(
                    (x: any) => x.value == characterSelectedObject.type
                  )}
                  onChange={(value: any) => {
                    setCharacterSelectedObject({
                      ...characterSelectedObject,
                      type: value.value,
                      avatar: value.value == 3 ? true : false,
                      assetDocId: value.value == 1 || value.value == 2 ? characterSelectedObject.assetDocId : null,
                      assetName: value.value == 1 || value.value == 2 ? characterSelectedObject.assetName : null,
                      assetPath: value.value == 1 || value.value == 2 ? characterSelectedObject.assetPath : null,
                    });
                    setDirtySelect(true);
                  }}
                  isOptionDisabled={(option : any) => {  return false } }
                  name="type"
                  autoComplete="off"
                  required
                ></Field>
                <ErrorMessage
                  component="p"
                  className="field-colorchange"
                  name="type"
                />
              </div>
            </div>
            <div className="row-style modal-row-stp-story col-sm-12 row">
              <div className="form-group-name col-sm-6 ">
                <label htmlFor="name">Name</label>
                <Field
                  className="form-control"
                  name="name"
                  type="text"
                  autoComplete="off"
                  required
                  disabled={characterSelectedObject.isMeCharacter == true} 
                ></Field>
                <ErrorMessage
                  component="p"
                  className="field-colorchange"
                  name="name"
                />
              </div>
              {characterSelectedObject.type === 0 ||
              characterSelectedObject.type === 2 ? (
                <div className="form-group-name col-sm-6">
                  <label htmlFor="bodyType">Body type</label>
                  <Field
                    as={Select}
                    styles={selectStylesForField}
                    menuPortalTarget={document.querySelector('body')}
                    options={bodyTypes}
                    name="bodyType"
                    value={bodyTypes?.find(
                      (x: any) => x.value == characterSelectedObject.bodyType
                    )}
                    onChange={(value: any) => {
                      setCharacterSelectedObject({
                        ...characterSelectedObject,
                        bodyType: value.value,
                      });
                      setDirtySelect(true);
                    }}
                    autoComplete="off"
                    required
                    isDisabled={characterSelectedObject.isMeCharacter == true} 
                  ></Field>
                  <ErrorMessage
                    component="p"
                    className="field-colorchange"
                    name="bodyType"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="row-style modal-row-stp-story col-sm-12 row">
              <div className="form-group-name col-sm-6 ">
                <label htmlFor="soName">SO Name</label>
                <Field
                  className="form-control"
                  name="soName"
                  type="text"
                  autoComplete="off"
                  disabled
                ></Field>
              </div>

              {characterSelectedObject.type === 0 ||
              characterSelectedObject.type === 2 ? (
                <div className="form-group-name col-sm-6">
                  <label htmlFor="skinColor">Skin Color</label>
                  <Field
                    as={Select}
                    options={skinColors}
                    styles={selectStylesForField}
                    menuPortalTarget={document.querySelector('body')}
                    name="skinColor"
                    value={skinColors?.find(
                      (x: any) => x.value == characterSelectedObject.skinColor
                    )}
                    onChange={(value: any) => {
                      setCharacterSelectedObject({
                        ...characterSelectedObject,
                        skinColor: value.value,
                      });
                      setDirtySelect(true);
                    }}
                    autoComplete="off"
                    required
                    isDisabled={characterSelectedObject.isMeCharacter == true} 
                  ></Field>
                  <ErrorMessage
                    component="p"
                    className="field-colorchange"
                    name="skinColor"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="row-style modal-row-stp-story col-sm-12 row">
              {characterSelectedObject.type === 0 ||
              characterSelectedObject.type === 2 ? (
                <div className="row-style modal-row-stp-story">
                  <div className="row-style col-sm-12 ">
                    <div className="mr-4 mt-4">
                      <span className="radio-button mr-2">
                        <Field
                          className=""
                          type="checkbox"
                          name="body"
                          autoComplete="off"
                        ></Field>
                      </span>

                      <span className="radio-label ">
                        {" "}
                        <label htmlFor="yes">Body Art</label>
                      </span>
                    </div>
                    <div className=" mt-4">
                      <span className="radio-button mr-2">
                        <Field
                          type="checkbox"
                          name="avatar"
                          autoComplete="off"
                        ></Field>
                      </span>
                      <span className="radio-label ">
                        {" "}
                        <label htmlFor="yes">Avatar</label>
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            {characterSelectedObject.type === 0 ||
            characterSelectedObject.type === 2 || characterSelectedObject.type === 3 ? (
              <div className="form-group-name col-sm-12 modal-row-stp-story mt-3">
                <label htmlFor="description">Description</label>
                <Field
                  className="form-control"
                  name="description"
                  type="text"
                  autoComplete="off"
                ></Field>
                <ErrorMessage
                  component="p"
                  className="field-colorchange"
                  name="description"
                />
              </div>
            ) : (
              ""
            )}

            {characterSelectedObject.type === 0 ||
            characterSelectedObject.type === 2 ? (
              <div className="form-group-name col-sm-12 modal-row-stp-story">
                <label htmlFor="reference">References</label>
                <Field
                  className="form-control"
                  name="reference"
                  type="text"
                  autoComplete="off"
                ></Field>
                {/*TODO Implement upload functionality*/}
                {/* <CButton
                  className="mt-2 wide"
                  style={{ float: "right" }}
                  color="primary"
                >
                  Upload
                </CButton> */}
                <ErrorMessage
                  component="p"
                  className="field-colorchange"
                  name="reference"
                />
              </div>
            ) : (
              ""
            )}

            { ![0,4,5].includes(characterSelectedObject.type) ? 
              <div className="form-group-name col-sm-12 modal-row-stp-story">
                <label htmlFor="assets" className="mt-3">Catalogue</label>
                <Field
                  as={Select}
                  options={characterSelectedObject.type == 3 ? avatarAssets : characterAssets}
                  styles={customStyles}
                  value={characterSelectedObject.type == 3 ? avatarAssets?.find(
                    (x: any) => x.value == characterSelectedObject.assetDocId
                  ) ?? '' : characterAssets?.find(
                    (x: any) => x.value == characterSelectedObject.assetDocId
                  ) ?? ''}
                  isClearable
                  onChange={(value: any) => 
                  {
                    if(value)
                    {
                      setCharacterSelectedObject({
                        ...characterSelectedObject,
                        assetDocId: value.value  ,
                        assetName: value.label,
                        assetPath: value.src ,
                        type: characterSelectedObject.type == 2 || characterSelectedObject.type == 3 ? characterSelectedObject.type : 1
                      });
                    }
                    else{
                      setCharacterSelectedObject({
                        ...characterSelectedObject,
                        assetDocId: null ,
                        assetName: null,
                        assetPath: null,
                        type: 0
                      });
                    }
                    setAssetDisabled(value != null ? true : false);
                    setDirtySelect(true);
                  }}
                  name="assets"
                  autoComplete="off"
                ></Field>
                <ErrorMessage
                  component="p"
                  className="field-colorchange"
                  name="assets"
                />
            </div> : <div/>}

            <div className="modal-buttons-container float-right">
              <CButton
                type="submit"
                disabled={!(dirty || dirtySelect) || !isValid}
                className="mr-2 wide"
                color="primary"
              >
                Save
              </CButton>
              <CButton className="mr-2 wide" color="secondary" onClick={() => setVisible(false)}>Cancel</CButton>
            </div>
          </CForm>
        );
      }}
    </Formik>
  );
};
