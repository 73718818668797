import React, { useEffect, useState } from 'react';
import { CButton } from '@coreui/react';
import Select from 'react-select';
import { GetSelectValuesForAssets, UpdateAssets } from '../../../services/ArtCatalog';
import { useDispatch } from 'react-redux';
import { ActionCreatorsForArtCatalog } from '../../../store/ArtCatalogReducer';
import { selectStylesForField } from '../Lines/_Helpers';
import { SaveChangesSessionLogger } from '../../../modules/firebaseDetailedLoggers';

export const ClothesPictureBody = ({clothesTypes, visible, setVisible, alert} : any) =>
{
    const dispatch = useDispatch();
    
    const [clothes, setClothes] = useState([]);

    useEffect(() =>
    {
       if(visible.object.clothesTag)
       {
            GetSelectValuesForAssets('api/ArtCatalog/GetClothesAttributes?clothesType='+`${visible.object?.clothesTag}`).then((res) => 
            { 
                setClothes(res);
            })
       }
    }, [visible.object.clothesTag])

    return(
        <div>
            <label>Tag</label>
            <Select
                styles={selectStylesForField}
                menuPortalTarget={document.querySelector('body')}
                options={clothesTypes} 
                className="art-cat-filters-row"
                value={clothesTypes.find((element : any) => element.value === visible.object.clothesTag)}
                onChange={(value : any) => 
                { 
                    setVisible({...visible, object: {...visible.object, clothesTag: value.label }})
                }}
            />
            <label className="mt-3">Clothes Tags</label>
            <Select 
                styles={selectStylesForField}
                menuPortalTarget={document.querySelector('body')}
                isMulti 
                options={clothes}  
                value={visible.object.clothesTagAttributes?.map((el : any, index : any) => { return { id: index, value: index, label: el }})}
                onChange={(value : any) => 
                { 
                    setVisible({...visible, object: {...visible.object, clothesTagAttributes: value?.map((el : any) => el.label) }})
                }}
            />
            <CButton 
                color="primary" 
                className="mt-5" 
                onClick={() => 
                {
                    const saveChangesSession = new SaveChangesSessionLogger();
                                    
                    saveChangesSession.asset_name = 'Clothes'
                    saveChangesSession.timestamp = new Date().toUTCString();
                    saveChangesSession.session_id = localStorage.getItem('sessionId') ?? '';
                    saveChangesSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                    saveChangesSession.user_id = localStorage.getItem('uid') ?? '';
                    
                    saveChangesSession.logEvent();
                    
                    UpdateAssets('api/ArtCatalog/Update_ClothesAssetTags', 
                        { 
                            docId: visible.object?.docId, 
                            clothesTag: visible.object?.clothesTag,
                            clothesTagAttributes: visible.object?.clothesTagAttributes
                        }, alert)
                
                    dispatch(ActionCreatorsForArtCatalog.updateClothes(
                        {
                            id: visible.object?.docId, 
                            clothesTag: visible.object?.clothesTag,
                            clothesTagAttributes: visible.object?.clothesTagAttributes
                        }
                    ))
                }}
            >Save Changes</CButton>
        </div>
    )
}