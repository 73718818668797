import React from 'react';
import { useSelector } from 'react-redux';
import { LineType } from '../../interfaces/lineType';
import { LineTypes } from './Enums/LineTypes';

import { AnswerLine, BasicLine, BranchLine, CinematicLine, CustomizationLine, CustomizationLine2, AddOutfitLine, QuestionLine, OutfitLine1, OutfitLine2, OutfitLine3, GotoMainLine} from './Lines';
import EndOfLevel from './Lines/LunescapeAdditionalLines/EndOfLevel';
import Fashion from './Lines/LunescapeAdditionalLines/Fashion';
import TimedChoice from './Lines/TimedChoice';
import TimedChoiceAnswer from './Lines/TimedChoiceAnswer';
import VisualChoice from './Lines/VisualChoice';
import VisualChoiceAnswer from './Lines/VisualChoiceAnswer';
import { getInputLineNumber, validateEpisodeStatusForDisable } from './Lines/_Helpers';


export const AddLine = ({ selectedLineIndex, currentLine, storyCharacters, animations, episodeName, serialNumber, storyId }: any) => 
{
    const { defaultLine, lines, maxLineNumber, editEpisodePassParam } = useSelector((state: any) => state.editEpisodeReducer);
    const { modalPopupBool } = useSelector((state: any) => state.storiesReducer);
    let LOinput: LineType = {...defaultLine};
    LOinput.lineNumber = getInputLineNumber([],currentLine, -1, maxLineNumber, modalPopupBool.isOpen, modalPopupBool.type)
    //remove O first time it is used on FE, use default L instead with O actions
    const isOInputUsedButSelected = selectedLineIndex === -1 && ([LineTypes.O1, LineTypes.O2, LineTypes.O3].includes(currentLine.lineType)) 
    && lines?.filter((x :any) => [LineTypes.O1, LineTypes.O2, LineTypes.O3].includes(x.lineType))?.length > 0
    if(isOInputUsedButSelected || [LineTypes.A, LineTypes.BA, LineTypes.TA, LineTypes.VA].includes(currentLine.lineType ?? 0)) {
        //LOinput = {...defaultLine};
        //LOinput.lineNumber = getInputLineNumber(currentLine, maxLineNumber, modalPopupBool.isOpen, modalPopupBool.type);//currentLine.lineNumber;

        LOinput.depth = currentLine.depth;
        LOinput.lineFormat = currentLine.lineFormat;

        LOinput.storyId = currentLine.storyId;
        LOinput.storyTranslationId = currentLine.storyTranslationId;
        LOinput.episodeId = currentLine.episodeId;
        LOinput.episodeName = currentLine.episodeName;
        LOinput.previousLineDocId = currentLine.previousLineDocId;
        LOinput.episodeSerialNumber = currentLine.episodeSerialNumber;

        LOinput.characterId = currentLine.characterId;
        LOinput.characterName = currentLine.characterName;
        LOinput.characterPath = currentLine.characterPath;
        LOinput.emotion = currentLine.emotion;

        LOinput.musicId = currentLine.musicId ?? undefined;
        LOinput.musicName = currentLine.musicName ?? undefined;
        LOinput.musicDescription = currentLine.musicDescription ?? undefined;

        LOinput.shortSoundEnabled = currentLine.shortSoundEnabled ?? false;
        LOinput.shortSoundId = currentLine.shortSoundEnabled ? currentLine.shortSoundId : undefined;
        LOinput.shortSoundName = currentLine.shortSoundEnabled? currentLine.shortSoundName : undefined;
        LOinput.shortSoundDescription = currentLine.shortSoundEnabled ? currentLine.shortSoundDescription : undefined;

        LOinput.itemId = currentLine.itemId ?? undefined;
        LOinput.itemName = currentLine.itemName ?? undefined;
        LOinput.itemDescription = currentLine.itemDescription ?? undefined;

        LOinput.avatarEnabled = currentLine.avatarEnabled ?? undefined;

        LOinput.callPhoneEnabled = currentLine.callPhoneEnabled ?? undefined;
        LOinput.callSmsEnabled = currentLine.callSmsEnabled ?? undefined;
        
        LOinput.callerCharacterId = currentLine.callerCharacterId ?? undefined;
        LOinput.callerCharacterName = currentLine.callerCharacterName ?? undefined;

        LOinput.charmingPoint = currentLine.charmingPoint ?? undefined;
        LOinput.premiumChoiceText = currentLine.premiumChoiceText ?? undefined;
        LOinput.premiumChoiceDescription = currentLine.premiumChoiceDescription ?? undefined;
        LOinput.charactersOutfit = currentLine.charactersOutfit;

        LOinput.backgroundId = currentLine.backgroundId ?? undefined;
        LOinput.backgroundName = currentLine.backgroundName ?? undefined;
        LOinput.backgroundDescription = currentLine.backgroundDescription ?? undefined;
        LOinput.text = currentLine.text ?? undefined;
    }

    return (
        <div style={validateEpisodeStatusForDisable(editEpisodePassParam.episodeStatusEnum) ? { pointerEvents: 'none', opacity: 0.7 } : {}} className="column-style mt-3 ml-1">
            {currentLine.lineType === LineTypes.L ? <BasicLine line={currentLine} inputLine={true} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber}/> :
                (selectedLineIndex !== -1 && currentLine.lineType === LineTypes.O1) ? <OutfitLine1 line={currentLine} inputLine={true}/>  :
                (selectedLineIndex !== -1 && currentLine.lineType === LineTypes.O2) ? <OutfitLine2 line={currentLine} inputLine={true}/>  :
                (selectedLineIndex !== -1 && currentLine.lineType === LineTypes.O3) ? <OutfitLine3 line={currentLine} inputLine={true}/>  :
                (isOInputUsedButSelected) ?  <BasicLine line={LOinput} inputLine={true} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber}/> :
                (currentLine.lineType === LineTypes.O1 || currentLine.lineType === LineTypes.O2 || currentLine.lineType === LineTypes.O3) ? <AddOutfitLine line={currentLine} inputLine={true} episodeName={episodeName} serialNumber={serialNumber}/> :
                    currentLine.lineType === LineTypes.Q ? <QuestionLine line={currentLine} inputLine={true} storyCharacters={storyCharacters} serialNumber={serialNumber} episodeName={episodeName}/> :
                        selectedLineIndex !== -1 && currentLine.lineType === LineTypes.A ? <AnswerLine line={currentLine} inputLine={true} serialNumber={serialNumber} episodeName={episodeName}/> :
                        selectedLineIndex === -1 && currentLine.lineType === LineTypes.A ? <BasicLine line={LOinput} inputLine={true} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber}/> :
                        currentLine.lineType === LineTypes.M ? <CinematicLine line={currentLine} inputLine={true} animations={animations} episodeName={episodeName} serialNumber={serialNumber}/> :
                            currentLine.lineType === LineTypes.C1 ? <CustomizationLine line={currentLine} inputLine={true} episodeName={episodeName} serialNumber={serialNumber} storyId={storyId}/> :
                                currentLine.lineType === LineTypes.C2 ? <CustomizationLine2 line={currentLine} inputLine={true} episodeName={episodeName} serialNumber={serialNumber} storyId={storyId}/> :
                                    currentLine.lineType === LineTypes.B ? <BranchLine line={currentLine} inputLine={true} serialNumber={serialNumber}/> :
                                    selectedLineIndex !== -1 && currentLine.lineType === LineTypes.BA ? <AnswerLine line={currentLine} inputLine={true} serialNumber={serialNumber} episodeName={episodeName} isBranchAnswer/> :
                                    selectedLineIndex === -1 && currentLine.lineType === LineTypes.BA ? <BasicLine line={LOinput} inputLine={true} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber}/> :
                                        currentLine.lineType === LineTypes.G ? <GotoMainLine line={currentLine} inputLine={true} episodeName={episodeName} serialNumber={serialNumber}/> :
                                            currentLine.lineType === LineTypes.E ? <EndOfLevel line={currentLine} inputLine={true} animations={animations} episodeName={episodeName} serialNumber={serialNumber}/> :
                                                currentLine.lineType === LineTypes.T ? <TimedChoice line={currentLine} inputLine={true} storyCharacters={storyCharacters} serialNumber={serialNumber} episodeName={episodeName}/> :
                                                    selectedLineIndex !== -1 && currentLine.lineType === LineTypes.TA ? <TimedChoiceAnswer line={currentLine} inputLine={true} serialNumber={serialNumber} episodeName={episodeName}/> :
                                                        selectedLineIndex === -1 && currentLine.lineType === LineTypes.TA ? <BasicLine line={LOinput} inputLine={true} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber}/> :
                                                            currentLine.lineType === LineTypes.V ? <VisualChoice line={currentLine} inputLine={true} storyCharacters={storyCharacters} serialNumber={serialNumber} episodeName={episodeName}/> :
                                                                selectedLineIndex !== -1 && currentLine.lineType === LineTypes.VA ? <VisualChoiceAnswer line={currentLine} inputLine={true} serialNumber={serialNumber} episodeName={episodeName}/> :
                                                                selectedLineIndex === -1 && currentLine.lineType === LineTypes.VA ? <BasicLine line={LOinput} inputLine={true} storyCharacters={storyCharacters} episodeName={episodeName} serialNumber={serialNumber}/> :
                                                                    currentLine.lineType === LineTypes.F ? <Fashion line={currentLine} inputLine={true} episodeName={episodeName} serialNumber={serialNumber} storyId={storyId}/> :
                                                <div />
            }
        </div>
    )
}

export default AddLine;