import React, { useEffect, useState } from 'react';
import { CButton } from '@coreui/react';
import Select from 'react-select';
import { GetSelectValuesForAssets, UpdateAssets } from '../../../services/ArtCatalog';
import { useDispatch } from 'react-redux';
import { ActionCreatorsForArtCatalog } from '../../../store/ArtCatalogReducer';
import { selectStylesForField } from '../Lines/_Helpers';
import { SaveChangesSessionLogger } from '../../../modules/firebaseDetailedLoggers';

export const CinematicsPictureBody = ({visible, setVisible, alert} : any) =>
{
    const dispatch = useDispatch();

    const [actionTags, setActionTags] = useState([]);
    const [locationTags, setLocationTags] = useState([]);
    const [plotTags, setPlotTags] = useState([]);

    useEffect(() => 
    {
        GetSelectValuesForAssets('api/ArtCatalog/GetCinematicLocations').then((response) => 
        { 
            setLocationTags(response);
        })
        GetSelectValuesForAssets('api/ArtCatalog/GetCinematicActorObject').then((response) => 
        { 
            setActionTags(response);
        })
        GetSelectValuesForAssets('api/ArtCatalog/GetCinematicPlots').then((response) => 
        { 
            setPlotTags(response)
        })
    }, [])

    return(
        <div>
            <label>Object Tags</label>
            <Select 
                styles={selectStylesForField}
                menuPortalTarget={document.querySelector('body')}
                isMulti 
                className="art-cat-filters-row"
                options={actionTags} 
                value={visible.object.cinematicActionObjectTags?.map((el : any, index : any) => { return { id: index, value: index, label: el }})}
                onChange={(value : any) => 
                { 
                    setVisible({...visible, object: {...visible.object, cinematicActionObjectTags: value?.map((el : any) => el.label) }})
                }}
            />
            <label className="mt-3">Location Tags</label>
            <Select 
                styles={selectStylesForField}
                menuPortalTarget={document.querySelector('body')}
                isMulti 
                options={locationTags} 
                className="art-cat-filters-row"
                value={visible.object.cinematicLocationTags?.map((el : any, index : any) => { return { id: index, value: index, label: el }})}
                onChange={(value : any) => 
                { 
                    setVisible({...visible, object: {...visible.object, cinematicLocationTags: value?.map((el : any) => el.label) }})
                }}
            />
            <label className="mt-3">Plot Tags</label>
            <Select 
                styles={selectStylesForField}
                menuPortalTarget={document.querySelector('body')}
                isMulti 
                options={plotTags} 
                value={visible.object.cinematicPlotTags?.map((el : any, index : any) => { return { id: index, value: index, label: el }})}
                onChange={(value : any) => 
                { 
                    setVisible({...visible, object: {...visible.object, cinematicPlotTags: value?.map((el : any) => el.label)}})
                }}
            />
            <CButton 
                color="primary" 
                className="mt-5 center"
                onClick={() => 
                {
                    const saveChangesSession = new SaveChangesSessionLogger();
                                    
                    saveChangesSession.asset_name = 'Cinematic'
                    saveChangesSession.timestamp = new Date().toUTCString();
                    saveChangesSession.session_id = localStorage.getItem('sessionId') ?? '';
                    saveChangesSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                    saveChangesSession.user_id = localStorage.getItem('uid') ?? '';
                    
                    saveChangesSession.logEvent();
                    
                    UpdateAssets('api/ArtCatalog/Update_CinematicAssetTags', 
                        { 
                            docId: visible.object?.docId, 
                            locationTags: visible.object?.cinematicLocationTags,
                            actionObjectTags: visible.object?.cinematicActionObjectTags,
                            plotTags: visible.object?.cinematicPlotTags,
                        }, alert)
                    dispatch(ActionCreatorsForArtCatalog.updateCinematics(
                        {
                            id: visible.object?.docId, 
                            locationTags: visible.object?.cinematicLocationTags, 
                            actionObjectTags: visible.object?.cinematicActionObjectTags, 
                            plotTags: visible.object?.cinematicPlotTags
                        }))
                }}
            >Save Changes</CButton>
        </div> 
    )
}