import { CButton, CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTooltip } from '@coreui/react';
import React, { useEffect, useState } from 'react'
import { MdGTranslate } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { GetEpisodesForTranslationsSelect } from '../../services/EpisodeServices';
import { GetLanguages } from '../../services/LanguageServices';
import { GetAllStoriesForTranslationsSelect, GetStoryDetailsForSetupStory } from '../../services/StoriesServices';
import { GetAppendix, GetEpisodeTranslations, GetPossibleStatusesForTranslate, GetTranslationLanguages, GetTranslationsLines } from '../../services/TranslationsServices';
import { handleNameConvert } from '../Helpers/NameConvert';
import { useAlert } from 'react-alert';
import TranslationsHeader from '../Common/Translations/TranslationsHeader';
import ScriptBody from '../Common/Translations/ScriptBody';
import ReactLoading from 'react-loading';
import AppendixBody from '../Common/Translations/AppendixBody';
import { GetAnimations, GetEmotions, GetLineFormats, GetLineTypes, GetStoryCharacters } from '../../services/LineServices';
import { GetClothingCombinations, GetSkinColors } from '../../services/SetupStoryServices';
import { ActionCreatorsForTranslations } from '../../store/TranslationsReducer';
import { EpisodeStatus } from '../Common/Enums/EpisodeStatus';
import { ProofreadSessionLogger, TranslationSessionLogger } from '../../modules/firebaseAnalyticsLoggers';
import { ScriptAppendixTabSessionLogger } from '../../modules/firebaseDetailedLoggers';
import { IoArrowBackCircleSharp } from 'react-icons/io5';


export const TranslationsBody = () =>
{    
    const [loadingForScript, setLoadingForScript] = useState(false);
    const [loadingForAppendix, setLoadingForAppendix] = useState(false);

    const dispatch = useDispatch();
    const alert = useAlert();
    const history = useHistory();

    const { storyDetails } = useSelector((state: any) => state.storiesReducer);
    
    const currentTranslationStatus =  useSelector((state: any) => state.translationsReducer.currentTranslationStatus);
    const serbianTranslate = useSelector((state: any) => state.translationsReducer.serbianTranslate);
    const englishTranslate = useSelector((state: any) => state.translationsReducer.englishTranslate);
    const otherLanguageTranslate = useSelector((state: any) => state.translationsReducer.otherLanguageTranslate);

    const [languageData, setLanguageData] = useState([]);

    const { storyId, serialNumber, language, nativeLanguage } : any = useParams();

    const [stories, setStories] = useState<any[]>([]);
    const [episodes, setEpisodes] = useState<any[]>([]);

    const [chosenStory, setChosenStory] = useState<any>({}) 
    const [chosenEpisode, setChosenEpisode] = useState<any>({}) 

    //tabs state
    const [activeKey, setActiveKey] = useState(1);    
    
    useEffect(() =>
    { 
        setChosenStory(stories.find(element => element.id == storyId));
        setChosenEpisode(episodes.find(element => element.serialNumber == serialNumber))
    }, [storyId, serialNumber, language, stories, episodes])

    useEffect(() => 
    { 
        GetAllStoriesForTranslationsSelect(alert).then((response) => { setStories(response); })
        GetEpisodesForTranslationsSelect(storyId, alert).then((response) => { setEpisodes(response); })        
    }, [])
 
    useEffect(() => {
        async function initialStateFunction() {
            const lineTypes = await GetLineTypes(storyId, alert);
            const lineFormats = await GetLineFormats(storyId, alert);
            const storyCharacters = await GetStoryCharacters(storyId, alert);
            const emotions = await GetEmotions(alert);
            const animations = await GetAnimations(storyId, alert);
            const skinColorsPromise = await GetSkinColors();
            const clothingCombinations = await GetClothingCombinations();

            let lineTypesPromise = lineTypes?.map((lineType: any) => {
                return ({ id: lineType.id, value: lineType.id, label: lineType.label })
            })

            let lineFormatsPromise = lineFormats?.map((lineFormat: any) => {
                return ({ id: lineFormat.id, value: lineFormat.id, label: lineFormat.label })
            })

            let storyCharactersPromise = storyCharacters?.map((storyCharacter: any) => {
                return ({ id: storyCharacter.id, value: storyCharacter.id, label: storyCharacter.name, path: storyCharacter.path, changeOutfitDisabled: storyCharacter.changeOutfitDisabled})
            })

            let emotionsPromise = emotions?.map((emotion: any) => {
                return ({ id: emotion.id, value: emotion.id, label: emotion.label })
            })

            let animationsPromise = animations?.map((animation: any) => {
                return ({ id: animation.id, value: animation.id, label: animation.name })
            })

            const clothingCombinationsPromise = clothingCombinations?.map((comb : any) => {
                return ({ id: comb.id, value: comb.id, label: comb.label })
            })

            return { lineTypesPromise, lineFormatsPromise, storyCharactersPromise, emotionsPromise, animationsPromise, skinColorsPromise, clothingCombinationsPromise };
        }

        if(!loadingForScript)
        {
            initialStateFunction().then((response: any) => 
            { 
                dispatch(ActionCreatorsForTranslations.setLineTypes(response.lineTypesPromise))
                dispatch(ActionCreatorsForTranslations.setLineFormats(response.lineFormatsPromise))
                dispatch(ActionCreatorsForTranslations.setCharacters(response.storyCharactersPromise))
                dispatch(ActionCreatorsForTranslations.setEmotions(response.emotionsPromise))
                dispatch(ActionCreatorsForTranslations.setSkinColors(response.skinColorsPromise))
                dispatch(ActionCreatorsForTranslations.setAnimations(response.animationsPromise))
                dispatch(ActionCreatorsForTranslations.setClothingCombination(response.clothingCombinationsPromise))
            });
        }
    }, [serialNumber])
    
    useEffect(() => {
        if(chosenStory && chosenStory.id && chosenStory.value)
            GetStoryDetailsForSetupStory(dispatch, chosenStory.id, chosenStory.value);
    }, [chosenStory])

    useEffect(() =>
    {
        if(activeKey == 1)
        {
            setLoadingForScript(true);
            GetTranslationsLines(storyId, serialNumber, language, dispatch).then(() => 
            { 
                GetPossibleStatusesForTranslate(storyId, serialNumber, language, dispatch);
                
                GetTranslationLanguages(storyId, serialNumber, alert).then((response) => 
                { 
                    handleNameConvert(response);
        
                    setLanguageData(response);
                }); 
                setLoadingForScript(false);
             });
        }
        else if(activeKey == 2)
        {
            setLoadingForAppendix(true);
            GetAppendix(storyId, serialNumber, language, alert, dispatch).then(() => 
            { 
                GetPossibleStatusesForTranslate(storyId, serialNumber, language, dispatch);
                
                GetTranslationLanguages(storyId, serialNumber, alert).then((response) => 
                { 
                    handleNameConvert(response);
        
                    setLanguageData(response);
                }); 
                setLoadingForAppendix(false); 
            });
        }
    }, [storyId, serialNumber, language, nativeLanguage, activeKey])

    let proofReadSession : any = null;
    let translationSession : any = null;

    const logTranslationSession = () =>
    {
        translationSession.activity_end_timestamp = new Date().toUTCString();

        translationSession.logEvent();

        translationSession.activity_start_timestamp = translationSession.activity_end_timestamp;
    }

    const logProffreadSession = () =>
    {
        proofReadSession.activity_end_timestamp = new Date().toUTCString();

        proofReadSession.logEvent();

        proofReadSession.activity_start_timestamp = proofReadSession.activity_end_timestamp;
    }

    useEffect(() => 
    {
        if(chosenStory && Object.getOwnPropertyNames(chosenStory).length >= 1 && chosenEpisode && Object.getOwnPropertyNames(chosenEpisode).length >= 1 && currentTranslationStatus && currentTranslationStatus === EpisodeStatus.ReadyForTranslation && localStorage.getItem('role=translator'))
        {
            translationSession = new TranslationSessionLogger();

            translationSession.activity_start_timestamp = new Date().toUTCString();
            translationSession.story_name = chosenStory.label;
            translationSession.logged_in_user_name = localStorage.getItem('user') ?? '';
            translationSession.user_id = localStorage.getItem('uid') ?? '';
            translationSession.episode_number = chosenEpisode.serialNumber;
            translationSession.session_id = localStorage.getItem('sessionId') ?? '';
            translationSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');

            const timer = setInterval(() => {
                logTranslationSession();
            }, translationSession.timerValue)

            return () => { clearInterval(timer) }
        }
        else if(chosenStory && Object.getOwnPropertyNames(chosenStory).length >= 1 && chosenEpisode && Object.getOwnPropertyNames(chosenEpisode).length >= 1 && currentTranslationStatus && currentTranslationStatus === EpisodeStatus.Translated && localStorage.getItem('role=translatorLead'))
        {
            proofReadSession = new ProofreadSessionLogger();
            
            proofReadSession.activity_start_timestamp = new Date().toUTCString();
            proofReadSession.story_name = chosenStory.label;
            proofReadSession.logged_in_user_name = localStorage.getItem('user') ?? '';
            proofReadSession.user_id = localStorage.getItem('uid') ?? '';
            proofReadSession.episode_number = chosenEpisode.serialNumber;
            proofReadSession.session_id = localStorage.getItem('sessionId') ?? '';
            proofReadSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');

            const timer = setInterval(() => {
                logProffreadSession();
            }, proofReadSession.timerValue)
            
            return () => { clearInterval(timer) }
        }
    }, [chosenStory, chosenEpisode, currentTranslationStatus])

    return(
        <div>
            <div className="column-style mt-3"> 
                <div className="table-width dashboard translation-header-container">
                    <TranslationsHeader 
                        stories={stories} 
                        episodes={episodes} 
                        chosenStory={chosenStory} 
                        chosenEpisode={chosenEpisode} 
                        setStories={setStories}
                        setEpisodes={setEpisodes}
                        setChosenStory={setChosenStory}
                        setChosenEpisode={setChosenEpisode}
                        serialNumber={serialNumber}
                        language={language}
                        loadingForScript={loadingForScript}
                        setLoadingForScript={setLoadingForScript}
                        loadingForAppendix={loadingForAppendix}
                        setLoadingForAppendix={setLoadingForAppendix}
                        languageData={languageData}
                        setLanguageData={setLanguageData}
                        nativeLanguage={nativeLanguage}
                        serbianTranslate={serbianTranslate}
                        englishTranslate={englishTranslate}
                        otherLanguageTranslate={otherLanguageTranslate}
                    />
                </div>

                <CNav variant="tabs" className="table-width dashboard setup-story-nav-links-tabs">
                    <CTooltip content="Go back to page with languages.">
                        <CButton onClick={() => { 
                            if(chosenStory && chosenStory.id && chosenStory.value)
                                history.push(`/translations-translate/&storyId=${chosenStory.id}&storyTransId=${chosenStory.value}&episodeId=${chosenEpisode.value}`)
                            else
                                alert.info('Wait until the script load finishes.')
                        }}><IoArrowBackCircleSharp size={24}/></CButton>
                    </CTooltip>
                    <CNavItem>
                        <CNavLink disabled={loadingForAppendix} className="nav-link-art-catalog" active={activeKey === 1} 
                        onClick={() => 
                        { 
                            const scriptAppendixTabSession = new ScriptAppendixTabSessionLogger();
                            
                            scriptAppendixTabSession.tab_name = 'Script'
                            scriptAppendixTabSession.timestamp = new Date().toUTCString();
                            scriptAppendixTabSession.session_id = localStorage.getItem('sessionId') ?? '';
                            scriptAppendixTabSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                            scriptAppendixTabSession.user_id = localStorage.getItem('uid') ?? '';

                            scriptAppendixTabSession.logEvent();

                            setActiveKey(1); 
                        }}>Script</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink disabled={loadingForScript} className="nav-link-art-catalog" active={activeKey === 2}
                        onClick={() => 
                        { 
                            const scriptAppendixTabSession = new ScriptAppendixTabSessionLogger();
                            
                            scriptAppendixTabSession.tab_name = 'Appendix'
                            scriptAppendixTabSession.timestamp = new Date().toUTCString();
                            scriptAppendixTabSession.session_id = localStorage.getItem('sessionId') ?? '';
                            scriptAppendixTabSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                            scriptAppendixTabSession.user_id = localStorage.getItem('uid') ?? '';

                            scriptAppendixTabSession.logEvent();

                            setActiveKey(2); 
                        }}>Appendix</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink className="nav-link-art-catalog" active={activeKey === 3}
                        onClick={() => setActiveKey(3) }>Translation Notes</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent className="table-width dashboard">
                    <CTabPane className="tab-pan-art-catalog" active={activeKey === 1}>
                        { loadingForScript ?
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <ReactLoading height="75px" width="75px" className="mt-3 mb-5" type='spin' color='#0080FF'/>
                        </div> : 
                        <ScriptBody 
                            firstLanguage=
                            {
                                nativeLanguage == 0 ? serbianTranslate 
                                :  englishTranslate
                            }
                            secondLanguage=
                            {
                                nativeLanguage == 1 ? serbianTranslate 
                                : englishTranslate
                            }
                            thirdLanguage={otherLanguageTranslate}
                            storyId={storyId} 
                            serialNumber={serialNumber} 
                            language={language}
                            nativeLanguage={nativeLanguage}
                            loadingForScript={loadingForScript}
                        />
                        }
                    </CTabPane>
                    <CTabPane className="tab-pan-art-catalog" active={activeKey === 2}>
                        { loadingForAppendix ? 
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <ReactLoading height="75px" width="75px" className="mt-3 mb-5" type='spin' color='#0080FF'/>
                        </div> : 
                        <AppendixBody 
                            storyId={storyId}
                            serialNumber={serialNumber}
                            language={language}
                            nativeLanguage={nativeLanguage}
                            languageData={languageData}
                        />}
                    </CTabPane>
                    <CTabPane className="tab-pan-art-catalog" active={activeKey === 3}>
                        <div style={{whiteSpace: 'pre-line'}}>{storyDetails?.translationNotes}</div>
                    </CTabPane>
                </CTabContent>
            </div>
        </div>
        )
}

export default TranslationsBody;