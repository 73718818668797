import axios from 'axios';

const defaultOptions = {
    baseURL: '',
};

// Create instance
let instance = axios.create(defaultOptions);

// Set the AUTH token for any request
instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    config.headers.UID = token ? `${localStorage.getItem('uid')}` : '';
    return config;
});

export const axiosInstance = instance;