import React, { useState } from 'react';
import { CButton, CCard, CCardBody, CInput, CLabel } from '@coreui/react';
import { useEffect } from 'react';
import { GetAllStoriesForHeader, GetEpisodesForHeader } from '../../../services/StoriesServices';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorsForArtCatalog } from '../../../store/ArtCatalogReducer';
import { GetAssets, GetEpisodesForArtCatalog, GetSelectValuesForAssets } from '../../../services/ArtCatalog';
import { ApplyFiltersSessionLogger } from '../../../modules/firebaseDetailedLoggers';

export const Audio = ({setPage, data, activeKey} : any) =>
{
    const dispatch = useDispatch();
    
    const [searchText, setSearchText] = useState('');

    const { stories, episodes } = useSelector((state: any) => state.artCatalogReducer);

    const types = useSelector((state: any) => state.artCatalogReducer.audioTypes);
    const tags = useSelector((state: any) => state.artCatalogReducer.audioTags);
    
    const [chosenObject, setChosenObject] = useState({ chosenStory: null as any, chosenEpisode: null as any, chosenTag: null as any, chosenType: null as any })

    useEffect(() => 
    {
        
        GetSelectValuesForAssets('api/ArtCatalog/GetAudioTypes').then((response) => 
        { 
            dispatch(ActionCreatorsForArtCatalog.setAudioTypes(response));
            if(response?.length != 0)
            {
                GetSelectValuesForAssets('api/ArtCatalog/GetAudioTags?audioType='+`${response[0].value}`).then((res) => 
                { 
                    dispatch(ActionCreatorsForArtCatalog.setAudioTags(response));
                })
            }
        })
    }, [])
 
    useEffect(() => 
    {
        const timeOutId = setTimeout(() => 
        {
            let filteredLines = data?.map((l: any, i: number) => {
                return {...l, lineIndex: i}
            }).filter((l: any) => 
            {
                if(searchText && l.name?.toLowerCase().includes(searchText.toLowerCase()))
                {
                    return l;
                }
            })
            if(searchText) 
            {
                dispatch(ActionCreatorsForArtCatalog.setAudio(filteredLines)) 
                setPage(0);
            }
            else
            {
                dispatch(ActionCreatorsForArtCatalog.setLoadingImages(true));
                GetAssets('api/ArtCatalog/GetAssets_Audios', { storyId: null, episodeId: null, type: null, shortSoundTag: null, musicThemeTag: null, searchValue: null }).then((response) => 
                { 
                    dispatch(ActionCreatorsForArtCatalog.setAudio(response)) 
                })
            }
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [searchText])
    
    useEffect(() => 
    { 
        if(data.length === 0)
            setChosenObject({ chosenStory: null, chosenEpisode: null, chosenTag: null, chosenType: null }) 
    }, [activeKey])

    return(<div className=" mt-3 form-filter-top-art-catalog">
        <div className="row-style art-cat-filters-row" style={{width: '1500px'}}>
            <div className="form-group-name col-sm-2">
                <CLabel htmlFor="story">Find</CLabel>
                <CInput
                    className="form-control"
                    name="episode"
                    placeholder="Search for a sounds"
                    value={searchText}
                    onChange={(e : any) => setSearchText(e.target.value) }
                    type="text"
                    autoComplete="off"
                    required>
                </CInput>
            </div>
            <div className="form-group-name col-sm-2 ">
                <CLabel htmlFor="story">Story</CLabel>
                <Select
                    options={stories}
                    value={chosenObject.chosenStory}
                    isClearable
                    onChange={(value : any) => 
                    { 
                        if(value != null)
                        {
                            GetEpisodesForArtCatalog(value.id, value.value).then((response) => 
                            { 
                                dispatch(ActionCreatorsForArtCatalog.setEpisodes(response));
                                setChosenObject({...chosenObject, chosenStory: value, chosenEpisode: null});
                            });
                        }
                        else
                        {
                            setChosenObject({...chosenObject, chosenStory: null, chosenEpisode: null});
                        }
                    }}
                    as="select"
                    autoComplete="off"
                    placeholder="Choose a story..."
                    required >
                </Select>
            </div>
            <div className="form-group-name col-sm-2 ml-1">
                <CLabel htmlFor="episode">Episode</CLabel>
                <Select
                    options={episodes}
                    value={chosenObject.chosenEpisode}
                    isClearable
                    onChange={(value : any) => 
                    { 
                        setChosenObject({...chosenObject, chosenEpisode: value});
                    }}
                    name="episode"
                    as="select"
                    autoComplete="off"
                    placeholder="Enter episode..."
                    required>
                </Select>
            </div>
            <div className="form-group-name col-sm-2 ml-1">
                <CLabel htmlFor="episode">Type</CLabel>
                <Select
                    options={types}
                    value={chosenObject.chosenType}
                    isClearable
                    onChange={(value : any) => 
                    { 
                        setChosenObject({...chosenObject, chosenType: value, chosenTag: null});
                        if(value != null)
                        {
                            GetSelectValuesForAssets('api/ArtCatalog/GetAudioTags?audioType='+`${value.value}`).then((res) => 
                            { 
                                dispatch(ActionCreatorsForArtCatalog.setAudioTags(res));
                            })
                        }
                    }}
                    name="episode"
                    as="select"
                    autoComplete="off"
                    required>
                </Select>
            </div>
            <div className="form-group-name col-sm-2 ml-1">
                <CLabel htmlFor="episode">Tag</CLabel>
                <Select
                    options={tags}
                    value={chosenObject.chosenTag}
                    isClearable
                    onChange={(value : any) => 
                    { 
                        setChosenObject({...chosenObject, chosenTag: value});
                        //api call assets
                    }}
                    name="episode"
                    as="select"
                    autoComplete="off"
                    required>
                </Select>
            </div>
            <CButton className="btn-primary-sm wide btn-fin-review-btn float-right" onClick={() => 
            {
                dispatch(ActionCreatorsForArtCatalog.setLoadingImages(true));

                const applyFiltersSession = new ApplyFiltersSessionLogger();

                applyFiltersSession.asset_name = 'Audio';
                applyFiltersSession.query_string = searchText ?? '';
                applyFiltersSession.story_name = chosenObject?.chosenStory?.label ?? '';
                applyFiltersSession.episode_name = chosenObject?.chosenEpisode?.label ?? '';
                applyFiltersSession.type = chosenObject?.chosenType?.label ?? '';
                applyFiltersSession.tag = chosenObject?.chosenTag?.label ?? '';
                applyFiltersSession.timestamp = new Date().toUTCString();
                applyFiltersSession.session_id = localStorage.getItem('sessionId') ?? '';
                applyFiltersSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                applyFiltersSession.user_id = localStorage.getItem('uid') ?? '';
                
                applyFiltersSession.logEvent();

                GetAssets('api/ArtCatalog/GetAssets_Audios', 
                { 
                    storyId: chosenObject.chosenStory != null ? chosenObject.chosenStory.value : null, 
                    episodeId: chosenObject.chosenEpisode != null ? chosenObject.chosenEpisode.value : null, 
                    type: chosenObject.chosenType != null ? chosenObject.chosenType.value : null, 
                    shortSoundTag: chosenObject.chosenTag != null && chosenObject.chosenType != null && chosenObject.chosenType.value === 1 ? chosenObject.chosenTag.value : null,
                    musicThemeTag: chosenObject.chosenTag != null && chosenObject.chosenType != null && chosenObject.chosenType.value === 2 ? chosenObject.chosenTag.value : null,
                    searchValue: searchText ?? null
                })
                .then((response) => 
                    { 
                        dispatch(ActionCreatorsForArtCatalog.setAudio(response)) 
                    })
            }}>Apply filters</CButton>
        </div>
    </div>
)
}

export default Audio;