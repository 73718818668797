import React, { useEffect, useState } from 'react';
import { CButton, CLabel, CForm, CInput, CTooltip } from '@coreui/react';
import {AiOutlineArrowDown, AiOutlineArrowUp, AiOutlineComment } from 'react-icons/ai';
import { isOnlyReviewer, isReviewer, isWriter, isWriterLead } from '../Auth/AuthConfig';
import { useAlert } from 'react-alert';
import * as yup from 'yup';
import { BsChevronCompactDown } from 'react-icons/bs';
//project imports
import { GetAllWritters, GetCommentDropDownUsers } from '../../services/LineServices';
import { Field, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { SelectFieldForComment } from '../Common/SelectField';
import { ResolveComment, SaveComment } from '../../services/EpisodeServices';
import { ActionCreatorsForEditEpisode } from '../../store/EditEpisodeReducer';
import { validateEpisodeCommentStatusForDisable, validateEpisodeStatusForDisable } from '../Common/Lines/_Helpers';

let commentSchema = yup.object().shape(
{
    comment: yup.string(),
    resolved: yup.boolean()
});

export const EditEpisodeComment = ({storyId, storyTransId, episodeId, episode} : any) => 
{
    const alert = useAlert();
    const dispatch = useDispatch();
    const { currentLine, selectedLineIndex, linesStartIndex, lines, linesFrame } = useSelector((state: any) => state.editEpisodeReducer);

    const [visible, setVisible] = useState(false);

    const [writters, setAllWritters] = useState([]);

    useEffect(() => 
    {
        GetCommentDropDownUsers(storyId, alert).then((response) => { setAllWritters(response) });
    }, [])

    return (
    <div>
        <div className="page-title edit-episode shadow cursor"  onClick={() => setVisible(!visible)}><AiOutlineComment/>&nbsp;Comments&nbsp;
                <span className="comment-unresolved-text">({lines?.filter((item: any) => item.comment && !item.commentResolved)?.length} unresolved out of {lines?.filter((item: any) => item.comment)?.length})</span> <span className="arrow-down" ><BsChevronCompactDown /> </span>  </div>
        { visible ? <div className="register-signin-left-panel col-sm-12 rounded shadow edit-episode comments" style={selectedLineIndex === -1 ? { pointerEvents: 'none', opacity: 0.6 } : {}}>
        <fieldset style={validateEpisodeCommentStatusForDisable(episode.episodeStatusEnum) ? 
        { 
            pointerEvents: 'none', 
            opacity: 0.7,
            border: '5px solid rgba(0, 0, 154, 0.5)',
            borderStyle: 'ridge',  
            boxSizing: 'border-box',
            textAlign: 'center'
        } : {}} className="register-signin-left-panel col-sm-12 rounded shadow edit-episode scrollable-body">  
        {validateEpisodeCommentStatusForDisable(episode.episodeStatusEnum) ? <legend className="legend-body nt-info rounded shadow">Adding and editing comments is disabled due to the current status of the episode.</legend> : '' }<Formik enableReinitialize 
            initialValues={{ 
                comment: selectedLineIndex === -1 ? '' : currentLine.comment ?? '', 
                reporter: currentLine.commentReporterName ?? localStorage.getItem('user'), 
                assignedTo: selectedLineIndex === -1 ? null : currentLine.commentAssignedToName ?? null
            }}
            onSubmit={(values : any) => 
            {
                if(values.assignedTo || (currentLine.commentAssignedToName && currentLine.commentAssignedToUID))
                {
                    delete values.reporter;

                    values = {...values,
                        resolved: false,
                        storyId: storyId,
                        storyTranslationId: storyTransId,
                        episodeId: episodeId,
                        lineId: currentLine.docId, 

                        assignedToName:  values.assignedTo?.value ?? currentLine.commentAssignedToName,
                        assignedToUID: values.assignedTo?.id ?? currentLine.commentAssignedToUID,

                        reporterName: isWriterLead() || isReviewer() ? localStorage.getItem('user') : '',
                        reporterUID: isWriterLead() || isReviewer() ? localStorage.getItem('uid') : '',
                    }

                    delete values.assignedTo;

                    SaveComment(values, alert, dispatch);
                }
                else
                {
                    alert.error('You must select writter you want to assign comment to.')
                }
            }}
            validationSchema={commentSchema}>
        {({ dirty, isValid, handleSubmit }) => 
        {
            return(
                
                <CForm onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                    <div className="column-style mt-3 ">
                        <div className="page-title-container">
                            <div className="row-style">
                                <div className="form-group-name col-sm-6 text-comm-edit-stry">
                                    <Field
                                        className="form-control"
                                        placeholder="Add comment..."
                                        name="comment"
                                        type="text"
                                        as="textarea"
                                        autoComplete="off"
                                        required>
                                    </Field>
                                </div>
                                <div className="form-group-name col-sm-2 ml-1">
                                    <CLabel htmlFor="reportedBy">Reported by</CLabel>
                                    <Field
                                        className="form-control"
                                        style={{height:'38px', borderRadius:'5px'}}
                                        name="reporter"
                                        type="text"
                                        disabled
                                        autoComplete="off"
                                        required>
                                    </Field>
                                </div>
                                <div className="col-sm-2 ml-1">
                                    <CLabel htmlFor="assignedTo">Assigned to</CLabel>
                                    <Field
                                        component={SelectFieldForComment}
                                        options={writters}
                                        name="assignedTo"
                                        autoComplete="off"
                                        required>
                                    </Field>
                                </div>
                                { (currentLine.commentAssignedToUID && currentLine.commentAssignedToUID === localStorage.getItem('uid')) || 
                                (currentLine.commentReporterUID && currentLine.commentReporterUID === localStorage.getItem('uid')) ? 
                                    <div>
                                        <CLabel htmlFor="assignedTo" className="radio-label-comm-edit-stry" style={isOnlyReviewer() ? {color:'gray'} : {}}>Resolved</CLabel>
                                        <CInput
                                            style={{height:'36px', filter: "hue-rotate(25deg)"}}
                                            type="checkbox"
                                            autoComplete="off"
                                            disabled={currentLine.comment === "" || currentLine.comment == null || isOnlyReviewer() ? true : false}
                                            checked={selectedLineIndex === -1 ? false : currentLine.commentResolved}
                                            onChange={(e : any) => 
                                            {
                                                var values : any = 
                                                { 
                                                    storyId: storyId,
                                                    storyTranslationId: storyTransId,
                                                    episodeId: episodeId,
                                                    lineId: currentLine.docId,
                                                    comment: currentLine.comment,

                                                    reporterName: currentLine.commentReporterName,
                                                    reporterUID: currentLine.commentReporterUID,
                                                    assignedToName: currentLine.commentAssignedToName,
                                                    assignedToUID: currentLine.commentAssignedToUID,

                                                    resolved: !currentLine.commentResolved
                                                } 

                                                ResolveComment(values, alert, dispatch);
                                            }}
                                        >
                                        </CInput>
                                </div> : '' }
                                { selectedLineIndex != -1 ? 
                                <div className="form-group-name col-sm-1 ml-1 up-dwn-comm-edit-stry" style={{}}>
                                    <div className="up-btn-cont mb-2"> 
                                        <CTooltip placement="top" content='Go to the line above with comment.'>
                                        <CButton 
                                            className="button-up comm-edit-stry cursor"
                                            disabled={selectedLineIndex === 0 ? true : false}
                                            onClick={() => 
                                            {
                                                var index = selectedLineIndex - 1;
                                                while(index != 0 && !lines[index]?.comment)
                                                {
                                                    index--;
                                                }
                                                if(lines[index]?.comment )
                                                {
                                                    dispatch(ActionCreatorsForEditEpisode.setCurrentLine(
                                                    {
                                                        currentLine: lines[index],
                                                        currentLineIndex: index,
                                                        linesStartIndex: linesStartIndex > (lines?.length - index) ? linesStartIndex - (lines?.length - index) : 0,
                                                        linesSliceParam: index
                                                    }))
                                                }
                                            }}>
                                            <AiOutlineArrowUp size={21} className=""/>
                                        </CButton>
                                        </CTooltip>
                                    </div>
                                    <div className="dwn-btn-cont">
                                        <CTooltip placement="top" content='Go to the line bellow with comment.'>
                                        <CButton 
                                            className="button-down comm-edit-stry cursor"
                                            disabled={selectedLineIndex === lines?.length - 1 ? true : false}
                                            onClick={() => 
                                            { 
                                                var index = selectedLineIndex + 1;
                                                while(index != lines?.length - 1 && !lines[index]?.comment)
                                                {
                                                    index++;
                                                }
                                                if(lines[index]?.comment)
                                                {
                                                    dispatch(ActionCreatorsForEditEpisode.setCurrentLine(
                                                    {
                                                        currentLine: lines[index],
                                                        currentLineIndex: index,
                                                        linesStartIndex: index > linesFrame ? index - linesFrame : linesStartIndex,
                                                        linesSliceParam: index
                                                    }))
                                                }
                                            }}>
                                            <AiOutlineArrowDown size={21} className=""/>
                                        </CButton>
                                        </CTooltip>
                                    </div>
                                </div> : '' }


                            </div>
                            <div className="divider"></div>
                            <div className="col-sm-12 mt-4"style={{display: 'flex', flexDirection:'row', justifyContent: 'space-between'}}>
                                { selectedLineIndex == -1 ? <div style={{padding:'4px', fontStyle:'italic', fontWeight:'bold'}}>Adding comments is disabled for this line. Please, go to another line from the script.</div> : <div style={{padding:'4px', fontStyle:'italic', visibility:'hidden'}}></div>}
                                {isWriterLead() || isReviewer() ? <CButton type="submit" disabled={!dirty || !isValid} color="primary" style={{marginRight:'0px'}}>Add Comment</CButton> : ''}
                            </div>
                        </div>
                    </div>
                </CForm>
            )}}
            </Formik></fieldset>
            </div> : <div/> }  </div>
    )
}

export default EditEpisodeComment;
