import { axiosInstance } from '../axios/key';
import { ErrorHandler } from '../components/Helpers/ErrorHandler';

export const GetGASrcURL = async () => {
    try
    {
        const { data } = await axiosInstance.get('api/GA/GetGASrcURL'); 
        return data;
    }
    catch(e)
    {
        console.log('Error in getting GA Src URL.');
    }
}

export const GetGAScript = async () => {
    try
    {
        const { data } = await axiosInstance.get('api/GA/GetGAScript'); 
        return data;
    }
    catch(e)
    {
        console.log('Error in getting GA Script.');
    }
}