const initialState = 
{
    characters: [] as any[],
    bodyTypes: [] as any[],
    hairstyles: [] as any[],
    clothes: [] as any[],
    accessories: [] as any[],
    avatars: [] as any[],
    backgrounds: [] as any[],
    cinematics: [] as any[],
    audio: [] as any[],
    stories: [] as any[],
    episodes: [] as any[],
    loadingImages: false,

    accessoryTypes: [] as any[],
    audioTypes: [] as any[],
    audioTags: [] as any[],
    interiors: [] as any[],
    exteriors: [] as any[],
    bodyTypesCharacters: [] as any[],
    skinColorsCharacters: [] as any[],
    characterTypes: [] as any[],
    characterDisplayTypes: [] as any[],
    cinematicLocations: [] as any[],
    cinematicActorObjects: [] as any[],
    cinematicPlots: [] as any[],
    clothesTypes: [] as any[],
    clothesAttributes: [] as any[],
    hairstyleTypes: [] as any[],
    hairstyleAttributes: [] as any[],
}

export const ActionTypes =
{
    SET_CHARACTERS: 'SET_CHARACTERS',
    SET_BODY_TYPES: 'SET_BODY_TYPES',
    SET_HAIRSTYLES: 'SET_HAIRSTYLES',
    SET_CLOTHES: 'SET_CLOTHES',
    SET_ACCESSORIES: 'SET_ACCESSORIES',
    SET_AVATARS: 'SET_AVATARS',
    SET_BACKGROUNDS: 'SET_BACKGROUNDS',
    SET_CINEMATICS: 'SET_CINEMATICS',
    SET_AUDIO: 'SET_AUDIO',

    UPDATE_CHARACTER: 'UPDATE_CHARACTER',
    UPDATE_HAIRSTYLES: 'UPDATE_HAIRSTYLES',
    UPDATE_CLOTHES: 'UPDATE_CLOTHES',
    UPDATE_ACCESSORIES: 'UPDATE_ACCESSORIES',
    UPDATE_BACKGROUNDS: 'UPDATE_BACKGROUNDS',
    UPDATE_CINEMATICS: 'UPDATE_CINEMATICS',
    UPDATE_AUDIO: 'UPDATE_AUDIO',

    SET_STORIES: 'SET_STORIES',
    SET_EPISODES: 'SET_EPISODES',
    SET_LOADING_IMAGES: 'SET_LOADING_IMAGES',

    SET_ACCESSORY_TYPES: 'SET_ACCESSORY_TYPES',
    SET_AUDIO_TYPES: 'SET_AUDIO_TYPES',
    SET_AUDIO_TAGS: 'SET_AUDIO_TAGS',
    SET_INTERIORS: 'SET_INTERIORS',
    SET_EXTERIORS: 'SET_EXTERIORS',
    SET_BODY_TYPES_CHARACTERS: 'SET_BODY_TYPES_CHARACTERS',
    SET_SKIN_COLORS_CHARACTERS: 'SET_SKIN_COLORS_CHARACTERS',
    SET_CHARACTER_TYPES: 'SET_CHARACTER_TYPES',
    SET_CHARACTER_DISPLAY_TYPES: 'SET_CHARACTER_DISPLAY_TYPES',
    SET_CINEMATIC_LOCATIONS: 'SET_CINEMATIC_LOCATIONS',
    SET_CINEMATIC_ACTOR_OBJECTS: 'SET_CINEMATIC_ACTOR_OBJECTS',
    SET_CINEMATIC_PLOTS: 'SET_CINEMATIC_PLOTS',
    SET_CLOTHES_TYPES: 'SET_CLOTHES_TYPES',
    SET_CLOTHES_ATTRIBUTES: 'SET_CLOTHES_ATTRIBUTES',
    SET_HAIRSTYLE_TYPES: 'SET_HAIRSTYLE_TYPES',
    SET_HAIRSTYLE_ATTRIBUTES: 'SET_HAIRSTYLE_ATTRIBUTES',
}

export const ActionCreatorsForArtCatalog = 
{
    setCharacters: (payload: any) => ({type: ActionTypes.SET_CHARACTERS, payload}),
    setBodyTypes: (payload: any) => ({type: ActionTypes.SET_BODY_TYPES, payload}),
    setHairstyles: (payload: any) => ({type: ActionTypes.SET_HAIRSTYLES, payload}),
    setClothes: (payload: any) => ({type: ActionTypes.SET_CLOTHES, payload}),
    setAccessories: (payload: any) => ({type: ActionTypes.SET_ACCESSORIES, payload}),
    setAvatars: (payload: any) => ({type: ActionTypes.SET_AVATARS, payload}),
    setBackgrounds: (payload: any) => ({type: ActionTypes.SET_BACKGROUNDS, payload}),
    setCinematics: (payload: any) => ({type: ActionTypes.SET_CINEMATICS, payload}),
    setAudio: (payload: any) => ({type: ActionTypes.SET_AUDIO, payload}),

    updateCharacters: (payload: any) => ({type: ActionTypes.UPDATE_CHARACTER, payload}),
    updateHairstyles: (payload: any) => ({type: ActionTypes.UPDATE_HAIRSTYLES, payload}),
    updateClothes: (payload: any) => ({type: ActionTypes.UPDATE_CLOTHES, payload}),
    updateAccessories: (payload: any) => ({type: ActionTypes.UPDATE_ACCESSORIES, payload}),
    updateBackgrounds: (payload: any) => ({type: ActionTypes.UPDATE_BACKGROUNDS, payload}),
    updateCinematics: (payload: any) => ({type: ActionTypes.UPDATE_CINEMATICS, payload}),
    updateAudio: (payload: any) => ({type: ActionTypes.UPDATE_AUDIO, payload}),

    setStories: (payload: any) => ({type: ActionTypes.SET_STORIES, payload}),
    setEpisodes: (payload: any) => ({type: ActionTypes.SET_EPISODES, payload}),
    setLoadingImages: (payload: any) => ({type: ActionTypes.SET_LOADING_IMAGES, payload}),

    setAccessoryTypes: (payload: any) => ({type: ActionTypes.SET_ACCESSORY_TYPES, payload}),
    setAudioTypes: (payload: any) => ({type: ActionTypes.SET_AUDIO_TYPES, payload}),
    setAudioTags: (payload: any) => ({type: ActionTypes.SET_AUDIO_TAGS, payload}),
    setInteriors: (payload: any) => ({type: ActionTypes.SET_INTERIORS, payload}),
    setExteriors: (payload: any) => ({type: ActionTypes.SET_EXTERIORS, payload}),
    setBodyTypesCharacters: (payload: any) => ({type: ActionTypes.SET_BODY_TYPES_CHARACTERS, payload}),
    setSkinColorsCharacters: (payload: any) => ({type: ActionTypes.SET_SKIN_COLORS_CHARACTERS, payload}),
    setCharacterTypes: (payload: any) => ({type: ActionTypes.SET_CHARACTER_TYPES, payload}),
    setCharacterDisplayTypes: (payload: any) => ({type: ActionTypes.SET_CHARACTER_DISPLAY_TYPES, payload}),
    setCinematicLocations: (payload: any) => ({type: ActionTypes.SET_CINEMATIC_LOCATIONS, payload}),
    setCinematicActorObjects: (payload: any) => ({type: ActionTypes.SET_CINEMATIC_ACTOR_OBJECTS, payload}),
    setCinematicPlots: (payload: any) => ({type: ActionTypes.SET_CINEMATIC_PLOTS, payload}),
    setClothesTypes: (payload: any) => ({type: ActionTypes.SET_CLOTHES_TYPES, payload}),
    setClothesAttributes: (payload: any) => ({type: ActionTypes.SET_CLOTHES_ATTRIBUTES, payload}),
    setHairstyleTypes: (payload: any) => ({type: ActionTypes.SET_HAIRSTYLE_TYPES, payload}),
    setHairstyleAttributes: (payload: any) => ({type: ActionTypes.SET_HAIRSTYLE_ATTRIBUTES, payload}),
}

export default function landingPageReducer(state=initialState, action: any)
{
    switch(action.type)
    {
        case ActionTypes.SET_CHARACTERS:
            return {...state, characters: [...action.payload], loadingImages: false };
        case ActionTypes.SET_BODY_TYPES:
            return {...state, bodyTypes: [...action.payload], loadingImages: false };
        case ActionTypes.SET_HAIRSTYLES:
            return {...state, hairstyles: [...action.payload], loadingImages: false };
        case ActionTypes.SET_CLOTHES:
            return {...state, clothes: [...action.payload], loadingImages: false };
        case ActionTypes.SET_ACCESSORIES:
            return {...state, accessories: [...action.payload], loadingImages: false };
        case ActionTypes.SET_AVATARS:
            return {...state, avatars: [...action.payload], loadingImages: false };
        case ActionTypes.SET_BACKGROUNDS:
            return {...state, backgrounds: [...action.payload], loadingImages: false };
        case ActionTypes.SET_CINEMATICS:
            return {...state, cinematics: [...action.payload], loadingImages: false };
        case ActionTypes.SET_AUDIO:
            return {...state, audio: [...action.payload], loadingImages: false };

        case ActionTypes.SET_STORIES:
            return {...state, stories: [...action.payload] };
        case ActionTypes.SET_EPISODES:
            return {...state, episodes: [...action.payload] };
        case ActionTypes.SET_LOADING_IMAGES:
            return {...state, loadingImages: action.payload}
        
        case ActionTypes.SET_STORIES:
            return {...state, stories: [...action.payload] };
            
        case ActionTypes.SET_ACCESSORY_TYPES:
            return {...state, accessoryTypes: [...action.payload] };        
        case ActionTypes.SET_AUDIO_TYPES:
            return {...state, audioTypes: [...action.payload] };        
        case ActionTypes.SET_AUDIO_TAGS:
            return {...state, audioTags: [...action.payload] };        
        case ActionTypes.SET_INTERIORS:
            return {...state, interiors: [...action.payload] };        
        case ActionTypes.SET_EXTERIORS:
            return {...state, exteriors: [...action.payload] };

        case ActionTypes.SET_BODY_TYPES_CHARACTERS:
            return {...state, bodyTypesCharacters: [...action.payload] };        
        case ActionTypes.SET_SKIN_COLORS_CHARACTERS:
            return {...state, skinColorsCharacters: [...action.payload] };        
        case ActionTypes.SET_CHARACTER_TYPES:
            return {...state, characterTypes: [...action.payload] };     
        case ActionTypes.SET_CHARACTER_DISPLAY_TYPES:
            return {...state, characterDisplayTypes: [...action.payload] };
        case ActionTypes.SET_CINEMATIC_LOCATIONS:
            return {...state, cinematicLocations: [...action.payload] };     
        case ActionTypes.SET_CINEMATIC_ACTOR_OBJECTS:
            return {...state, cinematicActorObjects: [...action.payload] };     
        case ActionTypes.SET_CINEMATIC_PLOTS:
            return {...state, cinematicPlots: [...action.payload] };     
        case ActionTypes.SET_CLOTHES_TYPES:
            return {...state, clothesTypes: [...action.payload] };     
        case ActionTypes.SET_CLOTHES_ATTRIBUTES:
            return {...state, clothesAttributes: [...action.payload] };     
        case ActionTypes.SET_HAIRSTYLE_TYPES:
            return {...state, hairstyleTypes: [...action.payload] };
        case ActionTypes.SET_HAIRSTYLE_ATTRIBUTES:
            return {...state, hairstyleAttributes: [...action.payload] };
        
        case ActionTypes.UPDATE_CHARACTER:
            return {...state, characters: [...state.characters.map((element : any) => element.docId == action.payload.id 
                ? {...element, characterBodyType: action.payload.bodyType, characterSkinColor: action.payload.skinColor} : element)]}
        case ActionTypes.UPDATE_HAIRSTYLES:
            return {...state, hairstyles: [...state.hairstyles.map((element : any) => element.docId == action.payload.id 
                ? 
                {...element, 
                    hairstyleType: action.payload.hairstyleType, 
                    hairstyleNaturalAttributesTags: action.payload.hairstyleNaturalAttributesTags, 
                    hairstyleCostumeAttributesTags: action.payload.hairstyleCostumeAttributesTags
                } : element)]}
        case ActionTypes.UPDATE_CLOTHES:
            return {...state, clothes: [...state.clothes.map((element : any) => element.docId == action.payload.id 
                ? {...element, clothesTag: action.payload.clothesTag, clothesTagAttributes: action.payload.clothesTagAttributes} : element)]}
        case ActionTypes.UPDATE_ACCESSORIES:
            return {...state, accessories: [...state.accessories.map((element : any) => element.docId == action.payload.id 
                ? {...element, accessoryType: action.payload.accessoryType} : element)]}
        case ActionTypes.UPDATE_BACKGROUNDS:
            return {...state, backgrounds: [...state.backgrounds.map((element : any) => element.docId == action.payload.id 
                ? {...element, backgroundExterioTags: action.payload.exterioTags, backgroundInterioTags: action.payload.interioTags} : element)]}
        case ActionTypes.UPDATE_CINEMATICS:
            return {...state, cinematics: [...state.cinematics.map((element : any) => element.docId == action.payload.id 
                ? 
                {...element, 
                    cinematicLocationTags: action.payload.locationTags, 
                    cinematicActionObjectTags: action.payload.actionObjectTags,
                    cinematicPlotTags: action.payload.plotTags, 
                } : element)]}
        case ActionTypes.UPDATE_AUDIO:
            return {...state, audio: [...state.audio.map((element : any) => element.docId == action.payload.id 
                ? {...element, audioType: action.payload.type, audioTagAttributes: action.payload.audioTagAttributes} : element)]}
        default:
            return state;
    }
}