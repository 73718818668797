import React, { useEffect, useState } from 'react';
import { CInputGroup, CForm, CButton } from '@coreui/react';
import { Field, Formik } from 'formik';
import { AddNewLine } from '../../../services/EpisodeServices';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorsForEditEpisode } from '../../../store/EditEpisodeReducer';
import CreatableSelect from 'react-select/creatable';
import { useAlert } from 'react-alert';
import { LineType } from '../../../interfaces/lineType';
import { createDefaultLineTypeOptions, editEpisodeReadOnlyCell, emotionStyle, FormatObject, getInputLineNumber, validateActionChange, colorLineInsideAnswer, showLineActions, colorLineWithComment, isLineOptionChanged } from './_Helpers';
import _ from 'lodash';
import { DeleteLines } from '../../../services/LineServices';
import { ActionCreators } from '../../../store/StoriesReducer';
import { DeleteMultipleLinesConfirmationModal } from '../../Helpers/YesNoModal';
import '../../../styles/Lines.css'
import { LineTypes } from '../Enums/LineTypes';
import LineSidebar from '../LineSidebar';

const stateWithClearedLineVisualChoiceAnswer = (inputLine: boolean, selectedLineIndex: number, lines: LineType[], line: LineType, index: number, isRenderedByAction: boolean, isModalOpen: boolean, maxLineNumber: number, modalClickedLine: LineType, modalType : string) => 
{
    return (inputLine && selectedLineIndex === -1) || isModalOpen ? 
    { 
        docId: line.docId,
        storyId: line.storyId,
        storyTranslationId: line.storyTranslationId,
        episodeId: line.episodeId,
        lineNumber: getInputLineNumber(lines,line,index,maxLineNumber,isModalOpen, modalType),
        depth: isModalOpen ? (line.depth ?? 0) + ([LineTypes.Q, LineTypes.A, LineTypes.BA, LineTypes.T, LineTypes.TA, LineTypes.V, LineTypes.VA].includes(modalClickedLine.lineType ?? 0) ? 0 : -1) : 0,
        lineType: line.lineType,
        lineFormat: line.lineFormat,
        episodeName: line.episodeName,
        previousLineDocId: line.previousLineDocId,
        episodeSerialNumber: line.episodeSerialNumber,

        characterId: line.characterId,
        characterName: line.characterName,
        characterPath: line.characterPath,
        emotion: line.emotion,
        text: isRenderedByAction ? line.text : undefined,

        animationId: undefined,
        animationName: undefined,
        payed: line.payed,
        answerNumber: line.answerNumber,
        answerQuestionId: line.answerQuestionId,
        questionDocId: undefined,
    
        comment: undefined,
        commentReporterName: undefined,
        commentReporterUID: undefined,
        commentAssignedToName: undefined,
        commentAssignedToUID: undefined,
        commentResolved: undefined,

        musicId: line.musicId,
        musicName: line.musicName,
        musicDescription: line.musicDescription,

        shortSoundEnabled: line.shortSoundEnabled,
        shortSoundId: line.shortSoundId,
        shortSoundName: line.shortSoundName,
        shortSoundDescription: line.shortSoundDescription,

        itemId: line.itemId ?? undefined,
        itemName: line.itemName ?? undefined,
        itemDescription: line.itemDescription ?? undefined,
        
        avatarEnabled: line.avatarEnabled,
        
        changeIdentityOn: line.changeIdentityOn,
        changeIdentityName: line.changeIdentityName,

        callPhoneEnabled: line.callPhoneEnabled != null ? line.callPhoneEnabled : undefined,
        callSmsEnabled: line.callSmsEnabled != null ? line.callSmsEnabled : undefined,
        callerCharacterId: line.callerCharacterId,
        callerCharacterName: line.callerCharacterName,
        charmingPoint: line.charmingPoint != null ? line.charmingPoint : undefined,
        premiumChoiceText: line.premiumChoiceText != null ? line.premiumChoiceText : undefined,
        premiumChoiceDescription: line.premiumChoiceDescription != null ? line.premiumChoiceDescription : undefined,
        charactersOutfit: line.charactersOutfit,

        backgroundId: line.backgroundId,
        backgroundName: line.backgroundName,
        backgroundDescription: line.backgroundDescription,
    
        clothes1Name: undefined,
        clothes2Name: undefined,
        clothes3Name: undefined,
        clothes4Name: undefined,
        clothes5Name: undefined,
        clothes6Name: undefined,
        hairstyle1Name: undefined,
        hairstyle2Name: undefined,
        hairstyle3Name: undefined,
    
        clothes1Id: undefined,
        clothes2Id: undefined,
        clothes3Id: undefined,
        clothes4Id: undefined,
        clothes5Id: undefined,
        clothes6Id: undefined,
        hairstyle1Id: undefined,
        hairstyle2Id: undefined,
        hairstyle3Id: undefined,
        answerVisualChoiceId: line.answerVisualChoiceId,
        visualId: line.visualId,
        visualName: isRenderedByAction ? line.visualName : undefined,
    } 
    : 
    line
}

export const VisualChoiceAnswer = ({ line, inputLine, index, serialNumber, episodeName, isBranchAnswer, isPreviewInModal, inPreviewIndex }: any) =>
{
    const dispatch = useDispatch();
    const alert = useAlert();

    const { defaultVisualChoices, lines, selectedLineIndex, linesFrame, pickedActionColor, defaultLineTypes, isOLineAdded, defaultBranchQandC, defaultLineFormats, pickedColorType,
        initEditEpisodeBody, linesStartIndex, isRenderedByAction, updateCloudBool, maxLineNumber, editEpisodePassParam } = useSelector((state: any) => state.editEpisodeReducer);
    const { modalPopupBool } = useSelector((state: any) => state.storiesReducer);

    let currentLineTypes = createDefaultLineTypeOptions(lines, index, defaultLineTypes, defaultBranchQandC, isOLineAdded, modalPopupBool.isOpen, modalPopupBool.type);

    const [yesnomodal, setYesnomodal] = useState({boolean: false, array: [], nextLineDocId: null})

    const [clearedLine, clearLine] = useState(stateWithClearedLineVisualChoiceAnswer(inputLine, selectedLineIndex, lines, line, index, isRenderedByAction, modalPopupBool.isOpen, maxLineNumber, modalPopupBool.clickedLine, modalPopupBool.type));

    let addLineSchema = yup.object().shape(
    {
        //lineNumber: yup.string(),
        //lineType: yup.string(),
        //characterName: yup.string(),
        //emotion: yup.string(),
        //text: yup.string().max(validations.Q.length, validations.Q.text)
    });

    const setupSpanElements = () => 
    {
        let answerNumber = document.querySelector(`span[name="answerNumber${isPreviewInModal ? inPreviewIndex : index}"]`)
        if(answerNumber) 
        {
            answerNumber.innerHTML = lines[index ?? selectedLineIndex]?.answerNumber;
        }
        
        let lineNumber = document.querySelector(`span[name="lineNumber${isPreviewInModal ? inPreviewIndex : index}"]`);
        if(lineNumber) lineNumber.innerHTML = isBranchAnswer ? '-' : line.lineNumber;
        let lineType = document.querySelector(`span[name="lineType${isPreviewInModal ? inPreviewIndex : index}"]`);
        if(lineType) 
        { 
            let types = defaultLineTypes?.filter((x:any) => x.id == line.lineType);
            lineType.innerHTML = types.length > 0 ? (isBranchAnswer ? 'A' : types[0].label) : '';
        }

        let visualName = document.querySelector(`span[name="visualName${isPreviewInModal ? inPreviewIndex : index}"]`)
        if(visualName) visualName.innerHTML = line.visualName ?? "";
    }

    useEffect(() => { 
        if(!inputLine || isBranchAnswer) {
            setupSpanElements()
        }            
    }, [line])

    useEffect(() => {
        if((!line.isDefault && inputLine && selectedLineIndex != -1) || modalPopupBool.isOpen) 
            dispatch(ActionCreatorsForEditEpisode.disableVisualChoiceIfChosen(line)) 
    }, [modalPopupBool.isOpen, line.visualName])

    useEffect(() => 
    {
        clearLine(stateWithClearedLineVisualChoiceAnswer(inputLine, selectedLineIndex, lines, line, index, isRenderedByAction, modalPopupBool.isOpen, maxLineNumber, modalPopupBool.clickedLine, modalPopupBool.type))
    }, [line,maxLineNumber])

    return(
        <Formik validateOnChange={true} validateOnBlur={false} enableReinitialize initialValues={
            {
                lineNumber: line.lineNumber,
                lineType: line.lineType, 
                answerNumber: line.answerNumber,
                visualName: modalPopupBool.isOpen && inputLine && modalPopupBool.index === index 
                ? ((modalPopupBool.clickedLine.visualName === modalPopupBool.currentLine.visualName) ? "" : modalPopupBool.currentLine.visualName) 
                : (inputLine && selectedLineIndex === -1 && !isRenderedByAction) ? "" : (line.visualName ?? "")
            }}
            onSubmit={(values: LineType) => 
            {
                if((!inputLine || isBranchAnswer) && modalPopupBool.index != index)
                {
                    let currentLine = {...lines[index], answerNumber: lines[index].answerNumber + 1, visualName: null};
                    dispatch(ActionCreators.setModalPopupBool({ type: 'normal', isOpen: true, isFirstTimeOpen: true,  index: index, currentLine, clickedLine: currentLine }))
                }
                else if(line.changeIdentityOn && !line.changeIdentityName)
                {
                    alert.info('You must fill in the character name in Change Identity, because you have enabled it.')
                }
                else
                { 
                    dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updating'));

                    let properIndex = index;
                    let previousLineDocId = lines[lines.length - 1]?.docId;
                    if (index != null) {
                        let counter = index + 1;
                        previousLineDocId = lines[index]?.docId;
                        while(counter < lines.length && (lines[counter].depth > lines[index].depth))
                        {
                            previousLineDocId = lines[counter]?.docId;
                            properIndex = counter;
                            counter = counter + 1;
                        }
                    }
                    
                    //citamo actions iz reducera, smestamo u objekat i radimo new line
                    values = 
                    {
                        ...clearedLine,
                        ...values,
                        lineNumber: index != null ? index + 2 : (lines[0].isDefault ? 1 : lines.length + 1),       
                        answerNumber: lines[index].answerNumber + 1,
                        episodeName: episodeName,
                        previousLineDocId: previousLineDocId,
                        episodeSerialNumber: serialNumber,                   
                    }

                    var isChangedByAction = validateActionChange(dispatch, values, lines, lines[lines.length-1], true)
                    values = {...values, actionChangeFlag: isChangedByAction}
                    
                    AddNewLine(values, properIndex, linesFrame, selectedLineIndex, dispatch, alert, modalPopupBool.type, modalPopupBool.isOpen)
                }
            }}
            validationSchema={addLineSchema}>
            {({ dirty, isValid, handleSubmit }) => {
                return (
                    <div>
                    <CForm id="add-line-form-submit" className="row" onSubmit={(e : any) => 
                    { 
                        if(!isValid) alert.error('Line you are trying to add is not valid.')
                        if(updateCloudBool === 'updating' || (!modalPopupBool.isOpen && selectedLineIndex !== -1 && e.keyCode == 13)) 
                        {
                            e.preventDefault();
                            return;
                        } 
                        e.preventDefault(); handleSubmit(); 
                    }} disabled={!inputLine || isBranchAnswer}>
                        <CInputGroup className={inputLine && !isBranchAnswer ? 'col border-input-line' : 'col'} style={
                            { 
                                marginLeft: !inputLine || isBranchAnswer ? `${line.depth * parseInt(editEpisodeReadOnlyCell.minWidth.split("px")[0])}px` : "" ,
                                border: (selectedLineIndex === index) ? `4px solid #bdbdbd` : "",
                                padding: "0",
                                paddingLeft: !inputLine || isBranchAnswer ? `4px` : "0"
                            }}
                            onClick={(event: any) => {
                                if (!inputLine && !modalPopupBool.isOpen)
                                    dispatch(ActionCreatorsForEditEpisode.setCurrentLine({currentLine: line, currentLineIndex: index, linesStartIndex: index + 1 > linesFrame ? index + 1 - linesFrame : 0, linesSliceParam: index}))
                            }}>
                            <Field
                                {...(!inputLine || isBranchAnswer ? 
                                    {
                                        as:"span",
                                        title: showLineActions(line),
                                        style: {...editEpisodeReadOnlyCell, backgroundColor: colorLineInsideAnswer(lines, index, pickedColorType, editEpisodeReadOnlyCell)},
                                        name: `lineNumber${isPreviewInModal ? inPreviewIndex : index}`,
                                    } 
                                    : 
                                    {
                                        as: undefined,
                                        name: "lineNumber",
                                        className: 'col-auto',
                                        style: { backgroundColor: "rgb(216, 219, 224)", maxWidth: "50px", minHeight: "35px" }
                                    }
                                )}
                                type="text"
                                value={getInputLineNumber(lines,line,index,maxLineNumber,modalPopupBool.isOpen, modalPopupBool.type)}
                                disabled>
                            </Field>
                            <Field
                                {...(!inputLine || isBranchAnswer ? 
                                {
                                    as:"span",
                                    style: {...editEpisodeReadOnlyCell, backgroundColor: colorLineInsideAnswer(lines, index, pickedColorType, editEpisodeReadOnlyCell)},
                                    name: `lineType${isPreviewInModal ? inPreviewIndex : index}`,
                                    className: "col-auto cursor"
                                } 
                                : 
                                {
                                    as: "select",
                                    id: `lineType${index}`,
                                    name: `lineType`,
                                    className:"col-auto cursor",
                                    value:line.lineType,
                                    onChange:(event: any) => 
                                    {
                                        if(modalPopupBool.isOpen)
                                        {
                                            dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine: {...modalPopupBool.currentLine, lineType: parseInt(event.target.value)}, isFirstTimeOpen: false }))
                                        }
                                        else 
                                        { 
                                            dispatch(ActionCreatorsForEditEpisode.updateLineTypeParam(parseInt(event.target.value))) 
                                        }
                                    }
                                }
                            )}
                                {...((!modalPopupBool.isOpen) && (inputLine && !isBranchAnswer && selectedLineIndex!==-1) ? {style: { backgroundColor: "rgb(216, 219, 224)" }} : {} )}
                                type="text"
                                disabled={ (!modalPopupBool.isOpen) && ( !inputLine || isBranchAnswer || selectedLineIndex !== -1)}>
                                {inputLine && !isBranchAnswer ? currentLineTypes : ''}
                            </Field>
                            <Field
                                {...(!inputLine || isBranchAnswer ? 
                                    {
                                        as:"span",
                                        style: {...editEpisodeReadOnlyCell, backgroundColor: colorLineInsideAnswer(lines, index, pickedColorType, editEpisodeReadOnlyCell)},
                                        name: `answerNumber${isPreviewInModal ? inPreviewIndex : index}`,
                                        className: "col-sm-1 cursor"
                                    } 
                                    : 
                                    {
                                        type: 'text',
                                        className:"col-sm-1 cursor",
                                        style: {...emotionStyle, backgroundColor: "rgb(216, 219, 224)", cursor: 'auto',borderColor: "rgba(118, 118, 118, 0.3)", borderStyle: "solid", borderWidth: "1px", },
                                        name: `answerNumber`,
                                        value: line.answerNumber
                                    }
                                )}
                                type="text"
                                disabled>
                            </Field>
                            <Field
                                {...(!inputLine ? 
                                        {
                                            as:"span",
                                            style: editEpisodeReadOnlyCell,
                                            className: "col hair-line cursor",
                                            name: `visualName${isPreviewInModal ? inPreviewIndex : index}`,
                                        } 
                                        : 
                                        {
                                            as: CreatableSelect,
                                            className:"col-sm-2 cursor p-0 border-fix-input",
                                            name: "visualName",
                                            options: defaultVisualChoices,
                                            isClearable: false,
                                            backspaceRemovesValue: true,
                                            value: line.visualName ? { id: line.visualId, value: line.visualId, label: line.visualName } : null,
                                            onChange:(value: any) => 
                                            {
                                                if(updateCloudBool !== 'updating')
                                                {
                                                    if (value) 
                                                    { 
                                                        if (value.__isNew__ !== undefined) 
                                                        { 
                                                            if(modalPopupBool.isOpen)
                                                            {
                                                                dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine: {...modalPopupBool.currentLine, visualId: -1, visualName: value.label}, isFirstTimeOpen: false }))
                                                            }
                                                            else 
                                                            {
                                                                if(selectedLineIndex != -1)
                                                                {
                                                                    FormatObject(
                                                                        dispatch, 
                                                                        selectedLineIndex, 
                                                                        linesStartIndex,
                                                                        lines, 
                                                                        { lineToUpdateId: line.docId, lineModel: {...line, visualId: -1, visualName: value.label}}, alert)
                                                                }
                                                                dispatch(ActionCreatorsForEditEpisode.updateVisualParam({ ...value, id: -1 }))
                                                            }
                                                        }
                                                        else 
                                                        {
                                                            if(modalPopupBool.isOpen)
                                                            {
                                                                dispatch(ActionCreators.setModalPopupBool({...modalPopupBool, currentLine: {...modalPopupBool.currentLine, visualId: value.id, visualName: value.label}, isFirstTimeOpen: false }))
                                                            }
                                                            else if(selectedLineIndex !== -1)
                                                            {
                                                                FormatObject(
                                                                    dispatch, 
                                                                    selectedLineIndex, 
                                                                    linesStartIndex,
                                                                    lines, 
                                                                    { lineToUpdateId: line.docId, lineModel: 
                                                                        {...line, 
                                                                           visualId: value.id,
                                                                           visualName: value.label
                                                                        } 
                                                                    }, alert)
                                                            }  
                                                        }
                                                    }
                                                    else
                                                    {
                                                        dispatch(ActionCreatorsForEditEpisode.updateVisualParam({ id: null, value: null, label: ''}))
                                                    }
                                                }
                                            }}
                                    )
                                }
                                autoComplete="off"
                                type="text"
                                disabled={!inputLine}
                            >
                            </Field>
                            <span style={!inputLine ? {visibility: 'hidden'} : editEpisodeReadOnlyCell} className={!inputLine ? "" : "col hair-line cursor"}/>
                            {line.comment && !inputLine? <span className="tooltip-comment-exist" style={{backgroundColor: colorLineWithComment(line)}}></span> : ''}        
                        </CInputGroup >
                        {modalPopupBool.isOpen && inputLine && modalPopupBool.index === index  ? <CButton className="ml-5" type="submit" color="primary" size='lg'>Add line</CButton> : ''}
                        
                        <LineSidebar  
                            dispatch={dispatch} 
                            lines={lines} 
                            line={line} 
                            selectedLineIndex={selectedLineIndex} 
                            inputLine={inputLine} 
                            isPreviewInModal={isPreviewInModal}
                            modalPopupBool={modalPopupBool}
                            setYesnomodal={setYesnomodal}
                            editEpisodePassParam={editEpisodePassParam}
                            index={index}
                            pickedActionColor={pickedActionColor}
                            isChangedByOptionFlag={isLineOptionChanged(line)}
                        />
                        
                    </CForm>
                    <DeleteMultipleLinesConfirmationModal modal={yesnomodal} setModal={setYesnomodal} methodForYes={() => DeleteLines(dispatch, alert, yesnomodal.array, index, linesFrame, setYesnomodal, yesnomodal.nextLineDocId) } title="Delete confirmation" message="Are you sure you want to delete selected line or line block?"/>
                </div>)
            }}
        </Formik>)
}

export default VisualChoiceAnswer;