import React, { useEffect, useState } from 'react';
import { CButton, CForm, CModal, CModalBody, CModalHeader, CModalTitle, CTooltip, CLabel, CInput } from '@coreui/react';
import { isAnyTranslatorAndReviewer, isAnyWriter, isNarrativeDesigner, isOnlyReviewer, isWriterLead } from '../Auth/AuthConfig';
import { GetLanguages } from '../../services/LanguageServices';
import { useAlert } from 'react-alert';
import { handleNameConvert } from '../Helpers/NameConvert';
import Select from 'react-select';
import { GetAllStoriesForHeader, GetEpisodesForHeader } from '../../services/StoriesServices';
import { useHistory, useParams } from 'react-router-dom';
import { AiOutlineArrowDown, AiOutlineArrowUp, AiOutlineComment, AiOutlineEdit, AiTwotoneEdit } from 'react-icons/ai';
import { ChangeEpisodeStatus, GetPossibleEpisodeStatuses } from '../../services/StateMachine';
import { ActionCreatorsForEditEpisode, defaultLine } from '../../store/EditEpisodeReducer';
import { useDispatch, useSelector } from 'react-redux';
import { LineType } from '../../interfaces/lineType';
import { ErrorMessage, Field, Formik } from 'formik';
import * as yup from 'yup';

import { BsChevronCompactDown } from 'react-icons/bs';
import { contentForChangeEpisodeStatus } from '../..';
import { selectStylesForField } from '../Common/Lines/_Helpers';
import { UpdateEpisodeDescription } from '../../services/EpisodeServices';
import { EpisodeStatusChangedLogger, EpisodeSubmittedLogger } from '../../modules/firebaseAnalyticsLoggers';
import { EpisodeDropdownSessionLogger, FindTextSessionLogger, StoryDropdownSessionLogger } from '../../modules/firebaseDetailedLoggers';

let changeEpisodeDescriptionSchema = yup.object().shape({
    description: yup.string().max(400, 'Maximum amount of characters for title description is 400.').required("Required."),
});

export const EditEpisodeHeader = ({episode} : any) => 
{ 
    const alert = useAlert(contentForChangeEpisodeStatus);
    const { storyId, storyTransId, episodeId } : any = useParams();
    const [visible, setVisible] = useState(false);
    
    const statuses = useSelector((state: any) => state.editEpisodeReducer.episodeStates);
    const lines = useSelector((state: any) => state.editEpisodeReducer.lines);
    const linesStartIndex = useSelector((state: any) => state.editEpisodeReducer.linesStartIndex);
    const selectedLineIndex = useSelector((state: any) => state.editEpisodeReducer.selectedLineIndex);
    const linesFrame = useSelector((state: any) => state.editEpisodeReducer.linesFrame);
    
    const dispatch = useDispatch();
    const history = useHistory();

    const [stories, setStories] = useState([]);
    const [episodes, setEpisodes] = useState([]);

    const [chosenStory, setChosenStory] = useState({ id: episode.storyId, value: episode.storyTranslationId, label: episode.storyName }) 
    const [chosenEpisode, setChosenEpisode] = useState({ id: episode.id, value: episode.id, label: episode.title, description: episode.titleDescription, episodeStatusEnum: episode.episodeStatusEnum }) 

    const [text, setText] = useState('');

    const [changeEpisodeDescriptionPopup, setChangeEpisodeDescriptionPopup] = useState({isOpen: false, description: '', episodeId: ''});

    useEffect(() => 
    {
        const timeOutId = setTimeout(() => 
        {
            let filteredLines = lines?.map((l: LineType, i: number) => {
                return {...l, lineIndex: i}
            }).filter((l: LineType) => 
            {
                if(text && l.text?.toLowerCase().includes(text.toLowerCase()))
                {
                    return l;
                }
            })

            if(text) {
                const findTextSession = new FindTextSessionLogger();

                findTextSession.query_string = text ?? '';
                findTextSession.timestamp = new Date().toUTCString();
                findTextSession.session_id = localStorage.getItem('sessionId') ?? '';
                findTextSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                findTextSession.user_id = localStorage.getItem('uid') ?? '';

                findTextSession.logEvent();
            }

            dispatch(ActionCreatorsForEditEpisode.findTextFilter(filteredLines));
        }, 1500);
        return () => clearTimeout(timeOutId);
    }, [text])

    useEffect(() => 
    { 
        GetAllStoriesForHeader().then((response) => { setStories(response); })
    }, [])

    useEffect(() =>
    { 
        setChosenStory({ id: episode.storyId, value: episode.storyTranslationId, label: episode.storyName });
        setChosenEpisode({ id: episode.id, value: episode.id, label: episode.title, description: episode.titleDescription, episodeStatusEnum: episode.episodeStatusEnum })
    }, [episode])

    useEffect(() =>
    {
        GetEpisodesForHeader(storyId, storyTransId).then((response) => { setEpisodes(response) });
    }, [storyId, storyTransId])

    return (
    <div>
        <div className="page-title edit-episode shadow cursor" onClick={() => setVisible(!visible)}><AiOutlineEdit />&nbsp;Edit Episode (Language: {episode.languageName}) <span className="arrow-down"><BsChevronCompactDown /></span></div>
        { visible ? 
            <div className="register-signin-left-panel col-sm-12 rounded shadow edit-episode comments">
                <div className="row-style row edit-episode-header">
                <div className="form-group-name col-sm-2 ">
                    <CLabel htmlFor="story">Story</CLabel>
                    <Select
                        isDisabled={!isAnyWriter()}
                        options={stories}
                        styles={selectStylesForField}
                        menuPortalTarget={document.querySelector('body')}
                        value={chosenStory}
                        hideSelectedOptions
                        onChange={(value : any) => 
                        { 
                            const storyDropdownSession = new StoryDropdownSessionLogger();

                            storyDropdownSession.timestamp = new Date().toUTCString();
                            storyDropdownSession.session_id = localStorage.getItem('sessionId') ?? '';
                            storyDropdownSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                            storyDropdownSession.user_id = localStorage.getItem('uid') ?? '';

                            storyDropdownSession.logEvent();

                            
                            GetEpisodesForHeader(value.id, value.value).then((response) => 
                            { 
                                setEpisodes(response)
                                setChosenStory(value);
                                history.push(`/edit-episode/&storyId=${value.id}&storyTransId=${value.value}&episodeId=${response[0].id}`)
                            });
                        }}
                        as="select"
                        autoComplete="off"
                        placeholder="Choose a story..."
                        required >
                    </Select>
                </div>

                <div className="form-group-name col-sm-2 ml-1">
                    <CLabel htmlFor="episode">Episode</CLabel>
                    <Select
                        isDisabled={!isAnyWriter()}
                        options={episodes}
                        styles={selectStylesForField}
                        menuPortalTarget={document.querySelector('body')}
                        value={chosenEpisode}
                        hideSelectedOptions
                        onChange={(value : any) => 
                        { 
                            const episodeDropdownSession = new EpisodeDropdownSessionLogger();

                            episodeDropdownSession.timestamp = new Date().toUTCString();
                            episodeDropdownSession.session_id = localStorage.getItem('sessionId') ?? '';
                            episodeDropdownSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                            episodeDropdownSession.user_id = localStorage.getItem('uid') ?? '';

                            episodeDropdownSession.logEvent();

                            setChosenEpisode(value);
                            history.push(`/edit-episode/&storyId=${chosenStory.id}&storyTransId=${chosenStory.value}&episodeId=${value.id}`)
                        }}
                        name="episode"
                        as="select"
                        autoComplete="off"
                        placeholder="Enter episode..."
                        required>
                    </Select>
                </div>

                <div className="form-group-name col-sm-4 ml-1">
                    <CLabel htmlFor="description">Title Description</CLabel>
                    <div style={{display: 'flex'}}>
                        <div style={{width: '100%'}}>
                            <CTooltip placement="top" content={chosenEpisode?.description?.replace('\n', '<br>')}>
                            <CInput
                                disabled={!isAnyWriter()}
                                className="form-control"
                                name="description"
                                type="text"
                                value={chosenEpisode.description ? (chosenEpisode?.description?.split('\n')[0].substring(0, 25) + (chosenEpisode?.description?.length > 15 ? '...' : '')) : ''}
                                autoComplete="off"
                                readOnly>
                            </CInput>
                            </CTooltip>
                        </div>
                        <div style={{width:'50px'}}>
                            <AiTwotoneEdit size={23} color="#481fca" className="cursor mt-1 ml-1" onClick={() => { 
                                if(episode.canUpdateTitleAndDescription && isAnyWriter())
                                    setChangeEpisodeDescriptionPopup({isOpen: true, description: chosenEpisode.description, episodeId: chosenEpisode.id})
                                else
                                    alert.info('You cannot edit description for this episode.')
                                }}/>
                        </div>
                    </div>
                </div>

                <div className="form-group-name col-sm-2 ml-1 row-style">
                    <div className="column-style">
                        <CLabel htmlFor="find">Find</CLabel>
                        <CTooltip placement="top" content='Filtered lines will be marked with blue letters.'>
                        <CInput
                            className="form-control"
                            name="find"
                            type="text"
                            autoComplete="off"
                            placeholder="Find text..."
                            value={text}
                            onChange={(e : any) => { setText(e.target.value) }}
                            required>
                        </CInput>
                        </CTooltip>
                    </div>
                    <div className="form-group-name col-sm-1 up-dwn-comm-edit-stry" style={{}}>
                        <div className="up-btn-cont mb-2"> 
                            <CTooltip placement="top" content='Filtered line above.'>
                            <CButton 
                                className="button-up comm-edit-stry cursor"
                                disabled={selectedLineIndex === 0 ? true : false}
                                onClick={() => 
                                {
                                    var index = selectedLineIndex === -1 ? lines?.length - 1 : selectedLineIndex - 1;
                                    while(index != 0 && !lines[index]?.filtered)
                                    {
                                        index--;
                                    }
                                    if(lines[index]?.filtered)
                                    {
                                        dispatch(ActionCreatorsForEditEpisode.setCurrentLine(
                                        {
                                            currentLine: lines[index],
                                            currentLineIndex: index,
                                            linesStartIndex: linesStartIndex > (lines?.length - index) ? linesStartIndex - (lines?.length - index) : 0
                                        }))
                                    }
                                }}
                                >
                                <AiOutlineArrowUp size={14} className=""/>
                            </CButton>
                            </CTooltip>
                        </div>
                        <div className="dwn-btn-cont">
                            <CTooltip placement="top" content='Filtered line bellow.'>
                            <CButton 
                                className="button-down comm-edit-stry cursor"
                                disabled={selectedLineIndex === lines?.length - 1 || selectedLineIndex === -1 ? true : false}
                                onClick={() => 
                                {
                                    var index = selectedLineIndex + 1;
                                    while(index != lines?.length - 1 && !lines[index]?.filtered)
                                    {
                                        index++;
                                    }
                                    if(lines[index]?.filtered)
                                    {
                                        dispatch(ActionCreatorsForEditEpisode.setCurrentLine(
                                        {
                                            currentLine: lines[index],
                                            currentLineIndex: index,
                                            linesStartIndex: index > linesFrame ? index - linesFrame : linesStartIndex 
                                        }))
                                    }
                                }}
                                >
                                <AiOutlineArrowDown size={14} className=""/>
                            </CButton>
                            </CTooltip>
                        </div>
                    </div>
                </div>

                <div className="form-group-name col-sm-2 ml-1">
                    <CLabel htmlFor="episode">Episode Status</CLabel>
                    <Select
                        isDisabled={!isAnyWriter()}
                        options={statuses}
                        hideSelectedOptions
                        styles={selectStylesForField}
                        menuPortalTarget={document.querySelector('body')}
                        value={statuses.find((element : any) => element.value === chosenEpisode.episodeStatusEnum)}
                        onChange={(value : any) => 
                        { 
                            dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updating'));
                            ChangeEpisodeStatus(value.value, {storyId: storyId, translationId: storyTransId, episodeId: episodeId}).then((response : any) => 
                            {
                                if(response.success){
                                    dispatch(ActionCreatorsForEditEpisode.setEditEpisodePassParam({...episode, episodeStatusEnum: value.value}));
 
                                    const episodeStatusChangedLogger = new EpisodeStatusChangedLogger()
                                    episodeStatusChangedLogger.story_name = episode.storyName;
                                    episodeStatusChangedLogger.logged_in_user_name = localStorage.getItem('user') ?? '';
                                    episodeStatusChangedLogger.user_id = localStorage.getItem('uid') ?? '';
                                    episodeStatusChangedLogger.game_type = episode.storyGameTypeString;
                                    episodeStatusChangedLogger.episode_number = episode.serialNumber;
                                    episodeStatusChangedLogger.from_status = episode.episodeStatus;
                                    episodeStatusChangedLogger.to_status = response.newStatusString;
                                    episodeStatusChangedLogger.logEvent()

                                    if(episode.episodeStatusEnum === 1 && response.newStatus === 2) {
                                        const episodeSubmittedLogger = new EpisodeSubmittedLogger()
                                        episodeSubmittedLogger.story_name=  episode.storyName;
                                        episodeSubmittedLogger.logged_in_user_name = localStorage.getItem('user') ?? ''
                                        episodeSubmittedLogger.user_id = localStorage.getItem('uid') ?? '';
                                        episodeSubmittedLogger.episode_number = episode.serialNumber;
                                        episodeSubmittedLogger.logEvent()
                                    }
                                    if(isOnlyReviewer() || isAnyTranslatorAndReviewer()) history.push('/stories');
                                    GetPossibleEpisodeStatuses(dispatch, {storyId: storyId, translationId: storyTransId, episodeId: episodeId}).then(() => {
                                        dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('updated'));
                                    })

                                } else
                                {
                                    alert.info(response?.message); 
                                    dispatch(ActionCreatorsForEditEpisode.setUpdateCloudBool('error'));
                                }
                            })
                        }}
                        name="episode"
                        as="select"
                        autoComplete="off"
                        required>
                    </Select>
                </div>
            </div>

            <CModal show={changeEpisodeDescriptionPopup.isOpen} className="create-new-episode-modal" fade onClose={() => setChangeEpisodeDescriptionPopup({isOpen: false, description: '', episodeId: ''})} closeOnBackdrop={false}>
                <CModalHeader closeButton>
                    <CModalTitle>Change episode title description</CModalTitle>
                </CModalHeader> 
                <CModalBody>
                    <Formik enableReinitialize initialValues={{ description: changeEpisodeDescriptionPopup.description }}
                        onSubmit={(values) => 
                        { 
                            UpdateEpisodeDescription({
                                storyId: episode.storyId,
                                translationId: episode.storyTranslationId,
                                episodeId: episode.id,
                                text: values.description
                            }, dispatch, alert, setChangeEpisodeDescriptionPopup);
                        }}
                        validationSchema={changeEpisodeDescriptionSchema}>
                        {({ dirty, isValid, handleSubmit }) => 
                        {
                            return(
                                <CForm onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                    <div className="form-group-name col-sm-12 modal-row">
                                        <label htmlFor="description">Episode Title Description</label>
                                        <Field
                                            className="form-control"
                                            style={{height: '250px'}}
                                            name="description"
                                            type="text"
                                            as="textarea"
                                            autoComplete="off"
                                            required>
                                        </Field>
                                        <ErrorMessage component="p" className="field-colorchange" name="description" />
                                    </div>
                                    <div className="modal-buttons-container float-right">
                                        <CButton type="submit" disabled={!dirty || !isValid} className="mr-2" color="primary-sm">Confirm</CButton>
                                        <CButton color="cancel-sm" onClick={() => setChangeEpisodeDescriptionPopup({isOpen: false, description: '', episodeId: ''})}>Cancel</CButton>
                                    </div>
                                </CForm>
                            )}
                        }
                    </Formik>
                </CModalBody>
            </CModal>

            </div> : '' }</div>
    )
}

export default EditEpisodeHeader;
