import React, { useEffect, useState } from 'react';
import { CButton } from '@coreui/react';
import Select from 'react-select';
import { GetSelectValuesForAssets, UpdateAssets } from '../../../services/ArtCatalog';
import { useDispatch } from 'react-redux';
import { ActionCreatorsForArtCatalog } from '../../../store/ArtCatalogReducer';
import { selectStylesForField } from '../Lines/_Helpers';
import { SaveChangesSessionLogger } from '../../../modules/firebaseDetailedLoggers';

export const BackgroundPictureBody = ({visible, setVisible, alert} : any) =>
{
    const dispatch = useDispatch();

    const [interiorTags, setInteriorTags] = useState([]);
    const [exteriorTags, setExteriorTags] = useState([]);
    
    useEffect(() =>
    {
        GetSelectValuesForAssets('api/ArtCatalog/GetInterior').then((response) => 
        { 
            setInteriorTags(response)
        })
        GetSelectValuesForAssets('api/ArtCatalog/GetExterior').then((response) => 
        { 
            setExteriorTags(response)
        })
    }, [])

    return(
        <div>
            <label>Exterior Tags</label>
            <Select 
                styles={selectStylesForField}
                menuPortalTarget={document.querySelector('body')}
                isMulti 
                className="art-cat-filters-row"
                options={exteriorTags} 
                value={visible.object.backgroundExterioTags?.map((el : any, index : any) => { return { id: index, value: index, label: el }})}
                onChange={(value : any) => 
                { 
                    setVisible({...visible, object: {...visible.object, backgroundExterioTags: value?.map((el : any) => el.label), backgroundInterioTags: [] }})
                }}
            />
            <label className="mt-3">Interior Tags</label>
            <Select 
                styles={selectStylesForField}
                menuPortalTarget={document.querySelector('body')}
                isMulti 
                options={interiorTags} 
                value={visible.object.backgroundInterioTags?.map((el : any, index : any) => { return { id: index, value: index, label: el }})}
                onChange={(value : any) => 
                { 
                    setVisible({...visible, object: {...visible.object, backgroundInterioTags: value?.map((el : any) => el.label), backgroundExterioTags: [] }})
                }}
            />
            <CButton 
                color="primary" 
                className="mt-5" 
                onClick={() => 
                {
                    const saveChangesSession = new SaveChangesSessionLogger();
                                    
                    saveChangesSession.asset_name = 'Background'
                    saveChangesSession.timestamp = new Date().toUTCString();
                    saveChangesSession.session_id = localStorage.getItem('sessionId') ?? '';
                    saveChangesSession.session_number = parseInt(localStorage.getItem('sessionNumber') ?? '');
                    saveChangesSession.user_id = localStorage.getItem('uid') ?? '';
                    
                    saveChangesSession.logEvent();
                    
                    UpdateAssets('api/ArtCatalog/Update_BackgroundAssetTags', 
                        { 
                            docId: visible.object?.docId, 
                            exterioTags: visible.object?.backgroundExterioTags,
                            interioTags: visible.object?.backgroundInterioTags
                        }, alert)

                    dispatch(ActionCreatorsForArtCatalog.updateBackgrounds(
                        {
                            id: visible.object?.docId, 
                            exterioTags: visible.object?.backgroundExterioTags, 
                            interioTags: visible.object?.backgroundInterioTags
                        }))
                }}
            >Save Changes</CButton>
        </div>
    )
}