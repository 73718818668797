import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppendixOthersDataTable, AppendixTitleDescriptionTable } from './AppendixDataTable';
import { titleDescriptionColumns, otherTableColumns } from './TranslationLines/_TranslationsHelpers';
import ReactLoading from 'react-loading';
import Loader from '../Loader';

export const AppendixBody = ({ storyId, serialNumber, language, nativeLanguage, languageData } : any) =>
{
    const appendix = useSelector((state: any) => state.translationsReducer.appendix);

    let otherLanguage = (language != 0 && language != 1) ? languageData.find((element : any) => element.id == parseInt(language))?.label : 'Other';

    const { updatingBool } = useSelector((state: any) => state.translationsReducer);

    return(
    <div className="column-style">
        <Loader updatingBool={updatingBool}/>
        
        <h3 className="mt-3">Title description</h3>
        <AppendixTitleDescriptionTable 
            columns={titleDescriptionColumns(otherLanguage)} 
            data={appendix.episodeAppendix}
            storyId={storyId}
            serialNumber={serialNumber}
            language={language}
            nativeLanguage={nativeLanguage}
            languageData={languageData}
        />

        <h3 className="mt-3">Chapters Summary</h3>
        <AppendixOthersDataTable 
            columns={otherTableColumns(otherLanguage)} 
            data={appendix.chaptersSummaryAppendixList}
            storyId={storyId}
            serialNumber={serialNumber}
            language={language}
            nativeLanguage={nativeLanguage}
            languageData={languageData}
            urlForApi='api/Translations/TranslateChaptersSummary'
            tableFixedId={1}
        />

        <h3 className="mt-3">Premium Choice Description Bubble</h3>
        <AppendixOthersDataTable 
            columns={otherTableColumns(otherLanguage)} 
            data={appendix.descriptionBubbleAppendixList}
            storyId={storyId}
            serialNumber={serialNumber}
            language={language}
            nativeLanguage={nativeLanguage}
            languageData={languageData}
            urlForApi='api/Translations/TranslateDescriptionBubble'
            tableFixedId={6}
        />  
        
        <h3 className="mt-3">Charming Points</h3>
        <AppendixOthersDataTable 
            columns={otherTableColumns(otherLanguage)} 
            data={appendix.charmingPointsAppendixList}
            storyId={storyId}
            serialNumber={serialNumber}
            language={language}
            nativeLanguage={nativeLanguage}
            languageData={languageData}
            urlForApi='api/Translations/TranslateCharmingPoints'
            tableFixedId={2}
        />

        <h3 className="mt-3">Characters</h3>
        <AppendixOthersDataTable 
            columns={otherTableColumns(otherLanguage)} 
            data={appendix.charactersAppendix}
            storyId={storyId}
            serialNumber={serialNumber}
            language={language}
            nativeLanguage={nativeLanguage}
            languageData={languageData}
            urlForApi='api/Translations/TranslateCharacterName'
            tableFixedId={3}
        />

        <h3 className="mt-3">Hairstyles</h3>
        <AppendixOthersDataTable 
            columns={otherTableColumns(otherLanguage)} 
            data={appendix.hairstyleAppendix}
            storyId={storyId}
            serialNumber={serialNumber}
            language={language}
            nativeLanguage={nativeLanguage}
            languageData={languageData}
            urlForApi='api/Translations/TranslateHairstyleName'
            tableFixedId={4}
        />

        <h3 className="mt-3">Clothes</h3>
        <AppendixOthersDataTable 
            columns={otherTableColumns(otherLanguage)} 
            data={appendix.clothesAppendix}
            storyId={storyId}
            serialNumber={serialNumber}
            language={language}
            nativeLanguage={nativeLanguage}
            languageData={languageData}
            urlForApi='api/Translations/TranslateClothesName'
            tableFixedId={5}
        />
        
        <h3 className="mt-3">Backgrounds</h3>
        <AppendixOthersDataTable 
            columns={otherTableColumns(otherLanguage)}
            data={appendix.backgroundAppendix}
            storyId={storyId}
            serialNumber={serialNumber}
            language={language}
            nativeLanguage={nativeLanguage}
            languageData={languageData}
            urlForApi='api/Translations/TranslateBackgroundName'
            tableFixedId={7}
        />
    </div>)
}

export default AppendixBody;