export enum LineTypes 
{
    L,
    O1,
    O2,
    O3,
    Q,
    A,
    M,
    C1,
    C2,
    B,
    BA,
    G,
    E,
    T,
    TA,
    V,
    VA,
    F
}