import React, { useEffect, useState } from "react";
import { CButton, CForm } from "@coreui/react";
import { ErrorMessage, Field, Formik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { 
  SaveCinematic,
  GetTypesForSetupStory,
  GetAssetsForSetupStory,
  SaveVisualChoice, 
} from "../../../services/SetupStoryServices";
import ReactLoading from 'react-loading';
import { customStyles } from "../../Helpers/CustomStyles";
import { selectStylesForField } from "../Lines/_Helpers";
import { useAlert } from 'react-alert';
export const AddVisualChoicesModalBody = ({ selectedData, data, setData, setVisible, storyId }: any) =>
{
    const alert = useAlert();
    const [type, setType] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dirtySelect, setDirtySelect] = useState(false);
  
    const [assets, setAssets] = useState([]);
  
    let addVisualChoiceSchema = yup.object().shape({
      name: yup.string().required("You must enter name."),
    });
  
    const [isAssetDisabled, setAssetDisabled] = useState((selectedData.assetDocId == null || selectedData.assetDocId == '') ? false : true);
  
    const [visualChoiceSelectedObject, setVisualChoiceSelectedObject] = useState(
    {
      id: selectedData.id,
      storyId: selectedData.storyId,
      counter: selectedData.counter,
      episode: selectedData.episode,
      name: selectedData.name,
      soName: selectedData.soName,
      type: selectedData.type,
      reference: selectedData.reference,
      assetDocId: selectedData.assetDocId,
      assetPath: selectedData.assetPath,
      assetName: selectedData.assetName
    });
  
    useEffect(() => {
      GetTypesForSetupStory('api/SetupStory/SetupStory_GetVisualTypes').then((result: any) => {
        setType(
          result?.map((option: any) => {
            return { id: option.id, value: option.value, label: option.label };
          })
        );
      });
  
      GetAssetsForSetupStory(`api/SetupStory/GetVisualAssets?storyId=${storyId}`).then((result : any) => 
      {
        setAssets(result?.map((option : any) => { return { id: option.id, value: option.id, label: option.name, src: option.path } }))
      })
    }, []);

    return(
        <Formik
          initialValues={{
            episode: selectedData.episode,
            name: selectedData.name,
            soName: selectedData.soName,
            type: selectedData.type,
            reference: selectedData.reference,
            assetDocId: selectedData.assetDocId,
            assetPath: selectedData.assetPath,
            assetName: selectedData.assetName
          }}
          onSubmit={(values) => { 
            setLoading(true);
            SaveVisualChoice({
              ...values, 
              id: visualChoiceSelectedObject.id,
              storyId: visualChoiceSelectedObject.storyId,
              counter: visualChoiceSelectedObject.counter,
              type: visualChoiceSelectedObject.type, 
              assetDocId: visualChoiceSelectedObject.assetDocId,
              assetPath: visualChoiceSelectedObject.assetPath,
              assetName: visualChoiceSelectedObject.assetName
            }, data, setData, setVisible, setLoading, alert)
        }}
          validationSchema={addVisualChoiceSchema}
        >
          {({ dirty, isValid, handleSubmit }) => {
            return (
              <CForm onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                { loading ? <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1000, position: 'absolute', top: '0%', left: '0%', width: '100%', height: '100%'}}>
                                <div style={{justifyContent: 'center', alignItems: 'center', zIndex: 1001, position: 'absolute', top: '45%', left: '45%'}}>
                                  <ReactLoading className="mt-3 mb-5" type='spin' color='#0080FF'/>
                                  </div>
                            </div> : <div></div> }
                <div className="row-style modal-row-stp-story col-sm-12 row">
                  <div className="form-group-name col-sm-6 ">
                    <label htmlFor="episode">Episode</label>
                    <Field
                      className="form-control"
                      name="episode"
                      type="text"
                      disabled
                      autoComplete="off"
                    ></Field>
                  </div>
    
                  <div className="form-group-name col-sm-6">
                    <label htmlFor="name">Name</label>
                    <Field
                      className="form-control"
                      name="name"
                      type="text"
                      autoComplete="off"
                      disabled
                      required
                    ></Field>
                    <ErrorMessage
                      component="p"
                      className="field-colorchange"
                      name="name"
                    />
                  </div>
                </div>
                <div className="row-style modal-row-stp-story col-sm-12 row">
                  <div className="form-group-name col-sm-6 ">
                    <label htmlFor="type">Type</label>
                    <Field
                      as={Select}
                      options={type}
                      styles={selectStylesForField}
                      menuPortalTarget={document.querySelector('body')}
                      value={type?.find(
                        (x: any) => x.value == visualChoiceSelectedObject.type
                      )}
                      onChange={(value: any) => 
                      {
                        setVisualChoiceSelectedObject({
                          ...visualChoiceSelectedObject,
                          type: value.value,
                          assetDocId: value.value == 1 || value.value == 2 ? visualChoiceSelectedObject.assetDocId : null,
                          assetName: value.value == 1 || value.value == 2 ? visualChoiceSelectedObject.assetName : null,
                          assetPath: value.value == 1 || value.value == 2 ? visualChoiceSelectedObject.assetPath : null,
                        });
                        setDirtySelect(true);
                      }}
                      isOptionDisabled={(option : any) => { return false } }
                      name="type"
                      autoComplete="off"
                      required
                    ></Field>
                    <ErrorMessage
                      component="p"
                      className="field-colorchange"
                      name="type"
                    />
                  </div>
                { visualChoiceSelectedObject.type !== 1 ? 
                  <div className="form-group-name col-sm-6 ">
                      <label htmlFor="reference">Reference</label>
                      <Field
                        className="form-control"
                        name="reference"
                        type="text"
                        autoComplete="off"
                      ></Field>
                      <ErrorMessage
                        component="p"
                        className="field-colorchange"
                        name="reference"
                      />
                  </div> : <div/> }
                </div>

    
                <div className="form-group-name col-sm-12 modal-row-stp-story">
                    <label htmlFor="assets" className="mt-3">Catalogue</label>
                    <Field
                      as={Select}
                      options={assets}
                      styles={{...customStyles, ...selectStylesForField}}
                      menuPortalTarget={document.querySelector('body')}
                      value={assets?.find(
                        (x: any) => x.value == visualChoiceSelectedObject.assetDocId
                      ) ?? ''}
                      isClearable
                      onChange={(value: any) => 
                      {
                        if(value)
                        {
                          setVisualChoiceSelectedObject({
                            ...visualChoiceSelectedObject,
                            assetDocId: value.value  ,
                            assetName: value.label,
                            assetPath: value.src ,
                            type: visualChoiceSelectedObject.type == 2 ? visualChoiceSelectedObject.type : 1
                          });
                        }
                        else{
                          setVisualChoiceSelectedObject({
                            ...visualChoiceSelectedObject,
                            assetDocId: null ,
                            assetName: null,
                            assetPath: null,
                            type: 0
                          });
                        }
                        setAssetDisabled(value != null ? true : false);
                        setDirtySelect(true);
                      }}
                      name="assets"
                      autoComplete="off"
                    ></Field>
                    <ErrorMessage
                      component="p"
                      className="field-colorchange"
                      name="assets"
                    />
                </div>
    
                <div className="modal-buttons-container float-right">
                  <CButton
                    type="submit"
                    disabled={!(dirty || dirtySelect) || !isValid}
                    className="mr-2 wide"
                    color="primary"
                  >
                    Save
                  </CButton>
                  <CButton className="mr-2 wide" color="secondary" onClick={() => setVisible(false)}>Cancel</CButton>
                </div>
              </CForm>
            );
          }}
        </Formik>)
}

export default AddVisualChoicesModalBody;